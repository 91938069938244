import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { BodyCopy, Headline } from 'components/common/typography';
import { ButtonContainer as Button } from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { IDeviceCompatibilityPayload } from 'models';

import { IAnalyticsProps } from 'components/common/analytics';
import {
    TealiumFlowName,
    TealiumLinkEvents,
    TealiumPageLinkLocations,
    TealiumPageType,
    TealiumPagevents,
    TealiumLoginState,
} from 'models/tealium';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';
import { OMNI_SESSION_KEYS } from 'models/constants';
const styles = require('./signup-success.less');

interface ShortcodeValidationResponse {
    readonly campaign_uid: string;
    readonly valid: string;
}

interface Props extends IAnalyticsProps {
    readonly isSignedIn: boolean;
    readonly account: any;
    readonly device: any;
    readonly shortCode: any;
    readonly validateShortCodeResponse: ShortcodeValidationResponse;
    checkDevice(payload: IDeviceCompatibilityPayload): void;
    redirectTo(url: string): void;
}

@CSSModules(styles)
class SignupSuccess extends React.Component<Props> {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        TealiumDispatcher.dispatchView(
            TealiumFlowName.NONE,
            TealiumPageType.SIGNIN_SUCCESS,
            TealiumLoginState.LOGGED_IN,
            this.props.account ? this.props.account.accountid : '',
        );
        const {
            account,
            device: { compatibility },
        } = this.props;
        this.saveCompatibilityCheck(account, compatibility);
        sessionStorage.removeItem('refCode');
    }

    render() {
        const {
            props: {
                isSignedIn,
                redirectTo,
                validateShortCodeResponse,
                shortCode,
            },
        } = this;

        if (validateShortCodeResponse.valid === '1') {
            sessionStorage.setItem(OMNI_SESSION_KEYS.shortCode, shortCode);
            sessionStorage.setItem(
                OMNI_SESSION_KEYS.campaignId,
                validateShortCodeResponse.campaign_uid,
            );
        }
        return (
            <>
                {isSignedIn ? (
                    <div styleName="signup-success">
                        <div styleName="signup-success__header">
                            <Headline center>
                                {__('signup-success.header')}
                            </Headline>

                            <BodyCopy styleName="signup-success__body">
                                {__('signup-success.body')}
                            </BodyCopy>
                        </div>
                        <div styleName="signup-success__btn-wrapper">
                            <Button
                                onClick={event => this.tealiumEventShop(event)}
                                invert
                                styleName="signup-success__btn"
                            >
                                {__('signup-success.btn-1')}
                            </Button>
                            <Button
                                onClick={event => this.tealiumEventBYOD(event)}
                                invert
                                styleName="signup-success__btn"
                            >
                                {__('signup-success.btn-2')}
                            </Button>
                        </div>
                        <BodyCopy styleName="signup-success__footnote">
                            {__('signup-success.footnote')}
                        </BodyCopy>
                    </div>
                ) : (
                    redirectTo(__('routes.home'))
                )}
            </>
        );
    }

    private readonly saveCompatibilityCheck = (account, compatibility) => {
        if (
            account.orders.length === 0 &&
            compatibility.fetchStatus === FetchStatus.SUCCESS
        ) {
            if (compatibility.compatibility.deviceId) {
                sessionStorage.setItem(
                    OMNI_SESSION_KEYS.imei,
                    compatibility.compatibility.deviceId,
                );
            } else if (compatibility.compatibility.payload) {
                const {
                    carrier,
                    model,
                    os,
                } = compatibility.compatibility.payload;
                sessionStorage.setItem(OMNI_SESSION_KEYS.carrier, carrier);
                sessionStorage.setItem(OMNI_SESSION_KEYS.os, os);
                sessionStorage.setItem(OMNI_SESSION_KEYS.model, model);
            }
        }
    };

    private readonly tealiumEventShop = (
        event: React.FormEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();
        TealiumDispatcher.dispatchEvent(
            TealiumFlowName.NONE,
            TealiumPageType.SIGNIN_SUCCESS,
            TealiumPagevents.SIGNIN_SUCCESS_BROWSE,
            TealiumPageLinkLocations.NONE,
            TealiumLinkEvents.BUTTON,
            TealiumLoginState.LOGGED_IN,
            this.props.account ? this.props.account.accountid : '',
        );
        setTimeout(() => this.handleShop(), 800);
    };

    private readonly handleShop = () => {
        window.location.href = __('routes.phones');
    };

    private readonly tealiumEventBYOD = (
        event: React.FormEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();
        TealiumDispatcher.dispatchEvent(
            TealiumFlowName.NONE,
            TealiumPageType.SIGNIN_SUCCESS,
            TealiumPagevents.SIGNIN_SUCCESS_BYOD,
            TealiumPageLinkLocations.NONE,
            TealiumLinkEvents.BUTTON,
            TealiumLoginState.LOGGED_IN,
            this.props.account ? this.props.account.accountid : '',
        );
        setTimeout(() => this.handleBOYD(), 800);
    };

    private readonly handleBOYD = () => {
        window.location.href = __('routes.byod');
    };
}

export { SignupSuccess };
