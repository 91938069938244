import * as React from 'react';
import * as CSSModules from 'react-css-modules';

interface Props extends React.HTMLProps<HTMLButtonElement> {}
const NavButtonComponent = ({ children, ...props }: Props) => (
    <button {...props} styleName="nav-button">
        {children}
    </button>
);

const NavButton = CSSModules(NavButtonComponent, require('./nav-button.less'));
export { NavButton };
