import * as React from 'react';

import { IAnalyticsProps } from 'components/common/analytics';
import { BaseForm } from 'components/common/forms';
import { Hidden } from 'components/common/hidden';
import { BodyCopy } from 'components/common/typography';
import { ButtonContainer as Button, InputContainer as Input } from 'containers';
import { IInputValidationProps } from 'models';
import { InputValidator } from 'utils';

interface IMappedProps extends IAnalyticsProps, IInputValidationProps {
    readonly error: string;
    readonly fetching: boolean;
    handleSubmit(zipCode: string): void;
}

interface IOwnProps {
    readonly complete: boolean;
}

type IProps = IMappedProps & IOwnProps;

class ZipCodeForm extends BaseForm<IProps, {}> {
    render() {
        const {
            hasErrors,
            onSubmit,
            props: {
                data: { zipCode },
                error,
                handleOnChange,
                handleOnValidate,
                complete,
                fetching,
            },
        } = this;

        return (
            <form onSubmit={onSubmit}>
                <Input
                    noMargin
                    error={error}
                    id="zipCode"
                    label={__('zip-code-form.label')}
                    readOnly={complete}
                    value={zipCode}
                    onChange={handleOnChange}
                    onValidate={handleOnValidate}
                    validate={InputValidator.validateZipCode}
                />
                <BodyCopy thin>{__('zip-code-form.copy')}</BodyCopy>
                <Hidden when={complete}>
                    <Button
                        right
                        loading={fetching}
                        disabled={hasErrors}
                        type="submit"
                        label="Next"
                    />
                </Hidden>
            </form>
        );
    }

    // Override
    private readonly onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const {
            props: {
                data: { zipCode },
                handleSubmit,
            },
        } = this;
        handleSubmit(zipCode);
    };

    private get hasErrors(): boolean {
        const {
            props: {
                errors: { zipCode },
            },
        } = this;
        return zipCode;
    }
}

export { ZipCodeForm };
