import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { ActionType, CancelAction } from '.';

interface CancelState {
    readonly fetchStatus: FetchStatus;
    readonly error: string;
}

const DEFAULT_STATE: CancelState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
};

const {
    CANCEL_SERVICE,
    REPORT_CANCEL_ERROR,
    REPORT_CANCEL_SUCCESS,
} = ActionType;

const cancelReducer = (
    state: CancelState = DEFAULT_STATE,
    action: CancelAction | IDefaultAction,
) => {
    switch (action.type) {
        case CANCEL_SERVICE:
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        case REPORT_CANCEL_SUCCESS:
            return {
                ...state,
                fetchStatus: FetchStatus.SUCCESS,
            };
        case REPORT_CANCEL_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        default:
            return state;
    }
};

export { cancelReducer, DEFAULT_STATE, CancelState };
