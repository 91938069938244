import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { TealiumFlowName, TealiumPageType } from 'models/tealium';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';

const styles = require('./swap-aem.less');
@CSSModules(styles, { allowMultiple: true })
class SwapAEM extends React.Component<{}> {
    constructor(props: {}) {
        super(props);
    }
    componentDidMount() {
        TealiumDispatcher.dispatchView(
            TealiumFlowName.NONE,
            TealiumPageType.SWAP_LANDING,
        );
    }
    render(): JSX.Element {
        return (
            <>
                <div>
                    <iframe src={__('routes.swap.aem')} styleName="iframe" />
                </div>
            </>
        );
    }
}

export { SwapAEM };
