import {
    IConfigurationBasePaths,
    IDeviceCompatibilityPayload,
    IDeviceCompatibilityResponse,
    IDeviceListCarriersModels,
} from 'models';
import { stringify } from 'query-string';
import { ThinAirService } from './thin-air.service';

class DeviceService extends ThinAirService {
    static async checkDeviceCompatibility(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        payload: IDeviceCompatibilityPayload,
    ): Promise<IDeviceCompatibilityResponse> {
        const response = await DeviceService.fetchJson<
            IDeviceCompatibilityResponse
        >(
            `${DeviceService.BASE_URL}${
                basePaths.device
            }/compatibility?${stringify(payload)}`,
            {
                headers: DeviceService.getDefaultHeaders(firebaseToken),
                method: 'GET',
            },
        );
        return response;
    }

    static async getCarriersModels(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
    ): Promise<IDeviceListCarriersModels> {
        const response = await DeviceService.fetchJson<
            IDeviceListCarriersModels
        >(`${DeviceService.BASE_URL}${basePaths.device}/carriersmodels`, {
            headers: DeviceService.getDefaultHeaders(firebaseToken),
            method: 'GET',
        });

        if (!response.carriers || !response.models) {
            throw new Error('Error fecthing carriers/models.');
        }

        return response;
    }
}

export { DeviceService };
