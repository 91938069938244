import { FAQSearchBar } from 'components/common/search/faq-search-bar';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { searchFAQ } from 'state/faq';

const mapStateToProps = (state: IApplicationState) => ({
    searchTerm: state.faq.searchTerm,
});

const mapDispatchToProps = dispatch => ({
    searchFAQ: term => dispatch(searchFAQ(term)),
});

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const FAQSearchBarContainer = enhance(FAQSearchBar);

export { FAQSearchBarContainer };
