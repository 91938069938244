import { withAnalytics } from 'components/common/analytics';
import { SelectNpaNxxForm } from 'components/sign-up/components/phone-number/new-number/select-npa-nxx-form';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';

const mapStateToProps = ({ purchase }: IApplicationState, ownProps) => ({
    ...purchase,
    ...ownProps,
});

const enhance = compose(
    withAnalytics,
    connect(mapStateToProps),
);
const SelectNpaNxxFormContainer = enhance(SelectNpaNxxForm);

export { SelectNpaNxxFormContainer };
