import * as classNames from 'classnames';
import * as Emoji from 'components/common/graphics/emoji';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Close } from 'components/common/close';
import { BodyCopy, Headline } from 'components/common/typography';
import { IAnalyticsProps, IDeviceTypeProps, IModalProps } from 'models';
import { disableBodyScroll, enableBodyScroll } from 'utils';
import { ButtonContainer as Button } from 'containers';

const styles = require('./modal.less');

interface Props extends IModalProps, IDeviceTypeProps, IAnalyticsProps {
    readonly title?: string;
    readonly subtitle?: string;
    readonly content?: string;
    readonly closable?: boolean;
    readonly closeSelf?: boolean;
    readonly children?: React.ReactElement<any>;
    readonly additionalProps?: any;
    readonly invert?: boolean;
    readonly center?: boolean;
    readonly emoji?: boolean;
    readonly minHeight?: number;
    readonly error?: boolean;
    readonly transparent?: boolean;
    readonly noPadding?: boolean;
    readonly notice?: React.ReactNode;
    readonly showNotice?: boolean;
    readonly closeButton?: boolean;
    readonly suggestedAddressModal?: boolean;
}

@CSSModules(styles, { allowMultiple: true })
class ModalWrapper extends React.Component<Props> {
    // tslint:disable:readonly-keyword
    targetElement;
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.targetElement = document.querySelector('#modal');
        disableBodyScroll(this.targetElement, {
            reserveScrollBarGap: true,
        });
        this.closeNotice();
    }

    componentWillReceiveProps() {
        this.closeNotice();
    }

    componentWillUnmount() {
        enableBodyScroll(this.targetElement);
    }

    render() {
        const {
            props: {
                children,
                closable,
                additionalProps,
                title,
                subtitle,
                invert,
                center,
                emoji,
                minHeight,
                error,
                transparent,
                noPadding,
                notice,
                showNotice,
                closeButton,
                suggestedAddressModal,
            },
        } = this;
        return children ? (
            <div styleName={classNames('modal')} id="modal">
                <div
                    styleName={classNames('inner-container', {
                        transparent,
                        suggestedAddressModal,
                    })}
                    style={{ minHeight: center ? 336 : minHeight || 0 }}
                >
                    <div styleName="content-wrapper">
                        {notice && (
                            <div
                                styleName={classNames('notice-wrapper', {
                                    collapsed: !showNotice,
                                })}
                            >
                                <Headline three noMargin styleName="notice">
                                    {notice}
                                </Headline>
                            </div>
                        )}
                        <div
                            styleName={classNames('content', {
                                center,
                                invert,
                                noPadding,
                            })}
                        >
                            {closable && (
                                <Close
                                    onClick={this.closeModalWithAnalytics}
                                    invert={invert}
                                />
                            )}
                            {title && (
                                <Headline
                                    two
                                    center
                                    noMarginTop
                                    invert={invert}
                                    error={error}
                                >
                                    {title}
                                </Headline>
                            )}
                            {subtitle && (
                                <BodyCopy
                                    styleName={classNames('subtitle', {
                                        error,
                                    })}
                                >
                                    {subtitle}
                                </BodyCopy>
                            )}
                            {emoji && (
                                <div styleName="emojis">
                                    <Emoji.O styleName="o" invert={invert} />
                                    <Emoji.D styleName="d" invert={invert} />
                                </div>
                            )}
                            {React.cloneElement(
                                children as React.ReactElement<any>,
                                {
                                    ...additionalProps,
                                    onClose: this.closeModalWithAnalytics,
                                },
                            )}
                            {closeButton && (
                                <div styleName="close-button">
                                    <Button
                                        fullWidthMobile
                                        margin20
                                        label="Okay"
                                        onClick={this.closeModalWithAnalytics}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ) : null;
    }

    private readonly closeNotice = () => {
        const { closeSelf, onClose, showNotice } = this.props;
        if (closeSelf && showNotice) {
            if (!!onClose) {
                onClose();
            }
        }
    };

    private readonly closeModalWithAnalytics = () => {
        const { onClose } = this.props;
        if (!!onClose) {
            onClose();
        }
    };
}

export { ModalWrapper };
