import { Surprised } from 'components/common/graphics/emoji/surprised';
import { Headline } from 'components/common/typography';
import { BodyCopy } from 'components/common/typography/bodycopy/bodycopy';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./four-oh-four.less');

const FourOhFour = () => (
    <div styleName="container">
        <div styleName="inner-container">
            <div styleName="emoji">
                <Surprised />
            </div>
            <div styleName="headline">
                <Headline two noMargin>
                    {__('four-oh-four.headline')}
                </Headline>
            </div>
            <div styleName="body">
                <BodyCopy noMargin>{__('four-oh-four.body')}</BodyCopy>
            </div>
        </div>
    </div>
);

export default CSSModules(FourOhFour, styles, {});
