import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import * as classNames from 'classnames';

const styles = require('./grid-divider.less');

interface GridDividerProps {
    readonly children?: any;
    readonly white?: boolean;
    readonly desktopMargin?: boolean;
}

const GridDividerComponent = ({ children, ...props }: GridDividerProps) => {
    return (
        <div styleName={classNames('grid-divider', { ...props })}>
            <div styleName={classNames('grid-divider-wrapper', { ...props })}>
                {children}
            </div>
        </div>
    );
};
const GridDivider = CSSModules(GridDividerComponent, styles, {
    allowMultiple: true,
});
export { GridDivider };
