import { all } from 'redux-saga/effects';
import { accountSaga } from './account';
import { authenticationSaga } from './authentication';
import { billHistorySaga } from './bill';
import { chatSaga } from './chat';
import { configurationSaga } from './configuration';
import { deviceSaga } from './device';
import { emailActionSaga } from './email-action';
import { faqSaga } from './faq';
import { feedbackSaga } from './feedback';
import { firebaseSaga } from './firebase';
import { leadSaga } from './lead';
import { paymentSaga } from './payment';
import { portStatusSaga } from './port-status';
import { productsSaga } from './products';
import { purchaseSaga } from './purchase';
import { referralSaga } from './referral';
import { registrationSaga } from './registration';
import { shopSaga } from './shop';
import { startupSaga } from './startup';
import { stayInLoopSaga } from './stay-in-loop';
import { tealiumSDKSaga } from './tealium';
import { userValidationSaga } from './user-validation';
import { viewSaga } from './view';

export default function* rootSaga() {
    // We start all the sagas in parallel
    yield all([
        accountSaga(),
        authenticationSaga(),
        billHistorySaga(),
        chatSaga(),
        configurationSaga(),
        deviceSaga(),
        emailActionSaga(),
        faqSaga(),
        feedbackSaga(),
        firebaseSaga(),
        leadSaga(),
        paymentSaga(),
        portStatusSaga(),
        productsSaga(),
        purchaseSaga(),
        referralSaga(),
        registrationSaga(),
        startupSaga(),
        tealiumSDKSaga(),
        userValidationSaga(),
        viewSaga(),
        stayInLoopSaga(),
        shopSaga(),
    ]);
}
