import { NonNormalizedTopic, Topic } from './faq-topic.model';

interface NonNormalizedFAQResponse {
    readonly version: string;
    readonly topics: ReadonlyArray<NonNormalizedTopic>;
}

class FAQResponse {
    readonly topics: ReadonlyArray<Topic>;

    constructor(
        readonly version: string,
        topics: ReadonlyArray<NonNormalizedTopic>,
    ) {
        this.topics = topics.map(Topic.normalize);
    }

    static normalize({
        version,
        topics,
    }: NonNormalizedFAQResponse): FAQResponse {
        return new FAQResponse(version, topics);
    }
}

export { NonNormalizedFAQResponse, FAQResponse };
