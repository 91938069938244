import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Label } from 'components/common/typography';
import { BooleanButtonContainer as BooleanButton } from 'containers';

interface IBooleanButtonsProps {
    readonly displayIf?: boolean;
    readonly labelFalse?: string | null;
    readonly labelTrue?: string | null;
    readonly metricFalse: any;
    readonly metricTrue: any;
    readonly noMarginTop?: boolean;
    readonly noPaddingSides?: boolean;
    readonly prompt: string;
    readonly flow: string;
    readonly value: boolean | null;
    onSelect(answer: boolean): void;
}

const styles = require('./boolean-buttons.less');

@CSSModules(styles, { allowMultiple: true })
class BooleanButtons extends React.Component<IBooleanButtonsProps, {}> {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.setState({ value: null });
    }

    render() {
        const {
            displayIf = true,
            labelFalse,
            labelTrue,
            metricFalse,
            metricTrue,
            noMarginTop,
            noPaddingSides,
            prompt,
            value,
            flow,
        } = this.props;
        return displayIf ? (
            <>
                <Label id="prompt" styleName="prompt" noMarginTop={noMarginTop}>
                    {prompt}
                </Label>
                <div styleName="button-wrapper">
                    <div styleName="button">
                        <BooleanButton
                            selected={value === true}
                            onClick={this.onSelect(true)}
                            metricTrue={metricTrue}
                            flow={flow}
                            noPaddingSides={noPaddingSides}
                            label={labelTrue ? labelTrue : 'Yes'}
                        />
                    </div>
                    <div styleName="button">
                        <BooleanButton
                            selected={value === false}
                            onClick={this.onSelect(false)}
                            metricFalse={metricFalse}
                            flow={flow}
                            noPaddingSides={noPaddingSides}
                            label={labelFalse ? labelFalse : 'No'}
                        />
                    </div>
                </div>
            </>
        ) : null;
    }

    private readonly onSelect = (value: boolean) => (
        e: React.MouseEvent<HTMLButtonElement>,
    ): void => {
        e.preventDefault();
        this.props.onSelect(value);
    };
}

export { BooleanButtons };
