import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';
import { ErrorMsg } from 'components/common/typography/error-msg/error-msg';
import { Grid, GridTemplates } from 'components/grid';
import { ButtonContainer as Button, InputContainer as Input } from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { IEmailPassword, IInputValidationProps } from 'models';
import {
    TealiumFlowName,
    TealiumLinkEvents,
    TealiumPageType,
    TealiumPagevents,
} from 'models/tealium';
import { Link } from 'react-router-dom';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';

const styles = require('./sign-in.less');

interface IProps extends IAnalyticsProps, IInputValidationProps {
    readonly error: string;
    readonly fetchStatus: FetchStatus;
    readonly isSignedIn: boolean;
    readonly bagCount: number;
    readonly customerStatus: '';
    readonly billFetchStatus: FetchStatus;
    readonly statusTransition: string;
    signIn(email: string, password: string, rememberMe: boolean): void;
    clearAuthenticationErrors(): void;
    checkCart(): void;
    redirectTo(url: string): void;
}

interface IState extends IEmailPassword {
    readonly email: string;
    readonly error: string;
    readonly password: string;
}

const DEFAULT_STATE = {
    email: '',
    error: '',
    password: '',
    rememberMe: true,
};

@CSSModules(styles)
class SignIn extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    componentWillUnmount() {
        this.props.clearAuthenticationErrors();
    }

    componentWillReceiveProps(newProps) {
        const {
            props: {
                checkCart,
                fetchStatus,
                bagCount,
                redirectTo,
                customerStatus,
                billFetchStatus,
                statusTransition,
            },
        } = this;
        let custStatus = String(customerStatus).toUpperCase();
        let status = String(statusTransition).toUpperCase();
        if (newProps && newProps.isSignedIn) {
            const loc = window.location.search.split('=');
            if (loc && loc.length > 0 && loc[0] === '?goto') {
                window.location.href = loc[1];
                return null;
            }
        }

        if (
            fetchStatus !== newProps.fetchStatus &&
            newProps.fetchStatus === FetchStatus.SUCCESS &&
            custStatus !== 'SUSPENDED'
        ) {
            checkCart();
        } else if (
            bagCount !== 0 &&
            customerStatus !== '' &&
            custStatus !== 'SUSPENDED' &&
            (custStatus !== 'LAPSED' ||
                (custStatus === 'LAPSED' && status === 'ACTIVE') ||
                (custStatus === 'LAPSED' &&
                    status !== 'ACTIVE' &&
                    billFetchStatus !== FetchStatus.NOT_FETCHED &&
                    billFetchStatus !== FetchStatus.FETCHING))
        ) {
          if (window.location.pathname != '/') {
            redirectTo(__('routes.home'));
          }
        }
    }

    componentDidMount() {
        TealiumDispatcher.dispatchView(
            TealiumFlowName.NONE,
            TealiumPageType.SIGNIN,
        );
    }

    render() {
        const {
            handleChange,
            handleSubmit,
            isFetching,
            hasErrors,
            state: { email, password },
        } = this;
        return (
            <Grid>
                <GridTemplates.HeadlineTemplate
                    lg
                    center
                    headlineCentered
                    headline={__('sign-in.header')}
                >
                    <form styleName="form" onSubmit={handleSubmit}>
                        <label htmlFor="email" styleName="signInLabel">
                            {__('sign-in.email-input.placeholder')}
                        </label>
                        <Input
                            id="email"
                            value={email}
                            type="email"
                            onChange={handleChange}
                            onFocus={e => {
                                e.stopPropagation();
                                TealiumDispatcher.dispatchEvent(
                                    TealiumFlowName.NONE,
                                    TealiumPageType.SIGNIN,
                                    TealiumPagevents.SIGNIN_EMAIL,
                                    '',
                                    TealiumLinkEvents.FORM_FIELD,
                                );
                            }}
                        />
                        <label htmlFor="password" styleName="signInLabel">
                            {__('sign-in.password-input.placeholder')}
                        </label>
                        <Input
                            noMargin
                            togglePassword
                            tabIndex={'0'}
                            id="password"
                            type="password"
                            value={password}
                            onChange={handleChange}
                            onFocus={e => {
                                e.stopPropagation();
                                TealiumDispatcher.dispatchEvent(
                                    TealiumFlowName.NONE,
                                    TealiumPageType.SIGNIN,
                                    TealiumPagevents.SIGNIN_PASSWORD,
                                    '',
                                    TealiumLinkEvents.FORM_FIELD,
                                );
                            }}
                        />

                        <ErrorMsg>
                            {this.props.error || this.state.error}
                        </ErrorMsg>

                        {/* <CheckboxOption
                            id="remember-me"
                            checked={rememberMe}
                            onChange={handleRememberMeChange}
                            style={{ display: 'none' }}
                        >
                            Remember me
                        </CheckboxOption> */}
                        <Button
                            fullWidth
                            rounded
                            loading={isFetching}
                            disabled={hasErrors}
                            type="submit"
                            onClick={e => {
                                e.stopPropagation();
                                TealiumDispatcher.dispatchEvent(
                                    TealiumFlowName.NONE,
                                    TealiumPageType.SIGNIN,
                                    TealiumPagevents.SIGNIN_SUBMIT,
                                    '',
                                    TealiumLinkEvents.BUTTON,
                                );
                            }}
                            label={__('sign-in.sign-in-button')}
                        />
                    </form>
                    <div styleName="forgot-password">
                        <Link
                            to={__('routes.forgot-password')}
                            onClick={e => {
                                e.stopPropagation();
                                TealiumDispatcher.dispatchEvent(
                                    TealiumFlowName.NONE,
                                    TealiumPageType.SIGNIN,
                                    TealiumPagevents.SIGNIN_FORGOT_PWD,
                                    '',
                                    TealiumLinkEvents.TEXT_LINK,
                                );
                            }}
                        >
                            {__('sign-in.forgot-password')}
                        </Link>
                    </div>
                </GridTemplates.HeadlineTemplate>
            </Grid>
        );
    }

    private get isFetching(): boolean {
        const {
            props: { fetchStatus },
        } = this;
        return fetchStatus === FetchStatus.FETCHING;
    }

    /**
     * Responds to the form submit event.
     * @param event dispatched from form submit.
     * @private
     */
    private readonly handleSubmit = (
        event: React.FormEvent<HTMLFormElement>,
    ): void => {
        event.preventDefault();
        const {
            email,
            password,
            // rememberMe
        } = this.state;
        if (!this.hasErrors) {
            this.props.signIn(email, password, false);
        }
    };

    /**
     * Responds to a input change to update the state.
     * @param key to update on state.
     * @returns A curried event handler that knows its state-key
     * @private
     */
    private readonly handleChange = (
        event: React.FormEvent<HTMLInputElement>,
    ): void => {
        const { name, value } = event.target as HTMLInputElement;

        const state = {};
        state[name] = value;

        this.setState(state);
    };

    private get hasErrors(): boolean {
        const {
            state: { email, password },
        } = this;
        return email === '' || password === '';
    }
}

export { SignIn };
