// tslint:disable:max-line-length
import * as React from 'react';

interface Props extends React.HTMLProps<SVGElement> {
    readonly invert?: boolean;
}

const Surprised = ({ invert, ...other }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="115"
        height="115"
        viewBox="0 0 115 115"
        // {...other}
    >
        <g fill={invert ? '#ffffff' : '#1800FF'} fillRule="evenodd">
            <path d="M20.978 83.011h74.044V64.993H20.978V83.01zM2 102h112V46H2v56zM25 11c0 6.074-4.926 11-10.999 11C7.924 22 3 17.074 3 11S7.924 0 14.001 0C20.074 0 25 4.926 25 11M113 11c0 6.074-4.926 11-11.001 11C95.924 22 91 17.074 91 11s4.924-11 10.999-11S113 4.926 113 11" />
        </g>
    </svg>
);

export { Surprised };
