import { withAnalytics } from 'components/common/analytics';
import { Summary } from 'components/sign-up/components/summary';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { checkout } from 'state/purchase/checkout/checkout.actions';

const mapStateToProps = ({ payment, purchase }: IApplicationState) => ({
    checkoutStatus: purchase.checkout.fetchStatus,
    paymentNonce: payment.paymentNonce,
});

const mapDispatchToProps = dispatch => ({
    checkout: p => dispatch(checkout(p)),
});

const enhance = compose(
    withAnalytics,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const SummaryContainer = enhance(Summary);

export { SummaryContainer };
