import { withAnalytics } from 'components/common/analytics';
import { withInputValidation } from 'components/common/forms';
import { EnterPhoneNumber } from 'components/sign-up/components/phone-number/port-number/enter-phone-number';
import { compose } from 'redux';

const dataKeys: ReadonlyArray<string> = ['phoneNumber'];

const enhance = compose(
    withAnalytics,
    withInputValidation(dataKeys),
);
const EnterPhoneNumberContainer = enhance(EnterPhoneNumber);

export { EnterPhoneNumberContainer };
