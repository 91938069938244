import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./message-bar.less');

interface Props {
    readonly messages: ReadonlyArray<string>;
    dismissAlert(idx: number): void;
}

const Message = (dismiss: (idx: number) => void, timeout: number = 10) => (
    message: string,
    idx: number,
) => {
    setTimeout(() => dismiss(idx), timeout * 1000);
    return (
        <div styleName="snackbar" key={`message_${idx}`}>
            <div styleName="snackbar-spacer" />
            <div styleName="snackbar-msg">
                <span>{message}</span>
            </div>
            <div styleName="snackbar-btn" onClick={() => dismiss(idx)}>
                <span>OKAY</span>
            </div>
        </div>
    );
};

@CSSModules(styles, { allowMultiple: true })
class MessageBar extends React.Component<Props> {
    render() {
        const {
            props: { messages, dismissAlert },
        } = this;
        return messages && messages.length ? (
            <>{messages.map(Message(dismissAlert))}</>
        ) : null;
    }
}

export { MessageBar };
