import { withAnalytics } from 'components/common/analytics';
import { withInputValidation } from 'components/common/forms/input';
import { ActionHandlerMode } from 'components/email-action-handler';
import { ResetPassword } from 'components/reset-password';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { resetPassword, verifyEmailActionCode } from 'state/email-action';

const dataKeys: ReadonlyArray<string> = ['password', 'confirmPassword'];

const mapStateToProps = ({ emailAction }: IApplicationState, otherProps) => ({
    location: otherProps.location as Location,
    resetStatus: emailAction.passwordResetStatus,
    verificationStatus: emailAction.verificationStatus,
});

const mapDispatchToProps = dispatch => ({
    resetPassword: (pw: string) => dispatch(resetPassword(pw)),
    verifyCode: (oobCode: string, mode: ActionHandlerMode) =>
        dispatch(verifyEmailActionCode({ oobCode, mode })),
});

const enhance = compose(
    withInputValidation(dataKeys),
    withAnalytics,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const ResetPasswordContainer = enhance(ResetPassword);

export { ResetPasswordContainer };
