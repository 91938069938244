import * as React from 'react';

import { Headline } from 'components/common/typography';
import { ModalWrapperContainer as ModalWrapper } from 'containers';
import { IModalProps } from 'models';

interface Props extends IModalProps {}

class FeedbackSuccessMsg extends React.Component<Props> {
    render() {
        return (
            <ModalWrapper>
                <Headline medium>
                    {__('feedback-modal-success.message')}
                </Headline>
            </ModalWrapper>
        );
    }
}

export { FeedbackSuccessMsg };
