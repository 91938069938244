// tslint:disable:max-line-length
import * as React from 'react';

interface Props extends React.HTMLProps<SVGElement> {
    readonly invert?: boolean;
}

const Squiggle = ({ invert, ...other }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="129"
        height="115"
        viewBox="0 0 129 115"
        // {...other}
    >
        <g fill={invert ? '#ffffff' : '#1800FF'} fillRule="evenodd">
            <path d="M26.52 99L1 73.17l13.565-13.307 11.955 12.1L45.258 53l18.74 18.963 18.735-18.961 18.743 18.962 11.96-12.101L127 73.169 101.476 99l-18.74-18.966L63.997 99 45.258 80.036zM32 11c0 6.075-4.926 11-11 11-6.076 0-11-4.925-11-11S14.924 0 21 0c6.074 0 11 4.925 11 11M119 11c0 6.075-4.926 11-11 11-6.076 0-11-4.925-11-11s4.924-11 11-11c6.074 0 11 4.925 11 11" />
        </g>
    </svg>
);

export { Squiggle };
