import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { splitProps } from 'utils';

const styles = require('./label.less');

enum Modifiers {
    inline = 'inline',
    disabled = 'disabled',
    noMargin = 'noMargin',
    noMarginTop = 'noMarginTop',
    noMarginBottom = 'noMarginBottom',
    invert = 'invert',
    normal = 'normal',
    marginTop50 = 'marginTop50',
}

interface LabelModifiers {
    readonly inline?: boolean;
    readonly disabled?: boolean;
    readonly noMargin?: boolean;
    readonly noMarginTop?: boolean;
    readonly noMarginBottom?: boolean;
    readonly invert?: boolean;
    readonly normal?: boolean;
    readonly marginTop50?: boolean;
}

type BaseLabel = React.HTMLProps<HTMLLabelElement> & LabelModifiers;

interface Props extends BaseLabel {
    readonly id?: string;
    readonly label?: string; // I don't know where this came from.
}

const Label = ({
    htmlFor,
    id,
    label,
    children,
    styleName,
    ...props
}: Props): JSX.Element | null => {
    const [modifiers, other] = splitProps(props, Modifiers);
    return children ? (
        <label
            id={id}
            htmlFor={htmlFor}
            styleName={classNames('label', modifiers, styleName)}
            {...other}
        >
            {children}
        </label>
    ) : null;
};

export default CSSModules(Label, styles, { allowMultiple: true });
