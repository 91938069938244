import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import * as classNames from 'classnames';

const styles = require('./block.less');

interface BlockProps {
    readonly children?: any;
    readonly width?: any;
    readonly width100?: any;
}

const BlockComponent = ({ children, width = 1, width100 }: BlockProps) => {
    return (
        <div
            styleName={classNames('block', { width100 })}
            style={{ marginTop: width * 8 }}
        >
            {children}
        </div>
    );
};

const Block = CSSModules(BlockComponent, styles, { allowMultiple: true });
export { Block, BlockProps };
