import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Hidden } from 'components/common/hidden';
import { InfoLink } from 'components/common/typography';

import {
    InactiveNumberErrorContainer as InactiveNumberError,
    IneligibleNumberErrorContainer as IneligibleNumberError,
    // LockedDeviceErrorContainer as LockedDeviceError,
    NewNumberContainer as NewNumber,
    PortNumberContainer as PortNumber,
} from 'containers';

import { IAnalyticsProps } from 'components/common/analytics';

import { IQueueableModal, IServiceStep, IServiceStepProps } from 'models';
// import { ProductType } from 'models/products';
import { IPurchaseState } from 'state/purchase/purchase.reducer';
import { BooleanButtons } from '../boolean-buttons';
import { IInactiveNumberErrorProps } from '../errors/inactive-number-error';
import { IIneligibleNumberErrorProps } from '../errors/ineligible-number-error';

const styles = require('./get-phone-number.less');

interface IMappedProps extends IAnalyticsProps, IPurchaseState {
    clearCartErrors(): void;
    redirect(path: string): void;
    reportCarriersError(): void;
    showModal(props: IQueueableModal): void;
}

interface IOtherProps extends IServiceStepProps {
    onNext(): void;
}

interface IState {
    readonly isDeviceUnlocked: boolean | null;
    readonly isKeepingCurrentNumber: boolean | null;
    readonly isNumberActive: boolean | null;
}

type IProps = IMappedProps & IOtherProps;

@CSSModules(styles)
class GetPhoneNumber extends React.Component<IProps, IState>
    implements IServiceStep {
    constructor(props) {
        super(props);

        this.state = {
            isDeviceUnlocked: true,
            isKeepingCurrentNumber: null,
            isNumberActive: null,
        };
    }

    render() {
        const {
            isKeepingCurrentNumber,
            isDeviceUnlocked,
            isNumberActive,
        } = this.state;
        const isCreating = isKeepingCurrentNumber === false;
        const isPorting = isKeepingCurrentNumber === true;
        const deviceIsUnlocked = isDeviceUnlocked === true;
        const numberIsActive = isNumberActive === true;
        return (
            <div styleName="container">
                <div styleName="buttons">
                    <BooleanButtons
                        noMarginTop
                        prompt={__('get-phone-number.current-number.prompt')}
                        onSelect={this.selectKeepCurrentNumber}
                        value={isKeepingCurrentNumber}
                        metricFalse={__(
                            'get-phone-number.current-number-no-metrics',
                        )}
                        metricTrue={__(
                            'get-phone-number.current-number-yes-metrics',
                        )}
                        flow={''}
                    />
                    <Hidden until={isPorting}>
                        <BooleanButtons
                            prompt={__('get-phone-number.active-number.prompt')}
                            onSelect={this.selectNumberActive}
                            value={isNumberActive}
                            metricFalse={__(
                                'get-phone-number.active-number-no-metrics',
                            )}
                            metricTrue={__(
                                'get-phone-number.active-number-yes-metrics',
                            )}
                            flow={''}
                        />
                        <p styleName="btn-msg">
                            {__('get-phone-number.active-number.message')}
                            <div styleName="link-porting-phone">
                                <InfoLink
                                    to={__('routes.porting-phone-number')}
                                    target="_blank"
                                >
                                    {__(
                                        'get-phone-number.porting-phone-number',
                                    )}
                                </InfoLink>
                            </div>
                        </p>
                    </Hidden>
                </div>
                <div styleName="col">
                    <div style={{ marginTop: '0px' }}>
                        {isPorting && deviceIsUnlocked && numberIsActive ? (
                            <PortNumber
                                onNext={this.props.onNext}
                                onEmptyCarrierList={this.noCarriersAvailable}
                            />
                        ) : isCreating && deviceIsUnlocked ? (
                            <NewNumber onNext={this.props.onNext} />
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }

    private readonly selectKeepCurrentNumber = (answer: boolean) => {
        this.setState({ isKeepingCurrentNumber: answer });
        this.props.clearCartErrors();
    };

    // private readonly selectDeviceUnlocked = (answer: boolean) => {
    //     const { showModal, redirect } = this.props;
    //
    //     this.setState({ isDeviceUnlocked: answer });
    //
    //     if (answer === false) {
    //         showModal({
    //             additionalProps: {
    //                 onButton: () => redirect('/'),
    //             },
    //             component: LockedDeviceError as any,
    //         });
    //     }
    // };

    private readonly selectNumberActive = (answer: boolean) => {
        const { showModal } = this.props;

        this.setState({ isNumberActive: answer });

        if (answer === false) {
            showModal({
                additionalProps: {
                    onButton: () =>
                        this.setState({
                            isDeviceUnlocked: true,
                            isKeepingCurrentNumber: false,
                            isNumberActive: null,
                        }), // don't keep number, yes device is unlocked, no answer
                } as IInactiveNumberErrorProps,
                component: InactiveNumberError as any,
            });
        }
    };

    private readonly noCarriersAvailable = () => {
        this.props.reportCarriersError();
        this.props.showModal({
            additionalProps: {
                onButton: () =>
                    this.setState({
                        isKeepingCurrentNumber: false,
                        isNumberActive: null,
                    }), // don't keep number, yes device is unlocked, no answer
            } as IIneligibleNumberErrorProps,
            component: IneligibleNumberError as any,
        });
    };
}

export { GetPhoneNumber };
