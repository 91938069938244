import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { NavLink } from 'components/common/typography';
import {
    TealiumFlowName,
    TealiumLinkEvents,
    TealiumLoginState,
    TealiumPageLinkLocations,
    TealiumPageType,
    TealiumPagevents,
} from 'models/tealium';
import { smellyStoreSingleton } from 'utils/store';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';
import { Link } from 'react-router-dom';

import { startPollEmailVerification } from '../../state/authentication/authentication.actions';

const styles = require('./nav-links.less');

interface Props {
    readonly isAuthenticated: boolean;
    readonly mobileExpanded: boolean;
    onAnyClick(): void;
    isCartPresent: boolean;
    signOut(): void;
}

@CSSModules(styles, { allowMultiple: true })
class NavLinks extends React.Component<Props> {
    render() {
        const {
            props: {
                isAuthenticated,
                mobileExpanded,
                onAnyClick,
                isCartPresent,
                signOut,
            },
        } = this;

        return (
            <ul styleName={classNames('links', { mobileExpanded })}>
                <div styleName="links-center">
                    <li>
                        <NavLink
                            onClick={async () => {
                                await this.tealiumEventShop();
                                await onAnyClick();
                            }}
                            to={__('routes.phones')}
                        >
                            {__('navigation.phones')}
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            onClick={async () => {
                                await this.tealiumEventPlan();
                                await onAnyClick();
                            }}
                            to={__('routes.plan')}
                        >
                            {__('navigation.plan')}
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            onClick={async () => {
                                await this.tealiumEventFaq();
                                await onAnyClick();
                                await this.triggerEmailVerification();
                            }}
                            to={__('routes.help')}
                        >
                            {__('navigation.help')}
                        </NavLink>
                    </li>
                    {isAuthenticated && (
                        <>
                            <li styleName="account-options">
                                <NavLink
                                    onClick={async () => {
                                        await this.tealiumEventSignIn();
                                        await onAnyClick();
                                    }}
                                    to={__('routes.account')}
                                >
                                    {__('navigation.account')}
                                </NavLink>
                            </li>
                            {false && <div styleName="link-divider" />}
                            <li styleName="account-options">
                                <NavLink
                                    onClick={async () => {
                                        await signOut();
                                        await onAnyClick();
                                    }}
                                >
                                    {__('navigation.sign-out')}
                                </NavLink>
                            </li>
                        </>
                    )}
                </div>
                {!isAuthenticated && (
                    <div styleName="links-right">
                        <li>
                            <NavLink
                                onClick={async () => {
                                    await this.tealiumEventSignIn();
                                    await onAnyClick();
                                }}
                                to={__('routes.sign-in')}
                            >
                                {__('navigation.sign-in')}
                            </NavLink>
                        </li>
                        <div styleName="link-divider" />
                        <li>
                            {isCartPresent ? (
                                <Link to={__('routes.cart')}>
                                    <img
                                        styleName="cart-icon"
                                        src={__('navigation.cart.present.icon')}
                                    />
                                </Link>
                            ) : (
                                <Link to={__('routes.cart')}>
                                    <img
                                        styleName="cart-icon"
                                        src={__('navigation.cart.default.icon')}
                                    />
                                </Link>
                            )}
                        </li>
                    </div>
                )}
            </ul>
        );
    }

    private readonly triggerEmailVerification = () => {
        const { dispatch } = smellyStoreSingleton.store;
        dispatch(startPollEmailVerification());
    };

    private readonly tealiumEventShop = () => {
        const page = window.location.pathname.replace('/', '');
        TealiumDispatcher.dispatchEvent(
            TealiumFlowName.NONE,
            page.indexOf('promo') >= 0 ? 'mastercard_promo' : page,
            TealiumPagevents.TOP_SHOP,
            TealiumPageLinkLocations.TOP_NAV,
            TealiumLinkEvents.TEXT_LINK,
            this.props.isAuthenticated
                ? TealiumLoginState.LOGGED_IN
                : TealiumLoginState.LOGGED_OUT,
        );
    };
    private readonly tealiumEventPlan = () => {
        const page = window.location.pathname.replace('/', '');
        TealiumDispatcher.dispatchEvent(
            TealiumFlowName.NONE,
            page,
            TealiumPagevents.TOP_PLAN,
            TealiumPageLinkLocations.TOP_NAV,
            TealiumLinkEvents.TEXT_LINK,
            this.props.isAuthenticated
                ? TealiumLoginState.LOGGED_IN
                : TealiumLoginState.LOGGED_OUT,
        );
        TealiumDispatcher.dispatchView(
            TealiumFlowName.NONE,
            TealiumPageType.PLAN,
            this.props.isAuthenticated
                ? TealiumLoginState.LOGGED_IN
                : TealiumLoginState.LOGGED_OUT,
        );
    };
    private readonly tealiumEventFaq = () => {
        const page = window.location.pathname.replace('/', '');
        TealiumDispatcher.dispatchEvent(
            TealiumFlowName.NONE,
            page,
            TealiumPagevents.TOP_HELP,
            TealiumPageLinkLocations.TOP_NAV,
            TealiumLinkEvents.TEXT_LINK,
            this.props.isAuthenticated
                ? TealiumLoginState.LOGGED_IN
                : TealiumLoginState.LOGGED_OUT,
        );
        TealiumDispatcher.dispatchView(
            TealiumFlowName.NONE,
            TealiumPageType.Help,
            this.props.isAuthenticated
                ? TealiumLoginState.LOGGED_IN
                : TealiumLoginState.LOGGED_OUT,
        );
    };
    private readonly tealiumEventSignIn = () => {
        const page = window.location.pathname.replace('/', '');
        TealiumDispatcher.dispatchEvent(
            TealiumFlowName.NONE,
            page,
            TealiumPagevents.TOP_SIGNIN,
            TealiumPageLinkLocations.TOP_NAV,
            TealiumLinkEvents.TEXT_LINK,
        );
    };
}

export { NavLinks };
