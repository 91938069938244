import * as React from 'react';
import * as CSSModules from 'react-css-modules';

// import { Hidden } from 'components/common/hidden/hidden';
import { BodyCopy } from '../../common/typography';

const styles = require('./referral-message.less');

interface Props {
    readonly refCode: string;
}

@CSSModules(styles, { allowMultiple: true })
class ReferralMessage extends React.Component<Props> {
    render() {
        const {
            props: { refCode },
        } = this;
        if (!refCode) {
            return null;
        }

        return (
            <div>
                <BodyCopy noMargin center hasRefCode>
                    {__('account.referral-message')}&nbsp;
                    <BodyCopy blue inline>
                        {refCode}
                    </BodyCopy>
                </BodyCopy>
            </div>
        );
    }
}
export { ReferralMessage };
