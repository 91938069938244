import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import {
    ButtonContainer as Button,
    ModalWrapperContainer as ModalWrapper,
} from 'containers';
import { IModalProps } from 'models';
import { CPNI } from '../cpni';

const styles = require('./privacy-opt-in-confirm.less');

interface Props extends IModalProps {}

@CSSModules(styles)
class PrivacyOptInConfirm extends React.Component<Props> {
    render() {
        const {
            props: { bindHandleClickWith, onConfirm },
        } = this;
        return (
            <ModalWrapper closable title={__('account.privacy.cpni.header')}>
                <>
                    <div styleName="modal-inner-wrap">
                        <div styleName="scroll-section">
                            <CPNI />
                        </div>
                        <div styleName="modal-options">
                            <Button
                                styleName="button"
                                onClick={bindHandleClickWith(onConfirm)}
                                label={__('agree')}
                            />
                        </div>
                    </div>
                </>
            </ModalWrapper>
        );
    }
}

export { PrivacyOptInConfirm };
