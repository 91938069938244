import { SignupSuccess } from 'components/account/signup-success';
import { FetchStatus } from 'lib/br-redux';
import { IApplicationState, IDeviceCompatibilityPayload } from 'models';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { checkDevice } from 'state/device/compatibility/compatibility.actions';

import { withAnalytics } from 'components/common/analytics';

const mapStateToProps = ({
    authentication,
    account,
    device,
    referral,
    registration,
}: IApplicationState) => ({
    isSignedIn: authentication.fetchStatus === FetchStatus.SUCCESS,
    account,
    device,
    shortCode: referral.shortCode,
    registration: registration,
    validateShortCodeResponse: referral.validateShortCodeResponse,
});
const mapDispatchToProps = dispatch => ({
    checkDevice: (payload: IDeviceCompatibilityPayload) =>
        dispatch(checkDevice(payload)),
    redirectTo: (url: string) => dispatch(push(url)),
});

const enhance = compose(
    withAnalytics,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const SignupSuccessContainer = enhance(SignupSuccess);
export { SignupSuccessContainer };
