import { withDeviceType } from 'components/common/device-type';
import { Navigation } from 'components/navigation';
import { FetchStatus } from 'lib/br-redux';
import { Account, IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AuthenticationActions } from 'state/authentication';
import { toggleSidebarCart } from 'state/view/view.actions';
import { HandsetCartItem, ProductType } from '../models';
import { startPollEmailVerification } from '../state/authentication/authentication.actions';
import { checkCart } from '../state/account/account.actions';

const mapStateToProps = ({
    account,
    authentication,
    purchase: {
        cart: { result: cart },
    },
}: IApplicationState) => ({
    account: new Account(account),
    device: cart
        ? (cart.itemForProductType(ProductType.HANDSET) as HandsetCartItem)
        : null,
    isAuthenticated: authentication.fetchStatus === FetchStatus.SUCCESS,
});

const mapDispatchToProps = dispatch => ({
    signOut: () => dispatch(AuthenticationActions.signOut()),
    toggleSidebarCart: () => dispatch(toggleSidebarCart()),
    startPollEmailVerification: () => dispatch(startPollEmailVerification()),
    checkCart: () => dispatch(checkCart()),
});

const enhance = compose(
    withDeviceType,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const NavigationContainer = enhance(Navigation);
export { NavigationContainer };
