import { Feedback } from 'components/feedback';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { FeedbackActions } from 'state/feedback';
import { push } from 'react-router-redux';

const mapStateToProps = (state: IApplicationState) => ({
    error: state.feedback.error,
    fetchStatus: state.feedback.fetchStatus,
});

const mapDispatchToProps = dispatch => ({
    submit: (msg: string) => dispatch(FeedbackActions.submitFeedback(msg)),
    redirectTo: (url: string) => dispatch(push(url)),
});

export const FeedbackContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Feedback);
