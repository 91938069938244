import { debounce } from 'lodash';
import { IDeviceTypeProps } from 'models';
import * as React from 'react';
import { getComponentDisplayName } from 'utils';

const mobileBreakpoint = 768;

export const withDeviceType = WrappedComponent => {
    class WithDeviceType extends React.Component<any, IDeviceTypeProps> {
        // tslint:disable:readonly-keyword
        private debounced;
        constructor(props) {
            super(props);
            this.state = this.deviceState;
        }

        componentWillMount() {
            this.debounced = debounce(this.handleWindowSizeChange, 150);
            window.addEventListener('resize', this.debounced);
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.debounced);
            this.debounced.cancel();
        }

        handleWindowSizeChange = () => {
            this.setState(this.deviceState);
        };

        static get displayName() {
            return `WithDeviceType(${getComponentDisplayName(
                WrappedComponent,
            )})`;
        }
        render() {
            const {
                props,
                state: { isDesktop, isMobile, windowWidth },
            } = this;
            return (
                <WrappedComponent
                    isDesktop={isDesktop}
                    isMobile={isMobile}
                    windowWidth={windowWidth}
                    {...props}
                />
            );
        }
        private get deviceState() {
            return {
                isDesktop: window.innerWidth > mobileBreakpoint,
                isMobile: window.innerWidth <= mobileBreakpoint,
                windowWidth: window.innerWidth,
            };
        }
    }
    return WithDeviceType;
};
