import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { DelayTransition } from 'components/delay-transition';
import { LoadingIndicator } from 'components/loading-indicator';
import { PromoBanner } from 'components/promo-banner';
import { ScrollRestore } from 'components/scroll-restore';
import { WipeTransition } from 'components/wipe-transition';
import {
    FooterContainer as Footer,
    HeadContainer as Head,
    MessageBarContainer as MessageBar,
    ModalManagerContainer as ModalManager,
    NoticePresenterContainer as NoticePresenter,
} from 'containers';
import { NavigationContainer } from 'containers/navigation.container';
import { CustomerState } from 'models';
import { Route } from 'react-router-dom';
import routes from 'routes';
import { disableBodyScroll, Toggles, enableBodyScroll } from 'utils';
import { IConfiguration } from 'models';
import {
    configurePrechatSettings,
    populateFields,
    getAccountFromStorage,
    reloadLiveChatByNavigation,
} from 'state/configuration/liveChat';
import IdleTimer from 'react-idle-timer';

const styles = require('./app.less');

interface Props {
    readonly account: any;
    readonly customerState: CustomerState;
    readonly authenticated: boolean;
    readonly hasModals: boolean;
    readonly configuration: IConfiguration;
    readonly initializing: boolean;
    readonly location: any;
    readonly userSessionTimeout: number;
    refreshExcToken(): void;
    signOut(): void;
    checkCart(): void;
}

interface State {
    readonly isTransitioning: boolean;
    readonly didBlockInitialTransition: boolean;
    readonly prevPath: string;
    readonly refreshCalled: boolean;
}

const routeDelay = 500;
let idleTimer: IdleTimer;
@CSSModules(styles)
class App extends React.Component<Props, State> {
    // tslint:disable:readonly-keyword
    targetElement;
    constructor(props: Props) {
        super(props);
        this.state = {
            didBlockInitialTransition: false,
            isTransitioning: false,
            prevPath: '',
            refreshCalled: false,
        };
    }
    componentWillMount() {
        // clearing the session storage set for previous timer when component is mounted
        if (sessionStorage.getItem('PreviousExpTimer')) {
            sessionStorage.removeItem('PreviousExpTimer');
        }
    }
    componentDidUpdate(_, prevProps) {
        const {
            props: { hasModals, initializing, refreshExcToken, authenticated },
        } = this;
        if (this.targetElement) {
            if (initializing) {
                disableBodyScroll(this.targetElement);
            } else {
                if (!hasModals) {
                    enableBodyScroll(this.targetElement);
                }
            }
        }
        // Code for refreshing the token
        if (authenticated) {
            const ExchangeTokenExpiration: any = sessionStorage.getItem(
                'ExchangeTokenExpiration',
            );
            const ExchangeToken: any = sessionStorage.getItem('ExchangeToken');
            const previousTimer: any = sessionStorage.getItem(
                'PreviousExpTimer',
            );
            if (ExchangeToken && ExchangeTokenExpiration) {
                const currentTime: any = new Date().getTime();
                if (
                    previousTimer !== ExchangeTokenExpiration &&
                    ExchangeTokenExpiration >= currentTime + 180 * 1000
                ) {
                    // Refreshing the token two minutes before it is expired
                    setTimeout(() => {
                        refreshExcToken();
                        setTimeout(
                            () => this.setState({ refreshCalled: true }),
                            1000,
                        );
                    }, ExchangeTokenExpiration - currentTime - 120000);
                    sessionStorage.setItem(
                        'PreviousExpTimer',
                        ExchangeTokenExpiration,
                    );
                }
            }
        }
    }
    componentWillReceiveProps(newProps) {
        // if (newProps.configuration !== undefined) {
        //   this.setState({
        //     userSessionTimeout: newProps.configuration.user_session_timeout
        //   });
        // }
        newProps.history.listen((location, action) => {
            // location is an object like window.location
            if (this.props.location.pathname !== location.pathname) {
              reloadLiveChatByNavigation();
              const account = getAccountFromStorage();
                const chatConfigInfo: any = sessionStorage.getItem(
                    'chatConfig',
                );
                const parsedChatConfig = JSON.parse(chatConfigInfo);
                if (account) {
                    console.log('(web)i am in if condition at user logged in');
                    populateFields(JSON.parse(account), parsedChatConfig);
                } else {
                    console.log('(web)i am in else block');
                    configurePrechatSettings(JSON.parse(chatConfigInfo));
                    console.log('(web)i am in else block, moving out');
                }
            }
            console.log(action, location.pathname, location.state);
        });
    }
    componentDidMount() {
        this.targetElement = document.querySelector('#app-wrapper');
    }

    showBanner = (authenticated, pathName) => {
        return (
            !authenticated &&
            pathName !== __('routes.promo-url') &&
            pathName !== __('routes.swap')
        );
    };

    showRefCode = account => {
        const { configuration } = this.props;
        return (
            account &&
            account.refCode !== '' &&
            Toggles.isReferral(configuration.toggles)
        );
    };

    render(): JSX.Element {
        const {
            authenticated,
            customerState,
            initializing,
            userSessionTimeout,
            signOut,
        } = this.props;
        const { isTransitioning } = this.state;

        return (
            <IdleTimer
                ref={ref => {
                    idleTimer = ref;
                }}
                element={document}
                onIdle={signOut}
                onAction={() => idleTimer.reset()}
                timeout={userSessionTimeout}
            >
                <div styleName="app-wrapper" id="app-wrapper">
                    <Head />
                    <NoticePresenter />
                    <NavigationContainer />
                    {this.showBanner(authenticated, location.pathname) && (
                        <PromoBanner />
                    )}
                    <LoadingIndicator visible={initializing} />
                    <main id="main-content" styleName="app-wrapper">
                        <Route
                            render={({ location }) => (
                                <ScrollRestore
                                    location={location}
                                    delay={routeDelay}
                                >
                                    <DelayTransition
                                        transitionKey={location.key || ''}
                                        delayFor={routeDelay}
                                        onStart={this.startTransition}
                                        onEnd={this.endTransition}
                                    >
                                        {routes({
                                            authenticated,
                                            customerState,
                                            initializing,
                                            location,
                                        })}
                                    </DelayTransition>
                                </ScrollRestore>
                            )}
                        />
                    </main>
                    <Footer />

                    {/* floaty stuff */}
                    <MessageBar />
                    <ModalManager />
                    <WipeTransition active={isTransitioning} />
                </div>
            </IdleTimer>
        );
    }

    private readonly startTransition = () => {
        if (this.state.didBlockInitialTransition) {
            this.setState({ isTransitioning: true });
        } else {
            this.setState({ didBlockInitialTransition: true });
        }
    };

    private readonly endTransition = () => {
        setTimeout(() => this.setState({ isTransitioning: false }), 100);
    };
}

export { App };
