import { FAQResponse, IApplicationState } from 'models';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { FAQService } from 'services';
import { trackError } from 'state/tealium';
import {
    ActionType,
    reportFAQFetchError,
    reportFAQFetchSuccess,
    reportFAQUpToDate,
} from './faq.actions';

function* fetchFAQ() {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const currentVersion = yield select(
            (state: IApplicationState) => state.faq.version,
        );
        const response: FAQResponse | undefined = yield call(
            FAQService.fetchTopics,
            basePaths,
            currentVersion,
        );
        if (response) {
            yield put(reportFAQFetchSuccess(response));
        } else {
            yield put(reportFAQUpToDate());
        }
    } catch (err) {
        yield put(reportFAQFetchError((err as Error).message));
    }
}

function* faqSaga() {
    yield* [
        takeEvery(ActionType.FETCH_FAQ, fetchFAQ),
        takeEvery(ActionType.REPORT_FAQ_FETCH_ERROR, trackError),
    ];
}

export { faqSaga };
