import { withAnalytics } from 'components/common/analytics';
import { withInputValidation } from 'components/common/forms/input';
import { Address } from 'components/sign-up/components/address/address';
import { Account, IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateUserAccount } from 'state/account';

const dataKeys: ReadonlyArray<string> = [
    'shippingAddress',
    'shippingAddressStreetLineTwo',
    'homeAddress',
    'homeAddressStreetLineTwo',
];

const mapStateToProps = (state: IApplicationState) => ({
    updateAccountError: state.account.updateAccountError,
    updateAccountStatus: state.account.updateAccountFetchStatus,
    user: new Account(state.account),
});

const mapDispatchToProps = dispatch => ({
    updateAccount: account => dispatch(updateUserAccount(account)),
});

const enhance = compose(
    withAnalytics,
    withInputValidation(dataKeys),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const AddressContainer = enhance(Address);

export { AddressContainer };
