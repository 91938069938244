import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';
import { Dots } from 'components/common/dots';
import { CheckboxOption } from 'components/common/forms';
import { ErrorMsg } from 'components/common/typography/error-msg';
import { InfoLink } from 'components/common/typography/info-link';
import { FetchStatus } from 'lib/br-redux';
import { uuidv4 } from 'utils';

const styles = require('./autopay-checkbox.less');

interface IPropsFromState {
    readonly authStatus: FetchStatus;
    readonly fetchStatus: FetchStatus;
    readonly status: boolean;
}

interface IPropsFromDispatch {
    getAutopayStatus(): void;
    setAutopayStatus(status: boolean): void;
    showAutopayTerms(): void;
}

type IProps = IPropsFromState & IPropsFromDispatch & IAnalyticsProps;

@CSSModules(styles)
class AutopayCheckbox extends React.Component<IProps, {}> {
    // tslint:disable:variable-name
    // tslint:disable:readonly-keyword
    private _id: string;
    private intervalId?: number;
    // tslint:enable

    componentDidMount() {
        if (this.props.fetchStatus === FetchStatus.NOT_FETCHED) {
            this.startPolling();
        }
    }

    render() {
        switch (this.props.fetchStatus) {
            case FetchStatus.SUCCESS:
                return this.checkbox;
            case FetchStatus.ERROR:
                return this.error;
            default:
                return <Dots invert />;
        }
    }

    private get error(): JSX.Element {
        return <ErrorMsg>{__('autopay-checkbox.error')}</ErrorMsg>;
    }

    private get checkbox(): JSX.Element {
        const {
            onCheckboxChange,
            onShowAutopayTerms,
            props: { status },
        } = this;
        return (
            <CheckboxOption
                id={this.id}
                checked={status}
                onChange={onCheckboxChange}
                blue
                autoCheckBox
                marginTop50
                autoCheckBoxSpan
                mobileFont
            >
                <>
                    {__('autopay-checkbox.default_label')}
                    <InfoLink
                        fontCircular
                        mobileFont
                        onClick={onShowAutopayTerms}
                    >
                        {__('autopay-checkbox.link-text')}
                    </InfoLink>
                </>
            </CheckboxOption>
        );
    }

    private readonly onShowAutopayTerms = (e): void => {
        e.stopPropagation();
        this.props.showAutopayTerms();
    };

    private readonly onCheckboxChange = (checked: boolean): void => {
        this.props.setAutopayStatus(checked);
    };

    private readonly startPolling = () => {
        this.intervalId = window.setInterval(this.tryGetAutopayStatus, 500);
    };
    private readonly tryGetAutopayStatus = () => {
        const { authStatus, fetchStatus } = this.props;
        if (authStatus === FetchStatus.SUCCESS) {
            if (fetchStatus === FetchStatus.NOT_FETCHED) {
                this.props.getAutopayStatus();
            }

            this.clearInterval();
        }
    };

    private readonly clearInterval = () => {
        if (typeof this.intervalId !== 'undefined') {
            clearInterval(this.intervalId);
            this.intervalId = undefined;
        }
    };

    private get id(): string {
        if (!this._id) {
            // apply a UUID to id so that multiple instances of this component
            // can exist on the same page
            this._id = `enrollInAutopay_${uuidv4()}`;
        }

        return this._id;
    }
}

export { AutopayCheckbox };
