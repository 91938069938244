import { BaseResubmissionForm } from 'components/port-fallout/base-resubmission-form';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    resubmitAccountNumber,
    resubmitPhoneNumber,
    resubmitPIN,
} from 'state/purchase/port/resubmission/resubmission.actions';

const mapStateToProps = ({ purchase }: IApplicationState) => ({
    error: purchase.port.resubmission.error,
    fetchStatus: purchase.port.resubmission.fetchStatus,
});

const mapDispatchToProps = dispatch => ({
    resubmitAccountNumber: p => dispatch(resubmitAccountNumber(p)),
    resubmitPIN: p => dispatch(resubmitPIN(p)),
    resubmitPhoneNumber: p => dispatch(resubmitPhoneNumber(p)),
});

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const BaseResubmissionFormContainer = enhance(BaseResubmissionForm);

export { BaseResubmissionFormContainer };
