import { find } from 'lodash';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./error-msg.less');

interface IProps {
    readonly children?: React.ReactNode;
}

const remappedErrorMessages = {
    'Failed to fetch': __('default_service_error'),
};

const remapErrorMessage = message =>
    find(remappedErrorMessages, (value, key) => key === message) || message;

const ErrorMsgComponent: React.StatelessComponent<IProps> = ({
    children,
}: IProps) =>
    children ? (
        <span styleName="error-msg">{remapErrorMessage(children)}</span>
    ) : null;

const ErrorMsg = CSSModules(ErrorMsgComponent, styles, { allowMultiple: true });
export { ErrorMsg };
