import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./prospective.less');

@CSSModules(styles, { allowMultiple: true })
class Prospective extends React.Component<{}> {
    render() {
        return <>{(window.location.href = __('routes.home'))}</>;
    }
}

export { Prospective };
