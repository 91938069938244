enum ChannelModel {
    WEB = 'Desktop',
    ANDROID = 'MWAndroid',
    IOS = 'MWios',
    DEVICE_IOS = 'iOS',
    DEVICE_ANDROIDOS = 'AndroidOS',
}

enum OMNI_SESSION_KEYS {
    firebaseToken = 'firebaseToken',
    carrier = 'DeviceCarrier',
    os = 'DeviceOS',
    model = 'DeviceModel',
    imei = 'Imei',
    shortCode = 'shortCode',
    campaignId = 'campaignId',
    swap = 'swap',
}

enum CLASS_CODE_LABELS {
    SVC = 'Visible Service',
    EQP = 'Phone',
    'EQP-DP' = 'Phone (Financed)',
    FEA = 'Visible Protect',
    'REF-CR' = 'Referral Credits',
    NONRTNFEE = 'Non-return Fee',
    DEFAULT = 'Visible',
}

enum CLASS_CODES {
    SVC = 'SVC',
    EQP = 'EQP',
    'EQP-DP' = 'EQP-DP',
    FEA = 'FEA',
    'REF-CR' = 'REF-CR',
    NONRTNFEE = 'NONRTNFEE',
}

export { ChannelModel, OMNI_SESSION_KEYS, CLASS_CODE_LABELS, CLASS_CODES };
