import { withAnalytics } from 'components/common/analytics';
import { withInputValidation } from 'components/common/forms';
import { DeviceCheck } from 'components/device-check';
import { IApplicationState, IDeviceCompatibilityPayload } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { showAccountSignupModal } from 'state/account';
import {
    checkDevice,
    clearCheckDevice,
} from 'state/device/compatibility/compatibility.actions';
import { captureLead } from 'state/lead/lead.actions';

const dataKeys: ReadonlyArray<string> = ['email', 'imei'];

const mapStateToProps = ({
    lead,
    device: {
        compatibility: { fetchStatus },
    },
}: IApplicationState) => ({
    deviceCompatibilityStatus: fetchStatus,
    leadError: lead.error,
    leadStatus: lead.fetchStatus,
});

const mapDispatchToProps = dispatch => ({
    captureLead: data => dispatch(captureLead(data)),
    checkDevice: (payload: IDeviceCompatibilityPayload) =>
        dispatch(checkDevice(payload)),
    clearCheckDevice: () => dispatch(clearCheckDevice()),
    showAccountSignupModal: () => dispatch(showAccountSignupModal()),
});

const enhance = compose(
    withAnalytics,
    withInputValidation(dataKeys),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);

const DeviceCheckContainer = enhance(DeviceCheck);

export { DeviceCheckContainer };
