import * as classNames from 'classnames';
import * as moment from 'moment';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { RouteComponentProps } from 'react-router-dom';

import { ContentWrapper } from 'components/common/content-wrapper';
import { Divider } from 'components/common/divider';
import { Dots } from 'components/common/dots';
import { Chevron } from 'components/common/graphics';
import { Hidden } from 'components/common/hidden';
import {
    BodyCopy,
    Col,
    Headline,
    InfoLink,
    Row /*, Subhead*/,
} from 'components/common/typography';
// import { ButtonContainer as Button } from 'containers';
import { FetchStatus } from 'lib/br-redux';
import {
    DetailedChargeEvent,
    FetchedChargeEventDetails,
    IModalProps,
    TaxItem,
    UnfetchedChargeEventDetails,
} from 'models';
import { CLASS_CODES } from 'models/constants';

const styles = require('./bill-details.less');

interface Props
    extends IModalProps,
        DetailedChargeEvent,
        RouteComponentProps<any> {
    readonly account: Account;
    readonly chargeEvents: ReadonlyArray<DetailedChargeEvent>;
    fetchBillHistory(): void;
    fetchDetails(eventId: string): void;
}

@CSSModules(styles, { allowMultiple: true })
class BillDetails extends React.Component<Props> {
    componentDidMount() {
        const {
            chargeEvents,
            detailStatement,
            match: {
                params: { eventid },
            },
            fetchBillHistory,
            fetchDetails,
        } = this.props;
        if (chargeEvents.length === 0) {
            fetchBillHistory();
        }
        if (
            !detailStatement ||
            !detailStatement.fetchStatus ||
            new Set([FetchStatus.NOT_FETCHED, FetchStatus.ERROR]).has(
                detailStatement.fetchStatus,
            )
        ) {
            fetchDetails(eventid);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            chargeEvents,
            match: {
                params: { eventid },
            },
            fetchDetails,
        } = this.props;
        if (
            prevProps.chargeEvents.length !== chargeEvents.length &&
            chargeEvents.length > 0
        ) {
            fetchDetails(eventid);
        }
    }

    render() {
        const { detailStatement } = this.props;
        if (!detailStatement || !detailStatement.fetchStatus) {
            return null;
        }
        const detailsAreReady =
            detailStatement.fetchStatus === FetchStatus.SUCCESS;
        const detailsAreBeingFetched = new Set([
            FetchStatus.NOT_FETCHED,
            FetchStatus.FETCHING,
        ]).has(detailStatement.fetchStatus);
        const detailsDidError =
            detailStatement.fetchStatus === FetchStatus.ERROR;

        return (
            <>
                <Hidden until={detailsDidError}>
                    <BodyCopy>
                        {(detailStatement as UnfetchedChargeEventDetails)
                            .error || __('default-error-message')}
                    </BodyCopy>
                </Hidden>
                <Hidden until={detailsAreBeingFetched}>
                    <Dots invert />
                </Hidden>
                <Hidden until={detailsAreReady}>
                    {this.details(detailStatement as FetchedChargeEventDetails)}
                </Hidden>
                {/*
                  <Button
                      styleName="button"
                      onClick={onClose}
                      label={__('OK')}
                  />
                  */}
            </>
        );
    }

    private details(
        chargeDetails: FetchedChargeEventDetails,
    ): JSX.Element | null {
        const {
            eventtime,
            paymentmethodtype,
            paymentmethoddetails,
            details,
        } = this.props;
        if (chargeDetails.fetchStatus === FetchStatus.SUCCESS) {
            const grossPrice: number = chargeDetails.details.reduce(
                (acc, item) => {
                    acc += parseFloat(item.discountPrice);
                    return acc;
                },
                0,
            );
            return (
                <>
                    <ContentWrapper>
                        <Row>
                            <Col width={3} mobileWidth={6}>
                                <InfoLink
                                    styleName="bill-details-back-link-wrapper"
                                    plain
                                    to={`${__('routes.account.bill-history')}`}
                                >
                                    <BodyCopy blue flex>
                                        <Chevron blue left />
                                        {__('bill_details.payment-history')}
                                    </BodyCopy>
                                </InfoLink>
                            </Col>
                            <Col width={6} mobileWidth={6}>
                                <BodyCopy grey>
                                    {moment(eventtime).format('MMM D, YYYY')}
                                </BodyCopy>
                            </Col>
                        </Row>
                    </ContentWrapper>

                    <Divider small />

                    <ContentWrapper>
                        <Row>
                            <Col width={3} mobileWidth={12}>
                                <Headline medium three dark>
                                    {__('bill_details.charges')}
                                </Headline>
                            </Col>
                            <Col width={9} mobileWidth={12}>
                                {chargeDetails.details.map((detail, idx) => (
                                    <>
                                        {idx > 0 ? <Divider medium /> : ''}
                                        <Row mobileFlex>
                                            {detail.displayName ? (
                                                <Col width={7} mobileWidth={9}>
                                                    <BodyCopy
                                                        bold
                                                        flex
                                                        columnSubtitle
                                                    >
                                                        {detail.displayName}
                                                    </BodyCopy>
                                                </Col>
                                            ) : (
                                                <Col width={7} mobileWidth={9}>
                                                    <Headline medium three dark>
                                                        {__(
                                                            'bill_details.visible',
                                                        )}
                                                    </Headline>
                                                </Col>
                                            )}
                                            <Col width={1} mobileWidth={3}>
                                                <BodyCopy
                                                    bold
                                                    right
                                                    columnSubtitle
                                                >
                                                    {this.currency(
                                                        detail.grossPrice,
                                                    )}
                                                </BodyCopy>
                                            </Col>
                                        </Row>
                                        {detail.sumTaxItemList.length >= 0 ? (
                                            <Col width={7} mobileWidth={9}>
                                                {detail.classCode !==
                                                    CLASS_CODES.EQP && (
                                                    <BodyCopy columnDescription>
                                                        {detail.classCode ===
                                                            CLASS_CODES.SVC && (
                                                            <BodyCopy
                                                                columnDescription
                                                            >
                                                                {__(
                                                                    'bill_details.service',
                                                                )}
                                                            </BodyCopy>
                                                        )}
                                                        {detail.redeemedPromoCredits && (
                                                            <BodyCopy
                                                                columnDescription
                                                            >
                                                                {__(
                                                                    'bill_details.new.member.discount',
                                                                )}
                                                            </BodyCopy>
                                                        )}
                                                        {detail.redeemedReferralCredits && (
                                                            <BodyCopy
                                                                columnDescription
                                                            >
                                                                {__(
                                                                    'bill_details.referral',
                                                                )}
                                                            </BodyCopy>
                                                        )}
                                                        {detail.redeemedGroupCredits && (
                                                            <BodyCopy
                                                                columnDescription
                                                            >
                                                                {__(
                                                                    'bill_details.party.pay.discount',
                                                                ).replace(
                                                                    '{GROUP_MEMBERS}',
                                                                    Number(
                                                                        detail.redeemedGroupCredits,
                                                                    ) /
                                                                        -5 +
                                                                        1,
                                                                )}
                                                            </BodyCopy>
                                                        )}
                                                        {detail.redeemedGoodWillCredits && (
                                                            <BodyCopy
                                                                columnDescription
                                                            >
                                                                {__(
                                                                    'bill_details.reward',
                                                                )}
                                                            </BodyCopy>
                                                        )}
                                                        {detail.gl_Visible_Device_Extended_Warranty_Offer && (
                                                            <BodyCopy
                                                                columnDescription
                                                            >
                                                                {__(
                                                                    'bill_details.warranty',
                                                                )}
                                                            </BodyCopy>
                                                        )}
                                                        {detail.classCode !==
                                                            CLASS_CODES.FEA &&
                                                            detail.classCode !==
                                                                CLASS_CODES.SVC &&
                                                            __(
                                                                'bill_details.visible-service-contract',
                                                            )}
                                                    </BodyCopy>
                                                )}
                                                {detail.classCode !==
                                                    CLASS_CODES.EQP &&
                                                    detail.gl_Visible_Device_Insurance_Offer && (
                                                        <BodyCopy
                                                            columnDescription
                                                        >
                                                            {__(
                                                                'bill_details.visible-insurance',
                                                            )}
                                                        </BodyCopy>
                                                    )}
                                                <BodyCopy columnDescription>
                                                    {__(
                                                        'bill_details.taxes-and-fees',
                                                    )}
                                                </BodyCopy>
                                                {detail.sumTaxItemList.map(
                                                    this.mapTaxToTableRow(
                                                        'LABEL',
                                                    ),
                                                )}
                                                <BodyCopy columnDescription>
                                                    {}
                                                </BodyCopy>
                                            </Col>
                                        ) : (
                                            ''
                                        )}

                                        {detail.sumTaxItemList.length >= 0 ? (
                                            <Col width={1} mobileWidth={3}>
                                                <BodyCopy
                                                    columnDescription
                                                    right
                                                >
                                                    {detail.classCode !==
                                                        CLASS_CODES.EQP &&
                                                        (detail.classCode ===
                                                        CLASS_CODES.SVC
                                                            ? this.currency(
                                                                  detail.modifiedDisplayNetRevenue ||
                                                                      detail.totalNetRevenue ||
                                                                      detail.gl_Visible_Device_Extended_Warranty_Offer,
                                                              )
                                                            : '')}
                                                </BodyCopy>
                                                {detail.redeemedPromoCredits && (
                                                    <BodyCopy
                                                        columnDescription
                                                        right
                                                    >
                                                        {this.currency(
                                                            detail.redeemedPromoCredits,
                                                        )}
                                                    </BodyCopy>
                                                )}
                                                {detail.redeemedReferralCredits && (
                                                    <BodyCopy
                                                        columnDescription
                                                        right
                                                    >
                                                        {this.currency(
                                                            detail.redeemedReferralCredits,
                                                        )}
                                                    </BodyCopy>
                                                )}
                                                {detail.redeemedGroupCredits && (
                                                    <BodyCopy
                                                        columnDescription
                                                        right
                                                    >
                                                        {this.currency(
                                                            detail.redeemedGroupCredits,
                                                        )}
                                                    </BodyCopy>
                                                )}
                                                {detail.redeemedGoodWillCredits && (
                                                    <BodyCopy
                                                        columnDescription
                                                        right
                                                    >
                                                        {this.currency(
                                                            detail.redeemedGoodWillCredits,
                                                        )}
                                                    </BodyCopy>
                                                )}
                                                {detail.classCode !==
                                                    CLASS_CODES.EQP &&
                                                    detail.classCode !==
                                                        CLASS_CODES.SVC &&
                                                    detail.gl_Visible_Device_Insurance_Offer && (
                                                        <BodyCopy
                                                            columnDescription
                                                            right
                                                        >
                                                            {this.currency(
                                                                detail.gl_Visible_Device_Insurance_Offer,
                                                            )}
                                                        </BodyCopy>
                                                    )}

                                                <BodyCopy
                                                    columnDescription
                                                    right
                                                >
                                                    {this.totalTaxesAndFees(
                                                        detail.totalTaxAmount,
                                                        detail.totalFeeAmount ||
                                                            detail.totalFeeAmount,
                                                    )}
                                                </BodyCopy>
                                                {detail.sumTaxItemList.map(
                                                    this.mapTaxToTableRow(
                                                        'DATA',
                                                    ),
                                                )}
                                                <BodyCopy
                                                    columnDescription
                                                    right
                                                >
                                                    {}
                                                </BodyCopy>
                                            </Col>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ))}
                            </Col>
                        </Row>
                    </ContentWrapper>
                    <Divider />
                    <ContentWrapper>
                        <Row>
                            <Col width={3} mobileWidth={12}>
                                <Headline medium three dark>
                                    {__('bill_details.total-due')}
                                </Headline>
                            </Col>

                            <Col width={5} mobileWidth={6}>
                                {paymentmethodtype !== undefined ? (
                                    <BodyCopy columnSubtitle>
                                        {__(
                                            'bill_details.paid-with-visa-ending-in',
                                        ).replace(
                                            '${paymentmethodtype}',
                                            paymentmethodtype,
                                        )}{' '}
                                        {paymentmethoddetails}
                                    </BodyCopy>
                                ) : (
                                    ''
                                )}
                            </Col>

                            <Col width={1} mobileWidth={6}>
                                <BodyCopy bold columnSubtitle right>
                                    {this.currency(grossPrice)}
                                </BodyCopy>
                            </Col>
                        </Row>
                    </ContentWrapper>

                    <ContentWrapper>
                        <Row>
                            <Col width={3} mobileWidth={12} />
                            <Col width={6} mobileWidth={12}>
                                {paymentmethodtype !== undefined &&
                                details !== 'Service' &&
                                chargeDetails.details.length > 0 &&
                                chargeDetails.details[0]
                                    .gl_Visible_Device_Extended_Warranty_Offer ? (
                                    <BodyCopy columnDescription>
                                        {__(
                                            'bill_details.tax-description',
                                        ).replace(
                                            '${price}',
                                            this.currency(
                                                chargeDetails.details[0]
                                                    .gl_Visible_Device_Extended_Warranty_Offer,
                                            ),
                                        )}
                                    </BodyCopy>
                                ) : (
                                    ''
                                )}
                                <BodyCopy columnDescription>
                                    {__('bill_details.privacy-notice')}

                                    <InfoLink
                                        to={`${__('routes.privacy')}`}
                                        target="_blank"
                                    >
                                        {__('bill_details.privacy')}
                                    </InfoLink>
                                </BodyCopy>
                            </Col>
                            <Col width={3} mobileWidth={12} />
                        </Row>
                    </ContentWrapper>
                </>
            );
            // return (
            //     <>
            //         <table styleName="table">
            //             <tbody>
            //                 <tr styleName="pad-bottom">
            //                     <td>
            //                         <strong>
            //                             {__('bill_details.taxes-and-fees')}
            //                         </strong>
            //                     </td>
            //                     <td styleName="currency">
            //                         <strong>
            //                             {/* todo(dan) this is already rounded */}
            //                             {this.totalTaxesAndFees(
            //                                 chargeDetails.totalTaxAmount,
            //                                 chargeDetails.totalFeeAmount ||
            //                                     chargeDetails.totalFeeAmout,
            //                             )}
            //                             {/* fee amount weirdness above is to cover for a typo from the server */}
            //                         </strong>
            //                     </td>
            //                 </tr>
            //                 {chargeDetails.sumTaxItemList.map(
            //                     this.mapTaxToTableRow,
            //                 )}
            //                 <tr styleName="pad-top">
            //                     <td>
            //                         <strong>
            //                             {account.userDevice &&
            //                             account.userDevice !== '' &&
            //                             chargeDetails.classCode !== 'SVC'
            //                                 ? account.userDevice
            //                                 : __('bill_details.service-plan')}
            //                         </strong>
            //                     </td>
            //                     <td styleName="currency">
            //                         <strong>
            //                             {/* todo(dan) modifiedDisplayNetRevenue provided to 2 decimals, */}
            //                             {/* but including totalNetRevenue in case it's null */}
            //                             {this.currency(
            //                                 chargeDetails.modifiedDisplayNetRevenue ||
            //                                     chargeDetails.totalNetRevenue,
            //                             )}
            //                         </strong>
            //                     </td>
            //                 </tr>
            //             </tbody>
            //         </table>
            //         <div styleName="total">
            //             <Subhead styleName="total-word">{__('total')}</Subhead>
            //             <Subhead styleName="total-number">
            //                 {this.currency(chargeDetails.grossPrice)}
            //             </Subhead>
            //         </div>
            //     </>
            // );
        }
        return null;
    }

    // private readonly mapTaxToTableRow = (
    //     taxItem: TaxItem,
    //     i: number,
    // ): JSX.Element => {
    //     return (
    //         <tr key={i}>
    //             <td>{taxItem.sumDescription}</td>
    //             <td styleName="currency">
    //                 <strong>{this.currency(taxItem.sumTaxAmount)}</strong>
    //             </td>
    //         </tr>
    //     );
    // };

    private readonly mapTaxToTableRow = (type: 'LABEL' | 'DATA') => (
        taxItem: TaxItem,
        i: number,
    ): JSX.Element => {
        if (taxItem.sumDescription !== undefined) {
            return (
                <p
                    key={`${type}_${i}`}
                    styleName={classNames(
                        'bill-details-column-wrapper-description',
                        { subItem: type === 'LABEL' },
                    )}
                >
                    {type === 'LABEL'
                        ? taxItem.sumDescription
                        : this.currency(taxItem.sumTaxAmount)}
                </p>
            );
        } else {
            return <p />;
        }
    };

    private currency(numString: string | number): string {
        const numStringAbs = Math.abs(Number(numString));
        return `${Number(numString) < 0 ? '-' : ''}$${(
            Number(100 * Number(numStringAbs)) / 100
        ).toFixed(2)}`;
    }

    private totalTaxesAndFees(
        numTaxesString: string,
        numFeesString: string,
    ): string {
        const totalAmount =
            Number(100 * (Number(numTaxesString) + Number(numFeesString))) /
                100 || 0;
        return this.currency(totalAmount);
    }
}

export { BillDetails };
