import * as React from 'react';

import { IAnalyticsProps } from 'models';
import { connect } from 'react-redux';
import { eventError, eventTealiumLink, eventTealiumView } from 'state/tealium';
import {
    EventPayloadTealiumLink,
    EventPayloadTealiumView,
} from 'state/tealium/tealium.actions';
import { getComponentDisplayName } from 'utils';

interface Props extends IAnalyticsProps {
    readonly children?: any;
}

const AnalyticsInjectorComponent = ({ children: Child, ...props }: Props) =>
    React.cloneElement(Child, props);

// tslint:disable:variable-name
const mapDispatchToProps = dispatch => ({
    trackError: (errorMessage: string, errorCode?: string) =>
        dispatch(eventError({ error_msg: errorMessage, error_cd: errorCode })),
    createTealiumLinkHandler: (payload: EventPayloadTealiumLink) => () =>
        dispatch(eventTealiumLink(payload)),
    createTealiumViewHandler: (payload: EventPayloadTealiumView) => () =>
        dispatch(eventTealiumView(payload)),
});
// tslint:enable:variable-name

const AnalyticsInjector = connect(
    null,
    mapDispatchToProps,
)(AnalyticsInjectorComponent);

const withAnalytics = WrappedComponent => {
    class WithAnalytics extends React.Component<any> {
        static get displayName() {
            return `WithAnalytics(${getComponentDisplayName(
                WrappedComponent,
            )})`;
        }

        render() {
            const { props } = this;
            return (
                <AnalyticsInjector>
                    <WrappedComponent {...props} />
                </AnalyticsInjector>
            );
        }
    }
    return WithAnalytics;
};

export { withAnalytics, IAnalyticsProps };
