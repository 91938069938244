import {
    ICommissionUpdateRequest,
    IConfigurationBasePaths,
    IReferralEvent,
    IReferrerRequest,
    IReferrerStats,
    // IReferrerStatsRequest,
    IReferrerUpdateRequest,
    IReferringAmbassador,
    IValidateShortCodeResponse,
} from 'models';
import { ThinAirService } from './thin-air.service';

interface IGenericResponse {
    readonly code: string;
    readonly message: string;
    readonly data?: any;
    readonly fault?: any;
}

class ReferralService extends ThinAirService {
    static async validateShortCode(
        basePaths: IConfigurationBasePaths,
        cartId: string,
        shortCode: string,
    ): Promise<IValidateShortCodeResponse> {
        let endpoint = `${ReferralService.BASE_URL}${
            basePaths.referral
        }/shortcode?short_code=${shortCode}`;

        if (cartId) {
            endpoint = endpoint + `&cart_id=${cartId}`;
        }

        const response = await ReferralService.fetchJson<IGenericResponse>(
            endpoint,
            {
                headers: ReferralService.getDefaultHeaders(),
            },
        );

        if (response.code === '200') {
            const {
                data: { shortcode },
            } = response;
            return shortcode;
        }

        throw new Error(response.fault.userErrorMessage);
    }

    static async postEvent(
        basePaths: IConfigurationBasePaths,
        event: IReferralEvent,
    ): Promise<void> {
        const response = await ReferralService.fetchJson<IGenericResponse>(
            `${ReferralService.BASE_URL}${basePaths.referral}/event`,
            {
                body: JSON.stringify({ event }),
                method: 'POST',
            },
        );

        if (response.code === '200') {
            return Promise.resolve();
        }

        throw new Error(
            `Could not postEvent: code:${response.code} message:${
                response.message
            }`,
        );
    }

    static async getOrCreateReferrer(
        basePaths: IConfigurationBasePaths,
        referrerRequest: IReferrerRequest,
    ): Promise<IReferringAmbassador> {
        const response = await ReferralService.fetchJson<IGenericResponse>(
            `${ReferralService.BASE_URL}${
                basePaths.referral
            }/referrer?${ReferralService.getQueryString(referrerRequest)}`,
        );

        if (response.code === '200') {
            const { data } = response;
            return Promise.resolve(data);
        }

        throw new Error(
            `Could not getOrCreateReferrer: code:${response.code} message:${
                response.message
            }`,
        );
    }

    static async updateReferrer(
        basePaths: IConfigurationBasePaths,
        referrerUpdateRequest: IReferrerUpdateRequest,
    ): Promise<void> {
        const response = await ReferralService.fetchJson<IGenericResponse>(
            `${ReferralService.BASE_URL}${basePaths.referral}/referrer`,
            {
                body: JSON.stringify({ referrerUpdateRequest }),
                headers: ReferralService.getDefaultHeaders(),
                method: 'POST',
            },
        );

        if (response.code === '200') {
            return Promise.resolve();
        }

        throw new Error(
            `Could not updateReferrer: code:${response.code} message:${
                response.message
            }`,
        );
    }

    static async updateCommission(
        basePaths: IConfigurationBasePaths,
        commissionUpdateRequest: ICommissionUpdateRequest,
    ): Promise<void> {
        const response = await ReferralService.fetchJson<IGenericResponse>(
            `${ReferralService.BASE_URL}${basePaths.referral}/commission`,
            {
                body: JSON.stringify({ commissionUpdateRequest }),
                // TODO(dan): Seems strange there is no required authorization.
                method: 'POST',
            },
        );

        if (response.code === '200') {
            return Promise.resolve();
        }

        throw new Error(
            `Could not updateCommission: code:${response.code} message:${
                response.message
            }`,
        );
    }

    static async newReferralInfo(
        basePaths: IConfigurationBasePaths,
        authKey: string,
    ): Promise<void> {
        const response = await ReferralService.fetchJson<IGenericResponse>(
            `${ReferralService.BASE_URL}${
                basePaths.referral
            }/newreferralinfo?authKey=${authKey}`,
            {
                headers: ReferralService.getDefaultHeaders(), // TODO(dan): X-MBSY-HMAC-SHA256?
                method: 'POST',
            },
        );

        if (response.code === '200') {
            return Promise.resolve();
        }

        throw new Error(
            `Could not updateCommission: code:${response.code} message:${
                response.message
            }`,
        );
    }

    static async getCreditBalance(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
    ): Promise<Response> {
        const response = await fetch(
            `${ReferralService.BASE_URL}${basePaths.referral}/creditbalance`,
            {
                headers: ReferralService.getDefaultHeaders(firebaseToken),
                method: 'GET',
            },
        );

        if (response.status !== 200) {
            throw new Error(
                await ReferralService.parseErrorOrDefault(response),
            );
        }

        return response.json();
    }

    static async getCreditEarned(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
    ): Promise<Response> {
        const response = await fetch(
            `${ReferralService.BASE_URL}${basePaths.referral}/creditearned`,
            {
                headers: ReferralService.getDefaultHeaders(firebaseToken),
                method: 'GET',
            },
        );

        if (response.status !== 200) {
            throw new Error(
                await ReferralService.parseErrorOrDefault(response),
            );
        }

        return response.json();
    }
    static async getReferrerStats(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        email,
    ): Promise<IReferrerStats> {
        const referralStats = await ReferralService.fetchJson<IReferrerStats>(
            `${ReferralService.BASE_URL}${basePaths.referral}/stats`,
            {
                body: JSON.stringify({ emailId: email }),
                headers: ReferralService.getDefaultHeaders(firebaseToken),
                method: 'POST',
            },
        );
        return referralStats;
    }
}

export { ReferralService };
