import { browserName, osName, isMobile } from 'react-device-detect';

export function renderLivechatByFlag(enableLiveAgentChat) {
  if (window.location.pathname === __('routes.user-validator')) return;
    if (enableLiveAgentChat) {
        makeChatFunctional();
    } else {
        var script = document.createElement('script');
        script.setAttribute(
            'src',
            'https://visible.inq.com/chatskins/launch/inqChatLaunch10005834.js',
        );
        script.setAttribute('type', 'text/javascript');
        document.body.appendChild(script);
    }

    function makeChatFunctional() {
        var initESW = function(gslbBaseURL) {
            window['embedded_svc'].settings.displayHelpButton = true; //Or false
            window['embedded_svc'].settings.language = ''; //For example, enter 'en' or 'en-US'
            window['embedded_svc'].addEventHandler(
                'onChatRequestSuccess',
                function(data) {
                    localStorage.setItem(
                        'chatSession',
                        data.liveAgentSessionKey,
                    );
                },
            );
            window['embedded_svc'].addEventHandler(
                'onChatReconnectSuccessful',
                function(data) {
                    localStorage.setItem(
                        'chatSession',
                        data.liveAgentSessionKey,
                    );
                },
            );
            window['embedded_svc'].addEventHandler(
                'onChatEndedByAgent',
                function() {
                    localStorage.removeItem('chatSession');
                },
            );
            window['embedded_svc'].addEventHandler(
                'onChatEndedByChasitor',
                function() {
                    localStorage.removeItem('chatSession');
                },
            );
            window['embedded_svc'].addEventHandler(
                'onConnectionError',
                function() {
                    localStorage.removeItem('chatSession');
                },
            );
            window['embedded_svc'].addEventHandler(
                'onIdleTimeoutOccurred',
                function() {
                    localStorage.removeItem('chatSession');
                },
            );
            window['embedded_svc'].settings.prepopulatedPrechatFields = {};
            const account = getAccountFromStorage();
            const chatConfigInfo: any = sessionStorage.getItem('chatConfig');
            const parsedChatConfig = JSON.parse(chatConfigInfo);
            if (account) {
                populateFields(JSON.parse(account), parsedChatConfig);
            } else {
                configurePrechatSettings(JSON.parse(chatConfigInfo));
            }
            //Sets the auto-population of pre-chat form fields

            //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
            //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)
            window['embedded_svc'].settings.enabledFeatures = ['LiveAgent'];
            window['embedded_svc'].settings.entryFeature = 'LiveAgent';
            window['embedded_svc'].settings.autoOpenPostChat = true;
            if (parsedChatConfig && parsedChatConfig.id !== '') {
                window['embedded_svc'].init(
                    process.env.SF_SITE_URL,
                    process.env.SF_HELP_CENTER_URL,
                    gslbBaseURL,
                    process.env.SF_GSL_BASE_URL,
                    'Chat_Web',
                    {
                        baseLiveAgentContentURL:
                            process.env.SF_BASE_LIVE_AGENT_CONTENT_URL,
                        deploymentId: process.env.SF_DEPLOYMENT_ID,
                        buttonId: process.env.SF_BUTTON_ID,
                        baseLiveAgentURL: process.env.SF_BASE_LIVE_AGENT,
                        eswLiveAgentDevName: 'Chat_Web',
                        isOfflineSupportEnabled: false,
                    },
                );
            } else {
                window['embedded_svc'].init(
                    process.env.SF_SITE_URL,
                    process.env.SF_HELP_CENTER_URL,
                    gslbBaseURL,
                    process.env.SF_GSL_BASE_URL,
                    'Lead_Chat',
                    {
                        baseLiveAgentContentURL:
                            process.env.SF_BASE_LIVE_AGENT_CONTENT_URL,
                        deploymentId: process.env.SF_DEPLOYMENT_ID,
                        buttonId: process.env.SF_BUTTON_ID,
                        baseLiveAgentURL: process.env.SF_BASE_LIVE_AGENT,
                        eswLiveAgentDevName: 'Lead_Chat',
                        isOfflineSupportEnabled: false,
                    },
                );
            }
        };
        if (!window['embedded_svc']) {
            var s = document.createElement('script');
            const embeddedService: any = process.env.SF_EMBEDDED_SERVICE_URL;
            s.setAttribute('src', embeddedService);
            s.onload = function() {
                initESW(null);
            };
            document.body.appendChild(s);
        } else {
            initESW('https://service.force.com');
        }
    }
}
export function getAccountFromStorage() {
  const account =
      sessionStorage.getItem('AccountDetails') ||
      localStorage.getItem('AccountDetails');
  return account;
}


export function populateFields(account, parsedChatConfig) {
    const fields = {
        FirstName: account.first_name,
        LastName: account.last_name,
        Email: account.email_address,
        Phone: account.phoneNumber,
    };
    console.log('i am at populateFields');
    window['embedded_svc'].settings.extraPrechatInfo = [
        {
            entityName: 'Contact',
            showOnCreate: true,
            saveToTranscript: 'ContactId',
            entityFieldMaps: [
                {
                    isExactMatch: true,
                    fieldName: 'FirstName',
                    doCreate: false,
                    doFind: true,
                    label: 'First Name',
                },
                {
                    isExactMatch: true,
                    fieldName: 'LastName',
                    doCreate: false,
                    doFind: true,
                    label: 'Last Name',
                },
                {
                    isExactMatch: true,
                    fieldName: 'Email',
                    doCreate: false,
                    doFind: true,
                    label: 'Email',
                },
            ],
        },
        {
            entityName: 'LiveChatTranscript',
            entityFieldMaps: [
                {
                    doCreate: true,
                    doFind: true,
                    fieldName: 'Chat_Source__c',
                    isExactMatch: true,
                    label: 'Chat Source',
                },
                {
                    doCreate: true,
                    doFind: true,
                    fieldName: 'Chat_Platform__c',
                    isExactMatch: true,
                    label: 'Chat Platform',
                },
                {
                    doCreate: true,
                    doFind: true,
                    fieldName: 'Chat_Initiated_From__c',
                    isExactMatch: true,
                    label: 'Chat Initiated From',
                },
                {
                    doCreate: true,
                    doFind: true,
                    fieldName: 'Chat_Token__c',
                    isExactMatch: true,
                    label: 'Chat Token',
                },
                {
                    doCreate: true,
                    doFind: true,
                    fieldName: 'Chat_Browser__c',
                    isExactMatch: true,
                    label: 'Chat Browser',
                },
            ],
        },
    ];

    window['embedded_svc'].settings.extraPrechatFormDetails = [
        {
            label: 'First Name',
            value: fields.FirstName,
            displayToAgent: true,
        },
        {
            label: 'Last Name',
            value: fields.LastName,
            displayToAgent: false,
        },
        {
            label: 'Email',
            value: fields.Email,
            displayToAgent: true,
        },
        {
            label: 'Chat Source',
            value: isMobile ? 'Mobile Web' : 'Web',
            transcriptFields: ['Chat_Source__c'],
            displayToAgent: true,
        },
        {
            label: 'Chat Platform',
            value: osName,
            transcriptFields: ['Chat_Platform__c'],
            displayToAgent: true,
        },
        {
            label: 'Chat Initiated From',
            value: window.location.href,
            transcriptFields: ['Chat_Initiated_From__c'],
            displayToAgent: true,
        },
        {
            label: 'Chat Token',
            value: parsedChatConfig.token,
            transcriptFields: ['Chat_Token__c'],
            displayToAgent: true,
        },
        {
            label: 'Chat Browser',
            value: browserName,
            transcriptFields: ['Chat_Browser__c'],
            displayToAgent: true,
        },
        {
            label: 'Contact Id',
            value: parsedChatConfig.id,
            transcriptFields: ['Contact_Id__c'],
            displayToAgent: true,
        },
    ];
}

export function configurePrechatSettings(chatConfigInfo) {
    window['embedded_svc'].settings.extraPrechatInfo = [
        {
            entityFieldMaps: [
                {
                    doCreate: true,
                    doFind: true,
                    fieldName: 'Chat_Source__c',
                    isExactMatch: true,
                    label: 'Chat Source',
                },
                {
                    doCreate: true,
                    doFind: true,
                    fieldName: 'Chat_Platform__c',
                    isExactMatch: true,
                    label: 'Chat Platform',
                },
                {
                    doCreate: true,
                    doFind: true,
                    fieldName: 'Chat_Initiated_From__c',
                    isExactMatch: true,
                    label: 'Chat Initiated From',
                },
                {
                    doCreate: true,
                    doFind: true,
                    fieldName: 'Chat_Token__c',
                    isExactMatch: true,
                    label: 'Chat Token',
                },
                {
                    doCreate: true,
                    doFind: true,
                    fieldName: 'Chat_Browser__c',
                    isExactMatch: true,
                    label: 'Chat Browser',
                },
                {
                    doCreate: true,
                    doFind: true,
                    fieldName: 'Contact_Id__c',
                    isExactMatch: true,
                    label: 'Contact Id',
                },
            ],

            entityName: 'LiveChatTranscript',
            saveToTranscript: '',
            showOnCreate: true,
        },
    ];

    window['embedded_svc'].settings.extraPrechatFormDetails = [
        {
            label: 'Chat Source',
            value: isMobile ? 'Mobile Web' : 'Web',
            transcriptFields: ['Chat_Source__c'],
            displayToAgent: true,
        },
        {
            label: 'Chat Platform',
            value: osName,
            transcriptFields: ['Chat_Platform__c'],
            displayToAgent: true,
        },
        {
            label: 'Chat Initiated From',
            value: window.location.href,
            transcriptFields: ['Chat_Initiated_From__c'],
            displayToAgent: true,
        },
        {
            label: 'Chat Token',
            value:
                chatConfigInfo && chatConfigInfo.token
                    ? chatConfigInfo.token
                    : '',
            transcriptFields: ['Chat_Token__c'],
            displayToAgent: true,
        },
        {
            label: 'Chat Browser',
            value: browserName,
            transcriptFields: ['Chat_Browser__c'],
            displayToAgent: true,
        },
        {
            label: 'Contact Id',
            value: chatConfigInfo && chatConfigInfo.id ? chatConfigInfo.id : '',
            transcriptFields: ['Contact_Id__c'],
            displayToAgent: true,
        },
    ];
}

//TODO: Need to work on URL CHAnge
window.addEventListener('popstate', function(e) {
    const chatConfig = sessionStorage.getItem('chatConfig');
    if (chatConfig) {
        configurePrechatSettings(JSON.parse(chatConfig));
    }
});

function checkChatStatus() {
  return document.getElementById('esw_storage_iframe');
}

export function reloadLiveChatByNavigation() {
  const chatStatus = checkChatStatus();
  const enableLiveAgentChat = sessionStorage.getItem('enableLiveAgentChat');
  if (window.location.pathname === __('routes.user-validator')) return;
  if (!chatStatus) renderLivechatByFlag(enableLiveAgentChat);
}

