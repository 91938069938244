import { withAnalytics } from 'components/common/analytics';
import { PhoneNumber } from 'components/sign-up/components/phone-number';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchCart } from 'state/purchase/cart/cart.actions';

const mapStateToProps = (
    { purchase, products }: IApplicationState,
    ownProps,
) => ({
    cart: purchase.cart.result,
    cartFetchStatuses: new Set([
        purchase.cart.retrieveFetchStatus,
        purchase.cart.updateFetchStatus,
    ]),
    ...ownProps,
});

const mapDispatchToProps = dispatch => ({
    fetchCart: () => dispatch(fetchCart()),
});

const enhance = compose(
    withAnalytics,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const PhoneNumberContainer = enhance(PhoneNumber);

export { PhoneNumberContainer };
