import * as React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';

interface SocialTags {
    readonly ogDescription?: string;
    readonly ogImage?: string;
    readonly ogTitle?: string;
    readonly ogType?: string;
    readonly twitterCard?: string;
    readonly twitterDescription?: string;
    readonly twitterImage?: string;
    readonly twitterTitle?: string;
}

interface SocialTagDefaults {
    readonly ogImage: string;
    readonly ogType: string;
    readonly twitterCard: string;
    readonly twitterImage: string;
}

interface MetaData extends SocialTags {
    readonly title: string;
    readonly description: string;
    readonly robots: string;
}

const hydrateSocialData = (
    providedMetaData: MetaData,
    defaults: SocialTagDefaults,
): MetaData => ({
    ...providedMetaData,
    ogDescription:
        providedMetaData.ogDescription || providedMetaData.description,
    ogImage: providedMetaData.ogImage || defaults.ogImage,
    ogTitle: providedMetaData.ogTitle || providedMetaData.title,
    ogType: providedMetaData.ogType || defaults.ogType,
    twitterCard: providedMetaData.twitterCard || defaults.twitterCard,
    twitterDescription:
        providedMetaData.twitterDescription || providedMetaData.description,
    twitterImage: providedMetaData.twitterImage || defaults.twitterImage,
    twitterTitle: providedMetaData.twitterTitle || providedMetaData.title,
});

interface Props extends RouteComponentProps<any> {
    readonly inviteCode: string;
}

class Head extends React.Component<Props> {
    render() {
        const {
            props: { location, match },
        } = this;
        if (location) {
            const route = location.pathname.replace(/^\//, '');
            const meta = __(`routes.meta`);
            const socialTagDefaults = meta.social_defaults as SocialTagDefaults;
            const routeMeta = meta[route] || {};
            const inviteCode = this.props.inviteCode
                ? this.props.inviteCode
                : match && match.params && match.params.inviteCode
                ? match.params.inviteCode
                : '';
            const {
                title = 'Visible',
                description = null,
                robots = 'noindex, nofollow',
                ...socialMetaValues
            } = hydrateSocialData(routeMeta, socialTagDefaults);
            return (
                <Helmet>
                    <title>{title}</title>
                    <meta name="robots" content={robots} />
                    {description && (
                        <meta name="description" content={description} />
                    )}

                    {Object.keys(socialMetaValues).map(
                        this.keyToTag(socialMetaValues),
                    )}

                    {inviteCode ? (
                        <meta
                            name="apple-itunes-app"
                            content={`app-id=1367950045, app-argument=https://${
                                window.location.host
                            }/invite/${inviteCode}`}
                        />
                    ) : (
                        <meta
                            name="apple-itunes-app"
                            content={`app-id=1367950045, app-argument=${
                                window.location.href
                            }`}
                        />
                    )}
                </Helmet>
            );
        } else {
            return null;
        }
    }

    private readonly keyToTag = (values: any) => (key: string, idx: number) => {
        // if this is an allowed tag AND a value was specified
        if (this.allowedKeys.has(key) && values[key]) {
            return (
                <meta
                    key={['socialtag', idx].join('_')}
                    name={this.formatKey(key)}
                    content={values[key]}
                />
            );
        }

        return null;
    };

    private readonly formatKey = (key: string): string =>
        key.replace(/([A-Z])/g, ':$1').toLowerCase(); // add colons before the capital letters and lowercase the string

    private get allowedKeys(): Set<string> {
        return new Set([
            'ogDescription',
            'ogImage',
            'ogTitle',
            'ogType',
            'twitterCard',
            'twitterDescription',
            'twitterImage',
            'twitterTitle',
        ]);
    }
}

export { Head };
