import { UpdateEmailModal } from 'components/account/update-email-modal/update-email-modal';
import { withAnalytics } from 'components/common/analytics';
import { withInputValidation } from 'components/common/forms/input';
import { withModal } from 'components/common/modal';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateEmailAddress } from 'state/account';

const dataKeys: ReadonlyArray<string> = ['email'];

const mapStateToProps = ({ account }: IApplicationState) => ({
    error: account.changeEmailError,
    status: account.changeEmailFetchStatus,
});

const mapDispatchToProps = dispatch => ({
    updateEmailAddress: p => dispatch(updateEmailAddress(p)),
});

const enhance = compose(
    withModal,
    withAnalytics,
    withInputValidation(dataKeys),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const UpdateEmailModalContainer = enhance(UpdateEmailModal);

export { UpdateEmailModalContainer };
