import { createAction, IAction } from 'lib/br-redux';
import { IProductItemModel, IShopPhonesModel } from 'models';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    FETCH_PRODUCTS = 'PRODUCTS/FETCH_PRODUCTS',
    FETCH_PHONES = 'PRODUCTS/FETCH_PHONES',
    REPORT_FETCH_PRODUCTS_ERROR = 'PRODUCTS/REPORT_FETCH_PRODUCTS_ERROR',
    REPORT_FETCH_PRODUCTS_SUCCESS = 'PRODUCTS/REPORT_FETCH_PRODUCTS_SUCCESS',
    REPORT_FETCH_PHONES_ERROR = 'PRODUCTS/REPORT_FETCH_PHONES_ERROR',
    REPORT_FETCH_PHONES_SUCCESS = 'PRODUCTS/REPORT_FETCH_PHONES_SUCCESS',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type FetchProductsAction = IAction<ActionType.FETCH_PRODUCTS>;
type FetchProductsSuccessAction = IAction<
    ActionType.REPORT_FETCH_PRODUCTS_SUCCESS,
    ReadonlyArray<IProductItemModel>
>;
type FetchProductsErrorAction = IAction<
    ActionType.REPORT_FETCH_PRODUCTS_ERROR,
    string
>;

type FetchPhonesAction = IAction<ActionType.FETCH_PHONES>;
type FetchPhonesSuccessAction = IAction<
    ActionType.REPORT_FETCH_PHONES_SUCCESS,
    ReadonlyArray<IShopPhonesModel>
>;
type FetchPhonesErrorAction = IAction<
    ActionType.REPORT_FETCH_PHONES_ERROR,
    string
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type ProductsAction =
    | FetchProductsAction
    | FetchProductsSuccessAction
    | FetchProductsErrorAction
    | FetchPhonesAction
    | FetchPhonesSuccessAction
    | FetchPhonesErrorAction;

// ------------------------------------
// Action Creators
// i.e. the stuff that actually makes it to the compiled Javascript
// ------------------------------------

const fetchProducts = createAction(ActionType.FETCH_PRODUCTS);
const fetchProductsSuccess = createAction(
    ActionType.REPORT_FETCH_PRODUCTS_SUCCESS,
    (product: ReadonlyArray<IProductItemModel>) => product,
);
const fetchProductsError = createAction(
    ActionType.REPORT_FETCH_PRODUCTS_ERROR,
    (error: string) => error,
);
const fetchPhones = createAction(ActionType.FETCH_PHONES);
const fetchPhonesSuccess = createAction(
    ActionType.REPORT_FETCH_PHONES_SUCCESS,
    (product: ReadonlyArray<IShopPhonesModel>) => product,
);
const fetchPhonesError = createAction(
    ActionType.REPORT_FETCH_PHONES_ERROR,
    (error: string) => error,
);

export {
    ActionType,
    FetchProductsAction,
    FetchProductsSuccessAction,
    FetchProductsErrorAction,
    ProductsAction,
    fetchProducts,
    fetchProductsSuccess,
    fetchProductsError,
    fetchPhones,
    fetchPhonesSuccess,
    fetchPhonesError,
};
