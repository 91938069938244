import { createAction, IAction } from 'lib/br-redux';
import { IQueueableModal, Queueable } from 'models';

interface IModalLoading {
    readonly primary: boolean;
    readonly loading: boolean;
}
// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    REQUEST_LOADING_INDICATOR = 'VIEW/REQUEST_LOADING_INDICATOR',
    DISMISS_LOADING_INDICATOR = 'VIEW/DISMISS_LOADING_INDICATOR',
    SHOW_MESSAGE = 'VIEW/SHOW_MESSAGE',
    DISMISS_MESSAGE = 'VIEW/DISMISS_MESSAGE',
    SHOW_MODAL = 'VIEW/SHOW_MODAL',
    DISMISS_MODAL = 'VIEW/DISMISS_MODAL',
    SHOW_NOTICE = 'VIEW/SHOW_NOTICE',
    DISMISS_NOTICE = 'VIEW/DISMISS_NOTICE',
    TOGGLE_SIDEBAR_CART = 'VIEW/TOGGLE_SIDEBAR_CART',
    CLOSE_SIDEBAR_CART = 'VIEW/CLOSE_SIDEBAR_CART',

    CONFIRM = 'VIEW/CONFIRM',
    CANCEL = 'VIEW/CANCEL',

    MODAL_LOADING = 'VIEW/MODAL_LOADING',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

// prettier-ignore
type IRequestLoadingIndicatorAction = IAction<
    ActionType.REQUEST_LOADING_INDICATOR,
    string
>;
// prettier-ignore
type IDismissLoadingIndicatorAction = IAction<
    ActionType.DISMISS_LOADING_INDICATOR,
    string
>;
type IShowMessageAction = IAction<ActionType.SHOW_MESSAGE, string>;
type IDismissMessageAction = IAction<ActionType.DISMISS_MESSAGE, number>;
type IShowModalAction = IAction<ActionType.SHOW_MODAL, IQueueableModal>;
type IDismissModalAction = IAction<ActionType.DISMISS_MODAL>;

type IShowNoticeAction<T> = IAction<ActionType.SHOW_NOTICE, Queueable<T>>;
type IDismissNoticeAction = IAction<ActionType.DISMISS_NOTICE>;
type IConfirmAction = IAction<ActionType.CONFIRM>;
type ICancelAction = IAction<ActionType.CANCEL>;
type IToggleSidebarCartAction = IAction<ActionType.TOGGLE_SIDEBAR_CART>;
type ICloseSidebarCartAction = IAction<ActionType.CLOSE_SIDEBAR_CART>;
type IModalLoadingAction = IAction<ActionType.MODAL_LOADING, IModalLoading>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type ViewAction =
    | IRequestLoadingIndicatorAction
    | IDismissLoadingIndicatorAction
    | IShowMessageAction
    | IDismissMessageAction
    | IShowModalAction
    | IDismissModalAction
    | IShowNoticeAction<any>
    | IDismissNoticeAction
    | IToggleSidebarCartAction
    | ICloseSidebarCartAction
    | IModalLoadingAction;

// ------------------------------------
// Action Creators
// i.e. the stuff that actually makes it to the compiled Javascript
// ------------------------------------

// prettier-ignore
const requestLoadingIndicator = createAction(
    ActionType.REQUEST_LOADING_INDICATOR,
    (key: string) => key,
);

// prettier-ignore
const dismissLoadingIndicator = createAction(
    ActionType.DISMISS_LOADING_INDICATOR,
    (key: string) => key,
);

const showMessage = createAction(ActionType.SHOW_MESSAGE, (msg: string) => msg);

const dismissMessage = createAction(
    ActionType.DISMISS_MESSAGE,
    (idx: number) => idx,
);

const showModal = createAction(
    ActionType.SHOW_MODAL,
    (content: Queueable<any>) => content,
);
const dismissModal = createAction(ActionType.DISMISS_MODAL);
const modalLoading = createAction(
    ActionType.MODAL_LOADING,
    (state: IModalLoading) => state,
);

const showNotice = createAction(
    ActionType.SHOW_NOTICE,
    (content: Queueable<any>) => content,
);
const dismissNotice = createAction(ActionType.DISMISS_NOTICE);

const reportConfirmation = createAction(ActionType.CONFIRM);
const reportCancel = createAction(ActionType.CANCEL);
const toggleSidebarCart = createAction(ActionType.TOGGLE_SIDEBAR_CART);
const closeSidebarCart = createAction(ActionType.CLOSE_SIDEBAR_CART);

export {
    ActionType,
    dismissLoadingIndicator,
    dismissMessage,
    IDismissLoadingIndicatorAction,
    IDismissMessageAction,
    IRequestLoadingIndicatorAction,
    IShowMessageAction,
    requestLoadingIndicator,
    showMessage,
    ViewAction,
    showModal,
    dismissModal,
    IShowNoticeAction,
    IDismissNoticeAction,
    showNotice,
    dismissNotice,
    IConfirmAction,
    ICancelAction,
    reportConfirmation,
    reportCancel,
    toggleSidebarCart,
    closeSidebarCart,
    modalLoading,
};
