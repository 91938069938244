import * as React from 'react';

const Checkmark = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="12"
        viewBox="0 0 15 12"
    >
        <path
            fill="#1800ff"
            fillRule="evenodd"
            d="M4.648 8.828L13.477 0l1.171 1.172-10 10L0 6.523l1.172-1.171z"
        />
    </svg>
);

export { Checkmark };
