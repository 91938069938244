import * as React from 'react';

import { GetPhoneNumberContainer as GetPhoneNumber } from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { Cart, IAnalyticsProps } from 'models';
import { SIMCartItem } from 'models/cart';
import { ProductType } from 'models/products';
import { HasPhoneNumber } from './has-phone-number';

enum Instruction {
    GET_PHONE_NUMBER,
    SHOW_SELECTION,
    ERROR,
    FETCHING,
}

interface MappedProps {
    readonly cartFetchStatuses: Set<FetchStatus>;
    readonly cart: Cart | null;
    fetchCart(): void;
}

interface OwnProps extends IAnalyticsProps {
    onNext(): void;
}

type Props = MappedProps & OwnProps;

interface State {
    readonly instruction: Instruction;
}

class PhoneNumber extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { cartFetchStatuses } = props;
        const instruction: Instruction = cartFetchStatuses.has(
            FetchStatus.FETCHING,
        )
            ? Instruction.FETCHING
            : this.hasPhoneNumber(props)
            ? Instruction.SHOW_SELECTION
            : Instruction.GET_PHONE_NUMBER;

        this.state = { instruction };
    }

    render() {
        const { onNext, cart } = this.props;
        const { instruction } = this.state;
        // tslint:disable-next-line:no-let
        let sim: SIMCartItem | undefined;
        if (cart) {
            sim = cart.itemForProductType(ProductType.SIM) as SIMCartItem;
        }

        switch (instruction) {
            case Instruction.GET_PHONE_NUMBER:
                return <GetPhoneNumber onNext={onNext} />;
            case Instruction.SHOW_SELECTION:
                return <HasPhoneNumber sim={sim} />;
            case Instruction.ERROR:
                return <h2>{__('default-error-message')}</h2>;
            default:
                return null;
        }
    }

    componentWillReceiveProps(props: Props): void {
        if (this.hasPhoneNumber(props)) {
            this.setState({ instruction: Instruction.SHOW_SELECTION });
        } else {
            this.setState({ instruction: Instruction.GET_PHONE_NUMBER });
        }
    }

    private hasPhoneNumber(props: Props): boolean {
        const { cart } = props;
        return !!(cart && cart.hasProductType(ProductType.SIM));
    }
}

export { PhoneNumber };
