import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import {
    BodyCopy,
    ErrorMsg,
    InfoLink,
    Label,
} from 'components/common/typography';
import {
    ButtonContainer as Button,
    ModalWrapperContainer as ModalWrapper,
    SelectContainer as Select,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { ChoiceOption, IAnalyticsProps, ILead } from 'models';

const styles = require('./lead-capture-modal.less');

interface Props extends IAnalyticsProps {
    readonly error: string;
    readonly status: FetchStatus;
    readonly email: string;
    captureLead(lead: ILead): void;
    clearCaptureLead(): void;
}

interface State {
    readonly carrier: string;
    readonly device: string;
    readonly os: string;
}

const DEFAULT_STATE: State = {
    carrier: '',
    device: '',
    os: '',
};

@CSSModules(styles)
class LeadCaptureModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = DEFAULT_STATE;
    }
    render() {
        const {
            bindHandleChange,
            handleSubmit,
            isFetching,
            normalizeOptions,
            props: { error },
            state: { os, device, carrier },
        } = this;
        return (
            <ModalWrapper closable title={__('home.lead-capture.title')}>
                <form onSubmit={handleSubmit}>
                    <Label>{__('home.lead-capture.os.label')}</Label>
                    <Select
                        margin30Bottom
                        handleChange={bindHandleChange(
                            'os',
                            __('home.lead-capture.os.label'),
                        )}
                        options={normalizeOptions(
                            __('home.lead-capture.os.options'),
                        )}
                        value={os}
                    />
                    {os === 'iOS' && (
                        <>
                            <Label>
                                {__('home.lead-capture.device.label')}
                            </Label>
                            <Select
                                margin30Bottom
                                handleChange={bindHandleChange(
                                    'device',
                                    __('home.lead-capture.device.label'),
                                )}
                                options={normalizeOptions(
                                    __('home.lead-capture.device.options'),
                                )}
                                value={device}
                            />
                        </>
                    )}
                    <Label>{__('home.lead-capture.carrier.label')}</Label>
                    <Select
                        margin30Bottom
                        handleChange={bindHandleChange(
                            'carrier',
                            __('home.lead-capture.carrier.label'),
                        )}
                        options={normalizeOptions(
                            __('home.lead-capture.carrier.options'),
                        )}
                        value={carrier}
                    />
                    <BodyCopy>
                        {__('home.lead-capture.body')}
                        <InfoLink
                            element="a"
                            to={__('routes.help.unlocked')}
                            target="_blank"
                        >
                            {__('home.lead-capture.body.what-does-this-mean')}
                        </InfoLink>
                    </BodyCopy>
                    <ErrorMsg>{error}</ErrorMsg>
                    <Button
                        onClick={handleSubmit}
                        loading={isFetching}
                        type="submit"
                        label={__('home.lead-capture.button')}
                    />
                </form>
            </ModalWrapper>
        );
    }

    private readonly normalizeOptions = (
        options: ReadonlyArray<string>,
    ): ReadonlyArray<ChoiceOption> => {
        return options.map(item => ({ label: item, value: item }));
    };
    private get isFetching(): boolean {
        const {
            props: { status },
        } = this;
        return status === FetchStatus.FETCHING;
    }
    private readonly bindHandleChange = (name: string, label: string) => (
        value: string,
    ): void => {
        const newState = { ...this.state };
        newState[name] = value;
        this.setState(newState);
    };
    private readonly handleSubmit = (
        event: React.FormEvent<HTMLFormElement>,
    ): void => {
        event.preventDefault();
        const {
            props: { captureLead, email },
            state: { os, device, carrier },
        } = this;
        captureLead({
            carrier,
            device,
            email,
            os,
        });
    };
}

export { LeadCaptureModal };
