enum FacebookLoginStatus {
    CONNECTED = 'connected',
    NOT_AUTHORIZED = 'not_authorized',
    UNKNOWN = 'unknown',
    NOT_FETCHED = 'not_fetched',
    ERROR = 'error',
}

interface IFacebookMeResponse {
    readonly email?: string;
    readonly first_name?: string;
    readonly last_name?: string;
}

export { FacebookLoginStatus, IFacebookMeResponse };
