// tslint:disable:max-line-length
import * as React from 'react';

interface Props extends React.HTMLProps<SVGElement> {
    readonly invert?: boolean;
}

const D = ({ invert, ...other }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="115"
        height="115"
        viewBox="0 0 63 58"
    >
        <defs>
            <path id="a" d="M6.667.339h6.313v12.627H.354V.339h6.313z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                fill={invert ? '#ffffff' : '#1800FF'}
                d="M12.626 6.313a6.314 6.314 0 1 1-12.628 0 6.314 6.314 0 0 1 12.628 0"
            />
            <g transform="translate(50 -.339)">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <path
                    fill={invert ? '#ffffff' : '#1800FF'}
                    d="M12.98 6.652a6.314 6.314 0 1 1-12.628 0 6.314 6.314 0 0 1 12.628 0"
                    mask="url(#b)"
                />
            </g>
            <path
                fill={invert ? '#ffffff' : '#1800FF'}
                d="M11.385 31.526c2.362 8.892 10.48 15.462 20.105 15.462 9.624 0 17.743-6.57 20.104-15.462H11.385zM31.49 57.661C14.137 57.661.02 43.543.02 26.191v-5.337H62.96v5.336c0 17.353-14.118 31.471-31.47 31.471z"
            />
        </g>
    </svg>
);

export { D };
