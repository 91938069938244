import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';
import { InputContainer as Input } from 'containers';
import { IInputValidationProps } from 'models';
import { GoogleAddress } from 'models/google';
import { DeferredPromise, InputValidator } from 'utils';
import { findKey } from 'lodash';

const styles = require('./address-field.less');

const addressTypes = {
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    locality: 'long_name',
    postal_code: 'short_name',
    street: 'short_name',
};

interface IMappedProps extends IAnalyticsProps, IInputValidationProps {
    readonly apiDidInit: boolean;
    readonly apiError: boolean;
}

interface IOwnProps {
    readonly id: string;
    readonly label: string;
    readonly error?: string;
    readonly name?: string;
    readonly defaultValue?: string;
    readonly placeholder?: string;
    readonly disabled?: boolean;
    readonly readOnly?: boolean;
    readonly readOnlyText?: boolean;
    readonly twoColm?: boolean;
    readonly showLabel?: boolean;
    readonly noMargin?: boolean;
    onChange(address?: GoogleAddress): void;
    onValidate(id: string, valid: boolean): void;
    onFocus?(): void;
}

type Props = IMappedProps & IOwnProps;

interface State {
    readonly initialized: boolean;
    readonly address: string;
    readonly placeId?: string;
}

const DEFAULT_STATE: State = {
    address: '',
    initialized: false,
    placeId: '',
};

@CSSModules(styles, { allowMultiple: true })
class AddressField extends React.Component<Props, State> {
    private readonly initPromise = new DeferredPromise();
    // tslint:disable:readonly-keyword
    private self: HTMLInputElement;
    private autocomplete: google.maps.places.Autocomplete;
    // tslint:enable

    constructor(props: IMappedProps & IOwnProps) {
        super(props);
        this.state = {
            ...DEFAULT_STATE,
            address: props.defaultValue || '',
        };
    }

    componentDidMount(): void {
        this.initGoogleAutocomplete();
    }

    componentWillReceiveProps(newProps: Props): void {
        if (
            newProps.defaultValue &&
            newProps.defaultValue !== this.props.defaultValue
        ) {
            this.setState({ address: newProps.defaultValue });
        }
    }

    render() {
        const {
            assignSelf,
            handleOnChange,
            props: {
                apiDidInit,
                id,
                data,
                label,
                showLabel,
                error,
                onFocus,
                onValidate,
                noMargin,
                placeholder,
                disabled,
                onChange,
                readOnly,
                readOnlyText,
                twoColm,
            },
            state: { address },
        } = this;

        if (apiDidInit) {
            return (
                <Input
                    id={id}
                    label={label}
                    showLabel={showLabel}
                    onFocus={onFocus}
                    onRef={assignSelf}
                    value={address}
                    placeholder={placeholder}
                    disabled={disabled}
                    readOnly={readOnly || !onChange}
                    readOnlyText={readOnlyText || !onChange}
                    error={error}
                    twoColm={twoColm}
                    noMargin={noMargin}
                    onChange={handleOnChange}
                    onValidate={onValidate}
                    validateValue={data[id]}
                    validate={InputValidator.validateAddress}
                    tabIndex={readOnly ? -1 : 0}
                />
            );
        }

        return null;
    }

    private readonly assignSelf = (ref: HTMLInputElement) => {
        this.self = ref;
    };

    private readonly handleOnChange = (
        event: React.FormEvent<HTMLInputElement>,
    ): void => {
        const { value: address } = event.target as HTMLInputElement;
        if (!this.props.onChange) {
            return;
        }
        this.setState({ address, placeId: '' });
        this.props.onChange();
    };

    private readonly initGoogleAutocomplete = async (): Promise<void> => {
        const {
            state: { initialized },
        } = this;
        if (!initialized) {
            try {
                await this.checkForInit();
                this.autocomplete = new google.maps.places.Autocomplete(
                    this.self,
                    {
                        types: ['geocode'],
                    },
                );

                // Enter select result
                google.maps.event.addDomListener(this.self, 'keydown', e => {
                    if (e.keyCode === 13) {
                        e.preventDefault();
                    }
                });

                this.autocomplete.addListener(
                    'place_changed',
                    this.placeDidChange,
                );
            } finally {
                this.setState({
                    initialized: true,
                });
            }
        }
    };

    private readonly placeDidChange = (): void => {
        const place = this.autocomplete.getPlace();
        const address: Partial<GoogleAddress> = {};
        let streetName;
        if (
            this.autocomplete &&
            this.autocomplete['gm_accessors_'] &&
            this.autocomplete['gm_accessors_'].place
        ) {
            const placeObj = this.autocomplete['gm_accessors_'].place;
            const findKeyObj = findKey(placeObj, 'formattedPrediction');
            const str = placeObj[findKeyObj].formattedPrediction;
            streetName = str.split(',')[0];
        }

        if (place && place.address_components) {
            for (const component of place.address_components) {
                const addressType = component.types[0];
                if (addressTypes[addressType]) {
                    address[addressType] = component[addressTypes[addressType]];
                }
            }
            address.street = streetName;
            if (this.state.placeId !== place.id) {
                this.setState({ placeId: place.id });
                this.props.onChange(address as GoogleAddress);
            }
        }
    };

    private async checkForInit(): Promise<void> {
        const { apiDidInit, apiError } = this.props;
        if (apiDidInit) {
            return this.initPromise.resolve();
        } else if (apiError) {
            return this.initPromise.reject();
        } else {
            await this.sleep(100);
            return this.checkForInit();
        }
    }

    private async sleep(milliseconds: number): Promise<{}> {
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
    }
}

export { AddressField };
