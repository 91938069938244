import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { BodyCopy } from 'components/common/typography';
import {
    ButtonContainer as Button,
    ModalWrapperContainer as ModalWrapper,
} from 'containers';
import {
    IAnalyticsProps,
    IDeviceTypeProps,
    IModalProps,
    // HandsetCartItem,
} from 'models';

const styles = require('./clear-cart.less');
interface Props extends IModalProps, IDeviceTypeProps, IAnalyticsProps {
    readonly showActions?: boolean;
    readonly handsetName?: string;

    clearCartConfirm(): void;
    clearCartNo(): void;
}

@CSSModules(styles)
class ClearCart extends React.Component<Props> {
    modalCopy(handsetName) {
        console.log(handsetName);
        if (handsetName === 'Byod Handset') {
            return __('clear-cart.body-2');
        }
        return `${__('clear-cart.body-phone1')} ${handsetName}.`;
    }

    render() {
        const {
            props: { isDesktop, isMobile, handsetName },
        } = this;
        return (
            <ModalWrapper closable title={__('clear-cart.modal-title')}>
                <div styleName={'clear-cart-cont'}>
                    {handsetName && (
                        <BodyCopy medium>
                            {this.modalCopy(handsetName)}
                        </BodyCopy>
                    )}
                    <>
                        <div styleName="modal-options">
                            <Button
                                styleName="button"
                                onClick={this.props.clearCartConfirm}
                                fullWidth={isMobile}
                                minWidth={isDesktop ? 222 : undefined}
                                label={__('clear-cart.ok')}
                            />
                            <Button
                                styleName="button"
                                onClick={this.props.clearCartNo}
                                label={__('clear-cart.cancel')}
                            />
                        </div>
                    </>
                </div>
            </ModalWrapper>
        );
    }
}

export { ClearCart };
