import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { ActionType, IResubmissionAction } from './resubmission.actions';

interface ResubmissionState {
    readonly fetchStatus: FetchStatus;
    readonly error: string;
}

const DEFAULT_STATE: ResubmissionState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
};

const {
    RESUBMIT_ACCOUNT_NUMBER,
    RESUBMIT_PHONE_NUMBER,
    RESUBMIT_PIN,
    REPORT_RESUBMISSION_ERROR,
    REPORT_RESUBMISSION_SUCCESS,
} = ActionType;

const resubmissionReducer = (
    state: ResubmissionState = DEFAULT_STATE,
    action: IResubmissionAction | IDefaultAction,
) => {
    switch (action.type) {
        case RESUBMIT_ACCOUNT_NUMBER:
        case RESUBMIT_PHONE_NUMBER:
        case RESUBMIT_PIN:
            return {
                error: '',
                fetchStatus: FetchStatus.FETCHING,
            };
        case REPORT_RESUBMISSION_SUCCESS:
            return {
                error: '',
                fetchStatus: FetchStatus.SUCCESS,
            };
        case REPORT_RESUBMISSION_ERROR:
            return {
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        default:
            return state;
    }
};

export { DEFAULT_STATE, ResubmissionState, resubmissionReducer };
