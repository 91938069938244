import { createAction, IAction } from 'lib/br-redux';
import { FAQResponse } from 'models';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    FETCH_FAQ = 'FAQ/FETCH_FAQ',
    REPORT_FAQ_FETCH_SUCCESS = 'FAQ/REPORT_FAQ_FETCH_SUCCESS',
    REPORT_FAQ_UP_TO_DATE = 'FAQ/REPORT_FAQ_UP_TO_DATE',
    REPORT_FAQ_FETCH_ERROR = 'FAQ/REPORT_FAQ_FETCH_ERROR',
    SEARCH_FAQ = 'FAQ/SEARCH_FAQ',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type IFetchFAQAction = IAction<ActionType.FETCH_FAQ, string>;
type IFetchFAQSuccessAction = IAction<
    ActionType.REPORT_FAQ_FETCH_SUCCESS,
    FAQResponse
>;
type IFAQUpToDateAction = IAction<ActionType.REPORT_FAQ_UP_TO_DATE>;
type IFetchFAQErrorAction = IAction<ActionType.REPORT_FAQ_FETCH_ERROR, string>;
type ISearchFAQAction = IAction<ActionType.SEARCH_FAQ, string>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IFAQAction =
    | IFetchFAQAction
    | IFetchFAQSuccessAction
    | IFAQUpToDateAction
    | IFetchFAQErrorAction
    | ISearchFAQAction;

// ------------------------------------
// Action Creators
// i.e. the stuff that actually makes it to the compiled Javascript
// ------------------------------------

const fetchFAQ = createAction(ActionType.FETCH_FAQ);
const reportFAQFetchSuccess = createAction(
    ActionType.REPORT_FAQ_FETCH_SUCCESS,
    (res: FAQResponse) => res,
);
const reportFAQUpToDate = createAction(ActionType.REPORT_FAQ_UP_TO_DATE);
const reportFAQFetchError = createAction(
    ActionType.REPORT_FAQ_FETCH_ERROR,
    (err: string) => err,
);
const searchFAQ = createAction(
    ActionType.SEARCH_FAQ,
    (searchTerm: string) => searchTerm,
);

export {
    ActionType,
    IFAQAction,
    IFAQUpToDateAction,
    IFetchFAQAction,
    IFetchFAQErrorAction,
    IFetchFAQSuccessAction,
    ISearchFAQAction,
    fetchFAQ,
    reportFAQFetchError,
    reportFAQFetchSuccess,
    reportFAQUpToDate,
    searchFAQ,
};
