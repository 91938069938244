import * as React from 'react';

import { ReferralLanding } from 'components/account/pay-bill/referral/referral-landing';
import { ReferralTerms } from 'components/account/pay-bill/referral/referral-terms';
import { Affirm } from 'components/affirm';
import { Androidbeta } from 'components/android-beta';
import {
    HowToInsertSimcard,
    HowToUnlockaPhone,
    PortingPhoneNumber,
} from 'components/help';
import { InviteRouteHandlerContainer } from 'components/invite/handle-invite-route';
import { Androidgeneral, Iosgeneral } from 'components/media-general';
import {
    ProtectConsent,
    ProtectCoverage,
    VisibleProtect,
} from 'components/protect';
import { Sxsw } from 'components/sxsw';
import {
    AccountContainer,
    ConnectContainer,
    CoverageContainer,
    CreateAccountContainer as CreateAccount,
    DeviceCheckContainer as DeviceCheck,
    EmailActionRouterContainer,
    FAQContainer,
    FeedbackContainer,
    ForgotPasswordContainer,
    FourOhFourContainer,
    HomeContainer,
    IncompatibilityIMEIContainer,
    PlanContainer,
    PrivacyPolicyContainer,
    PromoContainer,
    ReturnsPolicyContainer,
    // ShopVisibleContainer,
    SignInContainer,
    SignUpContainer,
    // SignupSuccessContainer,
    StayInLoopContainer,
    SwapContainer,
    TermsAndConditionsContainer,
    TermsOfUseContainer,
    UserValidatorContainer,
    ApplicationHomeContainer,
    AccountTerminatedContainer,
} from 'containers';
import { SwapAemContainer } from 'containers/swap-aem.container';
import { CustomerState } from 'models/account/account.model';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConditionalRoute, SecureRoute } from 'utils/routing';

interface Props {
    readonly customerState: CustomerState;
    readonly location: any; // TODO
    readonly authenticated: boolean;
    readonly initializing: boolean;
}

const routes = ({
    customerState,
    location,
    authenticated,
    initializing,
}: Props) => (
    <Switch location={location} key={location.key}>
        <Route exact path={__('routes.home')} component={HomeContainer} />
        <Route
            exact
            path={__('routes.home-static')}
            component={HomeContainer}
        />
        {/* <Route
            path={__('routes.shop-visible')}
            component={ShopVisibleContainer}
        /> */}
        <Route path={__('routes.plan')} component={PlanContainer} />
        <Route path={__('routes.connect')} component={ConnectContainer} />
        <Route path={__('routes.coverage')} component={CoverageContainer} />
        <Route path={__('routes.sign-in')} component={SignInContainer} />
        <Route path={__('routes.help')} component={FAQContainer} />
        <Route
            path={__('routes.stay-in-loop')}
            component={StayInLoopContainer}
        />
        <Route
            path={__('routes.home.application')}
            component={ApplicationHomeContainer}
        />
        {/* <Route
            path={__('routes.signup-success')}
            component={SignupSuccessContainer}
        /> */}
        <Route
            exact
            path={__('routes.swappdp')}
            component={() => {
                window.location.href = __('routes.swappdp');
                return null;
            }}
        />
        <Route
            exact
            path={__('routes.phones')}
            component={() => {
                window.location.href = __('routes.phones');
                return null;
            }}
        />
        <Route
            exact
            path={__('routes.cart')}
            component={() => {
                window.location.href = __('routes.cart');
                return null;
            }}
        />
        <Route path={__('routes.account.groups')} component={PlanContainer} />
        <Route
            path={__('routes.coverage')}
            render={() => (window.location.href = __('routes.coverage'))}
        />
        <Route path={__('routes.promo-url')} component={PromoContainer} />
        <Route
            path={__('routes.terms-and-conditions')}
            component={TermsAndConditionsContainer}
        />
        <Route
            path={__('routes.terms-of-use')}
            component={TermsOfUseContainer}
        />
        <Route
            path={__('routes.legal-disclosures')}
            render={() =>
                (window.location.href = __('routes.legal-disclosures'))
            }
        />
        <Route
            path={__('routes.supplemental-terms')}
            render={() =>
                (window.location.href = __('routes.supplemental-terms'))
            }
        />
        <Route
            path={__('routes.forgot-password')}
            component={ForgotPasswordContainer}
        />
        <Route
            path={__('routes.email-action')}
            component={EmailActionRouterContainer}
        />
        <Route path={__('routes.privacy')} component={PrivacyPolicyContainer} />
        <Route
            path={__('routes.returns-policy')}
            component={ReturnsPolicyContainer}
        />
        <Route path={__('routes.protect-consent')} component={ProtectConsent} />
        <Route path={__('routes.visible-protect')} component={VisibleProtect} />
        <Route
            path={__('routes.protect-coverage')}
            component={ProtectCoverage}
        />
        <Route
            path={__('routes.how-to-insert-sim-card')}
            component={HowToInsertSimcard}
        />
        <Route
            path={__('routes.how-to-unlock-a-phone')}
            component={HowToUnlockaPhone}
        />
        <Route
            path={__('routes.porting-phone-number')}
            component={PortingPhoneNumber}
        />
        <Route
            path={__('routes.incompatible.imei')}
            component={IncompatibilityIMEIContainer}
        />
        <Route path={__('routes.swap.landing')} component={SwapContainer} />

        <Route path={__('routes.swap')} component={SwapAemContainer} />

        <Route
            path={__('routes.referralLanding')}
            component={ReferralLanding}
        />
        <Route path={__('routes.referral')} component={ReferralLanding} />
        <Route path={__('routes.referralterms')} component={ReferralTerms} />
        <Route path={__('routes.affirm')} component={Affirm} />
        <Route path={__('routes.sxsw')} component={Sxsw} />
        <Route path={__('routes.android-beta')} component={Androidbeta} />
        <Route path={__('routes.sign-up')} component={CreateAccount} />
        <ConditionalRoute
            initializing={initializing}
            component={SignUpContainer}
            location={location}
            condition={
                !new Set([
                    CustomerState.PREACTIVATED,
                    CustomerState.PURCHASED,
                    CustomerState.ACTIVE,
                ]).has(customerState)
            }
            path={__('routes.byod')}
            destination={__('routes.home')}
            isBYOD={false}
        />
        <Route
            path={__('routes.invite')}
            component={InviteRouteHandlerContainer}
        />
        <Route
            path={__('routes.user-validator')}
            component={UserValidatorContainer}
        />
        <Route path={__('routes.iosgeneral')} component={Iosgeneral} />
        <Route path={__('routes.androidgeneral')} component={Androidgeneral} />
        <ConditionalRoute
            initializing={initializing}
            component={DeviceCheck}
            location={location}
            condition={
                !new Set([
                    CustomerState.PREACTIVATED,
                    CustomerState.PURCHASED,
                    CustomerState.ACTIVE,
                    CustomerState.LAPSED,
                    CustomerState.PAUSED,
                ]).has(customerState)
            }
            path="/compatibility"
            destination={__('routes.compatibility')}
            isBYOD={CustomerState.PREACTIVATED === customerState}
        />
        <Route
            exact
            path={__('routes.account.party')}
            component={() => {
                window.location.href = __('routes.account.party');
                return null;
            }}
        />
        <Route
            exact
            path={__('routes.account.overview')}
            component={() => {
                window.location.href = __('routes.account.overview');
                return null;
            }}
        />
        <SecureRoute
            initializing={initializing}
            component={AccountTerminatedContainer}
            location={location}
            authenticated={authenticated}
            path={__('routes.account.terminated')}
            isBYOD={false}
        />
        <SecureRoute
            component={AccountContainer}
            location={location}
            authenticated={authenticated}
            initializing={initializing}
            customerState={customerState}
            requiredCustomerStates={
                new Set([
                    CustomerState.PREACTIVATED,
                    CustomerState.PURCHASED,
                    CustomerState.ACTIVE,
                    CustomerState.LAPSED,
                    CustomerState.PAUSED,
                    CustomerState.REGISTERED,
                    CustomerState.UNKNOWN,
                ])
            }
            path={__('routes.account')}
            isBYOD={false}
        />
        <SecureRoute
            component={FeedbackContainer}
            authenticated={authenticated}
            initializing={initializing}
            location={location}
            path={__('routes.feedback')}
            isBYOD={false}
        />
        <Redirect from="/your-privacy" to={__('routes.account.privacy')} />
        <Redirect from="/join" to={__('routes.plan')} />
        <Redirect from="/marvel" to={__('routes.home')} />
        <Route
            exact
            path={__('routes.account.party')}
            component={() => {
                window.location.href = __('routes.account.party');
                return null;
            }}
        />
        <Route component={FourOhFourContainer} />
    </Switch>
);
export default routes;
