import { routerReducer as routing } from 'react-router-redux';
import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { accountReducer as account } from './account';
import { authenticationReducer as authentication } from './authentication';
import { billReducer as bill } from './bill';
import { currentBillReducer as currentBill } from './bill/current-bill.reducer';
import { configurationReducer as configuration } from './configuration';
import { deviceReducer as device } from './device';
import { emailActionReducer as emailAction } from './email-action';
import { faqReducer as faq } from './faq';
import { feedbackReducer as feedback } from './feedback';
import { leadReducer as lead } from './lead';
import { paymentReducer as payment } from './payment';
import { portStatusReducer as portStatus } from './port-status';
import { productsReducer as products } from './products';
import { purchaseReducer as purchase } from './purchase';
import { referralReducer as referral } from './referral';
import { registrationReducer as registration } from './registration';
import { shopReducer as shop } from './shop';
import { startupReducer as startup } from './startup';
import { stayInLoopReducer as stayinloop } from './stay-in-loop';
import { analyticsReducer as analytics } from './tealium';
import { userValidationReducer as userValidation } from './user-validation';
import { viewReducer as view } from './view';
import { groups } from './account/groups';

const persistConfig = {
    key: 'thin-air',
    storage,
    whitelist: ['authentication', 'faq', 'purchase'],
};

export default persistCombineReducers(persistConfig, {
    // tslint:disable:object-literal-sort-keys
    routing: routing as any, // smelly, but fixes error
    // add more reducers here
    account,
    analytics,
    authentication,
    bill,
    currentBill,
    configuration,
    device,
    emailAction,
    faq,
    feedback,
    lead,
    payment,
    portStatus,
    products,
    purchase,
    referral,
    registration,
    startup,
    userValidation,
    view,
    stayinloop,
    shop,
    groups,
});
