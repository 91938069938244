import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

interface Props extends RouteProps {
    readonly condition: boolean;
    readonly destination: string;
    readonly initializing: boolean;
    readonly location: any;
    readonly isBYOD: boolean;
}

const maybeCreateRedirect = (initializing, destination, location, isBYOD) => {
    // don't redirect if auth is still initializing OR
    // we aren't already on the sign in screen
    if (!initializing && location.pathname !== destination) {
        return <Redirect to={destination} />;
    } else if (isBYOD) {
        return (
            <Redirect
                from={__('routes.compatibility')}
                to={__('routes.bring-your-own-phone')}
            />
        );
    }

    return <div />;
};

const ConditionalRoute = ({
    condition,
    destination,
    initializing,
    location,
    isBYOD,
    ...rest
}: Props) => {
    return condition ? (
        <Route {...rest} />
    ) : (
        maybeCreateRedirect(initializing, destination, location, isBYOD)
    );
};
export { ConditionalRoute };
