import { IAccount, IConfigurationBasePaths, PaymentMethod } from 'models';
import { ThinAirService } from './thin-air.service';

interface IClientTokenResponse {
    readonly result: {
        readonly clientToken?: string;
    };
}

interface IAutopayResponse {
    readonly status: 'on' | 'off';
}

class PaymentService extends ThinAirService {
    static async getClientToken(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
    ): Promise<string> {
        const response = (await PaymentService.fetchJson(
            `${PaymentService.BASE_URL}${basePaths.payment}/getClientToken`,
            {
                headers: PaymentService.getDefaultHeaders(firebaseToken),
                method: 'GET',
            },
        )) as IClientTokenResponse;

        if (response.result && response.result.clientToken) {
            return response.result.clientToken as string;
        }

        throw new Error('Could not get client token.');
    }

    static async getDefaultPaymentMethod(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
    ): Promise<PaymentMethod> {
        return PaymentService.fetchJson<PaymentMethod>(
            `${PaymentService.BASE_URL}${basePaths.payment}/defaultmethod`,
            {
                headers: PaymentService.getDefaultHeaders(firebaseToken),
                method: 'GET',
            },
        );
    }

    static async updateDefaultPaymentMethod(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        // tslint:disable-next-line:variable-name
        payment_nonce: string,
    ): Promise<void> {
        const response = await fetch(
            `${PaymentService.BASE_URL}${basePaths.payment}/defaultmethod`,
            {
                body: JSON.stringify({ payment_nonce }),
                headers: PaymentService.getDefaultHeaders(firebaseToken),
                method: 'PUT',
            },
        );

        if (response.status === 200) {
            return Promise.resolve();
        }

        throw new Error(response.status.toString());
    }

    static async isAutopayEnabled(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
    ): Promise<boolean> {
        const response = (await PaymentService.fetchJson(
            `${PaymentService.BASE_URL}${basePaths.payment}/autopay`,
            {
                headers: PaymentService.getDefaultHeaders(firebaseToken),
            },
        )) as IAutopayResponse;
        // const response = {
        //     status: 'on',
        // };

        return response.status === 'on';
    }

    static async setAutopayStatus(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        status: boolean,
    ): Promise<boolean> {
        const response = (await PaymentService.fetchJson(
            `${PaymentService.BASE_URL}${basePaths.payment}/autopay`,
            {
                body: JSON.stringify({
                    status: PaymentService.statusFromBool(status),
                }),
                headers: PaymentService.getDefaultHeaders(firebaseToken),
                method: 'PUT',
            },
        )) as IAutopayResponse;

        return response.status === 'on';
    }

    static async makeManualPayment(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        chargeAmount: number,
        account: IAccount,
        kountDevice: any,
    ): Promise<void> {
        const deviceData = (kountDevice && kountDevice.deviceData) || {};
        const billingAddress = {
            firstName: account.firstName,
            lastName: account.lastName,
            locality: account.address.billing_address.city,
            postalCode: account.address.billing_address.zip,
            region: account.address.billing_address.state,
            streetAddress:
                account.address.billing_address.street_line1 +
                ' ' +
                account.address.billing_address.street_line2,
            countryCode: account.address.billing_address.countryCode,
        };
        const shippingAddress = account.address.shipping_address
            ? {
                  firstName: account.firstName,
                  lastName: account.lastName,
                  locality: account.address.shipping_address.city,
                  postalCode: account.address.shipping_address.zip,
                  region: account.address.shipping_address.state,
                  streetAddress:
                      account.address.shipping_address.street_line1 +
                      ' ' +
                      account.address.shipping_address.street_line2,
                  countryCode: account.address.shipping_address.countryCode,
              }
            : '';

        const response = await fetch(
            `${PaymentService.BASE_URL}${basePaths.payment}/manualpaynew`,
            {
                body: JSON.stringify({
                    billingAddress,
                    chargeAmount,
                    deviceData,
                    shippingAddress,
                }),
                headers: PaymentService.getDefaultHeaders(firebaseToken),
                method: 'POST',
            },
        );

        if (response.status !== 204) {
            throw new Error(await PaymentService.parseErrorOrDefault(response));
        }

        return;
    }

    private static statusFromBool(status: boolean): 'off' | 'on' {
        return status === true ? 'on' : 'off';
    }
}

export { PaymentService };
