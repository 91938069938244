import {
    FetchedChargeEventDetailsResponse,
    IApplicationState,
    IBillHistoryResponse,
    INextDueDate,
} from 'models';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { BillService } from 'services';
import {
    ActionType,
    fetchBillHistoryError,
    fetchBillHistorySuccess,
    IFetchBillDetailsAction,
    loadBillDetails,
    reportFetchBillDetailsError,
    reportFetchCurrentBillError,
    reportFetchCurrentBillSuccess,
} from 'state/bill';
import { getToken as getFirebaseToken } from 'state/firebase/firebase.saga';
import { trackError } from 'state/tealium';

function* fetchBillHistory() {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getFirebaseToken);
        const response: IBillHistoryResponse = yield call(
            BillService.fetchBillHistory,
            basePaths,
            firebaseToken,
        );
        yield put(fetchBillHistorySuccess(response));
    } catch (error) {
        yield put(fetchBillHistoryError(error.message));
    }
}

function* fetchCurrentBill() {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getFirebaseToken);
        const response: INextDueDate = yield call(
            BillService.fetchCurrentBill,
            basePaths,
            firebaseToken,
        );
        yield put(reportFetchCurrentBillSuccess(response));
        return [true, null];
    } catch (error) {
        yield put(reportFetchCurrentBillError(error.message));
        return [false, (error as Error).message];
    }
}

function* fetchBillDetails(action: IFetchBillDetailsAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getFirebaseToken);
        const detail: FetchedChargeEventDetailsResponse = yield call(
            BillService.fetchBillDetails,
            basePaths,
            firebaseToken,
            action.payload,
        );
        yield put(loadBillDetails({ eventId: action.payload, detail }));
    } catch (err) {
        // tslint:disable-next-line:no-console
        yield put(
            reportFetchBillDetailsError({
                error: (err as Error).message,
                id: action.payload,
            }),
        );
    }
}

function* billHistorySaga() {
    yield all([
        takeEvery(ActionType.FETCH_BILL_HISTORY, fetchBillHistory),
        takeEvery(ActionType.FETCH_CURRENT_BILL, fetchCurrentBill),
        takeEvery(ActionType.FETCH_BILL_DETAILS, fetchBillDetails),
        takeEvery(ActionType.REPORT_FETCH_BILL_HISTORY_ERROR, trackError),
        takeEvery(ActionType.REPORT_FETCH_CURRENT_BILL_ERROR, trackError),
        takeEvery(ActionType.REPORT_FETCH_BILL_DETAILS_ERROR, trackError),
    ]);
}

export { billHistorySaga, fetchCurrentBill };
