import { createAction, IAction } from 'lib/br-redux';
import {
    IDeviceCompatibilityPayload,
    IDeviceListCarriersModels,
} from 'models/device';
import { IDeviceCompatibilityResponse } from '../../../models/device/device.model';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    CHECK = 'DEVICE/CHECK_DEVICE_COMPATIBILITY',
    CLEAR = 'DEVICE/CLEAR_CHECK_DEVICE_COMPATIBILITY',
    CLEAR_FETCH_STATUS = 'DEVICE/CLEAR_FETCH_STATUS',
    SKIP = 'DEVICE/SKIP_CHECK_DEVICE_COMPATIBILITY',
    LOCKED = 'DEVICE/LOCKED_CHECK_DEVICE_COMPATIBILITY',
    LOAD = 'DEVICE/LOAD_DEVICE_CARRIERS_MODELS',
    REPORT_CHECK_DEVICE_COMPATIBILITY_SUCCESS = 'DEVICE/REPORT_CHECK_DEVICE_COMPATIBILITY_SUCCESS',
    REPORT_CHECK_DEVICE_COMPATIBILITY_ERROR = 'DEVICE/REPORT_CHECK_DEVICE_COMPATIBILITY_ERROR',
    REPORT_LOAD_DEVICE_CARRIERS_MODELS_SUCCESS = 'DEVICE/REPORT_LOAD_DEVICE_CARRIERS_MODELS_SUCCESS',
    REPORT_LOAD_DEVICE_CARRIERS_MODELS_ERROR = 'DEVICE/REPORT_LOAD_DEVICE_CARRIERS_MODELS_ERROR',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type IDeviceCompatibilityCheckAction = IAction<
    ActionType.CHECK,
    IDeviceCompatibilityPayload
>;

type IDeviceCompatibilityClearAction = IAction<ActionType.CLEAR>;

type IDeviceCompatibilityClearFetchStatusAction = IAction<
    ActionType.CLEAR_FETCH_STATUS
>;

type IDeviceCompatibilitySkipAction = IAction<ActionType.SKIP>;

type IDeviceCompatibilityLockedAction = IAction<ActionType.LOCKED>;

type IDeviceLoadCarriersModelsAction = IAction<ActionType.LOAD>;

type IReportDeviceCompatibilitySuccessAction = IAction<
    ActionType.REPORT_CHECK_DEVICE_COMPATIBILITY_SUCCESS,
    IDeviceCompatibilityResponse
>;

type IReportDeviceCompatibilityErrorAction = IAction<
    ActionType.REPORT_CHECK_DEVICE_COMPATIBILITY_ERROR,
    string
>;

type IReportLoadDeviceCarriersModelsSuccessAction = IAction<
    ActionType.REPORT_LOAD_DEVICE_CARRIERS_MODELS_SUCCESS,
    IDeviceListCarriersModels
>;

type IReportLoadDeviceCarriersModelsErrorAction = IAction<
    ActionType.REPORT_LOAD_DEVICE_CARRIERS_MODELS_ERROR,
    string
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IDeviceCompatibilityAction =
    | IDeviceCompatibilityCheckAction
    | IDeviceCompatibilityClearAction
    | IDeviceCompatibilityClearFetchStatusAction
    | IDeviceCompatibilitySkipAction
    | IDeviceCompatibilityLockedAction
    | IReportDeviceCompatibilityErrorAction
    | IReportDeviceCompatibilitySuccessAction
    | IDeviceLoadCarriersModelsAction
    | IReportLoadDeviceCarriersModelsSuccessAction
    | IReportLoadDeviceCarriersModelsErrorAction;

const checkDevice = createAction(
    ActionType.CHECK,
    (payload: IDeviceCompatibilityPayload) => payload,
);
const clearCheckDevice = createAction(ActionType.CLEAR);
const clearFetchStatus = createAction(ActionType.CLEAR_FETCH_STATUS);
const skipCheckDevice = createAction(ActionType.SKIP);
const lockedCheckDevice = createAction(ActionType.LOCKED);
const loadCarriersModels = createAction(ActionType.LOAD);
const reportDeviceCompatibilitySuccess = createAction(
    ActionType.REPORT_CHECK_DEVICE_COMPATIBILITY_SUCCESS,
    (payload: IDeviceCompatibilityResponse) => payload,
);
const reportDeviceCompatibilityError = createAction(
    ActionType.REPORT_CHECK_DEVICE_COMPATIBILITY_ERROR,
    (er: string) => er,
);
const reportLoadDeviceCarriersModelsSuccess = createAction(
    ActionType.REPORT_LOAD_DEVICE_CARRIERS_MODELS_SUCCESS,
    (payload: IDeviceListCarriersModels) => payload,
);
const reportLoadDeviceCarriersModelsError = createAction(
    ActionType.REPORT_LOAD_DEVICE_CARRIERS_MODELS_ERROR,
    (er: string) => er,
);

export {
    ActionType,
    IDeviceCompatibilityAction,
    IDeviceCompatibilityClearFetchStatusAction,
    IReportDeviceCompatibilityErrorAction,
    IReportDeviceCompatibilitySuccessAction,
    IDeviceCompatibilityCheckAction,
    IDeviceCompatibilityClearAction,
    checkDevice,
    clearFetchStatus,
    clearCheckDevice,
    loadCarriersModels,
    lockedCheckDevice,
    skipCheckDevice,
    reportDeviceCompatibilityError,
    reportDeviceCompatibilitySuccess,
    reportLoadDeviceCarriersModelsError,
    reportLoadDeviceCarriersModelsSuccess,
};
