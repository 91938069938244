import { createAction, IAction } from 'lib/br-redux';
import { ILead } from 'models';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    CAPTURE_LEAD = 'LEAD/CAPTURE_LEAD',
    CLEAR = 'LEAD/CLEAR_CAPTURE_LEAD',
    SHOW_LEAD_CAPTURE_MODAL = 'LEAD/SHOW_LEAD_CAPTURE_MODAL',
    REPORT_LEAD_CAPTURE_SUCCESS = 'LEAD/REPORT_LEAD_CAPTURE_SUCCESS',
    REPORT_LEAD_CAPTURE_ERROR = 'LEAD/REPORT_LEAD_CAPTURE_ERROR',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type ICaptureLeadAction = IAction<ActionType.CAPTURE_LEAD, ILead>;
type IClearCaptureLeadAction = IAction<ActionType.CLEAR>;
type IShowLeadCaptureModalAction = IAction<
    ActionType.SHOW_LEAD_CAPTURE_MODAL,
    string
>;
type IReportLeadCapturSuccessAction = IAction<
    ActionType.REPORT_LEAD_CAPTURE_SUCCESS
>;
type IReportLeadCaptureErrorAction = IAction<
    ActionType.REPORT_LEAD_CAPTURE_ERROR,
    string
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type ILeadAction =
    | ICaptureLeadAction
    | IClearCaptureLeadAction
    | IShowLeadCaptureModalAction
    | IReportLeadCaptureErrorAction
    | IReportLeadCapturSuccessAction;

// ------------------------------------
// Action Creators
// i.e. the stuff that actually makes it to the compiled Javascript
// ------------------------------------

const captureLead = createAction(
    ActionType.CAPTURE_LEAD,
    (lead: ILead) => lead,
);
const clearCaptureLead = createAction(ActionType.CLEAR);
const showLeadCaptureModal = createAction(
    ActionType.SHOW_LEAD_CAPTURE_MODAL,
    (email: string) => email,
);
const reportLeadCaptureError = createAction(
    ActionType.REPORT_LEAD_CAPTURE_ERROR,
    (err: string) => err,
);

const reportLeadCaptureSuccess = createAction(
    ActionType.REPORT_LEAD_CAPTURE_SUCCESS,
);

export {
    ActionType,
    ICaptureLeadAction,
    IReportLeadCaptureErrorAction,
    IReportLeadCapturSuccessAction,
    ILeadAction,
    captureLead,
    clearCaptureLead,
    showLeadCaptureModal,
    reportLeadCaptureError,
    reportLeadCaptureSuccess,
};
