import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./affirm.less');

@CSSModules(styles)
class Affirm extends React.Component<{}> {
    render() {
        return <>{(window.location.href = __('routes.affirm'))}</>;
    }
}

export { Affirm };
