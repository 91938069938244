import {
    createDefaultCRUDReducer,
    CRUDBaseState,
    FetchStatus,
} from 'lib/br-redux';
import { PaymentKind, PaymentMethod } from 'models';
import { UnknownPaymentMethod } from 'models/payment-method/payment-method.model';
import { ActionType, IDefaultPaymentAction } from './default-method.actions';

interface IDefaultPaymentState extends CRUDBaseState {
    readonly paymentMethod: PaymentMethod;
}

const DEFAULT_STATE: IDefaultPaymentState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
    paymentMethod: {
        kind: PaymentKind.UNKNOWN,
        token: '',
    } as UnknownPaymentMethod,
};

const defaultPaymentMethodReducer = createDefaultCRUDReducer({
    DEFAULT_STATE,
    additionalCases: [
        {
            callback: (
                state: IDefaultPaymentState,
                action: IDefaultPaymentAction,
            ) => ({
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            }),
            type: ActionType.UPDATE_DEFAULT_PAYMENT_METHOD,
        },
    ],
    errorAction: ActionType.REPORT_DEFAULT_PAYMENT_METHOD_ERROR,
    fetchAction: ActionType.GET_DEFAULT_PAYMENT_METHOD,
    successAction: ActionType.LOAD_DEFAULT_PAYMENT_METHOD,
});

export { defaultPaymentMethodReducer, IDefaultPaymentState, DEFAULT_STATE };
