import { PortNumber } from 'components/sign-up/components/phone-number/port-number';
import { IApplicationState } from 'models';
import { ProductCollection } from 'models/products';
import { ICartItemPayload } from 'models/products/products.model';
import { connect } from 'react-redux';
import { CarriersActions } from 'state/purchase/port/port.actions';
import { CartActions } from 'state/purchase/purchase.actions';

const { clearCarriers, fetchCarriers } = CarriersActions;
const { addItemToCart } = CartActions;

const mapStateToProps = ({ products, purchase }: IApplicationState) => ({
    carriers: purchase.port.carriers.results,
    carriersError: purchase.port.carriers.error,
    carriersFetchStatus: purchase.port.carriers.fetchStatus,
    cart: purchase.cart.result,
    cartError: purchase.cart.error,
    cartFetchStatuses: new Set([
        purchase.cart.retrieveFetchStatus,
        purchase.cart.updateFetchStatus,
    ]),
    sim: new ProductCollection(products.results).sim,
});

const mapDispatchToProps = dispatch => ({
    addItemToCart: (item: ICartItemPayload) => dispatch(addItemToCart(item)),
    clearCarriers: () => dispatch(clearCarriers()),
    fetchCarriers: (number: string) => dispatch(fetchCarriers(number)),
});

const PortNumberContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PortNumber);

export { PortNumberContainer };
