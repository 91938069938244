import { IApplicationState } from 'models';
import { push } from 'react-router-redux';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { StayInLoopService } from 'services';
import { trackError } from 'state/tealium';
import { smellyStoreSingleton } from 'utils/store';

import {
    ActionType,
    CaptureStayInLoop,
    reportErrorStayInLoop,
    reportSuccessStayInLoop,
    ShowStayInLoopLanding,
} from './stay-in-loop.actions';

function* captureStayInLoop(action: CaptureStayInLoop) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        yield call(
            StayInLoopService.CaptureStayInLoop,
            action.payload,
            basePaths,
        );
        yield put(reportSuccessStayInLoop());
    } catch (err) {
        yield put(reportErrorStayInLoop((err as Error).message));
    }
}

function* stayInLoopSaga() {
    yield all([
        takeEvery(ActionType.SHOW_STAY_IN_LOOP_LANDING, showStayInLoopLanding),
        takeEvery(ActionType.CAPTURE_STAY_IN_LOOP, captureStayInLoop),
        takeEvery(ActionType.REPORT_ERROR_STAY_IN_LOOP, trackError),
    ]);
}

function* showStayInLoopLanding(action: ShowStayInLoopLanding) {
    const { dispatch } = smellyStoreSingleton.store;
    yield select((state: IApplicationState) => state.stayinloop.emailFooter);
    dispatch(push(__('routes.stay-in-loop')));
}

export { stayInLoopSaga };
