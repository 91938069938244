import { withDeviceType } from 'components/common/device-type';
import { AuthenticatedLinks } from 'components/navigation/authenticated-links';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { startPollEmailVerification } from '../state/authentication/authentication.actions';
import { checkCart } from '../state/account/account.actions';

const mapStateToProps = (state: IApplicationState) => ({
    customerState: state.account.customerState,
});

const mapDispatchToProps = dispatch => ({
    startPollEmailVerification: () => dispatch(startPollEmailVerification()),
    checkCart: () => dispatch(checkCart()),
});

const enhance = compose(
    withDeviceType,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const AuthenticatedLinksContainer = enhance(AuthenticatedLinks);

export { AuthenticatedLinksContainer };
