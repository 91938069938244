import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { PortErrorReason } from 'models/port';
import {
    ActionType as AuthActionType,
    ISignOutAction,
} from 'state/authentication/authentication.actions';
import { ActionType, PortStatusAction } from './port-status.actions';

interface IPortStatusState {
    readonly fetchStatus: FetchStatus;
    readonly error: PortErrorReason;
}

const DEFAULT_STATE: IPortStatusState = {
    error: PortErrorReason.NO_ERROR,
    fetchStatus: FetchStatus.NOT_FETCHED,
};

const {
    FETCH_PORT_STATUS,
    REPORT_FETCH_PORT_STATUS_ERROR,
    REPORT_FETCH_PORT_STATUS_SUCCESS,
} = ActionType;

const portStatus = (
    state: IPortStatusState = DEFAULT_STATE,
    action: PortStatusAction | IDefaultAction,
): IPortStatusState => {
    switch (action.type) {
        case FETCH_PORT_STATUS:
            return {
                error: PortErrorReason.NO_ERROR,
                fetchStatus: FetchStatus.FETCHING,
            };
        case REPORT_FETCH_PORT_STATUS_SUCCESS:
            return { error: action.payload, fetchStatus: FetchStatus.SUCCESS };
        case REPORT_FETCH_PORT_STATUS_ERROR:
            return {
                error: PortErrorReason.NO_ERROR,
                fetchStatus: FetchStatus.ERROR,
            };
        default:
            return state;
    }
};

// Clear this if there is a sign out
const portStatusReducer = (
    state: IPortStatusState | undefined,
    action: ISignOutAction | IDefaultAction,
) => {
    if (action.type === AuthActionType.SIGN_OUT) {
        state = undefined;
    }

    return portStatus(state, action as IDefaultAction);
};

export { IPortStatusState, DEFAULT_STATE, portStatusReducer };
