import { StartupScript } from 'models';
import { all, call, put } from 'redux-saga/effects';
import { StartupActions } from 'state/startup';
import { eventError } from 'state/tealium';

const getScriptSrc = (callbackName: string): string =>
    `https://maps.googleapis.com/maps/api/js?key=${
        process.env.GOOGLE_API_KEY
    }&libraries=places&callback=${callbackName}`;

const setCallbackAndScript = (
    callbackName: string,
    script: HTMLScriptElement,
): Promise<void> =>
    new Promise((resolve, reject) => {
        window[callbackName] = () => resolve();
        script.onerror = error => reject(error);
        document.body.appendChild(script);
    });

function* initialize() {
    try {
        if (typeof process.env.GOOGLE_API_KEY === 'undefined') {
            yield put(
                eventError({
                    error_msg: __(
                        'startup.social.google-api-key-undefined-error',
                    ),
                }),
            );
            throw Error(__('startup.social.google-api-key-undefined-error'));
        }

        const callbackName = `GooglePlatform${new Date().getMilliseconds()}`;
        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.setAttribute('src', getScriptSrc(callbackName));
        yield call(setCallbackAndScript, callbackName, script);
        yield put(
            StartupActions.reportInitializationSuccess(
                StartupScript.GOOGLE_SDK,
            ),
        );
    } catch (err) {
        yield put(
            eventError({
                error_msg: __('startup.social.google-initialization-error'),
            }),
        );
        yield put(
            StartupActions.reportInitializationFailure(
                StartupScript.GOOGLE_SDK,
            ),
        );
    }
}

function* googleSDKSaga() {
    yield all([initialize() as any]);
}

export { googleSDKSaga };
