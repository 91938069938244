import { CartSidebar } from 'components/cart-sidebar';
import { withAnalytics } from 'components/common/analytics';
import { withDeviceType } from 'components/common/device-type';
import { Account, IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { deleteDeviceFromCartConfirm } from 'state/purchase/cart/cart.actions';
import { toggleSidebarCart } from 'state/view/view.actions';

const mapStateToProps = ({
    account,
    purchase: {
        cart: { result },
    },
    view: { sidebarCartOpen },
}: IApplicationState) => ({
    account: new Account(account),
    cart: result,
    open: sidebarCartOpen,
});

const mapDispatchToProps = dispatch => ({
    deleteDeviceFromCartConfirm: () => dispatch(deleteDeviceFromCartConfirm()),
    toggleSidebarCart: () => dispatch(toggleSidebarCart()),
});

const enhance = compose(
    withAnalytics,
    withDeviceType,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const CartSidebarContainer = enhance(CartSidebar);

export { CartSidebarContainer };
