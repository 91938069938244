import { FeedbackSuccessMsg } from 'components/feedback-modal/feedback-modal';
import { IApplicationState } from 'models';
import { delay } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { FeedbackService } from 'services';
import {
    ActionType,
    ISubmitFeedbackAction,
    reportFeedbackError,
    reportFeedbackSuccess,
} from 'state/feedback/feedback.actions';
import { getToken } from 'state/firebase/firebase.saga';
import { trackError } from 'state/tealium';
import { dismissModal, showModal } from 'state/view/view.actions';

function* submitFeedback(action: ISubmitFeedbackAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);
        const account = yield select(
            ({ account }: IApplicationState) => account,
        );
        yield call(
            FeedbackService.submitFeedback,
            basePaths,
            firebaseToken,
            action.payload,
            account.accountid,
        );
        yield put(reportFeedbackSuccess());
        yield put(
            showModal({
                additionalProps: {},
                component: FeedbackSuccessMsg as any, // todo
            }),
        );
        yield call(delay, 1500);
        yield put(dismissModal());
    } catch (err) {
        yield put(reportFeedbackError((err as Error).message));
    }
}

function* feedbackSaga() {
    yield all([
        takeEvery(ActionType.SUBMIT_FEEDBACK, submitFeedback),
        takeEvery(ActionType.REPORT_FEEDBACK_ERROR, trackError),
    ]);
}

export { feedbackSaga };
