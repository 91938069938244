import { PortStatus } from 'models/port/port-status';

enum PortErrorReason {
    NO_ERROR,
    GENERIC_ERROR,
    ACCOUNT_NUMBER,
    INACTIVE_NUMBER,
    PIN_ERROR,
}

enum PortErrorReasonString {
    GENERIC_ERROR = '',

    ACCOUNT_NUMBER = 'PORT-IN RESOLUTION REQUIRED 8A',
    INACTIVE_NUMBER = 'PORT-IN RESOLUTION REQUIRED 6D',
    PIN_ERROR = 'PORT-IN RESOLUTION REQUIRED 8C',
}

class PortError {
    constructor(public status: PortStatus, public remark: string) {}

    get reason(): PortErrorReason {
        if (this.status === PortStatus.ISSUE) {
            const {
                ACCOUNT_NUMBER,
                INACTIVE_NUMBER,
                PIN_ERROR,
            } = PortErrorReasonString;
            switch (this.reasonString) {
                case ACCOUNT_NUMBER:
                    return PortErrorReason.ACCOUNT_NUMBER;
                case INACTIVE_NUMBER:
                    return PortErrorReason.INACTIVE_NUMBER;
                case PIN_ERROR:
                    return PortErrorReason.PIN_ERROR;
                default:
                    return PortErrorReason.GENERIC_ERROR;
            }
        }

        return PortErrorReason.NO_ERROR;
    }

    private get reasonString(): PortErrorReasonString {
        return Object.keys(PortErrorReasonString).reduce(
            (reason: PortErrorReasonString, key: string) =>
                this.remark.indexOf(PortErrorReasonString[key]) > -1
                    ? PortErrorReasonString[key]
                    : reason,
            PortErrorReasonString.GENERIC_ERROR,
        );
    }
}

export { PortError, PortErrorReason, PortErrorReasonString };
