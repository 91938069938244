import { withAnalytics } from 'components/common/analytics';
import { withDeviceType } from 'components/common/device-type';
import { Registered } from 'components/home/registered';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { showAccountSignupModal } from 'state/account';
import { showModal } from 'state/view/view.actions';
import { ViewActions } from '../state/view';
import { IApplicationState } from 'models';
import { deleteDraftOrderFromCart } from 'state/purchase/cart/cart.actions';
import { push } from 'react-router-redux';

const mapStateToProps = ({ purchase }: IApplicationState) => ({
    cart: purchase.cart,
    fetchStatus: purchase.port.resubmission.fetchStatus,
});

const mapDispatchToProps = dispatch => ({
    deleteDraftOrderFromCart: () => dispatch(deleteDraftOrderFromCart()),
    showAccountSignupModal: () => dispatch(showAccountSignupModal()),
    showModal: p => dispatch(showModal(p)),
    dismissModal: () => dispatch(ViewActions.dismissModal()),
    redirectTo: (url: string) => dispatch(push(url)),
});

const enhance = compose(
    withAnalytics,
    withDeviceType,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const RegisteredContainer = enhance(Registered);

export { RegisteredContainer };
