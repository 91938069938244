import { createAction, IAction } from 'lib/br-redux';

import { GroupMembershipDetailResponse } from 'models/account';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    REPORT_FETCH_GROUP_MEMBERS_SUCCESS = 'ACCOUNT/REPORT_FETCH_GROUP_MEMBERS_SUCCESS',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type ReportFetchGroupMembersSuccessAction = IAction<
    ActionType.REPORT_FETCH_GROUP_MEMBERS_SUCCESS,
    GroupMembershipDetailResponse
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IGroupAction = ReportFetchGroupMembersSuccessAction;

// ------------------------------------
// Action Creators
// i.e. the stuff that actually makes it to the compiled Javascript
// ------------------------------------

const fetchGroupMemberStatusSuccess = createAction(
    ActionType.REPORT_FETCH_GROUP_MEMBERS_SUCCESS,
    (groups: GroupMembershipDetailResponse) => groups,
);

export { ActionType, IGroupAction, fetchGroupMemberStatusSuccess };
