import * as React from 'react';

import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { setInviteCode } from 'state/registration/registration.actions';

interface MappedProps {
    setInviteCode(code: string): any;
}

type Props = MappedProps & RouteComponentProps<any>;

class InviteRouteHandler extends React.Component<Props> {
    componentDidMount() {
        const {
            setInviteCode,
            match: { params },
        } = this.props;
        setInviteCode(params.inviteCode);
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        return this.props.location.pathname.indexOf('invite') > -1 ? (
            <Redirect to={__('routes.sign-up')} />
        ) : null;
    }
}

const mapDispatchToProps = dispatch => ({
    setInviteCode: code => dispatch(setInviteCode(code)),
});
const InviteRouteHandlerContainer = connect(
    null,
    mapDispatchToProps,
)(InviteRouteHandler);

export { InviteRouteHandlerContainer };
