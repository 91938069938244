// tslint:disable:no-console
import { IApplicationState } from 'models';
import { IAccount } from 'models/account';
import {
    PageView,
    TealiumPageViewData,
} from 'models/tealium/tealium-data.model';
import { delay } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { all, call, put, select } from 'redux-saga/effects';
import { getIdOrDefault } from 'state/firebase/firebase.saga';
import {
    ActionType,
    eventError,
    IAnalyticsAction,
    pushEventQueue,
} from 'state/tealium';
import { loadState, saveState } from 'utils/localstorage';
import { uuidv4 } from 'utils/uuidv4';
import { popEventQueue } from './tealium.actions';

// todo(dan) There is a 'persist:thin-air' key in localstorage, but we'll keep this separate for now.
const STORE_LOCAL_KEY_GLOBAL_ID = 'thinair_global_id';

// todo(dan) Add to Redux store and hit localstorage only when undefined?
const getGlobalId = (): string =>
    loadState(STORE_LOCAL_KEY_GLOBAL_ID) ||
    saveState(STORE_LOCAL_KEY_GLOBAL_ID, uuidv4());

const getScriptSrc = () =>
    `//tags.tiqcdn.com/utag/${process.env.TEALIUM_ACCOUNT}/${
        process.env.TEALIUM_PROFILE
    }/${process.env.TEALIUM_ENV}/utag.js`;

const setCallbackAndScript = (script: HTMLScriptElement): Promise<void> =>
    new Promise((resolve, reject) => {
        script.onload = () => resolve();
        script.onerror = error => reject(error);
        document.body.insertBefore(script, document.body.firstChild);
    });

function* trackEvent(
    actionType: ActionType,
    payload: any,
    data: TealiumPageViewData,
) {
    switch (actionType) {
        case ActionType.EVENT_ERROR:
        case ActionType.EVENT_LINK:
            yield put(
                pushEventQueue({
                    data: payload,
                    type: actionType,
                }),
            );
            break;
        case ActionType.EVENT_PAGE_VIRTUAL:
            yield put(
                pushEventQueue({
                    data: PageView.forPageName(payload.page_name, data).event(
                        data,
                    ),
                    type: actionType,
                }),
            );
            break;
        case ActionType.EVENT_PAGE_VIEW:
            yield put(
                pushEventQueue({
                    data: PageView.forPath(data.pathname, data).event(data),
                    type: actionType,
                }),
            );
            break;
        case ActionType.EVENT_TEALIUM_LINK:
            if (payload.page_name === '') {
                payload.page_name = __('home.prospective.page-name');
            }
            yield put(
                pushEventQueue({
                    data: payload,
                    type: actionType,
                }),
            );
            break;
        case ActionType.EVENT_TEALIUM_VIEW:
            yield put(
                pushEventQueue({
                    data: payload,
                    type: actionType,
                }),
            );
            break;
        default:
            break;
    }
}

function* track(action: IAnalyticsAction) {
    const token: string = yield call(getIdOrDefault);
    const account: IAccount = yield select(
        (state: IApplicationState) => state.account,
    );
    const inviteCode: string = yield select(
        (state: IApplicationState) =>
            state.registration.prefilledInfo.inviteCode,
    );
    yield call(trackEvent, action.type, action.payload || {}, {
        account,
        globalId: getGlobalId(),
        inviteCode,
        isLoggedIn: token.length > 0,
        pathname: window.location.pathname,
    });
}

function* trackError(action: any) {
    yield put(eventError({ error_msg: action.payload }));
}

function* initialize() {
    try {
        if (window.utag) {
            console.warn('analytics error: tried to load again');
            return;
        }
        if (typeof process.env.TEALIUM_ENV === 'undefined') {
            throw Error('Tealium environment is undefined.');
        }

        // suppress the automatic page tracking to allow for custom tracking of each page
        window.utag_cfg_ovrd = { noview: true };

        // Console Debugging for Tealium
        document.cookie = 'utagdb=false';

        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.type = 'text/javascript';
        script.setAttribute('src', getScriptSrc());
        yield call(setCallbackAndScript, script);
    } catch (err) {
        //
    }
}

function* processEventQueue() {
    while (true) {
        // TODO(dan) This seems like a problematic design pattern.  Couldn't the loop restart before the pop is processed?
        // TODO(dan) This has been increased to 1000 from 100 as it seemed like the loop could restart before a pop.
        yield call(delay, 1000);
        if (window.utag) {
            try {
                const eventQueue = yield select(
                    ({ analytics: { eventQueue } }: IApplicationState) =>
                        eventQueue,
                );
                if (!eventQueue || !eventQueue.length) {
                    continue;
                }
                const event = eventQueue[0];
                // TODO(dan) This pops immediately to favor not publishing event vs publishing more than once.
                yield put(popEventQueue(event));
                switch (event.type) {
                    case ActionType.EVENT_ERROR:
                    case ActionType.EVENT_LINK:
                    case ActionType.EVENT_TEALIUM_LINK:
                        window.utag.link(event.data);
                        break;
                    case ActionType.EVENT_TEALIUM_VIEW:
                        window.utag.view(event.data);
                        break;
                    case ActionType.EVENT_PAGE_VIRTUAL:
                    case ActionType.EVENT_PAGE_VIEW:
                    // window.utag.view(event.data);
                    // break;
                    default:
                        break;
                }
            } catch (err) {
                console.warn('analytics event queue error', err);
            }
        } else {
            console.warn('analytics event queue error: utag not found');
        }
    }
}

function* tealiumSDKSaga() {
    yield all([
        initialize(),
        processEventQueue(),
        takeLatest(ActionType.EVENT_ERROR, track),
        takeLatest(ActionType.EVENT_PAGE_VIEW, track),
        takeLatest(ActionType.EVENT_PAGE_VIRTUAL, track),
        takeLatest(ActionType.EVENT_LINK, track),
        takeLatest(ActionType.EVENT_TEALIUM_LINK, track),
        takeLatest(ActionType.EVENT_TEALIUM_VIEW, track),
    ]);
}

export { tealiumSDKSaga, trackError };
