import { createAction, IAction } from 'lib/br-redux';

enum ActionType {
    PAUSE_SERVICE = 'ACCOUNT?PAUSE?PAUSE_SERVICE',
    REPORT_PAUSE_SUCCESS = 'ACCOUNT/PAUSE/REPORT_PAUSE_SUCCESS',
    REPORT_PAUSE_ERROR = 'ACCOUNT/PAUSE/REPORT_PAUSE_ERROR',
}

type PauseServiceAction = IAction<ActionType.PAUSE_SERVICE, string>;
type ReportPauseSuccessAction = IAction<ActionType.REPORT_PAUSE_SUCCESS>;
type ReportPauseErrorAction = IAction<ActionType.REPORT_PAUSE_ERROR, string>;

type PauseAction =
    | PauseServiceAction
    | ReportPauseErrorAction
    | ReportPauseSuccessAction;

const pauseService = createAction(
    ActionType.REPORT_PAUSE_ERROR,
    (pauseEndDate: string) => pauseEndDate,
);
const reportPauseSuccess = createAction(ActionType.REPORT_PAUSE_SUCCESS);
const reportPauseError = createAction(
    ActionType.REPORT_PAUSE_ERROR,
    (errorMessage: string) => errorMessage,
);

export {
    ActionType,
    PauseServiceAction,
    ReportPauseErrorAction,
    ReportPauseSuccessAction,
    PauseAction,
    pauseService,
    reportPauseError,
    reportPauseSuccess,
};
