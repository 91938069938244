import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';
import { ErrorMsg } from 'components/common/typography/error-msg';
import { splitProps, ValidationResponse } from 'utils';

const eyeIcon = '/assets/images/icons/eye.svg';
const eyeClosedIcon = '/assets/images/icons/eye-closed.svg';
const styles = require('./input.less');

interface Props extends React.HTMLProps<HTMLInputElement>, IAnalyticsProps {
    readonly disabled?: boolean;
    readonly error?: string;
    readonly id: string;
    readonly styleName?: string;
    readonly label?: string;
    readonly description?: string;
    readonly doneTypingInterval?: number;
    readonly onValidate?: (id: string, valid: boolean) => void;
    readonly onRef?: (ref: HTMLInputElement) => void;
    readonly validate?: (value: string | object) => ValidationResponse;
    readonly validateValue?: object;
    readonly optional?: boolean;
    readonly inline?: boolean;
    readonly showLabel?: boolean;
    readonly showDescription?: boolean;
    readonly togglePassword?: boolean;
    readonly showErrors?: boolean;
    readonly readOnly?: boolean;
    readonly readOnlyText?: boolean;
    readonly twoColm?: boolean;
    readonly noMargin?: string;
}

enum Modifiers {
    noMargin = 'noMargin',
    short = 'short',
}

interface InputModifiers extends IAnalyticsProps {
    readonly noMargin?: boolean;
    readonly short?: boolean;
}

type BaseInput = Props & InputModifiers;

interface IState {
    readonly validateErrorMsg: string;
    readonly showPassword: boolean;
}

@CSSModules(styles, { allowMultiple: true })
class Input extends React.Component<BaseInput, IState> {
    // tslint:disable:readonly-keyword
    private doneTypingTimer: any;
    constructor(props) {
        super(props);
        this.state = { showPassword: false, validateErrorMsg: '' };
    }
    componentDidMount() {
        const {
            props: { id, value },
        } = this;

        if (this.props.validate) {
            // Propagate initial state to handlers
            this.handleDoneTyping(this.normalizeValue(value), id)();
        }
    }
    componentWillUnmount() {
        this.handleOnKeyDown();
    }
    componentWillReceiveProps(newProps: Props): void {
        if (this.props.validate && newProps.value !== this.props.value) {
            this.handleOnKeyDown();

            const { id, value } = newProps;
            this.handleOnKeyUp(this.normalizeValue(value), id);
        }
    }
    render(): JSX.Element {
        const {
            handleToggleShowPassword,
            props: {
                type,
                id,
                error,
                description,
                showDescription,
                label,
                showLabel,
                styleName,
                value,
                onRef,
                optional,
                inline,
                noMargin,
                togglePassword,
                tabIndex,
                showErrors = true,
                short,
                disabled,
                readOnly,
                readOnlyText,
                twoColm,
                validate,
                onValidate,
                trackError,
                createTealiumLinkHandler,
                createTealiumViewHandler,
                validateValue,
                ...props
            },
            state: { showPassword, validateErrorMsg },
        } = this;
        const [modifiers, other] = splitProps(props, Modifiers);

        return (
            <div
                styleName={classNames(
                    'wrapper',
                    { inline, short, noMargin },
                    { twoColm: twoColm ? 'twoColm' : '' },
                    modifiers,
                    styleName,
                )}
            >
                <span styleName={classNames('inner-wrapper')}>
                    {showLabel && (
                        <label styleName="label" htmlFor={id}>
                            {label}
                        </label>
                    )}

                    {showDescription && (
                        <div styleName="description">{description}</div>
                    )}

                    <input
                        styleName={classNames('input', {
                            disabled,
                            error: showErrors && (validateErrorMsg || error),
                            readOnly,
                            readOnlyText,
                            twoColm,
                            short,
                        })}
                        type={showPassword ? 'text' : type || 'text'}
                        id={id}
                        name={id}
                        value={value || ''}
                        ref={onRef}
                        placeholder={label}
                        {...other}
                    />

                    {togglePassword && (
                        <img
                            tabIndex={tabIndex}
                            onClick={handleToggleShowPassword}
                            onKeyDown={handleToggleShowPassword}
                            styleName="toggle-password"
                            src={showPassword ? eyeIcon : eyeClosedIcon}
                            title={
                                showPassword ? 'Hide Password' : 'Show Password'
                            }
                        />
                    )}

                    <span
                        styleName={classNames('slider', {
                            disabled,
                            readOnly,
                        })}
                    />
                </span>
                {showErrors && <ErrorMsg>{validateErrorMsg || error}</ErrorMsg>}
            </div>
        );
    }
    private readonly handleDoneTyping = (value: string, id: string) => () => {
        const {
            props: { onValidate, trackError, validate, validateValue },
        } = this;
        if (validate) {
            // Validate Input with validate function
            // Pass off an overridden value (object usually) or the string value to the validator
            const {
                errorMessage: validateErrorMsg,
                valid,
            }: ValidationResponse = validate(validateValue || value);
            if (onValidate) {
                onValidate(id, valid);
            }
            // Don't show an error message when the value is blank
            const newValidateErrorMsg = value === '' ? '' : validateErrorMsg;
            // Clear errors when the Input is empty
            this.setState({
                validateErrorMsg: newValidateErrorMsg,
            });
            if (newValidateErrorMsg) {
                trackError(newValidateErrorMsg);
            }
        }
    };
    private readonly handleOnKeyUp = (value: string, id: string) => {
        const {
            props: { doneTypingInterval = 1000 },
        } = this;

        clearTimeout(this.doneTypingTimer);
        // Clear local errors immediately
        this.setState({
            validateErrorMsg: '',
        });
        this.doneTypingTimer = setTimeout(
            this.handleDoneTyping(value, id),
            doneTypingInterval,
        );
    };
    private readonly handleOnKeyDown = () => {
        if (this.doneTypingTimer) {
            clearTimeout(this.doneTypingTimer);
        }
        this.doneTypingTimer = false;
    };
    private readonly handleToggleShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    private normalizeValue(value): string {
        return (value || '').toString();
    }
}

export { Input };
