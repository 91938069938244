import { combineReducers } from 'redux';
import {
    billHistoryReducer,
    DEFAULT_STATE as DEFAULT_HISTORY,
    IBillHistoryState,
} from './bill-history.reducer';
import {
    currentBillReducer,
    DEFAULT_STATE as DEFAULT_CURRENT,
    ICurrentBillState,
} from './current-bill.reducer';

const billReducer = combineReducers({
    currentBill: currentBillReducer,
    history: billHistoryReducer,
});

interface IBillState {
    readonly currentBill: ICurrentBillState;
    readonly history: IBillHistoryState;
}

const DEFAULT_STATE: IBillState = {
    currentBill: DEFAULT_CURRENT,
    history: DEFAULT_HISTORY,
};

export { billReducer, IBillState, DEFAULT_STATE };
