import * as Emoji from 'components/common/graphics/emoji';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Headline } from 'components/common/typography';
import { BodyCopy } from 'components/common/typography/bodycopy/bodycopy';
import { EmailActionHandler } from 'components/email-action-handler';
import { FetchStatus } from 'lib/br-redux';

interface Props {}

class VerifyEmailComponent extends EmailActionHandler<Props, {}> {
    render() {
        const { verificationStatus } = this.props;
        if (verificationStatus === FetchStatus.SUCCESS) {
            return this.successMessage;
        }

        if (verificationStatus === FetchStatus.ERROR) {
            return this.errorMessage;
        }

        return <div />;
    }

    private get successMessage(): JSX.Element {
        return (
            <div styleName="verify-container">
                <Emoji.D styleName="emoji" />
                <Headline two>{__('verify-email.success-message')}</Headline>
            </div>
        );
    }

    private get errorMessage(): JSX.Element {
        return (
            <div styleName="verify-container">
                <Emoji.T styleName="emoji" />
                <Headline two>{__('verify-email.error-title')}</Headline>
                <BodyCopy styleName="error-message">
                    {__('verify-email.error-message')}
                </BodyCopy>
            </div>
        );
    }
}

const VerifyEmail = CSSModules(
    VerifyEmailComponent,
    require('./verify-email.less'),
);
export { VerifyEmail };
