import * as React from 'react';

import { IQueueableNotice } from 'models';

interface Props {
    readonly noticeQueue: ReadonlyArray<IQueueableNotice>;
}

class NoticePresenter extends React.Component<Props, {}> {
    render() {
        if (this.next) {
            const additionalProps = this.next.additionalProps || {};
            return React.createElement(
                this.next.component as React.ComponentClass<any>,
                {
                    ...additionalProps,
                },
            );
        }

        return null;
    }

    private get next(): IQueueableNotice | null {
        if (this.props.noticeQueue && this.props.noticeQueue.length) {
            return this.props.noticeQueue[0];
        }

        return null;
    }
}

export { NoticePresenter };
