abstract class JsonService {
    /**
     * Used to handle most common use case for a service (fetch data, and deserialize the response as JSON).
     * This expects the response to be in the 200 range, otherwise it throws an error.
     * @param url Fully qualified URL the request is sent to.
     * @param fetchConfig An optional configuration object for the fetch api.
     * @returns A deserialized response expected to conform to Model
     */
    protected static async fetchJson<Model>(
        url: string,
        fetchConfig: RequestInit = {},
    ): Promise<Model> {
        return fetch(url, fetchConfig)
            .then(JsonService._ensure200)
            .then(response => response.json());
    }

    /**
     * Checks response status code for a 200.
     * Throws error if status is out of range.
     * @param response from server.
     * @returns {Promise<any>} returned promise.
     * @private
     */
    private static async _ensure200(response: Response): Promise<Response> {
        if (response.status >= 300) {
            try {
                // Try to parse response body for error details.
                // Reject with error if possible.
                const error = await response.json();
                return Promise.reject(error);
            } catch (err) {
                // Could not parse response body.
                // Throw generic error.
                throw new Error(
                    `Response status code out of range: ${response.status}`,
                );
            }
        }

        // Everything is cool.
        return response;
    }
}

export default JsonService;
