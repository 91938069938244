import * as React from 'react';

import { ResubmissionType } from 'components/port-fallout/base-resubmission-form';
import { BaseResubmissionFormContainer as BaseResubmissionForm } from 'containers';

const PhoneNumberResubmissionForm = () => (
    <BaseResubmissionForm
        title={__('phone-number-resubmission.title')}
        message={__('phone-number-resubmission.message')}
        label={__('phone-number-resubmission.label')}
        type={ResubmissionType.PHONE_NUMBER}
    />
);

export { PhoneNumberResubmissionForm };
