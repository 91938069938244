import { createStateMerger, FetchStatus, IDefaultAction } from 'lib/br-redux';
import { ActionType, IMdnAction } from './mdn.actions';

interface IMdnState {
    readonly fetchStatus: FetchStatus;
    readonly error: string;
    readonly value: string;
}

const DEFAULT_STATE = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
    value: '',
};

const { FETCH_MDN, LOAD_MDN, REPORT_MDN_ERROR, CLEAR_MDN } = ActionType;

const mdnReducer = (
    state: IMdnState = DEFAULT_STATE,
    action: IMdnAction | IDefaultAction,
): IMdnState => {
    const merge = createStateMerger(state);
    switch (action.type) {
        case FETCH_MDN:
            return merge({ fetchStatus: FetchStatus.FETCHING });
        case LOAD_MDN:
            return merge({
                fetchStatus: FetchStatus.SUCCESS,
                value: action.payload,
            });
        case REPORT_MDN_ERROR:
            return merge({
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            });
        case CLEAR_MDN:
            return state;
        default:
            return state;
    }
};

export { DEFAULT_STATE, IMdnState, mdnReducer };
