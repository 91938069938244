import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { BodyCopy } from 'components/common/typography';
import {
    ButtonContainer as Button,
    ModalWrapperContainer as ModalWrapper,
} from 'containers';
import { IAnalyticsProps, IModalProps } from 'models';

const styles = require('./errors.less');

interface IInactiveNumberErrorProps extends IAnalyticsProps, IModalProps {
    onButton(): void;
}

@CSSModules(styles, { allowMultiple: true })
class InactiveNumberError extends React.Component<IInactiveNumberErrorProps> {
    render() {
        return (
            <ModalWrapper
                title={__('phone-number-errors.inactive-number.title')}
            >
                <>
                    <div styleName="content">
                        <BodyCopy>
                            {__('phone-number-errors.inactive-number.body')}
                        </BodyCopy>
                    </div>
                    <div>
                        <Button
                            onClick={this.onButtonClick}
                            label={__(
                                'phone-number-errors.inactive-number.button-text',
                            )}
                        />
                    </div>
                </>
            </ModalWrapper>
        );
    }

    private readonly onButtonClick = () => {
        const {
            props: { onClose, onButton },
        } = this;
        onClose();
        onButton();
    };
}

export { InactiveNumberError, IInactiveNumberErrorProps };
