import { ReferralMessage } from 'components/account/referral-message/referral-message';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';

const mapStateToProps = (state: IApplicationState) => ({
    refCode: state.account.refCode,
    referralLink: state.account.referralLink,
});

const enhance = connect(mapStateToProps);
const ReferralMessageContainer = enhance(ReferralMessage);

export { ReferralMessageContainer };
