import * as React from 'react';

interface Props extends React.HTMLProps<SVGSVGElement> {}

const Hamburger = (props: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="26"
        viewBox="0 0 33 26"
        {...props}
    >
        <g
            fill="none"
            fillRule="evenodd"
            stroke="#1800FF"
            strokeLinecap="square"
            strokeWidth="3.5"
        >
            <path d="M21.028 21.167H5M27.738 13.167H5M21.028 5.167H5" />
        </g>
    </svg>
);

export { Hamburger };
