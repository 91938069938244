import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';
import { CheckboxOption } from 'components/common/forms';
import { BodyCopy, InfoLink } from 'components/common/typography';
import { ButtonContainer as Button } from 'containers';
import { FetchStatus } from 'lib/br-redux';

const styles = require('./summary.less');

interface MappedProps extends IAnalyticsProps {
    readonly checkoutStatus: FetchStatus;
    readonly paymentNonce: string;
    checkout(): void;
}

type Props = MappedProps;

interface State {
    readonly agreesToTerms;
}

const DEFAULT_STATE: State = {
    agreesToTerms: false,
};

@CSSModules(styles, { allowMultiple: true })
class Summary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    render() {
        const {
            props: { checkoutStatus },
            state: { agreesToTerms },
        } = this;

        return (
            <div styleName="wrapper">
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <div styleName="col">
                            <BodyCopy>
                                {__('sign-up.summary.main-text')}
                            </BodyCopy>
                            <InfoLink
                                target="_blank"
                                element="a"
                                styleName="net-neutrality"
                                to={__('routes.help.broadband')}
                            >
                                {__('sign-up.summary.net-neutrality')}
                            </InfoLink>
                            <CheckboxOption
                                id="terms-box"
                                checked={agreesToTerms}
                                onChange={this.handleTermsChange}
                            >
                                <span>
                                    {__(
                                        'sign-up.summary.agrees-to-terms-label.agree',
                                    )}
                                    <a
                                        styleName="label-link"
                                        target="_blank"
                                        href={__('routes.terms-and-conditions')}
                                    >
                                        {__(
                                            'sign-up.summary.agrees-to-terms-label.terms',
                                        )}
                                    </a>
                                    {__(
                                        'sign-up.summary.agrees-to-terms-label.and',
                                    )}
                                    <a
                                        styleName="label-link"
                                        href={__('routes.privacy')}
                                        target="_blank"
                                    >
                                        {__(
                                            'sign-up.summary.agrees-to-terms-label.privacy',
                                        )}
                                    </a>
                                </span>
                            </CheckboxOption>
                        </div>
                    </div>
                    <div>
                        <div styleName="confirm-wrapper">
                            <Button
                                fullWidth
                                styleName="button"
                                type="submit"
                                loading={
                                    checkoutStatus === FetchStatus.FETCHING
                                }
                                disabled={!agreesToTerms}
                                label={__('confirm')}
                            />
                            <span styleName="privacy-statement">
                                {__('sign-up.summary.privacy-label')}
                            </span>
                        </div>
                    </div>
                </form>
                {this.screen}
            </div>
        );
    }

    private get screen(): JSX.Element | null {
        if (!this.props.paymentNonce || !this.props.paymentNonce.trim()) {
            return (
                <div
                    onClick={e => e.stopPropagation()}
                    styleName="disabled-screen"
                />
            );
        }

        return null;
    }

    private readonly handleSubmit = (
        event: React.FormEvent<HTMLFormElement>,
    ): void => {
        event.preventDefault();
        // tslint:disable-next-line:variable-name
        const { checkout } = this.props;
        const { agreesToTerms } = this.state;
        if (agreesToTerms) {
            checkout();
        }

        return;
    };

    private readonly handleTermsChange = (agreesToTerms: boolean) => {
        this.setState({ agreesToTerms });
    };
}

export { Summary };
