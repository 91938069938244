import { PreActive } from 'components/account/pay-bill/pre-active';
import { withAnalytics } from 'components/common/analytics';
import { withDeviceType } from 'components/common/device-type';
import { Account, IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { resendVerificationEmail } from 'state/authentication/authentication.actions';

const portingIssue = notice => {
    return (
        (notice.additionalProps && notice.additionalProps.generalPortError) ||
        (notice.additionalProps && notice.additionalProps.mdnCounterEnabled) ||
        (notice.additionalProps && notice.additionalProps.pinCounterEnabled)
    );
};

const mapStateToProps = ({
    account,
    groups,
    configuration,
    purchase,
    view,
}: IApplicationState) => ({
    account: new Account(account),
    groups: groups.groups,
    configuration: configuration,
    portIssue: view.noticeQueue.find(portingIssue),
    cart: purchase.cart.result,
});

const mapDispatchToProps = dispatch => ({
    resendVerificationEmail: () => dispatch(resendVerificationEmail()),
});

const enhance = compose(
    withAnalytics,
    withDeviceType,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const PreActiveContainer = enhance(PreActive);

export { PreActiveContainer };
