import { FetchStatus, InitializationStatus } from 'lib/br-redux';
import { IApplicationState, StartupScript } from 'models';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { authInit } from 'state/authentication/authentication.actions';
import { ViewActions } from 'state/view';
import { loadScripts } from './scripts';
import { ActionType } from './startup.actions';

const key = 'init-loader';
const { FIREBASE, CONFIGURATION, AUTHENTICATION } = StartupScript;

function* initialize() {
    try {
        yield put(ViewActions.requestLoadingIndicator(key));
        yield call(loadScripts);
    } catch (err) {
        yield put(ViewActions.dismissLoadingIndicator(key));
    }
}

const REQUIRED_FOR_LOADING_TEARDOWN: ReadonlyArray<any> = [
    CONFIGURATION,
    FIREBASE,
    AUTHENTICATION,
];

function* checkAppInitState() {
    try {
        const [startup, authFetchStatus, authInitStatus] = yield select(
            (state: IApplicationState) => [
                state.startup,
                state.authentication.fetchStatus,
                state.authentication.initializationStatus,
            ],
        );

        const isConfigPending: boolean =
            startup.pending.indexOf(CONFIGURATION) !== -1;
        const isFirebasePending: boolean =
            startup.pending.indexOf(FIREBASE) !== -1;
        const canInitAuth: boolean =
            authFetchStatus === FetchStatus.NOT_FETCHED &&
            authInitStatus === InitializationStatus.INITIALIZING;

        // If firebase init and config init success/failure, call auth init
        if (!isConfigPending && !isFirebasePending && canInitAuth) {
            yield put(authInit());
        }
        if (
            REQUIRED_FOR_LOADING_TEARDOWN.every(
                i => startup.initialized.indexOf(i) > -1,
            )
        ) {
            yield put(ViewActions.dismissLoadingIndicator(key));
        }
    } catch (error) {
        yield put(ViewActions.dismissLoadingIndicator(key));
    }
}

function* startupSaga() {
    yield all([
        takeEvery(ActionType.REPORT_INITIALIZATION_SUCCESS, checkAppInitState),
        takeEvery(ActionType.REPORT_INITIALIZATION_FAILURE, checkAppInitState),
        initialize() as any,
    ]);
}

export { startupSaga };
