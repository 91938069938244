import * as React from 'react';

interface IProps {
    handleSubmit(payload: any): void;
}

class BaseForm<P extends IProps, S> extends React.Component<P, S> {
    constructor(props) {
        super(props);
    }

    protected readonly handleChangeFor = (key: string) => (
        e: React.FormEvent<HTMLInputElement>,
    ): void => {
        const value = (e.target as HTMLInputElement).value;
        const state = {};
        state[key] = value;
        this.setState(state);
    };

    protected readonly handleSubmit = (payload: any) => (
        e: React.FormEvent<HTMLFormElement>,
    ): void => {
        e.preventDefault();
        this.props.handleSubmit(payload);
    };
}

export { BaseForm };
