import { createAction, IAction } from 'lib/br-redux';
import { IConfiguration } from 'models';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    FETCH = 'CONFIGURATION/FETCH',
    LOAD = 'CONFIGURATION/LOAD',
    REPORT_SUCCESS = 'CONFIGURATION/REPORT_SUCCESS',
    REPORT_ERROR = 'CONFIGURATION/REPORT_ERROR',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type IFetchConfigurationAction = IAction<ActionType.FETCH>;
type ILoadConfigurationAction = IAction<ActionType.LOAD, IConfiguration>;

type IReportSuccessAction = IAction<ActionType.REPORT_SUCCESS>;
type IReportErrorAction = IAction<ActionType.REPORT_ERROR, string>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IConfigurationAction =
    | IFetchConfigurationAction
    | ILoadConfigurationAction
    | IReportSuccessAction
    | IReportErrorAction;

// ------------------------------------
// Action Creators
// i.e. the stuff that actually makes it to the compiled Javascript
// ------------------------------------

const fetchConfiguration = createAction(ActionType.FETCH);
const loadConfiguration = createAction(
    ActionType.LOAD,
    (config: IConfiguration) => config,
);
const reportConfigurationError = createAction(
    ActionType.REPORT_ERROR,
    (error: string) => error,
);
const reportConfigurationSuccess = createAction(ActionType.REPORT_SUCCESS);

export {
    ActionType,
    IFetchConfigurationAction,
    ILoadConfigurationAction,
    IReportErrorAction,
    IConfigurationAction,
    fetchConfiguration,
    loadConfiguration,
    reportConfigurationSuccess,
    reportConfigurationError,
};
