// tslint:disable-next-line:no-reference
/// <reference path="../@types.custom/index.d.ts" />
import * as React from 'react';

import { LoadingIndicator } from 'components/loading-indicator';
import { IApplicationState } from 'models';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Store } from 'redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { configureStore } from 'state';
import { smellyStoreSingleton } from 'utils/store';
import { Root } from './root';

const app = document.getElementById('app');
const [store, history, persistor] = configureStore();
smellyStoreSingleton.setStoreForUseByIndexFileOnly(store as Store<
    IApplicationState
>);
render(
    <AppContainer>
        <PersistGate
            loading={<LoadingIndicator visible />}
            persistor={persistor}
        >
            <Root store={store} history={history} />
        </PersistGate>
    </AppContainer>,
    app,
);

if ((module as any).hot) {
    (module as any).hot.accept('./root', () => {
        const { Root: RootContainer } = require('./root');
        render(
            <AppContainer>
                <PersistGate
                    loading={<LoadingIndicator visible />}
                    persistor={persistor}
                >
                    <RootContainer store={store} history={history} />
                </PersistGate>
            </AppContainer>,
            app,
        );
    });
}
