import { AccountNav } from 'components/account/account-nav';
import { withAnalytics } from 'components/common/analytics';
import { withDeviceType } from 'components/common/device-type';
import { IApplicationState } from 'models';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'react-router-redux';

const mapDispatchToProps = dispatch => ({
    redirectTo: (url: string) => dispatch(push(url)),
});

const mapStateToProps = ({
    account,
    purchase,
    bill,
    referral,
    configuration,
    groups,
}: IApplicationState) => ({
    activeAccRefCodeCounter: account.activeAccRefCodeCounter,
    billFetchStatus: bill.currentBill.fetchStatus,
    cart: purchase.cart.result,
    configuration: configuration,
    creditBalance: referral.creditBalance,
    customerState: account.customerState,
    dueDate: bill.currentBill.dueDate,
    refCode: account.refCode,
    referralLink: account.referralLink,
    account: account,
    groups: groups.groups,
});

const enhance = compose(
    withAnalytics,
    withDeviceType,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const AccountNavContainer = enhance(AccountNav);

export { AccountNavContainer };
