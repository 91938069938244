import {
    AccountNumberResubmissionForm,
    PhoneNumberResubmissionForm,
    PINResubmissionForm,
} from 'components/port-fallout';
import { NoticeContainer as Notice } from 'containers';
import { IAccount, IApplicationState, INoticeProps } from 'models';
import { PortError, PortErrorReason } from 'models/port';
import { PortStatus } from 'models/port/port-status';
import { push } from 'react-router-redux';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { trackError } from 'state/tealium';
import { showModal, showNotice } from 'state/view/view.actions';
import { smellyStoreSingleton } from 'utils/store';
import {
    ActionType,
    reportFetchPortStatusError,
    reportFetchPortStatusSuccess,
    ReportFetchPortStatusSuccessAction,
} from './port-status.actions';

function* fetchPortStatus() {
    try {
        const account: IAccount = yield select(
            ({ account }: IApplicationState) => account,
        );
        yield put(
            reportFetchPortStatusSuccess(
                new PortError(
                    (account.portStatus || '') as PortStatus,
                    account.portRemarks || '',
                ).reason,
            ),
        );
    } catch (error) {
        yield put(reportFetchPortStatusError((error as Error).message));
    }
}

function getNumberTransferDetails(
    reason: PortErrorReason,
    account: IAccount,
): INoticeProps {
    const { dispatch } = smellyStoreSingleton.store;
    const baseNotice = {
        actionOneLabel: __('try again'),
        error: true,
        title: __('number-transfer-status.label'),
    };
    switch (reason) {
        case PortErrorReason.ACCOUNT_NUMBER:
            const accountErrorProps = {
                ...baseNotice,
                actionOne: () =>
                    dispatch(
                        showModal({
                            additionalProps: {},
                            component: AccountNumberResubmissionForm,
                        }),
                    ),
                body: __('number-transfer-status.account-number.message'),
                header: __('number-transfer-status.account-number.title'),
                pinCounterEnabled: true,
            };
            if (
                account.incorrectAccountPinPortRetryCounter ===
                account.incorrectAccountPinPortRetryThreshold
            ) {
                return {
                    ...accountErrorProps,
                    actionOne: () =>
                        window.inqFrame &&
                        window.inqFrame.Inq.C2CM.agrty(
                            window.inqFrame.Inq.C2CM._c2cList.length - 1,
                        ),
                    actionOneLabel: __('help'),
                    body: __('number-transfer-status.retries-max.message'),
                    header: __('number-transfer-status.retries-max.title'),
                };
            }
            return accountErrorProps;
        case PortErrorReason.INACTIVE_NUMBER:
            const inactiveErrorProps = {
                ...baseNotice,
                actionOne: () =>
                    dispatch(
                        showModal({
                            additionalProps: {},
                            component: PhoneNumberResubmissionForm,
                        }),
                    ),
                body: __('number-transfer-status.inactive-number.message'),
                header: __('number-transfer-status.inactive-number.title'),
                mdnCounterEnabled: true,
            };
            if (
                account.incorrectMDNPortRetryCounter ===
                account.incorrectMDNPortRetryThreshold
            ) {
                return {
                    ...inactiveErrorProps,
                    actionOne: () =>
                        window.inqFrame &&
                        window.inqFrame.Inq.C2CM.agrty(
                            window.inqFrame.Inq.C2CM._c2cList.length - 1,
                        ),
                    actionOneLabel: __('help'),
                    body: __('number-transfer-status.retries-max.message'),
                    header: __('number-transfer-status.retries-max.title'),
                };
            }
            return inactiveErrorProps;
        case PortErrorReason.PIN_ERROR:
            const pinErrorProps = {
                ...baseNotice,
                actionOne: () =>
                    dispatch(
                        showModal({
                            additionalProps: {},
                            component: PINResubmissionForm,
                        }),
                    ),
                body: __('number-transfer-status.pin-error.message'),
                header: __('number-transfer-status.pin-error.title'),
                pinCounterEnabled: true,
            };
            if (
                account.incorrectAccountPinPortRetryCounter ===
                account.incorrectAccountPinPortRetryThreshold
            ) {
                return {
                    ...pinErrorProps,
                    actionOne: () =>
                        window.inqFrame &&
                        window.inqFrame.Inq.C2CM.agrty(
                            window.inqFrame.Inq.C2CM._c2cList.length - 1,
                        ),
                    actionOneLabel: __('help'),
                    body: __('number-transfer-status.retries-max.message'),
                    header: __('number-transfer-status.retries-max.title'),
                };
            }
            return pinErrorProps;
        default:
            return {
                ...baseNotice,
                actionOne: () => dispatch(push('/faq')),
                actionOneLabel: __('help'),
                body: __('number-transfer-status.generic.message'),
                header: __('number-transfer-status.generic.title'),
                generalPortError: true,
            };
    }
}

function* presentPortStatusErrorIfNecessary(
    action: ReportFetchPortStatusSuccessAction,
) {
    const account: IAccount = yield select(
        ({ account }: IApplicationState) => account,
    );
    try {
        if (action.payload !== PortErrorReason.NO_ERROR) {
            yield put(
                showNotice({
                    additionalProps: getNumberTransferDetails(
                        action.payload,
                        account,
                    ),
                    component: Notice,
                }),
            );
        }
    } catch (_) {
        // fail silently
    }
}

function* portStatusSaga() {
    yield all([
        takeEvery(ActionType.FETCH_PORT_STATUS, fetchPortStatus),
        takeEvery(
            ActionType.REPORT_FETCH_PORT_STATUS_SUCCESS,
            presentPortStatusErrorIfNecessary,
        ),
        takeEvery(ActionType.REPORT_FETCH_PORT_STATUS_ERROR, trackError),
    ]);
}

export { portStatusSaga };
