import { NuanceData } from 'models/nuance/nuance-data.model';
import { NuancePageId, NuancePageIds } from './nuance-page-ids.model';

declare global {
    interface Window {
        // tslint:disable:readonly-keyword
        inqSiteID: number;
        nuanceData: NuanceData;
        inqFrame: any;
        Inq:
            | {
                  reinitChat: (
                      pageId: number | undefined | null,
                      // TODO(dan): There is a parameter to pass data.
                      data: any,
                  ) => void | undefined;
              }
            | undefined;

        InqRegistry: {
            chatListeners: [any] | undefined;
        };
    }
}

export { NuanceData, NuancePageId, NuancePageIds };
