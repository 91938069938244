import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./sxsw.less');

@CSSModules(styles)
class Sxsw extends React.Component<{}> {
    render() {
        return (
            <div>
                <iframe src={__('routes.sxsw.aem')} styleName="iframe" />
            </div>
        );
    }
}

export { Sxsw };
