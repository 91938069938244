import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { InPageNavContainer } from 'components/common/in-page-nav';
import { Headline } from 'components/common/typography';
import {
    Cart,
    CustomerState,
    IAccount,
    IAnalyticsProps,
    IConfiguration,
    IDeviceTypeProps,
    GroupMembershipDetailResponse,
} from 'models';

import {
    TealiumFlowName,
    TealiumLinkEvents,
    TealiumLoginState,
    TealiumPageLinkLocations,
    TealiumPageType,
    TealiumPagevents,
} from 'models/tealium';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';
import { ButtonContainer as Button } from 'containers';

const styles = require('./account-nav.less');

interface Props extends IAnalyticsProps, IDeviceTypeProps {
    readonly account: IAccount;
    readonly cart: Cart | null;
    readonly configuration: IConfiguration;
    readonly refCode: string;
    // readonly activeAccRefCodeCounter: number;
    readonly creditBalance: string;
    readonly customerState: CustomerState;
    readonly dueDate: string;
    readonly groups: GroupMembershipDetailResponse;
}

interface State {
    readonly flName: String;
}

// const BannerTitle = () => {
//     return (
//         <div styleName="banner-content">
//             <span styleName="banner-title">
//                 {__('account.party.banner.title')}
//             </span>
//         </div>
//     )
// }

@CSSModules(styles, { allowMultiple: true })
class AccountNav extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            flName: '',
        };
    }
    componentDidMount() {
        this.setState({
            flName:
                localStorage.getItem(TealiumFlowName.ACCOUNT_NAV_LINK) ===
                'true'
                    ? ''
                    : TealiumFlowName.SHOP_BYOD,
        });
        TealiumDispatcher.dispatchView(
            this.state.flName,
            this.props.customerState === CustomerState.ACTIVE
                ? TealiumPageType.ACTIVE_ACCOUNT_OVERVIEW
                : TealiumPageType.PRE_ACTIVE_WELCOME,
            TealiumLoginState.LOGGED_IN,
            this.props.account ? this.props.account.accountid : '',
        );
    }
    componentWillUnmount() {
        localStorage.removeItem(TealiumFlowName.ACCOUNT_NAV_LINK);
    }
    showPuchaseHistory = (cart, customerState) => {
        return customerState === CustomerState.LAPSED ||
            customerState === CustomerState.ACTIVE ||
            customerState === CustomerState.PAUSED ||
            (CustomerState.PURCHASED && cart && cart.isDevicePurchase())
            ? true
            : false;
    };
    render() {
        const {
            props: {
                cart,
                customerState,
                isMobile,
                configuration,
                account,
                groups,
            },
        } = this;
        const preactive = !!(
            (customerState && customerState === CustomerState.PURCHASED) ||
            (customerState && customerState === CustomerState.PREACTIVATED)
        );

        const isShowAccountTab = !(
            customerState && customerState === CustomerState.REGISTERED
        );
        const links = Array();
        if (isShowAccountTab) {
            links.push({
                label: `${__('account.currentStatement')}`,
                onClick: this.onOverViewClick,
                to: __('routes.account.overview'),
            });
            if (configuration.toggles.enableGroups) {
                links.push({
                    label: __('account.order.groups'),
                    to: __('routes.account.groups'),
                });
            }
            if (customerState && this.showPuchaseHistory(cart, customerState)) {
                links.push({
                    label: __('account.history'),
                    to: __('routes.account.bill-history'),
                });
            }
            links.push(
                ...[
                    {
                        label: isMobile
                            ? __('account.order.history.mobile')
                            : __('account.order.history'),
                        onClick: this.onOrderHistoryClick,
                        to: __('routes.account.order-history'),
                    },
                    {
                        label: preactive
                            ? __('account.profile')
                            : __('account.profile'),
                        onClick: this.onProfileClick,
                        to: __('routes.account.profile'),
                    },
                    {
                        label: isMobile
                            ? __('account.privacy.mobile')
                            : __('account.privacy'),
                        onClick: this.onSecurityClick,
                        to: __('routes.account.privacy'),
                    },
                ],
            );
        } else {
            links.push(
                ...[
                    {
                        label: preactive
                            ? __('account.profile')
                            : __('account.profile'),
                        onClick: this.onProfileClick,
                        to: __('routes.account.profile'),
                    },
                    {
                        label: isMobile
                            ? __('account.privacy.mobile')
                            : __('account.privacy'),
                        onClick: this.onSecurityClick,
                        to: __('routes.account.privacy'),
                    },
                ],
            );
        }

        return (
            <InPageNavContainer
                alignLeft={preactive}
                customerState={customerState}
                configuration={configuration}
                groups={groups}
                subHeadline={
                    <>
                        {configuration.toggles.enableGroups &&
                        groups &&
                        groups.GroupMembershipState !== 'Activated' ? (
                            <div styleName="banner-content ">
                                <Headline invert styleName="banner-subtitle">
                                    {__('account.hero.welcome')}
                                    {account.firstName}
                                </Headline>
                                <Headline invert styleName="banner-title">
                                    {__('account.party.banner.title')}
                                </Headline>
                                <Button
                                    styleName="button--party"
                                    invert
                                    onClick={this.handleLearnMoreClick}
                                    label={__('account.party.button.title')}
                                />
                            </div>
                        ) : (
                            <div styleName="banner-content ">
                                <Headline styleName="banner-title-group-member">
                                    {__('account.hero.welcome')}
                                    {account.firstName}
                                </Headline>
                            </div>
                        )}
                    </>
                }
                links={links}
            />
        );
    }

    private readonly handleLearnMoreClick = () => {
        window.location.href = __('routes.party-pay');
    };

    private readonly onOverViewClick = () => {
        TealiumDispatcher.dispatchEvent(
            this.state.flName,
            TealiumPageType.PRE_ACTIVE_WELCOME,
            TealiumPagevents.ACCOUNT_OVERVIEW,
            TealiumPageLinkLocations.REGISTERED_CONTINUE_LOCATION,
            TealiumLinkEvents.TEXT_LINK,
            TealiumLoginState.LOGGED_IN,
            this.props.account ? this.props.account.accountid : '',
        );
    };
    private readonly onProfileClick = () => {
        TealiumDispatcher.dispatchEvent(
            this.state.flName,
            TealiumPageType.PRE_ACTIVE_WELCOME,
            TealiumPagevents.ACCOUNT_PROFILE,
            TealiumPageLinkLocations.REGISTERED_CONTINUE_LOCATION,
            TealiumLinkEvents.TEXT_LINK,
            TealiumLoginState.LOGGED_IN,
            this.props.account ? this.props.account.accountid : '',
        );
    };

    private readonly onOrderHistoryClick = () => {
        TealiumDispatcher.dispatchEvent(
            this.state.flName,
            TealiumPageType.PRE_ACTIVE_WELCOME,
            TealiumPagevents.ACCOUNT_ORDER_HISTORY,
            TealiumPageLinkLocations.REGISTERED_CONTINUE_LOCATION,
            TealiumLinkEvents.TEXT_LINK,
            TealiumLoginState.LOGGED_IN,
            this.props.account ? this.props.account.accountid : '',
        );
    };

    private readonly onSecurityClick = () => {
        TealiumDispatcher.dispatchEvent(
            this.state.flName,
            TealiumPageType.PRE_ACTIVE_WELCOME,
            TealiumPagevents.ACCOUNT_SECURITY,
            TealiumPageLinkLocations.REGISTERED_CONTINUE_LOCATION,
            TealiumLinkEvents.TEXT_LINK,
            TealiumLoginState.LOGGED_IN,
            this.props.account ? this.props.account.accountid : '',
        );
    };
}
export { AccountNav };
