import { IDefaultAction } from 'lib/br-redux';
import { StartupScript } from 'models';
import { ActionType, IStartupAction } from './startup.actions';

interface IStartupState {
    readonly pending: ReadonlyArray<StartupScript>;
    readonly initialized: ReadonlyArray<StartupScript>;
    readonly failed: ReadonlyArray<StartupScript>;
}

const {
    FACEBOOK,
    FIREBASE,
    GOOGLE_SDK,
    AUTHENTICATION,
    CONFIGURATION,
    CHAT,
} = StartupScript;

const DEFAULT_STATE: IStartupState = {
    failed: [],
    initialized: [],
    pending: [
        FACEBOOK,
        FIREBASE,
        GOOGLE_SDK,
        AUTHENTICATION,
        CONFIGURATION,
        CHAT,
    ],
};

const {
    REPORT_INITIALIZATION_FAILURE,
    REPORT_INITIALIZATION_SUCCESS,
} = ActionType;

const startupReducer = (
    state: IStartupState | undefined = DEFAULT_STATE,
    action: IStartupAction | IDefaultAction,
): IStartupState => {
    switch (action.type) {
        case REPORT_INITIALIZATION_FAILURE:
            const failedIdx = state.pending.indexOf(action.payload);
            return {
                ...state,
                ...{
                    failed: [...state.failed, state.pending[failedIdx]],
                    pending: [
                        ...state.pending.slice(0, failedIdx),
                        ...state.pending.slice(failedIdx + 1),
                    ],
                },
            };
        case REPORT_INITIALIZATION_SUCCESS:
            const initializedIdx = state.pending.indexOf(action.payload);
            if (initializedIdx > -1) {
                return {
                    ...state,
                    ...{
                        initialized: [
                            ...state.initialized,
                            state.pending[initializedIdx],
                        ],
                        pending: [
                            ...state.pending.slice(0, initializedIdx),
                            ...state.pending.slice(initializedIdx + 1),
                        ],
                    },
                };
            }
            return state;
        default:
            return state;
    }
};

export { startupReducer, IStartupState, DEFAULT_STATE };
