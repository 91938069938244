import * as React from 'react';

import { FetchStatus } from 'lib/br-redux';
import { parse } from 'query-string';

enum ActionHandlerMode {
    RESET_PASSWORD = 'resetPassword',
    RECOVER_EMAIL = 'recoverEmail',
    VERIFY_EMAIL = 'verifyEmail',
    UNKNOWN = '',
}

interface EmailActionUrlParams {
    readonly apiKey?: string;
    readonly continueUrl?: string;
    readonly mode?: string;
    readonly oobCode?: ActionHandlerMode;
}

interface BaseProps {
    readonly location: Location;
    readonly verificationStatus: FetchStatus;
    verifyCode(oobCode: string, mode: ActionHandlerMode): void;
}

abstract class EmailActionHandler<Props, State = {}> extends React.Component<
    Props & BaseProps,
    State
> {
    componentDidMount(): void {
        this.props.verifyCode(this.oobCode, this.mode);
    }

    protected get apiKey(): string {
        return this.params.apiKey || '';
    }

    protected get continueUrl(): string {
        return this.params.continueUrl || '';
    }

    protected get mode(): ActionHandlerMode {
        return (
            (this.params.mode as ActionHandlerMode) || ActionHandlerMode.UNKNOWN
        );
    }

    protected get oobCode(): string {
        return this.params.oobCode || '';
    }

    private get params(): EmailActionUrlParams {
        try {
            return parse(this.props.location.search);
        } catch (err) {
            return {};
        }
    }
}

export { EmailActionHandler, ActionHandlerMode };
