import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';
import { CheckboxOption } from 'components/common/forms';
import { Hidden } from 'components/common/hidden';
import { BodyCopy } from 'components/common/typography/bodycopy';
import { ErrorMsg } from 'components/common/typography/error-msg';
import {
    AddressFieldContainer as AddressField,
    ButtonContainer as Button,
    InputContainer as Input,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { isEqual } from 'lodash';
import {
    Account,
    IAddress,
    IInputValidationProps,
    INonNormalizedAccount,
    IServiceStep,
    IServiceStepProps,
} from 'models';
import { GoogleAddress } from 'models/google';
import { formatAddress, InputValidator, transformGoogleAddress } from 'utils';

const styles = require('./address.less');

interface IMappedProps extends IAnalyticsProps, IInputValidationProps {
    readonly updateAccountError: string;
    readonly updateAccountStatus: FetchStatus;
    readonly user: Account;
    updateAccount(account: Partial<INonNormalizedAccount>): void;
}

interface IOtherProps extends IServiceStepProps {}

interface IState {
    readonly addressInjected: boolean;
    readonly error: string;
    readonly shippingAddressIsHomeAddress: boolean;
}

const DEFAULT_STATE: IState = {
    addressInjected: false,
    error: '',
    shippingAddressIsHomeAddress: true,
};

type IProps = IMappedProps & IOtherProps;

@CSSModules(styles)
class Address extends React.Component<IProps, IState> implements IServiceStep {
    constructor(props: IProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    componentWillReceiveProps(newProps: IProps): void {
        const oldUpdateAccountStatus = this.props.updateAccountStatus;
        if (
            newProps.updateAccountStatus === FetchStatus.SUCCESS &&
            newProps.updateAccountStatus !== oldUpdateAccountStatus &&
            this.props.onNext
        ) {
            this.setState({ error: '' });
        }
        if (
            newProps.updateAccountStatus === FetchStatus.ERROR &&
            newProps.updateAccountError
        ) {
            this.setState({ error: newProps.updateAccountError });
        }
        this.initSavedAddress(newProps);
    }

    render() {
        const {
            bindHandleAddressChange,
            handleCheckboxChange,
            handleSubmit,
            hasErrors,
            props: {
                data: {
                    homeAddress,
                    homeAddressStreetLineTwo,
                    shippingAddressStreetLineTwo,
                    shippingAddress,
                },
                data,
                errors,
                updateAccountStatus,
                handleOnChange,
                handleOnValidate,
            },
            state: { error, shippingAddressIsHomeAddress },
        } = this;
        return (
            <form onSubmit={handleSubmit}>
                <ErrorMsg>{error}</ErrorMsg>
                <div styleName="content">
                    <div styleName="col">
                        <AddressField
                            noMargin
                            id="shippingAddress"
                            label={__('address.shipping-address-label')}
                            onChange={bindHandleAddressChange(
                                'shippingAddress',
                            )}
                            placeholder={__('address.line-one.placeholder')}
                            defaultValue={formatAddress(shippingAddress)}
                            onValidate={handleOnValidate}
                            data={data}
                            errors={errors}
                        />
                        <BodyCopy noMargin thin>
                            {__('address.po_box_error')}
                        </BodyCopy>
                        <div styleName="line2">
                            <Input
                                id="shippingAddressStreetLineTwo"
                                label={__('address.line-two.label')}
                                placeholder={__('address.line-two.placeholder')}
                                value={shippingAddressStreetLineTwo}
                                onChange={handleOnChange}
                                onValidate={handleOnValidate}
                                validate={InputValidator.validateAddressLineTwo}
                                errors={errors}
                            />
                        </div>
                        <div styleName="checkbox">
                            <CheckboxOption
                                id="shippingAddressIsHomeAddress"
                                checked={shippingAddressIsHomeAddress}
                                onChange={handleCheckboxChange}
                            >
                                {__('address.checkbox_label')}
                            </CheckboxOption>
                        </div>
                    </div>
                    <div styleName="col">
                        <Hidden until={!shippingAddressIsHomeAddress}>
                            <AddressField
                                noMargin
                                id="homeAddress"
                                label={__('address.service-address-label')}
                                placeholder={__('address.line-one.placeholder')}
                                onChange={bindHandleAddressChange(
                                    'homeAddress',
                                )}
                                defaultValue={formatAddress(homeAddress)}
                                onValidate={handleOnValidate}
                                data={data}
                                errors={errors}
                            />
                            <BodyCopy noMargin thin>
                                {__('address.po_box_error')}
                            </BodyCopy>
                            <div styleName="line2">
                                <Input
                                    id="homeAddressStreetLineTwo"
                                    label={__('address.line-two.label')}
                                    placeholder={__(
                                        'address.line-two.placeholder',
                                    )}
                                    value={homeAddressStreetLineTwo}
                                    onChange={handleOnChange}
                                    onValidate={handleOnValidate}
                                    validate={
                                        InputValidator.validateAddressLineTwo
                                    }
                                    errors={errors}
                                />
                            </div>
                        </Hidden>
                    </div>
                </div>
                <div>
                    <div styleName="confirm-wrapper">
                        <Button
                            styleName="button"
                            type="submit"
                            disabled={hasErrors}
                            loading={
                                updateAccountStatus === FetchStatus.FETCHING
                            }
                            label={__('next')}
                        />
                    </div>
                </div>
            </form>
        );
    }

    private get hasErrors(): boolean {
        const {
            props: {
                errors: {
                    homeAddress,
                    shippingAddress,
                    homeAddressStreetLineTwo,
                    shippingAddressStreetLineTwo,
                },
            },
            state: { shippingAddressIsHomeAddress },
        } = this;

        return (
            shippingAddress ||
            shippingAddressStreetLineTwo ||
            ((!shippingAddressIsHomeAddress && homeAddress) ||
                homeAddressStreetLineTwo)
        );
    }

    private initSavedAddress(newProps) {
        const {
            props: { handleOnChange },
            state: { addressInjected },
        } = this;
        const {
            user: {
                address: { billing_address, shipping_address },
            },
        } = newProps;
        if (
            !addressInjected &&
            (shipping_address.street_line1 !== '' ||
                billing_address.street_line1 !== '')
        ) {
            this.setState({
                addressInjected: true,
                shippingAddressIsHomeAddress: isEqual(
                    billing_address,
                    shipping_address,
                ),
            });
            handleOnChange({
                setErrors: false,
                target: {
                    name: 'shippingAddress',
                    value: shipping_address,
                },
            });
            handleOnChange({
                setErrors: false,
                target: {
                    name: 'shippingAddressStreetLineTwo',
                    value: shipping_address.street_line2,
                },
            });
            handleOnChange({
                setErrors: false,
                target: {
                    name: 'homeAddress',
                    value: billing_address,
                },
            });
            handleOnChange({
                setErrors: false,
                target: {
                    name: 'homeAddressStreetLineTwo',
                    value: billing_address.street_line2,
                },
            });
        }
    }

    private readonly handleSubmit = (
        e: React.FormEvent<HTMLFormElement>,
    ): void => {
        e.preventDefault();
        const {
            props: {
                data: {
                    homeAddress,
                    homeAddressStreetLineTwo,
                    shippingAddress,
                    shippingAddressStreetLineTwo,
                },
                updateAccount,
            },
            state: { shippingAddressIsHomeAddress },
        } = this;
        updateAccount({
            address: {
                billing_address: shippingAddressIsHomeAddress
                    ? this.updateAddressesWithStLineTwo(
                          shippingAddress,
                          shippingAddressStreetLineTwo,
                      )
                    : this.updateAddressesWithStLineTwo(
                          homeAddress,
                          homeAddressStreetLineTwo,
                      ),
                shipping_address: this.updateAddressesWithStLineTwo(
                    shippingAddress,
                    shippingAddressStreetLineTwo,
                ),
            },
        });
    };

    private updateAddressesWithStLineTwo(
        address: IAddress,
        lineTwo: string,
    ): IAddress {
        return lineTwo ? { ...address, street_line2: lineTwo } : address;
    }

    private readonly bindHandleAddressChange = (name: string) => (
        val?: GoogleAddress,
    ): void => {
        const {
            props: { data, handleOnChange },
        } = this;
        handleOnChange({
            target: {
                name,
                value: {
                    ...transformGoogleAddress(val),
                    street_line2: data[name].street_line2,
                },
            },
        });
    };

    private readonly handleCheckboxChange = (
        value: boolean,
        name: string,
    ): void => {
        const state = {};
        state[name] = value;
        this.setState(state);
    };
}

export { Address };
