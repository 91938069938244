import { IDefaultAction } from 'lib/br-redux';
import {
    DEFAULT_STATE as DEFAULT_COMPATIBILITY_STATE,
    deviceCompatibilityReducer,
    IDeviceCompatibilityState,
} from './compatibility';

interface IDeviceState {
    readonly compatibility: IDeviceCompatibilityState;
}

const DEFAULT_STATE: IDeviceState = {
    compatibility: DEFAULT_COMPATIBILITY_STATE,
};

const deviceReducer = (
    state: IDeviceState = DEFAULT_STATE,
    action: IDefaultAction,
): IDeviceState => {
    switch (action.type) {
        default:
            return {
                ...state,
                compatibility: deviceCompatibilityReducer(
                    state.compatibility,
                    action as IDefaultAction,
                ),
            };
    }
};

export { DEFAULT_STATE, IDeviceState, deviceReducer };
