import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { CountDownContainer as CountDown } from 'containers';
import { IDeviceTypeProps, INoticeProps } from 'models';
import { splitProps } from 'utils';

const styles = require('./notice.less');

enum Modifiers {
    error = 'error',
}

interface INoticeModifiers {
    readonly white?: boolean;
}

interface Props extends INoticeModifiers, IDeviceTypeProps, INoticeProps {}

@CSSModules(styles, { allowMultiple: true })
class Notice extends React.Component<Props> {
    render() {
        const {
            props: { body, error, header, actionOneLabel },
            props,
        } = this;
        const [modifiers] = splitProps(props, Modifiers);
        const cn = classNames('notice', modifiers);
        return (
            <div styleName={cn}>
                <div styleName="inner-container">
                    <div styleName="panel">
                        <div styleName="details">
                            <p
                                styleName={classNames('title', {
                                    error,
                                })}
                            >
                                {header}:
                            </p>
                            <p styleName="description">{body}</p>
                            <p styleName="cta" onClick={this.actionOneClick}>
                                {actionOneLabel}
                            </p>
                            <p styleName="countdown">{this.countDown()}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private readonly actionOneClick = () => {
        const {
            props: { actionOne },
        } = this;
        if (actionOne) {
            actionOne();
        }
    };

    private readonly countDown = () => {
        const { mdnCounterEnabled, pinCounterEnabled } = this.props;
        return (
            (mdnCounterEnabled || pinCounterEnabled) && (
                <span>
                    (
                    <CountDown
                        mdnCounterEnabled={mdnCounterEnabled}
                        pinCounterEnabled={pinCounterEnabled}
                    />
                    )
                </span>
            )
        );
    };

    // private readonly actionTwoClick = () => {
    //     const { props: { actionTwo } } = this;
    //     if (actionTwo) {
    //         actionTwo();
    //     }
    // };

    // private readonly actionTwoButton = () => {
    //     const {
    //         actionTwoClick,
    //         props: {
    //             actionTwo,
    //             actionTwoDisabled,
    //             actionTwoLabel,
    //             actionTwoLoading,
    //             isDesktop,
    //             isMobile,
    //         },
    //     } = this;
    //     return actionTwo ? (
    //         <div styleName="button button-two">
    //             <Button
    //                 noMarginBottom
    //                 minWidth={isDesktop ? 164 : 124.8}
    //                 loading={actionTwoLoading}
    //                 onClick={actionTwoClick}
    //                 disabled={actionTwoDisabled}
    //                 noPaddingSides={isMobile}
    //                 type="submit"
    //                 label={actionTwoLabel}
    //             />
    //         </div>
    //     ) : null;
    // };
}

export { Notice, INoticeProps };
