import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';
import { ContentWrapper } from 'components/common/content-wrapper';
import { Divider } from 'components/common/divider';
import { Hidden } from 'components/common/hidden';
import { Col, ErrorMsg, Headline, Row } from 'components/common/typography';
import {
    AddressFieldContainer as AddressField,
    ButtonContainer as Button,
    InputContainer as Input,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';
import {
    Account,
    CustomerState,
    IAccount,
    IInputValidationProps,
    PatchableAccount,
} from 'models';
import { GoogleAddress } from 'models/google';
import { formatAddress, InputValidator, transformGoogleAddress } from 'utils';
import { normalizeNumber } from 'utils/normalize-number';

const styles = require('./edit-info.less');

interface MappedProps extends IInputValidationProps, IAnalyticsProps {
    readonly account: Account;
    readonly updateAccountErrorMessage: string;
    readonly reauthenticationStatus: FetchStatus;
    readonly resetPasswordError: string;
    readonly resetPasswordSuccess: string;
    readonly resetPasswordFetchStatus: FetchStatus;
    readonly updateAccountSuccessMessage: string;
    readonly updateAccountFetchStatus: FetchStatus;
    resendVerificationEmail();
    reauthenticate(): void;
    resetPassword(email: string): void;
    updateAccount(account: PatchableAccount): void;
}

type FieldName =
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'msg'
    | 'phoneNumber'
    | 'password'
    | 'shippingAddress'
    | 'shippingAddressLine2'
    | 'billingAddress'
    | 'billingAddressLine2'
    | 'actions';

interface OtherProps {
    readonly editableFields?: ReadonlyArray<FieldName>;
    onNext?(): void;
}

interface State {
    readonly accountInjected: boolean;
    readonly isEditing: boolean;
    readonly didReauthenticate: boolean;
    readonly emailVerifySent: boolean;
    readonly profileChanged: boolean;
}

type Props = MappedProps & OtherProps;

@CSSModules(styles, { allowMultiple: true })
class EditInfo extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            accountInjected: false,
            didReauthenticate: false,
            emailVerifySent: false,
            isEditing: false,
            profileChanged: false,
        };
    }

    componentWillReceiveProps(newProps: Props) {
        this.setReauthenticationStatus(newProps);
        this.setEditStatus(newProps);
        const {
            account,
            account: {
                address: { billing_address, shipping_address },
            },
            updateAccountFetchStatus,
            onNext,
            data: {
                firstName,
                lastName,
                email,
                billingAddress,
                billingAddressLine2,
                shippingAddress,
                shippingAddressLine2,
            },
        } = newProps;
        if (
            updateAccountFetchStatus !== this.props.updateAccountFetchStatus &&
            updateAccountFetchStatus === FetchStatus.SUCCESS
        ) {
            if (onNext) {
                onNext();
            }
        }

        if (
            account !== this.props.account &&
            updateAccountFetchStatus !== FetchStatus.FETCHING
        ) {
            this.initSavedAccount(account);
        }
        // TODO: countryCode is not returning correctly. if working, get rid of all the details of billing address and shipping address
        if (
            firstName !== account.firstName ||
            lastName !== account.lastName ||
            email !== account.emailAddress ||
            (JSON.stringify(
                billingAddress.city +
                    billingAddress.state +
                    billingAddress.street_line1 +
                    billingAddress.zip,
            ) !==
                JSON.stringify(
                    billing_address.city +
                        billing_address.state +
                        billing_address.street_line1 +
                        billing_address.zip,
                ) ||
                JSON.stringify(billingAddressLine2) !==
                    JSON.stringify(billing_address.street_line2) ||
                JSON.stringify(
                    shippingAddress.city +
                        shippingAddress.state +
                        shippingAddress.street_line1 +
                        shippingAddress.zip,
                ) !==
                    JSON.stringify(
                        shipping_address.city +
                            shipping_address.state +
                            shipping_address.street_line1 +
                            shipping_address.zip,
                    ) ||
                JSON.stringify(shippingAddressLine2) !==
                    JSON.stringify(shipping_address.street_line2))
        ) {
            this.setState({ profileChanged: true });
        } else {
            this.setState({ profileChanged: false });
        }
    }

    render(): JSX.Element {
        const {
            bindHandleOnAddressChange,
            handleEmailChange,
            handleOnResendClick,
            props: {
                account: { customerState, emailVerified, phoneNumber },
                data: {
                    firstName,
                    lastName,
                    email,
                    billingAddressLine2,
                    shippingAddressLine2,
                },
                data,
                errors,
                handleOnChange,
                handleOnValidate,
                updateAccountErrorMessage,
            },
            state: { emailVerifySent, isEditing },
            validateName,
        } = this;
        // tslint:disable-next-line:no-console
        const editable = isEditing;
        const editableCustomerState = !(
            customerState === CustomerState.PURCHASED ||
            customerState === CustomerState.PREACTIVATED
        );
        return (
            <form onSubmit={this.handleSubmit} styleName="edit-info-form">
                <div styleName="edit-info-editable-section">
                    <ErrorMsg>{updateAccountErrorMessage}</ErrorMsg>

                    <ContentWrapper>
                        <Row>
                            <Col width={3} mobileWidth={12}>
                                <Headline medium three dark>
                                    {__('account.profile.form.account')}
                                </Headline>
                            </Col>

                            <Col width={9} mobileWidth={12} topPadding>
                                {this.fieldIfEditable(
                                    'firstName',
                                    <Input
                                        id="firstName"
                                        showLabel
                                        label={__('account.profile.firstName')}
                                        value={firstName}
                                        readOnly={!editable}
                                        readOnlyText={!editable}
                                        twoColm
                                        onChange={
                                            editable
                                                ? handleOnChange
                                                : undefined
                                        }
                                        onValidate={handleOnValidate}
                                        validate={validateName}
                                    />,
                                )}
                                {this.fieldIfEditable(
                                    'email',
                                    <>
                                        <Input
                                            id="email"
                                            showLabel
                                            label={__('account.profile.email')}
                                            type="email"
                                            value={email}
                                            readOnly={!editable}
                                            readOnlyText={!editable}
                                            twoColm
                                            onChange={
                                                editable
                                                    ? handleOnChange
                                                    : undefined
                                            }
                                            onValidate={handleOnValidate}
                                            onFocus={
                                                editable
                                                    ? handleEmailChange
                                                    : undefined
                                            }
                                            validate={
                                                InputValidator.validateEmail
                                            }
                                        />
                                    </>,
                                )}
                                {this.fieldIfEditable(
                                    'lastName',
                                    <Input
                                        id="lastName"
                                        showLabel
                                        label={__('account.profile.lastName')}
                                        value={lastName}
                                        readOnly={!editable}
                                        readOnlyText={!editable}
                                        twoColm
                                        onChange={
                                            editable
                                                ? handleOnChange
                                                : undefined
                                        }
                                        onValidate={handleOnValidate}
                                        validate={validateName}
                                    />,
                                )}
                                {this.fieldIfEditable(
                                    'phoneNumber',
                                    <Input
                                        id="phoneNumber"
                                        showLabel
                                        label={__('account.profile.phone')}
                                        value={normalizeNumber(phoneNumber)}
                                        readOnly
                                        readOnlyText
                                        twoColm
                                        onChange={handleOnChange}
                                    />,
                                )}
                            </Col>
                        </Row>
                    </ContentWrapper>

                    <Hidden when={emailVerified}>
                        <Divider />
                        <ContentWrapper>
                            <Row>
                                <Col width={12} mobileWidth={12}>
                                    <div styleName="email-verif-container">
                                        <Hidden when={emailVerifySent}>
                                            <div>
                                                {__(
                                                    'account.profile.notVerified',
                                                )}
                                            </div>
                                            <div styleName="resend-link-container">
                                                <a
                                                    styleName="resend-link"
                                                    onClick={
                                                        handleOnResendClick
                                                    }
                                                >
                                                    {__(
                                                        'account.profile.resendEmail',
                                                    )}
                                                </a>
                                            </div>
                                        </Hidden>
                                        <Hidden when={!emailVerifySent}>
                                            <div>
                                                {__(
                                                    'account.profile.resendVerification',
                                                )}
                                            </div>
                                        </Hidden>
                                    </div>
                                </Col>
                            </Row>
                        </ContentWrapper>
                    </Hidden>
                    <Divider />

                    <ContentWrapper>
                        <Row>
                            <Col width={3} mobileWidth={12}>
                                <Headline medium three dark>
                                    {__('account.profile.form.address')}
                                </Headline>
                            </Col>

                            <Col width={9} mobileWidth={12} topPadding>
                                {this.fieldIfEditable(
                                    'shippingAddress',
                                    <AddressField
                                        id="shippingAddress"
                                        showLabel
                                        label={__(
                                            'address.billing-address-label',
                                        )}
                                        disabled={!editableCustomerState}
                                        readOnly={
                                            !editable || !editableCustomerState
                                        }
                                        readOnlyText={
                                            !editable || !editableCustomerState
                                        }
                                        twoColm
                                        onChange={
                                            editable && editableCustomerState
                                                ? bindHandleOnAddressChange(
                                                      'shippingAddress',
                                                  )
                                                : undefined
                                        }
                                        defaultValue={formatAddress(
                                            data.shippingAddress,
                                        )}
                                        onValidate={handleOnValidate}
                                        data={data}
                                        errors={errors}
                                    />,
                                )}
                                {this.fieldIfEditable(
                                    'billingAddress',
                                    <AddressField
                                        id="billingAddress"
                                        showLabel
                                        label={__(
                                            'account.profile.serviceAddress',
                                        )}
                                        disabled={!editableCustomerState}
                                        readOnly={
                                            !editable || !editableCustomerState
                                        }
                                        readOnlyText={
                                            !editable || !editableCustomerState
                                        }
                                        twoColm
                                        onChange={
                                            editable && editableCustomerState
                                                ? bindHandleOnAddressChange(
                                                      'billingAddress',
                                                  )
                                                : undefined
                                        }
                                        defaultValue={formatAddress(
                                            data.billingAddress,
                                        )}
                                        onValidate={handleOnValidate}
                                        data={data}
                                        errors={errors}
                                    />,
                                )}
                                {this.fieldIfEditable(
                                    'shippingAddress',
                                    <>
                                        <Input
                                            id="shippingAddressLine2"
                                            showLabel
                                            label={__(
                                                'address.shipping-unit-number-label',
                                            )}
                                            type="text"
                                            value={shippingAddressLine2}
                                            disabled={!editableCustomerState}
                                            readOnly={
                                                !editable ||
                                                !editableCustomerState
                                            }
                                            readOnlyText={
                                                !editable ||
                                                !editableCustomerState
                                            }
                                            twoColm
                                            onChange={
                                                editable &&
                                                editableCustomerState
                                                    ? handleOnChange
                                                    : undefined
                                            }
                                            maxLength={25}
                                            onValidate={handleOnValidate}
                                            validate={
                                                InputValidator.validateAddressLineTwo
                                            }
                                        />
                                    </>,
                                )}
                                {this.fieldIfEditable(
                                    'billingAddress',
                                    <>
                                        <Input
                                            id="billingAddressLine2"
                                            showLabel
                                            label={__(
                                                'account.profile.serviceAddress.unit',
                                            )}
                                            type="text"
                                            value={billingAddressLine2}
                                            disabled={!editableCustomerState}
                                            readOnly={
                                                !editable ||
                                                !editableCustomerState
                                            }
                                            readOnlyText={
                                                !editable ||
                                                !editableCustomerState
                                            }
                                            twoColm
                                            onChange={
                                                editable &&
                                                editableCustomerState
                                                    ? handleOnChange
                                                    : undefined
                                            }
                                            maxLength={25}
                                            onValidate={handleOnValidate}
                                            validate={
                                                InputValidator.validateAddressLineTwo
                                            }
                                        />
                                    </>,
                                )}

                                <span styleName="action-buttons-section">
                                    {this.actionButtonsSection}
                                </span>
                            </Col>
                        </Row>
                    </ContentWrapper>
                </div>
                <span styleName="action-buttons-section-mobile">
                    {this.actionButtonsSection}
                </span>
            </form>
        );
    }

    private get isFetching() {
        const {
            props: { updateAccountFetchStatus },
        } = this;
        return updateAccountFetchStatus === FetchStatus.FETCHING;
    }

    private readonly handleOnResendClick = (): void => {
        this.setState({ emailVerifySent: true });
        this.props.resendVerificationEmail();
    };

    private readonly toggleEdit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const {
            isFetching,
            props: { account },
            state: { isEditing },
        } = this;
        if (!isFetching && isEditing) {
            this.injectAccountData(account);
        }
        this.setState({ isEditing: !this.state.isEditing });
    };

    private readonly handleEmailChange = (): void => {
        if (this.state.isEditing && !this.state.didReauthenticate) {
            this.props.reauthenticate();
        }
    };

    private readonly bindHandleOnAddressChange = (name: string) => (
        val: GoogleAddress,
    ): void => {
        const {
            props: { handleOnChange },
        } = this;
        handleOnChange({
            target: {
                name,
                value: {
                    ...transformGoogleAddress(val),
                },
            },
        });
    };

    private initSavedAccount(account: Account) {
        if (!this.state.accountInjected) {
            this.setState({ accountInjected: true });
            this.injectAccountData(account);
        }
    }
    private readonly injectAccountData = account => {
        const {
            props: { handleOnChange },
        } = this;
        handleOnChange({
            setErrors: false,
            target: {
                name: 'billingAddress',
                value: {
                    ...account.address.billing_address,
                },
            },
        });
        handleOnChange({
            setErrors: false,
            target: {
                name: 'billingAddressLine2',
                value: account.address.billing_address.street_line2 || '',
            },
        });
        handleOnChange({
            setErrors: false,
            target: {
                name: 'shippingAddress',
                value: {
                    ...account.address.shipping_address,
                },
            },
        });
        handleOnChange({
            setErrors: false,
            target: {
                name: 'shippingAddressLine2',
                value: account.address.shipping_address.street_line2 || '',
            },
        });
        handleOnChange({
            setErrors: false,
            target: {
                name: 'email',
                value: account.emailAddress,
            },
        });
        handleOnChange({
            setErrors: false,
            target: {
                name: 'firstName',
                value: account.firstName,
            },
        });
        handleOnChange({
            setErrors: false,
            target: {
                name: 'lastName',
                value: account.lastName,
            },
        });
    };

    private fieldIfEditable(
        fieldName: FieldName,
        element: JSX.Element,
    ): JSX.Element | null {
        const { editableFields } = this.props;
        if (!editableFields || editableFields.indexOf(fieldName) > -1) {
            return element;
        }

        return null;
    }

    private readonly handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.updateAccount(Account.Requestify(this.getAccountPayload));
    };

    private get getAccountPayload(): Partial<IAccount> {
        const {
            props: {
                data: {
                    firstName,
                    lastName,
                    email,
                    billingAddress,
                    billingAddressLine2,
                    shippingAddress,
                    shippingAddressLine2,
                },
                account: {
                    address: { billing_address, shipping_address },
                },
            },
        } = this;
        const address =
            this.canEditField('billingAddress') &&
            this.canEditField('shippingAddress') &&
            // TODO: countryCode is not returning correctly. if working, get rid of all the details of billing address and shipping address
            (JSON.stringify(
                billingAddress.city +
                    billingAddress.state +
                    billingAddress.street_line1 +
                    billingAddress.street_line2 +
                    billingAddress.zip,
            ) !==
                JSON.stringify(
                    billing_address.city +
                        billing_address.state +
                        billing_address.street_line1 +
                        billing_address.street_line2 +
                        billing_address.zip,
                ) ||
                JSON.stringify(billingAddressLine2) !==
                    JSON.stringify(billing_address.street_line2) ||
                JSON.stringify(
                    shippingAddress.city +
                        shippingAddress.state +
                        shippingAddress.street_line1 +
                        shippingAddress.street_line2 +
                        shippingAddress.zip,
                ) !==
                    JSON.stringify(
                        shipping_address.city +
                            shipping_address.state +
                            shipping_address.street_line1 +
                            shipping_address.street_line2 +
                            shipping_address.zip,
                    ) ||
                JSON.stringify(shippingAddressLine2) !==
                    JSON.stringify(shipping_address.street_line2))
                ? {
                      billing_address: {
                          ...billingAddress,
                          street_line2: billingAddressLine2,
                      },
                      shipping_address: {
                          ...shippingAddress,
                          street_line2: shippingAddressLine2,
                      },
                  }
                : undefined;
        const emailAddress =
            this.canEditField('email') &&
            email !== this.props.account.emailAddress
                ? email
                : undefined;

        return {
            address,
            emailAddress,
            firstName:
                (this.canEditField('firstName') &&
                    firstName !== this.props.account.firstName) ||
                (this.canEditField('lastName') &&
                    lastName !== this.props.account.lastName)
                    ? firstName
                    : undefined,
            lastName:
                (this.canEditField('lastName') &&
                    lastName !== this.props.account.lastName) ||
                (this.canEditField('firstName') &&
                    firstName !== this.props.account.firstName)
                    ? lastName
                    : undefined,
        };
    }

    private readonly canEditField = (field: FieldName): boolean => {
        const { editableFields } = this.props;
        return !editableFields || editableFields.indexOf(field) > -1;
    };

    private readonly validateName = (value: string) => {
        const { CustomerNameAllowedCharacters } = InputValidator.Patterns;
        return new InputValidator(value)
            .doesNotExceedLengthOf(30)
            .conformsToPattern(CustomerNameAllowedCharacters)
            .validate();
    };

    private get hasErrors(): boolean {
        const {
            props: {
                errors: {
                    billingAddress,
                    billingAddressLine2,
                    email,
                    firstName,
                    lastName,
                    shippingAddress,
                    shippingAddressLine2,
                },
            },
            state: { profileChanged },
        } = this;
        return (
            billingAddress ||
            billingAddressLine2 ||
            email ||
            firstName ||
            lastName ||
            shippingAddress ||
            shippingAddressLine2 ||
            !profileChanged
        );
    }

    private get renderEditStateButtons(): JSX.Element {
        const {
            hasErrors,
            isFetching,
            state: { isEditing },
            toggleEdit,
        } = this;

        if (isEditing) {
            return (
                <>
                    <Button
                        readOnly={isFetching}
                        loading={isFetching}
                        type="submit"
                        disabled={hasErrors}
                        styleName="edit-button save-button"
                        label={__('account.profile.save')}
                    />
                    <Button
                        secondary
                        readOnly={isFetching}
                        onClick={toggleEdit}
                        styleName="edit-button"
                        label={__('account.profile.cancel')}
                    />
                </>
            );
        }

        return (
            <Button
                onClick={toggleEdit}
                styleName="edit-button"
                label={__('account.profile.editProfile')}
            />
        );
    }
    private readonly setReauthenticationStatus = (newProps: Props): void => {
        if (
            newProps.reauthenticationStatus !==
                this.props.reauthenticationStatus &&
            newProps.reauthenticationStatus === FetchStatus.SUCCESS
        ) {
            this.setState({ didReauthenticate: true });
        }
    };

    private readonly setEditStatus = (newProps: Props): void => {
        if (
            newProps.account !== this.props.account &&
            newProps.updateAccountFetchStatus !==
                this.props.updateAccountFetchStatus &&
            newProps.updateAccountFetchStatus === FetchStatus.SUCCESS
        ) {
            this.setState({ isEditing: false });
            this.injectAccountData(this.props.account);
        }
    };

    private get actionButtonsSection(): JSX.Element | null {
        return this.fieldIfEditable(
            'actions',
            <div styleName="action-buttons">{this.renderEditStateButtons}</div>,
        );
    }
}

export { EditInfo };
