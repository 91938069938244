import * as React from 'react';

const CheckedCircle = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 46 16"
    >
        <g fill="none" fillRule="evenodd">
            <circle cx="13" cy="13" r="13" fill="#1800FF" />
            <path
                stroke="#FFF"
                strokeLinecap="square"
                strokeWidth="3"
                d="M8 14.4l3.043 2.6L18 9"
            />
        </g>
    </svg>
);

export { CheckedCircle };
