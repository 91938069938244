import { withAnalytics } from 'components/common/analytics';
import { withInputValidation } from 'components/common/forms';
import { AccountInfo } from 'components/sign-up/components/phone-number/port-number/account-info';
import { compose } from 'redux';

const dataKeys: ReadonlyArray<string> = ['accountNumber', 'pin', 'zip'];

const enhance = compose(
    withAnalytics,
    withInputValidation(dataKeys),
);
const AccountInfoContainer = enhance(AccountInfo);

export { AccountInfoContainer };
