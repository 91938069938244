import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Chevron } from 'components/common/graphics/chevron';
import { BodyCopy } from 'components/common/typography';

const styles = require('./text-info.less');

interface Props {
    readonly label: string;
}

interface State {
    readonly open: boolean;
}

const DEFAULT_STATE = {
    open: false,
};

@CSSModules(styles, { allowMultiple: true })
class TextInfo extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;
    }
    render() {
        const {
            handleOnClick,
            props: { children, label },
            state: { open },
        } = this;
        return (
            <div styleName="text-info">
                <BodyCopy
                    marginTop
                    flex
                    medium
                    noMarginBottom
                    onClick={handleOnClick}
                    styleName="toggle"
                >
                    {label}
                    <Chevron marginLeft blue inverted={open} />
                </BodyCopy>
                <BodyCopy
                    medium
                    marginTop
                    noMarginBottom
                    styleName={classNames('content', { open })}
                >
                    {children}
                </BodyCopy>
            </div>
        );
    }
    readonly handleOnClick = () => {
        this.setState({ open: !this.state.open });
    };
}

export { TextInfo };
