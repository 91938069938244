import { withDeviceType } from 'components/common/device-type';
import { Notice } from 'components/common/notice';
import { Account, IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';

const mapStateToProps = ({ account }: IApplicationState) => ({
    account: new Account(account),
});

const enhance = compose(
    withDeviceType,
    connect(mapStateToProps),
);
const NoticeContainer = enhance(Notice);

export { NoticeContainer };
