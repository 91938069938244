import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./product-listing.less');
// const hostURL = 'https://visible.com/phones/';
import { ButtonContainer as Button } from 'containers';

interface IProps {
    readonly product: any;
    readonly key: any;
}
@CSSModules(styles)
class ProductListing extends React.Component<IProps> {
    constructor(props) {
        super(props);
    }

    render() {
        const { product, key } = this.props;
        const imageURL = product.ContentURL.split(':')[0];

        return (
            <div styleName="product-container">
                <div styleName="product-image-cont">
                    <img
                        id={key}
                        styleName="product-image"
                        src={'/assets/images/shop/' + imageURL.split('/')[2]}
                        onError={e => this.imageFailedtoLoad(key)}
                    />
                </div>
                <div styleName="product-info">
                    <div styleName="product-model">
                        {product.Make + ' ' + product.Name}
                    </div>
                    <div styleName="product-affirm">
                        {'Starting at '}
                        <b>{'$' + product.Price}</b>
                    </div>
                    <br />
                    {product.MRCValue > 0 ? (
                        <div styleName="product-affirm">
                            {'As low as '}
                            <b>{'$' + product.MRCValue + '/mo'}</b>
                            {' with'}
                            <img
                                styleName="affirm-logo"
                                src="/assets/images/shop/black_logo-transparent_bg.png"
                                alt="Affirm Logo"
                            />
                        </div>
                    ) : (
                        ''
                    )}
                    <br />
                    <div styleName="product-select-button">
                        <Button
                            invert
                            secondary
                            fullWidth
                            styleName="button"
                            label="Select"
                        />
                    </div>
                </div>
            </div>
        );
    }

    private readonly imageFailedtoLoad = key => {
        const i = document.getElementById(key);
        if (i) {
            i.setAttribute('src', __('home.generic-phone-img-src'));
        }
    };
}

export { ProductListing };
