import { IConfigurationBasePaths, IStayInLoop } from 'models';
import { ThinAirService } from './thin-air.service';
class StayInLoopService extends ThinAirService {
    static readonly DEFAULT_LEAD_VALUES = {
        Company: 'HOME_PAGE_CAPTURE',
        FirstName: 'HOME_PAGE_CAPTURE',
        LastName: 'HOME_PAGE_CAPTURE',
        inviteCode__c: 'HOME',
    };

    static async CaptureStayInLoop(
        iStayInLoop: IStayInLoop,
        basePaths: IConfigurationBasePaths,
    ): Promise<void> {
        const response = await fetch(
            `${StayInLoopService.BASE_URL}${basePaths.lead}`,
            {
                body: JSON.stringify({
                    ...StayInLoopService.DEFAULT_LEAD_VALUES,
                    Carrier__c: iStayInLoop.carrier,
                    Device_Model__c: iStayInLoop.device,
                    Email: iStayInLoop.email,
                    Mobile_OS__c: iStayInLoop.os,
                }),
                headers: StayInLoopService.getDefaultHeaders(),
                method: 'POST',
            },
        );

        if (response.status > 299) {
            throw new Error(
                await StayInLoopService._parseErrorOrDefault(
                    await response.json(),
                ),
            );
        }

        return;
    }

    static _parseErrorOrDefault(json: any) {
        try {
            return json[0].message || __('default_service_error');
        } catch (err) {
            return __('default_service_error');
        }
    }
}

export { StayInLoopService };
