import { BodyCopy, Headline } from 'components/common/typography';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./incompatibility-imei.less');

import { IAnalyticsProps } from 'components/common/analytics';

import { IDeviceTypeProps } from 'models';
import {
    TealiumFlowName,
    TealiumLinkEvents,
    TealiumPageLinkLocations,
    TealiumPageType,
    TealiumPagevents,
} from 'models/tealium';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';

import { ButtonContainer as Button } from 'containers';

interface IProps extends IAnalyticsProps, IDeviceTypeProps {
    redirectTo(url: string): void;
}

@CSSModules(styles, { allowMultiple: true })
class IncompatibilityIMEI extends React.Component<IProps, {}> {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <div styleName="section-imei-wrapper section-checks-wrapper">
                    <div styleName="email-text-header-wrapper">
                        <Headline center>
                            {__('incompatibility.header')}
                        </Headline>

                        <BodyCopy styleName="landing-text">
                            {__('incompatiblity.description')}
                        </BodyCopy>
                    </div>
                    <div styleName="button-container">
                        <Button
                            fullWidth
                            onClick={event => this.hadleLandingClick(event)}
                        >
                            {__('incompatibility.button.text')}
                        </Button>
                    </div>
                </div>
            </>
        );
    }

    private readonly hadleLandingClick = (
        event: React.FormEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();
        TealiumDispatcher.dispatchEvent(
            TealiumFlowName.NONE,
            TealiumPageType.NOT_COMPATABILE,
            TealiumPagevents.BROWSE_NEW_PHONES,
            TealiumPageLinkLocations.MODULE_ONE,
            TealiumLinkEvents.BUTTON,
        );
        const {
            props: { redirectTo },
        } = this;
        redirectTo(__('routes.phones'));
    };
}

export { IncompatibilityIMEI };
