import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import {
    ActionType as AuthenticationActionTypes,
    ISignOutAction,
} from 'state/authentication/authentication.actions';
import { ActionType, IManualPaymentAction } from './manual-payment.actions';

interface IManualPaymentState {
    readonly error: string;
    readonly fetchStatus: FetchStatus;
}

const DEFAULT_STATE: IManualPaymentState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
};

const {
    SUBMIT_MANUAL_PAYMENT,
    REPORT_MANUAL_PAYMENT_SUCCESS,
    REPORT_MANUAL_PAYMENT_ERROR,
} = ActionType;

const { SIGN_OUT } = AuthenticationActionTypes;

const manualPaymentReducer = (
    state: IManualPaymentState = DEFAULT_STATE,
    action: IManualPaymentAction | ISignOutAction | IDefaultAction,
): IManualPaymentState => {
    switch (action.type) {
        case SUBMIT_MANUAL_PAYMENT:
            return {
                ...state,
                error: '',
                fetchStatus: FetchStatus.FETCHING,
            };
        case REPORT_MANUAL_PAYMENT_SUCCESS:
            return {
                ...state,
                fetchStatus: FetchStatus.SUCCESS,
            };
        case REPORT_MANUAL_PAYMENT_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        case SIGN_OUT:
            return DEFAULT_STATE;
        default:
            return state;
    }
};

export { IManualPaymentState, DEFAULT_STATE, manualPaymentReducer };
