import { CustomerState, IAccount, IApplicationState } from 'models';
import {
    LOCATION_CHANGE,
    LocationChangeAction,
    push,
} from 'react-router-redux';
import {
    all,
    call,
    put,
    race,
    select,
    take,
    takeEvery,
} from 'redux-saga/effects';
import { accountSuspended } from 'state/account';
import { checkAuthenticationStatus } from 'state/authentication/authentication.actions';
import { updateNuanceChange } from 'state/chat/chat.saga';
import { eventPageView } from 'state/tealium/tealium.actions';
import { smellyStoreSingleton } from 'utils/store';
import {
    ActionType,
    closeSidebarCart,
    dismissModal,
    reportCancel,
    showModal,
} from './view.actions';

const { CONFIRM, CANCEL, DISMISS_MODAL } = ActionType;

// tslint:disable:no-console

/**
 * Tealium UTAG page view tracking call
 *
 * @param {LocationChangeAction} action
 */
function* updateTealiumPageView(action: LocationChangeAction) {
    try {
        yield put(eventPageView());
    } catch (err) {
        // Do nothing
        console.error(err);
    }
}

function* checkCustomerSuspended(action: LocationChangeAction) {
    const { dispatch } = smellyStoreSingleton.store;
    const account: IAccount = yield select(
        (state: IApplicationState) => state.account,
    );
    if (account.customerState === CustomerState.SUSPENDED) {
        // Do not allow user to navigate to account or sign-up.
        if (
            action.payload.pathname.startsWith(__('routes.account')) ||
            action.payload.pathname.startsWith(__('routes.sign-up'))
        ) {
            dispatch(push('/'));
        } else {
            yield call(accountSuspended);
        }
    }
}

function* onLocationChange(action: LocationChangeAction) {
    const account: IAccount = yield select(
        (state: IApplicationState) => state.account,
    );
    const modalQueue = yield select(
        ({ view }: IApplicationState) => view.modalQueue,
    );
    const sidebarCartOpened = yield select(
        ({ view: { sidebarCartOpen } }: IApplicationState) => sidebarCartOpen,
    );
    const location = yield select(
        (state: IApplicationState) => state.routing.location,
    );
    if (modalQueue.length) {
        // TODO(dan): Smelly.  Will allow for now, but figure out a better way.
        if (modalQueue[0].name === 'create-account') {
            if (
                // Dismiss if not on sign-up.
                location.pathname !== __('routes.sign-up') ||
                // Dismiss if on sign-up, and has account.
                (location.pathname === __('routes.sign-up') &&
                    account.accountid)
            ) {
                yield put(reportCancel());
                yield put(dismissModal());
            }
        }
    }
    yield call(updateNuanceChange, action.payload.pathname);
    yield call(updateTealiumPageView, action);
    if (
        account.customerState === CustomerState.UNKNOWN ||
        account.accountid === ''
    ) {
        // If no local customer state, check Firebase to see if they have logged in via another service (ex. Omniscripts via /phones).
        yield put(checkAuthenticationStatus());
    }
    yield call(checkCustomerSuspended, action);
    if (sidebarCartOpened) {
        yield put(closeSidebarCart());
    }
}

function* confirmWithModal(modal) {
    // Show the dialog
    yield put(showModal(modal));

    // Wait for either a yes or a no.
    const { yes } = yield race({
        dismiss: take(DISMISS_MODAL),
        no: take(CANCEL),
        yes: take(CONFIRM),
    });

    return !!yes;
}

function* viewSaga() {
    yield all([takeEvery(LOCATION_CHANGE, onLocationChange)]);
}

export { viewSaga, confirmWithModal };
