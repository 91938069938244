import { IDefaultAction } from 'lib/br-redux';
import { ActionType, IKountDeviceAction } from './kount-device.actions';

interface IKountDeviceState {
    readonly kountDevice: object;
}

const DEFAULT_STATE: IKountDeviceState = {
    kountDevice: {},
};

const { REPORT_KOUNT_DEVICE_SUCCESS } = ActionType;

const kountDeviceReducer = (
    state: IKountDeviceState = DEFAULT_STATE,
    action: IKountDeviceAction | IDefaultAction,
): IKountDeviceState => {
    switch (action.type) {
        case REPORT_KOUNT_DEVICE_SUCCESS:
            return {
                ...state,
                kountDevice: action.payload,
            };
        default:
            return state;
    }
};

export { IKountDeviceState, DEFAULT_STATE, kountDeviceReducer };
