import { auth } from 'firebase/app';

import { ReauthenticationModalContainer as ReauthenticationModal } from 'containers';
import 'firebase/auth';
import {
    Account,
    CustomerState,
    IApplicationState,
    StartupScript,
} from 'models';
import { push } from 'react-router-redux';
import { delay } from 'redux-saga';
import {
    all,
    call,
    put,
    race,
    select,
    take,
    takeEvery,
} from 'redux-saga/effects';
import { AccountService } from 'services';
import {
    accountLapsed,
    accountSuspended,
    accountTerminated,
    stopPollLapsedReactivation,
    emailVerifyNotice,
} from 'state/account';
import { loadUserAccount } from 'state/account/account.actions';
import { updateSentEmailVerifyNoticy } from 'state/account/account.saga';
import {
    resetChatInit,
    updateNuanceChange,
    updateNuanceFBID,
} from 'state/chat/chat.saga';
import {
    getCurrentUser,
    getToken,
    signInWithEmailAndPassword,
    signOut as signOutOfFirebase,
    trySendVerificationEmail,
} from 'state/firebase/firebase.saga';
import { fetchPortStatus } from 'state/port-status';
import { fetchCart } from 'state/purchase/cart/cart.actions';
import { StartupActions } from 'state/startup';
import { trackError } from 'state/tealium';
import {
    closeSidebarCart,
    dismissModal,
    dismissNotice,
    showMessage,
    showNotice,
} from 'state/view/view.actions';
import { confirmWithModal } from 'state/view/view.saga';
import {
    ActionType,
    authInit,
    IAuthenticateWithEmailAndPasswordAction,
    IFinishReauthenticationWithPasswordAction,
    reportAuthenticationError,
    reportAuthenticationSuccess,
    reportInitializationFailure,
    reportInitializationSuccess,
    reportReauthenticationFailure,
    reportReauthenticationSuccess,
    signOut as createSignOutAction,
    stopPollEmailVerification,
    startPollEmailVerification,
} from './authentication.actions';
import { ThinAirService } from 'services/thin-air.service';

import { OMNI_SESSION_KEYS } from 'models/constants';
// tslint:disable:no-console

const clearSessionKeys = () => {
    Object.keys(OMNI_SESSION_KEYS).forEach(key => {
        sessionStorage.removeItem(OMNI_SESSION_KEYS[key]);
    });
};
function* runPostAuthSteps() {
    try {
        const customerState = yield select(
            ({ account }: IApplicationState) => account.customerState,
        );
        const account = yield select(
            ({ account }: IApplicationState) => account,
        );
        yield call(resetChatInit);
        switch (customerState) {
            case CustomerState.LAPSED:
                yield put(fetchCart());
                yield call(accountLapsed);
                break;
            case CustomerState.ACTIVE:
            case CustomerState.PREACTIVATED:
            case CustomerState.PURCHASED:
                yield put(fetchCart());
                if (account.emailVerified === false) {
                    yield put(showNotice(emailVerifyNotice(account)));
                    yield put(startPollEmailVerification());
                }
                yield put(fetchPortStatus());
                break;
            case CustomerState.PAUSED:
                break;
            case CustomerState.SUSPENDED:
                yield call(accountSuspended);
                break;
            case CustomerState.TERMINATED:
                yield call(accountTerminated);
                break;
            case CustomerState.REGISTERED:
            case CustomerState.UNKNOWN:
                yield put(fetchCart());
                break;
        }
    } catch (err) {
        yield put(fetchCart()); // fallback to creating a new cart to keep from blocking anyone
    }
}

function* initialize() {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const fbUser = yield call(getCurrentUser);
        if (fbUser) {
            const token = yield getToken();
            sessionStorage.setItem('firebaseToken', token);
            const ExchangeTokenExpiration: any = sessionStorage.getItem(
                'ExchangeTokenExpiration',
            );
            const sessionExchangeToken: any = sessionStorage.getItem(
                'ExchangeToken',
            );
            let exchangeToken: any = {};
            if (sessionExchangeToken && ExchangeTokenExpiration) {
                const currentTime: any = new Date().getTime();
                if (currentTime + 180 * 1000 > ExchangeTokenExpiration) {
                    // Token exchange called if user is signed in and token about to expire
                    exchangeToken = yield call(
                        AccountService.getExcToken,
                        basePaths,
                        token,
                    );
                }
            } else {
                // Token exchange called if user is signed in and has no token
                exchangeToken = yield call(
                    AccountService.getExcToken,
                    basePaths,
                    token,
                );
            }
            if (exchangeToken.status) {
                switch (exchangeToken.status) {
                    case 200:
                        const excToken: any = yield call(
                            ThinAirService.parseJson,
                            exchangeToken,
                            __('default-error-message'),
                        );
                        const ExchangeTokenExpiration: any = new Date(
                            parseFloat(excToken.issued_at) +
                                parseFloat(excToken.expires_in) * 1000,
                        ).getTime();
                        sessionStorage.setItem(
                            'ExchangeTokenExpiration',
                            ExchangeTokenExpiration,
                        );
                        sessionStorage.setItem(
                            'ExchangeToken',
                            excToken.access_token,
                        );
                        if (sessionStorage.getItem('PreviousExpTimer')) {
                            sessionStorage.removeItem('PreviousExpTimer');
                        }
                        break;
                    default:
                        // unhandled response code - show an error
                        throw new Error(__('default-error-message'));
                }
            }

            const account = yield call(
                AccountService.findAccount,
                basePaths,
                token,
            );
            const user = yield call(Account.Normalize, account, fbUser);
            yield call(AccountService.populateFields, user);
            sessionStorage.setItem('AccountDetails', JSON.stringify(account));
            yield put(loadUserAccount(user));
        } else {
            // yield call(signOut);
        }
        yield put(reportInitializationSuccess());
        yield call(delay, 1000);
        // Must be after reportInitializationSuccess to ensure fetch status set to SUCCESS.
        if (fbUser) {
            yield put(reportAuthenticationSuccess());
        }
        yield put(
            StartupActions.reportInitializationSuccess(
                StartupScript.AUTHENTICATION,
            ),
        );
    } catch (error) {
        yield put(createSignOutAction()); // make sure that everything is cleared
        yield put(push('/'));
        yield put(
            reportInitializationFailure(
                __('sign-in.authentication.initialization-error'),
            ),
        );
    }
}

function* pollEmailVerification() {
    yield race({
        cancel: take(ActionType.STOP_POLL_EMAIL_VERIFICATION),
        run: call(fetchEmailVerification),
    });
}

function* fetchEmailVerification() {
    try {
        const user = yield call(getCurrentUser);
        yield call([user, user.reload]);
        if (user && user.emailVerified) {
            const account = yield select(
                ({ account }: IApplicationState) => account,
            );
            yield put(
                loadUserAccount(
                    new Account({ ...account, emailVerified: true }),
                ),
            );
            yield put(dismissNotice());
            yield put(stopPollEmailVerification());
        }
    } catch (err) {
        /* continue  */
    }
}

function* authenticateWithEmailAndPassword(
    action: IAuthenticateWithEmailAndPasswordAction,
) {
    try {
        yield* signInWithEmailAndPassword(action.payload);
        const token = yield getToken();
        sessionStorage.setItem('firebaseToken', token);
        yield call(initialize);
        yield put(reportAuthenticationSuccess());
        loadUserAccount;
        yield call(updateNuanceFBID, token);
        const loc = window.location.search.split('=');
        if (loc && loc.length > 0 && loc[0] === '?goto') {
            window.location.href = '/account/' + loc[1];
        }
    } catch (err) {
        yield put(reportAuthenticationError((err as Error).message));
    }
}

function* reauthenticate() {
    try {
        const user: firebase.User | undefined = yield call(getCurrentUser);
        if (user) {
            yield call(confirmWithModal, {
                additionalProps: {},
                component: ReauthenticationModal,
            });
        } else {
            throw new Error(__('sign-in.authentication.get-user-error'));
        }
    } catch (err) {
        yield put(reportReauthenticationFailure((err as Error).message));
    }
}

function* finisheReauthenticationWithPassword(
    action: IFinishReauthenticationWithPasswordAction,
) {
    try {
        const user: firebase.User | undefined = yield call(getCurrentUser);
        if (user) {
            const credential = auth.EmailAuthProvider.credential(
                user.email as string,
                action.payload,
            );
            yield call(
                user.reauthenticateWithCredential.bind(user),
                credential,
            );
            yield put(reportReauthenticationSuccess());
        } else {
            throw new Error(__('sign-in.authentication.get-user-error'));
        }
    } catch (err) {
        yield put(reportReauthenticationFailure((err as Error).message));
    }
}

function* clearSession() {
    try {
        clearSessionKeys();
        yield put(dismissModal());
        yield put(closeSidebarCart());
        yield put(dismissNotice());
        yield put(stopPollEmailVerification());
        yield put(stopPollLapsedReactivation());
        if (typeof window !== 'undefined') {
            yield call(updateNuanceChange, window.location.pathname);
        }
    } catch (err) {
        // tslint:disable-next-line:no-console
        console.warn(err);
    }
}

function* signOut() {
    try {
        // Clear & navigate first to avoid artifacts on current page that could be caused by sign out state.
        clearSessionKeys();
        window.localStorage.clear();
        yield call(clearSession);
        yield call(signOutOfFirebase);
    } catch (err) {
        // tslint:disable-next-line:no-console
        console.warn(err);
    }
}

function* resendVerificationEmail() {
    try {
        yield call(trySendVerificationEmail);
        yield put(showMessage('Validation email sent!'));
        // tslint:disable-next-line:no-empty
    } catch (err) {}
}

function* watchForFirebaseAndConfigInit() {
    try {
        yield call(initialize);
        // tslint:disable-next-line:no-empty
    } catch (err) {}
}

function* checkAuthenticationStatus() {
    const account = yield select(({ account }: IApplicationState) => account);

    if (
        account.customerState === CustomerState.UNKNOWN ||
        account.accountid === '' ||
        account.emailVerified === undefined
    ) {
        // User is not logged in locally.  Try to getCurrentUser from Firebase.
        yield call(authInit);
    }
}

function* refreshExcToken() {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const token = sessionStorage.getItem('ExchangeToken');
        const exchangeToken = yield call(
            AccountService.refreshExcToken,
            basePaths,
            token,
        );
        switch (exchangeToken.status) {
            case 200:
                const excToken: any = yield call(
                    ThinAirService.parseJson,
                    exchangeToken,
                    __('default-error-message'),
                );
                const ExchangeTokenExpiration: any = new Date(
                    parseFloat(excToken.issued_at) +
                        parseFloat(excToken.expires_in) * 1000,
                ).getTime();
                sessionStorage.setItem(
                    'ExchangeTokenExpiration',
                    ExchangeTokenExpiration,
                );
                sessionStorage.setItem('ExchangeToken', excToken.access_token);
                break;
            default:
                // unhandled response code - show an error
                throw new Error(__('default-error-message'));
        }
    } catch (error) {
        console.log('error to fetch refresh token');
    }
}

function* authenticationSaga() {
    yield all([
        takeEvery(
            ActionType.AUTHENTICATE_WITH_EMAIL_AND_PASSWORD,
            authenticateWithEmailAndPassword,
        ),
        takeEvery(ActionType.CLEAR_AUTHENTICATION_SESSION, clearSession),
        takeEvery(
            ActionType.CHECK_AUTHENTICATION_STATUS,
            checkAuthenticationStatus,
        ),
        takeEvery(ActionType.SIGN_OUT, signOut),
        takeEvery(ActionType.AUTH_INIT, watchForFirebaseAndConfigInit),
        takeEvery(
            ActionType.RESEND_VERIFICATION_EMAIL,
            resendVerificationEmail,
        ),
        takeEvery(
            ActionType.RESEND_VERIFICATION_EMAIL,
            updateSentEmailVerifyNoticy,
        ),
        takeEvery(ActionType.REAUTHENTICATE, reauthenticate),
        takeEvery(
            ActionType.FINISH_REAUTHENTICATION_WITH_PASSWORD,
            finisheReauthenticationWithPassword,
        ),
        takeEvery(ActionType.REPORT_AUTHENTICATION_SUCCESS, runPostAuthSteps),
        takeEvery(
            ActionType.START_POLL_EMAIL_VERIFICATION,
            pollEmailVerification,
        ),
        takeEvery(ActionType.REPORT_INITIALIZATION_FAILURE, trackError),
        takeEvery(ActionType.REPORT_AUTHENTICATION_ERROR, trackError),
        takeEvery(ActionType.REPORT_REAUTHENTICATION_ERROR, trackError),
        takeEvery(ActionType.REFRESH_EXC_TOKEN, refreshExcToken),
    ]);
}

export { authenticationSaga, runPostAuthSteps };
