import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./close.less');

interface Props {
    readonly left?: boolean;
    readonly invert?: boolean;
    onClick(): void;
}

@CSSModules(styles, { allowMultiple: true })
class Close extends React.Component<Props> {
    render() {
        const {
            props: { invert, left, ...props },
        } = this;
        return (
            <span
                tabIndex={0}
                role={'button'}
                aria-label={'Close'}
                styleName={classNames('close', { invert, left })}
                {...props}
            />
        );
    }
}

export { Close };
