import { withDeviceType } from 'components/common/device-type';
import { ShopVisible } from 'components/shop-visible';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchProducts } from '../state/shop/shop.actions';

const mapStateToProps = ({ shop }: IApplicationState) => ({
    shop,
});

const mapDispatchToProps = dispatch => ({
    fetchProducts: () => dispatch(fetchProducts()),
});

const enhance = compose(
    withDeviceType,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const ShopVisibleContainer = enhance(ShopVisible);
export { ShopVisibleContainer };
