import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { BodyCopy, Headline, Subhead } from 'components/common/typography';
import { Grid, GridTemplates } from 'components/grid';
import { ButtonContainer as Button } from 'containers';
import {
    Account,
    IAnalyticsProps,
    IDeviceTypeProps,
    IQueueableModal,
    CustomerState,
    PurchaseType,
    ProductType,
    HandsetCartItem,
} from 'models';
import { Hidden } from 'components/common/hidden';
import {
    TealiumFlowName,
    TealiumLinkEvents,
    TealiumLoginState,
    TealiumPageLinkLocations,
    TealiumPageType,
    TealiumPagevents,
} from 'models/tealium';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';
import { ClearCart } from './clear-cart';

const styles = require('./registered.less');

interface Props extends IDeviceTypeProps, IAnalyticsProps {
    readonly account: Account;
    readonly cart: any;
    redirectTo(url: string): void;
    showAccountSignupModal(): void;
    showModal(modal: IQueueableModal): void;
    dismissModal(): void;
    deleteDraftOrderFromCart(): void;
}

@CSSModules(styles, { allowMultiple: true })
class Registered extends React.Component<Props> {
    componentDidMount() {
        TealiumDispatcher.dispatchView(
            TealiumFlowName.NONE,
            TealiumPageType.HOME,
            TealiumLoginState.LOGGED_IN,
            this.props.account.accountid,
        );
    }
    render() {
        const {
            props: { account, isDesktop, cart },
        } = this;
        if (cart && cart.id !== '') {
            sessionStorage.setItem('OrderId', cart.id);
        }
        const deviceDraft = cart && this.showClearCart(cart);
        return (
            <Grid>
                <div styleName="hero-block">
                    <div styleName="hero-block__content">
                        <GridTemplates.HeadlineTemplate
                            width={12}
                            noMargin
                            headline={
                                <>
                                    {__('home.registered.hero.header')}
                                    {isDesktop ? <br /> : ' '}
                                    {__('home.registered.hero.header-2')}
                                    {', '}
                                    {account.firstName}
                                </>
                            }
                        >
                            <Hidden
                                when={
                                    account.customerState ===
                                    CustomerState.LAPSED
                                }
                            >
                                <BodyCopy styleName="hero-block__body">
                                    {deviceDraft
                                        ? __(
                                              'home.registered.hero.subheader-draft-device',
                                          )
                                        : __('home.registered.hero.subheader')}
                                </BodyCopy>
                                <div styleName="button-options">
                                    <Button
                                        fullWidthMobile
                                        minWidth={222}
                                        onClick={e => {
                                            e.stopPropagation();
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                TealiumPageType.HOME,
                                                TealiumPagevents.REGISTERED_HOME_CONTINUE,
                                                TealiumPageLinkLocations.REGISTERED_CONTINUE_LOCATION,
                                                TealiumLinkEvents.BUTTON,
                                                TealiumLoginState.LOGGED_IN,
                                                this.props.account
                                                    ? this.props.account
                                                          .accountid
                                                    : '',
                                            );
                                            this.props.showAccountSignupModal();
                                        }}
                                        styleName="hero-block__btn"
                                        label={__(
                                            'home.registered.hero.heroCta',
                                        )}
                                    />
                                    {deviceDraft && (
                                        <Button
                                            fullWidthMobile
                                            minWidth={222}
                                            onClick={() => {
                                                this.cleartCartModal();
                                            }}
                                            styleName="hero-clear__btn"
                                            label={__(
                                                'home.registered.hero.clearCart',
                                            )}
                                        />
                                    )}
                                </div>
                            </Hidden>
                        </GridTemplates.HeadlineTemplate>
                    </div>
                </div>
                <div styleName="emo-block__header">
                    <Headline two>{__('home.registered.emo-header')}</Headline>
                </div>
                <div styleName="emo-block">
                    <div styleName="emo-block__item">
                        <img
                            src={__('home.registered.emo-icon-1')}
                            alt=""
                            styleName="emo-block__icon"
                        />
                        <Subhead styleName="emo-block__subhead">
                            {__('home.registered.emo-head-1')}
                        </Subhead>
                        <BodyCopy noMargin styleName="emo-block__body">
                            {__('home.registered.emo-body-1')}
                        </BodyCopy>
                    </div>

                    <div styleName="emo-block__item">
                        <img
                            src={__('home.registered.emo-icon-2')}
                            alt=""
                            styleName="emo-block__icon"
                        />
                        <Subhead styleName="emo-block__subhead">
                            {__('home.registered.emo-head-2')}
                        </Subhead>
                        <BodyCopy noMargin styleName="emo-block__body">
                            {__('home.registered.emo-body-2')}
                        </BodyCopy>
                    </div>

                    <div styleName="emo-block__item">
                        <img
                            src={__('home.registered.emo-icon-3')}
                            alt=""
                            styleName="emo-block__icon"
                        />
                        <Subhead styleName="emo-block__subhead">
                            {__('home.registered.emo-head-3')}
                        </Subhead>
                        <BodyCopy noMargin styleName="emo-block__body">
                            {__('home.registered.emo-body-3')}
                        </BodyCopy>
                    </div>

                    <div styleName="emo-block__item">
                        <img
                            src={__('home.registered.emo-icon-4')}
                            alt=""
                            styleName="emo-block__icon"
                        />
                        <Subhead styleName="emo-block__subhead">
                            {__('home.registered.emo-head-4')}
                        </Subhead>
                        <BodyCopy noMargin styleName="emo-block__body">
                            {__('home.registered.emo-body-4')}
                        </BodyCopy>
                    </div>
                </div>
            </Grid>
        );
    }

    private readonly getHandset = (cart): HandsetCartItem | null => {
        return cart.result.items.filter(
            item => item.productType === ProductType.HANDSET,
        );
    };

    private readonly showClearCart = (cart): boolean => {
        const { result } = cart;
        if (
            result &&
            result.status === PurchaseType.STATUS_DRAFT &&
            result.items &&
            result.items.length > 0
        ) {
            return true;
        }
        return false;
    };
    private readonly cleartCartModal = (): void => {
        const clearCartConfirm = () => {
            this.props.deleteDraftOrderFromCart();
            this.props.dismissModal();
            // this.props.redirectTo(__('routes.signup-success'));
        };
        const clearCartNo = () => {
            this.props.dismissModal();
        };
        const { showModal, cart } = this.props;
        const handset = this.getHandset(cart);
        const handsetName = handset && handset[0].name;

        showModal({
            additionalProps: { clearCartConfirm, clearCartNo, handsetName },
            component: ClearCart as any,
        });
    };
}

export { Registered };
