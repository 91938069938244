// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

import { IStayInLoop } from 'models';

import { createAction, IAction } from 'lib/br-redux';

enum ActionType {
    REPORT_ERROR_STAY_IN_LOOP = 'STAY-IN-LOOP/REPORT_ERROR',
    REPORT_SUCCESS_STAY_IN_LOOP = 'STAY-IN-LOOP/REPORT_SUCCESS',
    CAPTURE_STAY_IN_LOOP = 'STAY-IN-LOOP/CAPTURE',
    SHOW_STAY_IN_LOOP_LANDING = 'STAY-IN-LOOP/SHOW-LANDING',
}

type IStayInLoopAction =
    | CaptureStayInLoop
    | ReportErrorStayInLoop
    | ReportSuccessStayInLoop
    | ShowStayInLoopLanding;

type CaptureStayInLoop = IAction<ActionType.CAPTURE_STAY_IN_LOOP, IStayInLoop>;
type ReportErrorStayInLoop = IAction<
    ActionType.REPORT_ERROR_STAY_IN_LOOP,
    string
>;
type ReportSuccessStayInLoop = IAction<ActionType.REPORT_SUCCESS_STAY_IN_LOOP>;

type ShowStayInLoopLanding = IAction<
    ActionType.SHOW_STAY_IN_LOOP_LANDING,
    IStayInLoop
>;

const captureStayInLoop = createAction(
    ActionType.CAPTURE_STAY_IN_LOOP,
    (IStayInLoop: IStayInLoop) => IStayInLoop,
);

const reportErrorStayInLoop = createAction(
    ActionType.REPORT_ERROR_STAY_IN_LOOP,
    (err: string) => err,
);

const reportSuccessStayInLoop = createAction(
    ActionType.REPORT_SUCCESS_STAY_IN_LOOP,
);

const showStayInLoopLanding = createAction(
    ActionType.SHOW_STAY_IN_LOOP_LANDING,
    (iStayInLoop: IStayInLoop) => iStayInLoop,
);

export {
    ActionType,
    CaptureStayInLoop,
    IStayInLoopAction,
    reportErrorStayInLoop,
    reportSuccessStayInLoop,
    captureStayInLoop,
    showStayInLoopLanding,
    ShowStayInLoopLanding,
};
