import { withAnalytics } from 'components/common/analytics';
import { withInputValidation } from 'components/common/forms/input';
import { ForgotPassword } from 'components/forgot-password';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { clearPasswordReset, resetPasswordForEmail } from 'state/account';

const dataKeys: ReadonlyArray<string> = ['email'];

const mapStateToProps = (state: IApplicationState) => ({
    error: state.account.resetPasswordError,
    status: state.account.resetPasswordFetchStatus,
});
const mapDispatchToProps = dispatch => ({
    clearPasswordReset: () => dispatch(clearPasswordReset()),
    sendEmail: (email: string) => dispatch(resetPasswordForEmail(email)),
});

const enhance = compose(
    withAnalytics,
    withInputValidation(dataKeys),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const ForgotPasswordContainer = enhance(ForgotPassword);

export { ForgotPasswordContainer };
