import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./terms.less');

@CSSModules(styles)
class TermsOfUse extends React.Component<{}> {
    render() {
        return <>{(window.location.href = __('routes.terms-of-use'))}</>;
    }
}
export { TermsOfUse };
