import { IConfigurationBasePaths, IProductItemModel } from 'models';
import { ThinAirService } from './thin-air.service';

class ProductsService extends ThinAirService {
    static async fetchProducts(
        basePaths: IConfigurationBasePaths,
    ): Promise<IProductItemModel> {
        return ProductsService.fetchJson<IProductItemModel>(
            `${ProductsService.BASE_URL}${basePaths.products}`,
            {
                headers: ProductsService.getDefaultHeaders(),
            },
        );
    }

    static async fetchPhones(
        basePaths: IConfigurationBasePaths,
    ): Promise<IProductItemModel> {
        return ProductsService.fetchJson<IProductItemModel>(
            `${ProductsService.BASE_URL}${
                basePaths.products
            }/getProducts?BYOD=false&Family=HANDSET`,
            {
                headers: ProductsService.getDefaultHeaders(),
            },
        );
    }
}

export { ProductsService };
