import { combineReducers } from 'redux';
import { clearStateOnSignOut } from 'state/utility';
import { cartReducer as cart, ICartState } from './cart/cart.reducer';
import {
    checkoutReducer as checkout,
    ICheckoutState,
} from './checkout/checkout.reducer';
import { IMdnState, mdnReducer as mdn } from './mdn/mdn.reducer';
import { INpaNxxState, npaNxxReducer as npanxx } from './npanxx/npanxx.reducer';
import { IPortState, portReducer as port } from './port/port.reducer';

interface IPurchaseState {
    readonly cart: ICartState;
    readonly checkout: ICheckoutState;
    readonly mdn: IMdnState;
    readonly npanxx: INpaNxxState;
    readonly port: IPortState;
}

const reducers = combineReducers({
    cart,
    checkout,
    mdn,
    npanxx,
    port,
});

const purchaseReducer = clearStateOnSignOut(reducers);

export { purchaseReducer, IPurchaseState };
