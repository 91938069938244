import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import {
    ActionType as AuthenticationActionTypes,
    ISignOutAction,
} from 'state/authentication/authentication.actions';
import { ActionType, ILapsedPaymentAction } from './lapsed-payment.actions';

interface ILapsedPaymentState {
    readonly error: string;
    readonly fetchStatus: FetchStatus;
}

const DEFAULT_STATE: ILapsedPaymentState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
};

const {
    SHOW_PAY_NOW,
    SUBMIT_PAY_NOW,
    REPORT_PAY_NOW_ERROR,
    REPORT_PAY_NOW_SUCCESS,
} = ActionType;

const { SIGN_OUT } = AuthenticationActionTypes;

const lapsedPaymentReducer = (
    state: ILapsedPaymentState = DEFAULT_STATE,
    action: ILapsedPaymentAction | ISignOutAction | IDefaultAction,
): ILapsedPaymentState => {
    switch (action.type) {
        case SHOW_PAY_NOW:
            return {
                ...state,
            };
        case SUBMIT_PAY_NOW:
            return {
                ...state,
                error: '',
                fetchStatus: FetchStatus.FETCHING,
            };
        case REPORT_PAY_NOW_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        case REPORT_PAY_NOW_SUCCESS:
            return {
                ...state,
                error: '',
                fetchStatus: FetchStatus.SUCCESS,
            };
        case SIGN_OUT:
            return DEFAULT_STATE;
        default:
            return state;
    }
};

export { ILapsedPaymentState, DEFAULT_STATE, lapsedPaymentReducer };
