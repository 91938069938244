interface IEmailPassword {
    readonly email: string;
    readonly password: string;
    readonly rememberMe?: boolean;
}

class EmailPassword implements IEmailPassword {
    static readonly DEFAULT = {
        email: '',
        password: '',
        rememberMe: false,
    };

    readonly email: string;
    readonly password: string;
    readonly rememberMe: boolean;

    constructor(credentials: IEmailPassword) {
        this.email = credentials.email;
        this.password = credentials.password;
        this.rememberMe = credentials.rememberMe || false;
    }

    static Deserialize(credentials: Partial<IEmailPassword>): EmailPassword {
        return new EmailPassword({
            ...EmailPassword.DEFAULT,
            ...credentials,
        });
    }
}

export { EmailPassword, IEmailPassword };
