import { combineReducers } from 'redux';
import { clearStateOnSignOut } from 'state/utility';
import {
    autoPayReducer,
    DEFAULT_STATE as AUTOPAY_STATE,
    IAutoPayState,
} from './autopay/autopay.reducer';
import {
    clientTokenReducer,
    DEFAULT_STATE as CLIENT_TOKEN_STATE,
    IClientTokenState,
} from './client-token/client-token.reducer';
import {
    DEFAULT_STATE as DEFAULT_METHOD_STATE,
    defaultPaymentMethodReducer,
    IDefaultPaymentState,
} from './default-method/default-method.reducer';
import {
    DEFAULT_STATE as MANUAL_PAYMENT_STATE,
    IManualPaymentState,
    manualPaymentReducer,
} from './manual-payment/manual-payment.reducer';

import {
    DEFAULT_STATE as PAYMENT_NONCE_STATE,
    IPaymentNonceState,
    paymentNonceReducer,
} from './payment-nonce/payment-nonce-reducer';

import {
    DEFAULT_STATE as LAPSED_PAYMENT_STATE,
    ILapsedPaymentState,
    lapsedPaymentReducer,
} from './lapsed-payment/lapsed-payment.reducer';

import {
    DEFAULT_STATE as KOUNT_DEVICE_STATE,
    IKountDeviceState,
    kountDeviceReducer,
} from './kount-device/kount-device.reducer';

interface IPaymentState {
    readonly autopay: IAutoPayState;
    readonly clientToken: IClientTokenState;
    readonly defaultMethod: IDefaultPaymentState;
    readonly kountDevice: IKountDeviceState;
    readonly lapsedPayment: ILapsedPaymentState;
    readonly manualPayment: IManualPaymentState;
    readonly paymentNonce: IPaymentNonceState;
}

const reducers = combineReducers({
    autopay: autoPayReducer,
    clientToken: clientTokenReducer,
    defaultMethod: defaultPaymentMethodReducer,
    kountDevice: kountDeviceReducer,
    lapsedPayment: lapsedPaymentReducer,
    manualPayment: manualPaymentReducer,
    paymentNonce: paymentNonceReducer,
});

const DEFAULT_STATE: IPaymentState = {
    autopay: AUTOPAY_STATE,
    clientToken: CLIENT_TOKEN_STATE,
    defaultMethod: DEFAULT_METHOD_STATE,
    kountDevice: KOUNT_DEVICE_STATE,
    lapsedPayment: LAPSED_PAYMENT_STATE,
    manualPayment: MANUAL_PAYMENT_STATE,
    paymentNonce: PAYMENT_NONCE_STATE,
};

const paymentReducer = clearStateOnSignOut(reducers);

export { IPaymentState, paymentReducer, DEFAULT_STATE };
