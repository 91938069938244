import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Hamburger, Logo, Times } from 'components/common/graphics';
import { Hidden } from 'components/common/hidden';
import { AuthenticatedLinksContainer as AuthenticatedLinks } from 'containers';
import { Account, HandsetCartItem, IDeviceTypeProps } from 'models';
import {
    TealiumFlowName,
    TealiumLinkEvents,
    TealiumLoginState,
    TealiumPageLinkLocations,
    TealiumPageType,
    TealiumPagevents,
} from 'models/tealium';
import { Link } from 'react-router-dom';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';
import { NavLinks } from './nav-links';

const styles = require('./navigation.less');

interface Props extends IDeviceTypeProps {
    readonly account: Account;
    readonly device: HandsetCartItem | null;
    readonly isAuthenticated: boolean;
    readonly transparent: boolean;
    signOut(): void;
    toggleSidebarCart(): void;
    startPollEmailVerification(): void;
    checkCart(): void;
}
interface State {
    readonly mobileExpanded: boolean;
    readonly overflow: 'visible' | 'hidden';
    readonly isCartPresent: boolean;
}

@CSSModules(styles, { allowMultiple: true })
class Navigation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            mobileExpanded: false,
            overflow: 'visible',
            isCartPresent: false,
        };
    }

    componentDidMount() {
        const { checkCart } = this.props;
        checkCart();
    }

    render() {
        const { account, isAuthenticated, signOut, transparent } = this.props;
        const { mobileExpanded, isCartPresent } = this.state;
        return (
            <header
                styleName={classNames('header', {
                    mobileExpanded,
                    transparent,
                })}
            >
                <a styleName="skip-link" href="#main-content">
                    Skip to main content
                </a>
                <div styleName="nav-container">
                    <nav styleName="nav">
                        <div styleName="nav-left">
                            <div styleName="hamburger-wrapper">
                                <button
                                    styleName="hamburger-button"
                                    onClick={this.onHamburgerClick}
                                >
                                    <span styleName="hamburger">
                                        {mobileExpanded ? (
                                            <Times />
                                        ) : (
                                            <Hamburger />
                                        )}
                                    </span>
                                </button>
                            </div>
                            <div styleName="logo-wrapper">
                                <Link
                                    onClick={async () => {
                                        await this.tealiumEventLogo();
                                        await this.props.startPollEmailVerification();
                                    }}
                                    to={__('routes.home')}
                                >
                                    <span styleName="logo">
                                        <Logo />
                                    </span>
                                    {/*TODO(dan) Old logo must remain in markup or mobile social icons render wrong.*/}
                                    <span styleName="logo-old">
                                        <Logo old={true} />
                                    </span>
                                </Link>
                            </div>
                            <div styleName="cart-icon-small-screen-only">
                                {isCartPresent ? (
                                    <Link to={__('routes.cart')}>
                                        <img
                                            src={__(
                                                'navigation.cart.present.icon',
                                            )}
                                        />
                                    </Link>
                                ) : (
                                    <Link to={__('routes.cart')}>
                                        <img
                                            src={__(
                                                'navigation.cart.default.icon',
                                            )}
                                        />
                                    </Link>
                                )}
                            </div>
                        </div>
                        <NavLinks
                            isAuthenticated={isAuthenticated}
                            onAnyClick={this.tryCloseMobileNav}
                            mobileExpanded={mobileExpanded}
                            isCartPresent={isCartPresent}
                            signOut={signOut}
                        />
                        <Hidden when={!isAuthenticated}>
                            <>
                                <AuthenticatedLinks
                                    account={account}
                                    mobileExpanded={mobileExpanded}
                                    onAnyClick={this.tryCloseMobileNav}
                                    signOut={signOut}
                                    isCartPresent={isCartPresent}
                                />
                            </>
                        </Hidden>
                    </nav>
                </div>
            </header>
        );
    }

    private readonly onHamburgerClick = () => {
        this.setState({ mobileExpanded: !this.state.mobileExpanded });
    };

    private readonly tryCloseMobileNav = () => {
        setTimeout(
            () =>
                this.setState({
                    mobileExpanded: false,
                }),
            250, // arbitrary
        );
    };
    private readonly tealiumEventLogo = () => {
        const page = window.location.pathname.replace('/', '');
        TealiumDispatcher.dispatchEvent(
            TealiumFlowName.NONE,
            page,
            TealiumPagevents.TOP_LOGO,
            TealiumPageLinkLocations.TOP_NAV,
            TealiumLinkEvents.ICON,
            this.props.isAuthenticated
                ? TealiumLoginState.LOGGED_IN
                : TealiumLoginState.LOGGED_OUT,
            this.props.isAuthenticated ? this.props.account.accountid : '',
        );
        TealiumDispatcher.dispatchView(
            TealiumFlowName.NONE,
            TealiumPageType.HOME,
            this.props.isAuthenticated
                ? TealiumLoginState.LOGGED_IN
                : TealiumLoginState.LOGGED_OUT,
            this.props.isAuthenticated ? this.props.account.accountid : '',
        );
    };
}

export { Navigation };
