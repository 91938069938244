import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { ActionType, IClientTokenAction } from './client-token.actions';

interface IClientTokenState {
    readonly clientToken: string;
    readonly error: string;
    readonly fetchStatus: FetchStatus;
}

const DEFAULT_STATE: IClientTokenState = {
    clientToken: '',
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
};

const {
    GET_CLIENT_TOKEN,
    LOAD_CLIENT_TOKEN,
    REPORT_CLIENT_TOKEN_ERROR,
} = ActionType;

const clientTokenReducer = (
    state: IClientTokenState = DEFAULT_STATE,
    action: IClientTokenAction | IDefaultAction,
): IClientTokenState => {
    switch (action.type) {
        case GET_CLIENT_TOKEN:
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        case LOAD_CLIENT_TOKEN:
            return {
                ...state,
                clientToken: action.payload,
                fetchStatus: FetchStatus.SUCCESS,
            };
        case REPORT_CLIENT_TOKEN_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        default:
            return state;
    }
};

export { IClientTokenState, DEFAULT_STATE, clientTokenReducer };
