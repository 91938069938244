import * as React from 'react';

import { CreateAccountFormContainer as CreateAccountForm } from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { IAnalyticsProps, IDeviceTypeProps } from 'models';

enum Status {
    CREATING_ACCOUNT,
    CREATE_ACCOUNT_SUCCESS,
    PICKING_A_PHONE,
}

interface Props extends IDeviceTypeProps, IAnalyticsProps {
    readonly authStatus: FetchStatus;
    readonly currentRoute: string | null;
    readonly compatibility?: boolean;
    redirectTo(url: string): void;
}

interface State {
    readonly status: Status;
}

class CreateAccount extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            status:
                props.authStatus === FetchStatus.SUCCESS
                    ? Status.PICKING_A_PHONE
                    : Status.CREATING_ACCOUNT,
        };
    }

    componentWillReceiveProps({ authStatus }: Props) {
        if (
            authStatus !== this.props.authStatus &&
            authStatus === FetchStatus.SUCCESS
        ) {
            this.setState(
                { status: Status.CREATE_ACCOUNT_SUCCESS },
                this.queueTransition,
            );
        }
    }

    render() {
        return (
            <>
                <CreateAccountForm compatibility={this.props.compatibility} />
            </>
        );
    }

    deleteLocalstorageForGroups() {
        if (sessionStorage.getItem('GROUP_NAME')) {
            sessionStorage.removeItem('GROUP_NAME');
        }
    }

    private readonly queueTransition = () => {
        const {
            props: { redirectTo },
        } = this;
        if (!this.props.compatibility) {
            this.deleteLocalstorageForGroups();
            setTimeout(() => redirectTo(__('routes.phones')), 1000);
        }
    };
}

export { CreateAccount };
