import { IConfigurationBasePaths, IMdnResponse, INpaNxxResponse } from 'models';
import { ThinAirService } from './thin-air.service';

class InventoryService extends ThinAirService {
    static async fetchNpaNxx(
        basePaths: IConfigurationBasePaths,
        zipCode: string,
    ): Promise<INpaNxxResponse> {
        return await InventoryService.fetchJson<INpaNxxResponse>(
            `${InventoryService.BASE_URL}${
                basePaths.inventory
            }/${zipCode}/NpaNxx`,
            {
                headers: InventoryService.getDefaultHeaders(),
                method: 'GET',
            },
        );
    }

    static async fetchMdn(
        basePaths: IConfigurationBasePaths,
        npaNxx: string,
    ): Promise<IMdnResponse> {
        return await InventoryService.fetchJson<IMdnResponse>(
            `${InventoryService.BASE_URL}${basePaths.inventory}/${npaNxx}/Gmdn`,
            {
                headers: InventoryService.getDefaultHeaders(),
                method: 'GET',
            },
        );
    }
}

export { InventoryService };
