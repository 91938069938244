import { Home } from 'components/home';
import { Account, IApplicationState } from 'models';
import { connect } from 'react-redux';
import { showModal } from 'state/view/view.actions';

const mapStateToProps = ({ account }: IApplicationState) => ({
    account: new Account(account),
    orders: account.orders,
});

const mapDispatchToProps = dispatch => ({
    showModal: p => dispatch(showModal(p)),
});

const HomeContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Home);

export { HomeContainer };
