import * as React from 'react';

import { AppContainer as App } from 'containers';
import { History } from 'history';
import { Provider } from 'react-redux';
import { ConnectedRouter as Router } from 'react-router-redux';
import { Store } from 'redux';

require('./root.less'); // global styles

class Root extends React.Component<IProps, {}> {
    render(): JSX.Element {
        return (
            <Provider store={this.props.store}>
                <Router history={this.props.history}>
                    <App />
                </Router>
            </Provider>
        );
    }
}

/**
 * Properties available on the <code>props</code> property.
 */
interface IProps {
    readonly store: Store<any>;
    readonly history: History;
}

export { Root };
