import { Privacy } from 'components/account/privacy';
import { withAnalytics } from 'components/common/analytics';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    resetPasswordForEmail,
    setPrivacyOptInOut,
} from 'state/account/account.actions';
import { showModal } from 'state/view/view.actions';
import { CPNIModalContainer } from '.';

const mapStateToProps = ({ account }: IApplicationState) => ({
    account,
    fetchStatus: account.privacyOptInOutStatus,
    resetPasswordError: account.resetPasswordError,
    resetPasswordFetchStatus: account.resetPasswordFetchStatus,
    resetPasswordSuccess: account.resetPasswordSuccess,
});

const mapDispatchToProps = dispatch => ({
    showCPNI: () =>
        dispatch(
            showModal({
                additionalProps: {},
                component: CPNIModalContainer as any,
            }),
        ),
    updateStatus: (status: boolean) => dispatch(setPrivacyOptInOut(status)),
    resetPassword: email => dispatch(resetPasswordForEmail(email)),
});

const enhance = compose(
    withAnalytics,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);

const PrivacyContainer = enhance(Privacy);

export { PrivacyContainer };
