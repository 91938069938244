import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import {
    ActionType as CompatibilityActionType,
    IDeviceCompatibilityAction,
} from 'state/device/compatibility/compatibility.actions';
import { ActionType, IRegistrationAction } from './registration.actions';
import {
    DEFAULT_VALIDATE_SHORT_CODE_RESPONSE,
    IValidateShortCodeResponse,
} from 'models/referral/referral.model';

interface IRegistrationState {
    readonly fetchStatus: FetchStatus;
    readonly error: string;
    // Info potentially pulled from Facebook, IMEI check, or route
    readonly prefilledInfo: {
        readonly email: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly imei: string;
        readonly inviteCode: string;
    };

    readonly validateShortCodeError: string;
    readonly validateShortCodeFetchStatus: FetchStatus;
    readonly validateShortCodeResponse: IValidateShortCodeResponse;
}

const DEFAULT_PREFILLED_INFO = {
    email: '',
    firstName: '',
    imei: '',
    inviteCode: '',
    lastName: '',
};

const DEFAULT_STATE: IRegistrationState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
    prefilledInfo: DEFAULT_PREFILLED_INFO,
    validateShortCodeError: '',
    validateShortCodeFetchStatus: FetchStatus.NOT_FETCHED,
    validateShortCodeResponse: DEFAULT_VALIDATE_SHORT_CODE_RESPONSE,
};

const {
    REGISTER_WITH_EMAIL_ADDRESS,
    REPORT_REGISTRATION_ERROR,
    REPORT_REGISTRATION_SUCCESS,
    VALIDATE_SHORT_CODE_BEFORE_SIGNIN,
    REPORT_VALIDATE_SHORT_BEFORE_SIGNIN_CODE_SUCCESS,
    REPORT_VALIDATE_SHORT_BEFORE_SIGNIN_CODE_FAILURE,
    FINISH_REGISTRATION_WITH_SOCIAL_INFO,
    SET_INVITE_CODE,
} = ActionType;

const { REPORT_CHECK_DEVICE_COMPATIBILITY_SUCCESS } = CompatibilityActionType;

const registrationReducer = (
    state: IRegistrationState = DEFAULT_STATE,
    action:
        | IRegistrationAction
        | IDeviceCompatibilityAction
        | { readonly type: '' }
        | IDefaultAction,
): IRegistrationState => {
    switch (action.type) {
        case REGISTER_WITH_EMAIL_ADDRESS:
            return {
                ...state,
                ...{ fetchStatus: FetchStatus.FETCHING, error: '' },
            };
        case REPORT_REGISTRATION_SUCCESS:
            return { ...state, ...{ fetchStatus: FetchStatus.SUCCESS } };
        case REPORT_REGISTRATION_ERROR:
            return {
                ...state,
                ...{ fetchStatus: FetchStatus.ERROR, error: action.payload },
            };
        case VALIDATE_SHORT_CODE_BEFORE_SIGNIN:
            return {
                ...state,
                ...{
                    shortCode: action.payload,
                    validateShortCodeFetchStatus: FetchStatus.FETCHING,
                },
            };
        case REPORT_VALIDATE_SHORT_BEFORE_SIGNIN_CODE_SUCCESS:
            return {
                ...state,
                ...{
                    validateShortCodeResponse: action.payload,
                    validateShortCodeFetchStatus: FetchStatus.SUCCESS,
                },
            };
        case REPORT_VALIDATE_SHORT_BEFORE_SIGNIN_CODE_FAILURE:
            return {
                ...state,
                ...{
                    validateShortCodeError: action.payload,
                    validateShortCodeFetchStatus: FetchStatus.ERROR,
                },
            };
        case FINISH_REGISTRATION_WITH_SOCIAL_INFO:
            return {
                ...state,
                ...{
                    prefilledInfo: {
                        ...state.prefilledInfo,
                        email:
                            action.payload.email ||
                            DEFAULT_PREFILLED_INFO.email,
                        firstName:
                            action.payload.firstName ||
                            DEFAULT_PREFILLED_INFO.firstName,
                        lastName:
                            action.payload.lastName ||
                            DEFAULT_PREFILLED_INFO.lastName,
                    },
                },
            };
        case REPORT_CHECK_DEVICE_COMPATIBILITY_SUCCESS:
            return {
                ...state,
                ...{
                    prefilledInfo: {
                        ...state.prefilledInfo,
                        imei:
                            action.payload.deviceId ||
                            DEFAULT_PREFILLED_INFO.imei,
                    },
                },
            };
        case SET_INVITE_CODE:
            return {
                ...state,
                prefilledInfo: {
                    ...state.prefilledInfo,
                    inviteCode: action.payload,
                },
            };
        default:
            return state;
    }
};

export { DEFAULT_STATE, IRegistrationState, registrationReducer };
