import { FetchStatus } from 'lib/br-redux';
import { Reducer } from 'redux';
import { ActionType, UserValidationAction } from './user-validation.actions';

type UserValidationState = FetchStatus;
const DEFAULT_STATE = FetchStatus.NOT_FETCHED;

const userValidationReducer: Reducer<UserValidationState> = (
    state = DEFAULT_STATE,
    action: UserValidationAction,
) => {
    switch (action.type) {
        case ActionType.VALIDATE:
            return FetchStatus.FETCHING;
        case ActionType.REPORT_VALIDATION_SUCCESS:
            return FetchStatus.SUCCESS;
        case ActionType.REPORT_VALIDATION_ERROR:
            return FetchStatus.ERROR;
        default:
            return state;
    }
};

export { UserValidationState, DEFAULT_STATE, userValidationReducer };
