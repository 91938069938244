import * as classNames from 'classnames';

const aggregateClasses = (modifiers, prefix?): string => {
    const prepend = prefix ? `${prefix}-` : '';
    const classes = Object.keys(modifiers)
        .filter(k => modifiers[k])
        .map(m => `${prepend}${m}`);
    return classNames(prefix, classes);
};

export default aggregateClasses;
