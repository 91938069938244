import { NewNumber } from 'components/sign-up/components/phone-number/new-number/new-number';
import { FetchStatus } from 'lib/br-redux';
import { IApplicationState } from 'models';
import { ProductType } from 'models/products';
import { ProductCollection } from 'models/products/products.model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { addItemToCart } from 'state/purchase/cart/cart.actions';
import { fetchMdn } from 'state/purchase/mdn/mdn.actions';
import { clearNpaNxx } from 'state/purchase/npanxx/npanxx.actions';

const mapStateToProps = ({ products, purchase }: IApplicationState) => ({
    cart: purchase.cart.result,
    displayMdn: purchase.mdn.fetchStatus === FetchStatus.SUCCESS,
    displayNpaNpxList: purchase.npanxx.fetchStatus === FetchStatus.SUCCESS,
    mdn: purchase.mdn.value,
    pendingItem: purchase.cart.pendingItems.find(
        p => p.productType === ProductType.SIM,
    ),
    sim: new ProductCollection(products.results).sim,
});

const mapDispatchToProps = dispatch => ({
    addItemToCart: item => dispatch(addItemToCart(item)),
    clearNpaNxx: item => dispatch(clearNpaNxx()),
    fetchMdn: (npanxx: string) => dispatch(fetchMdn(npanxx)),
});

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const NewNumberContainer = enhance(NewNumber);

export { NewNumberContainer };
