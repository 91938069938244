import * as React from 'react';
import * as CSSModules from 'react-css-modules';

interface ChecklistItem {
    readonly label: string;
    readonly complete?: boolean;
}
interface Props {
    readonly items: ReadonlyArray<ChecklistItem>;
}

const itemToLi = ({ label, complete }: ChecklistItem, idx: number) => (
    <li key={`item_${idx}`} styleName={complete ? 'checked' : 'unchecked'}>
        {label}
    </li>
);

const ChecklistComp = ({ items }: Props) => (
    <ul styleName="checklist">{items.map(itemToLi)}</ul>
);

const Checklist = CSSModules(require('./checklist.less'))(ChecklistComp);

export { Checklist };
