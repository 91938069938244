import { TealiumFlowName, TealiumPageType } from 'models/tealium';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';

const styles = require('./promo.less');

@CSSModules(styles)
class Promo extends React.Component<{}> {
    componentDidMount() {
        TealiumDispatcher.dispatchView(
            TealiumFlowName.PROMO_FLOW_NAME,
            TealiumPageType.PROMO_PAGE_NAME,
        );
    }
    render() {
        return (
            <div>
                <iframe
                    src={__('routes.promos.get200.aem')}
                    styleName="iframe"
                />
            </div>
        );
    }
}

export { Promo };
