import { ButtonContainer as Button } from 'containers';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
    readonly linkPath: string;
    readonly buttonText: string;
}

const InPageNavButton = (props: IProps) => (
    <div className="in-page-nav-button float-left">
        <Link to={props.linkPath}>
            <Button default label={props.buttonText} />
        </Link>
    </div>
);

export default InPageNavButton;
