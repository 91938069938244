import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Accordion } from 'components/common/accordion';
import { Close } from 'components/common/close';
import { Divider } from 'components/common/divider';
import { TextInfo } from 'components/common/text-info';
import { BodyCopy } from 'components/common/typography';
import {
    Account,
    Cart,
    HandsetCartItem,
    IAnalyticsProps,
    IDeviceTypeProps,
    ProductType,
    SIMCartItem,
} from 'models';
import {
    disableBodyScroll,
    enableBodyScroll,
    formatMoney,
    normalizeNumber,
} from 'utils';
import { Hidden } from '../common/hidden';

const styles = require('./cart-sidebar.less');

interface Props extends IDeviceTypeProps, IAnalyticsProps {
    readonly account: Account;
    readonly cart: Cart | null;
    readonly open: boolean;
    toggleSidebarCart(): void;
    deleteDeviceFromCartConfirm(): void;
}

@CSSModules(styles, { allowMultiple: true })
class CartSidebar extends React.Component<Props> {
    // tslint:disable:readonly-keyword
    targetElement;
    componentDidUpdate() {
        const {
            props: { isMobile, open },
        } = this;
        if (open) {
            if (isMobile) {
                disableBodyScroll(this.targetElement);
            } else {
                enableBodyScroll(this.targetElement);
            }
        }
    }
    componentDidMount() {
        this.targetElement = document.querySelector('#cart-sidebar');
    }
    componentWillUnmount() {
        enableBodyScroll(this.targetElement);
    }
    render() {
        const {
            closeCart,
            deleteCartItem,
            getDeviceLine,
            hasPricedDevice,
            props: { account, cart, open },
        } = this;
        const device = cart
            ? (cart.itemForProductType(ProductType.HANDSET) as HandsetCartItem)
            : null;
        const sim = cart
            ? (cart.itemForProductType(ProductType.SIM) as SIMCartItem)
            : null;
        return (
            <div styleName={classNames('cart', { open })} id="cart-sidebar">
                <Close onClick={closeCart} left />
                <Accordion
                    key="device"
                    title="Device"
                    active={true}
                    indicator={
                        device || sim ? (
                            <img
                                styleName="edit-icon"
                                src={__('cart.edit.icon')}
                                onClick={deleteCartItem}
                            />
                        ) : null
                    }
                    hideLeftIcon
                    contentPadding30
                    noPadding
                    subhead
                >
                    <Hidden when={account.customerState === 'Purchased'}>
                        {device && getDeviceLine(device)}
                        {sim && (
                            <div styleName="line-item">
                                <BodyCopy>{`${__('cart.visible-sim')}${
                                    sim.mdn
                                        ? ` ${normalizeNumber(sim.mdn)}`
                                        : ''
                                }`}</BodyCopy>
                                <BodyCopy styleName="value">
                                    {__('cart.free')}
                                </BodyCopy>
                            </div>
                        )}
                        <div styleName="line-item">
                            <BodyCopy noMargin>
                                {__('cart.next-day-shipping')}
                            </BodyCopy>
                            <BodyCopy noMargin styleName="value">
                                {__('cart.free')}
                            </BodyCopy>
                        </div>
                        {device && hasPricedDevice && (
                            <>
                                <Divider medium />
                                <TextInfo label={__('cart.return-policy')}>
                                    {__('cart.return-policy.more')}
                                </TextInfo>
                                <Divider medium />
                                <BodyCopy noMargin>
                                    {__('cart.affirm')}
                                </BodyCopy>
                            </>
                        )}
                    </Hidden>
                    <Hidden until={account.customerState === 'Purchased'}>
                        <BodyCopy noMargin>Your items are on the way.</BodyCopy>
                    </Hidden>
                </Accordion>
                <Accordion
                    key="services"
                    title="Services"
                    active={true}
                    hideIndicator
                    hideLeftIcon
                    contentPadding30
                    noPadding
                    subhead
                >
                    <div styleName="line-item">
                        <BodyCopy large>{__('cart.plan.title')}</BodyCopy>
                        <BodyCopy styleName="value marginLeft25">
                            {__('cart.plan.price')}
                        </BodyCopy>
                    </div>
                    <div styleName="line-item">
                        <BodyCopy large>{__('cart.plan.total')}</BodyCopy>
                        <BodyCopy styleName="value marginLeft25">
                            {__('cart.plan.price')}
                        </BodyCopy>
                    </div>
                    <BodyCopy>{__('cart.plan.body')}</BodyCopy>
                </Accordion>
            </div>
        );
    }
    private get hasPricedDevice(): boolean {
        const {
            props: { cart },
        } = this;
        const device = cart
            ? (cart.itemForProductType(ProductType.HANDSET) as HandsetCartItem)
            : null;
        return !!device && !device.isByod();
    }

    readonly getDeviceLine = device => {
        const { hasPricedDevice } = this;
        return (
            <div styleName="device-line">
                <img styleName="device-icon" src={__('cart.byod.icon')} />
                <div styleName="device-description">
                    <BodyCopy large noMargin>
                        {device && device.name}
                    </BodyCopy>
                    <BodyCopy noMargin>
                        {device &&
                            device.isByod() &&
                            __('cart.device.description.byod')}
                    </BodyCopy>
                </div>
                <BodyCopy noMargin right styleName="device-price">
                    {hasPricedDevice && formatMoney(device.oneTimeTotal)}
                </BodyCopy>
            </div>
        );
    };

    private readonly deleteCartItem = () => {
        const { deleteDeviceFromCartConfirm } = this.props;
        deleteDeviceFromCartConfirm();
    };

    private readonly closeCart = () => {
        const { toggleSidebarCart } = this.props;
        toggleSidebarCart();
    };
}

export { CartSidebar };
