import { all, call, put, take, takeLatest } from 'redux-saga/effects';
import { UserValidationService } from 'services';
import { AuthenticationActions } from 'state/authentication';
import {
    ActionType,
    reportUserValidationError,
    reportUserValidationSuccess,
    ValidateUserAction,
} from './user-validation.actions';

const { ActionType: AuthActionType } = AuthenticationActions;

function* validateUser(action: ValidateUserAction) {
    try {
        yield take(AuthActionType.AUTH_INIT); // wait for auth module to initialize
        yield call(
            UserValidationService.validate,
            encodeURIComponent(action.payload),
        );
        yield put(reportUserValidationSuccess());
    } catch (error) {
        yield put(reportUserValidationError(error));
    }
}

function* userValidationSaga() {
    yield all([takeLatest(ActionType.VALIDATE, validateUser)]);
}

export { userValidationSaga };
