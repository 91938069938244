import { QuestionAnswer } from './qa.model';

interface NonNormalizedTopic {
    readonly name: string;
    readonly topic: ReadonlyArray<QuestionAnswer>;
}

class Topic {
    // tslint:disable-next-line:readonly-keyword
    filteredQuestionsAndAnswers: ReadonlyArray<QuestionAnswer>;
    constructor(
        public name: string,
        public questionsAndAnswers: ReadonlyArray<QuestionAnswer>,
    ) {}
    static normalize({ name, topic }: NonNormalizedTopic): Topic {
        return new Topic(name || '', topic || []);
    }
}

export { NonNormalizedTopic, Topic };
