import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { splitProps } from 'utils';

const styles = require('./headline.less');

enum Modifiers {
    error = 'error',
    invert = 'invert',
    center = 'center',
    left = 'left',
    noMargin = 'noMargin',
    noMarginMobile = 'noMarginMobile',
    noMarginTop = 'noMarginTop',
    noMarginTopMobile = 'noMarginTopMobile',
    noMarginBottom = 'noMarginBottom',
    noMarginBottomMobile = 'noMarginBottomMobile',
    margin30Top = 'margin30Top',
    margin30Bottom = 'margin30Bottom',
    margin20BottomMobile = 'margin20BottomMobile',
    autoAlign = 'autoAlign',
    tableCaption = 'tableCaption',
    large = 'large',
    medium = 'medium',
    narrow = 'narrow',
    small = 'small',
    dark = 'dark',
    light = 'light',
    fontCircular = 'fontCircular',
    customSpacing = 'customSpacing',
    mobileMarginBottom35 = 'mobileMarginBottom35',
    margin16Bottom = 'margin16Bottom',
}

interface HeadlineModifiers {
    readonly error?: boolean;
    readonly two?: boolean;
    readonly three?: boolean;
    readonly invert?: boolean;
    readonly center?: boolean;
    readonly left?: boolean;
    readonly noMargin?: boolean;
    readonly noMarginMobile?: boolean;
    readonly noMarginTop?: boolean;
    readonly noMarginTopMobile?: boolean;
    readonly noMarginBottom?: boolean;
    readonly noMarginBottomMobile?: boolean;
    readonly margin30Top?: boolean;
    readonly margin30Bottom?: boolean;
    readonly autoAlign?: boolean;
    readonly tableCaption?: boolean;
    readonly large?: boolean;
    readonly medium?: boolean;
    readonly narrow?: boolean;
    readonly small?: boolean;
    readonly dark?: boolean;
    readonly light?: boolean;
    readonly fontCircular?: boolean;
    readonly customSpacing?: boolean;
    readonly mobileMarginBottom35?: boolean;
    readonly margin20BottomMobile?: boolean;
    readonly margin16Bottom?: boolean;
}

type BaseHeadline = React.HTMLProps<HTMLElement> & HeadlineModifiers;

const HeadlineComponent = ({
    two,
    three,
    children,
    ...props
}: BaseHeadline): JSX.Element => {
    const [modifiers, other] = splitProps(props, Modifiers);
    const styleNames = classNames('headline', modifiers);
    const el = two ? 'h2' : three ? 'h3' : 'h1';
    return React.createElement(el, {
        ...other,
        children,
        styleName: styleNames,
    });
};

const Headline = CSSModules(HeadlineComponent, styles, { allowMultiple: true });

export { Headline };
