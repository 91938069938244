import { ProductType } from 'models/products';
import { IAppliedPromotion, IPromoContent } from './promo-contents.interface';

enum MDNType {
    NEW = 'NEW',
    PORT_IN = 'PI',
    UNKNOWN = '',
}

enum PurchaseType {
    DEVICE_PURCHASE = 'Device Purchase',
    BYOD = 'BYOD',
    STATUS_DRAFT = 'Draft',
    STATUS_PROCESSED = 'Processed',
}

interface AOResponse {
    readonly Account: AOAccountResponse;
}

interface AOAccountResponse {
    readonly Phone: string;
    readonly MDN: string;
    readonly Status: string;
    readonly 'Swrve ID': string;
    readonly 'Service Sharing Type': string;
    readonly GUID: string;
    readonly 'Matrixx ID': string;
    readonly Name: string;
    readonly CustomerNumber: string;
    readonly Orders?: ReadonlyArray<AOAccountOrderResponse>;
    readonly Order?: ReadonlyArray<AOAccountOrderResponse>;
    readonly GrpMembershipDetail: ReadonlyArray<GroupMembershipDetailResponse>;
    readonly PROMO_CONTENTS?: ReadonlyArray<IPromoContent>;
    readonly appliedPromotions?: ReadonlyArray<IAppliedPromotion>;
}

interface GroupMembershipDetailResponse {
    readonly GroupMembershipState: string;
    readonly GroupName: string;
}

interface OrderDetailResponse {
    readonly Order?: AOAccountOrderResponse;
}

interface AOAccountOrderResponse {
    // TODO(dan) remove relevant optionals when api coalesces
    readonly 'Effective Recurring Total': number;
    readonly CreatedDate?: string;
    readonly DeliveredByDate?: string;
    readonly Items?: ReadonlyArray<OrderItemResponse>;
    readonly Item?: ReadonlyArray<OrderItemResponse>;
    readonly Model?: string;
    readonly Make?: string;
    readonly OrderNumber?: string;
    readonly OrderType?: string;
    readonly portRemarks?: string;
    readonly portStatus?: string;
    readonly POBO?: string;
    readonly PurchaseType?: string;
    readonly ReasonCode?: string;
    readonly 'SAP Order Number'?: string;
    readonly SalesforceId: string;
    readonly ShipByDate: string;
    readonly ShipmentTrackingURL?: string;
    readonly Status?: string;
    readonly StatusCode?: string;
    readonly Tax?: number;
    readonly EffectiveDate?: string;
    readonly OrderDate?: string;
    readonly OrderTotal?: string;
    readonly PaymentMethod?: string;
    readonly Type?: string;
    readonly TaxAmount?: string;
    readonly AddressLine2: string;
    readonly AddressLine1: string;
    readonly ProductType: string;
    readonly PROMO_CONTENTS: ReadonlyArray<IPromoContent>;
}

interface BaseItemResponse {
    readonly Name: string;
    readonly CreateDate: string;
    readonly Family: ProductType;
    readonly SalesforceId: string;
    readonly Refunded: boolean;
    readonly SKU: string;
    readonly ProductFamily: string;
    readonly ProductModel: string;
    readonly ProductMake: string;
    readonly ValuationType: string;
    readonly Quantity: string;
    readonly SerialNumber: string;
    readonly Tax: string;
    readonly OneTimeTotal: number;
    readonly ProductName: string;
    readonly LastFour?: string;
    readonly ProductMethod?: string;
    readonly colorCode?: string;
    readonly ProductSize?: string;
    readonly Status: string;
    readonly PurchaseType: string;
    readonly taxList?: ReadonlyArray<TAXList>;
    readonly desc?: string;
    readonly value?: string;
    readonly image?: string;
}

interface TAXList {
    readonly desc: string;
    readonly value: string;
}

interface SIMItemResponse extends BaseItemResponse {
    // TODO(dan) remove relevant optionals when api coalesces
    readonly IMSI?: string;
    readonly MDNType?: MDNType;
    readonly MDN: string;
    readonly 'One Time Total'?: number;
    readonly PortedFromPIN: string;
    readonly PortedFrom: string;
    readonly PortedFromAcct: string;
    readonly userdevice?: string;
}

interface PlanItemResponse extends BaseItemResponse {}

interface HandsetItemResponse extends BaseItemResponse {
    readonly ImageURL?: any;
    readonly Color?: string;
    readonly Storage?: string;
}

type OrderItemResponse =
    | SIMItemResponse
    | PlanItemResponse
    | HandsetItemResponse;

export {
    AOResponse,
    AOAccountResponse,
    GroupMembershipDetailResponse,
    AOAccountOrderResponse,
    BaseItemResponse,
    OrderItemResponse,
    HandsetItemResponse,
    SIMItemResponse,
    PlanItemResponse,
    OrderDetailResponse,
    MDNType,
    PurchaseType,
    TAXList,
};
