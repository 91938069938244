import { createAction, IAction } from 'lib/br-redux';

enum ActionType {
    VALIDATE = 'USER_VALIDATION/VALIDATE',
    REPORT_VALIDATION_SUCCESS = 'USER_VALIDATION/REPORT_VALIDATION_SUCCESS',
    REPORT_VALIDATION_ERROR = 'USER_VALIDATION/REPORT_VALIDATION_ERROR',
}

type ValidateUserAction = IAction<ActionType.VALIDATE, string>;
type ReportUserValidationSuccessAction = IAction<
    ActionType.REPORT_VALIDATION_ERROR,
    Error
>;
type ReportUserValidationErrorAction = IAction<
    ActionType.REPORT_VALIDATION_SUCCESS
>;

type UserValidationAction =
    | ValidateUserAction
    | ReportUserValidationErrorAction
    | ReportUserValidationSuccessAction;

const validateUser = createAction(
    ActionType.VALIDATE,
    (token: string) => token,
);

const reportUserValidationSuccess = createAction(
    ActionType.REPORT_VALIDATION_SUCCESS,
);

const reportUserValidationError = createAction(
    ActionType.REPORT_VALIDATION_ERROR,
    (error: Error) => error,
);

export {
    ActionType,
    ValidateUserAction,
    ReportUserValidationErrorAction,
    ReportUserValidationSuccessAction,
    UserValidationAction,
    validateUser,
    reportUserValidationError,
    reportUserValidationSuccess,
};
