import * as React from 'react';

import { BodyCopy, InfoLink } from 'components/common/typography';
import {
    ButtonContainer as Button,
    InputContainer as Input,
    ModalWrapperContainer as ModalWrapper,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { IInputValidationProps, IModalProps } from 'models';

interface Props extends IModalProps, IInputValidationProps {
    readonly error: string;
    readonly status: FetchStatus;
    finishReauthenticationWithPassword(pw: string): void;
}

class ReauthenticationModal extends React.Component<Props> {
    componentWillReceiveProps(newProps: Props): void {
        if (
            newProps.status !== this.props.status &&
            newProps.status === FetchStatus.SUCCESS
        ) {
            const {
                props: { bindHandleClickWith, onConfirm },
            } = this;
            bindHandleClickWith(onConfirm)();
        }
    }

    render() {
        const {
            isFetching,
            onSubmit,
            props: {
                data: { password },
                error,
                handleOnChange,
            },
        } = this;
        return (
            <ModalWrapper title="Enter password" closable>
                <form onSubmit={onSubmit}>
                    <BodyCopy thin center>
                        {__('reset-changeemail.body')}
                    </BodyCopy>
                    <Input
                        noMargin
                        id="password"
                        type="password"
                        label="password"
                        onChange={handleOnChange}
                        value={password}
                        error={error}
                    />
                    <BodyCopy center margin30Top>
                        <Button
                            type="submit"
                            center
                            loading={isFetching}
                            label={__('submit')}
                        />
                    </BodyCopy>
                    <BodyCopy center>
                        <InfoLink
                            to={`${__('routes.forgot-password')}`}
                            target="_blank"
                        >
                            {__('forgot-password.headline')}
                        </InfoLink>
                    </BodyCopy>
                </form>
            </ModalWrapper>
        );
    }

    private get isFetching(): boolean {
        const {
            props: { status },
        } = this;
        return status === FetchStatus.FETCHING;
    }

    private readonly onSubmit = (
        event: React.FormEvent<HTMLFormElement>,
    ): void => {
        event.preventDefault();
        const {
            props: {
                data: { password },
            },
        } = this;
        this.props.finishReauthenticationWithPassword(password);
    };
}

export { ReauthenticationModal };
