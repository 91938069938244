import { NoticePresenter } from 'components/common/notice';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';

const mapStateToProps = ({ view }: IApplicationState) => ({
    noticeQueue: view.noticeQueue,
});

const enhance = compose(connect(mapStateToProps));
const NoticePresenterContainer = enhance(NoticePresenter);

export { NoticePresenterContainer };
