import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./help.less');

@CSSModules(styles)
class HowToUnlockaPhone extends React.Component<{}> {
    render() {
        return (
            <>{(window.location.href = __('routes.how-to-unlock-a-phone'))}</>
        );
    }
}

export { HowToUnlockaPhone };
