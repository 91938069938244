import * as moment from 'moment';

import { AlertModalContainer as AlertModal } from 'containers';
import { IApplicationState, IMdnResponse, IProductItemModel } from 'models';
import {
    AOAccountOrderResponse,
    AOResponse,
    CustomerState,
    MDNType,
    OrderDetailResponse,
} from 'models/account';
import {
    Cart,
    HandsetCartItem,
    ICheckoutPayload,
    KOUNT_ENABLED,
} from 'models/cart/cart.model';
import { INpaNxxResponse } from 'models/inventory';
import { ProductType } from 'models/products/products.model';
import { IReferralEvent } from 'models/referral/referral.model';
import { push } from 'react-router-redux';
import {
    all,
    call,
    put,
    select,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import {
    AccountService,
    CartService,
    InventoryService,
    ReferralService,
    ShopService,
    PaymentService,
} from 'services';
import { PortService } from 'services/port.service';
import { fetchOrdersSuccess } from 'state/account/account.actions';
import { fetchGroupMemberStatusSuccess } from 'state/account/groups/groups.action';
import { emailVerifyNotice, refreshAccount } from 'state/account/account.saga';
import { getToken } from 'state/firebase/firebase.saga';
import {
    CartActions,
    CheckoutActions,
    MdnActions,
    NpaNxxActions,
    PortActions,
} from 'state/purchase/purchase.actions';
import {
    postEventFailure,
    postEventSuccess,
} from 'state/referral/referral.actions';
import { eventError, eventLink, trackError } from 'state/tealium';
import {
    closeSidebarCart,
    dismissLoadingIndicator,
    dismissModal,
    modalLoading,
    requestLoadingIndicator,
    showNotice,
} from 'state/view/view.actions';
import { confirmWithModal } from 'state/view/view.saga';
import { smellyStoreSingleton } from 'utils/store';
import { isReferral } from 'utils/toggles';
import {
    deleteDeviceFromCart,
    deleteItemFromCart,
    fetchOrderDetailsSuccess,
    IFetchOrderDetailsAction,
    reportFetchOrdersSuccess,
} from './cart/cart.actions';
import { AutopayActions } from 'state/payment/payment.actions';
import { loadUserAccount } from 'state/account/account.actions';
const { loadAutopayStatus, reportAutopayStatusError } = AutopayActions;
import { clearMdn } from './mdn/mdn.actions';
import { portSaga } from './port/port.saga';

function* getCartId() {
    const basePaths = yield select(
        (state: IApplicationState) => state.configuration.basePaths,
    );
    const existingCartId = yield select(
        (state: IApplicationState) => state.purchase.cart.id,
    );
    if (existingCartId && existingCartId.trim()) {
        return existingCartId;
    }

    const firebaseToken = yield call(getToken);
    const newCartId: string | undefined = yield call(
        CartService.getCartId,
        basePaths,
        firebaseToken,
    );

    if (!newCartId || !newCartId.trim()) {
        yield put(eventError({ error_msg: __('purchase.get-cart-id-error') }));
        throw new Error(__('purchase.get-cart-id-error'));
    }

    yield put(CartActions.setCartId(newCartId));
    return newCartId;
}

function* addPlanToCartIfNecessary(cartId: string, cart: Cart) {
    const items: ReadonlyArray<IProductItemModel> = yield select(
        ({ products }: IApplicationState) => products.results,
    );
    const plan = items.find(i => i.product_type === ProductType.PLAN);
    if (!plan) {
        yield put(eventError({ error_msg: __('default_service_error') }));
        throw new Error(__('default_service_error'));
    }

    if (!cart.hasProductType(ProductType.PLAN)) {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);
        const item = {
            itemid: plan.itemid,
            quantity: 1,
        };
        yield call(CartService.addItem, basePaths, firebaseToken, cartId, item);
        yield put(CartActions.reportAddItemSuccess(ProductType.PLAN));
    }
}

function* checkCancelPortNumber(orders: AOAccountOrderResponse) {
    const { dispatch } = smellyStoreSingleton.store;
    const location: Location | undefined = yield select(
        ({ routing }: IApplicationState) => routing.location,
    );
    if (
        orders.OrderType &&
        orders.OrderType === 'In-Flight Cancel' &&
        orders.ReasonCode &&
        orders.ReasonCode === 'Customer Initiated'
    ) {
        if (location && location.pathname !== __('routes.sign-up')) {
            yield call(confirmWithModal, {
                additionalProps: {
                    body: __('purchase.cancel-port.body'),
                    buttonText: __('purchase.cancel-port.button-text'),
                    error: true,
                    onConfirm: () => dispatch(push(__('routes.sign-up'))),
                    title: __('purchase.cancel-port.title'),
                },
                component: AlertModal,
            });
        }
    }
}

function* removeSimIfNecessary(cart: Cart) {
    try {
        const custState = yield select(
            (state: IApplicationState) => state.account.customerState,
        );
        // Delete the sim from cart only if its a new MDN with a customer status of Registered and
        // items of type PI(Port in) should not delete the sim from cart
        if (
            custState &&
            custState === CustomerState.REGISTERED &&
            cart.hasProductType(ProductType.SIM)
        ) {
            const item = cart.itemForProductType(ProductType.SIM);
            if (item && item.mdnType === MDNType.NEW) {
                const mdnTimeout = yield select(
                    (state: IApplicationState) =>
                        state.configuration.mdnTimeout,
                );
                const createDate = item.createDate;
                const itemId = item.itemId;
                if (createDate && itemId) {
                    const withinMdnTimeout =
                        moment().diff(moment(createDate), 'second') <
                        mdnTimeout;
                    if (!withinMdnTimeout) {
                        const basePaths = yield select(
                            (state: IApplicationState) =>
                                state.configuration.basePaths,
                        );
                        const firebaseToken = yield call(getToken);
                        yield call(
                            CartService.deleteItem,
                            basePaths,
                            firebaseToken,
                            cart.id,
                            itemId,
                            true, // this is always part of a BYOD flow
                        );
                        yield put(
                            CartActions.reportDeleteItemSuccess(
                                ProductType.SIM,
                            ),
                        );
                        yield put(CartActions.reportDeleteSim());
                        return true;
                    }
                }
            }
        }
    } catch (err) {
        yield put(CartActions.reportFetchCartError((err as Error).message));
    }
    return false;
}
function* getAutopayStatus() {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const account = yield select(
            ({ account }: IApplicationState) => account,
        );
        const firebaseToken = yield call(getToken);
        const autopayStatus = yield call(
            PaymentService.isAutopayEnabled,
            basePaths,
            firebaseToken,
        );
        yield put(loadAutopayStatus(autopayStatus));
        yield put(loadUserAccount({ ...account, autopay: autopayStatus }));
    } catch (err) {
        yield put(reportAutopayStatusError((err as Error).message));
    }
}

function* fetchCartRequest() {
    const createUserKey = 'create-user-key';
    try {
        yield put(requestLoadingIndicator(createUserKey));
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);

        const location = yield select(
            (state: IApplicationState) => state.routing.location,
        ) || {};

        if (location.pathname === __('routes.account.overview')) {
            yield call(getAutopayStatus);
        }

        // Fetch Cart
        const cartResponse: AOResponse = yield call(
            AccountService.getCart,
            basePaths,
            firebaseToken,
        );
        if (cartResponse.Account.Orders && cartResponse.Account.Orders.length) {
            const orders: any = cartResponse.Account.Orders;
            const PROMO_CONTENTS: any = cartResponse.Account.PROMO_CONTENTS;
            const appliedPromotions: any =
                cartResponse.Account.appliedPromotions;
            const cart = new Cart(orders[0]);
            const payload = { orders, PROMO_CONTENTS, appliedPromotions };
            if (
                cartResponse.Account.GrpMembershipDetail &&
                cartResponse.Account.GrpMembershipDetail.length > 0
            ) {
                const groupMember = cartResponse.Account.GrpMembershipDetail[0];
                yield put(fetchGroupMemberStatusSuccess(groupMember));
            }
            yield put(CartActions.reportFetchCartSuccess(cart));
            yield put(fetchOrdersSuccess(payload));
            yield call(checkCancelPortNumber, orders);
            return cart;
        }

        throw new Error(__('purchase.fetch-cart.missing-order-error'));
    } catch (err) {
        yield put(CartActions.reportFetchCartError((err as Error).message));
    } finally {
        yield put(dismissLoadingIndicator(createUserKey));
    }
}

function* fetchCart() {
    const cart = yield call(fetchCartRequest);
    if (cart && cart.id) {
        const removedSim = yield call(removeSimIfNecessary, cart);
        if (removedSim) {
            return yield call(fetchCartRequest);
        }
        return cart;
    }
}

function* fetchOrders() {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);
        // Fetch Cart
        const cartResponse: AOResponse = yield call(
            AccountService.getOrderHistory,
            basePaths,
            firebaseToken,
        );

        yield call(ShopService.getProducts, basePaths, firebaseToken);
        if (cartResponse.Account.Order && cartResponse.Account.Order.length) {
            const orders: any = cartResponse.Account.Order;
            yield put(reportFetchOrdersSuccess(orders));
            return orders;
        }

        throw new Error(__('purchase.fetch-cart.missing-order-error'));
    } catch (err) {
        yield put(CartActions.reportFetchOrdersError((err as Error).message));
    }
}

function* fetchOrderDetail(action: IFetchOrderDetailsAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);

        // Fetch Cart
        const orderDetailResponse: OrderDetailResponse = yield call(
            AccountService.getOrderDetails,
            basePaths,
            firebaseToken,
            action.payload,
        );

        if (
            orderDetailResponse.Order &&
            orderDetailResponse.Order.Item &&
            orderDetailResponse.Order.Item.length
        ) {
            const order: any = orderDetailResponse;
            yield put(fetchOrderDetailsSuccess(order));
            return order;
        }
    } catch (err) {
        yield put(CartActions.reportFetchCartError((err as Error).message));
    }
}

function* showRemovedSim() {
    yield call(confirmWithModal, {
        additionalProps: {
            body: __('phone-number.released-modal.body'),
            buttonText: __('phone-number.released-modal.action'),
            error: true,
            title: __('phone-number.released-modal.title'),
        },
        component: AlertModal,
    });
}

function* addItemToCart(action: CartActions.IAddItemAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);
        const cartId = yield call(getCartId);
        const cart: Cart = yield call(fetchCart);
        // If that item is already in the cart, remove it.
        const existingItem = cart.itemForProductType(
            action.payload.productType,
        );
        if (existingItem) {
            yield put(deleteItemFromCart(existingItem));
        }

        // Add the item
        const { productType, ...rest } = action.payload;
        yield call(CartService.addItem, basePaths, firebaseToken, cartId, rest);

        // The add item response does not contain the full cart,
        // so now we do this agian...
        const updatedCart: Cart = yield call(fetchCart);

        // Report that everything went okay
        yield put(CartActions.reportAddItemSuccess(action.payload.productType));
        yield put(CartActions.reportFetchCartSuccess(updatedCart));

        // Add the service plan if it isn't already there
        yield call(addPlanToCartIfNecessary, cartId, updatedCart);
    } catch (err) {
        yield put(
            CartActions.reportAddItemError({
                error: (err as Error).message,
                productType: action.payload.productType,
            }),
        );
    }
}
function* deleteItemFromCartRequest(existingItem) {
    if (!existingItem) {
        return;
    }
    const { itemId, productType } = existingItem;
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);
        const cartId = yield call(getCartId);

        yield call(
            CartService.deleteItem,
            basePaths,
            firebaseToken,
            cartId,
            itemId,
            false, // the user can only get here from a Device Purchase flow
        );
        const updatedCart: Cart = yield call(fetchCart);

        yield put(CartActions.reportDeleteItemSuccess(productType));
        yield put(CartActions.reportFetchCartSuccess(updatedCart));
    } catch (err) {
        yield put(
            CartActions.reportDeleteItemError({
                error: (err as Error).message,
                productType,
            }),
        );
    }
}
function* deleteItemFromCartSaga(action) {
    yield call(deleteItemFromCartRequest, action.payload);
}
function* deleteDeviceFromCartConfirm() {
    const cart = yield select(
        (state: IApplicationState) => state.purchase.cart.result,
    );
    const existingHandset = cart.itemForProductType(
        ProductType.HANDSET,
    ) as HandsetCartItem;
    const isByod = existingHandset.isByod();
    const { dispatch } = smellyStoreSingleton.store;
    if (isByod) {
        yield call(confirmWithModal, {
            additionalProps: {
                analyticsTag: __('cart.delete-byod-page-metrics'),
                body: __('cart.modal.delete.body.byod'),
                buttonText: __('cart.modal.delete.browse-phones'),
                closable: true,
                onConfirm: () => {
                    dispatch(deleteDeviceFromCart('phone'));
                    dispatch(
                        eventLink({
                            event_category: 'link_click',
                            link_name: __('cart.delete-byod-metrics'),
                        }),
                    );
                    return false;
                },
                onConfirmSecondary: () => {
                    dispatch(deleteDeviceFromCart('byod'));
                    push(__('routes.sign-up'));
                },
                title: `${__('cart.modal.delete.title')} ${
                    existingHandset.name
                }?`,
            },
            component: AlertModal,
        });
    } else {
        yield call(confirmWithModal, {
            additionalProps: {
                analyticsTag: __('cart.delete-device-page-metrics'),
                body: __('cart.modal.delete.body.phones'),
                buttonText: __('cart.modal.delete.restart'),
                buttonTextSecondary: __('cart.modal.delete.browse-phones'),
                closable: true,
                onConfirm: () => {
                    dispatch(deleteDeviceFromCart('phone'));
                    dispatch(
                        eventLink({
                            event_category: 'link_click',
                            link_name: __('cart.delete-device-restart-metrics'),
                        }),
                    );
                    return false;
                },
                onConfirmSecondary: () => {
                    dispatch(modalLoading({ primary: false, loading: true }));
                    dispatch(
                        eventLink({
                            event_category: 'link_click',
                            link_name: __('cart.delete-new-device-metrics'),
                        }),
                    );
                    window.location.href = __('routes.phones');
                },
                title: `${__('cart.modal.delete.title')} ${
                    existingHandset.name
                }?`,
            },
            component: AlertModal,
        });
    }
}

function* deleteDeviceFromCartSaga(action) {
    const cart = yield select(
        (state: IApplicationState) => state.purchase.cart.result,
    );
    const existingHandset = cart.itemForProductType(
        ProductType.HANDSET,
    ) as HandsetCartItem;
    yield put(modalLoading({ primary: true, loading: true }));
    yield put(closeSidebarCart());
    yield call(deleteItemFromCartRequest, existingHandset);
    yield put(clearMdn());
    yield put(dismissModal());
    if (action.payload === 'byod') {
        yield put(push(__('routes.sign-up')));
    } else {
        window.location.href = __('routes.phones');
    }
}

function* checkoutReferralEvent(checkoutPayload: ICheckoutPayload) {
    const toggles = yield select(
        (state: IApplicationState) => state.configuration.toggles,
    );
    if (isReferral(toggles)) {
        try {
            const basePaths = yield select(
                (state: IApplicationState) => state.configuration.basePaths,
            );
            const eventPayload: IReferralEvent = {
                custom1: checkoutPayload.imei,
                email: checkoutPayload.email,
                short_code: checkoutPayload.shortCode,
                transaction_id: checkoutPayload.transactionId,
                uid: checkoutPayload.uid,
            };
            yield call(ReferralService.postEvent, basePaths, eventPayload);
            yield put(postEventSuccess());
        } catch (err) {
            yield put(postEventFailure((err as Error).message));
        }
    }
}

function* checkout(action: CheckoutActions.IPerformCheckoutAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);
        const cart = yield call(fetchCartRequest);
        const device =
            cart &&
            (cart.itemForProductType(ProductType.HANDSET) as HandsetCartItem);
        const userState = yield select(
            (state: IApplicationState) => state.account,
        );
        const referralState = yield select(
            (state: IApplicationState) => state.referral,
        );
        const referralValid =
            referralState &&
            referralState.validateShortCodeResponse &&
            referralState.validateShortCodeResponse.valid;
        const campaignId =
            referralValid &&
            referralState.validateShortCodeResponse.campaign_uid;
        const shortCode = referralValid && referralState.shortCode;
        const kountDevice: any = yield select(
            (state: IApplicationState) => state.payment.kountDevice.kountDevice,
        );
        const checkoutPayload: ICheckoutPayload = {
            campaignId,
            email: userState.email,
            imei: device && device.serialNumber,
            deviceData: KOUNT_ENABLED
                ? kountDevice!.device!.deviceData
                : undefined,
            shortCode,
            transactionId: cart.id,
            uid: userState.account,
        };

        yield call(
            CartService.checkout,
            basePaths,
            firebaseToken,
            cart.id,
            checkoutPayload,
        );
        yield call(checkoutReferralEvent, checkoutPayload);
        yield call(refreshAccount);
        yield put(showNotice(emailVerifyNotice(userState)));
    } catch (err) {
        yield put(CheckoutActions.reportCheckoutError((err as Error).message));
        yield call(confirmWithModal, {
            additionalProps: {
                body: (err as Error).message,
                title: __('uh-oh'),
            },
            component: AlertModal,
        });
    }
}

function* fetchNpaNxx(action: NpaNxxActions.IFetchNpaNxxAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const results: INpaNxxResponse = yield call(
            InventoryService.fetchNpaNxx,
            basePaths,
            action.payload,
        );

        if (
            !results.npanxxlist ||
            (results.npanxxlist as ReadonlyArray<any>).length <= 0
        ) {
            throw new Error(__('inventory.no-carriers-error'));
        }

        yield put(NpaNxxActions.loadNpaNxx(results.npanxxlist));
    } catch (e) {
        yield put(NpaNxxActions.reportNpaNxxError((e as Error).message));
    }
}

function* fetchMdn(action: MdnActions.IFetchMdnAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const response: IMdnResponse = yield call(
            InventoryService.fetchMdn,
            basePaths,
            action.payload,
        );

        yield put(MdnActions.loadMdn(response.mdn));
    } catch (e) {
        yield put(MdnActions.reportMdnError((e as Error).message));
    }
}

function* fetchCarriers(
    action: PortActions.CarriersActions.IFetchCarriersAction,
) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);
        const response = yield call(
            PortService.fetchCarriers,
            basePaths,
            firebaseToken,
            action.payload.replace(/[^\d]/g, ''),
        );
        yield put(PortActions.CarriersActions.loadCarriers(response));
    } catch (e) {
        yield put(
            PortActions.CarriersActions.reportCarriersError(
                (e as Error).message,
            ),
        );
    }
}

// function* imeiInvalid(error: string) {
//     const { dispatch } = smellyStoreSingleton.store;
//     yield call(confirmWithModal, {
//         additionalProps: {
//             analyticsTag: __('imei.invalid.modal-name-metrics'),
//             body: __('imei.invalid.body'),
//             buttonText: __('imei.invalid.browse-phones'),
//             closable: true,
//             error: true,
//             onConfirm: () => {
//                 dispatch(modalLoading({ primary: false, loading: true }));
//                 dispatch(
//                     eventLink({
//                         event_category: 'link_click',
//                         link_name: __('imei.invalid.new-device-metrics'),
//                     }),
//                 );
//                 window.location.href = __('routes.phones');
//             },
//             onConfirmSecondary: () => {
//                 return true;
//             },
//             subtitle: error,
//             title: __('imei.invalid.title'),
//         },
//         component: AlertModal,
//     });
// }

function* clearCart() {
    try {
        // Delete order
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const cartId = yield select(
            (state: IApplicationState) => state.purchase.cart.id,
        );

        const firebaseToken = yield call(getToken);
        yield call(CartService.deleteOrder, basePaths, firebaseToken, cartId);
        yield call(fetchCartRequest);
        yield put(CartActions.deleteDraftOrderFromCartSuccess());
    } catch (error) {
        yield put(CartActions.deleteDraftOrderFromCartError());
    }
}

function* purchaseSaga() {
    yield all([
        portSaga(),
        takeEvery(CartActions.ActionType.ADD_ITEM_TO_CART, addItemToCart),
        takeEvery(CheckoutActions.ActionType.CHECKOUT, checkout),
        takeEvery(NpaNxxActions.ActionType.FETCH_NPA_NXX, fetchNpaNxx),
        takeEvery(MdnActions.ActionType.FETCH_MDN, fetchMdn),
        takeEvery(
            PortActions.CarriersActions.ActionType.FETCH_CARRIERS,
            fetchCarriers,
        ),
        takeEvery(CartActions.ActionType.FETCH_CART, fetchCart),
        takeLatest(CartActions.ActionType.FETCH_ORDERS, fetchOrders),
        takeLatest(
            CartActions.ActionType.FETCH_ORDER_DETAILS,
            fetchOrderDetail,
        ),

        takeEvery(CartActions.ActionType.REPORT_ADD_ITEM_ERROR, trackError),
        takeEvery(CheckoutActions.ActionType.REPORT_CHECKOUT_ERROR, trackError),
        takeEvery(MdnActions.ActionType.REPORT_MDN_ERROR, trackError),
        takeEvery(NpaNxxActions.ActionType.REPORT_NPA_NXX_ERROR, trackError),
        takeEvery(
            PortActions.CarriersActions.ActionType.REPORT_CARRIERS_ERROR,
            trackError,
        ),
        takeEvery(CartActions.ActionType.SHOW_REMOVED_SIM, showRemovedSim),
        takeEvery(
            CartActions.ActionType.DELETE_ITEM_FROM_CART,
            deleteItemFromCartSaga,
        ),
        takeEvery(
            CartActions.ActionType.DELETE_DEVICE_FROM_CART_CONFIRM,
            deleteDeviceFromCartConfirm,
        ),
        takeEvery(
            CartActions.ActionType.DELETE_DEVICE_FROM_CART,
            deleteDeviceFromCartSaga,
        ),
        takeLatest(
            CartActions.ActionType.DELETE_DRAFT_ORDER_HANDSET,
            clearCart,
        ),
    ]);
}

export { fetchCartRequest, purchaseSaga };
