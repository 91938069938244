import {
    FAQResponse,
    IConfigurationBasePaths,
    NonNormalizedFAQResponse,
} from 'models';
import { ThinAirService } from './thin-air.service';

class FAQService extends ThinAirService {
    static async fetchTopics(
        basePaths: IConfigurationBasePaths,
        version?: string,
    ): Promise<FAQResponse | void> {
        const response = await fetch(`${FAQService.BASE_URL}${basePaths.faq}`, {
            headers: FAQService.getHeadersWithCachedVersion(version),
            method: 'GET',
        });

        if (response.status === 304) {
            // No need to update anything
            return;
        }

        // There should be something in here
        const json = (await response.json()) as NonNormalizedFAQResponse;
        return FAQResponse.normalize(json);
    }

    private static getHeadersWithCachedVersion(version?: string): Headers {
        const headers = FAQService.getDefaultHeaders();
        if (version && version.trim()) {
            headers.append('Version', version);
        }

        return headers;
    }
}

export { FAQService };
