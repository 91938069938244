import { createAction, IAction } from 'lib/br-redux';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    SUBMIT_MANUAL_PAYMENT = 'PAYMENT/MANUAL_PAYMENT/SUBMIT_MANUAL_PAYMENT',
    REPORT_MANUAL_PAYMENT_SUCCESS = 'PAYMENT/MANUAL_PAYMENT/REPORT_MANUAL_PAYMENT_SUCCESS',
    REPORT_MANUAL_PAYMENT_ERROR = 'PAYMENT/MANUAL_PAYMENT/REPORT_MANUAL_PAYMENT_ERROR',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

interface ManualPaymentPayload {
    readonly amount: number;
}

type ISubmitManualPaymentAction = IAction<
    ActionType.SUBMIT_MANUAL_PAYMENT,
    ManualPaymentPayload
>;
type IReportManualPaymentSuccessAction = IAction<
    ActionType.REPORT_MANUAL_PAYMENT_SUCCESS
>;
type IReportManualPaymentErrorAction = IAction<
    ActionType.REPORT_MANUAL_PAYMENT_ERROR,
    string
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IManualPaymentAction =
    | ISubmitManualPaymentAction
    | IReportManualPaymentSuccessAction
    | IReportManualPaymentErrorAction;

const submitManualPayment = createAction(
    ActionType.SUBMIT_MANUAL_PAYMENT,
    (amount: number) => ({ amount }),
);
const reportManualPaymentSuccess = createAction(
    ActionType.REPORT_MANUAL_PAYMENT_SUCCESS,
);
const reportManualPaymentError = createAction(
    ActionType.REPORT_MANUAL_PAYMENT_ERROR,
    (er: string) => er,
);

export {
    ActionType,
    IManualPaymentAction,
    ISubmitManualPaymentAction,
    IReportManualPaymentSuccessAction,
    IReportManualPaymentErrorAction,
    submitManualPayment,
    reportManualPaymentSuccess,
    reportManualPaymentError,
};
