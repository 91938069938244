import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { IProductItemModel, IShopPhonesModel } from '../../models/products';
import { ActionType, ProductsAction } from './products.actions';

interface IProductsState {
    readonly fetchStatus: FetchStatus;
    readonly error: string;
    readonly results: ReadonlyArray<IProductItemModel>;
    readonly phones: ReadonlyArray<IShopPhonesModel>;
}

const DEFAULT_STATE: IProductsState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
    results: [],
    phones: [],
};

const {
    FETCH_PRODUCTS,
    REPORT_FETCH_PRODUCTS_ERROR,
    REPORT_FETCH_PRODUCTS_SUCCESS,
    FETCH_PHONES,
    REPORT_FETCH_PHONES_ERROR,
    REPORT_FETCH_PHONES_SUCCESS,
} = ActionType;

const productsReducer = (
    state: IProductsState = DEFAULT_STATE,
    action: ProductsAction | IDefaultAction,
): IProductsState => {
    switch (action.type) {
        case FETCH_PRODUCTS:
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        case REPORT_FETCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                fetchStatus: FetchStatus.SUCCESS,
                results: action.payload,
            };
        case REPORT_FETCH_PRODUCTS_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        case FETCH_PHONES:
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        case REPORT_FETCH_PHONES_SUCCESS:
            return {
                ...state,
                fetchStatus: FetchStatus.SUCCESS,
                phones: action.payload,
            };
        case REPORT_FETCH_PHONES_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        default:
            return state;
    }
};

export { IProductsState, productsReducer, DEFAULT_STATE };
