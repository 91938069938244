// tslint:disable:max-line-length
import * as React from 'react';

interface Props extends React.HTMLProps<SVGElement> {
    readonly invert?: boolean;
}

const Smile = ({ invert, ...other }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="115"
        height="115"
        viewBox="0 0 115 115"
        // {...other}
    >
        <g fill={invert ? '#ffffff' : '#1800FF'} fillRule="evenodd">
            <path d="M24.069 12.035c0 6.094-4.942 11.036-11.034 11.036C6.94 23.07 2 18.129 2 12.035 2 5.942 6.94 1 13.035 1 19.127 1 24.07 5.942 24.07 12.035M112.069 12.035c0 6.094-4.942 11.036-11.036 11.036C94.94 23.07 90 18.129 90 12.035 90 5.942 94.94 1 101.033 1c6.094 0 11.036 4.942 11.036 11.035M56.974 100.974C26.662 100.974 2 76.314 2 46h18.641c0 20.034 16.3 36.333 36.333 36.333 20.034 0 36.333-16.3 36.333-36.333h18.642c0 30.314-24.663 54.974-54.975 54.974" />
        </g>
    </svg>
);

export { Smile };
