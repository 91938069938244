import { App } from 'components/app';
import { FetchStatus } from 'lib/br-redux';
import { IApplicationState, StartupScript } from 'models';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { AuthenticationActions } from 'state/authentication';

const mapStateToProps = ({
    account,
    authentication,
    configuration: { userSessionTimeout },
    startup,
    view,
    configuration,
}: IApplicationState) => ({
    authenticated: authentication.fetchStatus === FetchStatus.SUCCESS,
    customerState: account.customerState,
    account: account,
    configuration,
    userSessionTimeout,
    hasModals: view.modalQueue.length > 0,
    initializing:
        startup.pending.filter(n => n !== StartupScript.CHAT).length ||
        view.loadingRequests.length,
});

const mapDispatchToProps = dispatch => ({
    signOut: () => dispatch(AuthenticationActions.signOut()),
    refreshExcToken: () => dispatch(AuthenticationActions.refreshExcToken()),
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const AppContainer = enhance(App);

export { AppContainer };
