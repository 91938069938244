import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';

import { Block } from 'components/common/block';
import { Hidden } from 'components/common/hidden';
import { BodyCopy, Headline } from 'components/common/typography';
import {
    ButtonContainer as Button,
    InputContainer as Input,
    SelectContainer as Select,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';
import {
    Account,
    ChoiceOption,
    IDeviceListCarriersModels,
    IDeviceModel,
    IDeviceTypeProps,
    IInputValidationProps,
    IModalProps,
} from 'models';
import { InputValidator } from 'utils';
import { IStayInLoop } from '../../models/stay-in-loop/stay-in-loop.model';
import { BooleanButtons } from '../sign-up/components/phone-number/boolean-buttons';

const styles = require('./stay-in-loop.less');

import { DEVICE_OS } from 'models/device';

interface IProps
    extends IAnalyticsProps,
        IDeviceTypeProps,
        IInputValidationProps,
        IModalProps {
    readonly bindHandleChange;
    readonly normalizeOptions;
    readonly handleOnChange;
    readonly handleOnValidate;
    readonly authStatus: FetchStatus;
    readonly account: Account;
    readonly deviceCompatibilityCarriersModels: IDeviceListCarriersModels;
    captureStayInLoop(IStayInLoop: IStayInLoop): void;
    redirectTo(url: string): void;
    loadCarriersModels(): void;
}

interface IState {
    readonly step: number | 1;
    readonly device: string | '';
    readonly carrier: string | '';
    readonly email: any;
    readonly os: string | 'Apple';
    readonly selectedOperatingSystem: number | 0;
}

const DEFAULT_STATE: IState = {
    step: 1,
    carrier: '',
    device: '',
    email: localStorage.getItem('email-signup'),
    os: 'Apple',
    selectedOperatingSystem: 0,
};

@CSSModules(styles, { allowMultiple: true })
class StayInLoop extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    componentDidMount() {
        this.props.loadCarriersModels();
    }
    render() {
        const emailFooter = localStorage.getItem('email-signup');
        const { authStatus } = this.props;
        switch (this.state.step) {
            case 1:
                return this.landing;
            case 2:
                return this.details;
            case 3:
                if (authStatus !== FetchStatus.SUCCESS) {
                    if (typeof emailFooter !== 'string') {
                        return this.email;
                    }
                }
            case 4:
                return this.confirmation;
        }
    }

    sendDataToServer() {
        const {
            props: { captureStayInLoop, authStatus, account },
            state: { device, carrier, email, os },
        } = this;
        const emailFooter = localStorage.getItem('email-signup');
        if (authStatus === FetchStatus.SUCCESS) {
            if (emailFooter !== '') {
                this.setState({
                    email: emailFooter,
                });
            } else {
                this.setState({
                    email: account.emailAddress,
                });
            }
            const iStayInLoop = {
                carrier,
                device,
                email: account.emailAddress,
                os,
            };
            captureStayInLoop(iStayInLoop);
        } else {
            if (emailFooter !== '') {
                const iStayInLoop = {
                    carrier,
                    device,
                    email,
                    os,
                };
                captureStayInLoop(iStayInLoop);
            } else {
                const iStayInLoop = {
                    carrier,
                    device,
                    email,
                    os,
                };
                captureStayInLoop(iStayInLoop);
            }
        }
    }

    private readonly hadleLandingClick = (
        event: React.FormEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();
        this.setState({ step: 2 });
    };

    private readonly handleEmailChange = event => {
        this.setState({
            email: event.target.value,
        });
    };

    private readonly handleClickDone = (
        event: React.FormEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();
        const {
            props: { redirectTo },
        } = this;
        this.sendDataToServer();
        redirectTo(__('routes.home'));
    };

    private get confirmation(): JSX.Element {
        return (
            <>
                <div styleName="section-imei-wrapper section-checks-wrapper">
                    <div styleName="email-text-header-wrapper">
                        <Headline center>{__('stay-in-loop.header')}</Headline>

                        <BodyCopy styleName="landing-text">
                            {__('stay-in-loop-confirmation.description')}
                        </BodyCopy>
                    </div>
                    <div styleName="button-container">
                        <Button
                            fullWidth
                            fullWidthMobile
                            minWidth="220px"
                            onClick={event => this.handleClickDone(event)}
                        >
                            {__('stay-in-loop-confirmation.done')}
                        </Button>
                    </div>
                </div>
            </>
        );
    }

    private get landing(): JSX.Element {
        return (
            <>
                <div styleName="section-imei-wrapper section-checks-wrapper">
                    <div styleName="email-text-header-wrapper">
                        <Headline center>{__('stay-in-loop.header')}</Headline>

                        <BodyCopy styleName="landing-text">
                            {__('stay-in-loop.description')}
                        </BodyCopy>
                    </div>
                    <div styleName="button-container">
                        <Button
                            onClick={event => this.hadleLandingClick(event)}
                        >
                            {__('stay-in-loop.button1')}
                        </Button>
                        <Button 
                            onClick={event => this.handleLandingNoClick(event)}
                        >
                            {__('stay-in-loop.button2')}
                        </Button>
                    </div>
                </div>
            </>
        );
    }
    private readonly handleLandingNoClick = (
        event: React.FormEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();
        const {
            props: { redirectTo },
        } = this;
        redirectTo(__('routes.sign-up'));
    };

    private readonly handleDetailsClick = (
        event: React.FormEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();
        this.setState({ step: 3 });
    };

    private get email(): JSX.Element {
        const {
            props: { handleOnValidate },
        } = this;
        return (
            <>
                <div styleName="section-imei-wrapper section-checks-wrapper">
                    <div styleName="email-text-header-wrapper">
                        <Headline center>
                            {__('stay-in-loop-email.header')}
                        </Headline>
                        <BodyCopy styleName="landing-text">
                            {__('stay-in-loop-email.description')}
                        </BodyCopy>
                    </div>
                    <form styleName="email-form">
                        <div styleName="email-form-input">
                            <Input
                                noMargin
                                label={__(
                                    'stay-in-loop-email.input-field-placeholder',
                                )}
                                id="email"
                                type="email"
                                inline
                                value={this.state.email}
                                onChange={email =>
                                    this.handleEmailChange(email)
                                }
                                onValidate={handleOnValidate}
                                validate={InputValidator.validateEmail}
                            />
                        </div>
                        <div styleName="email-form-button">
                            <Button
                                fullWidth
                                fullWidthMobile
                                minWidth="220px"
                                type="submit"
                                onClick={event =>
                                    this.handleStayInLoopSubmit(event)
                                }
                            >
                                {__('stay-in-loop.email-submit')}
                            </Button>
                        </div>
                    </form>
                </div>
            </>
        );
    }

    private get details(): JSX.Element {
        const {
            state: { selectedOperatingSystem },
        } = this;
        return (
            // <Grid>
            <>
                <div styleName="section-imei-wrapper section-checks-wrapper">
                    <Headline styleName="Tell-us-more-about-y" center>
                        {__('stay-in-loop-details.header')}
                    </Headline>
                    <div styleName="buttons section-operating-system">
                        <Block width={3}>
                            <BooleanButtons
                                noMarginTop
                                prompt={''}
                                onSelect={this.selectOperatingSystem}
                                value={
                                    selectedOperatingSystem === null
                                        ? null
                                        : selectedOperatingSystem === 0
                                }
                                labelFalse={__('stay-in-loop-details.android')}
                                labelTrue={__('stay-in-loop-details.apple')}
                                metricFalse={__(
                                    'stay-in-loop-details.ios-metrics',
                                )}
                                metricTrue={__(
                                    'stay-in-loop-details.android-metrics',
                                )}
                                flow={''}
                            />
                        </Block>
                    </div>
                    <Hidden
                        when={
                            selectedOperatingSystem === null ||
                            selectedOperatingSystem === 1
                        }
                    >
                        <Select
                            margin30Bottom
                            styleName="Rectangle"
                            handleChange={this.bindHandleChange(
                                'device',
                                __('stay-in-loop-details-select-phone'),
                            )}
                            options={this.getModelOptions()}
                            value={this.state.device}
                        />
                    </Hidden>
                    <Select
                        margin30Bottom
                        styleName="Rectangle"
                        handleChange={this.bindHandleChange(
                            'carrier',
                            __('stay-in-loop-details-select-carrier'),
                        )}
                        options={this.getCarrierOptions()}
                        value={this.state.carrier}
                    />
                    <div styleName="button-container">
                        <Button
                            margin30Top
                            fullWidth
                            fullWidthMobile
                            minWidth="220px"
                            type="submit"
                            onClick={event => this.handleDetailsClick(event)}
                        >
                            {__('stay-in-loop-details-button-next')}
                        </Button>
                    </div>
                </div>
            </>
        );
    }

    private readonly getCarrierOptions = (): ReadonlyArray<ChoiceOption> => {
        const { deviceCompatibilityCarriersModels } = this.props;
        if (
            !deviceCompatibilityCarriersModels ||
            !deviceCompatibilityCarriersModels.carriers
        ) {
            return Array<ChoiceOption>();
        }
        const fixCarriers = deviceCompatibilityCarriersModels.carriers.filter(
            item => {
                return item !== '-' && item !== 'Other';
            },
        );
        fixCarriers.push('Other');
        return fixCarriers.map(r => ({
            label: r,
            value: r,
        }));
    };

    private readonly getModelOptions = (): ReadonlyArray<ChoiceOption> => {
        const OS = this.getOS();
        if (OS) {
            return this.getModel(OS);
        }
        return Array<ChoiceOption>();
    };

    private readonly getOS = (): string => {
        const {
            state: { selectedOperatingSystem },
        } = this;
        if (selectedOperatingSystem === null) {
            return '';
        }
        const selectedOS =
            selectedOperatingSystem !== null ? selectedOperatingSystem : '';
        return DEVICE_OS[selectedOS];
    };

    private readonly getModel = (OS: string): ReadonlyArray<ChoiceOption> => {
        const { deviceCompatibilityCarriersModels } = this.props;
        const found:
            | IDeviceModel
            | undefined = deviceCompatibilityCarriersModels.models.find(
            (element: IDeviceModel) => {
                return element && OS in element;
            },
        );
        if (!found) {
            return Array<ChoiceOption>();
        }
        const fixModels = found[OS].filter(item => {
            return item !== '-' && item !== 'Other';
        });
        fixModels.push('Other');
        return fixModels.map(r => ({
            label: r,
            value: r,
        }));
    };

    private readonly selectOperatingSystem = (answer: boolean) => {
        if (answer === true) {
            this.setState({
                os: 'IOS',
                selectedOperatingSystem: answer ? 0 : 1,
            });
        } else {
            this.setState({
                os: 'Android',
                selectedOperatingSystem: answer ? 0 : 1,
            });
        }
    };

    private readonly bindHandleChange = (name: string, label: string) => (
        value: string,
    ): void => {
        const newState = { ...this.state };
        newState[name] = value;
        this.setState(newState);
    };

    private readonly handleStayInLoopSubmit = (
        event: React.FormEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();
        // this.sendDataToServer();
        this.setState({ step: 4 });
    };
}

export { StayInLoop };
