import { IDefaultAction } from 'lib/br-redux';
import { Reducer } from 'redux';
import {
    ActionType,
    ISignOutAction,
} from 'state/authentication/authentication.actions';

const clearStateOnSignOut = <S>(reducer: Reducer<S>) => (
    state: S | undefined,
    action: ISignOutAction | IDefaultAction,
): S => {
    if (action.type === ActionType.SIGN_OUT) {
        state = undefined;
    }

    return reducer(state as S, action);
};

export { clearStateOnSignOut };
