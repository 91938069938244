import { ServicePause } from 'components/account/service-pause/service-pause';
import { withModal } from 'components/common/modal';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { pauseService } from 'state/account/pause/pause.actions';

const mapStateToProps = (state: IApplicationState) => ({});

const mapDispatchToProps = dispatch => ({
    pauseService: p => dispatch(pauseService(p)),
});

const enhance = compose(
    withModal,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const ServicePauseContainer = enhance(ServicePause);

export { ServicePauseContainer };
