import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./success-msg.less');

interface IProps {
    readonly children?: React.ReactNode;
}

@CSSModules(styles, { allowMultiple: true })
class SuccessMsg extends React.Component<IProps> {
    render() {
        const {
            props: { children },
        } = this;
        return <span styleName="success-msg">{children}</span>;
    }
}

export { SuccessMsg };
