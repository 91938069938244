import { IConfigurationBasePaths, IShop } from 'models';
import { ThinAirService } from './thin-air.service';

class ShopService extends ThinAirService {
    static async getProducts(
        basePaths: IConfigurationBasePaths,
        // firebaseToken: string,
    ): Promise<IShop> {
        return ShopService.fetchJson<IShop>(
            `${ShopService.BASE_URL}${
                basePaths.products
            }/getProducts?BYOD=false&Family=HANDSET`,
            {
                headers: ShopService.getDefaultHeaders(),
            },
        );
    }
}

export { ShopService };
