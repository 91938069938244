import * as React from 'react';

import { IAnalyticsProps } from 'components/common/analytics';
import { Hidden } from 'components/common/hidden';
import { ButtonContainer as Button, InputContainer as Input } from 'containers';
import { IInputValidationProps } from 'models';
import { InputValidator } from 'utils';

interface Props extends IAnalyticsProps, IInputValidationProps {
    readonly fetching: boolean;
    readonly complete: boolean;
    readonly carriersError: string;
    handleSubmit(phoneNumber: string): void;
    handleOnPhoneNumberChange(phone: string): void;
}

class EnterPhoneNumber extends React.Component<Props> {
    render() {
        const {
            handleOnChange,
            props: {
                carriersError,
                data: { phoneNumber },
                errors: { phoneNumber: hasPhoneNumberError },
                complete,
                fetching,
                handleOnValidate,
            },
            validateNumber,
        } = this;

        return (
            <form onSubmit={this.handleSubmit}>
                <Input
                    id="phoneNumber"
                    label={__('enter-phone-number.label')}
                    description={__('enter-phone-number.description')}
                    showLabel
                    showDescription
                    error={carriersError}
                    onValidate={handleOnValidate}
                    onChange={handleOnChange}
                    validate={validateNumber}
                    value={phoneNumber}
                    placeholder={__('enter-phone-number.placeholder')}
                />
                <Hidden when={complete}>
                    <Button
                        right
                        loading={fetching}
                        disabled={hasPhoneNumberError}
                        type="submit"
                        label="Next"
                    />
                </Hidden>
            </form>
        );
    }

    private readonly validateNumber = (value: string) => {
        const { Phone } = InputValidator.Patterns;
        return new InputValidator(this.normalizePhoneNumber(value), {
            errorMessage: __('account-info.phone-number-error'),
        })
            .hasExactLengthOf(10, __('account-info.phone-number-length-error'))
            .conformsToPattern(Phone)
            .validate();
    };

    private normalizePhoneNumber(phoneNumber: string) {
        return phoneNumber.replace(/[^\d]/g, '');
    }
    private readonly handleSubmit = (
        event: React.FormEvent<HTMLFormElement>,
    ): void => {
        event.preventDefault();
        this.props.handleSubmit(
            this.normalizePhoneNumber(this.props.data.phoneNumber),
        );
    };

    private readonly handleOnChange = (
        event: React.FormEvent<HTMLInputElement>,
    ): void => {
        const { value } = event.target as HTMLInputElement;
        this.props.handleOnPhoneNumberChange(this.normalizePhoneNumber(value));
        this.props.handleOnChange(event);
    };
}

export { EnterPhoneNumber };
