enum PaymentKind {
    UNKNOWN = '',
    CREDIT_CARD = 'creditCard',
    PAYPAL = 'paypal',
    VENMO = 'venmo',
}

interface PaymentMethodBase {
    readonly kind: PaymentKind;
    readonly token: string;
}

interface UnknownPaymentMethod extends PaymentMethodBase {
    readonly kind: PaymentKind.UNKNOWN;
    readonly token: '';
}

interface CreditCardPaymentMethod extends PaymentMethodBase {
    readonly kind: PaymentKind.CREDIT_CARD;
    readonly cardHolderName: string;
    readonly last4: string;
    readonly cardType: string;
    readonly expirationDate: string;
    readonly expired: 'true' | 'false';
}

interface PaypalPaymentMethod extends PaymentMethodBase {
    readonly kind: PaymentKind.PAYPAL;
    readonly email: string;
}

interface VenmoPaymentMethod extends PaymentMethodBase {
    readonly kind: PaymentKind.VENMO;
    readonly username: string;
    readonly userId: string;
    readonly sourceDescription: string;
}

type PaymentMethod =
    | UnknownPaymentMethod
    | CreditCardPaymentMethod
    | PaypalPaymentMethod
    | VenmoPaymentMethod;

export {
    PaymentKind,
    PaymentMethod,
    VenmoPaymentMethod,
    CreditCardPaymentMethod,
    UnknownPaymentMethod,
    PaypalPaymentMethod,
};
