// tslint:disable:max-line-length
import * as React from 'react';

interface Props extends React.HTMLProps<SVGElement> {
    readonly invert?: boolean;
}

const U = ({ invert, ...other }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="115"
        height="115"
        viewBox="0 0 115 115"
        // {...other}
    >
        <g fill={invert ? '#ffffff' : '#1800FF'} fillRule="evenodd">
            <path d="M25.978 10.989c0 6.07-4.921 10.99-10.99 10.99S4 17.06 4 10.99C4 4.92 8.92 0 14.989 0c6.068 0 10.989 4.92 10.989 10.989M112.978 10.989c0 6.07-4.921 10.99-10.99 10.99S91 17.06 91 10.99C91 4.92 95.92 0 101.989 0c6.068 0 10.989 4.92 10.989 10.989M109.716 99.606H33.804C16.267 99.606 2 85.339 2 67.802 2 50.268 16.267 36 33.804 36h76.086v18.247H33.804c-7.476 0-13.557 6.08-13.557 13.555 0 7.474 6.08 13.558 13.557 13.558h75.912v18.246z" />
        </g>
    </svg>
);

export { U };
