import { withAnalytics } from 'components/common/analytics';
import { GetPhoneNumber } from 'components/sign-up/components/phone-number/get-phone-number';
import { IApplicationState } from 'models';
import { clearCartErrors } from 'state/purchase/cart/cart.actions';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { reportCarriersError } from 'state/purchase/port/carriers/carriers.actions';
import { showModal } from 'state/view/view.actions';

const mapStateToProps = ({ purchase }: IApplicationState, props) => ({
    ...purchase,
    ...props,
});

const mapDispatchToProps = dispatch => ({
    clearCartErrors: () => dispatch(clearCartErrors()),
    redirect: path => dispatch(push(path)),
    reportCarriersError: () => dispatch(reportCarriersError('')),
    showModal: props => dispatch(showModal(props)),
});

const enhance = compose(
    withAnalytics,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const GetPhoneNumberContainer = enhance(GetPhoneNumber);

export { GetPhoneNumberContainer };
