import {
    createStateMerger,
    FetchStatus,
    IAction,
    IDefaultAction,
} from 'lib/br-redux';
import {
    AOAccountOrderResponse,
    Cart,
    IApplicationState,
    OrderDetailResponse,
    ProductType,
} from 'models';
import { ActionType, ICartAction } from './cart.actions';

interface PendingItem {
    readonly productType: ProductType;
    readonly error: string;
}

interface ICartState {
    readonly error: string;
    readonly updateFetchStatus: FetchStatus;
    readonly retrieveFetchStatus: FetchStatus;
    readonly id: string;
    readonly result: Cart | null;
    readonly orderDetails: OrderDetailResponse | null;
    readonly pendingItems: ReadonlyArray<PendingItem>;
    readonly simRemoved: boolean;
    readonly orderNumber: string | '';
    readonly orderHistory: ReadonlyArray<AOAccountOrderResponse> | null;
}

const DEFAULT_STATE: ICartState = {
    error: '',
    id: '',
    pendingItems: [],
    result: null,
    retrieveFetchStatus: FetchStatus.NOT_FETCHED,
    simRemoved: false,
    updateFetchStatus: FetchStatus.NOT_FETCHED,
    orderDetails: null,
    orderNumber: '',
    orderHistory: [],
};

const {
    ADD_ITEM_TO_CART,
    DELETE_ITEM_FROM_CART,
    FETCH_CART,
    FETCH_ORDERS,
    REPORT_FETCH_ORDERS_SUCCESS,
    REPORT_FETCH_ORDERS_ERROR,
    REPORT_ADD_ITEM_ERROR,
    REPORT_ADD_ITEM_SUCCESS,
    REPORT_DELETE_ITEM_ERROR,
    REPORT_DELETE_ITEM_SUCCESS,
    REPORT_FETCH_CART_ERROR,
    REPORT_FETCH_CART_SUCCESS,
    FETCH_ORDER_DETAILS,
    FETCH_ORDER_DETAILS_SUCCESS,
    SET_CART_ID,
    CLEAR_CART_ERRORS,
    SHOW_REMOVED_SIM,
    REPORT_REMOVED_SIM,
} = ActionType;

const cartReducer = (
    state: ICartState = DEFAULT_STATE,
    action:
        | ICartAction
        | IAction<'persist/REHYDRATE', IApplicationState>
        | IDefaultAction,
) => {
    const merge = createStateMerger(state);
    switch (action.type) {
        case 'persist/REHYDRATE':
            try {
                return merge({
                    id: action.payload.purchase.cart.id || '',
                });
            } catch (_) {
                return state;
            }
        case SET_CART_ID:
            return merge({ id: action.payload });

        case FETCH_CART:
            return merge({ retrieveFetchStatus: FetchStatus.FETCHING });
        case FETCH_ORDERS:
            return merge({ retrieveFetchStatus: FetchStatus.FETCHING });
        case REPORT_FETCH_ORDERS_SUCCESS:
            return merge({
                error: '',
                orderHistory: action.payload,
                retrieveFetchStatus: FetchStatus.SUCCESS,
            });
        case REPORT_FETCH_CART_SUCCESS:
            return merge({
                error: '',
                id: action.payload.id,
                result: action.payload,
                retrieveFetchStatus: FetchStatus.SUCCESS,
            });
        case REPORT_FETCH_CART_ERROR:
            return merge({
                error: action.payload,
                retrieveFetchStatus: FetchStatus.ERROR,
            });

        case REPORT_FETCH_ORDERS_ERROR:
            return merge({
                error: action.payload,
                retrieveFetchStatus: FetchStatus.ERROR,
            });

        case ADD_ITEM_TO_CART:
            return merge({
                error: '',
                pendingItems: addOrUpdatePendingItem(state.pendingItems, {
                    error: '',
                    productType: action.payload.productType,
                }),
                updateFetchStatus: FetchStatus.FETCHING,
            });
        case REPORT_ADD_ITEM_SUCCESS:
            return merge({
                error: '',
                pendingItems: removePendingItem(
                    state.pendingItems,
                    action.payload,
                ),
                updateFetchStatus: FetchStatus.SUCCESS,
            });
        case REPORT_ADD_ITEM_ERROR:
            return merge({
                error: action.payload.error,
                pendingItems: addOrUpdatePendingItem(
                    state.pendingItems,
                    action.payload,
                ),
                updateFetchStatus: FetchStatus.ERROR,
            });

        case DELETE_ITEM_FROM_CART:
            return merge({
                error: '',
                updateFetchStatus: FetchStatus.FETCHING,
            });
        case REPORT_DELETE_ITEM_SUCCESS:
            return merge({
                error: '',
                updateFetchStatus: FetchStatus.SUCCESS,
            });
        case REPORT_DELETE_ITEM_ERROR:
            return merge({
                error: action.payload.error,
                updateFetchStatus: FetchStatus.ERROR,
            });

        case CLEAR_CART_ERRORS:
            return merge({
                error: '',
                pendingItems: [],
                retrieveFetchStatus: FetchStatus.NOT_FETCHED,
                updateFetchStatus: FetchStatus.NOT_FETCHED,
            });
        case SHOW_REMOVED_SIM:
            return merge({
                simRemoved: false,
            });
        case REPORT_REMOVED_SIM:
            return merge({
                simRemoved: true,
            });
        case FETCH_ORDER_DETAILS:
            return merge({
                orderNumber: action.payload,
                retrieveFetchStatus: FetchStatus.FETCHING,
            });
        case FETCH_ORDER_DETAILS_SUCCESS:
            return merge({
                error: '',
                orderDetails: action.payload,
                retrieveFetchStatus: FetchStatus.SUCCESS,
            });
        default:
            return state;
    }
};

const addOrUpdatePendingItem = (
    pendingItems: ReadonlyArray<PendingItem>,
    { productType, error }: PendingItem,
): ReadonlyArray<PendingItem> => {
    const idx = pendingItems.findIndex(i => i.productType === productType);
    if (idx > -1) {
        return [
            ...pendingItems.slice(0, idx),
            { productType, error },
            ...pendingItems.slice(idx + 1),
        ];
    }

    return [...pendingItems, { productType, error }];
};

const removePendingItem = (
    pendingItems: ReadonlyArray<PendingItem>,
    productType: ProductType,
): ReadonlyArray<PendingItem> => {
    return pendingItems.filter(p => p.productType !== productType);
};
export { ICartState, DEFAULT_STATE, cartReducer, PendingItem };
