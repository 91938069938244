const REFERRAL_ENABLED = process.env.REFERRAL_ENABLED === 'true';

interface ICommissionUpdateRequest {
    readonly commission_uid?: string;
    readonly transaction_uid?: string;
    readonly campaign_uid?: string;
    readonly is_approved?: string;
}

interface IReferralEvent {
    readonly campaign_uid?: number;
    readonly email?: string;
    readonly short_code?: string;
    readonly transaction_id?: string;
    readonly uid?: string;
    readonly custom1?: string;
}

interface IReferrerRequest {
    readonly email?: string;
    readonly email_new_ambassador?: string;
    readonly add_to_group_id?: string;
    readonly auto_create?: string;
    readonly first_name?: string;
    readonly last_name?: string;
    readonly uid?: string;
    readonly custom1?: string;
    readonly sandbox?: string;
}

interface IReferrerUpdateRequest {
    readonly email: string;
    readonly sandbox: number;
    readonly first_name: string;
    readonly last_name: string;
    readonly custom1?: string;
    readonly is_deactivated: number;
    readonly send_welcome_email: number;
    readonly new_uid: string;
    readonly set_groups: string;
}

interface IReferrerStatsRequest {
    readonly email: string;
    readonly uid?: string;
    readonly first_name?: string;
    readonly last_name?: string;
    readonly email_new_ambassador?: string;
    readonly sandbox?: string;
    readonly deactivate_new_ambassador?: string;
    readonly auto_create?: string;
    readonly start_date?: string;
    readonly end_date?: string;
}

interface IReferrerStats {
    readonly approvedSum: string;
    readonly referralCreditBal: string;
    readonly totalCreditsEarned: string;
}

const DEFAULT_REFERRER_STATS: IReferrerStats = {
    approvedSum: '',
    referralCreditBal: '',
    totalCreditsEarned: '',
};

interface IReferringAmbassador {
    readonly first_name: string;
    readonly last_name: string;
    readonly email: string;
    readonly uid: string;
    readonly commission: string;
    readonly balance_money: string;
    readonly balance_points: string;
    readonly memorable_url: string;
    readonly unique_referrals: string;
    readonly sandbox: string;
    readonly custom1: string;
    readonly custom2: string;
    readonly custom3: string;
}

const DEFAULT_REFERRING_AMBASSADOR: IReferringAmbassador = {
    balance_money: '',
    balance_points: '',
    commission: '',
    custom1: '',
    custom2: '',
    custom3: '',
    email: '',
    first_name: '',
    last_name: '',
    memorable_url: '',
    sandbox: '',
    uid: '',
    unique_referrals: '',
};

interface IValidateShortCodeResponse {
    readonly valid: string;
    readonly campaign_uid: string;
}
interface ICreditBalanceResponse {
    readonly ReferralCreditBal: number;
}
interface ITotalCreditEarnedResponse {
    readonly TotalCreditsEarned: number;
}

const DEFAULT_VALIDATE_SHORT_CODE_RESPONSE: IValidateShortCodeResponse = {
    campaign_uid: '',
    valid: '0',
};

export {
    REFERRAL_ENABLED,
    DEFAULT_REFERRER_STATS,
    DEFAULT_REFERRING_AMBASSADOR,
    DEFAULT_VALIDATE_SHORT_CODE_RESPONSE,
    ICommissionUpdateRequest,
    ICreditBalanceResponse,
    IReferralEvent,
    IReferrerRequest,
    IReferrerUpdateRequest,
    IReferrerStatsRequest,
    IReferrerStats,
    IReferringAmbassador,
    ITotalCreditEarnedResponse,
    IValidateShortCodeResponse,
};
