import {
    IApplicationState,
    ICommissionUpdateRequest,
    ICreditBalanceResponse,
    IReferrerStats,
    IReferrerUpdateRequest,
    ITotalCreditEarnedResponse,
    IValidateShortCodeResponse,
} from 'models';
import {
    all,
    call,
    put,
    select,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import { ReferralService } from 'services';
import { getToken } from 'state/firebase/firebase.saga';
import {
    ActionType,
    getCreditBalanceFailure,
    getCreditBalanceSuccess,
    getCreditEarnedSuccess,
    getCreditEarnedFailure,
    getReferrerStatsFailure,
    getReferrerStatsSuccess,
    updateCommissionFailure,
    updateCommissionSuccess,
    updateReferrerFailure,
    updateReferrerSuccess,
    validateShortCodeFailure,
    validateShortCodeSuccess,
} from './referral.actions';

function* validateShortCode(action) {
    try {
        const inviteCode = action.payload.inviteCode
            ? action.payload.inviteCode
            : action.payload;
        const existingCartId = yield select(
            (state: IApplicationState) => state.purchase.cart.id,
        );
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const shortCodeResponse: IValidateShortCodeResponse = yield call(
            ReferralService.validateShortCode,
            basePaths,
            existingCartId,
            inviteCode,
        );

        yield put(validateShortCodeSuccess(shortCodeResponse));
    } catch (err) {
        yield put(validateShortCodeFailure((err as Error).message));
    }
}

function* getCreditBalance() {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);
        const creditBalance: ICreditBalanceResponse = yield call(
            ReferralService.getCreditBalance,
            basePaths,
            firebaseToken,
        );

        yield put(
            getCreditBalanceSuccess(creditBalance.ReferralCreditBal.toString()),
        );
    } catch (err) {
        yield put(getCreditBalanceFailure((err as Error).message));
    }
}

function* getCreditEarned() {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);
        const creditEarned: ITotalCreditEarnedResponse = yield call(
            ReferralService.getCreditEarned,
            basePaths,
            firebaseToken,
        );

        yield put(
            getCreditEarnedSuccess(creditEarned.TotalCreditsEarned.toString()),
        );
    } catch (err) {
        yield put(getCreditEarnedFailure((err as Error).message));
    }
}

function* getReferrerStats(action) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );

        const firebaseToken = yield call(getToken);
        const referrerStats: IReferrerStats = yield call(
            ReferralService.getReferrerStats,
            basePaths,
            firebaseToken,
            action.payload,
        );

        yield put(getReferrerStatsSuccess(referrerStats));
    } catch (err) {
        yield put(getReferrerStatsFailure((err as Error).message));
    }
}

function* updateCommission(updateCommissionRequest: ICommissionUpdateRequest) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        yield call(
            ReferralService.updateCommission,
            basePaths,
            updateCommissionRequest,
        );

        yield put(updateCommissionSuccess());
    } catch (err) {
        yield put(updateCommissionFailure((err as Error).message));
    }
}

function* updateReferrer(updateReferrerRequest: IReferrerUpdateRequest) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        yield call(
            ReferralService.updateReferrer,
            basePaths,
            updateReferrerRequest,
        );

        yield put(updateReferrerSuccess());
    } catch (err) {
        yield put(updateReferrerFailure((err as Error).message));
    }
}

function* referralSaga() {
    yield all([
        takeEvery(ActionType.GET_CREDIT_BALANCE, getCreditBalance),
        takeEvery(ActionType.GET_CREDIT_EARNED, getCreditEarned),
        takeEvery(ActionType.GET_REFERRER_STATS, getReferrerStats),
        takeLatest(ActionType.VALIDATE_SHORT_CODE, validateShortCode),
    ]);
}

export { referralSaga, updateCommission, updateReferrer };
