import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./help.less');

@CSSModules(styles)
class PortingPhoneNumber extends React.Component<{}> {
    render() {
        return (
            <>{(window.location.href = __('routes.porting-phone-number'))}</>
        );
    }
}

export { PortingPhoneNumber };
