import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    dismissModal,
    reportCancel,
    reportConfirmation,
} from 'state/view/view.actions';
import { getComponentDisplayName } from 'utils';

const withModal = WrappedComponent => {
    class WithModal extends React.Component<any> {
        static get displayName() {
            return `WithModal(${getComponentDisplayName(WrappedComponent)})`;
        }

        render() {
            const { bindHandleClickWith, props } = this;
            return (
                <WrappedComponent
                    bindHandleClickWith={bindHandleClickWith}
                    {...props}
                />
            );
        }
        protected readonly bindHandleClickWith = (
            handler: () => boolean | undefined,
        ) => () => {
            if (handler) {
                const response = handler();
                if (response === false) {
                    return;
                }
            }
            this.props.onClose();
        };
    }
    const mapDispatchToProps = dispatch => {
        return {
            onCancel: () => dispatch(reportCancel()),
            onClose: () => dispatch(dismissModal()),
            onConfirm: () => dispatch(reportConfirmation()),
        };
    };
    const mergeProps = (stateProps, dispatchProps, ownProps) => {
        return {
            ...stateProps,
            ...dispatchProps,
            ...ownProps,
        };
    };
    const enhance = compose(
        connect(
            null,
            mapDispatchToProps,
            mergeProps,
        ),
    );
    return enhance(WithModal);
};

export { withModal };
