import { combineReducers } from 'redux';
import { clearStateOnSignOut } from 'state/utility';
import {
    carriersReducer as carriers,
    ICarriersState,
} from './carriers/carriers.reducer';
import {
    resubmissionReducer as resubmission,
    ResubmissionState,
} from './resubmission/resubmission.reducer';

interface IPortState {
    readonly carriers: ICarriersState;
    readonly resubmission: ResubmissionState;
}

const reducers = combineReducers({
    carriers,
    resubmission,
});

const portReducer = clearStateOnSignOut(reducers);

export { portReducer, IPortState };
