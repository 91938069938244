import { withAnalytics } from 'components/common/analytics';
import { withModal } from 'components/common/modal';
import { LeadCaptureModal } from 'components/footer/lead-capture-modal';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { captureLead, clearCaptureLead } from 'state/lead/lead.actions';

const mapStateToProps = ({ lead }: IApplicationState) => ({
    error: lead.error,
    status: lead.fetchStatus,
});

const mapDispatchToProps = dispatch => ({
    captureLead: data => dispatch(captureLead(data)),
    clearCaptureLead: () => dispatch(clearCaptureLead()),
});

const enhance = compose(
    withAnalytics,
    withModal,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const LeadCaptureModalContainer = enhance(LeadCaptureModal);

export { LeadCaptureModalContainer };
