import { createAction, IAction } from 'lib/br-redux';
import { PortErrorReason } from 'models/port';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    FETCH_PORT_STATUS = 'PORT_STATUS/FETCH_PORT_STATUS',
    REPORT_FETCH_PORT_STATUS_ERROR = 'PORT_STATUS/REPORT_FETCH_PORT_STATUS_ERROR',
    REPORT_FETCH_PORT_STATUS_SUCCESS = 'PORT_STATUS/REPORT_FETCH_PORT_STATUS_SUCCESS',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type FetchPortStatusAction = IAction<ActionType.FETCH_PORT_STATUS>;
type ReportFetchPortStatusSuccessAction = IAction<
    ActionType.REPORT_FETCH_PORT_STATUS_SUCCESS,
    PortErrorReason
>;
type ReportFetchPortStatusErrorAction = IAction<
    ActionType.REPORT_FETCH_PORT_STATUS_ERROR,
    string
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type PortStatusAction =
    | FetchPortStatusAction
    | ReportFetchPortStatusSuccessAction
    | ReportFetchPortStatusErrorAction;

// ------------------------------------
// Action Creators
// i.e. the stuff that actually makes it to the compiled Javascript
// ------------------------------------

const fetchPortStatus = createAction(ActionType.FETCH_PORT_STATUS);
const reportFetchPortStatusSuccess = createAction(
    ActionType.REPORT_FETCH_PORT_STATUS_SUCCESS,
    (errorReason: PortErrorReason) => errorReason,
);
const reportFetchPortStatusError = createAction(
    ActionType.REPORT_FETCH_PORT_STATUS_ERROR,
    (error: string) => error,
);

export {
    ActionType,
    PortStatusAction,
    FetchPortStatusAction,
    ReportFetchPortStatusErrorAction,
    ReportFetchPortStatusSuccessAction,
    fetchPortStatus,
    reportFetchPortStatusError,
    reportFetchPortStatusSuccess,
};
