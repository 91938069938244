import { TealiumFlowName, TealiumPageType } from 'models/tealium';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';

const styles = require('./coverage.less');

@CSSModules(styles, { allowMultiple: true })
class Coverage extends React.Component<{}> {
    constructor(props: {}) {
        super(props);
    }
    componentDidMount() {
        TealiumDispatcher.dispatchView(
            TealiumFlowName.NONE,
            TealiumPageType.COVERAGE,
        );
    }

    render() {
        return <>{(window.location.href = __('routes.coverage'))}</>;
    }
}

export { Coverage };
