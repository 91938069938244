import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { ActionType, BillAction } from './bill.actions';

interface ICurrentBillState {
    readonly fetchStatus: FetchStatus;
    readonly error: string;
    readonly dueDate: string;
    readonly chargeAmount: number;
    readonly enableManualPay: boolean;
    readonly accountBalance: number;
    readonly redeemedCredits: number;
    readonly totalAmount: number;
    readonly totalMembersInGroup: number;
    readonly groupCredits: number;
    readonly referralCredits: number;
    readonly goodwillCredits: number;
}

const DEFAULT_STATE: ICurrentBillState = {
    chargeAmount: 0,
    dueDate: '',
    enableManualPay: false,
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
    accountBalance: 0,
    redeemedCredits: 0,
    totalAmount: 0,
    totalMembersInGroup: 0,
    groupCredits: 0,
    referralCredits: 0,
    goodwillCredits: 0,
};

const {
    FETCH_CURRENT_BILL,
    REPORT_FETCH_CURRENT_BILL_ERROR,
    REPORT_FETCH_CURRENT_BILL_SUCCESS,
} = ActionType;

const currentBillReducer = (
    state: ICurrentBillState = DEFAULT_STATE,
    action: BillAction | IDefaultAction,
): ICurrentBillState => {
    switch (action.type) {
        case FETCH_CURRENT_BILL:
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        case REPORT_FETCH_CURRENT_BILL_SUCCESS:
            return {
                ...state,
                chargeAmount: action.payload.charge_amount,
                dueDate: action.payload.due_date,
                enableManualPay: action.payload.enableManualPay,
                fetchStatus: FetchStatus.SUCCESS,
                accountBalance: action.payload.accountBalance,
                redeemedCredits: action.payload.redeemedCredits,
                totalAmount: action.payload.totalAmount,
                totalMembersInGroup: action.payload.totalMembersInGroup,
                groupCredits: action.payload.groupCredits,
                referralCredits: action.payload.referralCredits,
                goodwillCredits: action.payload.goodwillCredits,
            };
        case REPORT_FETCH_CURRENT_BILL_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        default:
            return state;
    }
};

export { ICurrentBillState, currentBillReducer, DEFAULT_STATE };
