import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./protect.less');

@CSSModules(styles)
class ProtectCoverage extends React.Component<{}> {
    render() {
        return (
            <div>
                <iframe
                    src={__('routes.protect-coverage.aem')}
                    styleName="iframe"
                />
            </div>
        );
    }
}

export { ProtectCoverage };
