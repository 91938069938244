import { Label } from 'components/common/typography';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
const styles = require('./text-area.less');

interface Props extends React.HTMLProps<HTMLTextAreaElement> {
    readonly id: string;
}

@CSSModules(styles, { allowMultiple: true })
class TextArea extends React.Component<Props> {
    render() {
        const {
            props: { children, label, id },
            props,
        } = this;
        return (
            <>
                <Label id={`${id}_label`}>{label}</Label>
                <textarea id={id} styleName="text-area" {...props}>
                    {children}
                </textarea>
            </>
        );
    }
}

export { TextArea };
