import { IApplicationState, StartupScript } from 'models';
import { NuanceData, NuancePageIds } from 'models/nuance';
import { END, eventChannel } from 'redux-saga';
import { all, call, fork, put, select, take } from 'redux-saga/effects';
import { getIdOrDefault } from 'state/firebase/firebase.saga';
import {
    reportInitializationFailure,
    reportInitializationSuccess,
} from 'state/startup/startup.actions';
import { eventError } from 'state/tealium';

// tslint:disable:no-console
function waitForChatInit() {
    return eventChannel(emitter => {
        const pageLandingListener = {
            onPageLanding: () => {
                emitter(reportInitializationSuccess(StartupScript.CHAT));
                emitter(END);
            },
        };
        window.InqRegistry = { chatListeners: [pageLandingListener] };
        // tslint:disable-next-line:no-empty
        return () => {};
    });
}

function* initialize() {
    try {
        const channel = yield call(waitForChatInit);
        while (true) {
            const action = yield take(channel);
            yield put(action);
            yield fork(resetChatInit);
        }
    } catch (err) {
        yield put(eventError({ error_msg: (err as Error).message }));
        yield put(reportInitializationFailure(StartupScript.CHAT));
    }
}

function* resetChatInit() {
    try {
        const location = yield select(
            ({ routing }: IApplicationState) => routing.location,
        );
        const pathname = location ? location.pathname : '';
        yield call(updateNuanceChange, pathname);
    } catch (err) {
        yield put(eventError({ error_msg: (err as Error).message }));
        yield put(reportInitializationFailure(StartupScript.CHAT));
    }
}

// tslint:disable:no-console
function* updateNuanceChange(pathname: string) {
    try {
        const initialized: ReadonlyArray<StartupScript> = yield select(
            ({ startup }: IApplicationState) => startup.initialized,
        );
        if (initialized.indexOf(StartupScript.CHAT) > -1) {
            const FB_ID = yield call(getIdOrDefault);

            window.nuanceData = {
                FB_ID,
            } as NuanceData;

            if (window.Inq && window.Inq.reinitChat) {
                const map = NuancePageIds.find(m => m[0] === pathname);
                window.Inq.reinitChat(map ? map[1] : null, window.nuanceData);
            }
        }
    } catch (err) {
        // Do nothing
    }
}

function* updateNuanceFBID(FB_ID: string) {
    try {
        window.nuanceData = {
            FB_ID,
        } as NuanceData;
    } catch (err) {
        // Do nothing
    }
}

function* chatSaga() {
    yield all([initialize()]);
}

export { chatSaga, resetChatInit, updateNuanceChange, updateNuanceFBID };
