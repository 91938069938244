import { OrderHistory } from 'components/account/order-history/order-history';
import { withDeviceType } from 'components/common/device-type';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { CartActions } from 'state/purchase/purchase.actions';

const { fetchOrders } = CartActions;

const mapStateToProps = (state: IApplicationState) => ({
    orderHistories: state.purchase.cart.orderHistory || [],
    fetchStatus: state.account.ordersFetchStatus,
});

const mapDispatchToProps = dispatch => ({
    fetchOrderHistory: () => dispatch(fetchOrders()),
});

const enhance = compose(
    withDeviceType,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const OrderHistoryContainer = enhance(OrderHistory);

export { OrderHistoryContainer };
