import { createAction, IAction } from 'lib/br-redux';
import { INpaNxxCollection } from 'models';

enum ActionType {
    FETCH_NPA_NXX = 'PURCHASE/NPANXX/FETCH_NPA_NPX',
    LOAD_NPA_NXX = 'PURCHASE/NPANXX/LOAD_NPA_NXX',
    REPORT_NPA_NXX_ERROR = 'PURCHASE/NPANXX/REPORT_NPA_NXX_ERROR',
    CLEAR_NPA_NXX = 'PURCHASE/NPANXX/CLEAR_NPA_NXX',
}

type IFetchNpaNxxAction = IAction<ActionType.FETCH_NPA_NXX, string>;
type ILoadNpaNxxAction = IAction<ActionType.LOAD_NPA_NXX, INpaNxxCollection>;
type IReportNpaNxxErrorAction = IAction<
    ActionType.REPORT_NPA_NXX_ERROR,
    string
>;
type IClearNpaNxxAction = IAction<ActionType.CLEAR_NPA_NXX>;

type INpaNxxAction =
    | IFetchNpaNxxAction
    | ILoadNpaNxxAction
    | IClearNpaNxxAction
    | IReportNpaNxxErrorAction;

const loadNpaNxx = createAction(
    ActionType.LOAD_NPA_NXX,
    (results: INpaNxxCollection) => results,
);

const fetchNpaNxx = createAction(
    ActionType.FETCH_NPA_NXX,
    (zipCode: string) => zipCode,
);

const reportNpaNxxError = createAction(
    ActionType.REPORT_NPA_NXX_ERROR,
    (error: string) => error,
);

const clearNpaNxx = createAction(ActionType.CLEAR_NPA_NXX);

export {
    ActionType,
    IFetchNpaNxxAction,
    ILoadNpaNxxAction,
    IReportNpaNxxErrorAction,
    INpaNxxAction,
    loadNpaNxx,
    fetchNpaNxx,
    reportNpaNxxError,
    clearNpaNxx,
};
