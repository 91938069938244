import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { splitProps } from 'utils';

const styles = require('./subhead.less');

enum Modifiers {
    activeLink = 'activeLink',
    link = 'link',
    noMargin = 'noMargin',
    center = 'center',
    large = 'large',
    medium = 'medium',
    error = 'error',
}

interface SubheaderModifiers {
    readonly activeLink?: boolean;
    readonly noMargin?: boolean;
    readonly link?: boolean;
    readonly center?: boolean;
    readonly large?: boolean;
    readonly medium?: boolean;
    readonly error?: boolean;
}

type BaseSubheader = React.HTMLProps<HTMLParagraphElement> & SubheaderModifiers;

const SubheadComponent = ({
    styleName,
    children,
    ...props
}: BaseSubheader): JSX.Element => {
    const [modifiers, other] = splitProps(props, Modifiers);
    const cn = classNames('subhead', modifiers, styleName);
    return (
        <p styleName={cn} {...other}>
            {children}
        </p>
    );
};

const Subhead = CSSModules(SubheadComponent, styles, { allowMultiple: true });
export { Subhead };
