import * as React from 'react';

interface Props {
    readonly mdnCounterEnabled: boolean;
    readonly pinCounterEnabled: boolean;
    readonly incorrectMDNPortRetryCounter: number;
    readonly incorrectMDNPortRetryThreshold: number;
    readonly incorrectAccountPinPortRetryCounter: number;
    readonly incorrectAccountPinPortRetryThreshold: number;
}

class CountDown extends React.Component<Props> {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            mdnCounterEnabled,
            pinCounterEnabled,
            incorrectMDNPortRetryCounter,
            incorrectMDNPortRetryThreshold,
            incorrectAccountPinPortRetryCounter,
            incorrectAccountPinPortRetryThreshold,
        } = this.props;
        return (
            <>
                {mdnCounterEnabled &&
                    this.createCounter(
                        incorrectMDNPortRetryCounter,
                        incorrectMDNPortRetryThreshold,
                    )}
                {pinCounterEnabled &&
                    this.createCounter(
                        incorrectAccountPinPortRetryCounter,
                        incorrectAccountPinPortRetryThreshold,
                    )}
            </>
        );
    }

    private readonly createCounter = (
        counter: number,
        threshold: number,
    ): JSX.Element => {
        const total =
            counter < threshold && counter >= 0 ? threshold - counter : 0;
        return this.displayCounter(total);
    };

    private readonly displayCounter = (counter: number): JSX.Element => {
        return <span>{counter} attempts remaining</span>;
    };
}

export { CountDown };
