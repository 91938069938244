interface IConfigurationBasePaths {
    // TODO(dan) Remove optional from each attribute as it is implemented.
    readonly account?: string;
    readonly appconfig?: string;
    readonly auth?: string;
    readonly cart?: string;
    readonly device?: string;
    readonly faq?: string;
    readonly feedbacks?: string;
    readonly fraud?: string;
    readonly fulfillment?: string;
    readonly inventory?: string;
    readonly lead?: string;
    readonly mdnmigration?: string;
    readonly mtas?: string;
    readonly notification?: string;
    readonly orderprovision?: string;
    readonly payment?: string;
    readonly paymentnotification?: string;
    readonly places?: string;
    readonly port?: string;
    readonly products?: string;
    readonly referral?: string;
    readonly bag?: string;
}

interface INonNormalizedConfigurationToggles {
    // TODO(dan) Remove optional from each attribute as it is implemented.
    readonly enableAffirm?: string;
    readonly enableAssurant?: string;
    readonly enableReferral?: string;
    readonly enableSwap?: string;
    readonly enableGroups?: boolean;
    readonly restrictPOBoxAddressForDevice?: string;
    readonly restrictSameBillingShippingAddress?: string;
}

interface INonNormalizedConfiguration {
    // TODO(dan) Remove optional from each attribute as it is implemented.
    readonly appconfig_id: string;
    readonly appconfig_name: string;
    readonly auth_token_timeout: number;
    readonly user_session_timeout: number;
    readonly basePaths: IConfigurationBasePaths;
    readonly device_catalog_url?: string;
    readonly mdn_timeout: number;
    readonly toggles?: INonNormalizedConfigurationToggles;
}

interface IConfigurationToggles {
    // TODO(dan) Remove optional from each attribute as it is implemented.
    readonly enableAffirm?: boolean;
    readonly enableAssurant?: boolean;
    readonly enableReferral?: boolean;
    readonly enableLiveAgentChat?: boolean;
    readonly enableSwap?: boolean;
    readonly enableGroups?: boolean;
    readonly enableCashPayment?: boolean;
    readonly restrictPOBoxAddressForDevice?: boolean;
    readonly restrictSameBillingShippingAddress?: boolean;
}

interface IConfiguration {
    readonly appconfigId: string;
    readonly appconfigName: string;
    readonly authTokenTimeout: number;
    readonly userSessionTimeout: number;
    readonly basePaths: IConfigurationBasePaths;
    readonly deviceCatalogUrl: string;
    readonly mdnTimeout: number;
    readonly toggles: IConfigurationToggles;
}

interface IConfigurationResponse {
    readonly appconfig: INonNormalizedConfiguration;
    readonly toggles: INonNormalizedConfigurationToggles;
}

interface IChatTokenResponse {
    readonly token: string;
    readonly id: string;
}

class Configuration implements IConfiguration {
    static readonly DEFAULT: IConfiguration = {
        appconfigId: '',
        appconfigName: '',
        authTokenTimeout: -1,
        userSessionTimeout: 10800 * 1000,
        basePaths: {},
        deviceCatalogUrl: '',
        mdnTimeout: -1,
        toggles: {},
    };

    static readonly DEFAULT_TOGGLES: IConfigurationToggles = {
        enableAffirm: false,
        enableAssurant: false,
        enableReferral: false,
        enableGroups: false,
        restrictPOBoxAddressForDevice: false,
        restrictSameBillingShippingAddress: false,
    };

    // tslint:disable:readonly-keyword
    appconfigId: string;
    appconfigName: string;
    authTokenTimeout: number;
    userSessionTimeout: number;
    basePaths: IConfigurationBasePaths;
    deviceCatalogUrl: string;
    mdnTimeout: number;
    toggles: IConfigurationToggles;

    constructor(value: IConfiguration) {
        this.appconfigId = value.appconfigId || '';
        this.appconfigName = value.appconfigName || '';
        this.authTokenTimeout = value.authTokenTimeout || -1;
        this.userSessionTimeout = value.userSessionTimeout || -1;
        this.basePaths = value.basePaths || null;
        this.deviceCatalogUrl = value.deviceCatalogUrl || '';
        this.mdnTimeout = value.mdnTimeout || -1;
        this.toggles = value.toggles || Configuration.DEFAULT_TOGGLES;
    }

    static Normalize(
        configuration: INonNormalizedConfiguration,
    ): Configuration {
        const toggles: IConfigurationToggles = !configuration.toggles
            ? Configuration.DEFAULT_TOGGLES
            : ((
                  iToggles: INonNormalizedConfigurationToggles,
              ): IConfigurationToggles => {
                  const normalizedToggles: IConfigurationToggles = {};
                  Object.keys(iToggles).forEach(k => {
                      normalizedToggles[k] = iToggles[k] === 'Y';
                  });
                  return normalizedToggles;
              })(configuration.toggles as INonNormalizedConfigurationToggles);
        return new Configuration({
            appconfigId: configuration.appconfig_id || '',
            appconfigName: configuration.appconfig_name || '',
            authTokenTimeout: configuration.auth_token_timeout || -1,
            userSessionTimeout:
                configuration.user_session_timeout || 10800 * 1000,
            basePaths: configuration.basePaths || {},
            deviceCatalogUrl: configuration.device_catalog_url || '',
            mdnTimeout: configuration.mdn_timeout || -1,
            toggles,
        });
    }
}

export {
    Configuration,
    IConfiguration,
    IConfigurationBasePaths,
    IConfigurationResponse,
    IConfigurationToggles,
    IChatTokenResponse,
    INonNormalizedConfiguration,
};
