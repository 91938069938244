import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { D as ReallyHappyEmoji } from 'components/common/graphics/emoji';
import { Headline } from 'components/common/typography/headline/headline';
import { EmailActionHandler } from 'components/email-action-handler';
import { Grid, GridTemplates } from 'components/grid';
import { ButtonContainer as Button, InputContainer as Input } from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { IAnalyticsProps, IInputValidationProps } from 'models';
import { InputValidator } from 'utils';
import { BodyCopy } from '../common/typography';

const styles = require('./reset-password.less');

interface Props extends IInputValidationProps, IAnalyticsProps {
    readonly resetStatus: FetchStatus;
    resetPassword(password: string): void;
}

interface State {
    readonly error: string;
}

@CSSModules(styles)
class ResetPassword extends EmailActionHandler<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
        };
    }

    render() {
        const { resetStatus, verificationStatus } = this.props;
        if (resetStatus === FetchStatus.SUCCESS) {
            return this.successMessage;
        }

        if (verificationStatus === FetchStatus.SUCCESS) {
            return this.resetForm;
        }

        return <div />;
    }

    private get successMessage(): JSX.Element {
        return (
            <div styleName="success-container">
                <ReallyHappyEmoji styleName="emoji" />
                <Headline two>{__('reset-password.success-message')}</Headline>
            </div>
        );
    }

    private get resetForm(): JSX.Element {
        const {
            hasErrors,
            isFetching,
            props: {
                handleOnChange,
                handleOnValidate,
                data: { confirmPassword, password },
            },
        } = this;
        return (
            <Grid>
                <GridTemplates.HeadlineTemplate
                    lg
                    center
                    headlineCentered
                    headline={__('reset-password.title')}
                >
                    <BodyCopy center>
                        {__('reset-password.instructions')}
                    </BodyCopy>

                    <form onSubmit={this.handleSubmit}>
                        <Input
                            id="password"
                            type="password"
                            label={__('reset-password.password-label')}
                            value={password}
                            onChange={handleOnChange}
                            placeholder={__(
                                'reset-password.placeholder.password-input',
                            )}
                            onValidate={handleOnValidate}
                            validate={InputValidator.validatePassword}
                        />
                        <Input
                            id="confirmPassword"
                            type="password"
                            label={__('reset-password.confirm-password-label')}
                            value={confirmPassword}
                            onChange={handleOnChange}
                            placeholder={__(
                                'reset-password.placeholder.confirm-password-input',
                            )}
                            onValidate={handleOnValidate}
                            validate={InputValidator.validateConfirmPassword(
                                password,
                            )}
                        />
                        <Button
                            fullWidth
                            rounded
                            type="submit"
                            disabled={hasErrors}
                            loading={isFetching}
                            label={__('reset-password.submit-button.text')}
                        />
                    </form>
                </GridTemplates.HeadlineTemplate>
            </Grid>
        );
    }

    private get isFetching(): boolean {
        const {
            props: { resetStatus },
        } = this;
        return resetStatus === FetchStatus.FETCHING;
    }

    private get hasErrors(): boolean {
        const {
            props: {
                errors: { confirmPassword, password },
            },
        } = this;
        return confirmPassword || password;
    }

    private readonly handleSubmit = (
        e: React.FormEvent<HTMLFormElement>,
    ): void => {
        const {
            props: {
                data: { password },
                resetPassword,
            },
        } = this;
        e.preventDefault();
        resetPassword(password);
    };
}

export { ResetPassword };
