import { createAction, IAction } from 'lib/br-redux';

enum ActionType {
    FETCH_PRODUCTS_LIST = 'SHOP/FETCH_PRODUCTS_LIST',
    FETCH_PRODUCTS_SUCCESS = 'SHOP/FETCH_PRODUCTS_SUCCESS',
    FETCH_PRODUCTS_FAILURE = 'SHOP/FETCH_PRODUCTS_FAILURE',
}

type IFetchProducts = IAction<ActionType.FETCH_PRODUCTS_LIST>;

type IFetchProductsSuccessAction = IAction<
    ActionType.FETCH_PRODUCTS_SUCCESS,
    any
>;

type IReportFetchProductsErrorAction = IAction<
    ActionType.FETCH_PRODUCTS_FAILURE,
    string
>;

type IShopAction =
    | IFetchProducts
    | IFetchProductsSuccessAction
    | IReportFetchProductsErrorAction;

const fetchProducts = createAction(ActionType.FETCH_PRODUCTS_LIST);

const reportFetchProductsSuccess = createAction(
    ActionType.FETCH_PRODUCTS_SUCCESS,
    (products: any) => products,
);
const reportFetchProductsError = createAction(
    ActionType.FETCH_PRODUCTS_FAILURE,
    (err: string) => err,
);

export {
    ActionType,
    IShopAction,
    fetchProducts,
    reportFetchProductsSuccess,
    reportFetchProductsError,
};
