import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./privacy-policy.less');

@CSSModules(styles)
class PrivacyPolicy extends React.Component<{}> {
    render() {
        return <>{(window.location.href = __('routes.privacy'))}</>;
    }
}

export { PrivacyPolicy };
