import * as moment from 'moment';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { ContentWrapper } from 'components/common/content-wrapper';
import { Dots } from 'components/common/dots';
import { Chevron } from 'components/common/graphics';
import { Hidden } from 'components/common/hidden';
import { BodyCopy, ErrorMsg, InfoLink } from 'components/common/typography';
// import {
//     BillDetailsContainer as BillDetails,
//     ButtonContainer as Button,
// } from 'containers';
import { FetchStatus } from 'lib/br-redux';
import {
    ChargeEventType,
    DetailedChargeEvent,
    IModalProps,
    IQueueableModal,
} from 'models';
import { formatMoney } from 'utils';

const styles = require('./bill-history.less');

interface IPropsFromState {
    readonly isDesktop?: boolean;
    readonly fetchStatus: FetchStatus;
    readonly chargeEvents: ReadonlyArray<DetailedChargeEvent>;
}

interface IPropsFromDispatch extends IModalProps {
    fetchBillHistory(): void;
    fetchDetails(eventId: string): void;
    showCPNI(): void;
    showModal(modal: IQueueableModal): void;
}

type Props = IPropsFromState & IPropsFromDispatch & IModalProps;

interface State {
    readonly expandedSection: number;
}

const DEFAULT_STATE: State = {
    expandedSection: -1,
};

@CSSModules(styles, { allowMultiple: true })
class BillHistory extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    componentDidMount() {
        this.props.fetchBillHistory();
    }

    render(): JSX.Element {
        // return (
        //   <ContentWrapper>
        //       <div styleName="bill-history-column-wrapper">
        //           <div styleName="bill-history-column-wrapper-type large">
        //               <h3 styleName="bill-history-column-wrapper-title">
        //                   {__('account.currentStatement.payment')}
        //               </h3>
        //               <p styleName="bill-history-column-wrapper-description">
        //                   {__(
        //                       'account.currentStatement.your-first-payment',
        //                   )}
        //               </p>
        //           </div>
        //
        //           <div styleName="bill-history-column-wrapper-extra">
        //               <h5 styleName="bill-history-column-wrapper-subtitle">
        //                   {__('account.currentStatement.payment-method')}
        //               </h5>
        //               <p styleName="bill-history-column-wrapper-description">
        //                   <Button
        //                       invert
        //                       secondary
        //                       fullWidth
        //                       styleName="button"
        //                       type="submit"
        //                       onClick={this.submitManualPayment}
        //                       label={__(
        //                           'account.currentStatement.change-payment-method',
        //                       )}
        //                   />
        //               </p>
        //           </div>
        //       </div>
        //   </ContentWrapper>
        // )
        return <ContentWrapper>{this.content}</ContentWrapper>;
    }

    private get content(): JSX.Element {
        switch (this.props.fetchStatus) {
            case FetchStatus.ERROR:
                return (
                    <ContentWrapper>
                        <ErrorMsg>{__('not-available-error')}</ErrorMsg>
                    </ContentWrapper>
                );
            case FetchStatus.SUCCESS:
                return this.history;
            default:
                return <Dots invert />;
        }
    }

    private get history(): JSX.Element {
        const {
            props: { isDesktop },
        } = this;
        return (
            <div styleName="billing-history-wrapper">
                {isDesktop ? (
                    <div styleName="history-labels">
                        <div styleName="label">
                            <BodyCopy inline>
                                {__('account.history.date')}
                            </BodyCopy>
                        </div>
                        <div styleName="label">
                            <BodyCopy inline>
                                {__('account.history.amount')}
                            </BodyCopy>
                        </div>
                        <div styleName="label">
                            <BodyCopy inline>
                                {__('account.history.type')}
                            </BodyCopy>
                        </div>
                        <div styleName="label">
                            <BodyCopy inline>
                                {__('account.history.details')}
                            </BodyCopy>
                        </div>
                    </div>
                ) : (
                    <div styleName="history-labels">
                        <div styleName="label">
                            <BodyCopy inline>
                                {__('account.history.date')}
                            </BodyCopy>
                        </div>
                        <div styleName="label">
                            <BodyCopy inline>
                                {__('account.history.amount-and-type')}
                            </BodyCopy>
                        </div>
                        <div styleName="label" />
                    </div>
                )}
                <div styleName="billing-history-list">
                    {this.props.chargeEvents.map(this.chargeEventToListItem)}
                </div>
            </div>
        );
    }

    private readonly chargeEventToListItem = (
        chargeEvent: DetailedChargeEvent,
        idx: number,
    ) => {
        const {
            props: { isDesktop },
        } = this;
        return !isDesktop ? (
            <div
                styleName="billing-history-list-item"
                key={`chargeEvent${idx}`}
                onClick={this.expandBillingHistoryBox(idx)}
            >
                <div styleName="item-section">
                    <div styleName="history-info">
                        <BodyCopy large inline>
                            {moment(chargeEvent.eventtime).format(
                                'MMM D, YYYY',
                            )}
                        </BodyCopy>
                    </div>
                    <div styleName="history-info price">
                        {chargeEvent.eventtype !==
                            ChargeEventType.SERVICE_REFUND && (
                            <BodyCopy inline>
                                {formatMoney(chargeEvent.amount)}
                            </BodyCopy>
                        )}
                        <BodyCopy noMargin inline>
                            {chargeEvent.eventtype}
                        </BodyCopy>
                        <BodyCopy
                            inline
                            error={
                                chargeEvent.details ===
                                ChargeEventType.TRANSACTION_FAILED
                                    ? true
                                    : false
                            }
                        >
                            {this.formatDetails(chargeEvent.details)}
                        </BodyCopy>
                    </div>
                    <div styleName="history-info detail-link">
                        <Hidden
                            until={
                                (chargeEvent.eventtype ===
                                    ChargeEventType.PAYMENT ||
                                    chargeEvent.eventtype ===
                                        ChargeEventType.REFUND ||
                                    chargeEvent.eventtype ===
                                        ChargeEventType.DEVICE_REFUND ||
                                    chargeEvent.eventtype ===
                                        ChargeEventType.INSURANCE_REFUND) &&
                                chargeEvent.eventid !== undefined
                            }
                        >
                            <InfoLink
                                plain
                                to={`${__(
                                    'routes.account.bill-details',
                                ).replace(':eventid', chargeEvent.eventid)}`}
                            >
                                <Chevron blue right />
                            </InfoLink>
                        </Hidden>
                    </div>
                </div>
            </div>
        ) : (
            <div
                styleName="billing-history-list-item"
                key={`chargeEvent${idx}`}
                onClick={this.expandBillingHistoryBox(idx)}
            >
                <div styleName="history-info">
                    <BodyCopy inline>
                        {moment(chargeEvent.eventtime).format('MMM D, YYYY')}
                    </BodyCopy>
                </div>
                <div styleName="history-info">
                    {/* Hide amounts for eventsType of Service Refunds */}
                    {chargeEvent.eventtype !==
                        ChargeEventType.SERVICE_REFUND && (
                        <BodyCopy inline>
                            {formatMoney(chargeEvent.amount)}
                        </BodyCopy>
                    )}
                </div>
                <div styleName="history-info">
                    <BodyCopy inline>{chargeEvent.eventtype}</BodyCopy>
                </div>
                <div styleName="history-info history-details">
                    <div styleName="bill-details">
                        <BodyCopy
                            inline
                            error={
                                chargeEvent.details ===
                                ChargeEventType.TRANSACTION_FAILED
                                    ? true
                                    : false
                            }
                        >
                            {this.formatDetails(chargeEvent.details)}
                        </BodyCopy>
                    </div>
                    <Hidden
                        until={
                            chargeEvent.eventtype !== ChargeEventType.CHARGE &&
                            (chargeEvent.eventtype ===
                                ChargeEventType.PAYMENT ||
                                chargeEvent.eventtype ===
                                    ChargeEventType.REFUND ||
                                chargeEvent.eventtype ===
                                    ChargeEventType.DEVICE_REFUND ||
                                chargeEvent.eventtype ===
                                    ChargeEventType.INSURANCE_REFUND) &&
                            chargeEvent.eventid !== undefined
                        }
                    >
                        <InfoLink
                            plain
                            to={`${__('routes.account.bill-details').replace(
                                ':eventid',
                                chargeEvent.eventid,
                            )}`}
                        >
                            <Chevron blue right />
                        </InfoLink>
                    </Hidden>
                </div>
            </div>
        );
    };

    private readonly expandBillingHistoryBox = (
        clickedIndex: number,
    ): (() => void) => () => {
        if (this.state.expandedSection === clickedIndex) {
            // clicked on the expanded section, close it
            this.setState({ expandedSection: -1 });
        } else {
            // clicked a different section, close the open one, open the new one
            this.setState({ expandedSection: clickedIndex });
        }
    };

    private readonly formatDetails = (details = ''): string => {
        return /^GoodWillReward/.test(details)
            ? __('account.history.reward')
            : details;
    };

    // private readonly showDetails = (
    //     chargeEvent: DetailedChargeEvent,
    // ): (() => void) => {
    //     const { showModal, fetchDetails } = this.props;
    //     return () => {
    //         showModal({
    //             additionalProps: { ...chargeEvent, fetchDetails },
    //             component: BillDetails as any,
    //         });
    //     };
    // };
}

export { BillHistory };
