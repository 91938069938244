import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import * as classNames from 'classnames';

const styles = require('./grid-image.less');

interface Props {
    readonly src: string | null | undefined;
    readonly imageMobile?: boolean;
    readonly showInMobile?: boolean;
    readonly fullWidth?: boolean;
}

const GridImageComponent = ({
    src,
    imageMobile,
    showInMobile,
    fullWidth,
    ...props
}: Props) =>
    src ? (
        <div
            styleName={classNames(
                'grid-image',
                { imageMobile },
                { showInMobile },
                { fullWidth },
            )}
        >
            <div
                styleName={'grid-image-img'}
                style={{ backgroundImage: `url('${src}')` }}
            />
        </div>
    ) : null;

const GridImage = CSSModules(GridImageComponent, styles, {
    allowMultiple: true,
});
export { GridImage };
