import { IDefaultAction } from 'lib/br-redux';
import { ActionType, IAnalyticsAction } from './tealium.actions';

interface IAnalyticsState {
    readonly eventQueue: any;
}

const DEFAULT_STATE: IAnalyticsState = {
    eventQueue: [],
};

const { PUSH_EVENT_QUEUE, POP_EVENT_QUEUE } = ActionType;

const analyticsReducer = (
    state: IAnalyticsState = DEFAULT_STATE,
    action: IAnalyticsAction | IDefaultAction,
): IAnalyticsState => {
    switch (action.type) {
        case PUSH_EVENT_QUEUE:
            return {
                ...state,
                eventQueue: [...state.eventQueue, action.payload],
            };

        case POP_EVENT_QUEUE:
            return {
                ...state,
                eventQueue: state.eventQueue.filter(
                    event => event !== action.payload,
                ),
            };
        default:
            return state;
    }
};

export { IAnalyticsState, analyticsReducer, DEFAULT_STATE };
