import { createAction, IAction } from 'lib/br-redux';
import { PaymentMethod } from 'models';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    GET_DEFAULT_PAYMENT_METHOD = 'PAYMENT/GET_DEFAULT_PAYMENT_METHOD',
    LOAD_DEFAULT_PAYMENT_METHOD = 'PAYMENT/LOAD_DEFAULT_PAYMENT_METHOD',
    REPORT_DEFAULT_PAYMENT_METHOD_ERROR = 'PAYMENT/REPORT_DEFAULT_PAYMENT_METHOD_ERROR',
    UPDATE_DEFAULT_PAYMENT_METHOD = 'PAYMENT/UPDATE_DEFAULT_PAYMENT_METHOD',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type IGetDefaultPaymentMethodAction = IAction<
    ActionType.GET_DEFAULT_PAYMENT_METHOD
>;
type ILoadDefaultPaymentMethodAction = IAction<
    ActionType.LOAD_DEFAULT_PAYMENT_METHOD,
    PaymentMethod
>;
type IReportIGetDefaultPaymentMethodErrorAction = IAction<
    ActionType.REPORT_DEFAULT_PAYMENT_METHOD_ERROR,
    string
>;
type IUpdateDefaultPaymentMethodAction = IAction<
    ActionType.UPDATE_DEFAULT_PAYMENT_METHOD,
    string
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IDefaultPaymentAction =
    | IGetDefaultPaymentMethodAction
    | ILoadDefaultPaymentMethodAction
    | IReportIGetDefaultPaymentMethodErrorAction
    | IUpdateDefaultPaymentMethodAction;

const getDefaultPaymentMethod = createAction(
    ActionType.GET_DEFAULT_PAYMENT_METHOD,
);
const loadDefaultPaymentMethod = createAction(
    ActionType.LOAD_DEFAULT_PAYMENT_METHOD,
    (paymentMethod: PaymentMethod) => ({ paymentMethod }),
);
const reportDefaultPaymentMethodError = createAction(
    ActionType.REPORT_DEFAULT_PAYMENT_METHOD_ERROR,
    (er: string) => er,
);

const updateDefaultPaymentMethod = createAction(
    ActionType.UPDATE_DEFAULT_PAYMENT_METHOD,
    (nonce: string) => nonce,
);

export {
    ActionType,
    IDefaultPaymentAction,
    IGetDefaultPaymentMethodAction,
    ILoadDefaultPaymentMethodAction,
    IReportIGetDefaultPaymentMethodErrorAction,
    getDefaultPaymentMethod,
    loadDefaultPaymentMethod,
    reportDefaultPaymentMethodError,
    IUpdateDefaultPaymentMethodAction,
    updateDefaultPaymentMethod,
};
