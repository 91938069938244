import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics/index';
import { Circle, FilledCircle } from 'components/common/graphics/circle-check';
import { BodyCopy } from 'components/common/typography';

const styles = require('./radio-button.less');

interface Props extends IAnalyticsProps {
    readonly children?: any;
    readonly selected: boolean;
    readonly metric?: any;
    onClick(e: React.MouseEvent<HTMLButtonElement>): any;
}

@CSSModules(styles, { allowMultiple: true })
class RadioButton extends React.Component<Props> {
    render() {
        const {
            props: { children, selected, onClick },
        } = this;
        return (
            <button
                styleName={['radio-button', selected ? 'selected' : ''].join(
                    ' ',
                )}
                onClick={event => {
                    onClick(event);
                }}
            >
                <BodyCopy blue large>
                    {selected ? <FilledCircle /> : <Circle />}
                </BodyCopy>
                <BodyCopy>{children}</BodyCopy>
            </button>
        );
    }
}

export { RadioButton };
