import { withAnalytics } from 'components/common/analytics';
import { SignIn } from 'components/sign-in';
import { FetchStatus } from 'lib/br-redux';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    authenticateWithEmailAndPassword,
    clearAuthenticationErrors,
} from 'state/authentication/authentication.actions';
import { checkCart } from '../state/account/account.actions';
import { push } from 'react-router-redux';

const mapStateToProps = (state: IApplicationState) => ({
    error: state.authentication.error,
    fetchStatus: state.authentication.fetchStatus,
    isSignedIn: state.authentication.fetchStatus === FetchStatus.SUCCESS,
    bagCount: state.account.bagCount,
    customerStatus: state.account.customerState,
    billFetchStatus: state.currentBill.fetchStatus,
    statusTransition: state.account.statusTransition,
});

const mapDispatchToProps = dispatch => ({
    clearAuthenticationErrors: () => dispatch(clearAuthenticationErrors()),
    redirectTo: (url: string) => dispatch(push(url)),
    signIn: (email, password, rememberMe) =>
        dispatch(
            authenticateWithEmailAndPassword({
                email,
                password,
                rememberMe,
            }),
        ),
    checkCart: () => dispatch(checkCart()),
});

const enhance = compose(
    withAnalytics,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);

const SignInContainer = enhance(SignIn);

export { SignInContainer };
