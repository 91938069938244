import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
require('./wipe-transition.less');

interface Props {
    readonly active: boolean;
    readonly timeout?: number;
}

const WipeTransition = ({ active, timeout }: Props) => (
    <CSSTransition
        appear
        classNames="wipe"
        timeout={timeout || 1000}
        mountOnEnter
        unmountOnExit
        in={active}
    >
        <span className="wipe blue-wipe" />
    </CSSTransition>
);

export { WipeTransition };
