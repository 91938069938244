import { AutopayConfirm } from 'components/account/autopay-confirm';
import { withAnalytics } from 'components/common/analytics';
import { withDeviceType } from 'components/common/device-type';
import { withModal } from 'components/common/modal/withModal';
import { compose } from 'redux';

const enhance = compose(
    withAnalytics,
    withModal,
    withDeviceType,
);
const AutopayConfirmContainer = enhance(AutopayConfirm);

export { AutopayConfirmContainer };
