import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

interface Props extends React.HTMLProps<HTMLHRElement> {
    readonly medium?: boolean;
    readonly dotted?: boolean;
    readonly shadow?: boolean;
    readonly small?: boolean;
    readonly custom?: boolean;
    readonly margin25?: boolean;
    readonly margin20?: boolean;
    readonly margin60?: boolean;
    readonly dashed?: boolean;
    readonly mobileMargin20?: boolean;
    readonly margin40?: boolean;
    readonly margin30?: boolean;
}

const DividerComponent = ({
    dotted,
    medium,
    shadow,
    small,
    custom,
    margin25,
    margin20,
    margin60,
    dashed,
    mobileMargin20,
    margin40,
    margin30,
}: Props) => {
    return (
        <hr
            styleName={classNames('divider', {
                dotted,
                medium,
                shadow,
                small,
                custom,
                margin25,
                margin20,
                margin60,
                dashed,
                mobileMargin20,
                margin40,
                margin30,
            })}
        />
    );
};
const Divider = CSSModules(require('./divider.less'), { allowMultiple: true })(
    DividerComponent,
);
export { Divider };
