import {
    ICartItemPayload,
    ICheckoutPayload,
    IConfigurationBasePaths,
    PurchaseType,
} from 'models';
import { ThinAirService } from './thin-air.service';

class CartService extends ThinAirService {
    static async getCartId(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
    ): Promise<string> {
        const { cart_id } = await CartService.fetchJson<any>(
            `${CartService.BASE_URL}${basePaths.cart}?purchase_type=BYOD`,
            {
                headers: CartService.getDefaultHeaders(firebaseToken),
            },
        );

        return cart_id;
    }

    static async addItem(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        cartId: string,
        item: ICartItemPayload,
    ): Promise<void> {
        await CartService.fetchJson(
            `${CartService.BASE_URL}${basePaths.cart}/${cartId}/additem`,
            {
                body: JSON.stringify([item]),
                headers: CartService.getDefaultHeaders(firebaseToken),
                method: 'POST',
            },
        );

        return;
    }

    static async deleteItem(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        cartId: string,
        itemId: string,
        isByod: boolean,
    ): Promise<any> {
        return await CartService.fetchJson(
            `${CartService.BASE_URL}${
                basePaths.cart
            }/${cartId}/item?itemid=${itemId}&purchase_type=${
                isByod ? PurchaseType.BYOD : PurchaseType.DEVICE_PURCHASE
            }`,
            {
                headers: CartService.getDefaultHeaders(firebaseToken),
                method: 'DELETE',
            },
        );
    }

    static async deleteOrder(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        cartId: string,
    ): Promise<any> {
        return await CartService.fetchJson(
            `${CartService.BASE_URL}${
                basePaths.cart
            }/integration/DeleteOrder_DeleteOrder`,
            {
                body: JSON.stringify({ cartId }),
                headers: CartService.getDefaultHeaders(firebaseToken),
                method: 'POST',
            },
        );
    }

    static async checkout(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        cartId: string,
        checkoutPayload: ICheckoutPayload,
    ): Promise<void> {
        const headers = CartService.getDefaultHeaders(firebaseToken);

        const response = await fetch(
            `${CartService.BASE_URL}${basePaths.cart}/${cartId}/checkout`,
            {
                body: JSON.stringify(checkoutPayload),
                headers,
                method: 'POST',
            },
        );

        if (response.status < 200 || response.status > 299) {
            throw new Error(await ThinAirService.parseErrorOrDefault(response));
        }

        return;
    }
}

export { CartService };
