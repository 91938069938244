import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Link, LinkProps } from 'react-router-dom';

const styles = require('./info-link.less');

interface Props extends Partial<LinkProps> {
    readonly element?: string;
    readonly plain?: boolean;
    readonly fullWidth?: boolean;
    readonly fontCircular?: boolean;
    readonly mobileFont?: boolean;
}

@CSSModules(styles, { allowMultiple: true })
class InfoLink extends React.Component<Props> {
    render() {
        const {
            props: {
                children,
                element,
                to = '',
                plain,
                fullWidth,
                fontCircular,
                mobileFont,
                ...props
            },
        } = this;
        return React.createElement(
            to ? Link : element || 'span',
            {
                styleName: classNames('info-link', {
                    plain,
                    fullWidth,
                    fontCircular,
                    mobileFont,
                }),
                to,
                ...props,
            },
            children,
        );
    }
}

export { InfoLink };
