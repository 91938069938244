import { createAction, IAction } from 'lib/br-redux';

enum ActionType {
    SELECT_PAYMENT_METHOD = 'PAYMENT/PAYMENT_NONCE/SELECT_PAYMENT_METHOD',
    CLEAR_PAYMENT_METHOD = 'PAYMENT/PAYMENT_NONCE/CLEAR_PAYMENT_METHOD',
}

type ISelectPaymentMethodAction = IAction<
    ActionType.SELECT_PAYMENT_METHOD,
    string
>;
type IClearPaymentMethodAction = IAction<ActionType.CLEAR_PAYMENT_METHOD>;

type IPaymentNonceAction =
    | ISelectPaymentMethodAction
    | IClearPaymentMethodAction;

const selectPaymentMethod = createAction(
    ActionType.SELECT_PAYMENT_METHOD,
    (nonce: string) => nonce,
);
const clearPaymentMethod = createAction(ActionType.CLEAR_PAYMENT_METHOD);

export {
    ActionType,
    ISelectPaymentMethodAction,
    IClearPaymentMethodAction,
    IPaymentNonceAction,
    selectPaymentMethod,
    clearPaymentMethod,
};
