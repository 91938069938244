import { IConfigurationBasePaths } from 'models';
import * as moment from 'moment';
import { ThinAirService } from './thin-air.service';

interface FeedbackRequest {
    readonly Source: 'Web';
    readonly Description: string;
    readonly User: string;
    readonly Timestamp: string;
}

class FeedbackService extends ThinAirService {
    static async submitFeedback(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        Description: string,
        User: string,
    ): Promise<any> {
        return fetch(`${FeedbackService.BASE_URL}${basePaths.feedbacks}`, {
            body: JSON.stringify({
                Description,
                Source: 'Web',
                Timestamp: moment().toISOString(),
                User,
            } as FeedbackRequest),
            headers: FeedbackService.getDefaultHeaders(firebaseToken),
            method: 'POST',
        });
    }
}

export { FeedbackService };
