import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./dots.less');

const whiteDots = '/assets/images/loading-indicators/white-loading-dots.gif';
const blueDots = '/assets/images/loading-indicators/blue-loading-dots.gif';

interface Props {
    readonly invert?: boolean;
}

@CSSModules(styles)
class Dots extends React.Component<Props> {
    render() {
        const {
            props: { invert },
        } = this;
        return <img styleName="dots" src={invert ? blueDots : whiteDots} />;
    }
}

export { Dots };
