import { createAction, IAction } from 'lib/br-redux';

enum ActionType {
    FETCH_MDN = 'PURCHASE/MDN/FETCH_MDN',
    LOAD_MDN = 'PURCHASE/MDN/LOAD_MDN',
    REPORT_MDN_ERROR = 'PURCHASE/MDN/REPORT_MDN_ERROR',
    CLEAR_MDN = 'PURCHASE/MDN/CLEAR_MDN',
}

const fetchMdn = createAction(ActionType.FETCH_MDN, (npaNxx: string) => npaNxx);

const loadMdn = createAction(ActionType.LOAD_MDN, (mdn: string) => mdn);

const reportMdnError = createAction(
    ActionType.REPORT_MDN_ERROR,
    (err: string) => err,
);

const clearMdn = createAction(ActionType.CLEAR_MDN);

type IFetchMdnAction = IAction<ActionType.FETCH_MDN, string>;
type ILoadMdnAction = IAction<ActionType.LOAD_MDN, string>;
type IReportMdnErrorAction = IAction<ActionType.REPORT_MDN_ERROR, string>;
type IClearMDNAction = IAction<ActionType.CLEAR_MDN>;

type IMdnAction =
    | IFetchMdnAction
    | ILoadMdnAction
    | IReportMdnErrorAction
    | IClearMDNAction;

export {
    ActionType,
    fetchMdn,
    loadMdn,
    reportMdnError,
    IFetchMdnAction,
    ILoadMdnAction,
    IReportMdnErrorAction,
    IMdnAction,
    clearMdn,
};
