import { FetchStatus } from 'lib/br-redux';

interface SummaryChargeEvent {
    readonly eventid: string;
    readonly eventtime: string;
    readonly eventtype: ChargeEventType;
    readonly amount: number; // "amount" in Swagger
    readonly details?: string;
    readonly transactionid?: string;
    readonly paymentmethodtype?: string;
    readonly paymentmethoddetails?: string;
    readonly reasoncode?: string;
    readonly orderid?: string;
}

enum ChargeEventType {
    CHARGE = 'Charge',
    CREDIT = 'Credit',
    PAYMENT = 'Payment',
    REFUND = 'Refund',
    SERVICE_REFUND = 'Service Refund',
    INSURANCE_REFUND = 'Insurance refund',
    DEVICE_REFUND = 'Device refund',
    TRANSACTION_FAILED = 'Transaction Failed',
    UNKNOWN = '',
}

interface DetailedChargeEvent extends SummaryChargeEvent {
    readonly detailStatement:
        | UnfetchedChargeEventDetails
        | FetchedChargeEventDetails;
}

interface UnfetchedChargeEventDetails {
    readonly fetchStatus:
        | FetchStatus.NOT_FETCHED
        | FetchStatus.FETCHING
        | FetchStatus.ERROR;
    readonly error: string;
}

interface FetchedChargeEventDetailsResponse {
    readonly msgID: string;
    readonly customerType?: string;
    readonly planID?: string;
    readonly grossPrice: string;
    readonly discountPrice: string;
    readonly geocode?: string;
    readonly taxTreatment?: string;
    readonly classCode?: string;
    readonly totalTaxAmount: string;
    readonly totalFeeAmount: string;
    readonly totalFeeAmout: string; // cover for a server side typo
    readonly totalNetRevenue: string;
    readonly redeemedCredits: string;
    readonly modifiedDisplayNetRevenue?: string;
    readonly sumTaxItemList: ReadonlyArray<TaxItem>;
    readonly gl_Visible_Device_Extended_Warranty_Offer: string;
    readonly gl_Visible_Device_Insurance_Offer: string;
    readonly displayName: string;
    readonly totalMembersInGroup: number;
    readonly redeemedPromoCredits: string;
    readonly redeemedReferralCredits: string;
    readonly redeemedGroupCredits: string;
    readonly redeemedGoodWillCredits: string;
    readonly referralCredits: number;
}

interface FetchedChargeEventDetails /* extends FetchedChargeEventDetailsResponse */ {
    readonly fetchStatus: FetchStatus.SUCCESS;
    readonly details: ReadonlyArray<FetchedChargeEventDetailsResponse>;
}

interface TaxItem {
    readonly sumTaxItemType: string;
    readonly sumTaxType: string;
    readonly sumDescription: string;
    readonly sumTaxAmount: string;
}

interface IBillHistoryResponse {
    readonly charge_event: ReadonlyArray<SummaryChargeEvent>;
}

interface INextDueDate {
    readonly due_date: string;
    readonly charge_amount: number;
    readonly enableManualPay: boolean;
    readonly accountBalance: number;
    readonly redeemedCredits: number;
    readonly totalAmount: number;
    readonly totalMembersInGroup: number;
    readonly groupCredits: number;
    readonly referralCredits: number;
    readonly goodwillCredits: number;
}

export {
    SummaryChargeEvent,
    IBillHistoryResponse,
    INextDueDate,
    TaxItem,
    DetailedChargeEvent,
    FetchedChargeEventDetailsResponse,
    FetchedChargeEventDetails,
    UnfetchedChargeEventDetails,
    ChargeEventType,
};
