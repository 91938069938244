import * as moment from 'moment';

import {
    AlertModalContainer as AlertModal,
    NoticeContainer as Notice,
    ReauthenticationModalContainer as ReauthenticationModal,
    UpdateAddressModalContainer as UpdateAddressModal,
    UpdateEmailModalContainer as UpdateEmailModal,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';
import {
    Account,
    CustomerState,
    IAccount,
    IActivateAccountPayload,
    IApplicationState,
    ICommissionUpdateRequest,
    INonNormalizedAccount,
    IReferrerRequest,
    IReferrerUpdateRequest,
} from 'models';
import { push } from 'react-router-redux';
import { delay } from 'redux-saga';
import {
    all,
    call,
    put,
    race,
    select,
    take,
    takeEvery,
} from 'redux-saga/effects';
import { AccountService, ReferralService } from 'services';
import { IAccountState } from 'state/account/account.reducer';
import { cancelSaga } from 'state/account/cancel';
import { pauseSaga } from 'state/account/pause';
import { AuthenticationActions } from 'state/authentication';
import { fetchCurrentBill } from 'state/bill/bill.saga';
import {
    getCurrentUser,
    getToken as getFirebaseToken,
    resetPasswordForEmail,
    updateDisplayName as updateFirebaseDisplayName,
    updateEmailAddress as updateFirebaseEmailAddress,
} from 'state/firebase/firebase.saga';
import { showPayNow } from 'state/payment/lapsed-payment/lapsed-payment.actions';
import { eventLink, trackError } from 'state/tealium';
import {
    dismissNotice,
    showMessage,
    showModal,
    showNotice,
} from 'state/view/view.actions';
import { confirmWithModal } from 'state/view/view.saga';
import { formatMoney } from 'utils';
import { smellyStoreSingleton } from 'utils/store';
import {
    ActionType,
    IResetPasswordForEmailAction,
    IShowUpdateEmailAction,
    IUpdateEmailAddressAction,
    IUpdateUserAccountAction,
    IReportCartAddItems,
    loadUserAccount,
    reportActivateAccountError,
    reportActivateAccountSuccess,
    reportPrivacyOptInOutError,
    reportPrivacyOptInOutSuccess,
    reportResetPasswordForEmailError,
    reportResetPasswordForEmailSuccess,
    reportShowUpdateEmailDone,
    reportUpdateEmailError,
    reportUpdateEmailSuccess,
    reportUpdateUserAccountError,
    reportUpdateUserAccountSuccess,
    resetUpdateAccountFetchStatus,
    SetPrivacyOptInOutAction,
    showUpdateEmail,
    checkCartSuccess,
    addItemsSuccess,
    addItemsFailure,
    updateUserAccountOld,
} from './account.actions';
import { fetchConfiguration } from 'state/configuration/configuration.saga';
import {
    dismissLoadingIndicator,
    requestLoadingIndicator,
} from 'state/view/view.actions';

import * as chatSAGA from 'state/chat/chat.saga';

function* activateAccountWithReferralCode(account: IAccount) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getFirebaseToken);
        const activateAccountPayload: IActivateAccountPayload = {
            campaignId: account.campaignId,
            email: account.emailAddress,
            firstName: account.firstName,
            imei: account.imei,
            lastName: account.lastName,
            newUid: account.accountid,
            transactionId: account.transactionId,
        };
        yield call(
            AccountService.activateAccount,
            basePaths,
            firebaseToken,
            activateAccountPayload,
        );
        const commissionUpdateRequest: ICommissionUpdateRequest = {
            campaign_uid: account.campaignId,
            is_approved: '1',
            transaction_uid: account.transactionId,
        };
        yield call(
            ReferralService.updateCommission,
            basePaths,
            commissionUpdateRequest,
        );
        const referrerUpdateRequest: IReferrerUpdateRequest = {
            custom1: account.imei,
            email: account.emailAddress,
            first_name: account.firstName,
            is_deactivated: 0,
            last_name: account.lastName,
            new_uid: account.accountid,
            sandbox: 0,
            send_welcome_email: 0,
            set_groups: '1',
        };
        yield call(
            ReferralService.updateReferrer,
            basePaths,
            referrerUpdateRequest,
        );
        yield put(reportActivateAccountSuccess(''));
    } catch (err) {
        yield put(reportActivateAccountError((err as Error).message));
    }
}

function* activateAccountWithoutReferralCode(account: IAccount) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getFirebaseToken);
        const activateAccountPayload: IActivateAccountPayload = {
            email: account.emailAddress,
            firstName: account.firstName,
            imei: account.imei,
            lastName: account.lastName,
            uid: account.accountid,
        };
        yield call(
            AccountService.activateAccount,
            basePaths,
            firebaseToken,
            activateAccountPayload,
        );
        const referrerRequest: IReferrerRequest = {
            add_to_group_id: '1',
            custom1: account.imei,
            email: account.emailAddress,
            email_new_ambassador: '0',
            first_name: account.firstName,
            last_name: account.lastName,
            sandbox: '0',
            uid: account.accountid,
        };
        yield call(
            ReferralService.getOrCreateReferrer,
            basePaths,
            referrerRequest,
        );
        yield put(reportActivateAccountSuccess(''));
    } catch (err) {
        yield put(reportActivateAccountError((err as Error).message));
    }
}

function* activateAccount() {
    const account = yield select((state: IApplicationState) => state.account);
    if (account.refCodeApplied) {
        activateAccountWithReferralCode(account);
    } else {
        activateAccountWithoutReferralCode(account);
    }
}

function emailVerifyNotice(
    userState: IAccountState,
    sent: boolean = false,
): any {
    const { dispatch } = smellyStoreSingleton.store;
    return {
        additionalProps: {
            actionOne: () => {
                dispatch(
                    eventLink({
                        event_category: 'link_click',
                        link_name: __('account.resend-email-metrics'),
                    }),
                );
                dispatch(AuthenticationActions.resendVerificationEmail());
            },
            actionOneDisabled: sent,
            actionOneLabel: sent
                ? __('account.notice.email.actionOneLabelSent')
                : __('account.notice.email.actionOneLabel'),
            actionTwo: () => {
                dispatch(
                    eventLink({
                        event_category: 'link_click',
                        link_name: __('account.edit-email-metrics'),
                    }),
                );
                dispatch(showUpdateEmail());
            },
            actionTwoLabel: __('account.notice.email.actionTwoLabel'),
            body: __('account.notice.email.body').replace(
                '${email}',
                userState.emailAddress,
            ),
            header: __('account.notice.email.header'),
            title: __('account.notice.email.title'),
        },
        component: Notice,
    };
}

function accountLapsedNotice(chargeAmount: number, dueDate: string): any {
    const { dispatch } = smellyStoreSingleton.store;
    let header = __('account.notice.lapsed.header')
        .replace('${amount}', formatMoney(chargeAmount))
        .replace('${date}', moment(dueDate).format('MMMM DD'));
    sessionStorage.setItem('noticeDetails', __('account.notice.lapsed.body'));
    sessionStorage.setItem('noticeToShow', header);
    sessionStorage.setItem(
        'actionBtnTitle',
        __('account.notice.lapsed.actionOneLabel'),
    );
    return {
        additionalProps: {
            actionOne: () => {
                dispatch(showPayNow(chargeAmount));
            },
            actionOneLabel: __('account.notice.lapsed.actionOneLabel'),
            body: __('account.notice.lapsed.body'),
            error: true,
            header: header,
            title: __('account.notice.lapsed.title'),
        },
        component: Notice,
    };
}

function accountSuspendedModal() {
    // const { dispatch } = smellyStoreSingleton.store;
    return {
        additionalProps: {
            onConfirm: () => {
                call(delay, 100);
                chatSAGA.resetChatInit();
            },
            body: __('account.notice.suspended.body'),
            buttonText: __('account.notice.suspended.actionTwoLabel'),
            error: true,
            title: __('account.notice.suspended.title'),
        },
        component: AlertModal,
    };
}

function accountTerminatedNotice() {
    sessionStorage.setItem(
        'noticeDetails',
        __('account.notice.terminated.body'),
    );
    sessionStorage.setItem(
        'noticeToShow',
        __('account.notice.terminated.header'),
    );
    return {
        additionalProps: {
            // tslint:disable-next-line:no-empty
            body: __('account.notice.terminated.body'),
            header: __('account.notice.terminated.header'),
            title: __('account.notice.terminated.title'),
        },
        component: Notice,
    };
}

function accountReactivatingNotice(): any {
    return {
        additionalProps: {
            body: __('account.notice.lapsedReactivating.body'),
            header: __('account.notice.lapsedReactivating.header'),
            title: __('account.notice.lapsedReactivating.title'),
        },
        component: Notice,
    };
}

function accountFailedPaymentNotice(chargeAmount: number): any {
    const { dispatch } = smellyStoreSingleton.store;

    sessionStorage.setItem(
        'noticeDetails',
        __('account.notice.failedPayment.body'),
    );
    sessionStorage.setItem(
        'noticeToShow',
        __('account.notice.failedPayment.header'),
    );
    sessionStorage.setItem(
        'actionBtnTitle',
        __('account.notice.failedPayment.buttonLabel'),
    );
    return {
        additionalProps: {
            actionOne: () => {
                dispatch(showPayNow(chargeAmount));
            },
            actionOneLabel: __('account.notice.failedPayment.buttonLabel'),
            body: __('account.notice.failedPayment.body'),
            error: true,
            header: __('account.notice.failedPayment.header'),
            title: __('account.notice.failedPayment.title'),
        },
        component: Notice,
    };
}

function accountLapsedNoBillingInfoNotice(): any {
    if (sessionStorage.getItem('noticeToShow') === null) {
        sessionStorage.setItem(
            'noticeToShow',
            __('account.notice.lapsedNoBillingInfo.header'),
        );
    }
    return {
        additionalProps: {
            header: __('account.notice.lapsedNoBillingInfo.header'),
            title: __('account.notice.lapsedNoBillingInfo.title'),
        },
        component: Notice,
    };
}

function* accountLapsed() {
    try {
        const { statusTransition, paymentFailure } = yield select(
            ({ account }: IApplicationState) => account,
        );
        if (String(statusTransition).toLowerCase() === 'active') {
            yield put(showNotice(accountReactivatingNotice()));
            yield call(pollLapsed);
        } else {
            const [didFetchBill] = yield call(fetchCurrentBill);
            if (didFetchBill) {
                const currentBill = yield select(
                    ({ bill }: IApplicationState) => bill.currentBill,
                );
                if (paymentFailure) {
                    yield put(
                        showNotice(
                            accountFailedPaymentNotice(
                                currentBill.chargeAmount,
                            ),
                        ),
                    );
                } else {
                    yield put(
                        showNotice(
                            accountLapsedNotice(
                                currentBill.chargeAmount,
                                currentBill.dueDate,
                            ),
                        ),
                    );
                }
            } else {
                yield put(showNotice(accountLapsedNoBillingInfoNotice()));
            }
        }
    } catch (err) {
        // TODO
        // tslint:disable-next-line:no-console
        console.error('error at accountLapsed');
        // tslint:disable-next-line:no-console
        console.error(err);
    }
}

function* accountSuspended() {
    yield put(showModal(accountSuspendedModal()));
}

function* accountTerminated() {
    yield put(showNotice(accountTerminatedNotice()));
}

function* pollLapsedReactivation() {
    yield race({
        cancel: take(ActionType.STOP_POLL_LAPSED_REACTIVATION),
        run: call(pollLapsed),
    });
}

function* pollLapsed() {
    while (true) {
        try {
            yield call(refreshAccount);
            const customerState = yield select(
                ({ account }: IApplicationState) => account.customerState,
            );
            if (customerState !== CustomerState.LAPSED) {
                yield put(dismissNotice());
                return;
            }
            // Continue to poll.
        } catch (err) {
            /* continue */
        }
        yield call(delay, 5000);
    }
}

function* resetPassword(action: IResetPasswordForEmailAction) {
    try {
        yield* resetPasswordForEmail(action.payload);
        yield put(
            reportResetPasswordForEmailSuccess(
                "We've emailed you a link to change your password.",
            ),
        );
    } catch (err) {
        yield put(reportResetPasswordForEmailError((err as Error).message));
    }
}

function* showUpdateEmailModal(action: IShowUpdateEmailAction) {
    const didConfirmReauthentication = yield call(confirmWithModal, {
        additionalProps: {},
        component: ReauthenticationModal,
    });
    // Must re-auth to continue
    if (!didConfirmReauthentication) {
        yield put(reportShowUpdateEmailDone());
        return;
    }
    const didConfirmUpdateEmail = yield call(confirmWithModal, {
        additionalProps: {},
        component: UpdateEmailModal,
    });
    yield put(reportShowUpdateEmailDone());
    if (!didConfirmUpdateEmail) {
        return;
    }
    yield put(dismissNotice());
    const userState = yield select((state: IApplicationState) => state.account);
    const notice: any = emailVerifyNotice(userState);
    notice.additionalProps.actionOneLabel = __(
        'account.notice.email.actionOneLabelSent',
    );
    yield put(showNotice(notice));
}

function* updateSentEmailVerifyNoticy(action: IShowUpdateEmailAction) {
    const userState = yield select((state: IApplicationState) => state.account);

    yield put(dismissNotice());
    yield put(showNotice(emailVerifyNotice(userState, true)));
    yield call(delay, 5000);
    const isAuthenticated = yield select(
        ({ authentication: { fetchStatus } }: IApplicationState) =>
            fetchStatus === FetchStatus.SUCCESS,
    );
    if (isAuthenticated) {
        yield put(dismissNotice());
        yield put(showNotice(emailVerifyNotice(userState)));
    }
}

function* updateEmailAddress(action: IUpdateEmailAddressAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getFirebaseToken);
        const fbUser = yield call(getCurrentUser);
        yield call(updateFirebaseEmailAddress, action.payload);

        const updatedAccount: INonNormalizedAccount = yield call(
            AccountService.updateAccount as any,
            basePaths,
            firebaseToken,
            {
                email_address: action.payload,
            },
        );

        yield put(loadUserAccount(Account.Normalize(updatedAccount, fbUser)));
        yield put(reportUpdateEmailSuccess());
    } catch (err) {
        yield put(reportUpdateEmailError((err as Error).message));
    }
}

function* updateAccount(action: IUpdateUserAccountAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getFirebaseToken);
        const fbUser = yield call(getCurrentUser);
        const userState = yield select(
            (state: IApplicationState) => state.account,
        );

        const { email_address } = action.payload;
        if (
            email_address &&
            email_address.trim() &&
            email_address !== userState.email_address
        ) {
            yield call(updateFirebaseEmailAddress, email_address);
        }

        const { first_name } = action.payload;
        if (
            first_name &&
            first_name.trim() &&
            first_name !== userState.first_name
        ) {
            yield call(updateFirebaseDisplayName, first_name);
        }

        const updatedAccount: INonNormalizedAccount = yield call(
            AccountService.updateAccount as any,
            basePaths,
            firebaseToken,
            action.payload,
        );

        yield put(loadUserAccount(Account.Normalize(updatedAccount, fbUser)));
        yield put(AuthenticationActions.reportAuthenticationSuccess());
        yield put(
            reportUpdateUserAccountSuccess('Profile successfully updated!'),
        );
        yield put(showMessage('Profile updated!'));
        yield put(resetUpdateAccountFetchStatus());
    } catch (err) {
        // yield put(reportUpdateEmailError((err as Error).message));
        const userState = yield select(
            (state: IApplicationState) => state.account,
        );
        yield put(AuthenticationActions.reportAuthenticationSuccess());
        yield put(loadUserAccount(Account.Deserialize(userState)));
        yield put(reportUpdateUserAccountError((err as Error).message));
    }
}

function* updateAccountOld(action: IUpdateUserAccountAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getFirebaseToken);
        const fbUser = yield call(getCurrentUser);
        const userState = yield select(
            (state: IApplicationState) => state.account,
        );

        const { email_address } = action.payload;
        if (
            email_address &&
            email_address.trim() &&
            email_address !== userState.email_address
        ) {
            yield call(updateFirebaseEmailAddress, email_address);
        }

        const { first_name } = action.payload;
        if (
            first_name &&
            first_name.trim() &&
            first_name !== userState.first_name
        ) {
            yield call(updateFirebaseDisplayName, first_name);
        }

        const updatedAccount: INonNormalizedAccount = yield call(
            AccountService.updateAccountOld as any,
            basePaths,
            firebaseToken,
            action.payload,
        );
        if (
            updatedAccount &&
            updatedAccount.isAddressSuggested &&
            updatedAccount.address
        ) {
            yield call(updateSuggestedAddress, action, updatedAccount);
        } else {
            yield put(
                loadUserAccount(Account.Normalize(updatedAccount, fbUser)),
            );
            yield put(AuthenticationActions.reportAuthenticationSuccess());
            yield put(
                reportUpdateUserAccountSuccess('Profile successfully updated!'),
            );
            yield put(showMessage('Profile updated!'));
            yield put(resetUpdateAccountFetchStatus());
        }
    } catch (err) {
        yield call(openErrorModal, action, err);
    }
}

function* openErrorModal(action: IUpdateUserAccountAction, errorCode: object) {
    if (action && action.payload && action.payload.address) {
        let addressObj = {};
        if (errorCode['message'] === '400.12') {
            addressObj = {
                enteredAddress: action.payload.address.shipping_address,
                suggestedAddress: '',
            };
        } else {
            addressObj = {
                enteredAddress: action.payload.address.billing_address,
                suggestedAddress: '',
            };
        }
        yield call(confirmWithModal, {
            additionalProps: {
                buttonText: 'Continue',
                closable: true,
                title:
                    errorCode['message'] === '400.12'
                        ? 'Billing and Shipping address verification.'
                        : 'Service address verification.',
                addressAlert: true,
                addressObj,
            },
            component: UpdateAddressModal,
        });
    }
}

function* updateSuggestedAddress(
    action: IUpdateUserAccountAction,
    suggestedAddress: INonNormalizedAccount,
) {
    if (
        action &&
        action.payload &&
        action.payload.address &&
        suggestedAddress &&
        suggestedAddress.address
    ) {
        let addressObj = {};
        if (suggestedAddress.address.shipping_address) {
            addressObj = {
                enteredAddress: action.payload.address.shipping_address,
                suggestedAddress: suggestedAddress.address.shipping_address,
            };
        } else {
            addressObj = {
                enteredAddress: action.payload.address.billing_address,
                suggestedAddress: suggestedAddress.address.billing_address,
            };
        }
        const ok = yield call(confirmWithModal, {
            additionalProps: {
                buttonText: 'Continue',
                closable: true,
                title: suggestedAddress.address.shipping_address
                    ? 'Shipping Address Verification'
                    : 'Service Address Verification',
                addressAlert: true,
                addressObj,
            },
            component: UpdateAddressModal,
        });
        if (ok) {
            let newPayLoad = {};
            if (suggestedAddress.address.shipping_address) {
                newPayLoad = Object.assign({}, action.payload, {
                    address: {
                        billing_address: action.payload.address.billing_address,
                        shipping_address:
                            suggestedAddress.address.shipping_address,
                    },
                });
            } else {
                newPayLoad = Object.assign({}, action.payload, {
                    address: {
                        billing_address:
                            suggestedAddress.address.billing_address,
                        shipping_address:
                            action.payload.address.shipping_address,
                    },
                });
            }
            yield put(updateUserAccountOld(newPayLoad));
        }
    }
}

function* updatePrivacyOptInOutStatus(action: SetPrivacyOptInOutAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getFirebaseToken);
        yield call(AccountService.updateAccount, basePaths, firebaseToken, {
            PrivacyPolicy: String(action.payload),
        });
        yield put(reportPrivacyOptInOutSuccess(action.payload));
    } catch (err) {
        yield put(reportPrivacyOptInOutError((err as Error).message));
    }
}

function* refreshAccount() {
    const basePaths = yield select(
        (state: IApplicationState) => state.configuration.basePaths,
    );
    const firebaseToken = yield call(getFirebaseToken);
    const firebaseUser = yield call(getCurrentUser);
    const account = yield call(
        AccountService.findAccount,
        basePaths,
        firebaseToken,
    );
    sessionStorage.setItem('AccountDetails', JSON.stringify(account));
    localStorage.setItem('AccountDetails', JSON.stringify(account));
    yield call(
        AccountService.populateFields,
        Account.Normalize(account, firebaseUser),
    );
    yield put(loadUserAccount(Account.Normalize(account, firebaseUser)));
}

function getCookie(cname) {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

function* checkBag() {
    const loadingKey = 'verify-cart';
    yield call(fetchConfiguration);
    try {
        yield put(requestLoadingIndicator(loadingKey));
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getFirebaseToken);
        const c = getCookie('cartId');
        // yield call(refreshAccount);
        const payload = yield call(
            AccountService.checkCart,
            basePaths,
            firebaseToken,
            c,
        );
        if (payload && payload.content.cartCount > 0) {
            sessionStorage.setItem('isCartAvailable', 'true');
        } else {
            sessionStorage.setItem('isCartAvailable', 'false');
        }
        yield put(checkCartSuccess(payload));
    } catch (err) {
        yield put(reportPrivacyOptInOutError((err as Error).message));
    } finally {
        yield put(dismissLoadingIndicator(loadingKey));
    }
}

function* addItemsBag(action: IReportCartAddItems) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const cartId = getCookie('cartId');
        let response;
        if (cartId) {
            response = yield call(
                AccountService.addItemsBag,
                basePaths,
                action.payload,
                cartId,
            );
        } else {
            response = yield call(
                AccountService.addItemsBag,
                basePaths,
                action.payload,
                cartId,
            );
        }
        if (response && response.content.action === 'ADDED') {
            document.cookie = `cartId=${
                response.content.bag.id
            }; max-age=3600; path=/`;
        }
        yield put(addItemsSuccess(response));
    } catch (err) {
        yield put(addItemsFailure((err as Error).message));
    }
}

function* showAccountSignupModal() {
    const cart = yield select(
        (state: IApplicationState) => state.purchase.cart.result,
    );
    const location = yield select(
        (state: IApplicationState) => state.routing.location,
    ) || {};
    if (location.pathname === __('routes.byod')) {
        yield put(push(__('routes.sign-up')));
    }
    // Ensure its a draft and Phones then redirect to Phones
    if (
        cart &&
        cart.isDevicePurchase() &&
        (cart.isDraft() || cart.isProcessed())
    ) {
        window.location.href = __('routes.cart');
        return;
    } else if (
        cart &&
        (cart.isDraft() || cart.isProcessed()) &&
        cart.isByod()
    ) {
        // Ensure its a draft and BYOD then redirect to BYOD
        window.location.href = __('routes.cart');
        return;
    } else {
        yield put(push(__('routes.phones')));
    }
}

function* accountSaga() {
    yield all([
        takeEvery(ActionType.ACTIVATE_ACCOUNT, activateAccount),
        takeEvery(ActionType.RESET_PASSWORD_FOR_EMAIL, resetPassword),
        takeEvery(ActionType.SHOW_UPDATE_EMAIL, showUpdateEmailModal),
        takeEvery(ActionType.UPDATE_EMAIL_ADDRESS, updateEmailAddress),
        takeEvery(ActionType.UPDATE_USER_ACCOUNT, updateAccount),
        takeEvery(ActionType.UPDATE_USER_ACCOUNT_OLD, updateAccountOld),
        takeEvery(
            ActionType.SET_PRIVACY_OPT_IN_OUT,
            updatePrivacyOptInOutStatus,
        ),
        cancelSaga(),
        pauseSaga(),
        takeEvery(ActionType.REPORT_RESET_PASSWORD_FOR_EMAIL_ERROR, trackError),
        takeEvery(ActionType.REPORT_RESET_PASSWORD_FOR_EMAIL_ERROR, trackError),
        takeEvery(ActionType.REPORT_EMAIL_UPDATE_ERROR, trackError),
        takeEvery(ActionType.REPORT_UPDATE_USER_ACCOUNT_ERROR, trackError),
        takeEvery(ActionType.REPORT_PRIVACY_OPT_IN_OUT_ERROR, trackError),
        takeEvery(ActionType.REPORT_RESET_PASSWORD_FOR_EMAIL_ERROR, trackError),
        takeEvery(ActionType.REPORT_EMAIL_UPDATE_ERROR, trackError),
        takeEvery(ActionType.REPORT_UPDATE_USER_ACCOUNT_ERROR, trackError),
        takeEvery(ActionType.REPORT_PRIVACY_OPT_IN_OUT_ERROR, trackError),
        takeEvery(
            ActionType.START_POLL_LAPSED_REACTIVATION,
            pollLapsedReactivation,
        ),
        takeEvery(ActionType.SHOW_ACCOUNT_SIGNUP_MODAL, showAccountSignupModal),
        takeEvery(ActionType.CART_CHECK, checkBag),
        takeEvery(ActionType.CART_ADD_ITEMS, addItemsBag),
    ]);
}

export {
    accountSaga,
    accountLapsed,
    accountSuspended,
    accountTerminated,
    refreshAccount,
    updateSentEmailVerifyNoticy,
    emailVerifyNotice,
};
