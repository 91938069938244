import { withAnalytics } from 'components/common/analytics';
import { ZipCodeForm } from 'components/sign-up/components/phone-number/new-number/zip-code-form';
import { FetchStatus } from 'lib/br-redux';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchNpaNxx } from 'state/purchase/npanxx/npanxx.actions';
import { withInputValidation } from '../components/common/forms/input';

const dataKeys: ReadonlyArray<string> = ['zipCode'];

const mapState = ({ purchase }: IApplicationState, ownProps) => ({
    error: purchase.npanxx.error,
    fetching: purchase.npanxx.fetchStatus === FetchStatus.FETCHING,
    ...ownProps,
});

const mapDispatchToProps = dispatch => ({
    handleSubmit: zip => dispatch(fetchNpaNxx(zip)),
});

const enhance = compose(
    withInputValidation(dataKeys),
    withAnalytics,
    connect(
        mapState,
        mapDispatchToProps,
    ),
);
const ZipCodeFormContainer = enhance(ZipCodeForm);

export { ZipCodeFormContainer };
