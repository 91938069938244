import * as moment from 'moment';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';
import { ContentWrapper } from 'components/common/content-wrapper';
import { Divider } from 'components/common/divider';
import { Dots } from 'components/common/dots';
import { Hidden } from 'components/common/hidden';
import {
    BodyCopy,
    Col,
    ErrorMsg,
    Headline,
    InfoLink,
    Row,
} from 'components/common/typography';
import {
    AutopayCheckboxContainer as AutopayCheckbox,
    BraintreeDropInContainer as BraintreeDropIn,
    ButtonContainer as Button,
    ModalWrapperContainer as ModalWrapper,
    PreActiveContainer as PreActive,
} from 'containers';

import { FetchStatus } from 'lib/br-redux';
import {
    Account,
    CustomerState,
    DetailedChargeEvent,
    IConfiguration,
    IDeviceTypeProps,
    IModalProps,
    IQueueableModal,
    KOUNT_ENABLED,
    PaymentKind,
    PaymentMethod,
} from 'models';

import { formatMoney, Toggles, isEmpty } from 'utils';

const styles = require('./pay-bill.less');

interface IState {
    readonly preactive: boolean;
    readonly isEditingPaymentInfo: boolean;
    readonly submitButton: HTMLButtonElement | null;
}

interface IProps extends IAnalyticsProps, IDeviceTypeProps, IModalProps {
    readonly account: Account;
    readonly approvedSum: number;
    readonly chargeAmount: number;
    readonly totalMembersInGroup: number;
    readonly chargeEvents: ReadonlyArray<DetailedChargeEvent>;
    readonly configuration: IConfiguration;
    readonly dueDate: string;
    readonly billFetchStatus: FetchStatus;
    readonly manualPaymentSubmissionStatus: FetchStatus;
    readonly manualPaymentError: string;
    readonly paymentMethod: PaymentMethod;
    readonly paymentMethodFetchStatus: FetchStatus;
    readonly autopayFetchStatus: FetchStatus;
    readonly autopayStatus: boolean;
    readonly enableManualPay: boolean;
    readonly kountDevice: object;
    readonly redeemedCredits: number;
    readonly totalAmount: number;
    readonly groupCredits: number;
    readonly referralCredits: number;
    readonly goodwillCredits: number;
    getCreditBalance(): void;
    getCreditEarned(): void;
    getDefaultPaymentMethod(): void;
    updateDefaultPaymentMethod(nonce: string): void;
    submitManualPayment(amount: number): void;
    showCPNI(): void;
    showModal(modal: IQueueableModal): void;
    redirectTo(url: string): void;
    fetchAutopayStatus(): void;
    fetchCurrentBill(): void;
    fetchReferralStats(email): void;
}

@CSSModules(styles, { allowMultiple: true })
class PayBill extends React.Component<IProps, IState> {
    componentWillMount(): void {
        const {
            account: { customerState },
        } = this.props;

        const preactive = !!(
            (customerState && customerState === CustomerState.PURCHASED) ||
            (customerState && customerState === CustomerState.PREACTIVATED)
        );

        this.setState({
            preactive,
            submitButton: null,
        });
    }

    componentDidMount() {
        const {
            configuration,
            fetchCurrentBill,
            getDefaultPaymentMethod,
            fetchReferralStats,
            account,
        } = this.props;
        fetchCurrentBill();
        // TODO: maybe not fetch this for preactive user
        if (!this.state.preactive) {
            getDefaultPaymentMethod();
        }
        if (
            Toggles.isReferral(configuration.toggles) &&
            account.customerState === CustomerState.ACTIVE
        ) {
            fetchReferralStats(account.emailAddress);
        }
    }

    showReferrals(chargeAmount, configuration, redeemedCredits, totalAmount) {
        if (
            isEmpty(chargeAmount) ||
            isEmpty(configuration.toggles) ||
            isEmpty(redeemedCredits) ||
            isEmpty(totalAmount)
        ) {
            return false;
        }
        return Toggles.isReferral(configuration.toggles);
    }
    getRedemptionLink(modalContent) {
        return JSON.parse(modalContent).RedemptionLink;
    }
    render() {
        return (
            <>
                <Hidden until={this.fetchError}>
                    <ContentWrapper>
                        <ErrorMsg>{__('not-available-error')}</ErrorMsg>
                    </ContentWrapper>
                </Hidden>
                <Hidden when={this.fetchError}>{this.content}</Hidden>
            </>
        );
    }

    private get fetchError(): boolean {
        const {
            billFetchStatus,
            paymentMethodFetchStatus,
            autopayFetchStatus,
        } = this.props;
        const { preactive } = this.state;
        return (
            !preactive &&
            [
                billFetchStatus,
                paymentMethodFetchStatus,
                autopayFetchStatus,
            ].indexOf(FetchStatus.ERROR) > -1
        );
    }

    getAppliedPromotion(promoCode) {
        const { appliedPromotions } = this.props.account;
        return appliedPromotions.find(
            appliedPromotion => appliedPromotion.promoCode === promoCode,
        );
    }

    private get content(): JSX.Element {
        const {
            billFetchStatus,
            chargeAmount,
            configuration,
            redeemedCredits,
            kountDevice,
            totalAmount,
            totalMembersInGroup,
            groupCredits,
            referralCredits,
            goodwillCredits,
        } = this.props;
        console.log(this.props.totalMembersInGroup);
        const { preactive } = this.state;

        const promoContents = this.props.account.PROMO_CONTENTS;
        if (billFetchStatus === FetchStatus.FETCHING) {
            return <Dots invert />;
        }
        return preactive ? (
            <PreActive />
        ) : (
            <>
                <ContentWrapper>
                    <Row>
                        <Col width={3} mobileWidth={6}>
                            <Headline
                                medium
                                three
                                fontCircular
                                light
                                customSpacing
                                noMarginBottom
                            >
                                {__('account.currentStatement.balance')}
                            </Headline>
                        </Col>
                        <Col width={4} mobileWidth={6} leftMargin={true}>
                            <BodyCopy
                                columnSubHeading
                                margin8Bottom
                                mobileFont14
                                greyFont
                            >
                                {formatMoney(totalAmount)}
                            </BodyCopy>
                            <BodyCopy
                                columnDescription
                                margin10Bottom
                                mobileFont12
                            >
                                {__(
                                    'account.currentStatement.unlimited-data-message',
                                )}
                            </BodyCopy>
                        </Col>
                    </Row>
                </ContentWrapper>

                {this.showReferrals(
                    chargeAmount,
                    configuration,
                    redeemedCredits,
                    totalAmount,
                ) && (
                    <ContentWrapper>
                        <Row>
                            <Col width={3} mobileWidth={6}>
                                <Headline
                                    medium
                                    three
                                    fontCircular
                                    light
                                    customSpacing
                                    noMarginBottom
                                >
                                    {__('referral.ref')}
                                </Headline>
                            </Col>
                            <Col width={4} mobileWidth={6} leftMargin={true}>
                                <BodyCopy
                                    margin8Bottom
                                    mobileFont14
                                    green
                                    columnSubHeading
                                >
                                    {formatMoney(redeemedCredits)}
                                </BodyCopy>
                            </Col>
                        </Row>
                    </ContentWrapper>
                )}
                <Divider />
                <ContentWrapper>
                    <Row>
                        <Col width={3} mobileWidth={12}>
                            <Headline
                                medium
                                three
                                fontCircular
                                light
                                customSpacing
                                noMargin
                                mobileMarginBottom35
                            >
                                {__(
                                    'account.currentStatement.upcoming-payment',
                                )}
                            </Headline>
                        </Col>
                        <Col width={9} mobileWidth={12} leftMargin={true}>
                            <>
                                <Col width={9} mobileWidth={9} minHeight20>
                                    <BodyCopy columnDescription mobileFont12>
                                        {__('account.visible')}
                                    </BodyCopy>
                                </Col>
                                <Col width={3} mobileWidth={3} minHeight20>
                                    <BodyCopy columnDescription mobileFont12>
                                        {formatMoney(totalAmount)}
                                    </BodyCopy>
                                </Col>
                            </>
                            {configuration.toggles.enableGroups &&
                                totalMembersInGroup > 1 &&
                                groupCredits > 0 && (
                                    <>
                                        <Col
                                            width={9}
                                            mobileWidth={9}
                                            minHeight20
                                        >
                                            <BodyCopy
                                                columnDescription
                                                mobileFont12
                                            >
                                                {__(
                                                    'account.partypay.discount',
                                                ).replace(
                                                    '${member}',
                                                    totalMembersInGroup,
                                                )}
                                            </BodyCopy>
                                        </Col>
                                        <Col
                                            width={3}
                                            mobileWidth={3}
                                            minHeight20
                                        >
                                            <BodyCopy
                                                columnDescription
                                                mobileFont12
                                            >
                                                {groupCredits && '-'}
                                                {formatMoney(groupCredits)}
                                            </BodyCopy>
                                        </Col>
                                    </>
                                )}
                            {referralCredits > 0 && (
                                <>
                                    <Col width={9} mobileWidth={9} minHeight20>
                                        <BodyCopy
                                            columnDescription
                                            mobileFont12
                                        >
                                            {__(
                                                'account.visible.referral.discount',
                                            )}
                                        </BodyCopy>
                                    </Col>
                                    <Col width={3} mobileWidth={3} minHeight20>
                                        <BodyCopy
                                            columnDescription
                                            mobileFont12
                                        >
                                            -{formatMoney(referralCredits)}
                                        </BodyCopy>
                                    </Col>
                                </>
                            )}
                            {goodwillCredits > 0 && (
                                <>
                                    <Col width={9} mobileWidth={9}>
                                        <BodyCopy columnDescription>
                                            {__('account.reward')}
                                        </BodyCopy>
                                    </Col>
                                    <Col width={3} mobileWidth={3}>
                                        <BodyCopy columnDescription>
                                            -{formatMoney(goodwillCredits)}
                                        </BodyCopy>
                                    </Col>
                                </>
                            )}
                            {false && ( // TODO: show when protect amount comes back
                                <>
                                    <Col width={9} mobileWidth={9} minHeight20>
                                        <BodyCopy
                                            columnDescription
                                            mobileFont12
                                        >
                                            {__('account.visible.protect')}
                                        </BodyCopy>
                                    </Col>
                                    <Col width={3} mobileWidth={3} minHeight20>
                                        <BodyCopy
                                            columnDescription
                                            mobileFont12
                                        >
                                            {formatMoney(0)}
                                        </BodyCopy>
                                    </Col>
                                </>
                            )}
                            <Divider margin25 dashed mobileMargin20 />
                            <>
                                <Col width={9} mobileWidth={9} minHeight20>
                                    {this.dueDateInfo}
                                </Col>
                                <Col width={3} mobileWidth={3} minHeight20>
                                    <BodyCopy
                                        columnDescription
                                        fontSize20
                                        greyFont
                                        marginTop4
                                        mobileFont14
                                    >
                                        {formatMoney(chargeAmount)}
                                    </BodyCopy>
                                </Col>
                            </>
                            <AutopayCheckbox />
                            {this.paymentButton}
                        </Col>
                    </Row>
                </ContentWrapper>
                <Divider margin60 />
                <ContentWrapper>
                    <Row>
                        <Col width={8} mobileWidth={12}>
                            <Headline
                                medium
                                three
                                fontCircular
                                light
                                customSpacing
                                noMargin
                                margin20BottomMobile
                            >
                                {__('account.currentStatement.payment')}
                            </Headline>
                        </Col>

                        <Col width={4} mobileWidth={12}>
                            <BodyCopy
                                columnSubHeading
                                margin8Bottom
                                noMarginTop
                            >
                                {__('account.currentStatement.payment-method')}
                            </BodyCopy>
                            {this.formattedPaymentInfo}
                            <Button
                                invert
                                secondary
                                fullWidth
                                styleName="change-payment-btn"
                                type="submit"
                                onClick={this.changeDefaultPaymentMethod}
                                fontSize16
                                mobileFont
                                label={__(
                                    'account.currentStatement.change-payment-method',
                                )}
                            />
                        </Col>
                    </Row>
                </ContentWrapper>
                <Divider margin60 />
                <ContentWrapper>
                    <Row>
                        <Col width={3} mobileWidth={12}>
                            <Headline
                                medium
                                three
                                fontCircular
                                light
                                customSpacing
                                noMargin
                                mobileMarginBottom35
                            >
                                <div styleName="promo-heading">
                                    Your Promotional Offers
                                </div>
                            </Headline>
                        </Col>
                        <Col width={9} mobileWidth={12} leftMargin={true}>
                            <Row>
                                {promoContents &&
                                    promoContents.length > 0 &&
                                    promoContents.map(promotion => {
                                        const appliedPromotion = this.getAppliedPromotion(
                                            promotion.promoCode,
                                        );
                                        return (
                                            <Col
                                                key={promotion.promoCode}
                                                width={6}
                                                mobileWidth={12}
                                            >
                                                <div styleName="promo-content">
                                                    <img
                                                        styleName="promo-logo"
                                                        src={`/assets/images/promotions/${
                                                            promotion.promoCode
                                                        }.png`}
                                                    />
                                                    <div styleName="promo-offer">
                                                        <p styleName="offer-text-header">
                                                            {
                                                                JSON.parse(
                                                                    promotion.modalContent,
                                                                ).AccountsPage
                                                                    .TitleText
                                                            }
                                                        </p>
                                                        {appliedPromotion &&
                                                            promotion.modalContent && (
                                                                <div>
                                                                    {appliedPromotion.redemptionCode && (
                                                                        <p styleName="offer-text">
                                                                            Redeem
                                                                            with
                                                                            code:{' '}
                                                                            {
                                                                                appliedPromotion.redemptionCode
                                                                            }
                                                                        </p>
                                                                    )}
                                                                    {JSON.parse(
                                                                        promotion.modalContent,
                                                                    )
                                                                        .RedemptionLink && (
                                                                        <p styleName="offer-text-link">
                                                                            <a
                                                                                target="_blank"
                                                                                href={this.getRedemptionLink(
                                                                                    promotion.modalContent,
                                                                                )}
                                                                            >
                                                                                Click
                                                                                here
                                                                                to
                                                                                redeem
                                                                            </a>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            )}
                                                        {!appliedPromotion && (
                                                            <div>
                                                                <p styleName="offer-text">
                                                                    {
                                                                        promotion.Description
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </Col>
                    </Row>
                </ContentWrapper>
                {/* Loading the braintree component is the only way we get the callback to get the kountDevice */}
                {KOUNT_ENABLED && Object.keys(kountDevice).length === 0 && (
                    <div styleName={'hide-bt'}>
                        <BraintreeDropIn
                            renderSubmitButton={this.renderSubmitButton}
                            onGetPaymentNonce={this.updateDefaultPaymentMethod}
                        />
                    </div>
                )}
            </>
        );
    }

    private readonly setSubmitButton = (
        submitButtonWrapper: HTMLDivElement,
    ): void => {
        if (submitButtonWrapper && submitButtonWrapper.firstChild) {
            this.setState({
                submitButton: submitButtonWrapper.firstChild as HTMLButtonElement,
            });
        }
    };

    private readonly changeDefaultPaymentMethod = (): void => {
        const { showModal } = this.props;
        showModal({
            additionalProps: {
                renderSubmitButton: this.renderSubmitButton,
                onGetPaymentNonce: this.updateDefaultPaymentMethod,
            },
            component: this.braintreeComponent,
        });
    };

    private get paymentButton(): JSX.Element | null {
        const {
            account,
            autopayStatus,
            billFetchStatus,
            kountDevice,
            enableManualPay,
            paymentMethodFetchStatus,
            manualPaymentError,
            manualPaymentSubmissionStatus,
        } = this.props;
        // If Pay Now payment pending, do not allow another concurrent payment to be made.
        const payNowPending =
            account.customerState === CustomerState.LAPSED &&
            String(account.statusTransition).toLowerCase() === 'active';

        const enableButton =
            !account.terminateDate && enableManualPay && !payNowPending;
        if (
            billFetchStatus === FetchStatus.SUCCESS &&
            paymentMethodFetchStatus === FetchStatus.SUCCESS &&
            !autopayStatus &&
            (!KOUNT_ENABLED ||
                // wait until the braintree kount info is loaded before letting the user pay
                (KOUNT_ENABLED &&
                    kountDevice &&
                    Object.keys(kountDevice).length))
        ) {
            return (
                <>
                    <ErrorMsg>{manualPaymentError}</ErrorMsg>
                    <div ref={this.setSubmitButton} styleName="confirm-wrapper">
                        <Button
                            fullWidth
                            styleName="payment-button"
                            type="submit"
                            fontSize16
                            mobileFont
                            onClick={this.submitManualPayment}
                            loading={
                                manualPaymentSubmissionStatus ===
                                FetchStatus.FETCHING
                            }
                            disabled={!enableButton}
                            label={__('account.currentStatement.payNow')}
                        />
                        <span styleName="privacy-statement">
                            {enableButton
                                ? __('account.currentStatement.payNowLegal')
                                : __(
                                      'account.currentStatement.authorization-message',
                                  )}
                        </span>
                        <Divider margin20 dashed />
                        <BodyCopy columnDescription mobileFont12>
                            {__('account.currentStatement.privacyNotice')}
                            <InfoLink
                                fontCircular
                                to={`${__('routes.privacy')}`}
                                target="_blank"
                                mobileFont
                            >
                                {__('account.currentStatement.privacyLinkText')}
                            </InfoLink>
                        </BodyCopy>
                    </div>
                </>
            );
        }

        return null;
    }

    private readonly submitManualPayment = () => {
        const { chargeAmount, submitManualPayment } = this.props;
        submitManualPayment(chargeAmount);
    };

    private get formattedPaymentInfo(): any {
        const noMargin = { margin: 0 };
        const { paymentMethod, paymentMethodFetchStatus } = this.props;

        if (paymentMethodFetchStatus === FetchStatus.FETCHING) {
            return __('account.currentStatement.fetchingDefaultPayment');
        }

        if (paymentMethodFetchStatus === FetchStatus.ERROR) {
            return __('account.currentStatement.errorDefaultPayment');
        }

        switch (paymentMethod.kind) {
            case PaymentKind.CREDIT_CARD:
                return (
                    <BodyCopy columnDescription style={noMargin} mobileFont12>
                        Using {paymentMethod.cardType}&nbsp;
                        {__('account.currentStatement.endingIn')}
                        {'...'}
                        {paymentMethod.last4}
                    </BodyCopy>
                );
            case PaymentKind.PAYPAL:
                return (
                    <BodyCopy style={noMargin}>
                        Using Paypal&nbsp;
                        {paymentMethod.email}
                    </BodyCopy>
                );
            case PaymentKind.VENMO:
                return (
                    <BodyCopy style={noMargin}>
                        Using Venmo&nbsp;
                        {paymentMethod.username}
                    </BodyCopy>
                );
            default:
                return __('account.currentStatement.unknownPaymentMethod');
        }
    }
    private get dueDateInfo(): JSX.Element {
        const { account, dueDate } = this.props;
        const lapsed =
            account.customerState === CustomerState.LAPSED &&
            account.statusTransition === '';

        return (
            <BodyCopy
                columnDescription
                error={lapsed}
                fontSize20
                black
                marginTop4
                mobileFont14
            >
                {__('account.currentStatement.due')}
                {moment(dueDate).month() === 4
                    ? moment(dueDate).format('MMM DD, YYYY')
                    : moment(dueDate).format('MMM. DD, YYYY')}
            </BodyCopy>
        );
    }

    private readonly updateDefaultPaymentMethod = (nonce: string): void => {
        this.props.updateDefaultPaymentMethod(nonce);
        this.setState({ isEditingPaymentInfo: false });
    };

    private readonly braintreeComponent = (props): JSX.Element => {
        return (
            <ModalWrapper
                closable
                title={__('account.currentStatement.change-payment-method')}
            >
                <BraintreeDropIn {...props} />
            </ModalWrapper>
        );
    };

    private readonly renderSubmitButton = ({
        onClick,
        isDisabled,
    }): JSX.Element => {
        return (
            <Button
                fullWidthMobile
                fullWidth
                margin20
                noMarginBottom
                onClick={onClick}
                disabled={isDisabled}
                label={__('account.currentStatement.setDefault')}
            />
        );
    };
}

export { PayBill };
