// Forked from https://github.com/willmcpo/body-scroll-lock by Preston Tighe

const includes = require('array-includes');
// tslint:disable

export interface BodyScrollOptions {
    readonly reserveScrollBarGap?: boolean;
}

const isIosDevice =
    typeof window !== 'undefined' &&
    window.navigator &&
    window.navigator.platform &&
    /iPad|iPhone|iPod|(iPad Simulator)|(iPhone Simulator)|(iPod Simulator)/.test(
        window.navigator.platform,
    );
type HandleScrollEvent = TouchEvent;

let firstTargetElement: HTMLElement | null = null;
let allTargetElements: Array<HTMLElement> = [];
let initialClientY: number = -1;
let previousBodyOverflowSetting;
let previousBodyPaddingRight;

const preventDefault = (rawEvent: HandleScrollEvent): boolean => {
    const e = rawEvent || window.event;
    if (e.preventDefault) e.preventDefault();

    return false;
};

const setOverflowHidden = (options?: BodyScrollOptions) => {
    // Setting overflow on body/documentElement synchronously in Desktop Safari slows down
    // the responsiveness for some reason. Setting within a setTimeout fixes this.
    // setTimeout(() => {
    // If previousBodyPaddingRight is already set, don't set it again.
    if (previousBodyPaddingRight === undefined) {
        const reserveScrollBarGap =
            !!options && options.reserveScrollBarGap === true;
        const scrollBarGap =
            window.innerWidth - document.documentElement.clientWidth;

        if (reserveScrollBarGap && scrollBarGap > 0) {
            previousBodyPaddingRight = document.body.style.paddingRight;
            document.body.style.paddingRight = `${scrollBarGap}px`;
        }
    }

    // If previousBodyOverflowSetting is already set, don't set it again.
    if (previousBodyOverflowSetting === undefined) {
        previousBodyOverflowSetting = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'relative';
    }
    // });
};

const restoreOverflowSetting = () => {
    // Setting overflow on body/documentElement synchronously in Desktop Safari slows down
    // the responsiveness for some reason. Setting within a setTimeout fixes this.
    // setTimeout(() => {
    if (previousBodyPaddingRight !== undefined) {
        document.body.style.paddingRight = previousBodyPaddingRight;

        // Restore previousBodyPaddingRight to undefined so setOverflowHidden knows it
        // can be set again.
        previousBodyPaddingRight = undefined;
    }

    if (previousBodyOverflowSetting !== undefined) {
        document.body.style.overflow = previousBodyOverflowSetting;
        document.body.style.overflowY = 'scroll';
        document.body.style.height = 'auto';
        document.body.style.position = 'static';

        // Restore previousBodyOverflowSetting to undefined
        // so setOverflowHidden knows it can be set again.
        previousBodyOverflowSetting = undefined;
    }
    // });
};

// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
const isTargetElementTotallyScrolled = (targetElement: any): boolean =>
    targetElement
        ? targetElement.scrollHeight - targetElement.scrollTop <=
          targetElement.clientHeight
        : false;

const handleScroll = (
    event: HandleScrollEvent,
    targetElement: any,
): boolean => {
    const clientY = event.targetTouches[0].clientY - initialClientY;

    if (targetElement && targetElement.scrollTop === 0 && clientY > 0) {
        // element is at the top of its scroll
        return preventDefault(event);
    }

    if (isTargetElementTotallyScrolled(targetElement) && clientY < 0) {
        // element is at the top of its scroll
        return preventDefault(event);
    }

    return true;
};

export const disableBodyScroll = (
    targetElement: any,
    options?: BodyScrollOptions,
): void => {
    if (isIosDevice) {
        // targetElement must be provided, and disableBodyScroll must not have been
        // called on this targetElement before.
        if (targetElement && !includes(allTargetElements, targetElement)) {
            allTargetElements = [...allTargetElements, targetElement];

            targetElement.ontouchstart = (event: HandleScrollEvent) => {
                if (event.targetTouches.length === 1) {
                    // detect single touch
                    initialClientY = event.targetTouches[0].clientY;
                }
            };
            targetElement.ontouchmove = (event: HandleScrollEvent) => {
                if (event.targetTouches.length === 1) {
                    // detect single touch
                    handleScroll(event, targetElement);
                }
            };
        }
        document.body.style.display = 'fixed';
    } else {
        if (!firstTargetElement) firstTargetElement = targetElement;
    }

    setOverflowHidden(options);
};

export const clearAllBodyScrollLocks = (): void => {
    if (isIosDevice) {
        // Clear all allTargetElements ontouchstart/ontouchmove handlers, and the references
        allTargetElements.forEach((targetElement: any) => {
            targetElement.ontouchstart = null;
            targetElement.ontouchmove = null;
        });

        allTargetElements = [];

        // Reset initial clientY
        initialClientY = -1;
        document.body.style.display = 'block';
    } else {
        firstTargetElement = null;
    }

    restoreOverflowSetting();
};

export const enableBodyScroll = (targetElement: any): void => {
    if (isIosDevice) {
        targetElement.ontouchstart = null;
        targetElement.ontouchmove = null;

        allTargetElements = allTargetElements.filter(
            element => element !== targetElement,
        );
        document.body.style.display = 'block';
    } else if (firstTargetElement === targetElement) {
        firstTargetElement = null;
    }
    restoreOverflowSetting();
};
