enum StartupScript {
    FACEBOOK = 'facebook',
    FIREBASE = 'firebase',
    GOOGLE_SDK = 'google-sdk',
    AUTHENTICATION = 'authentication',
    CONFIGURATION = 'configuration',
    CHAT = 'chat',
}

export { StartupScript };
