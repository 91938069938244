import { ICarrier, IConfigurationBasePaths } from 'models';
import { ThinAirService } from './thin-air.service';

// TODO: Move This
interface PhoneNumberPortRerequest {
    readonly portinMdn: string;
}

interface AccountNumberPortRerequest {
    readonly portinAccountNumber: string;
}

interface PINPortRerequest {
    readonly portinPin: string;
}

type PortRerequest =
    | PhoneNumberPortRerequest
    | AccountNumberPortRerequest
    | PINPortRerequest;

class PortService extends ThinAirService {
    static async fetchCarriers(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        portinMdn: string,
    ): Promise<ReadonlyArray<ICarrier>> {
        return PortService.fetchJson<ReadonlyArray<ICarrier>>(
            `${PortService.BASE_URL}${
                basePaths.port
            }/carrier?portinMdn=${portinMdn}`,
            {
                headers: PortService.getDefaultHeaders(firebaseToken),
                method: 'GET',
            },
        );
    }

    static async rerequest(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        request: PortRerequest,
    ): Promise<void> {
        const response = await fetch(
            `${PortService.BASE_URL}${basePaths.port}/rerequest`,
            {
                body: JSON.stringify(request),
                headers: PortService.getDefaultHeaders(firebaseToken),
                method: 'POST',
            },
        );

        if (response.status === 201 || response.status === 202) {
            return;
        }

        throw new Error(
            await PortService.parseErrorOrDefault(await response.json()),
        );
    }
}

export { PortService };
