import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { LinkProps, NavLink as RRNavLink } from 'react-router-dom';

const styles = require('./nav-link.less');

interface Props extends Partial<LinkProps> {
    readonly element?: string;
}

// tslint:disable
const activeStyle = {
    // borderStyle: 'solid',
    // borderWidth: '0 0 0px',
    color: '#1800ff',
};

@CSSModules(styles)
class NavLink extends React.Component<Props> {
    render() {
        const {
            isActive,
            props: { children, element, to = '', ...props },
        } = this;
        const childrenContainer = (
            <>
                <div styleName="nav-link-animated" />
                {children}
            </>
        );
        return (
            <div styleName="nav-link-wrapper">
                {React.createElement(
                    element || RRNavLink,
                    element
                        ? {
                              to,
                              styleName: 'nav-link',
                              ...props,
                          }
                        : {
                              to,
                              activeStyle,
                              styleName: 'nav-link',
                              isActive,
                              ...props,
                          },
                    childrenContainer,
                )}
            </div>
        );
    }
    isActive = (match, location) => {
        if (!match) {
            return false;
        }

        if (match.path === '/' && match.path !== location.pathname) {
            return false;
        }

        return !!match;
    };
}

export { NavLink };
