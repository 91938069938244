import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { get } from 'lodash';
import { ChoiceOption } from 'models';
import { splitProps } from 'utils';

const styles = require('./select.less');

enum Modifiers {
    disabled = 'disabled',
    block = 'block',
    margin30Bottom = 'margin30Bottom',
}

interface SelectModifiers {
    readonly block?: boolean;
    readonly disabled?: boolean;
    readonly label?: string;
    readonly margin30Bottom?: boolean;
}

type SelectType = React.HTMLProps<HTMLSelectElement> & SelectModifiers;

interface IProps extends SelectType {
    readonly options: ReadonlyArray<ChoiceOption>;
    readonly value: string;
    handleChange(string): void;
}

interface IState {
    readonly value: string;
    readonly open: boolean;
}

@CSSModules(styles, { allowMultiple: true })
class Select extends React.Component<IProps, IState> {
    componentWillMount(): void {
        const {
            props: { value },
        } = this;
        this.setState({ value, open: false });
    }

    readonly handleClickOutside = () => {
        this.setState({ open: false });
    };

    render(): JSX.Element {
        const {
            props: { options, disabled, label, ...props },
            state: { value, open },
        } = this;
        const [modifiers] = splitProps(props, Modifiers);
        const cn = classNames('select', modifiers, { open, disabled });
        const caption = this.getCaption();

        /* tslint:disable:no-empty */
        return (
            <div styleName={cn}>
                <div
                    onClick={this.toggleOptions}
                    className="select-toggle"
                    tabIndex={0}
                    role="list"
                    styleName={classNames('caption', {
                        selected: !!caption,
                    })}
                >
                    {caption || label || 'Select'}
                </div>

                {open && (
                    <ul onWheel={this.onWheel} onClick={this.toggleOptions}>
                        {options.map(this.itemize)}
                    </ul>
                )}

                <select value={value} disabled={disabled}>
                    {options.map(this.optionalize)}
                </select>
            </div>
        );
    }

    private readonly onSelect = (value: string): void => {
        this.setState({ value });
        this.props.handleChange(value);
    };

    private readonly onWheel = (
        e: React.WheelEvent<HTMLUListElement>,
    ): void => {
        const listEl = (e.target as any).parentElement;
        const { deltaY } = e;
        const { offsetHeight, scrollTop, scrollHeight } = listEl;
        const shouldPrevent =
            (offsetHeight + scrollTop === scrollHeight && deltaY > 0) ||
            (scrollTop === 0 && deltaY < 0);

        if (shouldPrevent) {
            e.preventDefault();
        }
    };

    private readonly toggleOptions = (): void => {
        const {
            props: { disabled },
            state: { open },
        } = this;
        this.setState({ open: !disabled && !open });
    };

    private readonly itemize = (
        opt: ChoiceOption,
        key: number,
    ): JSX.Element => {
        const { value, label } = opt;
        const selected = value === this.state.value;
        const styleName = classNames({ selected });
        return (
            <li
                key={key}
                styleName={styleName}
                value={value}
                onClick={() => this.onSelect(value)}
            >
                {label}
            </li>
        );
    };

    private readonly optionalize = (
        opt: ChoiceOption,
        key: number,
    ): JSX.Element => (
        <option key={key} value={opt.value}>
            {opt.label}
        </option>
    );

    private readonly getCaption = (): string =>
        !this.state.open ? get(this.getSelected(), 'label') : '';

    private readonly getSelected = (): ChoiceOption | void =>
        this.props.options.find(o => this.state.value === o.value);
}

export { Select };
