import { Location } from 'history';
import * as React from 'react';

interface Props {
    readonly location: Location;
    readonly delay: number;
}

class ScrollRestore extends React.Component<Props, {}> {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            setTimeout(() => window.scrollTo(0, 0), this.props.delay);
        }
    }

    render() {
        return this.props.children;
    }
}

export { ScrollRestore };
