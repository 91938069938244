// import paths from 'components/app/paths';

/**
 * [path, id]
 */
type NuancePageId = [string, number];
const NuancePageIds: ReadonlyArray<NuancePageId> = [
    ['/nuance/mobile-chat.html', 38049836],
    ['/', 38049837],
    ['/coverage', 38049838],
    ['/feedback', 38049839],
    ['/forgot-password', 38049840],
    ['/membership', 38049841],
    ['/sign-up', 38049842],
    ['/sign-in', 38049844],
    ['/faq', 38049845],
    ['/plans', 38049846],
    ['/terms', 38049847],
    ['/privacy', 38049848],
    // ['', 38049849],
    ['/account/pay-bill', 38049850],
    ['/account/bill-history', 38049851],
    ['/account/profile', 38049852],
    ['/account/service', 38049853],
];

export { NuancePageIds, NuancePageId };
