// import { IChatTokenResponse } from 'models';
import { JSONService } from 'utils';
import { browserName, osName } from 'react-device-detect';

class ChatService extends JSONService {
    static async fetchChatToken(): Promise<any> {
        const reqBody = {
            browser: browserName,
            platform: osName,
        };
        const exchangeToken = sessionStorage.getItem('ExchangeToken');
        const authToken = exchangeToken || process.env.API_TOKEN;
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${authToken}` || '');
        return fetch(`${process.env.API_BASE_URL}/v1/token/chat`, {
            headers,
            method: 'POST',
            body: JSON.stringify(reqBody),
        })
            .then(res => res.json())
            .then(data => data);
    }
}

export { ChatService };
