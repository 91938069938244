import { IApplicationState, ICarrier } from 'models';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { PortService } from 'services/port.service';
import { refreshAccount } from 'state/account/account.saga';
import { getToken } from 'state/firebase/firebase.saga';
import { fetchPortStatus } from 'state/port-status';
import { trackError } from 'state/tealium';
import { dismissModal } from 'state/view/view.actions';
import {
    ActionType,
    IResubmitAccountNumberAction,
    IResubmitPhoneNumberAction,
    IResubmitPINAction,
    reportResubmissionSuccess,
    resportResubmissionError,
} from './resubmission.actions';

function* handleResubmissionSuccess() {
    yield put(dismissModal()); // close the modal
    yield call(refreshAccount); // refetch the status
    yield put(fetchPortStatus());
}

function* resubmitPhoneNumber(action: IResubmitPhoneNumberAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const token = yield call(getToken);
        const carriers: ReadonlyArray<ICarrier> = yield call(
            PortService.fetchCarriers,
            basePaths,
            token,
            action.payload,
        );
        if (!carriers || !carriers.length) {
            throw new Error(__('number-transfer-status.generic.message'));
        }

        yield call(PortService.rerequest, basePaths, token, {
            portinMdn: action.payload,
        });
        yield put(reportResubmissionSuccess());
    } catch (err) {
        yield put(resportResubmissionError((err as Error).message));
    }
}

function* resubmitAccountNumber(action: IResubmitAccountNumberAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const token = yield call(getToken);
        yield call(PortService.rerequest, basePaths, token, {
            portinAccountNumber: action.payload,
        });
        yield put(reportResubmissionSuccess());
    } catch (err) {
        yield put(resportResubmissionError((err as Error).message));
    }
}

function* resubmitPIN(action: IResubmitPINAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const token = yield call(getToken);
        yield call(PortService.rerequest, basePaths, token, {
            portinPin: action.payload,
        });
        yield put(reportResubmissionSuccess());
    } catch (err) {
        yield put(resportResubmissionError((err as Error).message));
    }
}

function* resubmissionSaga() {
    yield all([
        takeEvery(ActionType.RESUBMIT_ACCOUNT_NUMBER, resubmitAccountNumber),
        takeEvery(ActionType.RESUBMIT_PHONE_NUMBER, resubmitPhoneNumber),
        takeEvery(ActionType.RESUBMIT_PIN, resubmitPIN),
        takeEvery(
            ActionType.REPORT_RESUBMISSION_SUCCESS,
            handleResubmissionSuccess,
        ),
        takeEvery(ActionType.REPORT_RESUBMISSION_ERROR, trackError),
    ]);
}

export { resubmissionSaga };
