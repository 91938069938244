import { IConfigurationResponse } from 'models';
import { JSONService } from 'utils';

class ConfigurationService extends JSONService {
    private static readonly baseURL = process.env.API_BASE_URL as string;
    private static readonly apiMetaVer = process.env.API_METAVERSION; // bump this when Apigee makes breaking API version changes that we need to account for.
    private static readonly configurationVer = 'v1';

    static async fetchConfiguration(): Promise<IConfigurationResponse> {
        const headers = new Headers();
        headers.append(
            'Authorization',
            `Bearer ${process.env.API_TOKEN}` || '',
        );
        return ConfigurationService.fetchJson<IConfigurationResponse>(
            `${ConfigurationService.baseURL}/${
                ConfigurationService.configurationVer
            }/appconfig?apiMetaVer=${ConfigurationService.apiMetaVer}`,
            {
                headers,
            },
        );
    }
}

export { ConfigurationService };
