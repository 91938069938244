import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./terms.less');

@CSSModules(styles)
class TermsAndConditions extends React.Component<{}> {
    render() {
        return (
            <>{(window.location.href = __('routes.terms-and-conditions'))}</>
        );
    }
}
export { TermsAndConditions };
