import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';
import { ButtonContainer as Button } from 'containers';
import {
    TealiumLinkEvents,
    TealiumPageLinkLocations,
    TealiumPageType,
} from 'models/tealium';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';

const styles = require('./boolean-button.less');

interface Props extends IAnalyticsProps {
    readonly label?: string;
    readonly children?: any;
    readonly selected: boolean;
    readonly metricFalse?: any;
    readonly metricTrue?: any;
    readonly flow: string;
    readonly noPaddingSides?: boolean;
    onClick(e: React.MouseEvent<HTMLButtonElement>): any;
}

@CSSModules(styles, { allowMultiple: true })
class BooleanButton extends React.Component<Props> {
    render() {
        const {
            props: {
                label,
                children,
                selected,
                onClick,
                metricFalse,
                metricTrue,
                noPaddingSides,
                flow,
            },
        } = this;
        return (
            <Button
                styleName={['boolean-button', selected ? 'selected' : ''].join(
                    ' ',
                )}
                selected={selected}
                invert
                aria-pressed={selected}
                noPaddingSides={noPaddingSides}
                onClick={event => {
                    if (metricFalse) {
                        TealiumDispatcher.dispatchEvent(
                            flow.toLowerCase(),
                            TealiumPageType.STANDALONE_COMPATABILITY,
                            metricFalse,
                            TealiumPageLinkLocations.MODULE_ONE,
                            TealiumLinkEvents.BUTTON,
                        );
                    }
                    if (metricTrue) {
                        TealiumDispatcher.dispatchEvent(
                            flow.toLowerCase(),
                            TealiumPageType.STANDALONE_COMPATABILITY,
                            metricTrue,
                            TealiumPageLinkLocations.MODULE_ONE,
                            TealiumLinkEvents.BUTTON,
                        );
                    }
                    onClick(event);
                }}
                label={label}
            >
                {children}
            </Button>
        );
    }
}

export { BooleanButton };
