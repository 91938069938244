import { Service } from 'components/account/service/service';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { showModal } from 'state/view/view.actions';

const mapStateToProps = (state: IApplicationState) => ({});

const mapDispatchToProps = dispatch => ({
    showModal: p => dispatch(showModal(p)),
});

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const ServiceContainer = enhance(Service);

export { ServiceContainer };
