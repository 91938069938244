import { withAnalytics } from 'components/common/analytics';
import { withDeviceType } from 'components/common/device-type';
import { withInputValidation } from 'components/common/forms';
import { Footer } from 'components/footer';
import { FetchStatus } from 'lib/br-redux';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { showLeadCaptureModal } from 'state/lead/lead.actions';
import { showStayInLoopLanding } from 'state/stay-in-loop/stay-in-loop.actions';

const dataKeys: ReadonlyArray<string> = ['email'];

const mapDispatchToProps = dispatch => ({
    showLeadCaptureModal: email => dispatch(showLeadCaptureModal(email)),
    showStayInLoopLanding: email => dispatch(showStayInLoopLanding(email)),
    redirectTo: (url: string) => dispatch(push(url)),
});

const mapStateToProps = ({ authentication }: IApplicationState) => ({
    isSignedIn: authentication.fetchStatus === FetchStatus.SUCCESS,
});

const enhance = compose(
    withAnalytics,
    withDeviceType,
    withInputValidation(dataKeys),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const FooterContainer = enhance(Footer);

export { FooterContainer };
