import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { BodyCopy, Label, Subhead } from 'components/common/typography';
import { ButtonContainer as Button } from 'containers';

const styles = require('./home-notice.less');

interface Props {
    readonly label?: string;
    readonly title?: string;
    readonly body?: string;
    readonly actionOneLabel?: string;
    readonly actionOne?: () => void;
    readonly actionOneRedirect?: string;
    redirectTo(url: string): void;
}

@CSSModules(styles)
class HomeNotice extends React.Component<Props> {
    render() {
        const {
            props: {
                label,
                title,
                body,
                actionOneLabel,
                actionOne,
                actionOneRedirect,
                redirectTo,
            },
        } = this;
        return (
            <div styleName="wrapper">
                <div>
                    {label && (
                        <Label id="label" styleName="label">
                            {label}
                        </Label>
                    )}
                    {title && <Subhead styleName="title">{title}</Subhead>}
                    {body && <BodyCopy noMargin>{body}</BodyCopy>}
                </div>
                {actionOneLabel && (
                    <div styleName="button-container">
                        <Button
                            onClick={
                                actionOneRedirect
                                    ? () => redirectTo(actionOneRedirect)
                                    : actionOne
                            }
                            label={actionOneLabel}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export { HomeNotice };
