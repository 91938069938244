import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { IStayInLoop } from 'models';
import { ActionType, IStayInLoopAction } from './stay-in-loop.actions';

interface IStayInLoopState {
    readonly iStayInLoopData: IStayInLoop;
    readonly error: string;
    readonly emailFooter: string;
    readonly fetchStatus: FetchStatus;
}

const DEFAULT_STATE: IStayInLoopState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
    emailFooter: '',
    iStayInLoopData: {
        email: '',
        device: '',
        carrier: '',
        os: '',
    },
};

const {
    REPORT_ERROR_STAY_IN_LOOP,
    REPORT_SUCCESS_STAY_IN_LOOP,
    CAPTURE_STAY_IN_LOOP,
    SHOW_STAY_IN_LOOP_LANDING,
} = ActionType;

const stayInLoopReducer = (
    state: IStayInLoopState = DEFAULT_STATE,
    action: IStayInLoopAction | IDefaultAction,
): IStayInLoopState => {
    switch (action.type) {
        case CAPTURE_STAY_IN_LOOP:
            return {
                ...state,
                error: '',
                fetchStatus: FetchStatus.FETCHING,
                iStayInLoopData: action.payload,
            };
        case REPORT_ERROR_STAY_IN_LOOP:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        case REPORT_SUCCESS_STAY_IN_LOOP:
            return {
                ...state,
                error: '',
                fetchStatus: FetchStatus.SUCCESS,
            };
        case SHOW_STAY_IN_LOOP_LANDING:
            return {
                ...state,
                error: '',
                fetchStatus: FetchStatus.SUCCESS,
                iStayInLoopData: action.payload,
            };
        default:
            return state;
    }
};

export { IStayInLoopState, DEFAULT_STATE, stayInLoopReducer, IStayInLoop };
