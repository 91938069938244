import { AddressField } from 'components/common/forms/address-field';
import { IApplicationState, StartupScript } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAnalytics } from '../components/common/analytics';

const mapStateToProps = (state: IApplicationState) => ({
    apiDidInit:
        state.startup.initialized.indexOf(StartupScript.GOOGLE_SDK) > -1,
    apiError: state.startup.failed.indexOf(StartupScript.GOOGLE_SDK) > -1,
});

const enhance = compose(
    withAnalytics,
    connect(mapStateToProps),
);
const AddressFieldContainer = enhance(AddressField);

export { AddressFieldContainer };
