import * as React from 'react';

import { InputContainer as Input } from 'containers';
import { normalizeNumber } from 'utils';

interface Props {
    readonly mdn: string;
}

const YourNewNumber = ({ mdn }: Props) => (
    <Input
        label={__('phone-number.your-new-number.title')}
        id="mdn"
        readOnly
        value={normalizeNumber(mdn)}
    />
);

export { YourNewNumber };
