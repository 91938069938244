import { withAnalytics } from 'components/common/analytics';
import { withModal } from 'components/common/modal';
import { IneligibleNumberError } from 'components/sign-up/components/phone-number/errors/ineligible-number-error';
import { compose } from 'redux';

const enhance = compose(
    withModal,
    withAnalytics,
);
const IneligibleNumberErrorContainer = enhance(IneligibleNumberError);

export { IneligibleNumberErrorContainer };
