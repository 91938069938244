import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { ActionType, IGroupAction } from './groups.action';
import { GroupMembershipDetailResponse } from 'models';
import {
    cancelReducer,
    CancelState,
    DEFAULT_STATE as DEFAULT_CANCEL_STATE,
} from '../cancel';

import {
    DEFAULT_STATE as DEFAULT_PAUSE_STATE,
    pauseReducer,
    PauseState,
} from '../pause';

interface IGroupState {
    readonly groups: GroupMembershipDetailResponse;
    readonly groupFetchStatus: FetchStatus;
    readonly cancel: CancelState;
    readonly pause: PauseState;
}

const DEFAULT_GROUP_MEMBERSHIP: GroupMembershipDetailResponse = {
    GroupMembershipState: '',
    GroupName: '',
};

const DEFAULT_GROUP_STATE: IGroupState = {
    groups: DEFAULT_GROUP_MEMBERSHIP,
    groupFetchStatus: FetchStatus.NOT_FETCHED,
    cancel: DEFAULT_CANCEL_STATE,
    pause: DEFAULT_PAUSE_STATE,
};

const { REPORT_FETCH_GROUP_MEMBERS_SUCCESS } = ActionType;

const groups = (
    state: IGroupState = DEFAULT_GROUP_STATE,
    action: IGroupAction | IDefaultAction,
): IGroupState => {
    switch (action.type) {
        case REPORT_FETCH_GROUP_MEMBERS_SUCCESS:
            return {
                ...state,
                groups: action.payload,
                groupFetchStatus: FetchStatus.SUCCESS,
            };
        default:
            return {
                ...state,
                cancel: cancelReducer(state.cancel, action as IDefaultAction),
                pause: pauseReducer(state.pause, action as IDefaultAction),
            };
    }
};

export { DEFAULT_GROUP_STATE, IGroupState, groups };
