// import { Omit } from './omit';

interface IReducer<S, A> {
    // tslint:disable-next-line:callable-types
    (state: S, action: A): S;
}

interface IAction<T extends string, P = undefined> {
    readonly type: T;
    readonly payload: P;
}

interface IDefaultAction {
    readonly type: '';
}

function createAction<T extends string>(type: T): () => IAction<T, undefined>;

function createAction<T extends string, U>(
    type: T,
    actionCreator: () => U,
): () => IAction<T, U>;

function createAction<T extends string, U, V>(
    type: T,
    actionCreator: (payload: U) => V,
): (payload: U) => IAction<T, V>;

function createAction<T extends string, U, V>(
    type: T,
    actionCreator?: (payload: U) => V,
): (payload: U) => IAction<T, V> {
    if (actionCreator) {
        return (payload: U) => ({
            payload: actionCreator(payload),
            type,
        });
    }

    return (payload: any) => ({
        payload,
        type,
    });
}

// tslint:disable:readonly-array
function createActionAny<T extends string>(
    type: T,
    actionCreator: (...args: any[]) => any,
): (...args: any[]) => IAction<T, any> {
    return (...args: any[]) => ({
        payload: actionCreator(...args),
        type,
    });
}
// tslint:enable:readonly-array

// Typescript >=  2.4.0
enum FetchStatus {
    NOT_FETCHED = 'NOT_FETCHED',
    FETCHING = 'FETCHING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

interface IFetchResults<R, E = any> {
    readonly fetchStatus: FetchStatus;
    readonly results: R;
    readonly error?: E;
}

enum InitializationStatus {
    INITIALIZING = 'INITIALIZING',
    INITIALIZED = 'INITIALIZED',
    FAILED = 'FAILED',
}

/**
 * Removed "id" property from T.
 * In CRUD operations, the id property is generally
 * generated by server on create.
 */
// type Creatable<T extends { readonly id: string | number }> = Omit<T, 'id'>;
/**
 * Requires ONLY the "id" property in T.
 * In RESTful PATCHes, conventionally only the properties being updated are required.
 */
type Patchable<T> = Partial<T> & { readonly id: string | number };

interface CRUDReducerConfig<State> {
    readonly fetchAction: string;
    readonly successAction: string;
    readonly errorAction: string;
    readonly DEFAULT_STATE: State;
    readonly additionalCases?: ReadonlyArray<AdditionalCase<State>>;
}

interface AdditionalCase<State> {
    readonly type: string;
    callback(state: State, action: IAction<any, any> | IDefaultAction): State;
}

interface CRUDBaseState {
    readonly fetchStatus: FetchStatus;
    readonly error: string;
}

const createDefaultCRUDReducer = <State extends CRUDBaseState>(
    config: CRUDReducerConfig<State>,
) => (
    state: State = config.DEFAULT_STATE,
    action: IAction<any, any> | IDefaultAction,
): State => {
    switch (action.type) {
        case config.fetchAction:
            return Object.assign({}, state, {
                error: '',
                fetchStatus: FetchStatus.FETCHING,
            });
        case config.successAction:
            if (typeof (action as any).payload !== 'undefined') {
                return Object.assign(
                    {},
                    state,
                    { fetchStatus: FetchStatus.SUCCESS },
                    (action as any).payload,
                );
            }

            return Object.assign({}, state, {
                fetchStatus: FetchStatus.SUCCESS,
            });
        case config.errorAction:
            return Object.assign({}, state, {
                error: (action as any).payload,
                fetchStatus: FetchStatus.ERROR,
            });
        default:
            if (config.additionalCases && config.additionalCases.length) {
                const adtlCase = config.additionalCases.find(
                    c => c.type === action.type,
                );
                if (adtlCase) {
                    return adtlCase.callback(state, action);
                }
            }

            return state;
    }
};

const createStateMerger = <State extends object>(previousState: State) => (
    updates: Partial<State>,
) => Object.assign({}, previousState, updates);

export {
    IAction,
    IDefaultAction,
    IReducer,
    IFetchResults,
    createAction,
    createActionAny,
    FetchStatus,
    InitializationStatus,
    // Creatable,
    Patchable,
    createDefaultCRUDReducer,
    CRUDBaseState,
    createStateMerger,
};
