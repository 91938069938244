import { createAction, IAction } from 'lib/br-redux';

enum ActionType {
    RESUBMIT_PHONE_NUMBER = 'PURCHASE/PORT/RESUBMISSION/RESUBMIT_PHONE_NUMBER',
    RESUBMIT_ACCOUNT_NUMBER = 'PURCHASE/PORT/RESUBMISSION/RESUBMIT_ACCOUNT_NUMBER',
    RESUBMIT_PIN = 'PURCHASE/PORT/RESUBMISSION/RESUBMIT_PIN',

    REPORT_RESUBMISSION_SUCCESS = 'PURCHASE/PORT/RESUBMISSION/REPORT_RESUBMISSION_SUCCESS',
    REPORT_RESUBMISSION_ERROR = 'PURCHASE/PORT/RESUBMISSION/REPORT_RESUBMISSION_ERROR',
}

type IResubmitPhoneNumberAction = IAction<
    ActionType.RESUBMIT_PHONE_NUMBER,
    string
>;
type IResubmitAccountNumberAction = IAction<
    ActionType.RESUBMIT_ACCOUNT_NUMBER,
    string
>;
type IResubmitPINAction = IAction<ActionType.RESUBMIT_PIN, string>;

type IReportResubmissionSuccessAction = IAction<
    ActionType.REPORT_RESUBMISSION_SUCCESS
>;
type IReportResubmissionErrorAction = IAction<
    ActionType.REPORT_RESUBMISSION_ERROR,
    string
>;

type IResubmissionAction =
    | IResubmitPhoneNumberAction
    | IResubmitAccountNumberAction
    | IResubmitPINAction
    | IReportResubmissionSuccessAction
    | IReportResubmissionErrorAction;

const resubmitPhoneNumber = createAction(
    ActionType.RESUBMIT_PHONE_NUMBER,
    (updated: string) => updated,
);
const resubmitAccountNumber = createAction(
    ActionType.RESUBMIT_ACCOUNT_NUMBER,
    (updated: string) => updated,
);
const resubmitPIN = createAction(
    ActionType.RESUBMIT_PIN,
    (updated: string) => updated,
);
const reportResubmissionSuccess = createAction(
    ActionType.REPORT_RESUBMISSION_SUCCESS,
);
const resportResubmissionError = createAction(
    ActionType.REPORT_RESUBMISSION_ERROR,
    (error: string) => error,
);

export {
    ActionType,
    IResubmissionAction,
    IResubmitAccountNumberAction,
    IResubmitPhoneNumberAction,
    IResubmitPINAction,
    resubmitAccountNumber,
    resubmitPhoneNumber,
    resubmitPIN,
    reportResubmissionSuccess,
    resportResubmissionError,
};
