import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { BodyCopy } from 'components/common/typography';
import {
    ButtonContainer as Button,
    ModalWrapperContainer as ModalWrapper,
} from 'containers';
import { IAnalyticsProps, IDeviceTypeProps, IModalProps } from 'models';

const styles = require('./autopay-confirm.less');

interface Props extends IModalProps, IDeviceTypeProps, IAnalyticsProps {
    readonly showActions?: boolean;
}

@CSSModules(styles)
class AutopayConfirm extends React.Component<Props> {
    render() {
        const {
            props: {
                bindHandleClickWith,
                isDesktop,
                isMobile,
                onConfirm,
                showActions = true,
            },
        } = this;
        return (
            <ModalWrapper closable title={__('autopay-confirm.title')}>
                <>
                    <BodyCopy medium>{__('autopay-confirm.p1')}</BodyCopy>
                    <BodyCopy medium>{__('autopay-confirm.p2')}</BodyCopy>
                    {showActions ? (
                        <>
                            <Button
                                fullWidth={isMobile}
                                minWidth={isDesktop ? 222 : undefined}
                                onClick={bindHandleClickWith(onConfirm)}
                                label={__('autopay-confirm.agree')}
                            />
                            <BodyCopy
                                noMarginBottom
                                medium
                                styleName="disclaimer"
                                label={__('autopay-confirm.disclaimer')}
                            />
                        </>
                    ) : null}
                </>
            </ModalWrapper>
        );
    }
}

export { AutopayConfirm };
