import * as React from 'react';

import { ActionHandlerMode } from 'components/email-action-handler';
import { FourOhFour } from 'components/four-oh-four';
import { ResetPasswordContainer, VerifyEmailContainer } from 'containers';
import { parse } from 'querystring';

interface Props {
    readonly location: Location;
}

interface State {
    readonly mode: ActionHandlerMode;
}

class EmailActionRouter extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { mode: ActionHandlerMode.UNKNOWN };
    }

    componentDidMount() {
        this.setState({ mode: this.params.mode || ActionHandlerMode.UNKNOWN });
    }

    render() {
        switch (this.state.mode) {
            case ActionHandlerMode.RESET_PASSWORD:
                return (
                    <ResetPasswordContainer location={this.props.location} />
                );
            case ActionHandlerMode.VERIFY_EMAIL:
                return <VerifyEmailContainer location={this.props.location} />;
            default:
                return <FourOhFour />;
        }
    }

    private get params() {
        try {
            const obj = parse(this.props.location.search);
            return Object.keys(obj).reduce(
                this.stripLeadingQuestionMarkFromKeys(obj),
                {},
            );
        } catch (err) {
            return {};
        }
    }

    private readonly stripLeadingQuestionMarkFromKeys = origObj => (
        obj,
        key: string,
    ) => {
        obj[this.stripLeadingQuestionMark(key)] = origObj[key];
        return obj;
    };

    private readonly stripLeadingQuestionMark = (str: string): string => {
        return str.charAt(0) === '?' ? str.substr(1) : str;
    };
}

export { EmailActionRouter };
