import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { DetailedChargeEvent, SummaryChargeEvent } from 'models';
import { ActionType, BillAction } from './bill.actions';

interface IBillHistoryState {
    readonly fetchStatus: FetchStatus;
    readonly error: string;
    readonly results: ReadonlyArray<DetailedChargeEvent>;
}

const DEFAULT_STATE: IBillHistoryState = {
    // currentBill: null,
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
    results: [],
};

const {
    FETCH_BILL_HISTORY,
    REPORT_FETCH_BILL_HISTORY_ERROR,
    REPORT_FETCH_BILL_HISTORY_SUCCESS,

    FETCH_BILL_DETAILS,
    REPORT_FETCH_BILL_DETAILS_ERROR,
    REPORT_FETCH_BILL_DETAILS_SUCCESS,
} = ActionType;

const summaryEventToDetailedEvent = (
    summary: SummaryChargeEvent,
): DetailedChargeEvent => ({
    ...summary,
    detailStatement: { error: '', fetchStatus: FetchStatus.NOT_FETCHED },
    details: summary.details,
});

const billHistoryReducer = (
    state: IBillHistoryState = DEFAULT_STATE,
    action: BillAction | IDefaultAction,
): IBillHistoryState => {
    switch (action.type) {
        case FETCH_BILL_HISTORY:
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        case REPORT_FETCH_BILL_HISTORY_SUCCESS:
            return {
                ...state,
                fetchStatus: FetchStatus.SUCCESS,
                results: action.payload.charge_event.map(
                    summaryEventToDetailedEvent,
                ),
            };
        case REPORT_FETCH_BILL_HISTORY_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };

        case FETCH_BILL_DETAILS:
            const idxToFetch = eventIdxForId(action.payload, state.results);
            return idxToFetch !== -1
                ? {
                      ...state,
                      results: [
                          ...state.results.slice(0, idxToFetch),
                          {
                              ...state.results[idxToFetch],
                              detailStatement: {
                                  error: '',
                                  fetchStatus: FetchStatus.FETCHING,
                              },
                          },
                          ...state.results.slice(idxToFetch + 1),
                      ],
                  }
                : state;
        case REPORT_FETCH_BILL_DETAILS_ERROR:
            const idxToError = eventIdxForId(action.payload.id, state.results);
            return idxToError !== -1
                ? {
                      ...state,
                      results: [
                          ...state.results.slice(0, idxToError),
                          {
                              ...state.results[idxToError],
                              detailStatement: {
                                  error: action.payload.error,
                                  fetchStatus: FetchStatus.ERROR,
                              },
                          },
                          ...state.results.slice(idxToError + 1),
                      ],
                  }
                : state;
        case REPORT_FETCH_BILL_DETAILS_SUCCESS:
            const idxToLoad = eventIdxForId(
                action.payload.eventId,
                state.results,
            );

            return idxToLoad !== -1
                ? {
                      ...state,
                      results: [
                          ...state.results.slice(0, idxToLoad),
                          {
                              ...state.results[idxToLoad],
                              detailStatement: {
                                  details: [...action.payload.detail],
                                  fetchStatus: FetchStatus.SUCCESS,
                              },
                          },
                          ...state.results.slice(idxToLoad + 1),
                      ],
                  }
                : state;
        default:
            return state;
    }
};

const eventIdxForId = (
    id: string,
    events: ReadonlyArray<DetailedChargeEvent>,
): number => events.findIndex(e => e.eventid === id);

export { IBillHistoryState, billHistoryReducer, DEFAULT_STATE };
