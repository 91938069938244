import { createAction, IAction } from 'lib/br-redux';
import { StartupScript } from 'models';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    REPORT_INITIALIZATION_SUCCESS = 'STARTUP/REPORT_INITIALIZATION_SUCCESS',
    REPORT_INITIALIZATION_FAILURE = 'STARTUP/REPORT_INITIALIZATION_FAILURE',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type IReportInitializationSuccess = IAction<
    ActionType.REPORT_INITIALIZATION_SUCCESS,
    StartupScript
>;
type IReportInitializationFailure = IAction<
    ActionType.REPORT_INITIALIZATION_FAILURE,
    StartupScript
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IStartupAction =
    | IReportInitializationSuccess
    | IReportInitializationFailure;

// ------------------------------------
// Action Creators
// i.e. the stuff that actually makes it to the compiled Javascript
// ------------------------------------

const reportInitializationSuccess = createAction(
    ActionType.REPORT_INITIALIZATION_SUCCESS,
    (script: StartupScript) => script,
);

const reportInitializationFailure = createAction(
    ActionType.REPORT_INITIALIZATION_FAILURE,
    (script: StartupScript) => script,
);

export {
    ActionType,
    IReportInitializationFailure,
    IReportInitializationSuccess,
    IStartupAction,
    reportInitializationFailure,
    reportInitializationSuccess,
};
