import { EditInfo } from 'components/account/edit-info';
import { withAnalytics } from 'components/common/analytics';
import { withInputValidation } from 'components/common/forms/input';
import { Account, IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateUserAccountOld } from 'state/account';
import { resetPasswordForEmail } from 'state/account/account.actions';
import { reauthenticate } from 'state/authentication/authentication.actions';
import { resendVerificationEmail } from '../state/authentication/authentication.actions';

const dataKeys: ReadonlyArray<string> = [
    'firstName',
    'lastName',
    'email',
    'billingAddress',
    'billingAddressLine2',
    'shippingAddress',
    'shippingAddressLine2',
];

const mapStateToProps = (state: IApplicationState) => ({
    account: new Account(state.account),
    reauthenticationStatus: state.authentication.reauthenticationStatus,
    resetPasswordError: state.account.resetPasswordError,
    resetPasswordFetchStatus: state.account.resetPasswordFetchStatus,
    resetPasswordSuccess: state.account.resetPasswordSuccess,
    updateAccountErrorMessage: state.account.updateAccountError,
    updateAccountFetchStatus: state.account.updateAccountFetchStatus,
});

const mapDispatchToProps = dispatch => ({
    reauthenticate: () => dispatch(reauthenticate()),
    resendVerificationEmail: () => dispatch(resendVerificationEmail()),
    resetPassword: email => dispatch(resetPasswordForEmail(email)),
    updateAccount: account => dispatch(updateUserAccountOld(account)),
});

const enhance = compose(
    withInputValidation(dataKeys),
    withAnalytics,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const EditInfoContainer = enhance(EditInfo);

export { EditInfoContainer };
