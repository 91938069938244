import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./grid.less');

interface RowProps {
    readonly children?: any;
    readonly noPadding?: boolean;
    readonly mobileFlex?: boolean;
}

interface ColProps {
    readonly children?: any;
    readonly width?: number;
    readonly mobileWidth?: number;
    readonly alignSelfEnd?: boolean;
    readonly justifyContentEnd?: boolean;
    readonly flexFlowCol?: boolean;
    readonly alignCenter?: boolean;
    readonly topPadding?: boolean;
    readonly leftMargin?: boolean;
    readonly minHeight20?: boolean;
}

const RowComponent = ({ children, noPadding, mobileFlex }: RowProps) => {
    return (
        <div styleName={classNames('row', { noPadding, mobileFlex })}>
            {children}
        </div>
    );
};

const Row = CSSModules(RowComponent, styles, {
    allowMultiple: true,
});

const ColComponent = ({
    children,
    width,
    mobileWidth,
    alignSelfEnd,
    alignCenter,
    topPadding,
    justifyContentEnd,
    flexFlowCol,
    leftMargin,
    minHeight20,
}: ColProps) => {
    return (
        <div
            styleName={classNames(
                'col',
                `col-${width}`,
                `col-sm-${mobileWidth}`,
                {
                    topPadding,
                    alignSelfEnd,
                    justifyContentEnd,
                    flexFlowCol,
                    alignCenter,
                    leftMargin,
                    minHeight20,
                },
            )}
        >
            {children}
        </div>
    );
};

const Col = CSSModules(ColComponent, styles, {
    allowMultiple: true,
});

export { Row, RowProps, Col, ColProps };
