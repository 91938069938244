import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import * as classNames from 'classnames';
import { GridImage } from 'components/grid/grid-image';
import { splitProps } from 'utils';

const styles = require('./grid-cell.less');

enum Modifiers {
    center = 'center',
    blue = 'blue',
    lightGray = 'lightGray',
    alignStart = 'alignStart',
    reversed = 'reversed',
    formatMobile = 'formatMobile',
    showInMobile = 'showInMobile',
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    xl = 'xl',
    full = 'full',
}

interface GridCellProps {
    readonly center?: boolean;
    readonly blue?: boolean;
    readonly imageSrc?: string;
    readonly imageSrcMobile?: string;
    readonly alignStart?: boolean;
    readonly reversed?: boolean;
    readonly lightGray?: boolean;
    readonly children?: any;
    readonly formatMobile?: boolean;
    readonly showInMobile?: boolean;
    readonly xs?: boolean;
    readonly sm?: boolean;
    readonly md?: boolean;
    readonly lg?: boolean;
    readonly xl?: boolean;
    readonly full?: boolean;
    readonly width?: number;
    readonly mobileWidth?: number;
}

const GridCellComponent = ({
    children,
    imageSrc,
    imageSrcMobile,
    width = 6,
    mobileWidth = 12,
    ...props
}: GridCellProps) => {
    const [modifiers] = splitProps(props, Modifiers);
    const gridImage = imageSrc ? <GridImage src={imageSrc} {...props} /> : null;
    const gridImageMobile = imageSrcMobile ? (
        <GridImage src={imageSrcMobile} imageMobile {...props} />
    ) : null;
    const sectionStyleName = gridImageMobile
        ? classNames('cell', 'showMobileImage', modifiers)
        : classNames('cell', modifiers);
    return (
        <section styleName={sectionStyleName}>
            <div
                styleName={classNames(
                    'content',
                    `col-${width}`,
                    `col-sm-${mobileWidth}`,
                )}
            >
                <div styleName="children-wrapper">{children}</div>
            </div>
            {gridImage}
            {gridImageMobile}
        </section>
    );
};

const GridCell = CSSModules(GridCellComponent, styles, { allowMultiple: true });
export { GridCell, GridCellProps };
