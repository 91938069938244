import * as moment from 'moment';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { ContentWrapper } from 'components/common/content-wrapper';
import { Dots } from 'components/common/dots';
import { Chevron } from 'components/common/graphics';
import { BodyCopy, ErrorMsg, InfoLink } from 'components/common/typography';
import { FetchStatus } from 'lib/br-redux';
import { AOAccountOrderResponse } from 'models';

const styles = require('./order-history.less');

interface IPropsFromState {
    readonly isDesktop?: boolean;
    readonly fetchStatus: FetchStatus;
    readonly orderHistories: ReadonlyArray<AOAccountOrderResponse>;
}

interface IPropsFromDispatch {
    fetchOrderHistory(): void;
}

type Props = IPropsFromState & IPropsFromDispatch;

interface State {
    readonly expandedSection: number;
}

const DEFAULT_STATE: State = {
    expandedSection: -1,
};

@CSSModules(styles, { allowMultiple: true })
class OrderHistory extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    componentDidMount() {
        this.props.fetchOrderHistory();
    }

    render(): JSX.Element {
        return <ContentWrapper>{this.content}</ContentWrapper>;
    }

    private get content(): JSX.Element {
        switch (this.props.fetchStatus) {
            case FetchStatus.ERROR:
                return <ErrorMsg>{__('not-available-error')}</ErrorMsg>;
            case FetchStatus.SUCCESS:
                return this.history;
            case FetchStatus.NOT_FETCHED:
                return this.history;
            default:
                return <Dots invert />;
        }
    }

    private get history(): JSX.Element {
        return (
            <div styleName="order-history-wrapper">
                <div styleName="history-labels">
                    <div styleName="label">
                        <BodyCopy inline>{__('account.history.date')}</BodyCopy>
                    </div>
                    <div styleName="label">
                        <BodyCopy inline>
                            {__('account.history.order-number')}
                        </BodyCopy>
                    </div>
                    <div styleName="label details-header">
                        <BodyCopy inline>
                            {__('account.history.details')}
                        </BodyCopy>
                    </div>
                </div>
                <div styleName="order-history-list">
                    {this.props.orderHistories.map(this.orderHistoryToListItem)}
                </div>
            </div>
        );
    }

    private readonly orderHistoryToListItem = (
        orderHistory: AOAccountOrderResponse,
        idx: number,
    ) => {
        const {
            props: { isDesktop },
        } = this;
        return !isDesktop ? (
            <div
                styleName="order-history-list-item"
                key={`orderHistory${idx}`}
                onClick={this.expandOrderHistoryBox(idx)}
            >
                <div styleName="item-section">
                    <div styleName="history-info">
                        <BodyCopy large inline>
                            {moment(orderHistory.OrderDate).format(
                                'MMM D, YYYY',
                            )}
                        </BodyCopy>
                    </div>
                    <div styleName="history-info">
                        <BodyCopy large inline>
                            {orderHistory.OrderNumber}
                        </BodyCopy>
                    </div>
                    <div styleName="history-info">
                        <BodyCopy large inline>
                            {orderHistory.ProductType}
                        </BodyCopy>
                    </div>
                    <div styleName="history-info details-view">
                        <InfoLink
                            plain
                            to={`${__('routes.account.order-details').replace(
                                ':orderNumber',
                                orderHistory.OrderNumber,
                            )}`}
                        >
                            <Chevron blue right />
                        </InfoLink>
                    </div>
                </div>
            </div>
        ) : (
            <div
                styleName="order-history-list-item"
                key={`orderHistory${idx}`}
                onClick={this.expandOrderHistoryBox(idx)}
            >
                <div styleName="history-info">
                    <BodyCopy inline>
                        {moment(orderHistory.OrderDate).format('MMM D, YYYY')}
                    </BodyCopy>
                </div>
                <div styleName="history-info">
                    <BodyCopy inline>{orderHistory.OrderNumber}</BodyCopy>
                </div>
                <div styleName="history-info history-details">
                    <div styleName="bill-details">
                        <BodyCopy inline>{orderHistory.ProductType}</BodyCopy>
                    </div>
                    <InfoLink
                        plain
                        to={`${__('routes.account.order-details').replace(
                            ':orderNumber',
                            orderHistory.OrderNumber,
                        )}`}
                    >
                        <Chevron blue right />
                    </InfoLink>
                </div>
            </div>
        );
    };

    private readonly expandOrderHistoryBox = (
        clickedIndex: number,
    ): (() => void) => () => {
        if (this.state.expandedSection === clickedIndex) {
            // clicked on the expanded section, close it
            this.setState({ expandedSection: -1 });
        } else {
            // clicked a different section, close the open one, open the new one
            this.setState({ expandedSection: clickedIndex });
        }
    };
}

export { OrderHistory };
