import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { ActionType, IAutopayAction } from './autopay.actions';

interface IAutoPayState {
    readonly status: boolean;
    readonly error: string;
    readonly fetchStatus: FetchStatus;
}

const DEFAULT_STATE: IAutoPayState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
    status: false,
};

const {
    GET_AUTOPAY_STATUS,
    LOAD_AUTOPAY_STATUS,
    REPORT_AUTOPAY_STATUS_ERROR,
    SET_AUTOPAY_STATUS,
} = ActionType;

const autoPayReducer = (
    state: IAutoPayState = DEFAULT_STATE,
    action: IAutopayAction | IDefaultAction,
): IAutoPayState => {
    switch (action.type) {
        case GET_AUTOPAY_STATUS:
        case SET_AUTOPAY_STATUS:
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        case LOAD_AUTOPAY_STATUS:
            return {
                ...state,
                fetchStatus: FetchStatus.SUCCESS,
                status: action.payload,
            };
        case REPORT_AUTOPAY_STATUS_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        default:
            return state;
    }
};

export { IAutoPayState, DEFAULT_STATE, autoPayReducer };
