import { withAnalytics } from 'components/common/analytics';
import { withDeviceType } from 'components/common/device-type';
import { SignUp } from 'components/sign-up';
import { get } from 'lodash';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { showAccountSignupModal } from 'state/account';
import { showRemovedSim } from 'state/purchase/cart/cart.actions';
import { startRegistrationWithSocialInfo } from 'state/registration/registration.actions';
import {
    dismissLoadingIndicator,
    requestLoadingIndicator,
} from 'state/view/view.actions';
import { HandsetCartItem, ProductType } from '../models';

const key = 'sign-up';

const mapStateToProps = ({
    authentication,
    account,
    purchase,
    purchase: {
        cart: { result: cart },
    },
    payment,
    registration,
    view,
}: IApplicationState) => ({
    ...purchase,
    accountid: account.accountid,
    authInitStatus: authentication.initializationStatus,
    authStatus: authentication.fetchStatus,
    billingAddress: account.address.billing_address,
    device: cart
        ? (cart.itemForProductType(ProductType.HANDSET) as HandsetCartItem)
        : null,
    isShowingLoading: view.loadingRequests.indexOf(key) !== -1,
    paymentNonce: payment.paymentNonce,
    registrationStatus: registration.fetchStatus,
    servicePlan: get('products.results[0]', '{}'),
    shippingAddress: account.address.shipping_address,
});

const mapDispatchToProps = dispatch => ({
    dismissLoadingIndicator: () => dispatch(dismissLoadingIndicator(key)),
    redirectTo: (url: string) => dispatch(push(url)),
    requestLoadingIndicator: () => dispatch(requestLoadingIndicator(key)),
    showAccountSignupModal: () => dispatch(showAccountSignupModal()),
    showRemovedSim: () => dispatch(showRemovedSim()),
    startRegistrationWithSocialInfo: () =>
        dispatch(startRegistrationWithSocialInfo()),
});

const enhance = compose(
    withAnalytics,
    withDeviceType,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const SignUpContainer = enhance(SignUp);

export { SignUpContainer };
