import { HomeNotice } from 'components/home/notice/home-notice';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';

const mapDispatchToProps = dispatch => ({
    redirectTo: (url: string) => dispatch(push(url)),
});

const enhance = compose(
    connect(
        null,
        mapDispatchToProps,
    ),
);
const HomeNoticeContainer = enhance(HomeNotice);

export { HomeNoticeContainer };
