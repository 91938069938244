import { createAction, IAction } from 'lib/br-redux';
import {
    AOAccountOrderResponse,
    Cart,
    CartItem,
    OrderDetailResponse,
    ProductType,
} from 'models';
import { ICartItemPayload } from 'models/products/products.model';
import { IOrderResponseWithPromotions } from 'models/account';

interface IAddItemErrorPayload {
    readonly productType: ProductType;
    readonly error: string;
}

interface IDeleteItemErrorPayload {
    readonly productType: ProductType;
    readonly error: string;
}

enum ActionType {
    SET_CART_ID = 'PURCHASE/CART/SET_CART_ID',

    ADD_ITEM_TO_CART = 'PURCHASE/CART/ADD_ITEM_TO_CART',
    REPORT_ADD_ITEM_SUCCESS = 'PURCHASE/CART/REPORT_ADD_ITEM_SUCCESSS',
    REPORT_ADD_ITEM_ERROR = 'PURCHASE/CART/REPORT_ADD_ITEM_ERROR',

    DELETE_ITEM_FROM_CART = 'PURCHASE/CART/DELETE_ITEM_FROM_CART',
    REPORT_DELETE_ITEM_SUCCESS = 'PURCHASE/CART/REPORT_DELETE_ITEM_SUCCESSS',
    REPORT_DELETE_ITEM_ERROR = 'PURCHASE/CART/REPORT_DELETE_ITEM_ERROR',

    FETCH_CART = 'PURCHASE/CART/FETCH_CART',
    REPORT_FETCH_CART_SUCCESS = 'PURCHASE/CART/REPORT_FETCH_CART_SUCCESS',
    REPORT_FETCH_CART_ERROR = 'PURCHASE/CART/REPORT_FETCH_CART_ERROR',

    FETCH_ORDERS = 'PURCHASE/ORDER/FETCH_ORDERS',
    REPORT_FETCH_ORDERS_SUCCESS = 'PURCHASE/ORDER/REPORT_FETCH_ORDERS_SUCCESS',
    REPORT_FETCH_ORDERS_ERROR = 'PURCHASE/ORDER/REPORT_FETCH_ORDERS_ERROR',

    FETCH_ORDER_DETAILS = 'PURCHASE/ORDER/FETCH_ORDER_DETAILS',
    FETCH_ORDER_DETAILS_SUCCESS = 'PURCHASE/ORDER/FETCH_ORDER_DETAILS_SUCCESS',

    CLEAR_CART_ERRORS = 'PURCHASE/CART/CLEAR_ERRORS',

    SHOW_REMOVED_SIM = 'PURCHASE/CART/SHOW_REMOVED_SIM',
    REPORT_REMOVED_SIM = 'PURCHASE/CART/REPORT_REMOVED_SIM',

    DELETE_DEVICE_FROM_CART_CONFIRM = 'PURCHASE/CART/DELETE_DEVICE_FROM_CART_CONFIRM',
    DELETE_DEVICE_FROM_CART = 'PURCHASE/CART/DELETE_DEVICE_FROM_CART',

    DELETE_DRAFT_ORDER_HANDSET = 'PURCHASE/CART/DELETE_DRAFT_ORDER_HANDSET',
    DELETE_DRAFT_ORDER_HANDSET_SUCCESS = 'PURCHASE/CART/DELETE_DRAFT_ORDER_HANDSET_SUCCESS',
    DELETE_DRAFT_ORDER_HANDSET_ERROR = 'PURCHASE/CART/DELETE_DRAFT_ORDER_HANDSET_ERROR',
}

type ISetCartIdAction = IAction<ActionType.SET_CART_ID, string>;

type IAddItemAction = IAction<ActionType.ADD_ITEM_TO_CART, ICartItemPayload>;
type IReportAddItemSuccessAction = IAction<
    ActionType.REPORT_ADD_ITEM_SUCCESS,
    ProductType
>;
type IReportAddItemErrorAction = IAction<
    ActionType.REPORT_ADD_ITEM_ERROR,
    IAddItemErrorPayload
>;

type IDeleteItemAction = IAction<ActionType.DELETE_ITEM_FROM_CART, CartItem>;
type IReportDeleteItemSuccessAction = IAction<
    ActionType.REPORT_DELETE_ITEM_SUCCESS,
    ProductType
>;
type IReportDeleteItemErrorAction = IAction<
    ActionType.REPORT_DELETE_ITEM_ERROR,
    IAddItemErrorPayload
>;

type IFetchCartAction = IAction<ActionType.FETCH_CART>;
type IFetchOrdersAction = IAction<ActionType.FETCH_ORDERS>;

type IFetchOrdersSuccessAction = IAction<
    ActionType.REPORT_FETCH_ORDERS_SUCCESS,
    ReadonlyArray<AOAccountOrderResponse>
>;

type IReportFetchOrdersErrorAction = IAction<
    ActionType.REPORT_FETCH_ORDERS_ERROR,
    string
>;

type IFetchOrderDetailsAction = IAction<ActionType.FETCH_ORDER_DETAILS, string>;
type IFetchOrderDetailsSuccessAction = IAction<
    ActionType.FETCH_ORDER_DETAILS_SUCCESS,
    OrderDetailResponse
>;

type IReportFetchCartSuccessAction = IAction<
    ActionType.REPORT_FETCH_CART_SUCCESS,
    Cart
>;
type IReportFetchCartErrorAction = IAction<
    ActionType.REPORT_FETCH_CART_ERROR,
    string
>;

type IClearErrorsAction = IAction<ActionType.CLEAR_CART_ERRORS>;

type IShowRemovedSimAction = IAction<ActionType.SHOW_REMOVED_SIM>;
type IReportDeleteSimAction = IAction<ActionType.REPORT_REMOVED_SIM>;
type IDeleteDeviceFromCartConfirmAction = IAction<
    ActionType.DELETE_DEVICE_FROM_CART_CONFIRM
>;
type IDeleteDeviceFromCartAction = IAction<ActionType.DELETE_DEVICE_FROM_CART>;

type ICartAction =
    | ISetCartIdAction
    | IAddItemAction
    | IReportAddItemSuccessAction
    | IReportAddItemErrorAction
    | IDeleteItemAction
    | IReportDeleteItemSuccessAction
    | IReportDeleteItemErrorAction
    | IReportAddItemSuccessAction
    | IReportAddItemErrorAction
    | IFetchCartAction
    | IFetchOrdersAction
    | IFetchOrderDetailsAction
    | IFetchOrderDetailsSuccessAction
    | IReportFetchCartSuccessAction
    | IReportFetchCartErrorAction
    | IClearErrorsAction
    | IShowRemovedSimAction
    | IReportDeleteSimAction
    | IReportFetchOrdersErrorAction
    | IDeleteDeviceFromCartConfirmAction
    | IFetchOrdersSuccessAction
    | IDeleteDeviceFromCartAction;

const setCartId = createAction(ActionType.SET_CART_ID, (id: string) => id);

const addItemToCart = createAction(
    ActionType.ADD_ITEM_TO_CART,
    (item: ICartItemPayload) => item,
);
const reportAddItemSuccess = createAction(
    ActionType.REPORT_ADD_ITEM_SUCCESS,
    (productType: ProductType) => productType, // TODO: figure out how to be more specific
);
const reportAddItemError = createAction(
    ActionType.REPORT_ADD_ITEM_ERROR,
    ({ productType, error }: IAddItemErrorPayload) => ({ productType, error }),
);

const deleteItemFromCart = createAction(
    ActionType.DELETE_ITEM_FROM_CART,
    (item: CartItem) => item,
);
const reportDeleteItemSuccess = createAction(
    ActionType.REPORT_DELETE_ITEM_SUCCESS,
    (productType: ProductType) => productType, // TODO: figure out how to be more specific
);
const reportDeleteItemError = createAction(
    ActionType.REPORT_DELETE_ITEM_ERROR,
    ({ productType, error }: IDeleteItemErrorPayload) => ({
        error,
        productType,
    }),
);
const fetchOrders = createAction(ActionType.FETCH_ORDERS);

const reportFetchOrdersSuccess = createAction(
    ActionType.REPORT_FETCH_ORDERS_SUCCESS,
    (orderResponse: IOrderResponseWithPromotions) => orderResponse,
);
const reportFetchOrdersError = createAction(
    ActionType.REPORT_FETCH_ORDERS_ERROR,
    (err: string) => err,
);

const fetchCart = createAction(ActionType.FETCH_CART);
const reportFetchCartSuccess = createAction(
    ActionType.REPORT_FETCH_CART_SUCCESS,
    (cart: Cart) => cart,
);
const reportFetchCartError = createAction(
    ActionType.REPORT_FETCH_CART_ERROR,
    (err: string) => err,
);

const fetchOrderDetails = createAction(
    ActionType.FETCH_ORDER_DETAILS,
    (orderNumber: string) => orderNumber,
);

const fetchOrderDetailsSuccess = createAction(
    ActionType.FETCH_ORDER_DETAILS_SUCCESS,
    (orderDetailResponse: OrderDetailResponse) => orderDetailResponse,
);

const clearCartErrors = createAction(ActionType.CLEAR_CART_ERRORS);

const showRemovedSim = createAction(ActionType.SHOW_REMOVED_SIM);

const reportDeleteSim = createAction(ActionType.REPORT_REMOVED_SIM);

const deleteDeviceFromCartConfirm = createAction(
    ActionType.DELETE_DEVICE_FROM_CART_CONFIRM,
);
const deleteDeviceFromCart = createAction(
    ActionType.DELETE_DEVICE_FROM_CART,
    (newDevice: string) => newDevice,
);

const deleteDraftOrderFromCart = createAction(
    ActionType.DELETE_DRAFT_ORDER_HANDSET,
);
const deleteDraftOrderFromCartSuccess = createAction(
    ActionType.DELETE_DRAFT_ORDER_HANDSET_SUCCESS,
);
const deleteDraftOrderFromCartError = createAction(
    ActionType.DELETE_DRAFT_ORDER_HANDSET_ERROR,
);

export {
    ActionType,
    ICartAction,
    ISetCartIdAction,
    IAddItemAction,
    IReportAddItemSuccessAction,
    IReportAddItemErrorAction,
    IDeleteItemAction,
    IReportDeleteItemSuccessAction,
    IReportDeleteItemErrorAction,
    IFetchCartAction,
    IFetchOrderDetailsAction,
    IReportFetchCartSuccessAction,
    IReportFetchCartErrorAction,
    setCartId,
    addItemToCart,
    deleteItemFromCart,
    clearCartErrors,
    reportAddItemSuccess,
    reportAddItemError,
    reportDeleteItemSuccess,
    reportDeleteItemError,
    fetchCart,
    fetchOrders,
    reportFetchOrdersSuccess,
    reportFetchOrdersError,
    fetchOrderDetails,
    fetchOrderDetailsSuccess,
    reportFetchCartSuccess,
    reportFetchCartError,
    showRemovedSim,
    reportDeleteSim,
    deleteDeviceFromCartConfirm,
    deleteDeviceFromCart,
    deleteDraftOrderFromCart,
    deleteDraftOrderFromCartSuccess,
    deleteDraftOrderFromCartError,
};
