import { createStateMerger, FetchStatus, IDefaultAction } from 'lib/br-redux';
import { ICarrier } from 'models/port';
import { ActionType, ICarriersAction } from './carriers.actions';

interface ICarriersState {
    readonly fetchStatus: FetchStatus;
    readonly error: string;
    readonly results: ReadonlyArray<ICarrier>;
}

const DEFAULT_STATE = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
    results: [],
};

const {
    FETCH_CARRIERS,
    CLEAR_CARRIERS,
    LOAD_CARRIERS,
    REPORT_CARRIERS_ERROR,
} = ActionType;

const carriersReducer = (
    state: ICarriersState = DEFAULT_STATE,
    action: ICarriersAction | IDefaultAction,
): ICarriersState => {
    const merge = createStateMerger(state);
    switch (action.type) {
        case CLEAR_CARRIERS:
            return DEFAULT_STATE;
        case FETCH_CARRIERS:
            return merge({ fetchStatus: FetchStatus.FETCHING });
        case LOAD_CARRIERS:
            return merge({
                error: '',
                fetchStatus: FetchStatus.SUCCESS,
                results: action.payload,
            });
        case REPORT_CARRIERS_ERROR:
            return merge({
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            });
        default:
            return state;
    }
};

export { DEFAULT_STATE, ICarriersState, carriersReducer };
