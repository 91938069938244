import { EmailActionRouter } from 'components/email-action-router';
import { connect } from 'react-redux';

const mapStateToProps = (_, ownProps) => ({
    location: ownProps.location as Location,
});

const EmailActionRouterContainer = connect(mapStateToProps)(EmailActionRouter);

export { EmailActionRouterContainer };
