import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { BodyCopy } from 'components/common/typography';
import { Grid, GridTemplates } from 'components/grid';
import { ButtonContainer as Button } from 'containers';
import { Account, IQueueableModal } from 'models';
import { ModalWrapperContainer as ModalWrapper } from 'containers';

const styles = require('./account-terminated.less');

interface Props {
    readonly account: Account;
    showModal(modal: IQueueableModal): any;
}

@CSSModules(styles)
class AccountTerminated extends React.Component<Props> {
    private readonly showTerminatedModal = (): JSX.Element => {
        return (
            <ModalWrapper closeButton title="Terminated Account">
                <BodyCopy medium>
                    {__('terminated.account.modal.message')}
                </BodyCopy>
            </ModalWrapper>
        );
    };

    private readonly showMessage = (): void => {
        const { showModal } = this.props;
        showModal({
            component: this.showTerminatedModal,
        });
    };

    render(): JSX.Element {
        return (
            <div>
                <Grid>
                    <GridTemplates.HeadlineTemplate
                        md
                        center
                        two
                        headlineCentered
                        headline={__('terminated.account.heading')}
                    >
                        <BodyCopy center>
                            {__('terminated.account.description')}
                        </BodyCopy>
                        <div styleName="service__btn">
                            <Button
                                fullWidthMobile
                                margin20
                                label={__('terminated.account.restart.button')}
                                onClick={this.showMessage}
                            />
                        </div>
                    </GridTemplates.HeadlineTemplate>
                </Grid>
            </div>
        );
    }
}

export { AccountTerminated };
