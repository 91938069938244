import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import {
    DEFAULT_COMPATIBILITY,
    IDeviceCompatibilityResponse,
    IDeviceListCarriersModels,
} from 'models/device/device.model';
import {
    ActionType,
    IDeviceCompatibilityAction,
} from './compatibility.actions';

interface IDeviceCompatibilityState {
    readonly compatibility: IDeviceCompatibilityResponse;
    readonly error: string;
    readonly fetchStatus: FetchStatus;
    readonly fetchStatusCarriersModels: FetchStatus;
    readonly carriersModels: IDeviceListCarriersModels;
}

const DEFAULT_STATE: IDeviceCompatibilityState = {
    carriersModels: {
        carriers: [],
        models: [],
    },
    compatibility: DEFAULT_COMPATIBILITY,
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
    fetchStatusCarriersModels: FetchStatus.NOT_FETCHED,
};

const {
    CHECK,
    CLEAR,
    CLEAR_FETCH_STATUS,
    LOAD,
    REPORT_CHECK_DEVICE_COMPATIBILITY_SUCCESS,
    REPORT_CHECK_DEVICE_COMPATIBILITY_ERROR,
    REPORT_LOAD_DEVICE_CARRIERS_MODELS_SUCCESS,
    REPORT_LOAD_DEVICE_CARRIERS_MODELS_ERROR,
} = ActionType;

const deviceCompatibilityReducer = (
    state: IDeviceCompatibilityState = DEFAULT_STATE,
    action: IDeviceCompatibilityAction | IDefaultAction,
): IDeviceCompatibilityState => {
    switch (action.type) {
        case CHECK:
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };

        case CLEAR:
            return {
                ...state,
                compatibility: DEFAULT_COMPATIBILITY,
                error: '',
                fetchStatus: FetchStatus.NOT_FETCHED,
            };
        case CLEAR_FETCH_STATUS:
            return {
                ...state,
                error: '',
                fetchStatus: FetchStatus.NOT_FETCHED,
            };
        case LOAD:
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        case REPORT_CHECK_DEVICE_COMPATIBILITY_SUCCESS:
            return {
                ...state,
                compatibility: action.payload,
                error: '',
                fetchStatus: FetchStatus.SUCCESS,
            };
        case REPORT_CHECK_DEVICE_COMPATIBILITY_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        case REPORT_LOAD_DEVICE_CARRIERS_MODELS_SUCCESS:
            return {
                ...state,
                carriersModels: action.payload,
                fetchStatusCarriersModels: FetchStatus.SUCCESS,
            };
        case REPORT_LOAD_DEVICE_CARRIERS_MODELS_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchStatusCarriersModels: FetchStatus.ERROR,
            };
        default:
            return state;
    }
};

export { IDeviceCompatibilityState, DEFAULT_STATE, deviceCompatibilityReducer };
