import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { ModalWrapperContainer as ModalWrapper } from 'containers';
import { IModalProps } from 'models';
import { CPNI } from '../cpni';

interface Props extends IModalProps {}

@CSSModules(require('./cpni-modal.less'))
class CPNIModal extends React.Component<Props> {
    render() {
        return (
            <ModalWrapper title={__('account.privacy.cpni.header')} closable>
                <div styleName="modal-inner-wrap">
                    <div styleName="scroll-section">
                        <CPNI />
                    </div>
                </div>
            </ModalWrapper>
        );
    }
}

export { CPNIModal };
