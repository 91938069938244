import { PickAPhone } from 'components/account/create-account/pick-a-phone';
import { withAnalytics } from 'components/common/analytics';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ViewActions } from '../state/view';

const mapDispatchToProps = dispatch => ({
    dismissModal: () => dispatch(ViewActions.dismissModal()),
});

const enhance = compose(
    withAnalytics,
    connect(
        null,
        mapDispatchToProps,
    ),
);
const PickAPhoneContainer = enhance(PickAPhone);
export { PickAPhoneContainer };
