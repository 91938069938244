import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { splitProps } from 'utils';

const styles = require('./bodycopy.less');

enum Modifiers {
    blue = 'blue',
    grey = 'grey',
    green = 'green',
    error = 'error',
    noMargin = 'noMargin',
    noMarginBottom = 'noMarginBottom',
    marginLeft = 'marginLeft',
    margin30Top = 'margin30Top',
    margin30Bottom = 'margin30Bottom',
    margin20Bottom = 'margin20Bottom',
    hasRefCode = 'hasRefCode',
    marginTop = 'marginTop',
    thin = 'thin',
    inline = 'inline',
    flex = 'flex',
    large = 'large',
    medium = 'medium',
    right = 'right',
    center = 'center',
    bold = 'bold',
    columnTitle = 'columnTitle',
    columnSubtitle = 'columnSubtitle',
    columnDescription = 'columnDescription',
    columnSubHeading = 'columnSubHeading',
    width70 = 'width70',
    margin8Bottom = 'margin8Bottom',
    margin10Bottom = 'margin10Bottom',
    fontSize20 = 'fontSize20',
    marginTop4 = 'marginTop4',
    black = 'black',
    greyFont = 'greyFont',
    noMarginTop = 'noMarginTop',
    mobileFont14 = 'mobileFont14',
    mobileFont12 = 'mobileFont12',
    margin16Bottom = 'margin16Bottom',
    marginTop12 = 'marginTop12',
    margin35Bottom = 'margin35Bottom',
    margin8BottomMobile = 'margin8BottomMobile',
    noMarginTopMobile = 'noMarginTopMobile',
    margin30BottomMobile = 'margin30BottomMobile',
    DisclaimerText = 'DisclaimerText',
}

interface BodyCopyModifiers {
    readonly blue?: boolean;
    readonly green?: boolean;
    readonly grey?: boolean;
    readonly error?: boolean;
    readonly noMargin?: boolean;
    readonly noMarginBottom?: boolean;
    readonly marginLeft?: boolean;
    readonly marginTop?: boolean;
    readonly margin30Top?: boolean;
    readonly hasRefCode?: boolean;
    readonly margin30Bottom?: boolean;
    readonly thin?: boolean;
    readonly inline?: boolean;
    readonly flex?: boolean;
    readonly large?: boolean;
    readonly medium?: boolean;
    readonly right?: boolean;
    readonly center?: boolean;
    readonly bold?: boolean;
    readonly columnTitle?: boolean;
    readonly columnSubtitle?: boolean;
    readonly columnSubHeading?: boolean;
    readonly columnDescription?: boolean;
    readonly width70?: boolean;
    readonly margin20Bottom?: boolean;
    readonly margin8Bottom?: boolean;
    readonly margin10Bottom?: boolean;
    readonly fontSize20?: boolean;
    readonly marginTop4?: boolean;
    readonly black?: boolean;
    readonly greyFont?: boolean;
    readonly noMarginTop?: boolean;
    readonly mobileFont14?: boolean;
    readonly mobileFont12?: boolean;
    readonly margin16Bottom?: boolean;
    readonly marginTop12?: boolean;
    readonly margin35Bottom?: boolean;
    readonly margin8BottomMobile?: boolean;
    readonly noMarginTopMobile?: boolean;
    readonly margin30BottomMobile?: boolean;
    readonly DisclaimerText?: boolean;
}

type BaseBodyCopy = React.HTMLProps<HTMLParagraphElement> & BodyCopyModifiers;

const BodyCopyComponent = ({
    styleName,
    children,
    ...props
}: BaseBodyCopy): JSX.Element => {
    const [modifiers, other] = splitProps(props, Modifiers);
    const cn = classNames('bodycopy', modifiers, styleName);
    return (
        <p styleName={cn} {...other}>
            {children}
        </p>
    );
};

const BodyCopy = CSSModules(BodyCopyComponent, styles, { allowMultiple: true });
export { BodyCopy };
