import * as React from 'react';

import { Accordion } from 'components/common/accordion';
import { IAnalyticsProps } from 'components/common/analytics';
import { Checkmark } from 'components/common/graphics';

interface IProps extends IAnalyticsProps {
    readonly active: boolean;
    readonly incomplete: boolean;
    readonly title: string;
    readonly completeButtonText?: string;
    readonly completed: boolean;
    readonly key?: number;
    readonly pageName: string;
    readonly suppressRightText?: boolean;

    onClick?(): void;
}

class PurchaseAccordion extends React.Component<IProps> {
    render(): JSX.Element {
        const { active, children, title, completed } = this.props;

        const rightText = this.getRightText();
        return (
            <Accordion
                active={active}
                title={title}
                onClick={this.viewEditWithAnalytics}
                hideIndicator={true}
                rightText={rightText}
                leftIcon={completed ? <Checkmark /> : undefined}
            >
                {children}
            </Accordion>
        );
    }

    private readonly viewEditWithAnalytics = () => {
        const { onClick } = this.props;
        if (onClick) {
            onClick();
        }
    };

    private readonly getRightText = () => {
        const {
            active,
            completed,
            incomplete,
            completeButtonText,
            suppressRightText,
        } = this.props;

        if (!suppressRightText) {
            if (active) {
                return undefined;
            } else if (completed) {
                return completeButtonText || 'Edit';
            } else if (incomplete) {
                return 'Resume';
            }
        }

        return undefined;
    };
}

export { PurchaseAccordion };
