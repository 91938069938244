import { createAction, IAction } from 'lib/br-redux';

enum ActionType {
    CHECKOUT = 'PURCHASE/CART/CHECKOUT',
    REPORT_CHECKOUT_SUCCESS = 'PURCHASE/CART/REPORT_CHECKOUT_SUCCESS',
    REPORT_CHECKOUT_ERROR = 'PURCHASE/CART/REPORT_CHECKOUT_ERROR',
}

interface ICheckoutPayload {
    readonly payment_nonce: string;
}

type IPerformCheckoutAction = IAction<ActionType.CHECKOUT, ICheckoutPayload>;
type IReportCheckoutSuccessAction = IAction<ActionType.REPORT_CHECKOUT_SUCCESS>;
type IReportCheckoutErrorAction = IAction<
    ActionType.REPORT_CHECKOUT_ERROR,
    string
>;

type ICheckoutAction =
    | IPerformCheckoutAction
    | IReportCheckoutErrorAction
    | IReportCheckoutSuccessAction;

const checkout = createAction(
    ActionType.CHECKOUT,
    (payload: ICheckoutPayload) => payload,
);

const reportCheckoutSuccess = createAction(ActionType.REPORT_CHECKOUT_SUCCESS);

const reportCheckoutError = createAction(
    ActionType.REPORT_CHECKOUT_ERROR,
    (err: string) => err,
);

export {
    ActionType,
    ICheckoutAction,
    IPerformCheckoutAction,
    IReportCheckoutSuccessAction,
    IReportCheckoutErrorAction,
    checkout,
    reportCheckoutSuccess,
    reportCheckoutError,
};
