import * as React from 'react';

const FilledCircle = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 46 16"
    >
        <g fill="none" fillRule="evenodd">
            <circle cx="13" cy="13" r="13" fill="#1800FF" />
        </g>
    </svg>
);

export { FilledCircle };
