import {
    FetchedChargeEventDetailsResponse,
    IBillHistoryResponse,
    IConfigurationBasePaths,
    INextDueDate,
} from 'models';
import { ThinAirService } from './thin-air.service';

class BillService extends ThinAirService {
    static async fetchCurrentBill(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
    ): Promise<INextDueDate> {
        // return {
        //     due_date: '2018-08-30T00:00:00.000000-05:00',
        //     charge_amount: 40,
        //     enableManualPay: true,
        // };

        return await BillService.fetchJson<INextDueDate>(
            `${BillService.BASE_URL}${basePaths.payment}/duedate`,
            {
                headers: BillService.getDefaultHeaders(firebaseToken),
            },
        );
    }

    static async fetchBillHistory(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
    ): Promise<IBillHistoryResponse> {
        // return {
        //     charge_event: [
        //         {
        //             eventid: '1248377998',
        //             eventtime: '2018-02-09T19:23:56+00:00',
        //             eventtype: ChargeEventType.CHARGE,
        //             amount: 40,
        //         },
        //         {
        //             eventid: '1248377908',
        //             eventtime: '2018-02-19T19:23:56+00:00',
        //             eventtype: ChargeEventType.CREDIT,
        //             amount: 25,
        //         },
        //         {
        //             eventid: '1248377995',
        //             eventtime: '2018-02-12T19:23:56+00:00',
        //             eventtype: ChargeEventType.PAYMENT,
        //             amount: 50,
        //         },
        //         {
        //             eventid: '1248377997',
        //             eventtime: '2018-02-10T19:23:56+00:00',
        //             eventtype: ChargeEventType.SERVICE_REFUND,
        //             amount: 20,
        //         },
        //     ],
        // };

        return await BillService.fetchJson<IBillHistoryResponse>(
            `${BillService.BASE_URL}${basePaths.payment}/statements`,
            {
                headers: BillService.getDefaultHeaders(firebaseToken),
            },
        );
    }

    static async fetchBillDetails(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        eventId: string,
    ): Promise<FetchedChargeEventDetailsResponse> {
        // return [
        //     {
        //         $: 'TaxSummaryResponse',
        //         msgID: '001127109',
        //         customerType: '00',
        //         planID: 'BASE001',
        //         grossPrice: '40.00',
        //         discountPrice: '40.00',
        //         geocode: 'US1703364707',
        //         taxTreatment: 'inclusive',
        //         classCode: 'SVC',
        //         totalTaxAmount: '0.91',
        //         totalFeeAmount: '0.00',
        //         totalNetRevenue: '39.08158280',
        //         modifiedDisplayNetRevenue: '39.09',
        //         sumTaxItemList: [
        //             {
        //                 sumTaxItemType: 'tax',
        //                 sumTaxType: '01',
        //                 sumDescription: 'IL State Sales Tax',
        //                 sumTaxAmount: '0.61',
        //             },
        //             {
        //                 sumTaxItemType: 'tax',
        //                 sumTaxType: '06',
        //                 sumDescription: 'IL State 911 Fee',
        //                 sumTaxAmount: '0.29',
        //             },
        //             {
        //                 sumTaxItemType: 'tax',
        //                 sumTaxType: '09',
        //                 sumDescription: 'IL Telecom Relay Srvc Fee',
        //                 sumTaxAmount: '0.01',
        //             },
        //         ],
        //     },
        //     {
        //         $: 'TaxSummaryResponse',
        //         msgID: '00127109',
        //         customerType: '99',
        //         planID: 'CA ReferralCredits',
        //         grossPrice: '12.00',
        //         discountPrice: '0',
        //         geocode: 'US1703364707',
        //         taxTreatment: 'inclusive',
        //         classCode: 'FEA',
        //         totalTaxAmount: '0.00',
        //         totalFeeAmount: '0.00',
        //         totalNetRevenue: '0.00000000',
        //         modifiedDisplayNetRevenue: '0.00',
        //         sumTaxItemList: [],
        //     },
        // ];
        return await BillService.fetchJson<FetchedChargeEventDetailsResponse>(
            `${BillService.BASE_URL}${basePaths.payment}/statements/${eventId}`,
            {
                headers: BillService.getDefaultHeaders(firebaseToken),
            },
        );
    }
}

export { BillService };
