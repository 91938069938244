import { withAnalytics } from 'components/common/analytics';
import { withModal } from 'components/common/modal';
import { LockedDeviceError } from 'components/sign-up/components/phone-number/errors/locked-device-error';
import { compose } from 'redux';

const enhance = compose(
    withModal,
    withAnalytics,
);
const LockedDeviceErrorContainer = enhance(LockedDeviceError);

export { LockedDeviceErrorContainer };
