import { eventTealiumLink, eventTealiumView } from 'state/tealium';
import { smellyStoreSingleton } from 'utils/store';

class TealiumDispatcher {
    static readonly dispatchView = (
        flowName,
        pageName,
        loginState?,
        accountId?,
    ) => {
        const { dispatch } = smellyStoreSingleton.store;
        dispatch(
            eventTealiumView({
                flow_name: flowName,
                page_name: pageName.replace('-', ''),
                tealium_event: 'pageview',
                login_state: loginState,
                account_id: accountId,
            }),
        );
    };

    static readonly dispatchEvent = (
        flowName,
        pageName,
        tealiumEvent,
        linkLocation?,
        linkType?,
        login_state?,
        account_id?,
        modelSelected?,
    ) => {
        const { dispatch } = smellyStoreSingleton.store;
        dispatch(
            eventTealiumLink({
                flow_name: flowName,
                page_name: pageName.replace('-', ''),
                tealium_event: tealiumEvent,
                link_location: linkLocation,
                link_type: linkType,
                login_state,
                account_id,
                model_selected: modelSelected,
            }),
        );
    };
}

export { TealiumDispatcher };
