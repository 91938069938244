import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
require('./delay-transition.less');

interface Props {
    readonly children?: any;
    readonly transitionKey: string;
    readonly delayFor: number;
    onStart(): void;
    onEnd(): void;
}

const DelayTransition = ({
    children,
    transitionKey,
    delayFor,
    onStart,
    onEnd,
}: Props) => (
    <TransitionGroup>
        <CSSTransition
            appear
            classNames="delay"
            key={transitionKey}
            timeout={delayFor}
            onEnter={onStart}
            onEntered={onEnd}
            style={{ width: '100%', height: '100%' }}
        >
            <div>{children}</div>
        </CSSTransition>
    </TransitionGroup>
);

export { DelayTransition };
