import { Account } from 'components/account';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { showPayNow } from 'state/payment/lapsed-payment/lapsed-payment.actions';

const mapDispatchToProps = dispatch => ({
    showPayNow: chargeAmount => dispatch(showPayNow(chargeAmount)),
});

const mapStateToProps = (state: IApplicationState) => ({
    autopayFetchStatus: state.payment.autopay.fetchStatus,
    billFetchStatus: state.bill.history.fetchStatus,
    customerState: state.account.customerState,
    getCreditBalanceFetchStatus: state.bill.history.fetchStatus,
    configuration: state.configuration,
    chargeAmount: state.bill.currentBill.chargeAmount,
});

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const AccountContainer = enhance(Account);

export { AccountContainer };
