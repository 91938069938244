import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Chevron } from 'components/common/graphics';

const styles = require('./accordion.less');

interface IProps {
    readonly active?: boolean;
    readonly title: string;
    readonly rightText?: string;
    readonly hideIndicator?: boolean;
    readonly hideLeftIcon?: boolean;
    readonly leftIcon?: JSX.Element;
    readonly indicator?: React.ReactNode;
    readonly contentPadding30?: boolean;
    readonly noPadding?: boolean;
    readonly subhead?: boolean;
    onClick?(): void;
}

interface IState {
    readonly wrapperHeight: number;
}

@CSSModules(styles, { allowMultiple: true })
class Accordion extends React.Component<IProps, IState> {
    // tslint:disable:readonly-keyword
    container?: HTMLDivElement;
    private content?: HTMLDivElement;
    // using any here because the compiler wants this to be a number and VSCode wants it to be NodeJs.Timer
    private interval?: any;
    // tslint:enable

    constructor(props) {
        super(props);
        this.state = { wrapperHeight: 0 };
    }

    render(): JSX.Element {
        const {
            active,
            children,
            onClick,
            title,
            leftIcon,
            hideIndicator,
            hideLeftIcon,
            noPadding,
            contentPadding30,
            subhead,
        } = this.props;

        const contentStyle = {
            display: this.state.wrapperHeight > 0 ? 'block' : 'none',
            maxHeight:
                this.state.wrapperHeight > 0
                    ? `${this.state.wrapperHeight}px`
                    : 0,
        };

        const indicator = this.getIndicatorContent();

        return (
            <div
                styleName={classNames('accordion-section', { noPadding })}
                ref={container =>
                    (this.container = container as HTMLDivElement)
                }
            >
                <div>
                    <div>
                        <div
                            onClick={onClick}
                            styleName={classNames('title-bar', {
                                active,
                                focusable: !hideIndicator,
                                subhead,
                            })}
                        >
                            {!hideLeftIcon && (
                                <span styleName="left-icon">{leftIcon}</span>
                            )}
                            <span styleName="title">{title}</span>
                            {indicator}
                        </div>
                    </div>
                </div>
                <div styleName="content-wrapper" style={contentStyle}>
                    <div
                        styleName={classNames('content', { contentPadding30 })}
                    >
                        <div>
                            <div
                                ref={content =>
                                    (this.content = content as HTMLDivElement)
                                }
                            >
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.calculateWrapperHeight();
        this.setClearInterval();
    }

    componentDidUpdate() {
        this.calculateWrapperHeight();
        this.setClearInterval();
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    private readonly getIndicatorContent = () => {
        const { active, hideIndicator, indicator, rightText } = this.props;

        if (typeof indicator !== 'undefined') {
            return indicator;
        } else if (rightText) {
            return <span styleName="right-text">{rightText}</span>;
        } else if (hideIndicator) {
            return null;
        } else {
            return active ? <Chevron blue inverted /> : <Chevron />;
        }
    };

    // If this accordion section is open, we want to periodically check that the size doesn't need to change
    private readonly setClearInterval = () => {
        if (this.interval && !this.props.active) {
            clearInterval(this.interval);
            this.interval = undefined;
        }

        if (this.props.active && !this.interval) {
            this.interval = setInterval(this.calculateWrapperHeight, 250);
        }
    };

    private readonly calculateWrapperHeight = () => {
        // arbitrarily adding 250px here to play it safe...
        const newHeight =
            this.props.active && this.content
                ? this.content.clientHeight + 2000
                : 0;

        if (newHeight !== this.state.wrapperHeight) {
            this.setState({ wrapperHeight: newHeight });
        }
    };
}

export { Accordion };
