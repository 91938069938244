import { withAnalytics } from 'components/common/analytics';
import { withDeviceType } from 'components/common/device-type';
import { withInputValidation } from 'components/common/forms/input';
import { Billing } from 'components/sign-up/components/billing/billing';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectPaymentMethod } from 'state/payment/payment-nonce/payment-nonce.actions';
import { validateShortCode } from '../state/referral';

const dataKeys: ReadonlyArray<string> = ['referralCode'];

const mapStateToProps = (
    { account, configuration, products, referral }: IApplicationState,
    ownProps,
) => ({
    configuration,
    refCodeApplied: account.refCodeApplied,
    ...ownProps,
    products: products.results,
    validateShortCodeError: referral.validateShortCodeError,
    validateShortCodeFetchStatus: referral.validateShortCodeFetchStatus,
});

const mapDispatchToProps = dispatch => ({
    selectPaymentMethod: p => dispatch(selectPaymentMethod(p)),
    validateShortCode: c => dispatch(validateShortCode(c)),
});

const enhance = compose(
    withDeviceType,
    withAnalytics,
    withInputValidation(dataKeys),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const BillingContainer = enhance(Billing);

export { BillingContainer };
