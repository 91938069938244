import { TealiumFlowName, TealiumPageType } from 'models/tealium';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';

const styles = require('./connect.less');

@CSSModules(styles)
class Connect extends React.Component<{}> {
    componentDidMount() {
        TealiumDispatcher.dispatchView(
            TealiumFlowName.NONE,
            TealiumPageType.VISIBLE_CONNECT,
        );
    }
    render() {
        return <>{(window.location.href = __('routes.connect'))}</>;
    }
}

export { Connect };
