import { MessageBar } from 'components/message-bar/message-bar';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ViewActions } from 'state/view';
import { IApplicationState } from '../models';

const mapStateToProps = (state: IApplicationState) => ({
    messages: state.view.messages,
});

const mapDispatchToProps = dispatch => ({
    dismissAlert: (idx: number) => dispatch(ViewActions.dismissMessage(idx)),
});

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const MessageBarContainer = enhance(MessageBar);

export { MessageBarContainer };
