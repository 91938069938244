import { ActionHandlerMode } from 'components/email-action-handler';
import { VerifyEmail } from 'components/verify-email';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { verifyEmailActionCode } from 'state/email-action';

const mapStateToProps = ({ emailAction }: IApplicationState, otherProps) => ({
    location: otherProps.location as Location,
    verificationStatus: emailAction.verificationStatus,
});

const mapDispatchToProps = dispatch => ({
    verifyCode: (oobCode: string, mode: ActionHandlerMode) =>
        dispatch(verifyEmailActionCode({ oobCode, mode })),
});

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const VerifyEmailContainer = enhance(VerifyEmail);

export { VerifyEmailContainer };
