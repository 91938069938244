import { IConfigurationBasePaths, ILead } from 'models';
import { ThinAirService } from './thin-air.service';

class LeadService extends ThinAirService {
    static readonly DEFAULT_LEAD_VALUES = {
        Company: 'HOME_PAGE_CAPTURE',
        FirstName: 'HOME_PAGE_CAPTURE',
        LastName: 'HOME_PAGE_CAPTURE',
        inviteCode__c: 'HOME',
    };

    static async capture(
        basePaths: IConfigurationBasePaths,
        lead: ILead,
    ): Promise<void> {
        const response = await fetch(
            `${LeadService.BASE_URL}${basePaths.lead}`,
            {
                body: JSON.stringify({
                    ...LeadService.DEFAULT_LEAD_VALUES,
                    Carrier__c: lead.carrier,
                    Device_Model__c: lead.device,
                    Email: lead.email,
                    Mobile_OS__c: lead.os,
                }),
                headers: LeadService.getDefaultHeaders(),
                method: 'POST',
            },
        );

        if (response.status > 299) {
            // response out of range
            throw new Error(
                await LeadService._parseErrorOrDefault(await response.json()),
            );
        }

        return;
    }

    static _parseErrorOrDefault(json: any) {
        try {
            return json[0].message || __('default_service_error');
        } catch (err) {
            return __('default_service_error');
        }
    }
}

export { LeadService };
