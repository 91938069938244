import { ServiceCancel } from 'components/account/service-cancel/service-cancel';
import { withModal } from 'components/common/modal';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { cancelService } from 'state/account/cancel/cancel.actions';

const mapStateToProps = (state: IApplicationState) => ({});

const mapDispatchToProps = dispatch => ({
    cancelService: () => dispatch(cancelService()),
});

const enhance = compose(
    withModal,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const ServiceCancelContainer = enhance(ServiceCancel);

export { ServiceCancelContainer };
