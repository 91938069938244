import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./protect.less');

@CSSModules(styles)
class VisibleProtect extends React.Component<{}> {
    render() {
        return <>{(window.location.href = __('routes.visible-protect'))}</>;
    }
}

export { VisibleProtect };
