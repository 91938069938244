import { IApplicationState } from 'models';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { AccountService } from 'services';
import { ActionType } from 'state/account/cancel/cancel.actions';
import { getToken } from 'state/firebase/firebase.saga';
import { trackError } from 'state/tealium';
import { CancelServiceAction, reportCancelError, reportCancelSuccess } from '.';
import { refreshAccount } from '..';

function* cancelService(action: CancelServiceAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const token = yield call(getToken);
        yield call(
            AccountService.cancelAccount,
            basePaths,
            token,
            action.payload,
        );
        yield call(refreshAccount); // added so that customer state gets updated
        yield put(reportCancelSuccess());
    } catch (err) {
        yield put(reportCancelError((err as Error).message));
    }
}

function* cancelSaga() {
    yield all([
        takeEvery(ActionType.CANCEL_SERVICE, cancelService),
        takeEvery(ActionType.REPORT_CANCEL_ERROR, trackError),
    ]);
}

export { cancelSaga };
