import { withAnalytics } from 'components/common/analytics';
import { AutopayCheckbox } from 'components/common/forms/autopay-checkbox';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    getAutopayStatus,
    setAutopayStatus,
    showAutopayTerms,
} from 'state/payment/autopay/autopay.actions';

const mapStateToProps = (state: IApplicationState, ownProps) => ({
    authStatus: state.authentication.fetchStatus,
    fetchStatus: state.payment.autopay.fetchStatus,
    status: state.account.autopay,
    ...ownProps,
});

const mapDispatchToProps = dispatch => ({
    getAutopayStatus: () => dispatch(getAutopayStatus()),
    setAutopayStatus: s => dispatch(setAutopayStatus(s)),
    showAutopayTerms: () => dispatch(showAutopayTerms()),
});

const enhance = compose(
    withAnalytics,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const AutopayCheckboxContainer = enhance(AutopayCheckbox);

export { AutopayCheckboxContainer };
