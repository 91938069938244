const MobileDetect = require('mobile-detect');
import { ChannelModel } from 'models/constants';
interface ThinAirFault {
    readonly faultString: string;
    readonly detail: {
        readonly errorcode: string;
    };

    readonly userErrorMessage?: string;
    readonly systemMessage?: string;
    readonly targetSystem?: string;
    readonly targetUrl?: string;
    readonly targetSystemPayload?: string;
    readonly excToken: string;
}

interface ThinAirError {
    readonly fault: ThinAirFault;
}

abstract class ThinAirService {
    static readonly BASE_URL: string = process.env.API_BASE_URL || '';

    static async parseJson(
        response: Response,
        defaultError: string = __('default_service_error'),
    ): Promise<string> {
        try {
            return await response.json();
        } catch (err) {
            // Could not parse response json.
            // Throw generic error.
            return defaultError;
        }
    }

    static async parseErrorOrDefault(
        response: Response,
        defaultError: string = __('default_service_error'),
    ): Promise<string> {
        try {
            // Try to parse response body for error details.
            // Reject with error if possible.
            const error: ThinAirError | any = await response.json();
            const fault = (error as ThinAirError).fault;
            return fault &&
                fault.userErrorMessage &&
                fault.userErrorMessage.trim().length
                ? fault.userErrorMessage
                : defaultError;
        } catch (err) {
            // Could not parse response body.
            // Throw generic error.
            return defaultError;
        }
    }

    static async parseError(
        response: Response,
        defaultError: string = __('default_service_error'),
    ): Promise<string> {
        try {
            const error: ThinAirError | any = await response.json();
            const fault = (error as ThinAirError).fault;
            return fault &&
                fault.detail &&
                fault.detail.errorcode &&
                fault.detail.errorcode.trim().length
                ? fault.detail.errorcode
                : defaultError;
        } catch (err) {
            return defaultError;
        }
    }

    protected static getDefaultHeaders(firebaseToken?: string): Headers {
        const headers = new Headers();
        const md = new MobileDetect(window.navigator.userAgent);
        let channel = '';
        let excToken = sessionStorage.getItem('ExchangeToken');
        if (excToken !== null && excToken !== undefined) {
            headers.append('Authorization', `Bearer ${excToken}` || '');
        } else {
            headers.append(
                'Authorization',
                `Bearer ${process.env.API_TOKEN}` || '',
            );
        }

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        if (md.mobile() === null) {
            channel = ChannelModel.WEB;
        } else if (md.os() === ChannelModel.DEVICE_IOS) {
            channel = ChannelModel.IOS;
            headers.append('app-version', window.navigator.appVersion);
        } else if (md.os() === ChannelModel.DEVICE_ANDROIDOS) {
            channel = ChannelModel.ANDROID;
            headers.append('app-version', window.navigator.appVersion);
        }
        headers.append('x-client-channel', channel);
        return headers;
    }

    protected static getExchangeToken(firebaseToken?: string): Headers {
        const headers = new Headers();
        const md = new MobileDetect(window.navigator.userAgent);
        let channel = '';

        if (firebaseToken) {
            headers.append('id-token', firebaseToken);
        }

        headers.append(
            'Authorization',
            `Bearer ${process.env.API_TOKEN}` || '',
        );
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append(
            'grant_type',
            'urn:ietf:params:oauth:grant-type:jwt-bearer',
        );
        headers.append('Accept', 'application/json');
        if (md.mobile() === null) {
            channel = ChannelModel.WEB;
        } else if (md.os() === ChannelModel.DEVICE_IOS) {
            channel = ChannelModel.IOS;
            headers.append('app-version', window.navigator.appVersion);
        } else if (md.os() === ChannelModel.DEVICE_ANDROIDOS) {
            channel = ChannelModel.ANDROID;
            headers.append('app-version', window.navigator.appVersion);
        }
        headers.append('x-client-channel', channel);
        return headers;
    }

    protected static async fetchJson<Model>(
        url: string,
        fetchConfig: RequestInit = {},
    ): Promise<Model> {
        try {
            return await fetch(url, fetchConfig)
                .then(ThinAirService.ensure200)
                .then(response => response.json());
        } catch (err) {
            return Promise.reject(
                new Error(
                    await ThinAirService.parseErrorOrDefault(err as Response),
                ),
            );
        }
    }

    protected static getQueryString(params) {
        const esc = encodeURIComponent;
        return Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
    }

    private static async ensure200(response: Response): Promise<Response> {
        // TODO: compatibility IEMI returns 400
        if (response.status >= 300) {
            return Promise.reject(response);
        }

        // Everything is cool.
        return response;
    }
}

export { ThinAirService };
