import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { IShop } from 'models';
import { ActionType, IShopAction } from './shop.actions';

interface IShopState {
    readonly products: IShop;
    readonly error: string;
    readonly fetchStatus: FetchStatus;
}

const DEFAULT_STATE: IShopState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
    products: {},
};

const {
    FETCH_PRODUCTS_LIST,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCTS_FAILURE,
} = ActionType;

const shopReducer = (
    state: IShopState = DEFAULT_STATE,
    action: IShopAction | IDefaultAction,
): IShopState => {
    switch (action.type) {
        case FETCH_PRODUCTS_LIST:
            return {
                ...state,
                error: '',
                fetchStatus: FetchStatus.FETCHING,
            };
        case FETCH_PRODUCTS_FAILURE:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        case FETCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                error: '',
                fetchStatus: FetchStatus.SUCCESS,
                products: action.payload,
            };
        default:
            return state;
    }
};

export { IShopState, DEFAULT_STATE, shopReducer, IShop };
