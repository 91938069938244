import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Checkmark } from 'components/common/graphics';
import { BodyCopy } from 'components/common/typography';
import { ErrorMsg } from 'components/common/typography/error-msg';
import {
    AutopayCheckboxContainer as AutopayCheckbox,
    BraintreeDropInContainer as BraintreeDropIn,
    ButtonContainer as Button,
    InputContainer as Input,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';
import {
    DEFAULT_VALIDATE_SHORT_CODE_RESPONSE,
    IAnalyticsProps,
    IConfiguration,
    IDeviceTypeProps,
    IInputValidationProps,
    IProductItemModel,
    IServiceStep,
    IServiceStepProps,
    IValidateShortCodeResponse,
} from 'models';
import { Toggles } from 'utils';

const styles = require('./billing.less');

interface IMappedProps
    extends IInputValidationProps,
        IAnalyticsProps,
        IDeviceTypeProps {
    readonly configuration: IConfiguration;
    readonly refCodeApplied: string;
    readonly products: ReadonlyArray<IProductItemModel>;
    readonly validateShortCodeError: string;
    readonly validateShortCodeFetchStatus: FetchStatus;

    selectPaymentMethod(nonce: string): void;
    validateShortCode(shortCode: string): void;
}

interface IOtherProps extends IServiceStepProps {}

interface IState {
    readonly didRenderSubmitButton: boolean;
    readonly error: string;
    readonly isLoading: boolean;
    readonly referralCodeInjected: boolean;
    readonly shortCodeResponse: IValidateShortCodeResponse;
}

const DEFAULT_STATE: IState = {
    didRenderSubmitButton: false,
    error: '',
    isLoading: false,
    referralCodeInjected: false,
    shortCodeResponse: DEFAULT_VALIDATE_SHORT_CODE_RESPONSE,
};

type IProps = IMappedProps & IOtherProps;

@CSSModules(styles)
class Billing extends React.Component<IProps, IState> implements IServiceStep {
    constructor(props: IProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    componentWillReceiveProps(newProps: IProps): void {
        const oldvalidateShortCodeFetchStatus = this.props
            .validateShortCodeFetchStatus;
        if (
            newProps.validateShortCodeFetchStatus === FetchStatus.SUCCESS &&
            newProps.validateShortCodeFetchStatus !==
                oldvalidateShortCodeFetchStatus &&
            this.props.onNext
        ) {
            this.setState({ error: '' });
        }
        if (
            newProps.validateShortCodeFetchStatus === FetchStatus.ERROR &&
            newProps.validateShortCodeError
        ) {
            this.setState({ error: newProps.validateShortCodeError });
        }
        this.initSavedReferralCode(newProps);
    }

    componentDidMount() {
        this.initSavedReferralCode(this.props);
    }

    render() {
        return (
            <BraintreeDropIn
                renderSubmitButton={this.renderPostBraintreeContent}
                onError={err => this.handleError(err)}
                onGetPaymentNonce={this.setNonce}
            />
        );
    }

    private readonly renderContent = ({ onClick, isDisabled }) => {
        const {
            isFetching,
            props: { isDesktop },
            state: { isLoading },
        } = this;
        return (
            <form onSubmit={event => this.handleSubmit(event, onClick)}>
                <BodyCopy
                    id="credit-card-legal-copy"
                    style={{
                        marginBottom: '20px',
                        marginTop: '20px',
                    }}
                >
                    {__('sign-up.billing.credit-card-save')}
                </BodyCopy>
                <AutopayCheckbox />
                <div
                    style={
                        isDesktop
                            ? {
                                  marginTop: '40px',
                                  width: '50%',
                              }
                            : {
                                  marginTop: '40px',
                                  width: '100%',
                              }
                    }
                >
                    {this.referralCodeDisplay}
                </div>
                <div
                    style={{
                        display: 'block',
                        height: '80px',
                        width: '100%',
                    }}
                >
                    <Button
                        className="purchase-step-button"
                        onClick={event => this.handleSubmit(event, onClick)}
                        disabled={isDisabled}
                        loading={isLoading || isFetching}
                        right
                        label="Next"
                    />
                </div>
                <BodyCopy className="disclaimer">
                    {__('sign-up.billing.disclaimer')}
                </BodyCopy>
            </form>
        );
    };

    private readonly renderPostBraintreeContent = (
        braintreeObj,
    ): JSX.Element => {
        return this.renderContent(braintreeObj);
    };

    private get isFetching(): boolean {
        const { validateShortCodeFetchStatus } = this.props;
        return validateShortCodeFetchStatus === FetchStatus.FETCHING;
    }

    get error(): string {
        const { validateShortCodeError } = this.props;
        if (validateShortCodeError) {
            return validateShortCodeError;
        }

        return '';
    }

    private initSavedReferralCode(newProps) {
        const {
            props: { handleOnChange, refCodeApplied },
            state: { referralCodeInjected },
        } = this;
        if (!referralCodeInjected && !!refCodeApplied) {
            this.setState({ referralCodeInjected: true });
            handleOnChange({
                setErrors: false,
                target: {
                    name: 'referralCode',
                    value: refCodeApplied,
                },
            });
        }
    }

    private readonly validateReferralCode = (): boolean => {
        const {
            data: { referralCode },
            validateShortCode,
        } = this.props;
        if (referralCode) {
            if (
                this.state.shortCodeResponse &&
                !this.state.shortCodeResponse.valid
            ) {
                validateShortCode(referralCode);
                return false;
            }
        }
        return true;
    };

    private get referralCodeDisplay(): JSX.Element | null {
        const {
            props: {
                configuration,
                data: { referralCode },
                errors,
                handleOnChange,
                handleOnValidate,
            },
            state: { error, referralCodeInjected },
        } = this;
        if (!Toggles.isReferral(configuration.toggles)) {
            return null;
        }
        return (
            <span>
                <Input
                    optional
                    id="referralCode"
                    label={__('sign-up.billing.referral-code')}
                    placeholder={__('sign-up.billing.referralCode-field-hint')}
                    value={referralCode}
                    onChange={handleOnChange}
                    onValidate={handleOnValidate}
                    validate={this.validateReferralCode}
                    errors={errors}
                    noMargin
                />
                {error ? (
                    <div
                        style={{
                            lineHeight: 1.25,
                            marginTop: '6px',
                        }}
                    >
                        <ErrorMsg>{error}</ErrorMsg>
                    </div>
                ) : referralCodeInjected ? (
                    <div
                        style={{
                            lineHeight: 1.25,
                            marginTop: '6px',
                        }}
                    >
                        <Checkmark />
                        <div
                            style={{
                                display: 'inline',
                                fontFamily: 'Montserrat',
                                fontSize: '16px',
                                fontWeight: 500,
                                marginLeft: '6px',
                            }}
                        >
                            {__(
                                'sign-up.billing.referral-code.message.applied',
                            )}
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            fontFamily: 'Montserrat',
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: 1.25,
                            marginTop: '6px',
                        }}
                    >
                        {__('sign-up.billing.referral-code.message.default')}
                    </div>
                )}
            </span>
        );
    }

    private readonly setNonce = (nonce: string) => {
        const { selectPaymentMethod, onNext } = this.props;
        selectPaymentMethod(nonce);
        this.setState({ isLoading: false });
        if (onNext) {
            onNext();
        } else {
            // tslint:disable-next-line:no-console
            console.warn('onNext is undefined');
        }
    };

    private readonly handleSubmit = (
        event: React.FormEvent<HTMLFormElement>,
        onClick,
    ): void => {
        event.preventDefault();
        if (this.validateReferralCode()) {
            this.setState({ isLoading: true });
            onClick();
        }
    };

    private readonly handleError = error => {
        if (typeof error !== 'undefined') {
            // tslint:disable-next-line:no-console
            this.setState({ isLoading: false });
        }
    };
}

export { Billing };
