import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Label } from 'components/common/typography';

const styles = require('./checkbox-option.less');

interface Props {
    readonly children: JSX.Element | string;
    readonly blue?: boolean;
    readonly autoCheckBox?: boolean;
    readonly autoCheckBoxSpan?: boolean;
    readonly mobileFont?: boolean;
    readonly marginTop50?: boolean;
    readonly id: string;
    readonly checked: boolean;
    readonly className?: string;
    readonly styleName?: string;
    readonly style?: object;
    readonly onChange: (value: boolean, name: string) => void;
}

@CSSModules(styles, { allowMultiple: true })
class CheckboxOption extends React.Component<Props> {
    render(): JSX.Element {
        const {
            props: {
                blue,
                id,
                checked,
                children,
                className,
                style,
                autoCheckBox,
                marginTop50,
                autoCheckBoxSpan,
                mobileFont,
            },
            handleOnClick,
        } = this;

        return (
            <Label
                inline
                className={className}
                htmlFor={id}
                id={`${id}_label`}
                style={style}
                styleName="Label"
                marginTop50={marginTop50}
            >
                <span
                    styleName={classNames('input', {
                        checked,
                        blue,
                        autoCheckBox,
                    })}
                    onClick={handleOnClick}
                />
                <span
                    styleName={classNames('span', {
                        autoCheckBoxSpan,
                        mobileFont,
                    })}
                    onClick={handleOnClick}
                >
                    {children}
                </span>
            </Label>
        );
    }

    private readonly handleOnClick = () => {
        const {
            props: { onChange, id, checked },
        } = this;
        onChange(!checked, id);
    };
}

export { CheckboxOption };
