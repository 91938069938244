import { withAnalytics } from 'components/common/analytics';
import { withDeviceType } from 'components/common/device-type';
import { withModal } from 'components/common/modal';
import { ModalWrapper } from 'components/common/modal/modal-wrapper';
import { compose } from 'redux';

const enhance = compose(
    withAnalytics,
    withModal,
    withDeviceType,
);
const ModalWrapperContainer = enhance(ModalWrapper);

export { ModalWrapperContainer };
