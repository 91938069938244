import { createAction, IAction } from 'lib/br-redux';
import { IEmailPassword } from 'models';
import { FacebookLoginStatus } from 'models/facebook';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    AUTH_INIT = 'AUTHENTICATION/AUTH_INIT',
    REPORT_INITIALIZATION_SUCCESS = 'AUTHENTICATION/REPORT_INITIALIZATION_SUCCESS',
    REPORT_INITIALIZATION_FAILURE = 'AUTHENTICATION/REPORT_INITIALIZATION_FAILURE',
    AUTHENTICATE_WITH_EMAIL_AND_PASSWORD = 'AUTHENTICATION/AUTHENTICATE_WITH_EMAIL_AND_PASSWORD',
    REPORT_AUTHENTICATION_SUCCESS = 'AUTHENTICATION/REPORT_AUTHENTICATION_SUCCESS',
    REPORT_AUTHENTICATION_ERROR = 'AUTHENTICATION/REPORT_AUTHENTICATION_ERROR',
    SIGN_OUT = 'AUTHENTICATION/SIGN-OUT',
    RESEND_VERIFICATION_EMAIL = 'AUTHENTICATION/RESEND_VERIFICATION_EMAIL',

    REAUTHENTICATE = 'AUTHENTICATION/REAUTHENTICATE',
    FINISH_REAUTHENTICATION_WITH_PASSWORD = 'AUTHENTICATION/FINISH_REAUTHENTICATION_WITH_PASSWORD',
    REPORT_REAUTHENTICATION_SUCCESS = 'AUTHENTICATION/REPORT_REAUTHENTICATION_SUCCESS',
    REPORT_REAUTHENTICATION_ERROR = 'AUTHENTICATION/REPORT_REAUTHENTICATION_ERROR',

    SET_FACEBOOK_LOGIN_STATUS = 'AUTHENTICATION/SET_FACEBOOK_LOGIN_STATUS',

    START_POLL_EMAIL_VERIFICATION = 'AUTHENTICATION/START_POLL_EMAIL_VERIFICATION',
    STOP_POLL_EMAIL_VERIFICATION = 'AUTHENTICATION/STOP_POLL_EMAIL_VERIFICATION',

    CLEAR_AUTHENTICATION_ERRORS = 'AUTHENTICATION/CLEAR_AUTHENTICATION_ERRORS',

    CHECK_AUTHENTICATION_STATUS = 'AUTHENTICATION/CHECK_AUTHENTICATION_STATUS',
    CLEAR_AUTHENTICATION_SESSION = 'AUTHENTICATION/CLEAR_AUTHENTICATION_SESSION',

    REFRESH_EXC_TOKEN = 'AUTHENTICATION/REFRESH_EXC_TOKEN',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type IAuthInitAction = IAction<ActionType.AUTH_INIT>;

type IAuthenticateWithEmailAndPasswordAction = IAction<
    ActionType.AUTHENTICATE_WITH_EMAIL_AND_PASSWORD,
    IEmailPassword
>;
type IReportAuthenticationSuccessAction = IAction<
    ActionType.REPORT_AUTHENTICATION_SUCCESS
>;
type IReportAuthenticationError = IAction<
    ActionType.REPORT_AUTHENTICATION_ERROR,
    string
>;

type ISignOutAction = IAction<ActionType.SIGN_OUT>;

type IReportInitializationSuccessAction = IAction<
    ActionType.REPORT_INITIALIZATION_SUCCESS
>;

type IReportInitializationFailureAction = IAction<
    ActionType.REPORT_INITIALIZATION_FAILURE,
    string
>;

type IResendVerificationEmail = IAction<ActionType.RESEND_VERIFICATION_EMAIL>;

type IReauthenticateAction = IAction<ActionType.REAUTHENTICATE>;
type IFinishReauthenticationWithPasswordAction = IAction<
    ActionType.FINISH_REAUTHENTICATION_WITH_PASSWORD,
    string
>;
type IReportReauthenticationSuccessAction = IAction<
    ActionType.REPORT_REAUTHENTICATION_SUCCESS
>;
type IReportReauthenticationFailureAction = IAction<
    ActionType.REPORT_REAUTHENTICATION_ERROR,
    string
>;

type ISetFacebookLoginStatusAction = IAction<
    ActionType.SET_FACEBOOK_LOGIN_STATUS,
    FacebookLoginStatus
>;

type IStartPollEmailVerificationAction = IAction<
    ActionType.START_POLL_EMAIL_VERIFICATION
>;

type IStopPollEmailVerificationAction = IAction<
    ActionType.STOP_POLL_EMAIL_VERIFICATION
>;
type IClearAuthenticationErrorsAction = IAction<
    ActionType.CLEAR_AUTHENTICATION_ERRORS
>;

type ICheckAuthenticationStatusAction = IAction<
    ActionType.CHECK_AUTHENTICATION_STATUS
>;
type IClearAuthenticationSessionAction = IAction<
    ActionType.CLEAR_AUTHENTICATION_SESSION
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IAuthenticationAction =
    | IAuthInitAction
    | IAuthenticateWithEmailAndPasswordAction
    | IReportAuthenticationSuccessAction
    | IReportAuthenticationError
    | ISignOutAction
    | IReportInitializationSuccessAction
    | IReportInitializationFailureAction
    | IResendVerificationEmail
    | IReauthenticateAction
    | IFinishReauthenticationWithPasswordAction
    | IReportReauthenticationFailureAction
    | IReportReauthenticationSuccessAction
    | ISetFacebookLoginStatusAction
    | IStartPollEmailVerificationAction
    | IStopPollEmailVerificationAction
    | IClearAuthenticationErrorsAction
    | ICheckAuthenticationStatusAction
    | IClearAuthenticationSessionAction;

// ------------------------------------
// Action Creators
// i.e. the stuff that actually makes it to the compiled Javascript
// ------------------------------------

const authInit = createAction(ActionType.AUTH_INIT);

const refreshExcToken = createAction(ActionType.REFRESH_EXC_TOKEN);

const authenticateWithEmailAndPassword = createAction(
    ActionType.AUTHENTICATE_WITH_EMAIL_AND_PASSWORD,
    (credentials: IEmailPassword) => credentials,
);

const reportAuthenticationSuccess = createAction(
    ActionType.REPORT_AUTHENTICATION_SUCCESS,
);

const reportAuthenticationError = createAction(
    ActionType.REPORT_AUTHENTICATION_ERROR,
    (error: string) => error,
);

const signOut = createAction(ActionType.SIGN_OUT);

const reportInitializationSuccess = createAction(
    ActionType.REPORT_INITIALIZATION_SUCCESS,
);

const reportInitializationFailure = createAction(
    ActionType.REPORT_INITIALIZATION_FAILURE,
    (error: string) => error,
);

const resendVerificationEmail = createAction(
    ActionType.RESEND_VERIFICATION_EMAIL,
);

const reauthenticate = createAction(ActionType.REAUTHENTICATE);
const finishReauthenticationWithPassword = createAction(
    ActionType.FINISH_REAUTHENTICATION_WITH_PASSWORD,
    (pw: string) => pw,
);
const reportReauthenticationSuccess = createAction(
    ActionType.REPORT_REAUTHENTICATION_SUCCESS,
);
const reportReauthenticationFailure = createAction(
    ActionType.REPORT_REAUTHENTICATION_ERROR,
    (err: string) => err,
);

const setFacebookLoginStatus = createAction(
    ActionType.SET_FACEBOOK_LOGIN_STATUS,
    (status: FacebookLoginStatus) => status,
);

const startPollEmailVerification = createAction(
    ActionType.START_POLL_EMAIL_VERIFICATION,
);

const stopPollEmailVerification = createAction(
    ActionType.STOP_POLL_EMAIL_VERIFICATION,
);

const clearAuthenticationErrors = createAction(
    ActionType.CLEAR_AUTHENTICATION_ERRORS,
);

const checkAuthenticationStatus = createAction(
    ActionType.CHECK_AUTHENTICATION_STATUS,
);

const clearAuthenticationSession = createAction(
    ActionType.CLEAR_AUTHENTICATION_SESSION,
);

export {
    ActionType,
    IAuthenticateWithEmailAndPasswordAction,
    IReportAuthenticationSuccessAction,
    IReportAuthenticationError,
    IAuthenticationAction,
    ISignOutAction,
    IReportInitializationFailureAction,
    IReportInitializationSuccessAction,
    authInit,
    authenticateWithEmailAndPassword,
    reportAuthenticationSuccess,
    reportAuthenticationError,
    signOut,
    reportInitializationFailure,
    reportInitializationSuccess,
    IResendVerificationEmail,
    resendVerificationEmail,
    reauthenticate,
    finishReauthenticationWithPassword,
    reportReauthenticationSuccess,
    reportReauthenticationFailure,
    IFinishReauthenticationWithPasswordAction,
    setFacebookLoginStatus,
    ISetFacebookLoginStatusAction,
    startPollEmailVerification,
    stopPollEmailVerification,
    checkAuthenticationStatus,
    clearAuthenticationErrors,
    clearAuthenticationSession,
    refreshExcToken,
};
