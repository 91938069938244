import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';
// import { BodyCopy, InfoLink } from 'components/common/typography';
import {
    // ButtonContainer as Button,
    IMEIContainer as Compatibility,
    // InputContainer as Input,
} from 'containers';
// import { FetchStatus } from 'lib/br-redux';
import {
    // IDeviceCompatibilityPayload,
    IInputValidationProps,
    // ILead,
    IModalProps,
} from 'models';
// import { InputValidator } from 'utils';

interface Props extends IAnalyticsProps, IInputValidationProps, IModalProps {
    // readonly leadError: string;
    // readonly leadStatus: FetchStatus;
    // readonly deviceCompatibilityStatus: FetchStatus;
    // checkDevice(payload: IDeviceCompatibilityPayload): void;
    // clearCheckDevice(): void;
    // captureLead(lead: ILead): void;
    // showAccountSignupModal(): void;
}

const styles = require('./device-check.less');

@CSSModules(styles, { allowMultiple: true })
class DeviceCheck extends React.Component<Props> {
    // componentWillUnmount() {
    //     this.handleTryAgain();
    // }

    render() {
        // const {
        //     props: {
        //         data: { email },
        //         leadError,
        //         leadStatus,
        //         handleOnChange,
        //         handleOnValidate,
        //         showAccountSignupModal,
        //     },
        // } = this;

        return <Compatibility />;
    }

    /*
     *
     *
        BELOW HERE IN THIS FILE IS NO LONGER USED.
        STANDALONE COMPATIBILITY AND BYOD COMPATIBILITY ARE BOTH PROVIDED
        BY THE IMEI COMPONENT (imei.tsx).
        Lead capture as a result of imei check failure is not [yet] in new design.
        If lead capture or other code in this file is required for standalone mode,
        please move over to imei.tsx and you can use the isStandalone() getter
        to determine mode of operation.
     *
     *
    */

    //     if (hasRemoteSuccess) {
    //         return (
    //             <>
    //                 <BodyCopy thin>{__('device-check.body.success')}</BodyCopy>
    //                 <Button
    //                     noMargin
    //                     minWidth={222}
    //                     onClick={() => {
    //                         showAccountSignupModal();
    //                     }}
    //                     label={__('device-check.button.success')}
    //                 />
    //             </>
    //         );
    //     } else if (hasRemoteErrors) {
    //         return (
    //             <form onSubmit={handleCaptureLead}>
    //                 <BodyCopy thin>{__('device-check.body.error')}</BodyCopy>
    //                 <Input
    //                     noMargin
    //                     id="email"
    //                     onValidate={handleOnValidate}
    //                     onChange={handleOnChange}
    //                     value={email}
    //                     validate={InputValidator.validateEmail}
    //                     label={__('device-check.email.label')}
    //                     readOnly={leadStatus === FetchStatus.FETCHING}
    //                     placeholder={__('device-check.email.placeholder')}
    //                     error={leadError}
    //                 />
    //                 <Button
    //                     margin15Top
    //                     noMarginBottom
    //                     loading={leadStatus === FetchStatus.FETCHING}
    //                     minWidth={242}
    //                     type="submit"
    //                     label={__('device-check.button.error')}
    //                 />
    //             </form>
    //         );
    //     } else {
    //         const {
    //             handleCheckIMEI,
    //             hasLocalErrors,
    //             isFetching,
    //             props: { data: { imei }, handleOnChange, handleOnValidate },
    //         } = this;
    //         return (
    //             <form onSubmit={handleCheckIMEI}>
    //                 <BodyCopy thin>{__('device-check.description')}</BodyCopy>
    //                 <Input
    //                     noMargin
    //                     id="imei"
    //                     label={__('device-check.input.label')}
    //                     onValidate={handleOnValidate}
    //                     onChange={handleOnChange}
    //                     validate={InputValidator.validateIMEI}
    //                     readOnly={isFetching}
    //                     value={imei}
    //                     placeholder={__('device-check.input.placeholder')}
    //                 />
    //                 <BodyCopy thin>
    //                     <InfoLink
    //                         to={__('routes.help.imei')}
    //                         target="_blank"
    //                     >
    //                         {__('imei.how-to')}
    //                     </InfoLink>
    //                 </BodyCopy>
    //                 <Button
    //                     noMargin
    //                     loading={isFetching}
    //                     type="submit"
    //                     disabled={hasLocalErrors}
    //                     minWidth={222}
    //                     label={__('device-check.button.text')}
    //                 />
    //             </form>
    //         );
    //     }
    // }
    //
    // private get title() {
    //     const { hasRemoteErrors, hasRemoteSuccess } = this;
    //
    //     if (hasRemoteSuccess) {
    //         return __('device-check.title.success');
    //     } else if (hasRemoteErrors) {
    //         return __('device-check.title.error');
    //     } else {
    //         return __('device-check.title');
    //     }
    // }
    //
    // private get hasLocalErrors(): boolean {
    //     return this.props.errors.imei;
    // }
    //
    // private get isFetching(): boolean {
    //     return this.props.deviceCompatibilityStatus === FetchStatus.FETCHING;
    // }
    //
    // private get hasRemoteErrors(): boolean {
    //     return this.props.deviceCompatibilityStatus === FetchStatus.ERROR;
    // }
    //
    // private get hasRemoteSuccess(): boolean {
    //     return this.props.deviceCompatibilityStatus === FetchStatus.SUCCESS;
    // }
    //
    // private readonly handleCheckIMEI = (
    //     event: React.FormEvent<HTMLFormElement>,
    // ): void => {
    //     event.preventDefault();
    //     const {
    //         props: { checkDevice, data: { imei: deviceId } },
    //     } = this;
    //
    //     const deviceIdWithNoSpaces = deviceId.replace(/\s/g, '');
    //
    //     checkDevice({ deviceId: deviceIdWithNoSpaces });
    // };
    //
    // private readonly handleCaptureLead = (
    //     event: React.FormEvent<HTMLFormElement>,
    // ): void => {
    //     event.preventDefault();
    //     const { props: { captureLead, data: { email } } } = this;
    //     captureLead({ email });
    // };
    //
    // private readonly handleTryAgain = (): void => {
    //     const { props: { clearCheckDevice, handleOnChange } } = this;
    //     clearCheckDevice();
    //     handleOnChange({
    //         target: {
    //             name: 'imei',
    //             value: '',
    //         },
    //     });
    // };
}

export { DeviceCheck };
