import {
    AOResponse,
    IActivateAccountPayload,
    IConfigurationBasePaths,
    ICreateAccountPayload,
    INonNormalizedAccount,
    IValidateShortCodeResponse,
    ICartRequest,
    ICartResponse,
    IAccount,
} from 'models';
import { ThinAirService } from './thin-air.service';

interface IGenericResponse {
    readonly code: string;
    readonly message: string;
    readonly data?: any;
}
class AccountService extends ThinAirService {
    static async activateAccount(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        payload: IActivateAccountPayload,
    ): Promise<Response> {
        const response = await fetch(
            `${AccountService.BASE_URL}${basePaths.account}/activate`,
            {
                body: JSON.stringify(payload),
                headers: AccountService.getDefaultHeaders(firebaseToken),
                method: 'POST',
            },
        );

        if (response.status !== 200) {
            throw new Error(await AccountService.parseErrorOrDefault(response));
        }

        return response;
    }
    static async validateShortCodeBeforeSignUp(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        shortCode: string,
    ): Promise<IValidateShortCodeResponse> {
        const response = await AccountService.fetchJson<IGenericResponse>(
            `${AccountService.BASE_URL}${
                basePaths.referral
            }/shortcode?short_code=${shortCode}`,
            {
                headers: AccountService.getDefaultHeaders(firebaseToken),
            },
        );

        if (response.code === '200') {
            const {
                data: { shortcode },
            } = response;
            return shortcode;
        }

        throw new Error('Invalid code. Please retry.');
    }

    static async getExcToken(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
    ): Promise<Response> {
        const response = await fetch(
            `${AccountService.BASE_URL}${basePaths.auth}/tokenExchange`,
            {
                headers: AccountService.getExchangeToken(firebaseToken),
                method: 'POST',
            },
        );

        if (response.status !== 200) {
            throw new Error(await AccountService.parseErrorOrDefault(response));
        }

        return response;
    }

    static async refreshExcToken(
        basePaths: IConfigurationBasePaths,
        excToken: string,
    ) {
        const response = await fetch(
            `${AccountService.BASE_URL}${basePaths.auth}`,
            {
                headers: {
                    Authorization: `Bearer ${excToken}`,
                    'Content-Type': 'application/json',
                    grant_type: 'refresh_token',
                },
                method: 'POST',
            },
        );

        if (response.status !== 200) {
            throw new Error(await AccountService.parseErrorOrDefault(response));
        }

        return response;
    }

    static async createAccount(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        payload: ICreateAccountPayload,
    ): Promise<Response> {
        const response = await fetch(
            `${AccountService.BASE_URL}${basePaths.account}`,
            {
                body: JSON.stringify(payload),
                headers: AccountService.getDefaultHeaders(firebaseToken),
                method: 'POST',
            },
        );
        if (!new Set([201, 403]).has(response.status)) {
            throw new Error(await AccountService.parseErrorOrDefault(response));
        }

        return response;
    }

    static async updateAccount(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        payload: Partial<INonNormalizedAccount>,
    ): Promise<Partial<INonNormalizedAccount>> {
        return await AccountService.fetchJson<INonNormalizedAccount>(
            `${AccountService.BASE_URL}${basePaths.account}/updateaccount`,
            {
                body: JSON.stringify(payload),
                headers: AccountService.getDefaultHeaders(firebaseToken),
                method: 'PATCH',
            },
        );
    }

    static async updateAccountOld(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        payload: Partial<INonNormalizedAccount>,
    ): Promise<Response> {
        const response = await fetch(
            `${AccountService.BASE_URL}${basePaths.account}/updateaccount`,
            {
                body: JSON.stringify(payload),
                headers: AccountService.getDefaultHeaders(firebaseToken),
                method: 'PATCH',
            },
        );

        if (response.status !== 200) {
            throw new Error(await AccountService.parseError(response));
        }

        return response.json();
    }

    static async findAccount(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
    ): Promise<INonNormalizedAccount> {
        return (await AccountService.fetchJson(
            `${AccountService.BASE_URL}${basePaths.account}`,
            {
                headers: AccountService.getDefaultHeaders(firebaseToken),
            },
        )) as INonNormalizedAccount;
    }

    static populateFields(account: IAccount) {
        const fields = {
            FirstName: account.firstName,
            LastName: account.lastName,
            Email: account.emailAddress,
            Phone: account.phoneNumber,
        };
        window['embedded_svc'].settings.prepopulatedPrechatFields = fields;
    }

    static async cancelAccount(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
    ): Promise<void> {
        const response = await fetch(
            `${AccountService.BASE_URL}${basePaths.account}/cancel`,
            {
                headers: AccountService.getDefaultHeaders(firebaseToken),
            },
        );

        if (response.status !== 200) {
            throw new Error(await AccountService.parseErrorOrDefault(response));
        }

        return;
    }

    static async pauseAccount(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        pauseEndDate: string,
    ): Promise<void> {
        const response = await fetch(
            `${AccountService.BASE_URL}${basePaths.account}/pause`,
            {
                body: JSON.stringify({ pauseEndDate }),
                headers: AccountService.getDefaultHeaders(firebaseToken),
            },
        );

        if (response.status !== 200) {
            throw new Error(await AccountService.parseErrorOrDefault(response));
        }

        return;
    }

    static async getCart(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
    ): Promise<AOResponse> {
        return await AccountService.fetchJson<AOResponse>(
            `${AccountService.BASE_URL}${basePaths.account}/order`,
            {
                headers: AccountService.getDefaultHeaders(firebaseToken),
            },
        );
    }

    static async checkCart(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        cartId: string,
    ): Promise<ICartResponse> {
        let headers;
        if (firebaseToken) {
            headers = AccountService.getDefaultHeaders(firebaseToken);
        } else {
            headers = AccountService.getDefaultHeaders();
        }
        headers.append('cartId', cartId);
        return await AccountService.fetchJson<ICartResponse>(
            `${AccountService.BASE_URL}${basePaths.bag}/check`,
            {
                headers: headers,
                method: 'POST',
            },
        );
    }

    static async addItemsBag(
        basePaths: IConfigurationBasePaths,
        items: ICartRequest,
        cartId: string,
    ): Promise<ICartResponse> {
        let headers = AccountService.getDefaultHeaders();
        if (cartId) {
            headers.append('cartId', cartId);
        }
        return await AccountService.fetchJson<ICartResponse>(
            `${AccountService.BASE_URL}${basePaths.bag}/addItems`,
            {
                body: JSON.stringify(items),
                headers: headers,
                method: 'POST',
            },
        );
    }

    static async getOrderHistory(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
    ): Promise<AOResponse> {
        return await AccountService.fetchJson<AOResponse>(
            `${AccountService.BASE_URL}${basePaths.account}/orders`,
            {
                headers: AccountService.getDefaultHeaders(firebaseToken),
            },
        );
    }

    static async getOrderDetails(
        basePaths: IConfigurationBasePaths,
        firebaseToken: string,
        orderNumber?: string,
    ): Promise<AOResponse> {
        return await AccountService.fetchJson<AOResponse>(
            `${AccountService.BASE_URL}${
                basePaths.account
            }/order/details?orderNumber=${orderNumber}`,
            {
                headers: AccountService.getDefaultHeaders(firebaseToken),
            },
        );
    }
}

export { AccountService };
