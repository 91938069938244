import { IDefaultAction } from 'lib/br-redux';
import { IQueueableModal, IQueueableNotice } from 'models';
import { ActionType, ViewAction } from './view.actions';

// prettier-ignore
interface IViewState {
    readonly loadingRequests: ReadonlyArray<string>;
    readonly messages: ReadonlyArray<string>;
    readonly modalQueue: ReadonlyArray<IQueueableModal>;
    readonly noticeQueue: ReadonlyArray<IQueueableNotice>;
    readonly sidebarCartOpen: boolean;
}

const DEFAULT_STATE: IViewState = {
    loadingRequests: [],
    messages: [],
    modalQueue: [],
    noticeQueue: [],
    sidebarCartOpen: true,
};

const {
    REQUEST_LOADING_INDICATOR,
    DISMISS_LOADING_INDICATOR,
    SHOW_MESSAGE,
    DISMISS_MESSAGE,
    DISMISS_MODAL,
    SHOW_MODAL,
    SHOW_NOTICE,
    DISMISS_NOTICE,
    TOGGLE_SIDEBAR_CART,
    CLOSE_SIDEBAR_CART,
    MODAL_LOADING,
} = ActionType;

const viewReducer = (
    state: IViewState = DEFAULT_STATE,
    action: ViewAction | IDefaultAction,
): IViewState => {
    switch (action.type) {
        case REQUEST_LOADING_INDICATOR:
            return {
                ...state,
                loadingRequests: [...state.loadingRequests, action.payload],
            };
        case DISMISS_LOADING_INDICATOR:
            const symbolIdx = state.loadingRequests.indexOf(action.payload);
            return {
                ...state,
                loadingRequests: [
                    ...state.loadingRequests.slice(0, symbolIdx),
                    ...state.loadingRequests.slice(symbolIdx + 1),
                ],
            };
        case SHOW_MESSAGE:
            return {
                ...state,
                messages: [...state.messages, action.payload],
            };
        case DISMISS_MESSAGE:
            return {
                ...state,
                messages: [
                    ...state.messages.slice(0, action.payload),
                    ...state.messages.slice(action.payload + 1),
                ],
            };
        case SHOW_MODAL:
            return {
                ...state,
                modalQueue: [action.payload, ...state.modalQueue],
            };
        case DISMISS_MODAL:
            const [, ...queue] = state.modalQueue;
            return {
                ...state,
                modalQueue: [...queue],
            };
        case MODAL_LOADING:
            const newModalQueue: ReadonlyArray<any> = [...state.modalQueue];
            if (newModalQueue[0]) {
                if (action.payload.primary) {
                    newModalQueue[0].additionalProps.primaryLoading =
                        action.payload.loading;
                } else {
                    newModalQueue[0].additionalProps.secondaryLoading =
                        action.payload.loading;
                }
            }
            return {
                ...state,
                modalQueue: newModalQueue,
            };
        case SHOW_NOTICE:
            return {
                ...state,
                noticeQueue: [action.payload, ...state.noticeQueue],
            };
        case DISMISS_NOTICE:
            return {
                ...state,
                noticeQueue: [],
            };
        case TOGGLE_SIDEBAR_CART:
            return {
                ...state,
                sidebarCartOpen: !state.sidebarCartOpen,
            };
        case CLOSE_SIDEBAR_CART:
            return {
                ...state,
                sidebarCartOpen: false,
            };
        default:
            return state;
    }
};

export { DEFAULT_STATE, IViewState, viewReducer };
