import {
    ActionType,
    fetchPhones,
    fetchPhonesError,
    fetchPhonesSuccess,
    fetchProducts,
    FetchProductsAction,
    fetchProductsError,
    fetchProductsSuccess,
} from './products.actions';
import {
    DEFAULT_STATE,
    IProductsState,
    productsReducer,
} from './products.reducer';
import { productsSaga } from './products.saga';
export {
    productsSaga,
    fetchProductsSuccess,
    fetchProductsError,
    fetchProducts,
    fetchPhones,
    fetchPhonesSuccess,
    fetchPhonesError,
    ActionType,
    FetchProductsAction,
    IProductsState,
    productsReducer,
    DEFAULT_STATE,
};
