// import * as load from 'little-loader';
// import { StartupScript } from 'models';
// import { FacebookLoginStatus } from 'models/facebook';
// import { delay } from 'redux-saga';
import { StartupScript } from 'models';
import { all, put } from 'redux-saga/effects';
// import { AuthenticationActions } from 'state/authentication';
import { StartupActions } from 'state/startup';
// import { eventError } from 'state/tealium';
// declare var FB: any;

// const createCallback = done => {
//     (window as any).fbAsyncInit = () => {
//         FB.init({
//             appId: process.env.FACEBOOK_APP_ID,
//             autoLogAppEvents: true,
//             version: process.env.FACEBOOK_APP_VERSION,
//             xfbml: true,
//         });
//         done();
//     };
// };

// const loadFB = reject => {
//     load('https://connect.facebook.net/en_US/sdk.js', error => {
//         if (error) {
//             reject();
//         }
//     });
// };
// const fetchFacebookLoginStatus = () =>
//     new Promise(resolve => {
//         FB.getLoginStatus(({ status }) => {
//             resolve(status as FacebookLoginStatus);
//         });
//     });

// const setCallbackAndLoadFacebook = () =>
//     new Promise((resolve, reject) => {
//         createCallback(resolve);
//         loadFB(reject);
//     });

// function* getFacebookLoginStatus() {
//     const { status, timeout } = yield race({
//         status: call(fetchFacebookLoginStatus),
//         timeout: call(delay, 10000),
//     });
//     if (timeout) {
//         yield put(
//             eventError({
//                 error_msg: __('startup.social.facebook-status-error'),
//             }),
//         );
//         throw Error(__('startup.social.facebook-status-error'));
//     }
//     return status;
// }

// function* initialize() {
//     try {
//         if (typeof process.env.FACEBOOK_APP_ID === 'undefined') {
//             yield put(
//                 eventError({
//                     error_msg: __(
//                         'startup.social.facebook-app-id-undefined-error',
//                     ),
//                 }),
//             );
//             throw Error(__('startup.social.facebook-app-id-undefined-error'));
//         }

//         yield call(setCallbackAndLoadFacebook);
//         const status: FacebookLoginStatus = yield call(getFacebookLoginStatus);
//         yield put(AuthenticationActions.setFacebookLoginStatus(status));
//         yield put(
//             StartupActions.reportInitializationSuccess(StartupScript.FACEBOOK),
//         );
//     } catch (err) {
//         yield put(
//             AuthenticationActions.setFacebookLoginStatus(
//                 FacebookLoginStatus.ERROR,
//             ),
//         );
//         yield put(
//             eventError({
//                 error_msg: __('startup.social.facebook-initialization-error'),
//             }),
//         );
//         yield put(
//             StartupActions.reportInitializationFailure(StartupScript.FACEBOOK),
//         );
//     }
// }

// function* facebookSDKSaga() {
//     yield all([initialize()]);
// }

function* initialize() {
    yield put(
        StartupActions.reportInitializationFailure(StartupScript.FACEBOOK),
    );
}

function* facebookSDKSaga() {
    yield all([initialize()]);
}

export { facebookSDKSaga };
