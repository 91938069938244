import { withAnalytics } from 'components/common/analytics';
import { withInputValidation } from 'components/common/forms';
import { IMEI } from 'components/sign-up/components/phone-number/imei';
import { IApplicationState, ICartRequest } from 'models';
import { IDeviceCompatibilityPayload } from 'models/device';
import { ProductType } from 'models/products';
import { ProductCollection } from 'models/products/products.model';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import {
    checkDevice,
    clearCheckDevice,
    clearFetchStatus,
    loadCarriersModels,
    lockedCheckDevice,
    skipCheckDevice,
} from 'state/device/compatibility/compatibility.actions';
import { addItemsCart } from 'state/account/account.actions';

const dataKeys: ReadonlyArray<string> = ['imei'];

const mapStateToProps = ({
    authentication,
    configuration,
    device: { compatibility },
    purchase,
    products,
    registration: {
        prefilledInfo: { imei: prefilledIMEI },
    },
    account,
}: IApplicationState) => {
    return {
        authStatus: authentication.fetchStatus,
        cart: purchase.cart.result,
        deviceCompatibilityCarriersModels: compatibility.carriersModels,
        deviceCompatibilityFetchStatus: compatibility.fetchStatus,
        deviceCompatibilityResponse: compatibility.compatibility,
        isEnableSwap: !!(
            configuration.toggles && configuration.toggles.enableSwap
        ),
        handset: new ProductCollection(products.results).handset,
        pendingItem: purchase.cart.pendingItems.find(
            p => p.productType === ProductType.HANDSET,
        ),
        prefilledIMEI,
        bag: account.bagResponse,
    };
};

const mapDispatchToProps = dispatch => ({
    addItemsCart: (payload: ICartRequest) => dispatch(addItemsCart(payload)),
    checkDevice: (payload: IDeviceCompatibilityPayload) =>
        dispatch(checkDevice(payload)),
    clearCheckDevice: () => dispatch(clearCheckDevice()),
    clearFetchStatus: () => dispatch(clearFetchStatus()),
    lockedCheckDevice: () => dispatch(lockedCheckDevice()),
    redirectTo: (url: string) => dispatch(push(url)),
    skipCheckDevice: () => dispatch(skipCheckDevice()),
    loadCarriersModels: () => dispatch(loadCarriersModels()),
});

const enhance = compose(
    withAnalytics,
    withInputValidation(dataKeys),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const IMEIContainer = enhance(IMEI);

export { IMEIContainer };
