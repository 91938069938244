// tslint:disable:max-line-length
import * as React from 'react';

interface Props extends React.HTMLProps<SVGElement> {
    readonly invert?: boolean;
}

const Three = ({ invert, ...other }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="115"
        height="115"
        viewBox="0 0 115 115"
        // {...other}
    >
        <g fill={invert ? '#ffffff' : '#1800FF'} fillRule="evenodd">
            <path d="M93.68 50c0 7.408-5.95 13.437-13.26 13.437S67.159 57.408 67.159 50H48.84c0 7.408-5.95 13.437-13.259 13.437-7.311 0-13.26-6.029-13.26-13.437H4c0 17.644 14.168 32 31.581 32 8.76 0 16.693-3.633 22.418-9.488C63.724 78.367 71.661 82 80.421 82 97.834 82 112 67.644 112 50H93.68zM25 11c0 6.074-4.927 11-11 11-6.075 0-11-4.926-11-11S7.925 0 14 0c6.073 0 11 4.926 11 11M113 11c0 6.074-4.926 11-11.001 11C95.924 22 91 17.074 91 11s4.924-11 10.999-11S113 4.926 113 11" />
        </g>
    </svg>
);

export { Three };
