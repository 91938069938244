import * as Emoji from 'components/common/graphics/emoji';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
const styles = require('./loading-indicator.less');

interface Props {
    readonly visible?: boolean;
}

interface State {
    readonly emojiIndex: number;
}

@CSSModules(styles, { allowMultiple: true })
class LoadingIndicator extends React.Component<Props, State> {
    // tslint:disable-next-line:readonly-keyword
    private interval: any;
    private readonly maxEmojiIndex = 8;

    private readonly emoji: ReadonlyArray<any> = [
        Emoji.Surprised,
        Emoji.Smile,
        Emoji.T,
        Emoji.Three,
        Emoji.D,
        Emoji.U,
        Emoji.F,
        Emoji.Squiggle,
        Emoji.Diamond,
    ];

    constructor(props: Props) {
        super(props);
        this.state = {
            emojiIndex: 0,
        };
        this.interval = setInterval(this.updateEmoji, 500);
    }

    componentWillUnmount(): void {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
        }
    }

    render() {
        const EmojiToRender = this.emoji[this.state.emojiIndex];
        return this.props.visible ? (
            <div styleName="bg">
                <EmojiToRender styleName="emoji" />
            </div>
        ) : null;
    }

    private readonly updateEmoji = () => {
        const emojiIndex =
            this.state.emojiIndex < this.maxEmojiIndex
                ? this.state.emojiIndex + 1
                : 0;
        this.setState({ emojiIndex });
    };
}
export { LoadingIndicator };
