import { createAction, IAction } from 'lib/br-redux';
import {
    ICommissionUpdateRequest,
    IReferralEvent,
    IReferrerRequest,
    IReferrerStats,
    IReferrerStatsRequest,
    IReferrerUpdateRequest,
    IReferringAmbassador,
    IValidateShortCodeResponse,
} from 'models/referral/referral.model';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    VALIDATE_SHORT_CODE = 'REFERRAL/VALIDATE_SHORT_CODE',
    REPORT_VALIDATE_SHORT_CODE_SUCCESS = 'REFERRAL/REPORT_VALIDATE_SHORT_CODE_SUCCESS',
    REPORT_VALIDATE_SHORT_CODE_FAILURE = 'REFERRAL/REPORT_VALIDATE_SHORT_CODE_FAILURE',

    POST_EVENT = 'REFERRAL/POST_EVENT',
    REPORT_POST_EVENT_SUCCESS = 'REFERRAL/REPORT_POST_EVENT_SUCCESS',
    REPORT_POST_EVENT_FAILURE = 'REFERRAL/REPORT_POST_EVENT_FAILURE',

    GET_OR_CREATE_REFERRER = 'REFERRAL/GET_OR_CREATE_REFERRER',
    REPORT_GET_OR_CREATE_REFERRER_SUCCESS = 'REFERRAL/REPORT_GET_OR_CREATE_REFERRER_SUCCESS',
    REPORT_GET_OR_CREATE_REFERRER_FAILURE = 'REFERRAL/REPORT_GET_OR_CREATE_REFERRER_FAILURE',

    UPDATE_REFERRER = 'REFERRAL/UPDATE_REFERRER',
    REPORT_UPDATE_REFERRER_SUCCESS = 'REFERRAL/REPORT_UPDATE_REFERRER_SUCCESS',
    REPORT_UPDATE_REFERRER_FAILURE = 'REFERRAL/REPORT_UPDATE_REFERRER_FAILURE',

    UPDATE_COMMISSION = 'REFERRAL/UPDATE_COMMISSION',
    REPORT_UPDATE_COMMISSION_SUCCESS = 'REFERRAL/REPORT_UPDATE_COMMISSION_SUCCESS',
    REPORT_UPDATE_COMMISSION_FAILURE = 'REFERRAL/REPORT_UPDATE_COMMISSION_FAILURE',

    NEW_REFERRAL_INFO = 'REFERRAL/NEW_REFERRAL_INFO',
    REPORT_NEW_REFERRAL_INFO_SUCCESS = 'REFERRAL/REPORT_NEW_REFERRAL_INFO_SUCCESS',
    REPORT_NEW_REFERRAL_INFO_FAILURE = 'REFERRAL/REPORT_NEW_REFERRAL_INFO_FAILURE',

    GET_CREDIT_BALANCE = 'REFERRAL/GET_CREDIT_BALANCE',
    REPORT_GET_CREDIT_BALANCE_SUCCESS = 'REFERRAL/REPORT_GET_CREDIT_BALANCE_SUCCESS',
    REPORT_GET_CREDIT_BALANCE_FAILURE = 'REFERRAL/REPORT_GET_CREDIT_BALANCE_FAILURE',

    GET_CREDIT_EARNED = 'REFERRAL/GET_CREDIT_EARNED',
    REPORT_GET_CREDIT_EARNED_SUCCESS = 'REFERRAL/REPORT_GET_CREDIT_EARNED_SUCCESS',
    REPORT_GET_CREDIT_EARNED_FAILURE = 'REFERRAL/REPORT_GET_CREDIT_EARNED_FAILURE',

    GET_REFERRER_STATS = 'REFERRAL/GET_REFERRER_STATS',
    REPORT_GET_REFERRER_STATS_SUCCESS = 'REFERRAL/REPORT_GET_REFERRER_STATS_SUCCESS',
    REPORT_GET_REFERRER_STATS_FAILURE = 'REFERRAL/REPORT_GET_REFERRER_STATS_FAILURE',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type IValidateShortCodeAction = IAction<ActionType.VALIDATE_SHORT_CODE, string>;

type IValidateShortCodeSuccessAction = IAction<
    ActionType.REPORT_VALIDATE_SHORT_CODE_SUCCESS,
    IValidateShortCodeResponse
>;

type IValidateShortCodeFailureAction = IAction<
    ActionType.REPORT_VALIDATE_SHORT_CODE_FAILURE,
    string
>;

type IPostEventAction = IAction<ActionType.POST_EVENT, IReferralEvent>;

type IPostEventSuccessAction = IAction<ActionType.REPORT_POST_EVENT_SUCCESS>;

type IPostEventFailureAction = IAction<
    ActionType.REPORT_POST_EVENT_FAILURE,
    string
>;

type IGetOrCreateReferrerAction = IAction<
    ActionType.GET_OR_CREATE_REFERRER,
    IReferrerRequest
>;

type IGetOrCreateReferrerSuccessAction = IAction<
    ActionType.REPORT_GET_OR_CREATE_REFERRER_SUCCESS,
    IReferringAmbassador
>;

type IGetOrCreateReferrerFailureAction = IAction<
    ActionType.REPORT_GET_OR_CREATE_REFERRER_FAILURE,
    string
>;

type IUpdateReferrerAction = IAction<
    ActionType.UPDATE_REFERRER,
    IReferrerUpdateRequest
>;

type IUpdateReferrerSuccessAction = IAction<
    ActionType.REPORT_UPDATE_REFERRER_SUCCESS
>;

type IUpdateReferrerFailureAction = IAction<
    ActionType.REPORT_UPDATE_REFERRER_FAILURE,
    string
>;

type IUpdateCommissionAction = IAction<
    ActionType.UPDATE_COMMISSION,
    ICommissionUpdateRequest
>;

type IUpdateCommissionSuccessAction = IAction<
    ActionType.REPORT_UPDATE_COMMISSION_SUCCESS
>;

type IUpdateCommissionFailureAction = IAction<
    ActionType.REPORT_UPDATE_COMMISSION_FAILURE,
    string
>;

type INewReferralInfoAction = IAction<ActionType.NEW_REFERRAL_INFO>;

type INewReferralInfoSuccessAction = IAction<
    ActionType.REPORT_NEW_REFERRAL_INFO_SUCCESS
>;

type INewReferralInfoFailureAction = IAction<
    ActionType.REPORT_NEW_REFERRAL_INFO_FAILURE,
    string
>;

type IGetCreditBalanceAction = IAction<ActionType.GET_CREDIT_BALANCE>;

type IGetCreditBalanceSuccessAction = IAction<
    ActionType.REPORT_GET_CREDIT_BALANCE_SUCCESS,
    string
>;

type IGetCreditBalanceFailureAction = IAction<
    ActionType.REPORT_GET_CREDIT_BALANCE_FAILURE,
    string
>;

type IGetCreditEarnedAction = IAction<ActionType.GET_CREDIT_EARNED>;

type IGetCreditEarnedSuccessAction = IAction<
    ActionType.REPORT_GET_CREDIT_EARNED_SUCCESS,
    string
>;

type IGetCreditEarnedFailureAction = IAction<
    ActionType.REPORT_GET_CREDIT_EARNED_FAILURE,
    string
>;

type IGetReferrerStatsAction = IAction<
    ActionType.GET_REFERRER_STATS,
    IReferrerStatsRequest
>;

type IGetReferrerStatsSuccessAction = IAction<
    ActionType.REPORT_GET_REFERRER_STATS_SUCCESS,
    IReferrerStats
>;

type IGetReferrerStatsFailureAction = IAction<
    ActionType.REPORT_GET_REFERRER_STATS_FAILURE,
    string
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IReferralAction =
    | IValidateShortCodeAction
    | IValidateShortCodeSuccessAction
    | IValidateShortCodeFailureAction
    | IPostEventAction
    | IPostEventSuccessAction
    | IPostEventFailureAction
    | IGetOrCreateReferrerAction
    | IGetOrCreateReferrerSuccessAction
    | IGetOrCreateReferrerFailureAction
    | IUpdateReferrerAction
    | IUpdateReferrerSuccessAction
    | IUpdateReferrerFailureAction
    | IUpdateCommissionAction
    | IUpdateCommissionSuccessAction
    | IUpdateCommissionFailureAction
    | INewReferralInfoAction
    | INewReferralInfoSuccessAction
    | INewReferralInfoFailureAction
    | IGetCreditBalanceAction
    | IGetCreditBalanceSuccessAction
    | IGetCreditBalanceFailureAction
    | IGetCreditEarnedAction
    | IGetCreditEarnedSuccessAction
    | IGetCreditEarnedFailureAction
    | IGetReferrerStatsAction
    | IGetReferrerStatsSuccessAction
    | IGetReferrerStatsFailureAction;

// ------------------------------------
// Action Creators
// i.e. the stuff that actually makes it to the compiled Javascript
// ------------------------------------

const validateShortCode = createAction(
    ActionType.VALIDATE_SHORT_CODE,
    (shortCode: string) => shortCode,
);

const validateShortCodeSuccess = createAction(
    ActionType.REPORT_VALIDATE_SHORT_CODE_SUCCESS,
    (shortCodeResponse: IValidateShortCodeResponse) => shortCodeResponse,
);

const validateShortCodeFailure = createAction(
    ActionType.REPORT_VALIDATE_SHORT_CODE_FAILURE,
    (message: string) => message,
);

const postEvent = createAction(
    ActionType.POST_EVENT,
    (event: IReferralEvent) => event,
);

const postEventSuccess = createAction(ActionType.REPORT_POST_EVENT_SUCCESS);

const postEventFailure = createAction(
    ActionType.REPORT_POST_EVENT_FAILURE,
    (message: string) => message,
);

const getOrCreateReferrer = createAction(
    ActionType.GET_OR_CREATE_REFERRER,
    (referrerRequest: IReferrerRequest) => referrerRequest,
);

const getOrCreateReferrerSuccess = createAction(
    ActionType.REPORT_GET_OR_CREATE_REFERRER_SUCCESS,
    (referringAmbassador: IReferringAmbassador) => referringAmbassador,
);

const getOrCreateReferrerFailure = createAction(
    ActionType.REPORT_GET_OR_CREATE_REFERRER_FAILURE,
    (message: string) => message,
);

const updateReferrer = createAction(
    ActionType.UPDATE_REFERRER,
    (referrerRequest: IReferrerRequest) => referrerRequest,
);

const updateReferrerSuccess = createAction(
    ActionType.REPORT_UPDATE_REFERRER_SUCCESS,
);

const updateReferrerFailure = createAction(
    ActionType.REPORT_UPDATE_REFERRER_FAILURE,
    (message: string) => message,
);

const updateCommission = createAction(
    ActionType.UPDATE_COMMISSION,
    (commissionUpdateRequest: ICommissionUpdateRequest) =>
        commissionUpdateRequest,
);

const updateCommissionSuccess = createAction(
    ActionType.REPORT_UPDATE_COMMISSION_SUCCESS,
);

const updateCommissionFailure = createAction(
    ActionType.REPORT_UPDATE_COMMISSION_FAILURE,
    (message: string) => message,
);

const newReferralInfo = createAction(ActionType.NEW_REFERRAL_INFO);

const newReferralInfoSuccess = createAction(
    ActionType.REPORT_NEW_REFERRAL_INFO_SUCCESS,
);

const newReferralInfoFailure = createAction(
    ActionType.REPORT_NEW_REFERRAL_INFO_FAILURE,
    (message: string) => message,
);

const getCreditBalance = createAction(ActionType.GET_CREDIT_BALANCE);

const getCreditBalanceSuccess = createAction(
    ActionType.REPORT_GET_CREDIT_BALANCE_SUCCESS,
    (creditBalance: string) => creditBalance,
);

const getCreditBalanceFailure = createAction(
    ActionType.REPORT_GET_CREDIT_BALANCE_FAILURE,
    (message: string) => message,
);

const getCreditEarned = createAction(ActionType.GET_CREDIT_EARNED);

const getCreditEarnedSuccess = createAction(
    ActionType.REPORT_GET_CREDIT_EARNED_SUCCESS,
    (creditEarned: string) => creditEarned,
);

const getCreditEarnedFailure = createAction(
    ActionType.REPORT_GET_CREDIT_EARNED_FAILURE,
    (message: string) => message,
);

const getReferrerStats = createAction(
    ActionType.GET_REFERRER_STATS,
    (email: string) => email,
);

const getReferrerStatsSuccess = createAction(
    ActionType.REPORT_GET_REFERRER_STATS_SUCCESS,
    (referrerStats: IReferrerStats) => referrerStats,
);

const getReferrerStatsFailure = createAction(
    ActionType.REPORT_GET_REFERRER_STATS_FAILURE,
    (message: string) => message,
);

export {
    ActionType,
    IReferralAction,
    IValidateShortCodeAction,
    IValidateShortCodeSuccessAction,
    IValidateShortCodeFailureAction,
    IValidateShortCodeResponse,
    IPostEventAction,
    IPostEventSuccessAction,
    IPostEventFailureAction,
    IGetOrCreateReferrerAction,
    IGetOrCreateReferrerSuccessAction,
    IGetOrCreateReferrerFailureAction,
    IUpdateReferrerAction,
    IUpdateReferrerSuccessAction,
    IUpdateReferrerFailureAction,
    IUpdateCommissionAction,
    IUpdateCommissionSuccessAction,
    IUpdateCommissionFailureAction,
    INewReferralInfoAction,
    INewReferralInfoSuccessAction,
    INewReferralInfoFailureAction,
    IGetCreditBalanceAction,
    IGetCreditBalanceSuccessAction,
    IGetCreditBalanceFailureAction,
    IGetReferrerStatsAction,
    IGetReferrerStatsSuccessAction,
    IGetReferrerStatsFailureAction,
    validateShortCode,
    validateShortCodeSuccess,
    validateShortCodeFailure,
    postEvent,
    postEventSuccess,
    postEventFailure,
    getOrCreateReferrer,
    getOrCreateReferrerSuccess,
    getOrCreateReferrerFailure,
    updateReferrer,
    updateReferrerSuccess,
    updateReferrerFailure,
    updateCommission,
    updateCommissionSuccess,
    updateCommissionFailure,
    newReferralInfo,
    newReferralInfoSuccess,
    newReferralInfoFailure,
    getCreditBalance,
    getCreditBalanceSuccess,
    getCreditBalanceFailure,
    getCreditEarned,
    getCreditEarnedSuccess,
    getCreditEarnedFailure,
    getReferrerStats,
    getReferrerStatsSuccess,
    getReferrerStatsFailure,
};
