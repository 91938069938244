import * as React from 'react';
import * as CSSModules from 'react-css-modules';

interface Props {
    readonly children?: any;
    readonly until?: boolean;
    readonly when?: boolean;
    readonly element?: string;
    readonly additionalProps?: React.HTMLProps<any>;
}

const HiddenComponent = ({
    children,
    until,
    when,
    element,
    additionalProps,
}: Props) => {
    if (until === true || when === false) {
        if (element || additionalProps) {
            return React.createElement(element || 'div', {
                ...additionalProps,
                children,
                styleName: 'hidden',
            });
        }

        return <>{children}</>;
    }

    return null;
};

const Hidden = CSSModules(HiddenComponent, require('./hidden.less'));
export { Hidden };
