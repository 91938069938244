import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { FetchStatus } from 'lib/br-redux';
import { KOUNT_ENABLED } from 'models/cart';
import { Dots } from '../dots';
import { BraintreeDropInReact } from './braintree-dropin-react';

const styles = require('./braintree-drop-in.less');

declare var braintree: any;

interface DropInPaymentMethodPayload {
    readonly nonce: string;
}

interface IMappedProps {
    readonly authInitialized: boolean;
    readonly authStatus: FetchStatus;
    readonly clientToken: string;
    readonly clientTokenStatus: FetchStatus;
    readonly error: string;

    getClientToken(): void;
    reportKountDeviceSuccess(): void;
}

interface SubmitButtonRenderProps {
    readonly isDisabled: boolean;
    onClick(): void;
}

interface IOtherProps {
    renderSubmitButton(props: SubmitButtonRenderProps): JSX.Element;
    onGetPaymentNonce(nonce: string): void;
    onError(error: any): void;
    onCreate(): void;
    onReportKountDevice(kountObj: object): void;
    onClose(): void;
}

interface IState {
    readonly clientTokenFetchAttempts: number;
    readonly intervalId?: number;
    readonly isLoading: boolean;
    readonly submitButton?: HTMLButtonElement;
}

const DEFAULT_STATE: IState = {
    clientTokenFetchAttempts: 0,
    intervalId: undefined,
    isLoading: true,
};

type IProps = IMappedProps & IOtherProps;

@CSSModules(styles, { allowMultiple: true })
class BraintreeDropIn extends React.Component<IProps, IState> {
    componentWillMount(): void {
        this.setState(DEFAULT_STATE);
    }

    componentDidMount(): void {
        this.maybeGetClientToken(this.props);
    }

    componentWillReceiveProps(newProps: IProps): void {
        this.maybeGetClientToken(newProps);
    }

    render() {
        const {
            props: { clientToken, renderSubmitButton },
            state: { isLoading },
        } = this;
        return (
            <>
                {isLoading && this.dots}
                {clientToken && (
                    <div
                        styleName={classNames('wrapper', {
                            hide: isLoading,
                        })}
                    >
                        <span style={{ color: 'red' }}>{this.props.error}</span>
                        <BraintreeDropInReact
                            braintree={braintree.dropin}
                            authorizationToken={clientToken}
                            card={{
                                overrides: {
                                    fields: {
                                        cvv: { maskInput: true },
                                        number: { maskInput: true },
                                    },
                                },
                            }}
                            handlePaymentMethod={this.handleSubmit}
                            onError={this.props.onError}
                            onCreate={this.onCreate}
                            paypal={{ singleUse: false, flow: 'vault' }}
                            renderSubmitButton={renderSubmitButton}
                            dataCollector={{
                                kount: KOUNT_ENABLED,
                                paypal: true,
                            }}
                        />
                    </div>
                )}
            </>
        );
    }

    private incrementInitTries() {
        this.setState({
            clientTokenFetchAttempts: this.state.clientTokenFetchAttempts + 1,
        });
    }

    private maybeGetClientToken(newProps: IProps): void {
        const {
            authInitialized,
            authStatus,
            clientToken,
            clientTokenStatus,
            getClientToken,
        } = newProps;
        const { clientTokenFetchAttempts } = this.state;

        if (
            (!clientToken || !clientToken.trim()) && // we don't already have a client token
            clientTokenFetchAttempts < 3 &&
            clientTokenStatus !== FetchStatus.FETCHING && // we aren't already trying to fetch a client token
            authInitialized && // auth module is initialized
            authStatus === FetchStatus.SUCCESS // user is logged in
        ) {
            this.incrementInitTries();
            getClientToken();
        }
    }

    private readonly handleSubmit = (
        payload: DropInPaymentMethodPayload,
    ): void => {
        this.props.onGetPaymentNonce(payload.nonce);
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    private readonly onCreate = dropinInstance => {
        this.setState({ isLoading: false });
        this.props.onReportKountDevice(dropinInstance._dataCollectorInstance);
    };
    private get dots(): JSX.Element {
        return <Dots invert />;
    }
}

export { BraintreeDropIn };
