import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Hidden } from 'components/common/hidden';
import { ErrorMsg } from 'components/common/typography';
import {
    ButtonContainer as Button,
    InputContainer as Input,
    SelectNpaNxxFormContainer as SelectNpaNxxForm,
    ZipCodeFormContainer as ZipCodeForm,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { IProductItemModel } from 'models';
import { Cart } from 'models/cart/cart.model';
import { ProductType } from 'models/products';
import { ICartItemPayload } from 'models/products/products.model';
import { PendingItem } from 'state/purchase/cart/cart.reducer';

interface IMappedProps {
    readonly displayNpaNpxList: boolean;
    readonly mdn: string;
    readonly displayMdn: boolean;
    readonly cart: Cart | null;
    readonly sim: IProductItemModel | undefined;
    readonly pendingItem: PendingItem | undefined;
    fetchMdn(npanxx: string): void;
    addItemToCart(item: ICartItemPayload): void;
    clearNpaNxx(): void;
}

interface IOtherProps {
    onNext(): void;
}

const styles = require('./new-number.less');

type IProps = IMappedProps & IOtherProps;

@CSSModules(styles, { allowMultiple: true })
class NewNumber extends React.Component<IProps> {
    componentWillUnmount() {
        this.props.clearNpaNxx();
    }
    render() {
        const { displayNpaNpxList, fetchMdn, displayMdn, mdn } = this.props;
        return (
            <>
                <ErrorMsg>{this.error}</ErrorMsg>
                <ZipCodeForm complete={displayNpaNpxList} />
                <Hidden until={displayNpaNpxList && !displayMdn}>
                    <SelectNpaNxxForm
                        complete={displayMdn}
                        handleSubmit={fetchMdn}
                    />
                </Hidden>
                <Hidden until={displayMdn}>
                    <>
                        <Input
                            noMargin
                            label={__('select-npa-nxx-form.label')}
                            id="selected-npa-nxx"
                            readOnly
                            value={mdn.replace(
                                /(\d{3})(\d{3})(\d{4})/,
                                '($1) $2',
                            )}
                        />
                        <Input
                            noMargin
                            label={__('new-number.new-number-label')}
                            id="mdn"
                            readOnly
                            value={mdn.replace(
                                /(\d{3})(\d{3})(\d{4})/,
                                '($1) $2-$3',
                            )}
                        />
                        <Button
                            right
                            loading={this.isFetching}
                            onClick={this.next}
                            label="Next"
                        />
                    </>
                </Hidden>
            </>
        );
    }

    componentWillReceiveProps(newProps: IProps): void {
        const { cart, onNext, sim } = newProps;
        if (sim) {
            if (
                cart &&
                cart.hasProductType(ProductType.SIM) &&
                this.fetchStatus !== FetchStatus.SUCCESS
            ) {
                onNext();
            }
        }
    }

    private get fetchStatus(): FetchStatus {
        const { cart, pendingItem } = this.props;
        if (cart && cart.hasProductType(ProductType.SIM)) {
            return FetchStatus.SUCCESS;
        }

        if (pendingItem) {
            return pendingItem.error && pendingItem.error.trim()
                ? FetchStatus.ERROR
                : FetchStatus.FETCHING;
        }

        return FetchStatus.NOT_FETCHED;
    }

    private get error(): string {
        const { pendingItem } = this.props;
        if (pendingItem) {
            return pendingItem.error;
        }

        return '';
    }

    private get isFetching(): boolean {
        return this.fetchStatus === FetchStatus.FETCHING;
    }

    private readonly next = (): void => {
        const { sim, addItemToCart, mdn } = this.props;
        if (sim) {
            const { itemid } = sim;
            addItemToCart({
                itemid,
                newMdn: mdn,
                productType: ProductType.SIM,
                quantity: 1,
            });
        } else {
            // tslint:disable-next-line:no-console
            console.error('Could not "sim" item');
        }
    };
}

export { NewNumber };
