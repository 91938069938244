import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./logo.less');

interface Props {
    // TODO(dan) Old logo must remain in markup or mobile social icons render wrong. Why?  https://cl.ly/0c1f528f201d
    readonly old?: boolean;
}

@CSSModules(styles, { allowMultiple: true })
class Logo extends React.Component<Props> {
    render() {
        return !this.props.old ? (
            <div styleName="visible-logo-wrapper">
                <div styleName="visible-logo-fixed">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="86.3"
                        height="24"
                        viewBox="0 0 109 30"
                    >
                        <title>Visible logo</title>
                        <path
                            fill="#1800FF"
                            fillRule="evenodd"
                            // tslint:disable:max-line-length
                            d="M95.838 17.165c.303-2.23 1.776-3.898 3.819-3.898 2.517 0 3.475 1.708 3.779 3.898h-7.598zm13.105 3.425v-.669c0-5.827-3.317-10.512-9.599-10.512-6.203 0-9.436 4.606-9.436 10.473 0 5.866 3.78 10.118 9.71 10.118 4.019 0 8.182-2.26 9.382-6.608h-5.605c-.74 1.535-1.992 2.395-3.684 2.395-2.848 0-4.029-2.756-4.029-5.197h13.261zm-27.828 8.78h5.463V0h-5.463v29.37zm-8.895-9.685c0 2.727-1.5 5.433-4.8 5.433-3.08 0-4.992-2.706-4.992-5.433 0-2.729 1.912-5.394 4.992-5.394 3.3 0 4.8 2.665 4.8 5.394zM57.43 29.37h5.462v-1.894h.078C64.063 29.051 66.596 30 68.547 30c5.877 0 9.214-5.118 9.214-10.394 0-5.079-3.7-10.197-9.344-10.197-2.028 0-3.96.863-5.525 2.509V0h-5.463v29.37zM52.981 3.268C52.982 1.496 51.538 0 49.743 0c-1.794 0-3.238 1.496-3.238 3.268 0 1.811 1.444 3.268 3.238 3.268 1.795 0 3.239-1.457 3.239-3.268zm-8.87 8.356c-1.45-1.344-3.71-2.215-5.958-2.215-3.59 0-6.087 2.637-6.087 6.26 0 3.11 1.912 4.252 3.824 5.079 1.911.826 3.823 1.22 3.823 2.913 0 1.26-.975 2.087-2.185 2.087-1.6 0-3.072-.983-4.058-2.244l-2.568 3.382C32.58 28.934 35.149 30 37.724 30c4.02 0 7.453-2.559 7.453-6.85 0-3.347-1.951-4.686-3.941-5.552-1.95-.866-3.94-1.26-3.94-2.677 0-.945.789-1.654 1.686-1.654 1.189 0 2.057.657 2.79 1.546l2.34-3.189zM29.576 3.268C29.575 1.496 28.13 0 26.336 0c-1.794 0-3.239 1.496-3.239 3.268 0 1.811 1.445 3.268 3.24 3.268 1.794 0 3.238-1.457 3.238-3.268zm-8.635 6.77L12.16 29.765H8.78L0 10.039h6.008l4.462 10.844 4.462-10.844h6.008z"
                        />
                    </svg>
                </div>
            </div>
        ) : (
            // TODO(dan) Old logo must remain in markup or mobile social icons render wrong. Why?  https://cl.ly/0c1f528f201d
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="125"
                height="35"
                viewBox="0 0 125 35"
            >
                <defs>
                    <path id="a" d="M124 17V0H0v34h124z" />
                </defs>
                <g
                    fill="none"
                    fillRule="evenodd"
                    transform="translate(.424 .565)"
                >
                    <mask id="b" fill="#fff">
                        <use xlinkHref="#a" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M109.027 19.453c.345-2.526 2.02-4.417 4.344-4.417 2.864 0 3.954 1.935 4.3 4.417h-8.644zm14.908 3.883v-.759c0-6.604-3.773-11.914-10.92-11.914-7.056 0-10.734 5.22-10.734 11.87C102.28 29.18 106.58 34 113.327 34c4.571 0 9.307-2.562 10.673-7.49h-6.376c-.843 1.741-2.266 2.716-4.191 2.716-3.24 0-4.583-3.124-4.583-5.89h15.085zm-31.657 9.95h6.214V0h-6.214v33.286zM82.158 22.31c0 3.091-1.707 6.158-5.46 6.158-3.504 0-5.68-3.067-5.68-6.158 0-3.092 2.176-6.113 5.68-6.113 3.753 0 5.46 3.021 5.46 6.113zM65.334 33.286h6.214V31.14h.089C72.878 32.924 75.76 34 77.98 34c6.686 0 10.482-5.8 10.482-11.78 0-5.756-4.21-11.557-10.63-11.557-2.307 0-4.506.979-6.285 2.844V0h-6.214v33.286zm-5.06-29.582C60.273 1.696 58.631 0 56.589 0c-2.042 0-3.684 1.696-3.684 3.704a3.685 3.685 0 0 0 3.684 3.704 3.685 3.685 0 0 0 3.684-3.704zm-10.09 9.47c-1.65-1.523-4.221-2.51-6.779-2.51-4.083 0-6.924 2.989-6.924 7.094 0 3.525 2.175 4.819 4.35 5.756 2.175.937 4.35 1.383 4.35 3.302 0 1.428-1.11 2.365-2.486 2.365-1.82 0-3.496-1.115-4.617-2.544l-2.922 3.834c1.909 2.32 4.831 3.529 7.76 3.529 4.573 0 8.479-2.9 8.479-7.764 0-3.793-2.22-5.31-4.484-6.291-2.219-.982-4.483-1.429-4.483-3.035 0-1.071.899-1.874 1.92-1.874 1.352 0 2.34.745 3.173 1.752l2.663-3.614zm-16.539-9.47C33.644 1.696 32.002 0 29.96 0c-2.041 0-3.684 1.696-3.684 3.704a3.686 3.686 0 0 0 3.684 3.704 3.685 3.685 0 0 0 3.684-3.704zm-9.822 7.673l-9.987 22.355H9.987L0 11.377h6.835l5.076 12.29 5.075-12.29h6.836z"
                        mask="url(#b)"
                    />
                </g>
            </svg>
        );
    }
}

export { Logo };
