// tslint:disable:max-line-length
import * as React from 'react';

interface Props extends React.HTMLProps<SVGElement> {
    readonly invert?: boolean;
}

const T = ({ invert, ...other }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="115"
        height="115"
        viewBox="0 0 115 115"
        // {...other}
    >
        <g fill={invert ? '#ffffff' : '#1800FF'} fillRule="evenodd">
            <path d="M108 39H90.562v18.097H5V74.903h85.562V93H108zM25.87 10.444c0 5.767-4.673 10.444-10.436 10.444C9.672 20.888 5 16.211 5 10.444 5 4.677 9.672 0 15.434 0c5.763 0 10.437 4.677 10.437 10.444M109.87 10.444c0 5.767-4.673 10.444-10.436 10.444C93.672 20.888 89 16.211 89 10.444 89 4.677 93.672 0 99.434 0c5.763 0 10.437 4.677 10.437 10.444" />
        </g>
    </svg>
);

export { T };
