import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Block } from 'components/common/block';
import { BodyCopy } from 'components/common/typography';
import {
    ButtonContainer as Button,
    InputContainer as Input,
    ModalWrapperContainer as ModalWrapper,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';

enum ResubmissionType {
    ACCOUNT_NUMBER,
    PHONE_NUMBER,
    PIN,
}

interface OwnProps {
    readonly title: string;
    readonly message: string;
    readonly label: string;
    readonly type: ResubmissionType;
}
interface MappedProps {
    readonly error: string;
    readonly fetchStatus: FetchStatus;

    resubmitAccountNumber(number: string): void;
    resubmitPhoneNumber(number: string): void;
    resubmitPIN(pin: string): void;
}

type Props = OwnProps & MappedProps;

interface State {
    readonly value: string;
}

const styles = require('./base-resubmission-form.less');

@CSSModules(styles, { allowMultiple: true })
class BaseResubmissionForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { value: '' };
    }

    render(): JSX.Element {
        const { error, fetchStatus, title, message, label } = this.props;
        const { value } = this.state;
        return (
            <ModalWrapper closable title={title}>
                <form onSubmit={this.handleSubmit}>
                    <BodyCopy thin>{message}</BodyCopy>
                    <Input
                        noMargin
                        id="number"
                        label={label}
                        onChange={this.onChange}
                        value={value}
                        error={error}
                    />

                    <Block width={3}>
                        <Button
                            loading={fetchStatus === FetchStatus.FETCHING}
                            type="submit"
                            label="Next"
                            fullWidth
                        />
                    </Block>
                </form>
            </ModalWrapper>
        );
    }

    private readonly handleSubmit = (
        event: React.FormEvent<HTMLFormElement>,
    ): void => {
        event.preventDefault();
        const {
            type,
            resubmitAccountNumber,
            resubmitPhoneNumber,
            resubmitPIN,
        } = this.props;
        const { value } = this.state;
        switch (type) {
            case ResubmissionType.ACCOUNT_NUMBER:
                resubmitAccountNumber(value);
                return;
            case ResubmissionType.PHONE_NUMBER:
                resubmitPhoneNumber(value);
                return;
            case ResubmissionType.PIN:
                resubmitPIN(value);
                return;
        }
    };

    private readonly onChange = (
        event: React.FormEvent<HTMLInputElement>,
    ): void => {
        const { value } = event.target as HTMLInputElement;
        this.setState({ value });
    };
}

export { BaseResubmissionForm, ResubmissionType };
