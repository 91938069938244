import { withAnalytics } from 'components/common/analytics';
import { SwapAEM } from 'components/swap-aem';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IApplicationState } from 'models';

const mapStateToProps = ({
    device
}: IApplicationState) => ({
    compatibility: device.compatibility.compatibility
});

const enhance = compose(
    withAnalytics,
    connect(mapStateToProps, null),
);

const SwapAemContainer = enhance(SwapAEM);

export { SwapAemContainer };
