import { PayNow } from 'components/account/pay-now';
import { withAnalytics } from 'components/common/analytics';
import { withDeviceType } from 'components/common/device-type';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { LapsedPaymentActions } from 'state/payment/payment.actions';

const { submitPayNow } = LapsedPaymentActions;

const mapStateToProps = ({ account, bill, payment }: IApplicationState) => ({
    chargeAmount: bill.currentBill.chargeAmount,
    payNowError: payment.lapsedPayment.error,
    payNowSubmissionStatus: payment.lapsedPayment.fetchStatus,
});

const mapDispatchToProps = dispatch => ({
    submitPayNow: (nonce: string, amount: number) =>
        dispatch(submitPayNow({ nonce, amount })),
});

const enhance = compose(
    withAnalytics,
    withDeviceType,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);

const PayNowContainer = enhance(PayNow);

export { PayNowContainer };
