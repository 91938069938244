import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { eventTealiumLink } from 'state/tealium';
import { smellyStoreSingleton } from 'utils/store';
import Props from './social-props';

const styles = require('./social.less');

@CSSModules(styles, { allowMultiple: true })
class Instagram extends React.Component<Props> {
    render() {
        const {
            props: { black },
        } = this;
        return (
            <a
                target="_blank"
                href={__('social.instagram_href')}
                rel="noopener"
                styleName={classNames('social', { black })}
                onClick={this.tealiumEventInstagram}
            >
                <img src="/assets/images/icons/IG.svg" alt="Instagram" />
            </a>
        );
    }
    private readonly tealiumEventInstagram = () => {
        const { dispatch } = smellyStoreSingleton.store;
        const page = window.location.pathname.replace('/', '');
        dispatch(
            eventTealiumLink({
                flow_name: '',
                page_name: page,
                tealium_event: 'instagram',
                link_location: 'footer',
                link_type: 'icon',
            }),
        );
    };
}

export { Instagram };
