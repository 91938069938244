import { TealiumFlowName, TealiumPageType } from 'models/tealium';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';

const styles = require('./referral-terms.less');

@CSSModules(styles)
class ReferralTerms extends React.Component<{}> {
    constructor(props: {}) {
        super(props);
    }
    componentDidMount() {
        TealiumDispatcher.dispatchView(
            TealiumFlowName.NONE,
            TealiumPageType.REFERRALTERMS,
        );
    }
    render() {
        return (
            <div>
                <iframe
                    src={__('routes.referralterms.aem')}
                    styleName="iframe"
                />
            </div>
        );
    }
}

export { ReferralTerms };
