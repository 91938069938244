import { D, Squiggle } from 'components/common/graphics/emoji';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Headline } from 'components/common/typography';
import { LoadingIndicator } from 'components/loading-indicator';
import { FetchStatus } from 'lib/br-redux';

interface Props {
    readonly status: FetchStatus;
}

const UserValidator: React.SFC<Props> = ({ status }) => {
    switch (status) {
        case FetchStatus.SUCCESS:
            return <ValidationSuccess />;
        case FetchStatus.ERROR:
            return <ValidationError />;
        default:
            return <Validating />;
    }
};

const Container: React.SFC = CSSModules(require('./user-validator.less'))(
    ({ children }) => <div styleName="validator-container">{children}</div>,
);

const Validating = () => (
    <Container>
        <LoadingIndicator />
        <Headline>{__('user-validator.validating.headline')}</Headline>
    </Container>
);

const ValidationSuccess = () => (
    <Container>
        <D />
        <Headline>{__('user-validator.validationSuccess.headline')}</Headline>
    </Container>
);

const ValidationError = () => (
    <Container>
        <Squiggle />
        <Headline>{__('user-validator.validationError.headline')}</Headline>
    </Container>
);

export default UserValidator;
