import { createAction, IAction } from 'lib/br-redux';
import {
    ITealiumError,
    ITealiumEventLink,
    ITealiumEventView,
    ITealiumLink,
} from 'models/tealium';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    EVENT_ERROR = 'ANALYTICS/EVENT_ERROR',
    EVENT_LINK = 'ANALYTICS/EVENT_LINK',
    EVENT_PAGE_VIEW = 'ANALYTICS/EVENT_PAGE_VIEW',
    EVENT_PAGE_VIRTUAL = 'ANALYTICS/EVENT_PAGE_VIRTUAL',
    PUSH_EVENT_QUEUE = 'ANALYTICS/PUSH_EVENT_QUEUE',
    POP_EVENT_QUEUE = 'ANALYTICS/POP_EVENT_QUEUE',
    EVENT_TEALIUM_LINK = 'ANALYTICS/EVENT_TEALIUM_LINK',
    EVENT_TEALIUM_VIEW = 'ANALYTICS/EVENT_TEALIUM_VIEW',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type EventPayloadError = ITealiumError;
type EventPayloadLink = ITealiumLink;
type EventPayloadTealiumLink = ITealiumEventLink;
type EventPayloadTealiumView = ITealiumEventView;

interface VirtualPageViewPayload {
    readonly page_name: string;
}

interface EventPayload {
    readonly type: ActionType;
    readonly data: object;
}

type IEventErrorAction = IAction<ActionType.EVENT_ERROR, EventPayloadError>;

type IEventLinkAction = IAction<ActionType.EVENT_LINK, EventPayloadLink>;
type IEventTealiumLinkAction = IAction<
    ActionType.EVENT_TEALIUM_LINK,
    EventPayloadTealiumLink
>;
type IEventTealiumViewAction = IAction<
    ActionType.EVENT_TEALIUM_VIEW,
    EventPayloadTealiumView
>;

type IEventPageVirtualAction = IAction<
    ActionType.EVENT_PAGE_VIRTUAL,
    VirtualPageViewPayload
>;

type IEventPageViewAction = IAction<ActionType.EVENT_PAGE_VIEW>;

type IPushEventQueueAction = IAction<ActionType.PUSH_EVENT_QUEUE, EventPayload>;
type IPopEventQueueAction = IAction<ActionType.POP_EVENT_QUEUE, EventPayload>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IAnalyticsAction =
    | IEventErrorAction
    | IEventLinkAction
    | IEventPageViewAction
    | IEventPageVirtualAction
    | IPushEventQueueAction
    | IPopEventQueueAction
    | IEventTealiumLinkAction
    | IEventTealiumViewAction;

// ------------------------------------
// Action Creators
// i.e. the stuff that actually makes it to the compiled Javascript
// ------------------------------------

const eventError = createAction(
    ActionType.EVENT_ERROR,
    (payload: EventPayloadError) => payload,
);
const eventLink = createAction(
    ActionType.EVENT_LINK,
    (payload: ITealiumLink) => payload,
);
const eventTealiumLink = createAction(
    ActionType.EVENT_TEALIUM_LINK,
    (payload: ITealiumEventLink) => payload,
);
const eventTealiumView = createAction(
    ActionType.EVENT_TEALIUM_VIEW,
    (payload: ITealiumEventView) => payload,
);
const eventPageView = createAction(ActionType.EVENT_PAGE_VIEW);
const eventPageVirtual = createAction(
    ActionType.EVENT_PAGE_VIRTUAL,
    // tslint:disable-next-line:variable-name
    (page_name: string) => ({ page_name }),
);

const pushEventQueue = createAction(
    ActionType.PUSH_EVENT_QUEUE,
    (event: EventPayload) => event,
);
const popEventQueue = createAction(
    ActionType.POP_EVENT_QUEUE,
    (event: EventPayload) => event,
);

export {
    ActionType,
    IEventErrorAction,
    IEventLinkAction,
    IEventTealiumLinkAction,
    IEventTealiumViewAction,
    IEventPageViewAction,
    IEventPageVirtualAction,
    IAnalyticsAction,
    eventError,
    eventLink,
    eventTealiumLink,
    eventTealiumView,
    eventPageView,
    eventPageVirtual,
    VirtualPageViewPayload,
    EventPayloadError,
    EventPayloadLink,
    EventPayloadTealiumLink,
    EventPayloadTealiumView,
    pushEventQueue,
    popEventQueue,
};
