import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { ActionType, PauseAction } from '.';

interface PauseState {
    readonly fetchStatus: FetchStatus;
    readonly error: string;
}

const DEFAULT_STATE: PauseState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
};

const { PAUSE_SERVICE, REPORT_PAUSE_ERROR, REPORT_PAUSE_SUCCESS } = ActionType;

const pauseReducer = (
    state: PauseState = DEFAULT_STATE,
    action: PauseAction | IDefaultAction,
) => {
    switch (action.type) {
        case PAUSE_SERVICE:
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        case REPORT_PAUSE_SUCCESS:
            return {
                ...state,
                fetchStatus: FetchStatus.SUCCESS,
            };
        case REPORT_PAUSE_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        default:
            return state;
    }
};

export { pauseReducer, DEFAULT_STATE, PauseState };
