import * as React from 'react';

import { set } from 'lodash';
import { IInputValidationState } from 'models';
import { getComponentDisplayName } from 'utils';

const withInputValidation = (
    dataKeys: ReadonlyArray<string>,
) => WrappedComponent => {
    class WithInputValidation extends React.Component<
        any,
        IInputValidationState
    > {
        constructor(props) {
            super(props);
            this.state = {
                data: dataKeys.reduce((acc, key) => set(acc, key, ''), {}),
                errors: dataKeys.reduce((acc, key) => set(acc, key, false), {}),
            };
        }

        static get displayName() {
            return `withInputValidation(${getComponentDisplayName(
                WrappedComponent,
            )})`;
        }

        render() {
            const {
                handleOnChange,
                handleOnValidate,
                props,
                state: { data, errors },
            } = this;
            return (
                <WrappedComponent
                    data={{ ...data }}
                    errors={{ ...errors }}
                    handleOnChange={handleOnChange}
                    handleOnValidate={handleOnValidate}
                    {...props}
                />
            );
        }

        private readonly handleOnChange = ({
            target,
            setErrors = true,
        }): void => {
            const { name, value } = target;
            const newState = { ...this.state };
            set(newState.data, name, value);

            // immediately error after a change until validation is done
            // validation is done after the user stops typing
            if (setErrors) {
                set(newState.errors, name, true);
            }

            this.setState(newState);
        };

        private readonly handleOnValidate = (
            id: string,
            valid: boolean,
        ): void => {
            const newState = { ...this.state };
            set(newState.errors, id, !valid);
            this.setState(newState);
        };
    }
    return WithInputValidation;
};

export { withInputValidation };
