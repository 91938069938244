enum ProductType {
    HANDSET = 'HANDSET',
    PLAN = 'PLAN',
    SIM = 'SIM',
    PROMOTIONS = 'PROMOTIONS',
}

interface IPortInfo {
    readonly portinAccountNumber: string;
    readonly portinMdn: string;
    readonly portinOperator: string;
    readonly portinPin: string;
    readonly portinSpid: string;
    readonly portinZip: string;
    readonly portEligibilityInd: string;
    readonly numberLoc: string;
}
interface ICartItemPayload {
    readonly itemid: string;
    readonly productType: ProductType;
    readonly quantity: number;
    readonly portin?: IPortInfo;
    readonly byodIMEI?: {
        readonly imei?: string;
        readonly os?: string;
        readonly model?: string;
        readonly carrier?: string;
        readonly compatibilityCheckDone?: boolean;
    };
    readonly newMdn?: string;
}

interface IProductItemModel {
    readonly itemid: string;
    readonly quantity: number;
    readonly name: string;
    readonly product_type: ProductType;
    readonly description: string;
    readonly price: 0;
    readonly shipping_method: string;
}
interface IShopPhonesModel {
    readonly phones: ReadonlyArray<IPhoneItemModel>;
}

interface IPhoneItemModel {
    readonly Id: string;
    readonly Name: string;
    readonly 'Device Model Type'?: string;
    readonly Code: string;
    readonly Type: string;
    readonly Make: string;
    readonly ContentURL: string;
    readonly Family: string;
    readonly Price: 0;
    readonly 'Device OS'?: string;
}

class ProductCollection {
    constructor(public items: ReadonlyArray<IProductItemModel> = []) {}

    get handset(): IProductItemModel | undefined {
        return this.findItem(ProductType.HANDSET);
    }

    get plan(): IProductItemModel | undefined {
        return this.findItem(ProductType.PLAN);
    }

    get sim(): IProductItemModel | undefined {
        return this.findItem(ProductType.SIM);
    }

    private findItem(desiredType: ProductType): IProductItemModel | undefined {
        return this.items.find(i => i.product_type === desiredType);
    }
}

export {
    IProductItemModel,
    ICartItemPayload,
    ProductType,
    ProductCollection,
    IPhoneItemModel,
    IShopPhonesModel,
};
