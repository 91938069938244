import { IApplicationState, IProductItemModel } from 'models';
import {
    all,
    call,
    fork,
    put,
    select,
    take,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';

import { AlertModalContainer as AlertModal } from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { IShopPhonesModel, ProductType } from 'models/products/products.model';
import {
    LOCATION_CHANGE,
    LocationChangeAction,
    push,
} from 'react-router-redux';
import { ProductsService } from 'services';
import { ConfigurationActions } from 'state/configuration';
import {
    ActionType,
    fetchPhonesError,
    fetchPhonesSuccess,
    fetchProductsError,
    fetchProductsSuccess,
} from 'state/products';
import { fetchProducts as createFetchProductsAction } from 'state/products/products.actions';
import { eventError } from 'state/tealium';
import { confirmWithModal } from 'state/view/view.saga';
import { smellyStoreSingleton } from 'utils/store';

function* fetchProducts() {
    try {
        yield take(ConfigurationActions.ActionType.REPORT_SUCCESS);
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const results: ReadonlyArray<IProductItemModel> = yield call(
            ProductsService.fetchProducts,
            basePaths,
        );

        if (
            !results ||
            results.findIndex(p => p.product_type === ProductType.HANDSET) <
                0 ||
            results.findIndex(p => p.product_type === ProductType.PLAN) < 0 ||
            results.findIndex(p => p.product_type === ProductType.SIM) < 0
        ) {
            throw new Error(__('default-error-message'));
        }

        yield put(fetchProductsSuccess(results));
    } catch (error) {
        yield put(fetchProductsError(error.message));
    }
}

function* fetchPhones() {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const phones: ReadonlyArray<IShopPhonesModel> = yield call(
            ProductsService.fetchPhones,
            basePaths,
        );
        yield put(fetchPhonesSuccess(phones));
        return phones;
    } catch (error) {
        yield put(fetchPhonesError(error.message));
    }
}

// ================================================================
// If a user views the sign up page after products failed to fetch,
// pop a modal and prevent sign up
// ================================================================

function* onProductFetchError() {
    // get current path
    const location: Location | undefined = yield select(
        ({ routing }: IApplicationState) => routing.location,
    );
    yield put(eventError({ error_msg: 'Product fetch error.' }));
    if (location && location.pathname === __('routes.sign-up')) {
        yield fork(showProductFetchError);
    }
}

function* showErrorIfEnteringSignUpAfterProductError(
    action: LocationChangeAction,
) {
    const fetchStatus = yield select(
        ({ products }: IApplicationState) => products.fetchStatus,
    );
    if (
        fetchStatus === FetchStatus.ERROR &&
        action.payload.pathname === __('routes.sign-up')
    ) {
        yield fork(showProductFetchError);
    }
}

function* showProductFetchError() {
    const { dispatch } = smellyStoreSingleton.store;
    yield call(confirmWithModal, {
        additionalProps: {
            body: __('default-error-message'),
            onConfirm: () => {
                dispatch(push('/')); // send to homepage
                dispatch(createFetchProductsAction()); // try fetching products again
            },
            title: __('oops'),
        },
        component: AlertModal,
    });
}

function* productsSaga() {
    yield all([
        takeEvery(ActionType.FETCH_PRODUCTS, fetchProducts),
        takeLatest(ActionType.FETCH_PHONES, fetchPhones),
        takeEvery(ActionType.REPORT_FETCH_PHONES_ERROR, onProductFetchError),
        takeEvery(ActionType.REPORT_FETCH_PRODUCTS_ERROR, onProductFetchError),
        takeEvery(LOCATION_CHANGE, showErrorIfEnteringSignUpAfterProductError),
    ]);
}

export { productsSaga };
