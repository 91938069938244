import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Divider } from 'components/common/divider';
import { Facebook, Instagram, Logo, Twitter } from 'components/common/graphics';
import { Hidden } from 'components/common/hidden';
import { ButtonContainer as Button, InputContainer as Input } from 'containers';
import {
    IAccount,
    IAnalyticsProps,
    IDeviceTypeProps,
    IInputValidationProps,
} from 'models';
import {
    TealiumFlowName,
    TealiumLinkEvents,
    TealiumLoginState,
    TealiumPageLinkLocations,
    TealiumPageType,
    TealiumPagevents,
} from 'models/tealium';
import { Link } from 'react-router-dom';
import { InputValidator } from 'utils';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';

const styles = require('./footer.less');

interface Props
    extends IAnalyticsProps,
        IInputValidationProps,
        IDeviceTypeProps {
    readonly isSignedIn: boolean;
    readonly account: IAccount;
    showLeadCaptureModal(email: string): void;
    showStayInLoopLanding(email: string): void;
    redirectTo(url: string): void;
    onAnyClick(): void;
}

interface IState {
    readonly emailFooter: string;
}

const DEFAUT_STATE: IState = {
    emailFooter: '',
};

@CSSModules(styles, { allowMultiple: true })
class Footer extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const {
            hasErrors,
            props: {
                handleOnValidate,
                handleOnChange,
                isSignedIn,
                data: { emailFooter },
            },
            showLeadCaptureModal,
        } = this;

        this.state = DEFAUT_STATE;

        return (
            <footer styleName="footer">
                <Divider medium />
                <div styleName="footer-wrapper top">
                    <div styleName="footer-container top">
                        <div styleName="left-content">
                            <p styleName="subscribe-text">
                                {__('footer.subscribe-text')}
                            </p>
                            <Hidden until={!isSignedIn}>
                                <form
                                    styleName="email-form"
                                    onSubmit={showLeadCaptureModal}
                                >
                                    <div styleName="email-form-input footer-input">
                                        <Input
                                            id="emailFooter"
                                            styleName="email-input"
                                            inline
                                            noMargin
                                            onValidate={handleOnValidate}
                                            onChange={handleOnChange}
                                            value={emailFooter}
                                            validate={
                                                InputValidator.validateEmail
                                            }
                                            label=""
                                            placeholder={__(
                                                'home.prospective.lead-input-field-placeholder',
                                            )}
                                            onFocus={e => {
                                                e.stopPropagation();
                                                const page = window.location.pathname.replace(
                                                    '/',
                                                    '',
                                                );
                                                TealiumDispatcher.dispatchEvent(
                                                    TealiumFlowName.NONE,
                                                    page,
                                                    TealiumPagevents.SIGNIN_EMAIL,
                                                    TealiumPageLinkLocations.FOOTER,
                                                    TealiumLinkEvents.FORM_FIELD,
                                                    this.props.isSignedIn
                                                        ? TealiumLoginState.LOGGED_IN
                                                        : TealiumLoginState.LOGGED_OUT,
                                                    this.props.isSignedIn
                                                        ? this.props.account
                                                              .accountid
                                                        : '',
                                                );
                                            }}
                                        />
                                    </div>
                                    <div styleName="email-form-button">
                                        <Button
                                            smallMobile
                                            type="submit"
                                            styleName="button-width submit-btn"
                                            disabled={hasErrors}
                                            onClick={e => {
                                                e.stopPropagation();
                                                const page = window.location.pathname.replace(
                                                    '/',
                                                    '',
                                                );
                                                TealiumDispatcher.dispatchEvent(
                                                    TealiumFlowName.NONE,
                                                    page,
                                                    TealiumPagevents.FOOTER_EMAIL_SUBMIT,
                                                    TealiumPageLinkLocations.FOOTER,
                                                    TealiumLinkEvents.BUTTON,
                                                    this.props.isSignedIn
                                                        ? TealiumLoginState.LOGGED_IN
                                                        : TealiumLoginState.LOGGED_OUT,
                                                    this.props.isSignedIn
                                                        ? this.props.account
                                                              .accountid
                                                        : '',
                                                );
                                            }}
                                            label={__(
                                                'home.prospective.email-submit',
                                            )}
                                        />
                                    </div>
                                </form>
                            </Hidden>
                            <div styleName="logo-wrapper showInDesktop">
                                <Link
                                    onClick={e => {
                                        e.stopPropagation();
                                        this.tealiumEventLogo;
                                    }}
                                    to={__('routes.home')}
                                >
                                    <span styleName="logo">
                                        <Logo />
                                    </span>
                                </Link>
                            </div>
                            <p styleName="copy-right-text showInDesktop line-height-20">
                                <span>{__('footer.copyright')}</span> |{' '}
                                <span>{__('footer.reserved')}</span>
                            </p>
                            <div styleName="termsOfUse showInDesktop line-height-24">
                                <p styleName="terms-content margin-0">
                                    <a
                                        href={__('routes.terms-and-conditions')}
                                        styleName="link-text"
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_TERMS,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                            );
                                        }}
                                    >
                                        {__('footer.terms-and-conditions')}
                                    </a>
                                </p>
                                <p styleName="terms-content margin-0">
                                    <a
                                        href={__('routes.terms-of-use')}
                                        styleName="link-text"
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_TERMS,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                            );
                                        }}
                                    >
                                        {__('footer.terms-of-use')}
                                    </a>
                                </p>
                                <p styleName="terms-content margin-0">
                                    <a
                                        href={__('routes.legal-disclosures')}
                                        styleName="link-text"
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_TERMS,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                            );
                                        }}
                                    >
                                        {__('footer.legal-disclosures')}
                                    </a>
                                </p>
                                <p styleName="terms-content margin-0">
                                    <a
                                        href={__('routes.supplemental-terms')}
                                        styleName="link-text"
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_TERMS,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                            );
                                        }}
                                    >
                                        {__('footer.supplemental-terms')}
                                    </a>
                                </p>
                                <p styleName="terms-content margin-0">
                                    <a
                                        href={__('routes.privacy')}
                                        styleName="link-text"
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_PRIVACY,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                                this.props.isSignedIn
                                                    ? TealiumLoginState.LOGGED_IN
                                                    : TealiumLoginState.LOGGED_OUT,
                                                this.props.isSignedIn
                                                    ? this.props.account
                                                          .accountid
                                                    : '',
                                            );
                                        }}
                                    >
                                        {__('footer.privacy')}
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div styleName="link-container">
                            <div styleName="link-column-1">
                                <p styleName="column-header">
                                    {__('footer.apps')}
                                </p>
                                <div styleName="footer-link">
                                    <a
                                        href={__('routes.download-iphone-app')}
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_IOS_DOWNLOAD,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                                this.props.isSignedIn
                                                    ? TealiumLoginState.LOGGED_IN
                                                    : TealiumLoginState.LOGGED_OUT,
                                                this.props.isSignedIn
                                                    ? this.props.account
                                                          .accountid
                                                    : '',
                                            );
                                        }}
                                    >
                                        {__('footer.iphone')}
                                    </a>
                                </div>
                                <div styleName="footer-link">
                                    <a
                                        href={__('routes.download-android-app')}
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_ANDROID_DOWNLOAD,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                                this.props.isSignedIn
                                                    ? TealiumLoginState.LOGGED_IN
                                                    : TealiumLoginState.LOGGED_OUT,
                                                this.props.isSignedIn
                                                    ? this.props.account
                                                          .accountid
                                                    : '',
                                            );
                                        }}
                                    >
                                        {__('footer.android')}
                                    </a>
                                </div>
                            </div>
                            <div styleName="link-column-2">
                                <p styleName="column-header">
                                    {__('footer.shop')}
                                </p>
                                <div styleName="footer-link">
                                    <Link
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_APPLE,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                            );
                                        }}
                                        to={__('routes.shop.apple')}
                                    >
                                        {__('footer.shop.apple')}
                                    </Link>
                                </div>
                                <div styleName="footer-link">
                                    <Link
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_GOOGLE,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                            );
                                        }}
                                        to={__('routes.shop.google')}
                                    >
                                        {__('footer.shop.google')}
                                    </Link>
                                </div>
                            </div>
                            <div styleName="link-column-3">
                                <p styleName="column-header">
                                    {__('footer.services')}
                                </p>
                                <div styleName="footer-link">
                                    <Link
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_PROTECT,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                                this.props.isSignedIn
                                                    ? TealiumLoginState.LOGGED_IN
                                                    : TealiumLoginState.LOGGED_OUT,
                                                this.props.isSignedIn
                                                    ? this.props.account
                                                          .accountid
                                                    : '',
                                            );
                                        }}
                                        to={__('routes.visible-protect')}
                                    >
                                        {__('footer.protect')}
                                    </Link>
                                </div>
                                <div styleName="footer-link">
                                    <Link
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_AFFIRM,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                                this.props.isSignedIn
                                                    ? TealiumLoginState.LOGGED_IN
                                                    : TealiumLoginState.LOGGED_OUT,
                                                this.props.isSignedIn
                                                    ? this.props.account
                                                          .accountid
                                                    : '',
                                            );
                                        }}
                                        to={__('routes.affirm')}
                                    >
                                        {__('footer.affirm')}
                                    </Link>
                                </div>
                                {/* <div styleName="footer-link">
                                    <Link
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_PARTY_PAY,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                            );
                                        }}
                                        to={__('routes.account.party')}
                                    >
                                        {__('footer.party.pay')}
                                    </Link>
                                </div> */}
                                <div styleName="footer-link">
                                    <Link
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_SWAP,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                            );
                                        }}
                                        to={__('routes.swap')}
                                    >
                                        {__('footer.swap')}
                                    </Link>
                                </div>
                                <div styleName="footer-link">
                                    <Link
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_COVERAGE,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                            );
                                        }}
                                        to={__('routes.coverage')}
                                    >
                                        {__('footer.coverage')}
                                    </Link>
                                </div>
                                <div styleName="footer-link">
                                    <a
                                        href={__('routes.returns-policy')}
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_RETURN,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                                this.props.isSignedIn
                                                    ? TealiumLoginState.LOGGED_IN
                                                    : TealiumLoginState.LOGGED_OUT,
                                                this.props.isSignedIn
                                                    ? this.props.account
                                                          .accountid
                                                    : '',
                                            );
                                        }}
                                    >
                                        {__('footer.returns')}
                                    </a>
                                </div>
                            </div>
                            <div styleName="link-column-4">
                                <p styleName="column-header">
                                    {__('footer.company')}
                                </p>
                                <div styleName="footer-link">
                                    <Link
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_CONNECT,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                                this.props.isSignedIn
                                                    ? TealiumLoginState.LOGGED_IN
                                                    : TealiumLoginState.LOGGED_OUT,
                                                this.props.isSignedIn
                                                    ? this.props.account
                                                          .accountid
                                                    : '',
                                            );
                                        }}
                                        to={__('routes.connect')}
                                    >
                                        {__('footer.connect')}
                                    </Link>
                                </div>
                                <div styleName="footer-link">
                                    <a
                                        href={__('routes.press')}
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_PRESS,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                                this.props.isSignedIn
                                                    ? TealiumLoginState.LOGGED_IN
                                                    : TealiumLoginState.LOGGED_OUT,
                                                this.props.isSignedIn
                                                    ? this.props.account
                                                          .accountid
                                                    : '',
                                            );
                                        }}
                                    >
                                        {__('footer.press')}
                                    </a>
                                </div>
                                <div styleName="footer-link">
                                    <a
                                        href={__('routes.careers')}
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_CAREERS,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                            );
                                        }}
                                    >
                                        {__('footer.careers')}
                                    </a>
                                </div>
                                <Hidden until={isSignedIn}>
                                    <div styleName="footer-link">
                                        <Link
                                            onClick={e => {
                                                e.stopPropagation();
                                                const page = window.location.pathname.replace(
                                                    '/',
                                                    '',
                                                );
                                                TealiumDispatcher.dispatchEvent(
                                                    TealiumFlowName.NONE,
                                                    page,
                                                    TealiumPagevents.FOOTER_FEEDBACK,
                                                    TealiumPageLinkLocations.FOOTER,
                                                    TealiumLinkEvents.TEXT_LINK,
                                                    this.props.isSignedIn
                                                        ? TealiumLoginState.LOGGED_IN
                                                        : TealiumLoginState.LOGGED_OUT,
                                                    this.props.isSignedIn
                                                        ? this.props.account
                                                              .accountid
                                                        : '',
                                                );
                                            }}
                                            to={__('routes.feedback')}
                                        >
                                            {__('footer.feedback')}
                                        </Link>
                                    </div>
                                </Hidden>
                                <div styleName="social-container showInDesktop">
                                    <div styleName="social-icon">
                                        <Facebook />
                                    </div>
                                    <div styleName="social-icon">
                                        <Instagram />
                                    </div>
                                    <div styleName="social-icon">
                                        <Twitter />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div styleName="showInMobile dispBlock">
                            <div styleName="social-container">
                                <div styleName="social-icon">
                                    <Facebook />
                                </div>
                                <div styleName="social-icon">
                                    <Instagram />
                                </div>
                                <div styleName="social-icon">
                                    <Twitter />
                                </div>
                            </div>
                            <div styleName="logo-wrapper">
                                <Link
                                    onClick={e => {
                                        e.stopPropagation();
                                        this.tealiumEventLogo;
                                    }}
                                    to={__('routes.home')}
                                >
                                    <span styleName="logo">
                                        <Logo />
                                    </span>
                                </Link>
                            </div>
                            <div styleName="copy-right-text">
                                <span styleName="inline-block line-height-25">
                                    {__('footer.copyright')}
                                </span>
                                <span styleName="inline-block line-height-25">
                                    {__('footer.reserved')}
                                </span>
                            </div>
                            <div styleName="termsOfUse">
                                <p styleName="terms-content">
                                    <a
                                        href={__('routes.terms-and-conditions')}
                                        styleName="link-text"
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_TERMS,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                            );
                                        }}
                                    >
                                        {__('footer.terms-and-conditions')}
                                    </a>
                                </p>
                                <p styleName="terms-content">
                                    <a
                                        href={__('routes.terms-of-use')}
                                        styleName="link-text"
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_TERMS,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                            );
                                        }}
                                    >
                                        {__('footer.terms-of-use')}
                                    </a>
                                </p>
                                <p styleName="terms-content">
                                    <a
                                        href={__('routes.legal-disclosures')}
                                        styleName="link-text"
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_TERMS,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                            );
                                        }}
                                    >
                                        {__('footer.legal-disclosures')}
                                    </a>
                                </p>
                                <p styleName="terms-content">
                                    <a
                                        href={__('routes.supplemental-terms')}
                                        styleName="link-text"
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_TERMS,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                            );
                                        }}
                                    >
                                        {__('footer.supplemental-terms')}
                                    </a>
                                </p>
                                <p styleName="terms-content dispBlock">
                                    <a
                                        href={__('routes.privacy')}
                                        styleName="link-text"
                                        target="_blank"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const page = window.location.pathname.replace(
                                                '/',
                                                '',
                                            );
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                page,
                                                TealiumPagevents.FOOTER_PRIVACY,
                                                TealiumPageLinkLocations.FOOTER,
                                                TealiumLinkEvents.TEXT_LINK,
                                                this.props.isSignedIn
                                                    ? TealiumLoginState.LOGGED_IN
                                                    : TealiumLoginState.LOGGED_OUT,
                                                this.props.isSignedIn
                                                    ? this.props.account
                                                          .accountid
                                                    : '',
                                            );
                                        }}
                                    >
                                        {__('footer.privacy')}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

    private get hasErrors(): boolean {
        return this.props.errors.emailFooter;
    }

    private readonly showLeadCaptureModal = (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        event.preventDefault();
        const {
            data: { emailFooter },
            showStayInLoopLanding,
        } = this.props;
        showStayInLoopLanding(emailFooter);
    };
    private readonly tealiumEventLogo = () => {
        const page = window.location.pathname.replace('/', '');
        TealiumDispatcher.dispatchEvent(
            TealiumFlowName.NONE,
            page,
            TealiumPagevents.FOOTER_LOGO,
            TealiumPageLinkLocations.FOOTER,
            TealiumLinkEvents.ICON,
            this.props.isSignedIn
                ? TealiumLoginState.LOGGED_IN
                : TealiumLoginState.LOGGED_OUT,
            this.props.isSignedIn ? this.props.account.accountid : '',
        );
        TealiumDispatcher.dispatchView(
            TealiumFlowName.NONE,
            TealiumPageType.HOME,
            this.props.isSignedIn
                ? TealiumLoginState.LOGGED_IN
                : TealiumLoginState.LOGGED_OUT,
            this.props.isSignedIn ? this.props.account.accountid : '',
        );
    };
}

export default Footer;
