import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./grid.less');

interface Props {
    readonly children?: any;
}

const GridComponent = ({ children }: Props) => (
    <div styleName="grid">{children}</div>
);
const Grid = CSSModules(GridComponent, styles);
export { Grid };
