import { BraintreeDropIn } from 'components/common/braintree/braintree-drop-in';
import { InitializationStatus } from 'lib/br-redux';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { PaymentActions } from 'state/payment';
import { reportKountDeviceSuccess } from '../state/payment/kount-device/kount-device.actions';

const { getClientToken } = PaymentActions.ClientTokenActions;

const mapStateToProps = (state: IApplicationState, ownProps) => ({
    authInitialized:
        state.authentication.initializationStatus ===
        InitializationStatus.INITIALIZED,
    authStatus: state.authentication.fetchStatus,
    clientToken: state.payment.clientToken.clientToken,
    clientTokenStatus: state.payment.clientToken.fetchStatus,
    error: state.payment.clientToken.error,
    ...ownProps,
});

const mapDispatchToProps = dispatch => ({
    getClientToken: () => dispatch(getClientToken()),
    onReportKountDevice: kountDevice =>
        dispatch(reportKountDeviceSuccess(kountDevice)),
});

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const BraintreeDropInContainer = enhance(BraintreeDropIn);

export { BraintreeDropInContainer };
