import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./content-wrapper.less');

interface ContentWrapperProps {
    readonly children?: any;
    readonly noPadding?: boolean;
    readonly marginBottom?: boolean;
}

const ContentWrapperComponent = ({
    children,
    noPadding,
    marginBottom,
}: ContentWrapperProps) => {
    return (
        <div
            styleName={classNames('content-wrapper', {
                noPadding,
                marginBottom,
            })}
        >
            {children}
        </div>
    );
};

const ContentWrapper = CSSModules(ContentWrapperComponent, styles, {
    allowMultiple: true,
});
export { ContentWrapper, ContentWrapperProps };
