// this is a clone of https://github.com/kovndr/braintree-dropin-react to add dataCollector

import * as React from 'react';

interface Props {
    readonly braintree: any;
    readonly authorizationToken: string;
    readonly handlePaymentMethod: any;
    readonly locale?: string;
    readonly paypal?: object;
    readonly paypalCredit?: object;
    readonly paymentOptionPriority?: any;
    readonly card?: object;
    readonly submitButtonText?: string;
    readonly className?: string;
    readonly renderSubmitButton?: any;
    readonly onCreate?: any;
    readonly onError?: any;
    readonly onDestroyStart?: any;
    readonly onDestroyEnd?: any;
    readonly dataCollector?: object;
}

interface State {
    readonly dropInInstance: any;
    readonly isSubmitButtonDisabled: boolean;
}

class BraintreeDropInReact extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            dropInInstance: null,
            isSubmitButtonDisabled: true,
        };
    }

    componentDidMount() {
        if (!this.props.braintree || this.state.dropInInstance) {
            return;
        }
        this.setup();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.authorizationToken &&
            this.state.dropInInstance &&
            (prevProps.authorizationToken !== this.props.authorizationToken ||
                prevProps.locale !== this.props.locale)
        ) {
            this.tearDown()
                .then(() => {
                    this.setState(
                        {
                            dropInInstance: null,
                            isSubmitButtonDisabled: true,
                        },
                        () => {
                            this.setup();
                        },
                    );
                })
                .catch(err => {
                    if (this.props.onError) {
                        this.props.onError(err);
                    }
                });
        }
    }

    componentWillUnmount() {
        if (!this.state.dropInInstance) {
            return;
        }

        this.tearDown().catch(err => {
            if (this.props.onError) {
                this.props.onError(err);
            }
        });
    }

    setup() {
        this.props.braintree.create(
            {
                authorization: this.props.authorizationToken,
                card: this.props.card,
                container: '.braintree-dropin-react-form',
                dataCollector: this.props.dataCollector,
                locale: this.props.locale,
                paymentOptionPriority: this.props.paymentOptionPriority,
                paypal: this.props.paypal,
                paypalCredit: this.props.paypalCredit,
            },
            (err, dropinInstance) => {
                if (err) {
                    if (this.props.onError) {
                        this.props.onError(err);
                    }
                    return;
                } else {
                    if (this.props.onCreate) {
                        this.props.onCreate(dropinInstance);
                    }
                }

                if (dropinInstance.isPaymentMethodRequestable()) {
                    this.setState({
                        isSubmitButtonDisabled: false,
                    });
                }

                dropinInstance.on('paymentMethodRequestable', event => {
                    this.setState({
                        isSubmitButtonDisabled: false,
                    });
                });

                dropinInstance.on('noPaymentMethodRequestable', () => {
                    this.setState({
                        isSubmitButtonDisabled: true,
                    });
                });

                this.setState({
                    dropInInstance: dropinInstance,
                });
            },
        );
    }

    readonly tearDown = () => {
        if (this.props.onDestroyStart) {
            this.props.onDestroyStart();
        }
        return new Promise((resolve, reject) => {
            this.state.dropInInstance.teardown(err => {
                if (this.props.onDestroyEnd) {
                    this.props.onDestroyEnd(err);
                }
                if (err) {
                    return reject(err);
                } else {
                    return resolve();
                }
            });
        });
    };

    readonly handleSubmit = event => {
        if (this.state.dropInInstance && !this.state.isSubmitButtonDisabled) {
            this.setState({ isSubmitButtonDisabled: true }, () => {
                this.state.dropInInstance.requestPaymentMethod(
                    (err, payload) => {
                        this.setState({
                            isSubmitButtonDisabled: false,
                        });
                        if (err) {
                            if (this.props.onError) {
                                this.props.onError(err);
                            }
                        } else {
                            this.props.handlePaymentMethod(payload);
                        }
                    },
                );
            });
        }
    };

    render() {
        return (
            <div className={this.props.className}>
                <div className="braintree-dropin-react-form" />
                <div className="braintree-dropin-react-submit-btn-wrapper">
                    {this.props.renderSubmitButton({
                        isDisabled: this.state.isSubmitButtonDisabled,
                        onClick: this.handleSubmit,
                        text: this.props.submitButtonText,
                    })}
                </div>
            </div>
        );
    }
}

export { BraintreeDropInReact };
