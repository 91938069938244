import { createAction, IAction } from 'lib/br-redux';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    SHOW_PAY_NOW = 'PAYMENT/LAPSED_PAYMENT/SHOW_PAY_NOW',
    SUBMIT_PAY_NOW = 'PAYMENT/LAPSED_PAYMENT/SUBMIT_PAY_NOW',
    REPORT_PAY_NOW_SUCCESS = 'PAYMENT?LAPSED_PAYMENT/REPORT_PAY_NOW_SUCCESS',
    REPORT_PAY_NOW_ERROR = 'PAYMENT?LAPSED_PAYMENT/REPORT_PAY_NOW_ERROR',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

interface PayNowPayload {
    readonly amount: number;
    readonly nonce: string;
}

type IShowPayNowAction = IAction<ActionType.SHOW_PAY_NOW, PayNowPayload>;
type ISubmitPayNowAction = IAction<ActionType.SUBMIT_PAY_NOW, PayNowPayload>;
type IReportPayNowSuccessAction = IAction<ActionType.REPORT_PAY_NOW_SUCCESS>;
type IReportPayNowErrorAction = IAction<
    ActionType.REPORT_PAY_NOW_ERROR,
    string
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type ILapsedPaymentAction =
    | IShowPayNowAction
    | ISubmitPayNowAction
    | IReportPayNowErrorAction
    | IReportPayNowSuccessAction;

const showPayNow = createAction(ActionType.SHOW_PAY_NOW, (amount: number) => ({
    amount,
}));
const submitPayNow = createAction(
    ActionType.SUBMIT_PAY_NOW,
    (obj: PayNowPayload) => obj,
);
const reportPayNowSuccess = createAction(ActionType.REPORT_PAY_NOW_SUCCESS);
const reportPayNowError = createAction(
    ActionType.REPORT_PAY_NOW_ERROR,
    (err: string) => err,
);

export {
    ActionType,
    ILapsedPaymentAction,
    ISubmitPayNowAction,
    IShowPayNowAction,
    showPayNow,
    submitPayNow,
    reportPayNowError,
    reportPayNowSuccess,
};
