import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { ProductListing } from './components/product-listing';

const styles = require('./shop-visible.less');

interface IShopProps {
    readonly shop?: any;
    fetchProducts(): void;
}

@CSSModules(styles)
class ShopVisible extends React.Component<IShopProps> {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchProducts();
    }

    render() {
        const prods =
            this.props.shop.products.length > 0 &&
            this.props.shop.products.map((prod, i) => {
                // tslint:disable
                if (prod.Type === 'One-time') {
                    return <ProductListing key={i} product={prod} />;
                }
            });

        // TODO: Make the text below defined in the json
        return (
            <div>
                <div styleName="shop-banner">
                    <h1>New phone. New you.</h1>
                </div>
                <div>{prods}</div>
            </div>
        );
    }
}

export { ShopVisible };
