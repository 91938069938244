import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';
import { Headline } from 'components/common/typography';
import { BodyCopy } from 'components/common/typography/bodycopy/bodycopy';
import { ButtonContainer as Button } from 'containers';
import { Link } from 'react-router-dom';

interface Props extends IAnalyticsProps {
    dismissModal(): void;
}

interface State {
    readonly expanded: boolean;
}

@CSSModules(require('./pick-a-phone.less'), { allowMultiple: true })
class PickAPhone extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = { expanded: false };
    }

    componentDidMount() {
        setTimeout(this.expand, 500);
    }

    render() {
        const { expanded } = this.state;
        return (
            <div styleName={classNames('container', { expanded })}>
                <div styleName="pick-a-phone">
                    <Headline two noMarginTop>
                        {__('create-account.pick-a-phone.title')}
                    </Headline>
                    <BodyCopy>
                        {__('create-account.pick-a-phone.bodycopy')}
                    </BodyCopy>
                    <div styleName="buttons-wrapper">
                        <a href={__('routes.phones')}>
                            <Button
                                noPaddingSides
                                fullWidth
                                label={__('create-account.pick-a-phone.browse')}
                            />
                        </a>
                        <Link to={__('routes.sign-up')}>
                            <Button
                                noPaddingSides
                                fullWidth
                                onClick={this.onChooseByod}
                                label={__('create-account.pick-a-phone.byod')}
                            />
                        </Link>
                    </div>
                    <BodyCopy marginTop noMarginBottom thin>
                        {__('create-account.pick-a-phone.change-your-mind')}
                    </BodyCopy>
                </div>
            </div>
        );
    }

    private readonly onChooseByod = () => {
        const { dismissModal } = this.props;
        dismissModal();
    };

    private readonly expand = () => {
        this.setState({ expanded: true });
    };
}

export { PickAPhone };
