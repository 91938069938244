import { all, put, takeEvery } from 'redux-saga/effects';
import { clearCartErrors } from 'state/purchase/cart/cart.actions';
import { ActionType } from './carriers.actions';

function* clearCarriers() {
    yield put(clearCartErrors());
}

function* carriersSaga() {
    yield all([takeEvery(ActionType.CLEAR_CARRIERS, clearCarriers)]);
}

export { carriersSaga };
