import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { InPageNavContent } from 'components/common/in-page-nav';
import {
    AccountNavContainer as AccountNav,
    BillDetailsContainer as BillDetails,
    BillHistoryContainer as BillHistory,
    EditInfoContainer as EditInfo,
    OrderDetailsContainer as OrderDetails,
    OrderHistoryContainer as OrderHistory,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { CustomerState, IApplicationState } from 'models';
import { match } from 'react-router-dom';
import { IConfigurationState } from 'state/configuration';

const styles = require('./account.less');

/**
 * Properties available on the <code>props</code> property.
 */
interface IProps {
    readonly match: match<any>;
    readonly autopayFetchStatus: FetchStatus;
    readonly billFetchStatus: FetchStatus;
    readonly customerState: CustomerState;
    readonly getCreditBalanceFetchStatus: FetchStatus;
    readonly configuration: IConfigurationState;
    readonly location: any;
    readonly history: any;
    readonly chargeAmount: number;

    fetchBillHistory(): void;
    showPayNow(chargeAmount: number): void;
}

@CSSModules(styles, { allowMultiple: true })
class Account extends React.Component<IProps, IApplicationState> {
    static readonly getDerivedStateFromProps = (
        nextProps: IProps,
        prevState: {},
    ): Partial<{}> => {
        if (nextProps.location.pathname == __('routes.account')) {
            if (nextProps.customerState !== CustomerState.REGISTERED) {
                window.location.href = '/account/overview'; // nextProps.history.replace('/account/overview'); redirect to shop/account/overview with redirection rule.
            } else {
                nextProps.history.replace('/account/profile');
            }
        }
        return {};
    };

    componentDidUpdate(prevProps) {
        const { showPayNow, chargeAmount } = this.props;

        let urlParams = new URLSearchParams(window.location.search);

        if (
            urlParams.has('showPaymentDialog') &&
            urlParams.get('showPaymentDialog') &&
            this.props.chargeAmount !== prevProps.chargeAmount
        ) {
            showPayNow(chargeAmount);
        }
    }
    render() {
        const {
            customerState,
            configuration: { toggles },
        } = this.props;
        return (
            <div styleName="account-content-container">
                <AccountNav />
                <div styleName="account-content-wrapper">
                    {customerState &&
                        customerState !== CustomerState.REGISTERED && (
                            <>
                                {toggles.enableCashPayment && (
                                    <InPageNavContent
                                        routePath={__('routes.account.overview')}
                                        routeComponent={() => {
                                            window.location.href = __(
                                                'routes.account.overview',
                                            );
                                            return null;
                                        }}
                                    />
                                )}
                                {toggles.enableGroups && (
                                    <InPageNavContent
                                        routePath={__('routes.account.groups')}
                                        // routeComponent={Plan}
                                        routeComponent={() => {
                                            window.location.href = __(
                                                'routes.account.groups',
                                            );
                                            return null;
                                        }}
                                    />
                                )}
                                <InPageNavContent
                                    routePath={__(
                                        'routes.account.bill-history',
                                    )}
                                    routeComponent={BillHistory}
                                />
                                <InPageNavContent
                                    routePath={__(
                                        'routes.account.bill-details',
                                    )}
                                    routeComponent={BillDetails}
                                />
                                <InPageNavContent
                                    routePath={__(
                                        'routes.account.order-history',
                                    )}
                                    routeComponent={OrderHistory}
                                />
                                <InPageNavContent
                                    routePath={__(
                                        'routes.account.order-details',
                                    )}
                                    routeComponent={OrderDetails}
                                />
                            </>
                        )}
                    <InPageNavContent
                        routePath={__('routes.account.profile')}
                        routeComponent={EditInfo}
                    />
                    <InPageNavContent
                        routePath={__('routes.account.privacy')}
                        routeComponent={() => {
                            window.location.href = __('routes.account.privacy');
                            return null;
                        }}
                    />
                </div>
            </div>
        );
    }
}

export { Account };
