import { createAction, IAction } from 'lib/br-redux';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    GET_AUTOPAY_STATUS = 'PAYMENT/GET_AUTOPAY_STATUS',
    SET_AUTOPAY_STATUS = 'PAYMENT/SET_AUTOPAY_STATUS',
    SHOW_AUTOPAY_TERMS = 'PAYMENT/SHOW_AUTOPAY_TERMS',

    LOAD_AUTOPAY_STATUS = 'PAYMENT/LOAD_AUTOPAY_STATUS',
    REPORT_AUTOPAY_STATUS_ERROR = 'PAYMENT/REPORT_AUTOPAY_ERROR',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type IGetAutopayStatusAction = IAction<ActionType.GET_AUTOPAY_STATUS>;

type ISetAutopayStatusAction = IAction<ActionType.SET_AUTOPAY_STATUS, boolean>;

type IShowAutopayTermsAction = IAction<ActionType.SHOW_AUTOPAY_TERMS>;

type ILoadAutopayStatusAction = IAction<
    ActionType.LOAD_AUTOPAY_STATUS,
    boolean
>;

type IReportAutopayStatusErrorAction = IAction<
    ActionType.REPORT_AUTOPAY_STATUS_ERROR,
    string
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IAutopayAction =
    | IGetAutopayStatusAction
    | IShowAutopayTermsAction
    | ILoadAutopayStatusAction
    | IReportAutopayStatusErrorAction
    | ISetAutopayStatusAction;

const getAutopayStatus = createAction(ActionType.GET_AUTOPAY_STATUS);
const loadAutopayStatus = createAction(
    ActionType.LOAD_AUTOPAY_STATUS,
    (status: boolean) => status,
);
const reportAutopayStatusError = createAction(
    ActionType.REPORT_AUTOPAY_STATUS_ERROR,
    (er: string) => er,
);

const setAutopayStatus = createAction(
    ActionType.SET_AUTOPAY_STATUS,
    (s: boolean) => s,
);

const showAutopayTerms = createAction(ActionType.SHOW_AUTOPAY_TERMS);

export {
    ActionType,
    IAutopayAction,
    getAutopayStatus,
    IGetAutopayStatusAction,
    ILoadAutopayStatusAction,
    IReportAutopayStatusErrorAction,
    loadAutopayStatus,
    reportAutopayStatusError,
    setAutopayStatus,
    ISetAutopayStatusAction,
    showAutopayTerms,
};
