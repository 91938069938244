import { IApplicationState } from 'models';
import { IShop } from 'models/shop/shop.model';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { ShopService } from 'services';
import {
    ActionType,
    reportFetchProductsError,
    reportFetchProductsSuccess,
} from 'state/shop';

function* fetchProducts() {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );

        const shopResponse: ReadonlyArray<IShop> = yield call(
            ShopService.getProducts,
            basePaths,
        );
        yield put(reportFetchProductsSuccess(shopResponse));
    } catch (err) {
        yield put(reportFetchProductsError(err));
    }
}

function* shopSaga() {
    yield all([takeEvery(ActionType.FETCH_PRODUCTS_LIST, fetchProducts)]);
}

export { shopSaga };
