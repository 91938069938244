import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { ContentWrapper } from 'components/common/content-wrapper';
import { Divider } from 'components/common/divider';
import { Subhead } from 'components/common/typography';
import { Grid, GridTemplates } from 'components/grid';
import {
    CustomerState,
    IConfiguration,
    GroupMembershipDetailResponse,
} from 'models';
import { Link } from 'react-router-dom';

const styles = require('./container.less');

interface IState {
    readonly isMobileDropdownOpen?: boolean;
    readonly customerStateCSS?: string;
}

interface InPageNavLink {
    readonly label: string;
    readonly to: string;
    readonly onClick: () => void;
}

interface Props {
    readonly alignLeft?: boolean;
    readonly links: ReadonlyArray<InPageNavLink>;
    readonly subHeadline?: JSX.Element;
    readonly customerState: string;
    readonly configuration: IConfiguration;
    readonly groups: GroupMembershipDetailResponse;
}

interface MobileLinkProps {
    readonly children?: any;
    readonly mobile?: boolean;
}

const MobileLinkWrapper = ({ children, mobile }: MobileLinkProps) => {
    if (mobile) {
        return (
            <div styleName="in-page-nav-links-dropdown-hidden-link-wrapper">
                {children}
            </div>
        );
    }

    return children;
};

class InPageNavContainer extends React.Component<Props, IState> {
    componentWillMount(): void {
        const {
            props: { customerState },
        } = this;
        if (customerState === CustomerState.REGISTERED) {
            this.setState({
                isMobileDropdownOpen: false,
                customerStateCSS: 'in-page-nav-links-container-profile',
            });
        } else {
            this.setState({
                isMobileDropdownOpen: false,
                customerStateCSS: 'in-page-nav-links-container',
            });
        }
    }

    render() {
        const {
            props: {
                alignLeft,
                links,
                subHeadline,
                configuration: { toggles },
                groups,
            },
        } = this;
        const {
            state: { customerStateCSS },
        } = this;
        return (
            <Grid>
                <div
                    styleName={
                        toggles.enableGroups &&
                        groups &&
                        groups.GroupMembershipState !== 'Activated'
                            ? 'account-head-party__bg'
                            : 'account-head__bg'
                    }
                >
                    <GridTemplates.HeadlineTemplate
                        width={12}
                        sm
                        left
                        noMargin
                        headline={__('account.hero.header')}
                        subHeadline={subHeadline}
                    />
                </div>
                {alignLeft}
                <ContentWrapper noPadding>
                    <div styleName={`${customerStateCSS}`}>
                        <div
                            styleName={classNames('in-page-nav-links-wrapper', {
                                alignLeft,
                            })}
                        >
                            {links.map(this.createLinkMapper())}
                        </div>
                    </div>
                </ContentWrapper>

                <Divider shadow />
            </Grid>
        );
    }

    private readonly createLinkMapper = (mobile?: boolean) => (
        link: InPageNavLink,
        idx: number,
    ): JSX.Element => {
        return (
            <MobileLinkWrapper key={`in-page_${idx}`}>
                <Subhead
                    noMargin
                    link
                    activeLink={location.href.includes(link.to)}
                >
                    <Link
                        styleName="in-page-nav-link"
                        to={link.to}
                        onClick={link.onClick}
                    >
                        {link.label}
                    </Link>
                </Subhead>
            </MobileLinkWrapper>
        );
    };
}

export default CSSModules(InPageNavContainer, styles, { allowMultiple: true });
