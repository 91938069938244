import { IAnalyticsProps } from 'components/common/analytics';
import { Dots } from 'components/common/dots';
import { ContentWrapper } from 'components/common/content-wrapper';
import { Divider } from 'components/common/divider';
import { CheckboxOption } from 'components/common/forms';
import { Hidden } from 'components/common/hidden';
import {
    BodyCopy,
    Col,
    ErrorMsg,
    Headline,
    InfoLink,
    Row,
    SuccessMsg,
} from 'components/common/typography';
import { ButtonContainer as Button } from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { IAccount } from 'models';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./privacy.less');

interface Props extends IAnalyticsProps {
    readonly account: IAccount;
    readonly fetchStatus: FetchStatus;
    readonly resetPasswordError: string;
    readonly resetPasswordSuccess: string;
    readonly resetPasswordFetchStatus: FetchStatus;
    resetPassword(email: string): void;
    showCPNI(): any;
    updateStatus(status: boolean): any;
}

interface State {
    readonly cpniOptedIn: boolean;
}

type FieldName = 'password';

@CSSModules(styles, { allowMultiple: true })
class Privacy extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { cpniOptedIn: false };
    }

    componentDidMount(): void {
        this.setState({ cpniOptedIn: this.props.account.privacyPolicyOptedIn });
    }

    componentWillReceiveProps({ account, fetchStatus }: Props) {
        if (fetchStatus !== this.props.fetchStatus) {
            this.setState({ cpniOptedIn: account.privacyPolicyOptedIn });
        }
    }

    render() {
        const {
            handleChange,
            props: { showCPNI, fetchStatus },
            state: { cpniOptedIn },
        } = this;

        return (
            <>
                <ContentWrapper>
                    <Row>
                        <Col width={9} mobileWidth={12}>
                            <Headline medium three dark>
                                {__('account.privacy.privacy-policy')}
                            </Headline>
                            <BodyCopy columnDescription>
                                {__('account.privacy.policy-description')}
                            </BodyCopy>
                        </Col>

                        <Col width={3} mobileWidth={12} alignSelfEnd>
                            <InfoLink
                                to={`${__('routes.privacy')}`}
                                target="_blank"
                                plain
                                fullWidth
                            >
                                <Button
                                    invert
                                    secondary
                                    fullWidth
                                    label={__('account.privacy.view')}
                                />
                            </InfoLink>
                        </Col>
                    </Row>
                </ContentWrapper>

                <Divider />

                <ContentWrapper>
                    <Row>
                        <Col width={9} mobileWidth={12}>
                            <Headline medium three dark>
                                {__('account.privacy.heading')}
                            </Headline>

                            <Hidden when={fetchStatus === FetchStatus.FETCHING}>
                                <CheckboxOption
                                    id="privacy-opt-in"
                                    onChange={handleChange}
                                    checked={cpniOptedIn}
                                >
                                    {__(
                                        'account.privacy.checkbox.opted-in.label',
                                    )}
                                </CheckboxOption>
                            </Hidden>
                            <Hidden
                                until={fetchStatus === FetchStatus.FETCHING}
                            >
                                <BodyCopy columnDescription>
                                    <Dots invert />
                                </BodyCopy>
                            </Hidden>
                        </Col>

                        <Col width={3} mobileWidth={12} alignSelfEnd>
                            <Button
                                invert
                                secondary
                                fullWidth
                                onClick={showCPNI}
                                label={__('account.privacy.view')}
                            />
                        </Col>
                    </Row>
                </ContentWrapper>

                <Divider />

                <ContentWrapper>
                    <Row>
                        <Col width={9} mobileWidth={12}>
                            <Headline medium three dark>
                                {__('account.profile.form.password')}
                            </Headline>
                            <BodyCopy columnDescription>
                                {__('account.profile.passwordChangeMessage')}
                            </BodyCopy>
                        </Col>

                        <Col width={3} mobileWidth={12} alignSelfEnd>
                            {this.changePasswordSection}
                        </Col>
                    </Row>
                </ContentWrapper>
            </>
        );
    }
    private readonly handleChange = (cpniOptedIn: boolean): void => {
        this.setState({ cpniOptedIn });
        this.props.updateStatus(cpniOptedIn);
    };

    private fieldIfEditable(
        fieldName: FieldName,
        element: JSX.Element,
    ): JSX.Element | null {
        return element;
    }

    private readonly changePassword = (): void => {
        const { account, resetPassword } = this.props;
        resetPassword(account.emailAddress);
    };

    private get changePasswordSection(): JSX.Element | null {
        const { resetPasswordError, resetPasswordSuccess } = this.props;
        return this.fieldIfEditable(
            'password',
            <>
                <Hidden when={!!resetPasswordSuccess}>
                    <Button
                        invert
                        secondary
                        fullWidth
                        onClick={this.changePassword}
                        label={__('account.profile.changePassword')}
                    />
                    <ErrorMsg>{resetPasswordError}</ErrorMsg>
                </Hidden>
                <Hidden until={!!resetPasswordSuccess}>
                    <SuccessMsg>{resetPasswordSuccess}</SuccessMsg>
                </Hidden>
            </>,
        );
    }
}

export { Privacy };
