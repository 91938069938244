import { OrderDetails } from 'components/account/order-details';
import { Account, IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { CartActions } from 'state/purchase/purchase.actions';
import { withDeviceType } from 'components/common/device-type';

const { fetchOrderDetails } = CartActions;

const mapStateToProps = (
    { account, purchase }: IApplicationState,
    ownProps,
) => ({
    account: new Account(account),
    orderHistories: account.orders || [],
    fetchStatus: account.ordersFetchStatus,
    orderNumber: ownProps.match.params.orderNumber,
    orderDetails: purchase.cart.orderDetails,
});

const mapDispatchToProps = dispatch => ({
    fetchOrderDetails: orderNumber => dispatch(fetchOrderDetails(orderNumber)),
});

const enhance = compose(
    withDeviceType,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const OrderDetailsContainer = enhance(OrderDetails as any);

export { OrderDetailsContainer };
