import { Headline } from 'components/common/typography';
import { BodyCopy } from 'components/common/typography/bodycopy/bodycopy';
import * as React from 'react';

const PortMessage = () => (
    <div>
        <Headline two>{__('phone-number.port-message.title')}</Headline>
        <BodyCopy>{__('phone-number.port-message.body')}</BodyCopy>
    </div>
);

export { PortMessage };
