import { IApplicationState } from 'models';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { AccountService } from 'services';
import { ActionType } from 'state/account/pause/pause.actions';
import { getToken } from 'state/firebase/firebase.saga';
import { PauseServiceAction, reportPauseError, reportPauseSuccess } from '.';
import { refreshAccount } from '..';

function* pauseService(action: PauseServiceAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const token = yield call(getToken);
        yield call(
            AccountService.pauseAccount,
            basePaths,
            token,
            action.payload,
        );
        yield call(refreshAccount); // added so that customer state gets updated
        yield put(reportPauseSuccess());
    } catch (err) {
        yield put(reportPauseError((err as Error).message));
    }
}

function* pauseSaga() {
    yield all([takeEvery(ActionType.PAUSE_SERVICE, pauseService)]);
}

export { pauseSaga };
