import { IApplicationState } from 'models';
import { Store } from 'redux';

class SmellyStoreSingleton {
    // tslint:disable:variable-name
    // tslint:disable-next-line:readonly-keyword
    private _store: Store<IApplicationState>;

    get store(): Store<IApplicationState> {
        return this._store;
    }

    setStoreForUseByIndexFileOnly(store: Store<IApplicationState>): void {
        this._store = store;
    }
}

const smellyStoreSingleton = new SmellyStoreSingleton();
export { smellyStoreSingleton };
