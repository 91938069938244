import { IAddress } from 'models';

interface ValidationResponse {
    readonly valid: boolean;
    readonly errorMessage: string;
}

interface Options {
    readonly errorMessage?: string;
    readonly optional?: boolean;
}

type CustomValidator = (input: string) => void;

class InputValidator {
    static readonly Patterns = {
        CustomerNameAllowedCharacters: /^[a-zA-Z'\-\.\s]+$/,
        // tslint:disable:max-line-length
        Email: /^(([^<>()\[\]\\.,;:\s"!@#$%^&*=|/{}~`]+(\.[^<>()\[\]\\.,;:\s"!@#$%^&*=|/{}~`]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        NumbersLettersDashes: /^[a-zA-Z\d\-]+$/,
        Numeric: /^[\d]+$/,
        Phone: /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/,
        ReferralCodeAllowedCharacters: /^[a-zA-Z0-9]+$/,
    };

    readonly input: string;

    // tslint:disable:variable-name
    // tslint:disable:readonly-keyword
    valid: boolean = true;
    private _errorMessage: string;
    private readonly optional: boolean;
    // tslint:enable

    constructor(
        input: string | null | undefined,
        { errorMessage, optional }: Options = {},
    ) {
        this.input = this.sanitize(input);
        this._errorMessage =
            errorMessage || __('input-validator.default-error');
        this.optional = optional || false;

        // Go ahead and check for length if this is not set to optional
        if (!this.optional && this.input.length === 0) {
            this.invalidate();
        }
    }

    static readonly required = (errorMessage?: string) => (
        value: string,
    ): ValidationResponse => {
        return new InputValidator(value, { errorMessage }).validate();
    };

    static readonly validateIMEI = (value: string): ValidationResponse => {
        const { Numeric } = InputValidator.Patterns;
        return new InputValidator(value, {
            errorMessage: __('imei.length-error'),
        })
            .conformsToPatternWithSpaces(Numeric)
            .hasAtLeastLengthOfWithSpaces(14)
            .doesNotExceedLengthOfWithSpaces(16)
            .validate();
    };

    static readonly validateText = (value: string) => {
        return new InputValidator(value).validate();
    };

    static readonly validateEmail = (value: string) => {
        const { Email } = InputValidator.Patterns;
        return new InputValidator(value, {
            errorMessage: __('input-validator.email-required-error'),
        })
            .conformsToPattern(Email, __('input-validator.email-invalid'))
            .validate();
    };

    static readonly validateFirstName = (value: string) => {
        const { CustomerNameAllowedCharacters } = InputValidator.Patterns;
        return new InputValidator(value, {
            errorMessage: __('input-validator.firstname-required-error'),
        })
            .doesNotExceedLengthOf(
                30,
                __('input-validator.firstname-max-length-error'),
            )
            .conformsToPattern(
                CustomerNameAllowedCharacters,
                __('input-validator.firstname-special-character-error'),
            )
            .validate();
    };

    static readonly validateLastName = (value: string) => {
        const { CustomerNameAllowedCharacters } = InputValidator.Patterns;
        return new InputValidator(value, {
            errorMessage: __('input-validator.lastname-required-error'),
        })
            .doesNotExceedLengthOf(
                30,
                __('input-validator.lastname-max-length-error'),
            )
            .conformsToPattern(
                CustomerNameAllowedCharacters,
                __('input-validator.lastname-special-character-error'),
            )
            .validate();
    };

    static readonly validatePassword = (value: string) => {
        return new InputValidator(value)
            .hasAtLeastLengthOf(
                8,
                __('input-validator.password-too-short-error'),
            )
            .doesNotConformToPattern(
                /\s/,
                __('input-validator.password-contains-space-error'),
            )
            .conformsToPattern(
                /[A-Z]/,
                __('input-validator.password-missing-uppercase-error'),
            )
            .conformsToPattern(
                /[a-z]/,
                __('input-validator.password-missing-lowercase-error'),
            )
            .conformsToPattern(
                /[~|!|@|#|$|%|^|&|*|?]/,
                __('input-validator.password-missing-special-error'),
            )
            .validate();
    };

    static readonly validateConfirmPassword = (password: string) => (
        confirmPassword: string,
    ) => {
        return new InputValidator(confirmPassword)
            .exectuteCustomValidator(() => {
                if (password !== confirmPassword) {
                    throw new Error(
                        __('input-validator.passwords-do-not-match-error'),
                    );
                }
            })
            .validate();
    };

    static readonly validateZipCode = (value: string) => {
        const { Numeric } = InputValidator.Patterns;
        return new InputValidator(value, {
            errorMessage: __('input-validator.zip-code-error'),
        })
            .hasExactLengthOf(5)
            .conformsToPattern(Numeric)
            .validate();
    };

    static readonly validateAddress = (value: IAddress): ValidationResponse => {
        const validatorStreetLine1 = new InputValidator(value.street_line1, {
            errorMessage: __('address.no-address'),
        }).validate();
        const validatorCity = new InputValidator(value.city, {
            errorMessage: __('address.no-address'),
        }).validate();
        const validatorState = new InputValidator(value.state, {
            errorMessage: __('address.no-address'),
        }).validate();
        const validatorZipCode = new InputValidator(value.zip, {
            errorMessage: __('address.no-address'),
        }).validate();
        return {
            errorMessage:
                validatorStreetLine1.errorMessage ||
                validatorCity.errorMessage ||
                validatorState.errorMessage ||
                validatorZipCode.errorMessage,
            valid:
                validatorStreetLine1.valid &&
                validatorCity.valid &&
                validatorState.valid &&
                validatorZipCode.valid,
        };
    };

    static readonly validateAddressLineTwo = (
        value: string,
    ): ValidationResponse => {
        return new InputValidator(value, {
            optional: true,
        }).validate();
    };

    static readonly validateReferralCode = (value: string) => {
        const { ReferralCodeAllowedCharacters } = InputValidator.Patterns;
        return new InputValidator(value, {
            errorMessage: __('input-validator.referral-code-error'),
            optional: true,
        })
            .conformsToPattern(
                ReferralCodeAllowedCharacters,
                __('input-validator.referral-code-special-character-error'),
            )
            .validate();
    };

    /**
     * Returns the error message ONLY if the input is invalid
     */
    get errorMessage(): string {
        if (!this.valid) {
            return this._errorMessage;
        }

        return '';
    }

    private get isNullAndOptional(): boolean {
        return this.optional && this.input.length === 0;
    }

    private get skippable(): boolean {
        return this.isNullAndOptional;
    }

    hasExactLengthOf(length: number, errorMessage?: string): this {
        if (this.skippable) {
            return this;
        }
        if (this.input.length !== length) {
            this.invalidate(errorMessage);
        }

        return this;
    }
    hasAtLeastLengthOf(minLength: number, errorMessage?: string): this {
        if (this.skippable) {
            return this;
        }
        if (this.input.length < minLength) {
            this.invalidate(errorMessage);
        }

        return this;
    }

    hasAtLeastLengthOfWithSpaces(
        minLength: number,
        errorMessage?: string,
    ): this {
        if (this.skippable) {
            return this;
        }

        const numOfSpaces = this.input.split(' ').length - 1;
        const inputLength = this.input.length;

        // -> If input has spaces...
        if (
            /\s/g.test(this.input) &&
            numOfSpaces > 0 &&
            inputLength - numOfSpaces < minLength
        ) {
            this.invalidate(errorMessage);
        }

        // -> If input has no spaces...
        if (!/\s/g.test(this.input) && this.input.length < minLength) {
            this.invalidate(errorMessage);
        }

        return this;
    }

    doesNotExceedLengthOf(maxLength: number, errorMessage?: string): this {
        if (this.skippable) {
            return this;
        }
        if (this.input.length > maxLength) {
            this.invalidate(errorMessage);
        }

        return this;
    }

    doesNotExceedLengthOfWithSpaces(
        maxLength: number,
        errorMessage?: string,
    ): this {
        if (this.skippable) {
            return this;
        }

        const numOfSpaces = this.input.split(' ').length - 1;
        const inputLength = this.input.length;

        // -> If input has spaces...
        if (
            /\s/g.test(this.input) &&
            numOfSpaces > 0 &&
            inputLength - numOfSpaces > maxLength
        ) {
            this.invalidate(errorMessage);
        }

        // -> If input has no spaces...
        if (!/\s/g.test(this.input) && this.input.length > maxLength) {
            this.invalidate(errorMessage);
        }

        return this;
    }

    conformsToPattern(pattern: RegExp, errorMessage?: string): this {
        if (this.skippable) {
            return this;
        }
        if (!pattern.test(this.input)) {
            this.invalidate(errorMessage);
        }

        return this;
    }

    conformsToPatternWithSpaces(pattern: RegExp, errorMessage?: string): this {
        if (this.skippable) {
            return this;
        }

        // -> If provided input has any spaces...
        if (/\s/g.test(this.input)) {
            if (!pattern.test(this.input.replace(/\s/g, ''))) {
                this.invalidate(errorMessage);
            }
        }

        // -> If provided input has no spaces...
        if (!/\s/g.test(this.input)) {
            if (!pattern.test(this.input)) {
                this.invalidate(errorMessage);
            }
        }

        return this;
    }

    doesNotConformToPattern(pattern: RegExp, errorMessage?: string): this {
        if (this.skippable) {
            return this;
        }
        if (pattern.test(this.input)) {
            this.invalidate(errorMessage);
        }

        return this;
    }

    exectuteCustomValidator(validator: CustomValidator): this {
        try {
            validator(this.input);
        } catch (error) {
            const message =
                error && (error as Error).message ? error.message : null;
            this.invalidate(message);
        }

        return this;
    }

    validate(): ValidationResponse {
        return {
            errorMessage: this.errorMessage,
            valid: this.valid,
        };
    }

    private invalidate(errorMessage?: string): void {
        if (errorMessage && errorMessage.trim()) {
            this._errorMessage = errorMessage;
        }
        this.valid = false;
    }

    private sanitize(input: string | null | undefined): string {
        return (input || '').trim();
    }
}

export { CustomValidator, InputValidator, ValidationResponse };
