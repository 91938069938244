// tslint:disable:max-line-length
import * as React from 'react';

interface Props extends React.HTMLProps<SVGElement> {
    readonly invert?: boolean;
}

const O = ({ invert, ...other }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="115"
        height="115"
        viewBox="0 0 115 115"
    >
        <g fill={invert ? '#ffffff' : '#1800FF'} fillRule="evenodd">
            <path d="M26.42 10.218c0 5.643-4.573 10.22-10.211 10.22S6 15.861 6 10.218C6 4.576 10.57 0 16.209 0c5.638 0 10.21 4.576 10.21 10.218M108.42 10.218c0 5.643-4.573 10.22-10.211 10.22S88 15.861 88 10.218C88 4.576 92.57 0 98.209 0c5.638 0 10.21 4.576 10.21 10.218M57.149 94.342C40.523 94.342 27 80.809 27 64.171 27 47.535 40.523 34 57.149 34c16.625 0 30.148 13.535 30.148 30.171 0 16.638-13.523 30.171-30.148 30.171m0-43.032c-7.086 0-12.853 5.769-12.853 12.861 0 7.09 5.767 12.862 12.853 12.862 7.085 0 12.85-5.772 12.85-12.862 0-7.092-5.765-12.86-12.85-12.86" />
        </g>
    </svg>
);

export { O };
