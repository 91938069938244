import { ActionHandlerMode } from 'components/email-action-handler/email-action-handler';
import { IApplicationState } from 'models';
import { push } from 'react-router-redux';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
    ActionType,
    reportEmailActionCodeVerificationFailure,
    reportEmailActionCodeVerificationSuccess,
    reportPasswordResetFailure,
    reportPasswordResetSuccess,
    ResetPasswordAction,
    VerifyEmailActionCodeAction,
} from 'state/email-action/email-action.actions';
import { EmailActionState } from 'state/email-action/email-action.reducer';
import {
    applyVerificationCode,
    resetPasswordWithOob,
    verifyPasswordResetCode,
} from 'state/firebase/firebase.saga';
import { trackError } from 'state/tealium';
import {
    dismissLoadingIndicator,
    requestLoadingIndicator,
} from 'state/view/view.actions';
import { eventError } from '../tealium';

function* verifyEmailActionCode(action: VerifyEmailActionCodeAction) {
    const loadingKey = 'verify-email-code';
    try {
        yield put(requestLoadingIndicator(loadingKey));
        const { oobCode, mode } = action.payload;
        switch (mode) {
            case ActionHandlerMode.RESET_PASSWORD:
                yield call(checkResetPasswordCode, oobCode);
                break;
            case ActionHandlerMode.VERIFY_EMAIL:
                yield call(applyVerificationCode, oobCode);
                break;
            default:
                yield put(
                    eventError({
                        error_msg: __('unrecognized_email_mode_error'),
                    }),
                );
                throw new Error(__('unrecognized_email_mode_error'));
        }

        yield put(reportEmailActionCodeVerificationSuccess());
    } catch (_) {
        yield put(
            reportEmailActionCodeVerificationFailure(
                __('email_action_code_verification_error'),
            ),
        );
    } finally {
        yield put(dismissLoadingIndicator(loadingKey));
    }
}

function* checkResetPasswordCode(oobCode: string) {
    try {
        yield call(verifyPasswordResetCode, oobCode);
    } catch (err) {
        // do custom thing
        yield put(
            push(
                `${__('reset-password-error-url')}?error=${encodeURIComponent(
                    (err as Error).message || __('default_service_error'),
                )}`,
            ),
        );

        // continue bubbling up the error
        throw err;
    }
}

function* resetPassword(action: ResetPasswordAction) {
    try {
        const { oobCode }: EmailActionState = yield select(
            ({ emailAction }: IApplicationState) => emailAction,
        );
        yield call(resetPasswordWithOob, oobCode, action.payload);
        yield put(reportPasswordResetSuccess());
    } catch (err) {
        yield put(
            push(
                `${__('reset-password-error-url')}?error=${encodeURIComponent(
                    __('reset-password-error-message'),
                )}`,
            ),
        );
        yield put(reportPasswordResetFailure((err as Error).message));
    }
}

function* emailActionSaga() {
    yield all([
        takeEvery(ActionType.VERIFY_CODE, verifyEmailActionCode),
        takeEvery(ActionType.RESET_PASSWORD, resetPassword),
        takeEvery(ActionType.REPORT_VERIFICATION_FAILURE, trackError),
        takeEvery(ActionType.REPORT_PASSWORD_RESET_FAILURE, trackError),
    ]);
}

export { emailActionSaga };
