import { UserValidationState } from './user-validation.reducer';

interface UserValidationSelectorState {
    readonly userValidation: UserValidationState;
}

const getUserValidationStatus = (state: UserValidationSelectorState) =>
    state.userValidation;

export { getUserValidationStatus };
