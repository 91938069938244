// tslint:disable:no-bitwise
// tslint:disable:no-let
// tslint:disable:triple-equals
const uuidv4 = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let r: number;
        let v: number;
        (r = (Math.random() * 16) | 0), (v = c == 'x' ? r : (r & 0x3) | 0x8);
        return v.toString(16);
    });

export { uuidv4 };
