import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

// import { Hidden } from 'components/common/hidden';
import { NavButton, NavText } from 'components/common/typography';
import { NavLink } from 'components/common/typography/nav-link/nav-link';
import { Link } from 'react-router-dom';
import { CustomerState, IAccount, IDeviceTypeProps } from 'models';
import {
    TealiumFlowName,
    TealiumLinkEvents,
    TealiumLoginState,
    TealiumPageLinkLocations,
    TealiumPageType,
    TealiumPagevents,
} from 'models/tealium';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';

const styles = require('./authenticated-links.less');

interface Props extends IDeviceTypeProps {
    readonly mobileExpanded: boolean;
    readonly account: IAccount;
    readonly customerState: CustomerState;
    signOut(): void;
    onAnyClick(): void;
    startPollEmailVerification(): void;
    isCartPresent: boolean;
}

@CSSModules(styles, { allowMultiple: true })
class AuthenticatedLinks extends React.Component<Props> {
    render() {
        const {
            props: {
                account,
                customerState,
                isDesktop,
                mobileExpanded,
                onAnyClick,
                signOut,
                isCartPresent,
            },
        } = this;
        const registered = !!(
            customerState && customerState === CustomerState.REGISTERED
        );

        return (
            <div styleName={classNames('auth-links', { mobileExpanded })}>
                {isDesktop && (
                    <div styleName="greeting">
                        <NavText styleName="first-name">
                            {account.firstName}
                        </NavText>
                        <ul styleName="dropdown">
                            <li>
                                <NavLink
                                    onClick={async () => {
                                        await this.onAccountClick();
                                        await onAnyClick();
                                        await this.props.startPollEmailVerification();
                                    }}
                                    to={
                                        registered
                                            ? __('routes.account.profile')
                                            : __('routes.account.overview')
                                    }
                                >
                                    Account
                                </NavLink>
                                <hr styleName="dropdown-divider" />
                            </li>
                            {/* </Hidden> */}
                            <li>
                                <NavButton
                                    onClick={() => {
                                        signOut();
                                        onAnyClick();
                                    }}
                                >
                                    Sign Out
                                </NavButton>
                            </li>
                        </ul>
                    </div>
                )}

                <div>
                    {isCartPresent ? (
                        <Link to={__('routes.cart')}>
                            <img
                                styleName="cart-icon"
                                src={__('navigation.cart.present.icon')}
                            />
                        </Link>
                    ) : (
                        <Link to={__('routes.cart')}>
                            <img
                                styleName="cart-icon"
                                src={__('navigation.cart.default.icon')}
                            />
                        </Link>
                    )}
                </div>
            </div>
        );
    }

    private readonly onAccountClick = () => {
        localStorage.setItem(TealiumFlowName.ACCOUNT_NAV_LINK, 'true');
        TealiumDispatcher.dispatchEvent(
            TealiumFlowName.NONE,
            TealiumPageType.HOME,
            TealiumPagevents.ACCOUNT,
            TealiumPageLinkLocations.TOP_NAV,
            TealiumLinkEvents.BUTTON,
            TealiumLoginState.LOGGED_IN,
            this.props.account ? this.props.account.accountid : '',
        );
    };
}

export { AuthenticatedLinks };
