import { CreateAccount } from 'components/account/create-account';
import { withAnalytics } from 'components/common/analytics';
import { withDeviceType } from 'components/common/device-type';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { ViewActions } from 'state/view';

const mapStateToProps = ({
    authentication,
    routing: { location },
}: IApplicationState) => ({
    authStatus: authentication.fetchStatus,
    currentRoute: location ? location.pathname : null,
});

const mapDispatchToProps = dispatch => ({
    dismissModal: () => dispatch(ViewActions.dismissModal()),
    redirectTo: (url: string) => dispatch(push(url)),
});

const enhance = compose(
    withAnalytics,
    withDeviceType,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const CreateAccountContainer = enhance(CreateAccount);
export { CreateAccountContainer };
