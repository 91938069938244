import { TealiumFlowName, TealiumPageType } from 'models/tealium';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';

const styles = require('./media-general.less');

@CSSModules(styles, { allowMultiple: true })
class Androidgeneral extends React.Component<{}> {
    constructor(props: {}) {
        super(props);
    }
    componentDidMount() {
        TealiumDispatcher.dispatchView(
            TealiumFlowName.NONE,
            TealiumPageType.ANDROID_LANDING,
        );
    }

    render(): JSX.Element {
        return (
            <div>
                <iframe
                    src={__('routes.androidgeneral.aem')}
                    styleName="iframe"
                />
            </div>
        );
    }
}

export { Androidgeneral };
