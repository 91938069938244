import { BillDetails } from 'components/account/bill-details';
import { withModal } from 'components/common/modal';
import { Account, IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchBillDetails, fetchBillHistory } from 'state/bill';

const mapStateToProps = ({ account, bill }: IApplicationState, ownProps) => ({
    account: new Account(account),
    chargeEvents: bill.history.results || [],
    ...ownProps,
    ...(bill.history.results.find(
        b => b.eventid === ownProps.match.params.eventid,
    ) || {}),
});

const mapDispatchToProps = dispatch => ({
    fetchBillHistory: () => dispatch(fetchBillHistory()),
    fetchDetails: id => dispatch(fetchBillDetails(id)),
});

const enhance = compose(
    withModal,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const BillDetailsContainer = enhance(BillDetails as any);

export { BillDetailsContainer };
