import { createAction, IAction } from 'lib/br-redux';

enum ActionType {
    CANCEL_SERVICE = 'ACCOUNT?CANCEL?CANCEL_SERVICE',
    REPORT_CANCEL_SUCCESS = 'ACCOUNT/CANCEL/REPORT_CANCEL_SUCCESS',
    REPORT_CANCEL_ERROR = 'ACCOUNT/CANCEL/REPORT_CANCEL_ERROR',
}

type CancelServiceAction = IAction<ActionType.CANCEL_SERVICE>;
type ReportCancelSuccessAction = IAction<ActionType.REPORT_CANCEL_SUCCESS>;
type ReportCancelErrorAction = IAction<ActionType.REPORT_CANCEL_ERROR, string>;

type CancelAction =
    | CancelServiceAction
    | ReportCancelErrorAction
    | ReportCancelSuccessAction;

const cancelService = createAction(ActionType.REPORT_CANCEL_ERROR);
const reportCancelSuccess = createAction(ActionType.REPORT_CANCEL_SUCCESS);
const reportCancelError = createAction(
    ActionType.REPORT_CANCEL_ERROR,
    (errorMessage: string) => errorMessage,
);

export {
    ActionType,
    CancelServiceAction,
    ReportCancelErrorAction,
    ReportCancelSuccessAction,
    CancelAction,
    cancelService,
    reportCancelError,
    reportCancelSuccess,
};
