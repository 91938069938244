import { IAnalyticsProps } from 'components/common/analytics';
import { Block } from 'components/common/block';
import { ContentWrapper } from 'components/common/content-wrapper';
import { Divider } from 'components/common/divider';
import { Hidden } from 'components/common/hidden';
import {
    BodyCopy,
    Col,
    Headline,
    InfoLink,
    Row,
    Subhead,
} from 'components/common/typography';
import {
    ButtonContainer as Button,
    CountDownContainer as CountDown,
} from 'containers';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import {
    Account,
    GroupMembershipDetailResponse,
    IConfiguration,
    Cart,
    HandsetCartItem,
    MDNType,
    ProductType,
    SIMCartItem,
    IDeviceTypeProps,
} from 'models';
import { PortStatus } from 'models/port/port-status';
import { normalizeNumber } from 'utils/normalize-number';

const mintCheckIcon = '/assets/images/icons/mint-check.svg';
const emptyCheckIcon = '/assets/images/icons/empty-check.svg';
const emailIcon = '/assets/images/icons/email.svg';
const phoneIcon = '/assets/images/icons/phone.svg';
const squareSmileIcon = '/assets/images/icons/square-smiley.svg';
const squareCommunityicon = '/assets/images/icons/square-community.svg';
const palmIcon = '/assets/images/icons/palm.svg';
const thumbsUpIcon = '/assets/images/icons/thumbsup.svg';
const yieldIcon = '/assets/images/icons/yield.svg';

const styles = require('./pre-active.less');

interface Props extends IAnalyticsProps, IDeviceTypeProps {
    readonly account: Account;
    readonly groups: GroupMembershipDetailResponse;
    readonly configuration: IConfiguration;
    readonly cart: Cart | null;
    readonly portIssue: any;
    resendVerificationEmail();
}

@CSSModules(styles)
class PreActive extends React.Component<Props> {
    getImageUrl(url) {
        return (
            '/shop/assets/images/shop/' +
            url.substring(url.lastIndexOf('/') + 1, url.length)
        );
    }
    render() {
        const {
            account,
            groups,
            configuration,
            cart,
            resendVerificationEmail,
            isMobile,
        } = this.props;
        const promoContents = this.props.account.PROMO_CONTENTS;

        const device = cart
            ? (cart.itemForProductType(ProductType.HANDSET) as HandsetCartItem)
            : null;
        const sim = cart
            ? (cart.itemForProductType(ProductType.SIM) as SIMCartItem)
            : null;
        const newMdn = sim && sim.mdnType === MDNType.NEW ? true : null;
        const byod = device && device.isByod() ? true : false;
        const {
            firstName,
            lastName,
            address: { shipping_address },
        } = account;
        return (
            <>
                {/* Tracking: cart && device && !byod */}
                {cart && device && !byod && (
                    <ContentWrapper>
                        <Row>
                            <Col width={3} mobileWidth={6}>
                                <Headline
                                    medium
                                    three
                                    fontCircular
                                    light
                                    customSpacing
                                    margin30Top
                                    margin16Bottom
                                >
                                    {__('preactive.delivery-header')}
                                </Headline>
                                <BodyCopy columnDescription mobileFont12>
                                    {__('preactive.delivery-description')}
                                </BodyCopy>
                            </Col>
                            <Col width={4} mobileWidth={6} leftMargin={true}>
                                <BodyCopy
                                    columnSubHeading
                                    margin16Bottom
                                    mobileFont14
                                    margin30Top
                                >
                                    {__('preactive.shipping-address')}
                                </BodyCopy>
                                <BodyCopy
                                    columnDescription
                                    noMargin
                                    mobileFont12
                                >
                                    {firstName} {lastName}
                                </BodyCopy>
                                <BodyCopy
                                    columnDescription
                                    margin8Bottom
                                    mobileFont12
                                >
                                    {shipping_address!.street_line1},{' '}
                                    {shipping_address!.state}{' '}
                                    {shipping_address!.zip}
                                </BodyCopy>
                                {cart.shippingUrl &&
                                    cart.shippingUrl.length > 0 && (
                                        <Block width={2} width100={isMobile}>
                                            <a
                                                target="_blank"
                                                href={cart.shippingUrl}
                                            >
                                                <Button
                                                    invert
                                                    secondary
                                                    fullWidth
                                                    minWidth={218}
                                                    label={__(
                                                        'preactive.track-order',
                                                    )}
                                                />
                                            </a>
                                        </Block>
                                    )}
                            </Col>
                            <Col width={4} mobileWidth={12}>
                                {isMobile && (
                                    <>
                                        {device && device.imageURL && (
                                            <Col width={6} mobileWidth={4}>
                                                <img
                                                    styleName="device__scale"
                                                    src={this.getImageUrl(
                                                        device.imageURL,
                                                    )}
                                                />
                                            </Col>
                                        )}
                                    </>
                                )}
                                <Col width={6} mobileWidth={6}>
                                    <BodyCopy
                                        columnSubHeading
                                        margin16Bottom
                                        mobileFont14
                                        margin30Top
                                        margin8BottomMobile
                                    >
                                        {(cart && cart.make) +
                                            ' ' +
                                            (device && device.name)}
                                    </BodyCopy>
                                    <BodyCopy
                                        columnDescription
                                        noMargin
                                        mobileFont12
                                    >
                                        {device.color}
                                    </BodyCopy>
                                    <BodyCopy
                                        columnDescription
                                        margin8Bottom
                                        mobileFont12
                                    >
                                        {device.storage}
                                    </BodyCopy>
                                </Col>
                                {!isMobile && (
                                    <>
                                        {device && device.imageURL && (
                                            <Col width={6} mobileWidth={6}>
                                                <img
                                                    styleName="device__scale"
                                                    src={this.getImageUrl(
                                                        device.imageURL,
                                                    )}
                                                />
                                            </Col>
                                        )}
                                    </>
                                )}
                            </Col>
                        </Row>
                    </ContentWrapper>
                )}
                {/* /Tracking: cart && device && !byod */}

                {/* Tracking: cart && byod */}
                {cart && byod && (
                    <>
                        <ContentWrapper marginBottom>
                            <Row>
                                <Col width={3} mobileWidth={6}>
                                    <Headline
                                        medium
                                        three
                                        fontCircular
                                        light
                                        customSpacing
                                        margin16Bottom
                                    >
                                        {__('preactive.delivery-header')}
                                    </Headline>
                                    <BodyCopy columnDescription width70>
                                        {__('preactive.delivery-description')}
                                    </BodyCopy>
                                </Col>
                                <Col
                                    width={4}
                                    mobileWidth={6}
                                    leftMargin={true}
                                >
                                    <BodyCopy
                                        columnSubHeading
                                        mobileFont14
                                        margin30Top
                                        margin16Bottom
                                    >
                                        {__('preactive.shipping-address')}
                                    </BodyCopy>
                                    <BodyCopy
                                        columnDescription
                                        noMargin
                                        mobileFont12
                                    >
                                        {firstName} {lastName}
                                    </BodyCopy>
                                    <BodyCopy
                                        columnDescription
                                        margin8Bottom
                                        mobileFont12
                                    >
                                        {shipping_address!.street_line1},{' '}
                                        {shipping_address!.state}{' '}
                                        {shipping_address!.zip}
                                    </BodyCopy>
                                    {cart.shippingUrl &&
                                        cart.shippingUrl.length > 0 && (
                                            <Block width={2}>
                                                <a
                                                    target="_blank"
                                                    href={cart.shippingUrl}
                                                >
                                                    <Button
                                                        invert
                                                        secondary
                                                        fullWidth
                                                        minWidth={218}
                                                        label={__(
                                                            'preactive.track-order',
                                                        )}
                                                    />
                                                </a>
                                            </Block>
                                        )}
                                </Col>
                                <Col width={4} mobileWidth={12}>
                                    {device && device.imageURL && (
                                        <Col width={6} mobileWidth={6}>
                                            <img
                                                styleName="device__scale"
                                                src={this.getImageUrl(
                                                    device.imageURL,
                                                )}
                                            />
                                        </Col>
                                    )}
                                </Col>
                            </Row>
                        </ContentWrapper>
                    </>
                )}
                <Divider />
                {((cart && device && sim) || (cart && byod)) && (
                    <>
                        {/* Next steps: 1st row */}
                        <ContentWrapper>
                            <Row>
                                <Col width={3} mobileWidth={12}>
                                    <Headline
                                        medium
                                        three
                                        fontCircular
                                        light
                                        customSpacing
                                        margin16Bottom
                                        noMarginTopMobile
                                    >
                                        {__('preactive.top-headline')}
                                    </Headline>
                                </Col>
                                <Col width={9} mobileWidth={12}>
                                    <Row>
                                        <Hidden
                                            when={
                                                account.emailVerified === true
                                            }
                                        >
                                            <Col width={6} mobileWidth={12}>
                                                <div styleName="whats-next-item">
                                                    <div styleName="item-content">
                                                        <div styleName="item-title">
                                                            <img
                                                                src={emailIcon}
                                                                alt="email-icon"
                                                                styleName="margin-bottom-20"
                                                            />
                                                            <div>
                                                                <BodyCopy
                                                                    columnSubHeading
                                                                    margin20Bottom
                                                                    mobileFont14
                                                                    marginTop12
                                                                    margin8BottomMobile
                                                                >
                                                                    {__(
                                                                        'preactive.whats-next.email',
                                                                    )}
                                                                </BodyCopy>
                                                                {isMobile && (
                                                                    <BodyCopy
                                                                        styleName="word__br"
                                                                        columnDescription
                                                                        margin8Bottom
                                                                        mobileFont12
                                                                    >
                                                                        {__(
                                                                            'preactive.whats-next.email-not-verified-one',
                                                                        )}
                                                                        {
                                                                            account.emailAddress
                                                                        }
                                                                        {'.'}
                                                                    </BodyCopy>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {!isMobile && (
                                                            <BodyCopy
                                                                styleName="word__br"
                                                                columnDescription
                                                                margin8Bottom
                                                                mobileFont12
                                                            >
                                                                {__(
                                                                    'preactive.whats-next.email-not-verified-one',
                                                                )}
                                                                {
                                                                    account.emailAddress
                                                                }
                                                                {'.'}
                                                            </BodyCopy>
                                                        )}
                                                        <Button
                                                            onClick={() =>
                                                                resendVerificationEmail()
                                                            }
                                                            label={__(
                                                                'preactive.whats-next.email-not-verified-link',
                                                            )}
                                                        />
                                                    </div>
                                                    <div styleName="item-status">
                                                        <img
                                                            src={emptyCheckIcon}
                                                            alt="empty-check"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Hidden>
                                        {/* /when={account.emailVerified === true} */}
                                        <Hidden
                                            until={
                                                account.emailVerified === true
                                            }
                                        >
                                            <Col width={6} mobileWidth={12}>
                                                <div styleName="whats-next-item">
                                                    <div styleName="item-content-margin">
                                                        <div styleName="item-title">
                                                            <img
                                                                src={emailIcon}
                                                                alt="email-icon"
                                                                styleName="margin-bottom-20"
                                                            />
                                                            <div>
                                                                <BodyCopy
                                                                    columnSubHeading
                                                                    margin20Bottom
                                                                    mobileFont14
                                                                    marginTop12
                                                                    margin8BottomMobile
                                                                >
                                                                    {__(
                                                                        'preactive.whats-next.email',
                                                                    )}
                                                                </BodyCopy>
                                                                {isMobile && (
                                                                    <BodyCopy
                                                                        columnDescription
                                                                        margin8Bottom
                                                                        mobileFont12
                                                                    >
                                                                        {
                                                                            account.emailAddress
                                                                        }
                                                                        {__(
                                                                            'preactive.whats-next.email-verified-copy',
                                                                        )}
                                                                    </BodyCopy>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {!isMobile && (
                                                            <BodyCopy
                                                                columnDescription
                                                                margin8Bottom
                                                                mobileFont12
                                                            >
                                                                {
                                                                    account.emailAddress
                                                                }
                                                                {__(
                                                                    'preactive.whats-next.email-verified-copy',
                                                                )}
                                                            </BodyCopy>
                                                        )}
                                                    </div>
                                                    <div styleName="item-status">
                                                        <img
                                                            src={mintCheckIcon}
                                                            alt="mint-check"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Hidden>
                                        {/* /until={account.emailVerified === true} */}
                                        {/* /until={!!newMdn} */}
                                        {this.newMDNDisplay}
                                        {isMobile && <Divider margin30 />}
                                        {this.portPendingDisplay}
                                        {this.portIssueDisplay}
                                        {this.portCompleteDisplay}
                                    </Row>
                                </Col>
                            </Row>
                        </ContentWrapper>
                        {/* /Next steps: 1st row */}

                        {/* divider */}
                        <div styleName="showInDesktop">
                            <ContentWrapper noPadding>
                                <Row>
                                    <Col width={3} mobileWidth={12}>
                                        &nbsp;
                                    </Col>
                                    <Col width={9} mobileWidth={12}>
                                        <Divider margin40 />
                                    </Col>
                                </Row>
                            </ContentWrapper>
                        </div>
                        {/* /divider */}

                        {/* Next steps: 2nd row */}
                        <ContentWrapper>
                            <Row>
                                {!isMobile && (
                                    <Col width={3} mobileWidth={12}>
                                        <Headline
                                            three
                                            medium
                                            dark
                                            styleName="showInDesktop"
                                        >
                                            &nbsp;
                                        </Headline>
                                    </Col>
                                )}
                                {isMobile && <Divider margin30 />}
                                <Col width={9} mobileWidth={12}>
                                    <Row>
                                        <Col width={6} mobileWidth={12}>
                                            <div styleName="whats-next-item">
                                                <div styleName="item-content">
                                                    <div styleName="item-title">
                                                        <img
                                                            src={
                                                                squareSmileIcon
                                                            }
                                                            styleName="margin-bottom-65"
                                                            alt="square-smiley"
                                                        />
                                                        <div>
                                                            <BodyCopy
                                                                columnSubHeading
                                                                margin20Bottom
                                                                mobileFont14
                                                                marginTop12
                                                                noMarginTopMobile
                                                                margin8BottomMobile
                                                            >
                                                                {__(
                                                                    'preactive.whats-next.download',
                                                                )}
                                                            </BodyCopy>
                                                            {isMobile && (
                                                                <BodyCopy
                                                                    columnDescription
                                                                    margin35Bottom
                                                                    mobileFont12
                                                                >
                                                                    {__(
                                                                        'preactive.whats-next.download-copy',
                                                                    )}
                                                                </BodyCopy>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {!isMobile && (
                                                        <BodyCopy
                                                            columnDescription
                                                            margin35Bottom
                                                            mobileFont12
                                                        >
                                                            {__(
                                                                'preactive.whats-next.download-copy',
                                                            )}
                                                        </BodyCopy>
                                                    )}
                                                    <div styleName="download-link">
                                                        <a
                                                            target="_blank"
                                                            href="https://itunes.apple.com/us/app/visible-phone-service/id1367950045?ls=1&mt=8"
                                                        >
                                                            <img src="/assets/images/home/app-store-badge.svg" />
                                                        </a>
                                                        <a
                                                            target="_blank"
                                                            href="https://play.google.com/store/apps/details?id=com.visiblemobile.flagship"
                                                        >
                                                            <img src="/assets/images/home/google-play-badge.svg" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div styleName="item-status">
                                                    <img
                                                        src={emptyCheckIcon}
                                                        alt="empty-check"
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                        <Hidden when={!!newMdn}>
                                            {isMobile && <Divider margin30 />}
                                            <Col width={6} mobileWidth={12}>
                                                <div styleName="whats-next-item-no-border">
                                                    <div styleName="item-content-margin">
                                                        <div styleName="item-title">
                                                            <img
                                                                src={phoneIcon}
                                                                alt="phone"
                                                                styleName="margin-bottom-65"
                                                            />
                                                            <div>
                                                                <BodyCopy
                                                                    columnSubHeading
                                                                    margin20Bottom
                                                                    mobileFont14
                                                                    marginTop12
                                                                    noMarginTopMobile
                                                                    margin8BottomMobile
                                                                >
                                                                    {__(
                                                                        'preactive.whats-next.install',
                                                                    )}
                                                                </BodyCopy>
                                                                {isMobile && (
                                                                    <>
                                                                        <BodyCopy
                                                                            columnDescription
                                                                            margin8Bottom
                                                                            mobileFont12
                                                                        >
                                                                            {__(
                                                                                'preactive.whats-next.install-copy',
                                                                            )}
                                                                        </BodyCopy>
                                                                        <BodyCopy
                                                                            columnDescription
                                                                            margin8Bottom
                                                                            mobileFont12
                                                                        >
                                                                            <a
                                                                                href={__(
                                                                                    'routes.learn-more-link',
                                                                                )}
                                                                            >
                                                                                {__(
                                                                                    'preactive.learn-link',
                                                                                )}
                                                                            </a>{' '}
                                                                            {__(
                                                                                'preactive.learn-description',
                                                                            )}
                                                                        </BodyCopy>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {!isMobile && (
                                                            <>
                                                                <BodyCopy
                                                                    columnDescription
                                                                    margin8Bottom
                                                                    mobileFont12
                                                                >
                                                                    {__(
                                                                        'preactive.whats-next.install-copy',
                                                                    )}
                                                                </BodyCopy>
                                                                <BodyCopy
                                                                    columnDescription
                                                                    margin8Bottom
                                                                    mobileFont12
                                                                >
                                                                    <a
                                                                        href={__(
                                                                            'routes.learn-more-link',
                                                                        )}
                                                                    >
                                                                        {__(
                                                                            'preactive.learn-link',
                                                                        )}
                                                                    </a>{' '}
                                                                    {__(
                                                                        'preactive.learn-description',
                                                                    )}
                                                                </BodyCopy>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div styleName="item-status">
                                                        <img
                                                            src={emptyCheckIcon}
                                                            alt="empty-check"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Hidden>
                                        {/* /when={!!newMdn} */}
                                        <Hidden until={!!newMdn}>
                                            {isMobile && <Divider margin30 />}
                                            <Col width={6} mobileWidth={12}>
                                                <div styleName="whats-next-item">
                                                    <div styleName="item-content">
                                                        <div styleName="item-title">
                                                            <img
                                                                src={phoneIcon}
                                                                alt="phone"
                                                                styleName="margin-bottom-65"
                                                            />
                                                            <BodyCopy
                                                                columnSubHeading
                                                                margin20Bottom
                                                                mobileFont14
                                                                marginTop12
                                                                noMarginTopMobile
                                                                margin8BottomMobile
                                                            >
                                                                {__(
                                                                    'preactive.whats-next.install',
                                                                )}
                                                            </BodyCopy>
                                                        </div>
                                                        <div>
                                                            <BodyCopy
                                                                columnDescription
                                                                margin8Bottom
                                                                mobileFont12
                                                            >
                                                                {__(
                                                                    'preactive.whats-next.install-copy',
                                                                )}
                                                            </BodyCopy>
                                                            {isMobile && (
                                                                <BodyCopy
                                                                    columnDescription
                                                                    margin8Bottom
                                                                    mobileFont12
                                                                >
                                                                    <a
                                                                        href={__(
                                                                            'routes.learn-more-link',
                                                                        )}
                                                                    >
                                                                        {__(
                                                                            'preactive.learn-link',
                                                                        )}
                                                                    </a>{' '}
                                                                    {__(
                                                                        'preactive.learn-description',
                                                                    )}
                                                                </BodyCopy>
                                                            )}
                                                        </div>
                                                        {!isMobile && (
                                                            <BodyCopy
                                                                columnDescription
                                                                margin8Bottom
                                                                mobileFont12
                                                            >
                                                                <a
                                                                    href={__(
                                                                        'routes.learn-more-link',
                                                                    )}
                                                                >
                                                                    {__(
                                                                        'preactive.learn-link',
                                                                    )}
                                                                </a>{' '}
                                                                {__(
                                                                    'preactive.learn-description',
                                                                )}
                                                            </BodyCopy>
                                                        )}
                                                    </div>
                                                    <div styleName="item-status">
                                                        <img
                                                            src={emptyCheckIcon}
                                                            alt="empty-check"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Hidden>
                                    </Row>
                                </Col>
                            </Row>
                        </ContentWrapper>
                        {configuration.toggles.enableGroups && (
                            <>
                                <div styleName="showInDesktop">
                                    <ContentWrapper noPadding>
                                        <Row>
                                            <Col width={3} mobileWidth={12}>
                                                &nbsp;
                                            </Col>
                                            <Col width={9} mobileWidth={12}>
                                                <Divider margin40 />
                                            </Col>
                                        </Row>
                                    </ContentWrapper>
                                </div>
                                <ContentWrapper>
                                    <Row>
                                        {!isMobile && (
                                            <Col width={3} mobileWidth={12}>
                                                <Headline
                                                    three
                                                    medium
                                                    dark
                                                    styleName="showInDesktop"
                                                >
                                                    &nbsp;
                                                </Headline>
                                            </Col>
                                        )}

                                        <Row>
                                            {isMobile && <Divider margin30 />}
                                            <Col width={6} mobileWidth={12}>
                                                <div styleName="whats-next-item">
                                                    <div styleName="item-content">
                                                        <div styleName="item-title">
                                                            <img
                                                                src={
                                                                    squareCommunityicon
                                                                }
                                                                styleName="margin-bottom-20"
                                                                alt="squarefriends"
                                                            />
                                                            <div>
                                                                <BodyCopy
                                                                    columnSubHeading
                                                                    margin20Bottom
                                                                    mobileFont14
                                                                    marginTop12
                                                                    noMarginTopMobile
                                                                    margin8BottomMobile
                                                                >
                                                                    {__(
                                                                        'preactive.save-money-with-party-pay',
                                                                    )}
                                                                </BodyCopy>
                                                                {isMobile && (
                                                                    <BodyCopy
                                                                        columnDescription
                                                                        margin8Bottom
                                                                        mobileFont12
                                                                    >
                                                                        {groups &&
                                                                        groups.GroupMembershipState ===
                                                                            'Invited'
                                                                            ? __(
                                                                                  'preactive.save-money.requested',
                                                                              )
                                                                            : __(
                                                                                  'preactive.save-money.preactive',
                                                                              )}
                                                                    </BodyCopy>
                                                                )}
                                                            </div>
                                                            <div styleName="item-status">
                                                                <img
                                                                    src={
                                                                        emptyCheckIcon
                                                                    }
                                                                    alt="empty-check"
                                                                />
                                                            </div>
                                                        </div>
                                                        {!isMobile && (
                                                            <BodyCopy
                                                                columnDescription
                                                                margin8Bottom
                                                                mobileFont12
                                                            >
                                                                {groups &&
                                                                groups.GroupMembershipState ===
                                                                    'Invited'
                                                                    ? __(
                                                                          'preactive.save-money.requested',
                                                                      )
                                                                    : __(
                                                                          'preactive.save-money.preactive',
                                                                      )}
                                                            </BodyCopy>
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                            {!isMobile && (
                                                <Col
                                                    width={6}
                                                    mobileWidth={12}
                                                />
                                            )}
                                        </Row>
                                    </Row>
                                </ContentWrapper>
                            </>
                        )}
                        {/* /Next steps: 2nd row */}
                    </>
                )}
                {/* /Next steps */}

                <Divider margin60 />
                <ContentWrapper>
                    <Row>
                        <Col width={3} mobileWidth={12}>
                            <Headline
                                medium
                                three
                                fontCircular
                                light
                                customSpacing
                                noMargin
                                mobileMarginBottom35
                            >
                                <div styleName="promo-heading">
                                    {__('preactive.promotions-header')}
                                </div>
                            </Headline>
                        </Col>
                        <Col width={9} mobileWidth={12}>
                            <Row>
                                {promoContents &&
                                    promoContents.length > 0 &&
                                    promoContents.map(promotion => {
                                        return (
                                            <Col
                                                key={promotion.promoCode}
                                                width={6}
                                                mobileWidth={12}
                                            >
                                                <div styleName="promo-content">
                                                    <img
                                                        styleName="promo-logo"
                                                        src={`/assets/images/promotions/${
                                                            promotion.promoCode
                                                        }.png`}
                                                    />
                                                    <div styleName="promo-offer">
                                                        <p styleName="offer-text-header">
                                                            {
                                                                JSON.parse(
                                                                    promotion.modalContent,
                                                                ).AccountsPage
                                                                    .TitleText
                                                            }
                                                        </p>
                                                        <p styleName="offer-text">
                                                            {
                                                                JSON.parse(
                                                                    promotion.modalContent,
                                                                ).AccountsPage
                                                                    .SubText
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </Col>
                    </Row>
                </ContentWrapper>
                <div>
                    <Divider />
                </div>
                {/* FAQ */}
                <ContentWrapper>
                    <Row>
                        <Col width={9} mobileWidth={12}>
                            <Headline
                                medium
                                three
                                fontCircular
                                light
                                customSpacing
                                margin16Bottom
                                noMarginTop
                            >
                                {__('preactive.faq-link.questions')}
                            </Headline>
                            <BodyCopy
                                columnDescription
                                margin8Bottom
                                mobileFont12
                                margin30BottomMobile
                            >
                                {__('preactive.faq-link.question-description')}
                            </BodyCopy>
                        </Col>

                        <Col width={3} mobileWidth={12}>
                            <InfoLink
                                to={`${__('routes.help')}`}
                                plain
                                fullWidth
                            >
                                <Button
                                    invert
                                    secondary
                                    fullWidth
                                    styleName="margin-bottom-20"
                                    label={__('preactive.faq-link.visit-help')}
                                />
                            </InfoLink>
                        </Col>
                    </Row>
                </ContentWrapper>
                {/* /FAQ */}
            </>
        );
    }

    private get portIssueDisplay() {
        const { portIssue, account, cart } = this.props;
        const sim = cart
            ? (cart.itemForProductType(ProductType.SIM) as SIMCartItem)
            : null;
        const newMdn = sim && sim.mdnType === MDNType.NEW ? true : null;
        return (
            <Hidden
                until={
                    !newMdn &&
                    account.portStatus === PortStatus.ISSUE &&
                    !!portIssue
                }
            >
                <Col width={6} mobileWidth={12}>
                    <div styleName="whats-next-item">
                        <div styleName="item-content">
                            <div styleName="item-title">
                                <img src={yieldIcon} alt="yield" />
                                <Subhead medium error>
                                    {portIssue &&
                                        portIssue.additionalProps.header}
                                </Subhead>
                            </div>
                            <BodyCopy grey>
                                {portIssue && portIssue.additionalProps.body}
                            </BodyCopy>
                            <Button
                                fullWidth
                                error
                                label={
                                    portIssue &&
                                    portIssue.additionalProps.actionOneLabel
                                }
                                onClick={
                                    portIssue &&
                                    portIssue.additionalProps.actionOne
                                }
                            />
                            <Block>
                                <BodyCopy medium grey>
                                    <CountDown
                                        mdnCounterEnabled={
                                            portIssue &&
                                            portIssue.additionalProps
                                                .mdnCounterEnabled
                                        }
                                        pinCounterEnabled={
                                            portIssue &&
                                            portIssue.additionalProps
                                                .pinCounterEnabled
                                        }
                                    />
                                </BodyCopy>
                            </Block>
                        </div>
                        <div styleName="item-status">
                            <img src={emptyCheckIcon} alt="empty-check" />
                        </div>
                    </div>
                </Col>
            </Hidden>
        );
    }

    private get newMDNDisplay() {
        const { cart } = this.props;
        const sim = cart
            ? (cart.itemForProductType(ProductType.SIM) as SIMCartItem)
            : null;
        const newMdn = sim && sim.mdnType === MDNType.NEW ? true : null;
        const mdn =
            sim && sim.mdnType === MDNType.NEW && normalizeNumber(sim.mdn);
        return (
            <Hidden until={!!newMdn}>
                <Col width={6} mobileWidth={12}>
                    <div styleName="whats-next-item">
                        <div styleName="item-content">
                            <div styleName="item-title">
                                <img src={thumbsUpIcon} alt="palm" />
                                <Subhead medium>
                                    {__('preactive.whats-next.new-mdn')}
                                </Subhead>
                            </div>
                            <BodyCopy grey>
                                {__('preactive.whats-next.new-mdn-copy')} {mdn}
                                {'.'}
                            </BodyCopy>
                        </div>
                        <div styleName="item-status">
                            <img src={mintCheckIcon} alt="mint-check" />
                        </div>
                    </div>
                </Col>
            </Hidden>
        );
    }

    private get portPendingDisplay() {
        const { account, cart, isMobile } = this.props;
        const sim = cart
            ? (cart.itemForProductType(ProductType.SIM) as SIMCartItem)
            : null;
        const newMdn = sim && sim.mdnType === MDNType.NEW ? true : null;
        return (
            <Hidden
                until={
                    !newMdn &&
                    account.portStatus !== PortStatus.ISSUE &&
                    account.portStatus !== PortStatus.COMPLETE
                }
            >
                <Col width={6} mobileWidth={12}>
                    <div styleName="whats-next-item-no-border">
                        <div styleName="item-content-margin">
                            <div styleName="item-title">
                                <img
                                    src={palmIcon}
                                    alt="palm"
                                    styleName="margin-bottom-35"
                                />
                                <div>
                                    <BodyCopy
                                        columnSubHeading
                                        mobileFont14
                                        noMarginTop
                                        margin8Bottom
                                    >
                                        {__('preactive.whats-next.hang')}
                                    </BodyCopy>
                                    {isMobile && (
                                        <BodyCopy
                                            columnDescription
                                            mobileFont12
                                        >
                                            {__(
                                                'preactive.whats-next.hang-copy-one',
                                            )}
                                        </BodyCopy>
                                    )}
                                </div>
                            </div>
                            {!isMobile && (
                                <BodyCopy
                                    columnDescription
                                    margin8Bottom
                                    mobileFont12
                                >
                                    {__('preactive.whats-next.hang-copy-one')}
                                </BodyCopy>
                            )}
                        </div>
                        <div styleName="item-status">
                            <img src={emptyCheckIcon} alt="empty-check" />
                        </div>
                    </div>
                </Col>
            </Hidden>
        );
    }

    private get portCompleteDisplay() {
        const { account } = this.props;
        return (
            <Hidden
                until={
                    !!account.portStatus &&
                    account.portStatus === PortStatus.COMPLETE
                }
            >
                <Col width={6} mobileWidth={12}>
                    <div styleName="whats-next-item">
                        <div styleName="item-content">
                            <div styleName="item-title">
                                <img src={thumbsUpIcon} alt="palm" />
                                <Subhead medium>
                                    {__('preactive.whats-next.port-complete')}
                                </Subhead>
                            </div>
                            <BodyCopy grey>
                                {__('preactive.whats-next.port-active-number')}
                            </BodyCopy>
                        </div>
                        <div styleName="item-status">
                            <img src={mintCheckIcon} alt="mint-check" />
                        </div>
                    </div>
                </Col>
            </Hidden>
        );
    }
}

export { PreActive };
