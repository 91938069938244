import { createAction, IAction } from 'lib/br-redux';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    GET_CLIENT_TOKEN = 'PAYMENT/GET_CLIENT_TOKEN',
    LOAD_CLIENT_TOKEN = 'PAYMENT/LOAD_CLIENT_TOKEN',
    REPORT_CLIENT_TOKEN_ERROR = 'PAYMENT/REPORT_CLIENT_TOKEN_ERROR',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type IGetClientTokenAction = IAction<ActionType.GET_CLIENT_TOKEN>;
type ILoadClientTokenAction = IAction<ActionType.LOAD_CLIENT_TOKEN, string>;
type IReportClientTokenErrorAction = IAction<
    ActionType.REPORT_CLIENT_TOKEN_ERROR,
    string
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IClientTokenAction =
    | IGetClientTokenAction
    | ILoadClientTokenAction
    | IReportClientTokenErrorAction;

const getClientToken = createAction(ActionType.GET_CLIENT_TOKEN);
const loadClientToken = createAction(
    ActionType.LOAD_CLIENT_TOKEN,
    (tkn: string) => tkn,
);
const reportClientTokenError = createAction(
    ActionType.REPORT_CLIENT_TOKEN_ERROR,
    (er: string) => er,
);

export {
    ActionType,
    getClientToken,
    IGetClientTokenAction,
    ILoadClientTokenAction,
    IClientTokenAction,
    IReportClientTokenErrorAction,
    loadClientToken,
    reportClientTokenError,
};
