// tslint:disable:no-empty
import { createBrowserHistory, History } from 'history';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore, Middleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import logger from 'redux-logger';
import { Persistor, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { IApplicationState } from 'models/application-state';
import rootReducer from './root.reducer';
import rootSaga from './root.saga';

// tslint:disable-next-line:readonly-array
const conditionalMiddleware: Middleware[] = [];
const sagaMiddleware = createSagaMiddleware();
const history = createBrowserHistory();

if (
    process.env.REDUX_LOGGER_ENABLED === 'true' &&
    process.env.NODE_ENV === 'development'
) {
    conditionalMiddleware.push(logger);
}

/**
 * Configures the starting state for the application.
 * @param preloadedState for the state startup.
 * @returns {Store<S>} initial state.
 */
const configureStore = (
    preloadedState: any = {},
): [Store<Partial<IApplicationState>>, History, Persistor] => {
    const enhancers: ReadonlyArray<any> = [
        sagaMiddleware,
        ...conditionalMiddleware,
        routerMiddleware(history),
    ];

    const middleware = applyMiddleware(...enhancers);
    const store = createStore(
        rootReducer,
        preloadedState,
        composeWithDevTools(middleware),
    );

    sagaMiddleware.run(rootSaga);

    if ((module as any).hot) {
        // Enable Webpack hot module replacement for reducers
        (module as any).hot.accept('./root.reducer', () => {
            const nextRootReducer = require('./root.reducer').default;
            store.replaceReducer(nextRootReducer);
        });
    }

    return [store, history, persistStore(store)];
};

export default configureStore;
