import { createAction, IAction } from 'lib/br-redux';
import {
    FetchedChargeEventDetailsResponse,
    IBillHistoryResponse,
    INextDueDate,
} from 'models';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------
enum ActionType {
    FETCH_BILL_HISTORY = 'BILL/FETCH_BILL_HISTORY',
    REPORT_FETCH_BILL_HISTORY_ERROR = 'BILL/REPORT_FETCH_BILL_HISTORY_ERROR',
    REPORT_FETCH_BILL_HISTORY_SUCCESS = 'BILL/REPORT_FETCH_BILL_HISTORY_SUCCESS',

    FETCH_CURRENT_BILL = 'BILL/FETCH_CURRENT_BILL',
    REPORT_FETCH_CURRENT_BILL_ERROR = 'BILL/REPORT_FETCH_CURRENT_BILL_ERROR',
    REPORT_FETCH_CURRENT_BILL_SUCCESS = 'BILL/REPORT_FETCH_CURRENT_BILL_SUCCESS',

    FETCH_BILL_DETAILS = 'BILL/FETCH_BILL_DETAILS',
    REPORT_FETCH_BILL_DETAILS_ERROR = 'BILL/REPORT_FETCH_BILL_DETAILS_ERROR',
    REPORT_FETCH_BILL_DETAILS_SUCCESS = 'BILL/REPORT_FETCH_BILL_DETAILS_SUCCESS',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------
type IFetchBillHistoryAction = IAction<ActionType.FETCH_BILL_HISTORY>;
type IFetchBillHistorySuccessAction = IAction<
    ActionType.REPORT_FETCH_BILL_HISTORY_SUCCESS,
    IBillHistoryResponse
>;
type IFetchBillHistoryErrorAction = IAction<
    ActionType.REPORT_FETCH_BILL_HISTORY_ERROR,
    string
>;

type IFetchCurrentBillAction = IAction<ActionType.FETCH_CURRENT_BILL>;
type IReportFetchCurrentBillSuccessAction = IAction<
    ActionType.REPORT_FETCH_CURRENT_BILL_SUCCESS,
    INextDueDate
>;
type IReportFetchCurrentBilErrorAction = IAction<
    ActionType.REPORT_FETCH_CURRENT_BILL_ERROR,
    string
>;

type IFetchBillDetailsAction = IAction<ActionType.FETCH_BILL_DETAILS, string>;
type IReportFetchBillDetailsSuccessAction = IAction<
    ActionType.REPORT_FETCH_BILL_DETAILS_SUCCESS,
    {
        readonly eventId: string;
        readonly detail: ReadonlyArray<FetchedChargeEventDetailsResponse>;
    }
>;
interface BillDetailsErrorPayload {
    readonly error: string;
    readonly id: string;
}
type IReportFetchBillDetailsErrorAction = IAction<
    ActionType.REPORT_FETCH_BILL_DETAILS_ERROR,
    BillDetailsErrorPayload
>;

// ------------------------------------
// Action Union Type
// ------------------------------------
type BillAction =
    | IFetchBillHistoryAction
    | IFetchBillHistoryErrorAction
    | IFetchBillHistorySuccessAction
    | IFetchCurrentBillAction
    | IReportFetchCurrentBilErrorAction
    | IReportFetchCurrentBillSuccessAction
    | IFetchBillDetailsAction
    | IReportFetchBillDetailsErrorAction
    | IReportFetchBillDetailsSuccessAction;

// ------------------------------------
// Action Creators
// i.e. the stuff that actually makes it to the compiled Javascript
// ------------------------------------
const fetchBillHistory = createAction(ActionType.FETCH_BILL_HISTORY);
const fetchBillHistorySuccess = createAction(
    ActionType.REPORT_FETCH_BILL_HISTORY_SUCCESS,
    (billHistory: IBillHistoryResponse) => billHistory,
);
const fetchBillHistoryError = createAction(
    ActionType.REPORT_FETCH_BILL_HISTORY_ERROR,
    (error: string) => error,
);

const fetchCurrentBill = createAction(ActionType.FETCH_CURRENT_BILL);
const reportFetchCurrentBillSuccess = createAction(
    ActionType.REPORT_FETCH_CURRENT_BILL_SUCCESS,
    (currentBill: INextDueDate) => currentBill,
);
const reportFetchCurrentBillError = createAction(
    ActionType.REPORT_FETCH_CURRENT_BILL_ERROR,
    (error: string) => error,
);

const fetchBillDetails = createAction(
    ActionType.FETCH_BILL_DETAILS,
    (eventId: string) => eventId,
);

interface BillDetailsToLoad {
    readonly eventId: string;
    readonly detail: FetchedChargeEventDetailsResponse;
}

const loadBillDetails = createAction(
    ActionType.REPORT_FETCH_BILL_DETAILS_SUCCESS,
    (payload: BillDetailsToLoad) => payload,
);
const reportFetchBillDetailsError = createAction(
    ActionType.REPORT_FETCH_BILL_DETAILS_ERROR,
    (payload: BillDetailsErrorPayload) => payload,
);

// ------------------------------------
// Exports
// ------------------------------------
export {
    ActionType,
    BillAction,
    fetchBillHistory,
    fetchBillHistoryError,
    fetchBillHistorySuccess,
    fetchCurrentBill,
    IFetchBillHistoryAction,
    IFetchBillHistoryErrorAction,
    IFetchBillHistorySuccessAction,
    IFetchBillDetailsAction,
    IReportFetchBillDetailsSuccessAction,
    IReportFetchBillDetailsErrorAction,
    reportFetchCurrentBillError,
    reportFetchCurrentBillSuccess,
    fetchBillDetails,
    reportFetchBillDetailsError,
    loadBillDetails,
};
