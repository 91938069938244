import { withAnalytics } from 'components/common/analytics';
import { withDeviceType } from 'components/common/device-type';
import { UpdateAddressModal } from 'components/common/modal';
import { withModal } from 'components/common/modal/withModal';
import { compose } from 'redux';

const enhance = compose(
    withAnalytics,
    withDeviceType,
    withModal,
);
const UpdateAddressModalContainer = enhance(UpdateAddressModal);

export { UpdateAddressModalContainer };
