import { ModalManager } from 'components/common/modal/modal-manager';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ViewActions } from 'state/view';

const mapStateToProps = (state: IApplicationState) => ({
    modalQueue: state.view.modalQueue,
});

const mapDispatchToProps = dispatch => ({
    dismissModal: () => dispatch(ViewActions.dismissModal()),
});

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const ModalManagerContainer = enhance(ModalManager);

export { ModalManagerContainer };
