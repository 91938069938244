import * as React from 'react';

import {
    // ProspectiveContainer as Prospective,
    ApplicationHomeContainer as ApplicationHome,
} from 'containers';
import { Account, CustomerState, IApplicationState } from 'models';
import { IPurchaseState } from 'state/purchase/purchase.reducer';

interface Props extends IPurchaseState {
    readonly account: Account;
}

class Home extends React.Component<Props, IApplicationState> {
    render(): JSX.Element {
        const { account } = this.props;

        switch (account.customerState) {
            case CustomerState.TERMINATED:
                return (
                    <>
                        {
                            (window.location.href = __(
                                'routes.account.terminated',
                            ))
                        }
                    </>
                );
            default:
                return <ApplicationHome />;
        }
    }
}

export { Home };
