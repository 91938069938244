import * as React from 'react';

import { IQueueableModal } from 'models';

interface IProps {
    readonly modalQueue: ReadonlyArray<IQueueableModal>;
    dismissModal(): void;
}

class ModalManager extends React.Component<IProps, {}> {
    render() {
        if (this.nextModal) {
            return (
                <div>
                    {React.createElement(
                        this.nextModal.component as React.ComponentClass<any>,
                        {
                            ...this.nextModal.additionalProps,
                        },
                    )}
                </div>
            );
        } else {
            return <div />;
        }
    }

    private get nextModal(): IQueueableModal | null {
        if (this.props.modalQueue && this.props.modalQueue.length) {
            return this.props.modalQueue[0];
        }

        return null;
    }
}

export { ModalManager };
