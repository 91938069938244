import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { Route } from 'react-router-dom';

const styles = require('./content.less');

interface IProps {
    readonly routePath: string;
    readonly routeComponent: any;
}

const InPageNavContent = (props: IProps) => (
    <div styleName="content">
        <Route path={props.routePath} component={props.routeComponent} />
    </div>
);

export default CSSModules(InPageNavContent, styles, { allowMultiple: true });
