import * as moment from 'moment';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { RouteComponentProps } from 'react-router-dom';

import { ContentWrapper } from 'components/common/content-wrapper';
import { Divider } from 'components/common/divider';
import { Dots } from 'components/common/dots';
import { Chevron } from 'components/common/graphics';
import { Hidden } from 'components/common/hidden';
import {
    BodyCopy,
    Col,
    Headline,
    InfoLink,
    Row,
    ErrorMsg,
} from 'components/common/typography';
import { FetchStatus } from 'lib/br-redux';
import {
    Account,
    AOAccountOrderResponse,
    OrderDetailResponse,
    PurchaseType,
    IDeviceTypeProps,
} from 'models';

const styles = require('./order-details.less');

interface Props extends RouteComponentProps<any>, IDeviceTypeProps {
    readonly account: Account;
    readonly orderHistories: ReadonlyArray<AOAccountOrderResponse>;
    readonly orderDetails: OrderDetailResponse;
    readonly fetchStatus: FetchStatus;
    readonly orderNumber: string;
    fetchOrderDetails(orderNumber: string): OrderDetailResponse;
}
enum OrderDetailsConstant {
    insurance = 'INSURANCE',
    plan = 'PLAN',
    financing = 'FINANCING',
    handset = 'HANDSET',
    promotions = 'PROMOTIONS',
    sim = 'SIM',
}

@CSSModules(styles)
class OrderDetails extends React.Component<Props> {
    componentDidMount() {
        const { fetchOrderDetails, orderNumber } = this.props;
        fetchOrderDetails(orderNumber);
    }

    // TODO: Remove/Replace this function when API returns Color correctly in orderDetails
    getDeviceColor(orderHistories) {
        const handsetItem = orderHistories[0].Items.filter(item => {
            return String(item.Family) === OrderDetailsConstant.handset;
        });

        return handsetItem[0].Color;
    }
    render() {
        const {
            account: {
                firstName,
                lastName,
                address: { shipping_address },
            },
            fetchStatus,
            orderDetails,
            orderHistories,
            isMobile,
        } = this.props;
        if (!orderDetails || !orderDetails.Order || !orderDetails.Order.Item) {
            return null;
        }
        const detailsAreReady =
            fetchStatus === FetchStatus.SUCCESS ||
            fetchStatus === FetchStatus.NOT_FETCHED;
        const detailsAreBeingFetched = new Set([FetchStatus.FETCHING]).has(
            fetchStatus,
        );
        const detailsDidError = fetchStatus === FetchStatus.ERROR;
        const isByod =
            orderDetails.Order.PurchaseType === PurchaseType.BYOD ||
            orderDetails.Order.ReasonCode === 'SIM Re-Order';

        const handsetItem = orderDetails.Order.Item.filter(item => {
            return String(item.ProductFamily) === OrderDetailsConstant.handset;
        });

        const simItem = orderDetails.Order.Item.filter(item => {
            return String(item.ProductFamily) === OrderDetailsConstant.sim;
        });

        if (
            (!handsetItem || handsetItem.length === 0) &&
            (!simItem || simItem.length === 0)
        ) {
            return <ErrorMsg>{__('not-available-error')}</ErrorMsg>;
        }
        const { ProductName, ProductMake } = handsetItem[0];

        const ValuationType =
            handsetItem[0].ValuationType === 'NEW' ? '' : 'Pre-Owned ';

        const displayTotal = item => {
            if (item.ProductFamily === OrderDetailsConstant.promotions) {
                return item.PromotionStatus;
            }
            return item.OneTimeTotal === 0 || item.OneTimeTotal === undefined
                ? __('order_details.free')
                : this.currency(item.OneTimeTotal);
        };

        const taxListItem = orderDetails.Order.Item.filter(item => {
            if (
                item.ProductFamily === OrderDetailsConstant.handset &&
                item.taxList &&
                item.taxList.length > 0
            ) {
                return item.taxList;
            }
        });
        const taxListItems =
            taxListItem && taxListItem.length > 0
                ? taxListItem[0].taxList
                : null;
        let promoListItems = [] as any;
        let disclaimerItems = [] as any;
        if (
            orderDetails.Order.PROMO_CONTENTS &&
            orderDetails.Order.PROMO_CONTENTS.length > 0
        ) {
            for (var value of orderDetails.Order.PROMO_CONTENTS) {
                const content = JSON.parse(value.modalContent);
                promoListItems.push(content.AccountsPage);
                disclaimerItems.push(
                    content.Attributes.Contents['Disclaimer'][0].Value,
                );
            }
        }
        return (
            <>
                <Hidden until={detailsDidError}>
                    <BodyCopy>{__('default-error-message')}</BodyCopy>
                </Hidden>
                <Hidden until={detailsAreBeingFetched}>
                    <Dots invert />
                </Hidden>
                <Hidden until={detailsAreReady}>
                    <div styleName="order-details-page">
                        <ContentWrapper>
                            <Row>
                                <Col width={6} mobileWidth={6}>
                                    <InfoLink
                                        styleName="order-details-back-link-wrapper"
                                        plain
                                        to={`${__(
                                            'routes.account.order-history',
                                        )}`}
                                    >
                                        <BodyCopy flex styleName="route-link">
                                            <Chevron blue left />
                                            {__('order_details.order-history')}
                                        </BodyCopy>
                                    </InfoLink>
                                </Col>
                            </Row>
                        </ContentWrapper>
                        <ContentWrapper>
                            <Row>
                                <Col width={3} mobileWidth={12}>
                                    <Headline medium styleName="main-heading">
                                        {__('order_details.order-number')}
                                        {orderDetails.Order.OrderNumber}
                                    </Headline>
                                </Col>

                                <Col width={6} mobileWidth={12}>
                                    <div styleName="detail-col-margin">
                                        <BodyCopy
                                            columnSubHeading
                                            margin20Bottom
                                        >
                                            {__('order_details.address')}
                                        </BodyCopy>
                                        <BodyCopy columnDescription>
                                            {firstName} {lastName}
                                        </BodyCopy>
                                        <BodyCopy columnDescription>
                                            {shipping_address!.street_line1},{' '}
                                            {shipping_address!.street_line2}
                                            {shipping_address!.street_line2
                                                ? ' , '
                                                : ' '}
                                            {shipping_address!.city}{' '}
                                            {shipping_address!.state}{' '}
                                            {shipping_address!.zip}
                                        </BodyCopy>
                                    </div>
                                </Col>
                                <Col width={3} mobileWidth={12}>
                                    <BodyCopy columnSubHeading margin20Bottom>
                                        {__('order_details.date')}
                                    </BodyCopy>
                                    <BodyCopy grey columnDescription>
                                        {moment(
                                            orderDetails.Order.OrderDate,
                                        ).format('MMM D, YYYY')}
                                    </BodyCopy>
                                </Col>
                            </Row>
                        </ContentWrapper>
                        <Divider />
                        <ContentWrapper>
                            <Row>
                                <Col width={3} mobileWidth={12}>
                                    <Headline medium styleName="main-heading">
                                        {__('order_details.product')}
                                    </Headline>
                                </Col>

                                <Col width={7} mobileWidth={12}>
                                    <div styleName="detail-col-margin">
                                        {ProductMake && ProductMake.length > 0 && (
                                            <BodyCopy
                                                columnSubHeading
                                                margin20Bottom
                                            >
                                                {`${ValuationType}${ProductMake} ${ProductName}`}
                                            </BodyCopy>
                                        )}
                                        <ContentWrapper noPadding>
                                            <Row>
                                                <Col
                                                    width={12}
                                                    mobileWidth={12}
                                                >
                                                    <>
                                                        <BodyCopy
                                                            columnDescription
                                                        >
                                                            {handsetItem[0]
                                                                .ProductSize
                                                                ? this.getDeviceColor(
                                                                      orderHistories,
                                                                  ) +
                                                                  ' ' +
                                                                  handsetItem[0]
                                                                      .ProductSize
                                                                : handsetItem[0]
                                                                      .ProductName}
                                                        </BodyCopy>
                                                    </>
                                                    {isByod ? (
                                                        ''
                                                    ) : (
                                                        <BodyCopy
                                                            columnDescription
                                                            bold
                                                        >
                                                            {__(
                                                                'order_details.sales-tax',
                                                            )}
                                                        </BodyCopy>
                                                    )}
                                                    {taxListItems &&
                                                    taxListItems.length > 0
                                                        ? taxListItems.map(
                                                              items => (
                                                                  <>
                                                                      <BodyCopy
                                                                          columnDescription
                                                                          styleName="text-indent"
                                                                      >
                                                                          {
                                                                              items.desc
                                                                          }
                                                                      </BodyCopy>
                                                                  </>
                                                              ),
                                                          )
                                                        : ''}
                                                    <BodyCopy
                                                        columnDescription
                                                        styleName="total-price"
                                                    >
                                                        {__(
                                                            'order_details.total',
                                                        )}{' '}
                                                        {!isByod &&
                                                        (handsetItem[0]
                                                            .ProductMethod ===
                                                            'Affirm' ||
                                                            handsetItem[0]
                                                                .ProductMethod ===
                                                                'Paypal')
                                                            ? __(
                                                                  'order_details.not.paid.card',
                                                              ).replace(
                                                                  ':PaymentMethod:',
                                                                  handsetItem[0]
                                                                      .ProductMethod,
                                                              )
                                                            : isByod
                                                            ? ''
                                                            : handsetItem[0]
                                                                  .LastFour
                                                            ? __(
                                                                  'order_details.paid-with-visa-ending-in',
                                                              ).replace(
                                                                  ':PaymentMethod:',
                                                                  handsetItem[0]
                                                                      .ProductMethod,
                                                              )
                                                            : handsetItem[0]
                                                                  .LastFour !==
                                                              undefined
                                                            ? __(
                                                                  'order_details.not.paid.card',
                                                              ).replace(
                                                                  ':PaymentMethod:',
                                                                  handsetItem[0]
                                                                      .ProductMethod,
                                                              )
                                                            : ''}
                                                        {
                                                            handsetItem[0]
                                                                .LastFour
                                                        }
                                                    </BodyCopy>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    width={12}
                                                    mobileWidth={12}
                                                >
                                                    {/* for BYOD */}

                                                    <>
                                                        <>
                                                            <BodyCopy
                                                                right
                                                                columnDescription
                                                            >
                                                                {handsetItem &&
                                                                    displayTotal(
                                                                        handsetItem[0],
                                                                    )}
                                                            </BodyCopy>
                                                        </>
                                                        {taxListItems &&
                                                        taxListItems.length > 0
                                                            ? taxListItems.map(
                                                                  items => (
                                                                      <>
                                                                          <BodyCopy
                                                                              columnDescription
                                                                              right
                                                                          >
                                                                              {this.currency(
                                                                                  String(
                                                                                      items.value,
                                                                                  ),
                                                                              )}
                                                                          </BodyCopy>
                                                                      </>
                                                                  ),
                                                              )
                                                            : ''}
                                                        <BodyCopy
                                                            columnDescription
                                                            styleName="total-price"
                                                            right
                                                        >
                                                            {isByod
                                                                ? '$0.00'
                                                                : this.currency(
                                                                      String(
                                                                          orderDetails
                                                                              .Order
                                                                              .OrderTotal,
                                                                      ),
                                                                  )}
                                                        </BodyCopy>
                                                    </>
                                                </Col>

                                                {isByod ? (
                                                    ''
                                                ) : (
                                                    <div styleName="showInDesktop">
                                                        {handsetItem[0] &&
                                                            handsetItem[0]
                                                                .image && (
                                                                <Col
                                                                    width={6}
                                                                    mobileWidth={
                                                                        12
                                                                    }
                                                                >
                                                                    <img
                                                                        id="deviceImage"
                                                                        styleName="device__scale"
                                                                        src={
                                                                            handsetItem &&
                                                                            handsetItem[0]
                                                                                .image
                                                                        }
                                                                        onError={
                                                                            this
                                                                                .imageFailedtoLoad
                                                                        }
                                                                    />
                                                                </Col>
                                                            )}
                                                    </div>
                                                )}
                                            </Row>
                                        </ContentWrapper>
                                    </div>
                                    <Divider medium />
                                    <ContentWrapper noPadding>
                                        <div styleName="desc-col-margin">
                                            <Row>
                                                <Col width={6} mobileWidth={12}>
                                                    <BodyCopy
                                                        columnSubHeading
                                                        styleName="margin-0"
                                                    >
                                                        {simItem &&
                                                        simItem.length > 0
                                                            ? simItem[0]
                                                                  .ProductName
                                                            : ''}
                                                    </BodyCopy>
                                                </Col>
                                                <Col width={6} mobileWidth={12}>
                                                    <BodyCopy
                                                        columnDescription
                                                        right
                                                        styleName="mobile-align-left"
                                                    >
                                                        {simItem &&
                                                            simItem.length >
                                                                0 &&
                                                            displayTotal(
                                                                simItem[0],
                                                            )}
                                                    </BodyCopy>
                                                </Col>
                                            </Row>
                                        </div>
                                    </ContentWrapper>
                                    {promoListItems && promoListItems.length > 0
                                        ? promoListItems.map(item => (
                                              <>
                                                  <Divider custom />
                                                  <ContentWrapper noPadding>
                                                      <Row>
                                                          <Col
                                                              width={6}
                                                              mobileWidth={12}
                                                          >
                                                              <BodyCopy
                                                                  columnSubHeading
                                                                  styleName="margin-0"
                                                              >
                                                                  {
                                                                      item.TitleText
                                                                  }
                                                              </BodyCopy>
                                                          </Col>
                                                          <Col
                                                              width={6}
                                                              mobileWidth={12}
                                                          >
                                                              <BodyCopy
                                                                  columnDescription
                                                                  right
                                                                  styleName="mobile-align-left"
                                                              >
                                                                  {item.SubText}
                                                              </BodyCopy>
                                                          </Col>
                                                      </Row>
                                                      {/* <Row>
                                                <Col width={6} mobileWidth={12}>
                                                    <BodyCopy
                                                        columnDescription
                                                        right
                                                    >
                                                        {item.SubText}
                                                    </BodyCopy>
                                                </Col>
                                            </Row> */}
                                                  </ContentWrapper>
                                              </>
                                          ))
                                        : ''}
                                </Col>
                            </Row>
                        </ContentWrapper>
                        {disclaimerItems && disclaimerItems.length && (
                            <>
                                <Divider margin60 />
                                <ContentWrapper>
                                    <Row>
                                        {!isMobile && (
                                            <Col width={3} mobileWidth={12} />
                                        )}
                                        <Col width={7} mobileWidth={12}>
                                            {disclaimerItems &&
                                            disclaimerItems.length > 0
                                                ? disclaimerItems.map(
                                                      (item, ind) => (
                                                          <BodyCopy
                                                              DisclaimerText
                                                              margin35Bottom={
                                                                  ind !==
                                                                  disclaimerItems.length -
                                                                      1
                                                              }
                                                              noMarginTop
                                                              dangerouslySetInnerHTML={{
                                                                  __html: item,
                                                              }}
                                                          />
                                                      ),
                                                  )
                                                : ''}
                                        </Col>
                                    </Row>
                                </ContentWrapper>
                            </>
                        )}
                    </div>
                </Hidden>
            </>
        );
    }
    private currency(numString: number | string): string {
        return `\$${(Number(100 * Number(numString)) / 100).toFixed(2)}`;
    }

    private readonly imageFailedtoLoad = () => {
        const i = document.getElementById('deviceImage');
        if (i) {
            i.setAttribute('src', __('home.generic-phone-img-src'));
        }
    };
}

export { OrderDetails };
