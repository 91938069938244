// tslint:disable:max-line-length
import * as React from 'react';

interface Props extends React.HTMLProps<SVGElement> {
    readonly invert?: boolean;
}

const Diamond = ({ invert, ...other }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="115"
        height="115"
        viewBox="0 0 115 115"
    >
        <g fill={invert ? '#ffffff' : '#1800FF'} fillRule="evenodd">
            <path d="M56.199 75.739l19.365-19.366L56.2 37.011 36.833 56.375l19.366 19.364zm45.494-19.366l-45.494 45.495-45.493-45.493 45.493-45.493 45.494 45.491zM24.116 11.058c0 6.108-4.952 11.058-11.06 11.058C6.95 22.116 2 17.166 2 11.058 2 4.952 6.95 0 13.056 0c6.108 0 11.06 4.952 11.06 11.058M112.263 11.058c0 6.108-4.952 11.058-11.058 11.058-6.108 0-11.058-4.95-11.058-11.058C90.147 4.952 95.097 0 101.205 0c6.106 0 11.058 4.952 11.058 11.058" />
        </g>
    </svg>
);

export { Diamond };
