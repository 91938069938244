import { User as FirebaseUser } from 'firebase/app';
import { IAppliedPromotion, IPromoContent } from './promo-contents.interface';
// import { read } from 'fs';

// Generated file. You can edit here or regenerate by running `yo br-web:model`.
// These model files are always expected to have a class and interface following
// the ClassName and 'I'+ClassName naming standard.

/**
 * Account Interface.
 */
interface IAddress {
    readonly street_line1: string;
    readonly street_line2?: string;
    readonly city: string;
    readonly state: string;
    readonly zip: string;
    readonly countryCode?: string;
}
interface IAccountAddress {
    readonly shipping_address: IAddress;
    readonly billing_address: IAddress;
}

interface IActivateAccountPayload {
    readonly campaignId?: string;
    readonly email: string;
    readonly firstName: string;
    readonly imei?: string;
    readonly lastName: string;
    readonly newUid?: string;
    readonly uid?: string;
    readonly transactionId?: string;
}

interface ICreateAccountPayload {
    readonly first_name: string;
    readonly last_name: string;
    readonly email_address: string;
    readonly shortCode?: string;
    readonly campaignId?: string;
}

interface INonNormalizedAccount {
    readonly first_name: string;
    readonly last_name: string;
    readonly email_address: string;
    readonly app_tc_version?: number;
    readonly app_tc_timestamp?: string;
    readonly autopay?: boolean;
    readonly address?: IAccountAddress;
    readonly accountid: string;
    readonly globalkey: string;
    readonly customerState: CustomerState;
    readonly phoneNumber?: string;
    readonly userDevice: string;
    readonly PrivacyPolicy: string;
    readonly PrivacyPolicyTime: string;
    readonly refCode?: string;
    readonly referralLink?: string;

    readonly inviteCode?: string;

    readonly terminateDate?: string;
    readonly portStatus?: string;
    readonly portRemarks?: string;
    readonly statusTransition?: string;
    readonly paymentFailure?: string;
    readonly activeAccRefCodeCounter?: string;
    readonly incorrectMDNPortRetryCounter?: string;
    readonly incorrectMDNPortRetryThreshold?: string;
    readonly incorrectAccountPinPortRetryCounter?: string;
    readonly incorrectAccountPinPortRetryThreshold?: string;
    // TODO(dan) Temporary.  Change to correct field when applied short code is persisted.
    readonly refCodeApplied?: string;
    // TODO(dan) Temporary.  Change to correct field when imei is persisted.
    readonly imei?: string;
    // TODO(dan) Temporary.  Change to correct field when campaignId is persisted.
    readonly campaignId?: string;
    // TODO(dan) Temporary.  Change to correct field when transactionId is persisted.
    readonly transactionId?: string;
    readonly isAddressSuggested?: boolean;
    readonly PROMO_CONTENTS?: IPromoContent[];
    readonly appliedPromotions?: IAppliedPromotion[];
}
interface IStepA {
    readonly step: number;
}
interface ICartRequest {
    readonly items: ReadonlyArray<ICartItems>;
}

interface ICartItems {
    readonly type?: string;
    readonly os?: string;
    readonly carrier?: string;
    readonly deviceName?: string;
    readonly imei?: string;
}

interface ICartResponse {
    readonly status: ICartResponseStatus;
    readonly content: ICartResponseContent;
}

interface ICartResponseStatus {
    readonly code: string;
    readonly msg: string;
}

interface ICartResponseContent {
    readonly action: string;
    readonly bag: ICartResponseBag;
    readonly cartCount: number;
    readonly conflict: IConflict;
}

interface ICartResponseBag {
    readonly id: string;
    readonly type: string;
    readonly expiresIn: number;
}

interface IConflict {
    readonly existing: ICartExisting;
    readonly new: ICartNew;
}

interface ICartExisting {
    readonly itemId: string;
    readonly type: string;
    readonly productId: string;
    readonly make: string;
    readonly name: string;
    readonly os: string;
    readonly seoName: string;
    readonly image: string;
    readonly preOwned: string;
    readonly skuPbeId: string;
    readonly prodCode: string;
    readonly capacity: string;
    readonly color: string;
    readonly condition: string;
    readonly carrier: string;
    readonly qty: number;
    readonly price: string;
    readonly childIds: string;
}

const DEFAULT_ICARTEXISTING: ICartExisting = {
    itemId: '',
    type: '',
    productId: '',
    make: '',
    name: '',
    os: '',
    seoName: '',
    image: '',
    preOwned: '',
    skuPbeId: '',
    prodCode: '',
    capacity: '',
    color: '',
    condition: '',
    carrier: '',
    qty: -1,
    price: '',
    childIds: '',
};

interface ICartNew {
    readonly type: string;
    readonly make: string;
    readonly name: string;
    readonly os: string;
    readonly image: string;
    readonly capacity: string;
    readonly color: string;
    readonly condition: string;
    readonly carrier: string;
    readonly qty: string;
    readonly price: string;
}

const DEFAULT_CARTNEW: ICartNew = {
    type: '',
    make: '',
    name: '',
    os: '',
    image: '',
    capacity: '',
    color: '',
    condition: '',
    carrier: '',
    qty: '',
    price: '',
};

const DEFAULT_ICART_RESPONSE_BAG_STATE: ICartResponseBag = {
    id: '',
    type: '',
    expiresIn: 0,
};

const DEFAULT_CONFLICT_STATE: IConflict = {
    existing: DEFAULT_ICARTEXISTING,
    new: DEFAULT_CARTNEW,
};

const DEFAULT_ICART_RESPONSE_CONTENT: ICartResponseContent = {
    action: '',
    bag: DEFAULT_ICART_RESPONSE_BAG_STATE,
    cartCount: -1,
    conflict: DEFAULT_CONFLICT_STATE,
};

const DEFAULT_ICART_RESPONSE_STATUS: ICartResponseStatus = {
    code: '',
    msg: '',
};

const DEFAULT_ICART_RESPONSE_STATE: ICartResponse = {
    status: DEFAULT_ICART_RESPONSE_STATUS,
    content: DEFAULT_ICART_RESPONSE_CONTENT,
};

interface IAccount {
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly emailAddress: string;
    readonly emailVerified: boolean;
    readonly fbid: string;
    readonly appTcVersion: number;
    readonly appTcTimestamp: string;
    readonly address: IAccountAddress;
    readonly autopay: boolean;
    readonly accountid: string;
    readonly globalkey: string;
    readonly customerState: CustomerState;
    readonly phoneNumber: string;
    readonly userDevice: string;
    readonly privacyPolicyOptedIn: boolean;
    readonly privacyPolicyOptInTime: string;
    readonly paymentFailure: boolean;
    readonly activeAccRefCodeCounter: number;
    readonly incorrectMDNPortRetryCounter: number;
    readonly incorrectMDNPortRetryThreshold: number;
    readonly incorrectAccountPinPortRetryCounter: number;
    readonly incorrectAccountPinPortRetryThreshold: number;

    readonly statusTransition: string;
    readonly inviteCode?: string;

    readonly terminateDate?: string;
    readonly portStatus?: string;
    readonly portRemarks?: string;

    readonly refCode?: string;
    readonly referralLink?: string;

    // TODO(dan) Temporary.  Change to correct field when applied short code is persisted.
    readonly refCodeApplied?: string;
    // TODO(dan) Temporary.  Change to correct field when imei is persisted.
    readonly imei?: string;
    // TODO(dan) Temporary.  Change to correct field when campaignId is persisted.
    readonly campaignId?: string;
    // TODO(dan) Temporary.  Change to correct field when transactionId is persisted.
    readonly transactionId?: string;

    readonly PROMO_CONTENTS?: ReadonlyArray<IPromoContent>;
    readonly appliedPromotions?: ReadonlyArray<IAppliedPromotion>;
}

enum CustomerState {
    UNKNOWN = '',
    REGISTERED = 'Registered',
    PURCHASED = 'Purchased',
    PREACTIVATED = 'Preactive',
    ACTIVE = 'Active',
    PAUSED = 'Paused',
    LAPSED = 'Lapsed',
    SUSPENDED = 'Suspended',
    TERMINATED = 'Terminated',
}

/**
 * Account Class.
 */
class Account implements IAccount {
    static readonly DEFAULT_ADDRESS: IAddress = {
        city: '',
        state: '',
        street_line1: '',
        street_line2: '',
        zip: '',
    };

    static readonly DEFAULT_STEP: IStepA = {
        step: 1,
    };

    static readonly PATCHABLE_KEYS: ReadonlyArray<PatchableKey> = [
        ['firstName', 'first_name'],
        ['lastName', 'last_name'],
        ['emailAddress', 'email_address'],
        ['address', 'address'],
        ['privacyPolicyOptedIn', 'PrivacyPolicy'],
        ['privacyPolicyOptInTime', 'PrivacyPolicyTime'],
    ];

    /**
     * Default values for Account.
     * @type {{id: string; firstName: string; lastName: string; emailAddress: string; : undefined;}}
     */
    static readonly DEFAULT: IAccount = {
        accountid: '',
        activeAccRefCodeCounter: 0,
        address: {
            billing_address: Account.DEFAULT_ADDRESS,
            shipping_address: Account.DEFAULT_ADDRESS,
        },
        appTcTimestamp: '',
        appTcVersion: -1,
        autopay: true,
        customerState: CustomerState.UNKNOWN,
        emailAddress: '',
        emailVerified: false,
        fbid: '',
        firstName: '',
        globalkey: '',
        id: '',
        incorrectAccountPinPortRetryCounter: 0,
        incorrectAccountPinPortRetryThreshold: 0,
        incorrectMDNPortRetryCounter: 0,
        incorrectMDNPortRetryThreshold: 0,
        lastName: '',
        paymentFailure: false,
        phoneNumber: '',
        privacyPolicyOptInTime: '',
        privacyPolicyOptedIn: false,
        statusTransition: '',
        userDevice: '',
        PROMO_CONTENTS: [],
        appliedPromotions: [],
    };

    // tslint:disable:readonly-keyword
    activeAccRefCodeCounter: number;
    address: IAccountAddress;
    appTcTimestamp: string;
    appTcVersion: number;
    autopay: boolean;
    emailAddress: string;
    emailVerified: boolean;
    fbid: string;
    firstName: string;
    id: string;
    incorrectMDNPortRetryCounter: number;
    incorrectMDNPortRetryThreshold: number;
    incorrectAccountPinPortRetryCounter: number;
    incorrectAccountPinPortRetryThreshold: number;
    inviteCode: string;
    lastName: string;
    accountid: string;
    globalkey: string;
    customerState: CustomerState;
    paymentFailure: boolean;
    phoneNumber: string;
    privacyPolicyOptedIn: boolean;
    privacyPolicyOptInTime: string;
    portStatus: string;
    portRemarks: string;
    refCode: string;
    referralLink: string;

    statusTransition: string;
    userDevice: string;
    terminateDate: string;
    // TODO(dan) Temporary.  Change to correct field when applied short code is persisted.
    refCodeApplied: string;
    // TODO(dan) Temporary.  Change to correct field when imei is persisted.
    imei: string;
    // TODO(dan) Temporary.  Change to correct field when campaignId is persisted.
    campaignId: string;
    // TODO(dan) Temporary.  Change to correct field when transactionId is persisted.
    transactionId: string;
    PROMO_CONTENTS: ReadonlyArray<IPromoContent>;
    appliedPromotions: ReadonlyArray<IAppliedPromotion>;

    constructor(value: IAccount) {
        this.activeAccRefCodeCounter = value.activeAccRefCodeCounter;
        this.address = value.address;
        this.appTcTimestamp = value.appTcTimestamp;
        this.appTcVersion = value.appTcVersion;
        this.autopay = value.autopay;
        this.emailAddress = value.emailAddress;
        this.emailVerified = value.emailVerified;
        this.fbid = value.fbid;
        this.firstName = value.firstName;
        this.id = value.id;
        this.lastName = value.lastName;
        this.incorrectMDNPortRetryCounter = value.incorrectMDNPortRetryCounter;
        this.incorrectMDNPortRetryThreshold =
            value.incorrectMDNPortRetryThreshold;
        this.incorrectAccountPinPortRetryCounter =
            value.incorrectAccountPinPortRetryCounter;
        this.incorrectAccountPinPortRetryThreshold =
            value.incorrectAccountPinPortRetryThreshold;
        this.accountid = value.accountid;
        this.globalkey = value.globalkey;
        this.customerState = value.customerState;
        this.paymentFailure = value.paymentFailure;
        this.phoneNumber = value.phoneNumber;
        this.userDevice = value.userDevice;
        this.PROMO_CONTENTS = value.PROMO_CONTENTS || [];

        this.terminateDate = value.terminateDate || '';
        this.inviteCode = value.inviteCode || '';
        this.portStatus = value.portStatus || '';
        this.portRemarks = value.portRemarks || '';
        this.refCode = value.refCode || '';
        this.referralLink = value.referralLink || '';

        // TODO(dan) Temporary.  Change to correct field when applied short code is persisted.
        this.refCodeApplied = value.refCodeApplied || '';
        // TODO(dan) Temporary.  Change to correct field when imei is persisted.
        this.imei = value.imei || '';
        // TODO(dan) Temporary.  Change to correct field when campaignId is persisted.
        this.campaignId = value.campaignId || '';
        // TODO(dan) Temporary.  Change to correct field when transactionId is persisted.
        this.transactionId = value.transactionId || '';

        this.privacyPolicyOptedIn = value.privacyPolicyOptedIn;
        this.privacyPolicyOptInTime = value.privacyPolicyOptInTime || '';
        this.statusTransition = value.statusTransition || '';
    }

    /**
     * Deserialize method providing new IAccount instance.
     * @param value of partial IAccount.
     * @returns {Account} with the default values overlaid with the provided input.
     * @constructor
     */
    static Deserialize(value: Partial<IAccount>): Account {
        return new Account({
            ...Account.DEFAULT,
            ...value,
        });
    }

    static Normalize(
        account: INonNormalizedAccount,
        fbUser: FirebaseUser,
    ): Account {
        return new Account({
            accountid: account.accountid,
            activeAccRefCodeCounter:
                Number(account.activeAccRefCodeCounter) ||
                Account.DEFAULT.activeAccRefCodeCounter,
            address: account.address || Account.DEFAULT.address,
            appTcTimestamp:
                account.app_tc_timestamp || Account.DEFAULT.appTcTimestamp,
            appTcVersion:
                account.app_tc_version || Account.DEFAULT.appTcVersion,
            autopay: account.autopay || false,
            // TODO(dan) Temporary.  Change to correct field when campaignId is persisted.
            campaignId: account.campaignId,
            customerState: Account.NormalizeCapitalization(
                account.customerState,
            ) as CustomerState,
            emailAddress: account.email_address,
            emailVerified: fbUser.emailVerified,
            fbid: fbUser.uid,
            firstName: account.first_name,
            globalkey: account.globalkey,
            id: account.accountid,
            // TODO(dan) Temporary.  Change to correct field when imei is persisted.
            imei: account.imei,
            incorrectAccountPinPortRetryCounter:
                Number(account.incorrectAccountPinPortRetryCounter) ||
                Account.DEFAULT.incorrectAccountPinPortRetryCounter,
            incorrectAccountPinPortRetryThreshold:
                Number(account.incorrectAccountPinPortRetryThreshold) ||
                Account.DEFAULT.incorrectAccountPinPortRetryThreshold,
            incorrectMDNPortRetryCounter:
                Number(account.incorrectMDNPortRetryCounter) ||
                Account.DEFAULT.incorrectMDNPortRetryCounter,
            incorrectMDNPortRetryThreshold:
                Number(account.incorrectMDNPortRetryThreshold) ||
                Account.DEFAULT.incorrectMDNPortRetryThreshold,
            inviteCode: account.inviteCode || '',
            lastName: account.last_name,
            paymentFailure: account.paymentFailure === 'true',
            phoneNumber: account.phoneNumber || '',
            portRemarks: account.portRemarks,
            portStatus: account.portStatus,
            privacyPolicyOptInTime: account.PrivacyPolicyTime,
            privacyPolicyOptedIn: account.PrivacyPolicy === 'true',
            refCode: account.refCode || '',
            referralLink: account.referralLink || '',
            // TODO(dan) Temporary.  Change to correct field when applied short code is persisted.
            refCodeApplied: account.refCodeApplied || '',
            statusTransition: account.statusTransition || '',
            terminateDate: account.terminateDate || '',
            // TODO(dan) Temporary.  Change to correct field when transactionId is persisted.
            transactionId: account.transactionId,
            userDevice: account.userDevice || '',
            PROMO_CONTENTS: account.PROMO_CONTENTS || [],
            appliedPromotions: account.appliedPromotions || [],
        });
    }

    static Requestify(account: Partial<IAccount>): PatchableAccount {
        return Account.PATCHABLE_KEYS.reduce(
            (patchable: PatchableAccount, key: PatchableKey) => {
                const [normal, nonNormal] = key;
                if (typeof account[normal] !== 'undefined') {
                    patchable[nonNormal] = account[normal];
                }

                return patchable;
            },
            {},
        );
    }

    private static NormalizeCapitalization(str?: string): string {
        if (str && String(str).trim().length) {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        }

        return str || '';
    }

    get name(): string {
        return this.firstName && this.lastName
            ? `${this.firstName} ${this.lastName}`
            : '';
    }
}

type PatchableAccount = Partial<INonNormalizedAccount>;
type PatchableKey = [string, string];

export {
    IAddress,
    IActivateAccountPayload,
    ICreateAccountPayload,
    IAccount,
    INonNormalizedAccount,
    Account,
    CustomerState,
    PatchableAccount,
    IStepA,
    ICartRequest,
    ICartResponse,
    DEFAULT_ICART_RESPONSE_STATE,
};
