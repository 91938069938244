import { ActionHandlerMode } from 'components/email-action-handler';
import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { ActionType, EmailActionAction } from './email-action.actions';

interface EmailActionState {
    readonly mode: ActionHandlerMode;
    readonly oobCode: string;
    readonly passwordResetError: string;
    readonly passwordResetStatus: FetchStatus;
    readonly verificationStatus: FetchStatus;
}

const DEFAULT_STATE: EmailActionState = {
    mode: ActionHandlerMode.UNKNOWN,
    oobCode: '',
    passwordResetError: '',
    passwordResetStatus: FetchStatus.NOT_FETCHED,
    verificationStatus: FetchStatus.NOT_FETCHED,
};

const {
    VERIFY_CODE,
    REPORT_VERIFICATION_FAILURE,
    REPORT_VERIFICATION_SUCCESS,

    RESET_PASSWORD,
    REPORT_PASSWORD_RESET_SUCCESS,
    REPORT_PASSWORD_RESET_FAILURE,
} = ActionType;

const emailActionReducer = (
    state: EmailActionState = DEFAULT_STATE,
    action: EmailActionAction | IDefaultAction,
) => {
    switch (action.type) {
        case VERIFY_CODE:
            return {
                ...state,
                ...action.payload,
                verificationStatus: FetchStatus.FETCHING,
            };
        case REPORT_VERIFICATION_SUCCESS:
            return {
                ...state,
                verificationStatus: FetchStatus.SUCCESS,
            };
        case REPORT_VERIFICATION_FAILURE:
            return {
                ...state,
                verificationStatus: FetchStatus.ERROR,
            };

        case RESET_PASSWORD:
            return {
                ...state,
                passwordResetStatus: FetchStatus.FETCHING,
            };
        case REPORT_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                passwordResetStatus: FetchStatus.SUCCESS,
            };
        case REPORT_PASSWORD_RESET_FAILURE:
            return {
                ...state,
                passwordResetError: action.payload,
                passwordResetStatus: FetchStatus.ERROR,
            };
        default:
            return state;
    }
};

export { emailActionReducer, DEFAULT_STATE, EmailActionState };
