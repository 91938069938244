import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { BodyCopy } from 'components/common/typography';
import {
    ButtonContainer as Button,
    ModalWrapperContainer as ModalWrapper,
} from 'containers';
import { IAnalyticsProps, IModalProps } from 'models';

const styles = require('./errors.less');

interface ILockedDeviceErrorProps extends IModalProps, IAnalyticsProps {
    onButton(): void;
}

@CSSModules(styles, { allowMultiple: true })
class LockedDeviceError extends React.Component<ILockedDeviceErrorProps> {
    render() {
        return (
            <ModalWrapper title={__('phone-number-errors.locked-device.title')}>
                <>
                    <div styleName="content">
                        <BodyCopy>
                            {__('phone-number-errors.locked-device.body')}
                        </BodyCopy>
                    </div>
                    <div>
                        <Button
                            onClick={this.onButtonClick}
                            label={__(
                                'phone-number-errors.locked-device.button-text',
                            )}
                        />
                    </div>
                </>
            </ModalWrapper>
        );
    }

    private readonly onButtonClick = () => {
        const { onButton, onClose } = this.props;
        onClose();
        onButton();
    };
}

export { LockedDeviceError, ILockedDeviceErrorProps };
