import * as React from 'react';

import { SIMCartItem } from 'models/cart';
import { PortMessage } from './port-message';
import { YourNewNumber } from './your-new-number';

interface Props {
    readonly sim: SIMCartItem | undefined;
}

class HasPhoneNumber extends React.Component<Props, {}> {
    render() {
        const { sim } = this.props;
        if (sim && sim.isNewMDN) {
            return <YourNewNumber mdn={sim.mdn} />;
        }

        return <PortMessage />;
    }
}

export { HasPhoneNumber };
