import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { BodyCopy, Headline } from 'components/common/typography';
import {
    ButtonContainer as Button,
    ModalWrapperContainer as ModalWrapper,
} from 'containers';
import { IModalProps } from 'models';

interface Props extends IModalProps {
    readonly pauseServiceTerm: number;
    pauseService(amount: number): void;
}

@CSSModules(require('./service-pause.less'))
class ServicePause extends React.Component<Props> {
    render() {
        const { pauseServiceTerm, onClose } = this.props;
        return (
            <ModalWrapper>
                <>
                    <div styleName="modal-inner-wrap">
                        <Headline two>
                            {__('service-pause.title')}
                            <span styleName="service-term-value">
                                {pauseServiceTerm}
                            </span>
                            {__('service-pause.term-unit')}
                            {__('service-pause.exclamation')}
                        </Headline>
                        <BodyCopy>{__('service-pause.message')}</BodyCopy>
                        <div styleName="modal-options">
                            <Button
                                styleName="button"
                                onClick={this.pauseService}
                                label={__('service-pause.submit')}
                            />
                            <Button
                                styleName="button"
                                onClick={onClose}
                                label={__('service-pause.cancel')}
                            />
                        </div>
                    </div>
                </>
            </ModalWrapper>
        );
    }

    private readonly pauseService = () => {
        const { pauseServiceTerm, pauseService } = this.props;
        pauseService(pauseServiceTerm);
    };
}

export { ServicePause };
