import * as React from 'react';

import { UserValidatorComponent } from 'components/user-validator';
import { FetchStatus } from 'lib/br-redux';
import { IApplicationState } from 'models';
import { parse } from 'query-string';
import { connect } from 'react-redux';
import { RouterState } from 'react-router-redux';
import { getUserValidationStatus, validateUser } from 'state/user-validation';

class UserValidator extends React.Component<Props> {  
  sf_tracker: any = 0;

  componentDidMount() {
      const { token, validateUser } = this.props;
      validateUser(token);
      this.sf_tracker = setInterval(() => {
        const sf_chat_button = document.querySelector('.embeddedServiceSidebar.modalContainer') ||
                               document.querySelector('.helpButton');
        if (sf_chat_button) {
          (sf_chat_button as HTMLElement).style.display = 'none';
        }
      }, 1000);
  }

  componentWillUnmount() {
    // show sf chat when leaving validate page
    const self = this;
    clearInterval(self.sf_tracker);
    const sf_chat_button = document.querySelector('.embeddedServiceSidebar.modalContainer') || 
                           document.querySelector('.helpButton');
    if (sf_chat_button) {
      (sf_chat_button as HTMLElement).style.display = 'block';
    }
  }

  render() {
      return <UserValidatorComponent status={this.props.status} />;
  }
}

interface Props {
    readonly status: FetchStatus;
    readonly token: string;
    validateUser(token: string): any;
}

const getToken = ({ location }: RouterState): string => {
    const queryString = parse((location && location.search) || '');
    return queryString.token || '';
};

const mapStateToProps = (state: IApplicationState, ownProps: RouterState) => ({
    status: getUserValidationStatus(state),
    token: getToken(ownProps),
});

const mapDispatchToProps = {
    validateUser,
};

const UserValidatorContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserValidator);

export { UserValidatorContainer };
