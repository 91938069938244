import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { ActionType, IFeedbackAction } from './feedback.actions';

interface IFeedbackState {
    readonly fetchStatus: FetchStatus;
    readonly error: string;
}

const DEFAULT_STATE: IFeedbackState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
};

const {
    SUBMIT_FEEDBACK,
    REPORT_FEEDBACK_ERROR,
    REPORT_FEEDBACK_SUCCESS,
} = ActionType;

const feedbackReducer = (
    state: IFeedbackState = DEFAULT_STATE,
    action: IFeedbackAction | IDefaultAction,
): IFeedbackState => {
    switch (action.type) {
        case SUBMIT_FEEDBACK:
            return {
                ...state,
                error: '',
                fetchStatus: FetchStatus.FETCHING,
            };
        case REPORT_FEEDBACK_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        case REPORT_FEEDBACK_SUCCESS:
            return {
                ...state,
                error: '',
                fetchStatus: FetchStatus.SUCCESS,
            };
        default:
            return state;
    }
};

export { IFeedbackState, DEFAULT_STATE, feedbackReducer };
