import { all } from 'redux-saga/effects';
import { facebookSDKSaga } from './facebook-sdk.saga';
import { googleSDKSaga } from './google-sdk.saga';

function* loadScripts() {
    // We start all the sagas in parallel
    yield all([facebookSDKSaga(), googleSDKSaga()]);
}

export { loadScripts };
