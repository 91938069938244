import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { BodyCopy } from 'components/common/typography';
import {
    ButtonContainer as Button,
    ModalWrapperContainer as ModalWrapper,
} from 'containers';
import { IAnalyticsProps, IDeviceTypeProps, IModalProps } from 'models';

const styles = require('./alert-modal.less');

interface Props extends IModalProps, IDeviceTypeProps, IAnalyticsProps {
    readonly title: string;
    readonly subtitle?: string;
    readonly body?: string;
    readonly buttonText?: string;
    readonly buttonTextSecondary?: string;
    readonly closable?: boolean;
    readonly invert?: boolean;
    readonly center?: boolean;
    readonly emoji?: boolean;
    readonly minHeight?: number;
    readonly error?: boolean;
    readonly primaryLoading?: boolean;
    readonly secondaryLoading?: boolean;
    readonly analyticsTag?: string;
    onConfirm(): any;
    onConfirmSecondary?(): any;
}

@CSSModules(styles, { allowMultiple: true })
class AlertModal extends React.Component<Props> {
    render() {
        const {
            title,
            subtitle,
            body,
            onConfirm,
            onConfirmSecondary,
            buttonText,
            buttonTextSecondary,
            closable,
            invert,
            center,
            emoji,
            minHeight,
            bindHandleClickWith,
            error,
            primaryLoading,
            secondaryLoading,
        } = this.props;
        return (
            <ModalWrapper
                title={title}
                subtitle={subtitle}
                closable={closable}
                invert={invert}
                center={center}
                emoji={emoji}
                minHeight={minHeight}
                error={error}
            >
                <>
                    {body && <BodyCopy>{body}</BodyCopy>}
                    <div styleName="buttons-wrapper">
                        <Button
                            disabled={primaryLoading || secondaryLoading}
                            loading={primaryLoading}
                            invert={invert}
                            fullWidthMobile
                            noPaddingSides
                            onClick={bindHandleClickWith(onConfirm)}
                            label={buttonText || __('ok')}
                        />
                        {buttonTextSecondary && (
                            <Button
                                disabled={primaryLoading || secondaryLoading}
                                loading={secondaryLoading}
                                styleName="secondary-button"
                                invert={invert}
                                fullWidthMobile
                                noPaddingSides
                                onClick={
                                    onConfirmSecondary
                                        ? bindHandleClickWith(
                                              onConfirmSecondary,
                                          )
                                        : undefined
                                }
                                label={buttonTextSecondary || __('ok')}
                            />
                        )}
                    </div>
                </>
            </ModalWrapper>
        );
    }
}

export { AlertModal };
