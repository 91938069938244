import { AlertModalContainer as AlertModal } from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { IApplicationState } from 'models';
import { IDeviceListCarriersModels } from 'models/device';
import { IProductItemModel, ProductType } from 'models/products';
import {
    LOCATION_CHANGE,
    LocationChangeAction,
    push,
} from 'react-router-redux';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { DeviceService } from 'services/device.service';
import { fetchConfiguration } from 'state/configuration/configuration.saga';
import {
    ActionType,
    IDeviceCompatibilityCheckAction,
    loadCarriersModels as createLoadCarriersModelsAction,
    reportDeviceCompatibilityError,
    reportDeviceCompatibilitySuccess,
    reportLoadDeviceCarriersModelsError,
    reportLoadDeviceCarriersModelsSuccess,
} from 'state/device/compatibility/compatibility.actions';
import { getToken } from 'state/firebase/firebase.saga';
import { addItemToCart } from 'state/purchase/cart/cart.actions';
import { eventError, eventLink, trackError } from 'state/tealium';
import { confirmWithModal } from 'state/view/view.saga';
import { smellyStoreSingleton } from 'utils/store';

function* getTokenOrDefault() {
    try {
        return yield call(getToken);
    } catch (err) {
        return '';
    }
}

function* checkDeviceCompatibility(action: IDeviceCompatibilityCheckAction) {
    // TODO(dan): Now device-check.tsx and imei.tsx both use this.  Does it work for both without a regression?
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const token = yield call(getTokenOrDefault);
        const response = yield call(
            DeviceService.checkDeviceCompatibility,
            basePaths,
            token,
            action.payload,
        );
        yield put(
            reportDeviceCompatibilitySuccess({
                ...response,
                // TODO(dan) This is to maintain compatibility with device-check.tsx.
                deviceId: action.payload.deviceId || '',
                payload: action.payload,
            }),
        );
    } catch (err) {
        yield put(reportDeviceCompatibilityError((err as Error).message));
    }
}

function* lockedDeviceCompatibility(action: IDeviceCompatibilityCheckAction) {
    const ok = yield call(confirmWithModal, {
        additionalProps: {
            body: __('imei.self.locked-device.body'),
            buttonText: __('imei.self.locked-device.action'),
            center: true,
            closable: true,
            minHeight: 368,
            title: __('imei.self.locked-device.title'),
        },
        component: AlertModal,
    });
    if (ok) {
        yield put(
            eventLink({
                event_category: 'link_click',
                link_name: __('imei.self.locked-device-confirm-metrics'),
            }),
        );
    }
}

function* skipDeviceCompatibility(action: IDeviceCompatibilityCheckAction) {
    const ok = yield call(confirmWithModal, {
        additionalProps: {
            body: __('imei.self.skip.warning.body'),
            buttonText: __('imei.self.skip.warning.action'),
            closable: true,
            title: __('imei.self.skip.warning.title'),
        },
        component: AlertModal,
    });
    const items: ReadonlyArray<IProductItemModel> = yield select(
        ({ products }: IApplicationState) => products.results,
    );
    const handset = items.find(i => i.product_type === ProductType.HANDSET);

    if (ok) {
        yield put(
            eventLink({
                event_category: 'link_click',
                link_name: __('imei.self.skip-metrics'),
            }),
        );
        // TODO(dan): Determine the correct way to complete Compatibility without an IMEI or handset.
        addItemToCart({
            byodIMEI: { imei: '' },
            itemid: handset ? handset.itemid : '',
            productType: ProductType.HANDSET,
            quantity: 1,
        });
    }
}

function* loadCarriersModels() {
    try {
        yield call(fetchConfiguration);

        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const token = yield call(getTokenOrDefault);
        const carriersModels: IDeviceListCarriersModels = yield select(
            ({
                device: {
                    compatibility: { carriersModels },
                },
            }: IApplicationState) => carriersModels,
        );
        if (
            carriersModels &&
            carriersModels.carriers &&
            carriersModels.carriers.length &&
            carriersModels.models &&
            carriersModels.models.length
        ) {
            // TODO(dan) Is this an ok assumption?
            // Don't fetch more than once.
            return;
        }
        const results: IDeviceListCarriersModels = yield call(
            DeviceService.getCarriersModels,
            basePaths,
            token,
        );
        yield put(reportLoadDeviceCarriersModelsSuccess(results));
    } catch (error) {
        yield put(reportLoadDeviceCarriersModelsError(error.message));
    }
}

// ======================================================================
// If a user views the sign up page after carriers/models failed to load,
// pop a modal and prevent sign up
// =======================================================================

function* onLoadCarriersModelsError() {
    // get current path
    const location: Location | undefined = yield select(
        ({ routing }: IApplicationState) => routing.location,
    );
    yield put(eventError({ error_msg: 'Error loading carriers & models.' }));
    if (location && location.pathname === __('routes.sign-up')) {
        yield fork(showLoadCarriersModelsError);
    }
}

function* showErrorIfEnteringSignUpAfterCarriersModelsError(
    action: LocationChangeAction,
) {
    const fetchStatus = yield select(
        ({ device }: IApplicationState) =>
            device.compatibility.fetchStatusCarriersModels,
    );
    if (
        fetchStatus === FetchStatus.ERROR &&
        action.payload.pathname === __('routes.sign-up')
    ) {
        yield fork(showLoadCarriersModelsError);
    }
}

function* showLoadCarriersModelsError() {
    const { dispatch } = smellyStoreSingleton.store;
    yield call(confirmWithModal, {
        additionalProps: {
            body: __('default-error-message'),
            onConfirm: () => {
                dispatch(push('/')); // send to homepage
                dispatch(createLoadCarriersModelsAction()); // try loading carriers/models again
            },
            title: __('oops'),
        },
        component: AlertModal,
    });
}

function* compatibilitySaga() {
    yield all([
        takeEvery(ActionType.LOAD, loadCarriersModels),
        takeEvery(ActionType.CHECK, checkDeviceCompatibility),
        takeEvery(ActionType.LOCKED, lockedDeviceCompatibility),
        takeEvery(ActionType.SKIP, skipDeviceCompatibility),
        takeEvery(
            ActionType.REPORT_CHECK_DEVICE_COMPATIBILITY_ERROR,
            trackError,
        ),
        takeEvery(
            ActionType.REPORT_LOAD_DEVICE_CARRIERS_MODELS_SUCCESS,
            loadCarriersModels,
        ),
        takeEvery(
            ActionType.REPORT_LOAD_DEVICE_CARRIERS_MODELS_ERROR,
            onLoadCarriersModelsError,
        ),
        takeEvery(
            LOCATION_CHANGE,
            showErrorIfEnteringSignUpAfterCarriersModelsError,
        ),
    ]);
}

export { compatibilitySaga };
