import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Col, InfoLink, Row } from 'components/common/typography';

import {
    ButtonContainer as Button,
    ModalWrapperContainer as ModalWrapper,
} from 'containers';
import { IAnalyticsProps, IDeviceTypeProps, IModalProps } from 'models';
import { Hidden } from 'components/common/hidden';

const styles = require('./update-address-modal.less');
const yieldIcon = '/assets/images/icons/yield.svg';

interface Props extends IModalProps, IDeviceTypeProps, IAnalyticsProps {
    readonly title: string;
    readonly buttonText?: string;
    readonly closable?: boolean;
    readonly primaryLoading?: boolean;
    readonly secondaryLoading?: boolean;
    readonly analyticsTag?: string;
    readonly addressAlert?: boolean;
    readonly addressObj?: any;
    onConfirm(): any;
    onConfirmSecondary?(): any;
    onClose(): any;
}

@CSSModules(styles, { allowMultiple: true })
class UpdateAddressModal extends React.Component<Props> {
    render() {
        const {
            title,
            onConfirm,
            buttonText,
            closable,
            bindHandleClickWith,
            primaryLoading,
            secondaryLoading,
            addressAlert,
            addressObj,
            onClose,
        } = this.props;
        return (
            <ModalWrapper
                title={title}
                closable={closable}
                suggestedAddressModal
            >
                <>
                    {addressAlert && (
                        <Row>
                            <Col width={6} mobileWidth={12}>
                                <div
                                    styleName={
                                        'address-section-entered' +
                                        (addressObj.suggestedAddress !== ''
                                            ? ' address-section-border'
                                            : '')
                                    }
                                >
                                    <div styleName="address-title">
                                        You entered:
                                    </div>
                                    <div styleName="address-font">
                                        <span
                                            styleName={
                                                addressObj.enteredAddress
                                                    .street_line1 !==
                                                addressObj.suggestedAddress
                                                    .street_line1
                                                    ? 'error street-address'
                                                    : 'street-address'
                                            }
                                        >
                                            {
                                                addressObj.enteredAddress
                                                    .street_line1
                                            }
                                            &nbsp;
                                            {addressObj.enteredAddress
                                                .street_line2 &&
                                                addressObj.enteredAddress
                                                    .street_line2 !== '' &&
                                                `#${
                                                    addressObj.enteredAddress
                                                        .street_line2
                                                }`}
                                        </span>
                                        <span
                                            styleName={
                                                'sub-content ' +
                                                (addressObj.suggestedAddress ===
                                                ''
                                                    ? 'error'
                                                    : '')
                                            }
                                        >
                                            {addressObj.enteredAddress.city},
                                        </span>
                                        <span
                                            styleName={
                                                'sub-content ' +
                                                (addressObj.suggestedAddress ===
                                                ''
                                                    ? 'error'
                                                    : '')
                                            }
                                        >
                                            {addressObj.enteredAddress.state},
                                        </span>
                                        <span
                                            styleName={
                                                'sub-content ' +
                                                (addressObj.suggestedAddress ===
                                                ''
                                                    ? 'error'
                                                    : '')
                                            }
                                        >
                                            {addressObj.enteredAddress.zip}
                                        </span>
                                        <br />
                                        <br />
                                        <br />
                                        <InfoLink
                                            plain
                                            styleName="edit-link"
                                            onClick={onClose}
                                        >
                                            Edit
                                        </InfoLink>
                                    </div>
                                </div>
                            </Col>
                            <Col width={6} mobileWidth={12}>
                                <div
                                    styleName={
                                        'address-section-suggested' +
                                        (addressObj.suggestedAddress !== ''
                                            ? ' suggested-address-border'
                                            : ' address-divider')
                                    }
                                >
                                    <Hidden
                                        when={
                                            addressObj.suggestedAddress === ''
                                        }
                                    >
                                        <img
                                            styleName="check-mark-icon"
                                            src="/assets/images/icons/check.svg"
                                            alt="check-mark"
                                        />
                                    </Hidden>
                                    {this.props.addressObj.suggestedAddress !==
                                    '' ? (
                                        <>
                                            <div styleName="address-title">
                                                Suggested address:
                                            </div>
                                            <div styleName="address-font">
                                                <span styleName="street-address">
                                                    {
                                                        addressObj
                                                            .suggestedAddress
                                                            .street_line1
                                                    }
                                                    &nbsp;
                                                    {addressObj.suggestedAddress
                                                        .street_line2 &&
                                                        addressObj
                                                            .suggestedAddress
                                                            .street_line2 !==
                                                            '' &&
                                                        `#${
                                                            addressObj
                                                                .suggestedAddress
                                                                .street_line2
                                                        }`}
                                                </span>
                                                <span styleName="sub-content">
                                                    {
                                                        addressObj
                                                            .suggestedAddress
                                                            .city
                                                    }
                                                    ,
                                                </span>
                                                <span styleName="sub-content">
                                                    {
                                                        addressObj
                                                            .suggestedAddress
                                                            .state
                                                    }
                                                    ,
                                                </span>
                                                <span styleName="sub-content">
                                                    {
                                                        addressObj
                                                            .suggestedAddress
                                                            .zip
                                                    }
                                                </span>
                                                <br />
                                                <br />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div styleName="address-error">
                                                <img
                                                    alt="warning-img"
                                                    styleName="address-yield-icon"
                                                    src={yieldIcon}
                                                />
                                                <span>Uh oh</span>
                                            </div>
                                            <span styleName="address-error-text">
                                                We are unable to verify this
                                                address.
                                                <br />
                                                Please enter a valid address to
                                                proceed with your order.
                                            </span>
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    )}
                    <div styleName="buttons-wrapper">
                        <Button
                            disabled={
                                primaryLoading ||
                                secondaryLoading ||
                                addressObj.suggestedAddress === ''
                            }
                            loading={primaryLoading}
                            noPaddingSides
                            onClick={bindHandleClickWith(onConfirm)}
                            label={buttonText || __('ok')}
                        />
                    </div>
                </>
            </ModalWrapper>
        );
    }
}

export { UpdateAddressModal };
