import * as classNames from 'classnames';
import { IAnalyticsProps } from 'components/common/analytics';
import { Block } from 'components/common/block';
import * as Emoji from 'components/common/graphics/emoji';
import { Hidden } from 'components/common/hidden';
import {
    BodyCopy,
    Headline,
    InfoLink,
    Subhead,
    Label,
} from 'components/common/typography';
import {
    ButtonContainer as Button,
    CreateAccountContainer as CreateAccount,
    InputContainer as Input,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';
import {
    ChoiceOption,
    IInputValidationProps,
    IModalProps,
    IProductItemModel,
    ICartRequest,
    ICartResponse,
} from 'models';
import { HandsetCartItem } from 'models/cart';
import { Cart } from 'models/cart/cart.model';
import {
    DEVICE_OS,
    IDeviceCompatibilityPayload,
    IDeviceCompatibilityResponse,
    IDeviceListCarriersModels,
    IDeviceModel,
} from 'models/device';
import { ProductType } from 'models/products';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { PendingItem } from 'state/purchase/cart/cart.reducer';
import { InputValidator } from 'utils';
import {
    TealiumFlowName,
    TealiumLinkEvents,
    TealiumPageLinkLocations,
    TealiumPageType,
    TealiumPagevents,
} from 'models/tealium';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';
import { BooleanButtons } from '../boolean-buttons';

const styles = require('./imei.less');

interface Props extends IAnalyticsProps, IInputValidationProps, IModalProps {
    readonly authStatus: FetchStatus;
    readonly cart: Cart | null;
    readonly complete: boolean;
    readonly deviceCompatibilityCarriersModels: IDeviceListCarriersModels;
    readonly deviceCompatibilityFetchStatus: FetchStatus;
    readonly deviceCompatibilityResponse: IDeviceCompatibilityResponse;
    readonly handset: IProductItemModel | undefined;
    readonly location: Location;
    readonly sidebarCartOpen?: boolean;
    readonly pendingItem: PendingItem | undefined;
    readonly prefilledIMEI: string;
    readonly leadError: string;
    readonly isEnableSwap?: boolean;
    readonly bag: ICartResponse;
    checkDevice(payload: IDeviceCompatibilityPayload): void;
    clearCheckDevice(): void;
    clearFetchStatus(): void;
    lockedCheckDevice(): void;
    redirectTo(url: string): void;
    skipCheckDevice(): void;
    loadCarriersModels(): void;
    addItemsCart(payload: ICartRequest): void;
}

enum Status {
    CREATING_ACCOUNT,
    CREATE_ACCOUNT_SUCCESS,
    PICKING_A_PHONE,
}

interface State {
    readonly animateEmoji: boolean;
    readonly checkMyself: boolean | null;
    readonly compatible: boolean | null;
    readonly compatibleCode: string;
    readonly compatibleMessage: string | null;
    readonly imeiInjected: boolean;
    readonly lastFetchStatus: FetchStatus;
    readonly compatibilityFetchStatus: FetchStatus;
    readonly selectedCarrier: string;
    readonly selectedModel: string;
    readonly selectedOperatingSystem: number | null;
    readonly selectedUnlocked: number | null;
    readonly signUp: boolean;
    readonly prevValue: number;
    readonly status: Status;
    readonly isIMEIErrorDisplayed: boolean | false;
}

const DEFAULT_COMPATIBILITY = {
    compatible: null,
    compatibleCode: '',
    compatibleMessage: '',
};

const CART_RESPONSE = {
    ADDED: 'ADDED',
    CART_CONFLICT: 'CART_CONFLICT',
    SUCCESS: 'Success',
};

@CSSModules(styles, { allowMultiple: true })
class IMEI extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            animateEmoji: true,
            checkMyself: null,
            ...DEFAULT_COMPATIBILITY,
            imeiInjected: false,
            lastFetchStatus: FetchStatus.NOT_FETCHED,
            selectedCarrier: '',
            selectedModel: '',
            selectedOperatingSystem: null,
            selectedUnlocked: null,
            signUp: false,
            isIMEIErrorDisplayed: false,
            compatibilityFetchStatus: FetchStatus.NOT_FETCHED,
            prevValue: 0,
            status:
                props.authStatus === FetchStatus.SUCCESS
                    ? Status.PICKING_A_PHONE
                    : Status.CREATING_ACCOUNT,
        };
    }

    componentWillReceiveProps(newProps: Props): void {
        this.initCompatibility();
        if (newProps !== this.props) {
            this.initSavedIMEI(newProps);
        }
        if (
            newProps.authStatus !== this.props.authStatus &&
            newProps.authStatus === FetchStatus.SUCCESS
        ) {
            this.setState(
                { status: Status.CREATE_ACCOUNT_SUCCESS },
                this.queueTransition,
            );
        }
        if (
            this.isStandalone &&
            newProps.authStatus === FetchStatus.NOT_FETCHED &&
            newProps.cart &&
            newProps.cart.hasProductType(ProductType.HANDSET)
        ) {
            this.setState({
                signUp: true,
            });
            setTimeout(() => window.scrollTo(0, 0), 1000);
        }
        if (newProps.bag && newProps.bag.status.msg === CART_RESPONSE.SUCCESS) {
            if (newProps.bag.content.action === CART_RESPONSE.ADDED) {
                // redirectTo(__('routes.sign-up'));
                window.location.href = __('routes.cart');
            } else if (
                newProps.bag.content.action === CART_RESPONSE.CART_CONFLICT
            ) {
                this.setSessionStorageForCartConflict(
                    newProps.bag.content.conflict.existing,
                    newProps.bag.content.conflict.new,
                );
                window.location.href = __('routes.cart.conflict');
            }
        }
    }

    private readonly setSessionStorageForCartConflict = (
        existing,
        newDevice,
    ) => {
        sessionStorage.setItem('isCartConflictStandalone', 'true');
        sessionStorage.setItem('existing', JSON.stringify(existing));
        sessionStorage.setItem('new', JSON.stringify(newDevice));
    };

    componentDidUpdate(_, prevState) {
        const isUpdate =
            prevState.selectedCarrier !== this.state.selectedCarrier ||
            prevState.selectedModel !== this.state.selectedModel ||
            prevState.selectedOperatingSystem !==
                this.state.selectedOperatingSystem ||
            prevState.selectedUnlocked !== this.state.selectedUnlocked;

        if (isUpdate && this.canCalculate()) {
            this.calculateCompatibility();
        }
    }

    componentDidMount() {
        this.initSavedIMEI(this.props);
        this.initCompatibility();
        this.props.loadCarriersModels();
        TealiumDispatcher.dispatchView(
            TealiumFlowName.OS,
            TealiumPageType.STANDALONE_COMPATABILITY,
        );
        this.calculateCompatibilityReset();
    }

    componentWillUnmount() {
        this.calculateCompatibilityReset();
    }

    render() {
        const {
            state: { signUp },
        } = this;
        return (
            <>
                <Hidden when={signUp}>
                    <div
                        styleName={
                            this.isStandalone
                                ? 'compatibility-container-standalone'
                                : 'compatibility-container'
                        }
                    >
                        <div styleName="section-imei-wrapper">
                            {this.enterDetails}
                        </div>
                    </div>
                </Hidden>
                <Hidden until={signUp}>
                    <div styleName="section-create-account-wrapper">
                        <CreateAccount compatibility={true} />
                    </div>
                </Hidden>
            </>
        );
    }

    private readonly tealiumEventNext = () => {
        TealiumDispatcher.dispatchEvent(
            this.state.selectedUnlocked
                ? TealiumFlowName.UNLOCKED
                : TealiumFlowName.IMEI,
            TealiumPageType.STANDALONE_COMPATABILITY,
            TealiumPagevents.NEXT,
            TealiumPageLinkLocations.MODULE_TWO,
            TealiumLinkEvents.BUTTON,
        );
        if (this.state.compatible) {
            TealiumDispatcher.dispatchView(
                TealiumFlowName.BYOD,
                TealiumPageType.SIGNUP,
            );
        }
    };

    private readonly tealiumEventIMEI = () => (
        e: React.MouseEvent<HTMLButtonElement>,
    ): void => {
        e.preventDefault();
        TealiumDispatcher.dispatchEvent(
            TealiumFlowName.IMEI,
            TealiumPageType.STANDALONE_COMPATABILITY,
            __('imei.input-field-metrics'),
            TealiumPageLinkLocations.MODULE_ONE,
            TealiumLinkEvents.FORM_FIELD,
        );
    };

    private get nextButton(): JSX.Element {
        return (
            <Hidden
                when={this.isNotCompatible}
                until={this.isNextButton || this.isGetNewPhone}
            >
                <Button
                    fullWidth
                    loading={this.isCompatibilityFetching}
                    type="submit"
                    fullWidthMobile
                    minWidth="220px"
                    disabled={this.isNextButtonDisable}
                    onClick={event => this.handleCheckIMEI(event)}
                >
                    {__('imei.self.result.next')}
                </Button>
            </Hidden>
        );
    }

    private get getNewPhoneButton(): JSX.Element {
        const {
            state: { compatible },
            isFetching,
        } = this;
        const isCompatible = compatible === true;
        const isCompatibiltyDetermined = compatible !== null;
        return (
            <Hidden until={this.isGetNewPhone}>
                <a href={__('routes.phones')}>
                    <Button
                        loading={isFetching}
                        disabled={!isCompatibiltyDetermined || isCompatible}
                        fullWidth
                        fullWidthMobile
                        minWidth="220px"
                    >
                        {__('imei.self.result.new-phone')}
                    </Button>
                </a>
            </Hidden>
        );
    }

    private get enterDetails(): JSX.Element {
        const {
            props: {
                cart,
                complete,
                data: { imei },
                handleOnChange,
                handleOnValidate,
            },
            state: {
                selectedCarrier,
                selectedModel,
                selectedOperatingSystem,
                selectedUnlocked,
            },
            // isFetching,
        } = this;
        const itemsInCart = cart && cart.hasProductType(ProductType.HANDSET);
        const unlockFAQSubheading = (
            <div styleName="unlock-faq-link">
                <BodyCopy center>
                    {__('imei.self.unlocked.subheading')}
                </BodyCopy>
                <BodyCopy center thin medium noMargin>
                    <InfoLink
                        to={__('routes.unlock-your-phone')}
                        target="_blank"
                        styleName="info-link"
                    >
                        {__('imei.self.unlock-your-phone')}
                    </InfoLink>
                </BodyCopy>
            </div>
        );
        const selectedStyles = (val: string) =>
            `no-margin no-pad bottom select-device${
                !!val ? ` selected-device` : ''
            }`;

        return (
            <>
                <Headline center>{__('imei.self.how-to-check')}</Headline>
                <Block width={3}>
                    <Subhead medium center>
                        {__('imei.self.how-to-description')}
                    </Subhead>
                </Block>
                <div styleName="buttons section-operating-system">
                    <Block width={3}>
                        <BooleanButtons
                            flow={__('imei.self.os-flow')}
                            noMarginTop
                            prompt={''}
                            onSelect={this.selectOperatingSystem}
                            value={
                                selectedOperatingSystem === null
                                    ? null
                                    : selectedOperatingSystem === 0
                            }
                            labelFalse={__('imei.self.os.android')}
                            labelTrue={__('imei.self.os.ios')}
                            metricFalse={__('imei.self.os-android-metrics')}
                            metricTrue={__('imei.self.os-ios-metrics')}
                        />
                        <BodyCopy medium thin center>
                            {__('imei.self.skip-disclaimer')}
                        </BodyCopy>
                    </Block>
                </div>
                <Hidden
                    when={
                        selectedOperatingSystem === null ||
                        selectedOperatingSystem === 1
                    }
                >
                    <div styleName="buttons section-model">
                        <select
                            styleName={selectedStyles(selectedModel)}
                            id="select-model"
                            value={selectedModel}
                            onChange={event => {
                                this.setState({
                                    selectedModel:
                                        event.target && event.target.value,
                                });
                                TealiumDispatcher.dispatchEvent(
                                    TealiumFlowName.MODEL,
                                    TealiumPageType.STANDALONE_COMPATABILITY,
                                    TealiumPagevents.MODEL_SELECTED,
                                    TealiumPageLinkLocations.MODULE_TWO,
                                    TealiumLinkEvents.DROPDOWN,
                                    '',
                                    '',
                                    event.target && event.target.value,
                                );
                                TealiumDispatcher.dispatchView(
                                    TealiumFlowName.CARRIER,
                                    TealiumPageType.STANDALONE_COMPATABILITY,
                                );
                            }}
                        >
                            <option value="" selected disabled hidden>
                                {__('imei.self.model.select')}
                            </option>
                            {this.getModelOptions().map((obj, index) => (
                                <option
                                    key={`${obj.value}-${index}`}
                                    value={`${obj.value}`}
                                >
                                    {obj.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </Hidden>
                <Hidden
                    when={
                        !selectedModel ||
                        selectedOperatingSystem === null ||
                        selectedOperatingSystem === 1
                    }
                >
                    <div
                        id="selectCarrier"
                        styleName="imei-col section-carrier"
                    >
                        <select
                            styleName={selectedStyles(selectedCarrier)}
                            id="select-carrier"
                            value={selectedCarrier}
                            onChange={event => {
                                this.setState({
                                    selectedCarrier:
                                        event.target && event.target.value,
                                });
                                TealiumDispatcher.dispatchEvent(
                                    TealiumFlowName.CARRIER,
                                    TealiumPageType.STANDALONE_COMPATABILITY,
                                    TealiumPagevents.CARRIER_SELECTED,
                                    TealiumPageLinkLocations.MODULE_TWO,
                                    TealiumLinkEvents.DROPDOWN,
                                    '',
                                    '',
                                    event.target && event.target.value,
                                );
                            }}
                        >
                            <option value="" selected disabled hidden>
                                {__('imei.self.carrier.select')}
                            </option>
                            {this.getCarrierOptions().map((obj, index) => (
                                <option
                                    key={`${obj.value}-${index}`}
                                    value={`${obj.value}`}
                                >
                                    {obj.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </Hidden>
                <Hidden
                    when={
                        !selectedModel ||
                        !selectedCarrier ||
                        selectedOperatingSystem === null ||
                        selectedOperatingSystem === 1
                    }
                >
                    <div styleName="section-phone-locked">
                        <Headline two center noMarginBottom>
                            {__('imei.self.unlocked.heading')}
                        </Headline>
                        {unlockFAQSubheading}
                        <div styleName="buttons section-unlocked">
                            <Block width={3}>
                                <BooleanButtons
                                    noMarginTop
                                    prompt={''}
                                    flow={__('imei.self.unlocked-flow')}
                                    onSelect={this.selectUnlocked}
                                    value={
                                        selectedUnlocked === null
                                            ? null
                                            : selectedUnlocked === 1
                                    }
                                    labelTrue={__('imei.self.unlocked.yes')}
                                    labelFalse={__('imei.self.unlocked.no')}
                                    metricTrue={__(
                                        'imei.self.unlocked-yes-metrics',
                                    )}
                                    metricFalse={__(
                                        'imei.self.unlocked-no-metrics',
                                    )}
                                />
                            </Block>
                        </div>
                    </div>
                </Hidden>
                <Hidden
                    when={
                        (selectedOperatingSystem === 0 &&
                            (!selectedModel ||
                                !selectedCarrier ||
                                selectedUnlocked === null ||
                                selectedUnlocked === 0 ||
                                selectedOperatingSystem === null)) ||
                        selectedOperatingSystem === null ||
                        (selectedOperatingSystem === 0 &&
                            this.state.compatibleCode !== 'NMI')
                    }
                >
                    <>
                        <Block width={3}>
                            {/* {checkMyself === false && <ErrorMsg>{error}</ErrorMsg>} */}
                            <Label styleName={'imei-label'} htmlFor={'imei'}>
                                {__('imei.input-placeholder')}
                            </Label>
                            <Input
                                id="imei"
                                type="number"
                                onValidate={handleOnValidate}
                                onChange={event =>
                                    this.handleUpdateIMEI(event, handleOnChange)
                                }
                                validate={InputValidator.validateIMEI}
                                readOnly={complete || itemsInCart}
                                value={imei}
                                onFocus={this.tealiumEventIMEI()}
                                onBlur={event => this.checkIMEIService(event)}
                            />
                        </Block>

                        <BodyCopy medium thin center>
                            {__('imei.how-to')}
                            <InfoLink
                                to={__('routes.help.imei')}
                                target="_blank"
                            >
                                IMEI?
                            </InfoLink>
                        </BodyCopy>
                    </>
                </Hidden>
                <div styleName="section-next-button">
                    {this.showNotice()}
                    {this.nextButton}
                    <Hidden until={this.isNotCompatible}>
                        <Button
                            fullWidth
                            // loading={isFetching}
                            fullWidthMobile
                            minWidth="220px"
                            onClick={() => {
                                this.handleCheckForMe();
                                this.tealiumEventUnlockedNext();
                            }}
                        >
                            {__('imei.self.check-for-me')}
                        </Button>
                    </Hidden>
                    {this.getNewPhoneButton}
                </div>
            </>
        );
    }

    private get isStandalone(): boolean {
        const location: string = this.props.location.pathname || '';
        const standalone = __('routes.compatibility');
        return location.substr(0, standalone.length) === standalone;
    }

    get fetchStatus(): FetchStatus {
        const {
            cart,
            deviceCompatibilityFetchStatus,
            pendingItem,
        } = this.props;
        if (pendingItem) {
            return pendingItem.error && pendingItem.error.trim()
                ? FetchStatus.ERROR
                : FetchStatus.FETCHING;
        }
        if (deviceCompatibilityFetchStatus !== FetchStatus.NOT_FETCHED) {
            return deviceCompatibilityFetchStatus;
        }
        if (cart && cart.hasProductType(ProductType.HANDSET)) {
            return FetchStatus.SUCCESS;
        }
        return FetchStatus.NOT_FETCHED;
    }

    private get isFetching(): boolean {
        const {
            props: { deviceCompatibilityFetchStatus },
        } = this;
        return this.fetchStatus === deviceCompatibilityFetchStatus;
    }

    private get isCompatibilityFetching(): boolean {
        return this.state.compatibilityFetchStatus === FetchStatus.FETCHING;
    }

    private get isNextButtonDisable(): boolean {
        const {
            state: {
                selectedOperatingSystem,
                selectedModel,
                selectedCarrier,
                selectedUnlocked,
                compatibleCode,
            },
            props: { data },
        } = this;
        return (
            selectedOperatingSystem === null ||
            (selectedOperatingSystem === 0 &&
                (!selectedModel ||
                    !selectedCarrier ||
                    selectedUnlocked === null ||
                    selectedUnlocked === 0 ||
                    selectedOperatingSystem === null ||
                    (compatibleCode === 'NMI' &&
                        (data.imei.length < 14 || data.imei.length > 16)))) ||
            (selectedOperatingSystem === 1 &&
                (data.imei.length < 14 || data.imei.length > 16))
        );
    }

    get error(): string {
        const { pendingItem } = this.props;
        if (pendingItem) {
            return pendingItem.error;
        }
        return '';
    }

    private readonly showNotice = () => {
        const {
            state: { animateEmoji, compatible, compatibleMessage },
        } = this;
        const isCompatibiltyDetermined = compatible !== null;
        this.initCompatibility();
        if (isCompatibiltyDetermined) {
            return (
                <div
                    styleName={classNames('notice-wrapper', {
                        collapsed: !isCompatibiltyDetermined,
                        incompatible: isCompatibiltyDetermined && !compatible,
                    })}
                >
                    <div styleName="notice">
                        <>
                            {compatible ? (
                                <Emoji.D
                                    styleName={classNames('emoji', {
                                        animate: animateEmoji,
                                    })}
                                />
                            ) : (
                                <Emoji.Squiggle
                                    styleName={classNames('emoji', {
                                        animate: animateEmoji,
                                    })}
                                />
                            )}
                            <span>{compatibleMessage}</span>
                        </>
                    </div>
                </div>
            );
        }
    };

    private readonly initCompatibility = () => {
        const {
            deviceCompatibilityFetchStatus,
            deviceCompatibilityResponse,
        } = this.props;
        const {
            compatible,
            compatibleCode,
            compatibleMessage,
            lastFetchStatus,
        } = this.state;
        if (lastFetchStatus === deviceCompatibilityFetchStatus) {
            return;
        }
        switch (deviceCompatibilityFetchStatus) {
            case FetchStatus.FETCHING:
            case FetchStatus.NOT_FETCHED:
            case FetchStatus.ERROR:
                if (
                    compatible !== null ||
                    compatibleCode !== '' ||
                    compatibleMessage !== ''
                ) {
                    this.setState({
                        ...DEFAULT_COMPATIBILITY,
                        lastFetchStatus: deviceCompatibilityFetchStatus,
                        compatible: false,
                    });
                }
                return;
            case FetchStatus.SUCCESS:
                this.setState({
                    compatible:
                        deviceCompatibilityResponse.compatibility === 'Y',
                    compatibleCode: deviceCompatibilityResponse.compatibility,
                    lastFetchStatus: deviceCompatibilityFetchStatus,
                    compatibilityFetchStatus: FetchStatus.SUCCESS,
                });
                if (deviceCompatibilityResponse.compatibility == 'Y') {
                    this.setState({
                        compatibleMessage:
                            deviceCompatibilityResponse.message ||
                            __('imei.self.result.success.message'),
                    });
                } else if (
                    !compatible &&
                    deviceCompatibilityResponse.swappable === 'true'
                ) {
                    this.setState({
                        compatibleMessage:
                            deviceCompatibilityResponse.message ||
                            __('imei.self.result.swap.error.message'),
                    });
                } else {
                    this.setState({
                        compatibleMessage:
                            deviceCompatibilityResponse.message ||
                            __('imei.self.result.error.message'),
                    });
                }

                setTimeout(this.animateEmoji, 0);
                return;
        }
    };

    private readonly initSavedIMEI = (props: Props) => {
        const imei = this.getIMEI(props);
        if (imei !== '' && !this.state.imeiInjected) {
            this.setState({ imeiInjected: true });
            this.props.handleOnChange({
                target: {
                    name: 'imei',
                    value: imei,
                },
            });
        }
    };

    private readonly getIMEI = (props): string => {
        const { cart, handset, prefilledIMEI } = props;
        if (prefilledIMEI) {
            return prefilledIMEI;
        }
        if (handset) {
            const handsetItem = cart
                ? cart.itemForProductType(ProductType.HANDSET)
                : undefined;
            if (handsetItem) {
                return (
                    (handsetItem as HandsetCartItem).serialNumber ||
                    'Unable to get serial number from cart'
                );
            }
        }
        return '';
    };

    private get isGetNewPhone(): boolean {
        const {
            state: { checkMyself, compatible, compatibleCode },
        } = this;
        const isCompatible = compatible === true;
        const isCompatibiltyCodeNMI = compatibleCode === 'NMI';
        const isCompatibiltyDetermined = compatible !== null;
        return (
            !!checkMyself &&
            isCompatibiltyDetermined &&
            !isCompatible &&
            !isCompatibiltyCodeNMI &&
            // TODO(dan) Remove when Get New Phone is supported
            false
        );
    }

    private get isNextButton(): boolean {
        const {
            state: { checkMyself, compatible },
        } = this;
        const isCompatible = compatible === true;
        const isCompatibiltyDetermined = compatible !== null;
        return (
            (!!checkMyself && !isCompatibiltyDetermined) ||
            (!!checkMyself && isCompatibiltyDetermined && isCompatible) ||
            !checkMyself
        );
    }

    private get isNotCompatible(): boolean {
        const {
            state: { checkMyself, compatible, compatibleCode },
        } = this;
        const isCompatible = compatible === true;
        const isCompatibiltyCodeNMI = compatibleCode === 'NMI';
        const isCompatibiltyDetermined = compatible !== null;
        return (
            !!checkMyself &&
            isCompatibiltyDetermined &&
            !isCompatible &&
            isCompatibiltyCodeNMI
        );
    }

    private readonly submitItem = (): void => {
        const {
            addItemsCart,
            data: { imei },
        } = this.props;
        const {
            // checkMyself,
            compatible,
            selectedCarrier,
            selectedModel,
        } = this.state;
        if (compatible) {
            if (imei) {
                addItemsCart({
                    items: [
                        {
                            type: 'byod',
                            imei: imei,
                            os: this.getOS(),
                        },
                    ],
                });
            } else {
                addItemsCart({
                    items: [
                        {
                            type: 'byod',
                            os: this.getOS(),
                            carrier: selectedCarrier,
                            deviceName: selectedModel,
                        },
                    ],
                });
            }
            return;
        }
    };

    private readonly queueTransition = () => {
        // this.submitItem();
        // We are in compatibility, not BYOD.  Must navigate.
        setTimeout(() => (window.location.href = __('routes.byod')));
    };

    private readonly handleCheckForMe = (): void => {
        this.selectCheckMyself(false);
    };

    private readonly tealiumEventUnlockedNext = () => {
        TealiumDispatcher.dispatchEvent(
            TealiumFlowName.UNLOCKED,
            TealiumPageType.STANDALONE_COMPATABILITY,
            TealiumPagevents.NEXT,
            TealiumPageLinkLocations.MODULE_TWO,
            TealiumLinkEvents.BUTTON,
        );
        TealiumDispatcher.dispatchView(
            TealiumFlowName.UNLOCKED,
            TealiumPageType.STANDALONE_COMPATABILITY,
        );
    };

    private readonly canCalculate = () => {
        const {
            selectedCarrier,
            selectedModel,
            selectedOperatingSystem,
            selectedUnlocked,
        } = this.state;
        const { deviceCompatibilityResponse } = this.props;
        const isCalculable =
            selectedCarrier !== '' &&
            selectedModel !== '' &&
            selectedOperatingSystem !== null &&
            selectedUnlocked !== null;
        if (isCalculable) {
            if (deviceCompatibilityResponse.compatibility === 'Y') {
                return true;
            } else {
                this.calculateCompatibilityReset();
            }
        }
        return isCalculable;
    };

    private readonly handleUpdateIMEI = (event, handleChange) => {
        const { compatibleCode, isIMEIErrorDisplayed, prevValue } = this.state;
        const { imei } = this.props.data;
        handleChange(event);
        if (
            compatibleCode === 'NMI' &&
            isIMEIErrorDisplayed &&
            Number(imei) < prevValue
        ) {
            this.calculateCompatibilityReset();
        } else if (compatibleCode !== 'NMI') {
            this.calculateCompatibilityReset();
        }
        this.setState({ prevValue: Number(imei) });
    };

    private readonly calculateCompatibility = () => {
        const { selectedCarrier, selectedModel, selectedUnlocked } = this.state;
        const { checkDevice } = this.props;
        if (selectedUnlocked === 0) {
            return false;
        } else {
            checkDevice({
                carrier: selectedCarrier,
                model: selectedModel,
                os: this.getOS(),
            });
            this.setState({
                compatibilityFetchStatus: FetchStatus.FETCHING,
            });
        }
    };

    private readonly calculateCompatibilityReset = (): void => {
        const {
            compatible,
            compatibleCode,
            compatibleMessage,
            lastFetchStatus,
        } = this.state;
        if (
            compatible !== null ||
            compatibleCode !== '' ||
            compatibleMessage !== '' ||
            lastFetchStatus !== FetchStatus.NOT_FETCHED
        ) {
            this.setState({
                compatible: null,
                compatibleCode: '',
                compatibleMessage: '',
                lastFetchStatus: FetchStatus.NOT_FETCHED,
                isIMEIErrorDisplayed: false,
                compatibilityFetchStatus: FetchStatus.NOT_FETCHED,
            });
        }
    };

    private readonly getCarrierOptions = (): ReadonlyArray<ChoiceOption> => {
        const { deviceCompatibilityCarriersModels } = this.props;
        if (
            !deviceCompatibilityCarriersModels ||
            !deviceCompatibilityCarriersModels.carriers
        ) {
            return Array<ChoiceOption>();
        }
        // TODO(dan): This massaging of data is risky and may someday cause unexpected results.  The API should return exactly what should display.
        const fixCarriers = deviceCompatibilityCarriersModels.carriers.filter(
            item => {
                return item !== '-' && item !== 'Other';
            },
        );
        fixCarriers.push('Other');
        return fixCarriers.map(r => ({
            label: r,
            value: r,
        }));
    };

    private readonly getModel = (OS: string): ReadonlyArray<ChoiceOption> => {
        const { deviceCompatibilityCarriersModels } = this.props;
        const found:
            | IDeviceModel
            | undefined = deviceCompatibilityCarriersModels.models.find(
            (element: IDeviceModel) => {
                return element && OS in element;
            },
        );
        if (!found) {
            return Array<ChoiceOption>();
        }
        // TODO(dan): This massaging of data is risky and may someday cause unexpected results.  The API should return exactly what should display.
        const fixModels = found[OS].filter(item => {
            return item !== '-' && item !== 'Other';
        });
        fixModels.push('Other');
        return fixModels.map(r => ({
            label: r,
            value: r,
        }));
    };

    private readonly getModelOptions = (): ReadonlyArray<ChoiceOption> => {
        const OS = this.getOS();
        if (OS) {
            return this.getModel(OS);
        }
        return Array<ChoiceOption>();
    };

    private readonly getOS = (): string => {
        const {
            state: { selectedOperatingSystem },
        } = this;
        if (selectedOperatingSystem === null) {
            return '';
        }
        const selectedOS =
            selectedOperatingSystem !== null ? selectedOperatingSystem : '';
        return DEVICE_OS[selectedOS];
    };

    private readonly selectCheckMyself = (answer: boolean) => {
        if (!answer) {
            this.props.clearCheckDevice();
        }
        this.setState({
            animateEmoji: true,
            checkMyself: answer,
            ...DEFAULT_COMPATIBILITY,
            selectedCarrier: '',
            selectedModel: '',
            selectedOperatingSystem: null,
            selectedUnlocked: null,
            isIMEIErrorDisplayed: false,
        });
    };

    private readonly selectOperatingSystem = (answer: boolean) => {
        this.setState({
            compatible: null,
            selectedModel: '',
            selectedUnlocked: null,
            selectedOperatingSystem: answer ? 0 : 1,
            isIMEIErrorDisplayed: false,
            compatibilityFetchStatus: FetchStatus.NOT_FETCHED,
        });
        if (answer) {
            TealiumDispatcher.dispatchView(
                TealiumFlowName.MODEL,
                TealiumPageType.STANDALONE_COMPATABILITY,
            );
        } else {
            TealiumDispatcher.dispatchView(
                TealiumFlowName.IMEI,
                TealiumPageType.STANDALONE_COMPATABILITY,
            );
        }
    };

    private readonly selectUnlocked = (answer: boolean) => {
        const { lockedCheckDevice } = this.props;
        this.setState({
            selectedUnlocked: answer ? 1 : 0,
            isIMEIErrorDisplayed: true,
            compatible: null,
        });
        if (!answer) {
            lockedCheckDevice();
        }
    };

    private readonly animateEmoji = () => {
        if (this && this.setState) {
            this.setState({ animateEmoji: false });
        }
    };

    private readonly handleCheckIMEI = (
        event: React.FormEvent<HTMLFormElement>,
    ): void => {
        event.preventDefault();
        this.tealiumEventNext();
        const {
            props: { redirectTo, deviceCompatibilityResponse, isEnableSwap },
            state: {
                isIMEIErrorDisplayed,
                selectedOperatingSystem,
                compatible,
            },
        } = this;
        this.submitItem();
        if (
            (isIMEIErrorDisplayed &&
                selectedOperatingSystem !== 0 &&
                deviceCompatibilityResponse.swappable === 'false' &&
                deviceCompatibilityResponse.compatibility === 'N') ||
            // This is a terrible hack to get swap working
            (!isEnableSwap &&
                deviceCompatibilityResponse.swappable === 'true') ||
            (selectedOperatingSystem === 0 &&
                (deviceCompatibilityResponse.compatibility === 'N' ||
                    deviceCompatibilityResponse.compatibility === ''))
        ) {
            redirectTo(__('routes.incompatible.imei'));
        } else if (
            isEnableSwap &&
            (!compatible && deviceCompatibilityResponse.swappable === 'true')
        ) {
            redirectTo(__('routes.swap.landing'));
        }
    };

    private readonly checkIMEIService = (
        event: React.FormEvent<HTMLFormElement>,
    ): void => {
        event.preventDefault();
        const {
            props: {
                checkDevice,
                data: { imei: deviceId },
            },
            state: {
                isIMEIErrorDisplayed,
                selectedOperatingSystem,
                compatibleCode,
            },
        } = this;
        this.calculateCompatibilityReset();
        if (
            ((isIMEIErrorDisplayed === false &&
                selectedOperatingSystem !== 0) ||
                compatibleCode === 'NMI') &&
            deviceId
        ) {
            const deviceIdWithNoSpaces = deviceId.replace(/\s/g, '');
            if (
                deviceIdWithNoSpaces.length >= 14 &&
                deviceIdWithNoSpaces.length <= 16
            ) {
                checkDevice({ deviceId: deviceIdWithNoSpaces });
                this.setState({
                    isIMEIErrorDisplayed: true,
                    compatibilityFetchStatus: FetchStatus.FETCHING,
                });
            }
        }
    };
}

export { IMEI };
