import { withInputValidation } from 'components/common/forms/input';
import { withModal } from 'components/common/modal';
import { ReauthenticationModal } from 'components/reauthentication-modal/reauthentication-modal';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { finishReauthenticationWithPassword } from 'state/authentication/authentication.actions';

const dataKeys: ReadonlyArray<string> = ['password'];

const mapStateToProps = (state: IApplicationState) => ({
    error: state.authentication.reauthenticationError,
    status: state.authentication.reauthenticationStatus,
});

const mapDispatchToProps = dispatch => ({
    finishReauthenticationWithPassword: pw =>
        dispatch(finishReauthenticationWithPassword(pw)),
});

const enhance = compose(
    withModal,
    withInputValidation(dataKeys),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const ReauthenticationModalContainer = enhance(ReauthenticationModal);

export { ReauthenticationModalContainer };
