import * as React from 'react';

import { IAnalyticsProps } from 'components/common/analytics';
import { Label } from 'components/common/typography';
import { SelectContainer as Select } from 'containers';
import { ChoiceOption } from 'models';
import { ICarrier } from 'models/port';

interface Props extends IAnalyticsProps {
    readonly carriers: ReadonlyArray<ICarrier>;
    readonly complete: boolean;
    readonly label?: string;
    handleChange(carrier: ICarrier): void;
}

interface State {
    readonly selectedOperatorName: string;
}

class SelectCarrier extends React.Component<Props, State> {
    componentWillMount() {
        this.setState({ selectedOperatorName: '' });
    }

    render(): JSX.Element {
        const { carriers, label } = this.props;
        const { selectedOperatorName } = this.state;
        return (
            <>
                <Label id="payment_label" htmlFor="default-payment" normal>
                    {label}
                </Label>
                <Select
                    handleChange={this.handleChange}
                    options={this.getOptions(carriers)}
                    value={selectedOperatorName}
                />
            </>
        );
    }

    private readonly getOptions = (
        carriers: ReadonlyArray<ICarrier>,
    ): ReadonlyArray<ChoiceOption> => {
        return carriers.map(c => ({
            label: c.operatorName,
            value: c.operatorName,
        }));
    };

    private readonly handleChange = (val): void => {
        const { carriers, handleChange } = this.props;
        this.setState({ selectedOperatorName: val });
        const selectedCarrier = carriers.find(c => c.operatorName === val);
        if (selectedCarrier) {
            handleChange(selectedCarrier);
        }
    };
}

export { SelectCarrier };
