// tslint:disable:max-line-length
import * as React from 'react';

interface Props extends React.HTMLProps<SVGElement> {
    readonly invert?: boolean;
}

const F = ({ invert, ...other }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="115"
        height="115"
        viewBox="0 0 115 115"
    >
        <g fill={invert ? '#ffffff' : '#1800FF'} fillRule="evenodd">
            <path d="M114.389 48H3v18.857h45.591v36.533H67.45V66.857H95.52v36.533h18.859V66.857h.009zM25.247 11.123c0 6.143-4.981 11.124-11.125 11.124C7.979 22.247 3 17.266 3 11.123 3 4.981 7.98 0 14.122 0c6.144 0 11.125 4.981 11.125 11.123M114.247 11.123c0 6.143-4.981 11.124-11.124 11.124C96.98 22.247 92 17.266 92 11.123 92 4.981 96.98 0 103.123 0c6.143 0 11.124 4.981 11.124 11.123" />
        </g>
    </svg>
);

export { F };
