import { withAnalytics } from 'components/common/analytics';
import { StayInLoop } from 'components/stay-in-loop';
import { Account, IApplicationState } from 'models';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { loadCarriersModels } from 'state/device/compatibility/compatibility.actions';
import { captureStayInLoop } from '../state/stay-in-loop/stay-in-loop.actions';

const mapDispatchToProps = dispatch => ({
    captureStayInLoop: data => dispatch(captureStayInLoop(data)),
    redirectTo: (url: string) => dispatch(push(url)),
    loadCarriersModels: () => dispatch(loadCarriersModels()),
});

const mapStateToProps = (state: IApplicationState, ownProps) => ({
    authStatus: state.authentication.fetchStatus,
    fetchStatus: state.payment.autopay.fetchStatus,
    account: new Account(state.account),
    emailFooter: state.stayinloop.iStayInLoopData,
    deviceCompatibilityCarriersModels:
        state.device.compatibility.carriersModels,
    ...ownProps,
});

const enhance = compose(
    withAnalytics,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const StayInLoopContainer = enhance(StayInLoop);

export { StayInLoopContainer };
