import { BodyCopy, Headline } from 'components/common/typography';
import { IDeviceTypeProps, OMNI_SESSION_KEYS } from 'models';

import { IDeviceCompatibilityResponse } from 'models/device/device.model';
import { Link } from 'react-router-dom';

import {
    TealiumFlowName,
    TealiumPageType,
    TealiumPagevents,
    TealiumPageLinkLocations,
    TealiumLinkEvents,
    TealiumLoginState,
} from 'models/tealium';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';

import { IAnalyticsProps } from 'components/common/analytics';

//import { ButtonContainer as Button } from 'containers';

const styles = require('./swap.less');

interface IProps extends IAnalyticsProps, IDeviceTypeProps {
    readonly compatibility: IDeviceCompatibilityResponse;
}

@CSSModules(styles, { allowMultiple: true })
class Swap extends React.Component<IProps, {}> {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        TealiumDispatcher.dispatchView(
            TealiumFlowName.STANDALONE_COMPATABILITY_FLOW,
            TealiumPageType.LETS_SWAP,
        );
    }
    render() {
        return (
            <>
                <div styleName="swap-wrapper">
                    <img
                        src="/assets/images/icons/swap-icon.svg"
                        width="80px"
                    />
                    <Headline center> {__('swap.header')}</Headline>
                    <BodyCopy large center>
                        {__('swap.description')}
                    </BodyCopy>

                    <ul styleName="swap-list">
                        <li>{__('swap.list-1')}</li>
                        <li>{__('swap.list-2')}</li>
                        <li>{__('swap.list-3')}</li>
                        <li>{__('swap.list-4')}</li>
                    </ul>
                    <Link
                        styleName="swap-btn"
                        onClick={e => {
                            e.stopPropagation();
                            TealiumDispatcher.dispatchEvent(
                                TealiumFlowName.STANDALONE_COMPATABILITY_FLOW,
                                TealiumPageType.LETS_SWAP,
                                TealiumPagevents.SWAP,
                                TealiumPageLinkLocations.NONE,
                                TealiumLinkEvents.BUTTON,
                                TealiumLoginState.LOGGED_OUT,
                            );
                            this.saveCompatibilityCheck();
                        }}
                        to={__('routes.phones')}
                    >
                        {__('swap.button.text')}
                    </Link>

                    <a
                        styleName="swap-link"
                        onClick={e => {
                            e.stopPropagation();
                            TealiumDispatcher.dispatchEvent(
                                TealiumFlowName.STANDALONE_COMPATABILITY_FLOW,
                                TealiumPageType.LETS_SWAP,
                                TealiumPagevents.TOP_SHOP,
                                TealiumPageLinkLocations.NONE,
                                TealiumLinkEvents.BUTTON,
                            );
                        }}
                        href={__('routes.phones')}
                    >
                        {__('swap.button.shop')}
                    </a>

                    <Link
                        styleName="swap-check"
                        onClick={e => {
                            e.stopPropagation();
                            TealiumDispatcher.dispatchEvent(
                                TealiumFlowName.STANDALONE_COMPATABILITY_FLOW,
                                TealiumPageType.LETS_SWAP,
                                TealiumPagevents.CHECK_AGAIN,
                                TealiumPageLinkLocations.NONE,
                                TealiumLinkEvents.TEXT_LINK,
                            );
                        }}
                        to={__('routes.compatibility')}
                    >
                        {__('swap.button.check')}
                    </Link>
                </div>
            </>
        );
    }

    private readonly saveCompatibilityCheck = () => {
        const {
            props: { compatibility },
        } = this;
        if (compatibility) {
            sessionStorage.setItem(
                OMNI_SESSION_KEYS.model,
                compatibility.deviceId!,
            );
            sessionStorage.setItem(OMNI_SESSION_KEYS.swap, 'true');
        }
    };
}

export { Swap };
