import { createAction, IAction } from 'lib/br-redux';
import { IRegisterableUser } from 'models';
import { IValidateShortCodeResponse } from 'models/referral/referral.model';
// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    REGISTER_WITH_EMAIL_ADDRESS = 'REGISTRATION/REGISTER_WITH_EMAIL_ADDRESS',
    REPORT_REGISTRATION_SUCCESS = 'REGISTRATION/REPORT_REGISTRATION_SUCCESS',
    REPORT_REGISTRATION_ERROR = 'REGISTRATION/REPORT_REGISTRATION_ERROR',

    VALIDATE_SHORT_CODE_BEFORE_SIGNIN = 'REGISTRATION/VALIDATE_SHORT_CODE_BEFORE_SIGNIN',
    REPORT_VALIDATE_SHORT_BEFORE_SIGNIN_CODE_SUCCESS = 'REGISTRATION/REPORT_VALIDATE_SHORT_CODE__SIGNIN_SUCCESS',
    REPORT_VALIDATE_SHORT_BEFORE_SIGNIN_CODE_FAILURE = 'REGISTRATION/REPORT_VALIDATE_SHORT_CODE__SIGNIN_FAILURE',

    START_REGISTRATION_WITH_SOCIAL_INFO = 'REGISTRATION/START_REGISTRATION_WITH_SOCIAL_INFO',
    FINISH_REGISTRATION_WITH_SOCIAL_INFO = 'REGISTRATION/FINISH_REGISTRATION_WITH_SOCIAL_INFO',
    SET_INVITE_CODE = 'REGISTRATION/SET_INVITE_CODE',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type IRegisterWithEmailAddressAction = IAction<
    ActionType.REGISTER_WITH_EMAIL_ADDRESS,
    IRegisterableUser
>;
type IReportRegistrationSuccessAction = IAction<
    ActionType.REPORT_REGISTRATION_SUCCESS
>;
type IReportRegistrationError = IAction<
    ActionType.REPORT_REGISTRATION_ERROR,
    string
>;

type IValidateShortCodeBeforeSignInAction = IAction<
    ActionType.VALIDATE_SHORT_CODE_BEFORE_SIGNIN,
    IRegisterableUser
>;

type IValidateShortCodeBeforeSignInSuccessAction = IAction<
    ActionType.REPORT_VALIDATE_SHORT_BEFORE_SIGNIN_CODE_SUCCESS,
    IValidateShortCodeResponse
>;

type IValidateShortCodeBeforeSignInFailureAction = IAction<
    ActionType.REPORT_VALIDATE_SHORT_BEFORE_SIGNIN_CODE_FAILURE,
    string
>;

type ISetInviteCodeAction = IAction<ActionType.SET_INVITE_CODE, string>;

interface ISocialRegistrationInfo {
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
}

type IStartRegistrationWithSocialInfoAction = IAction<
    ActionType.START_REGISTRATION_WITH_SOCIAL_INFO
>;

type IFinishRegistrationWithSocialInfoAction = IAction<
    ActionType.FINISH_REGISTRATION_WITH_SOCIAL_INFO,
    ISocialRegistrationInfo
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IRegistrationAction =
    | IRegisterWithEmailAddressAction
    | IReportRegistrationSuccessAction
    | IReportRegistrationError
    | IValidateShortCodeBeforeSignInAction
    | IValidateShortCodeBeforeSignInSuccessAction
    | IValidateShortCodeBeforeSignInFailureAction
    | IStartRegistrationWithSocialInfoAction
    | IFinishRegistrationWithSocialInfoAction
    | ISetInviteCodeAction;

// ------------------------------------
// Action Creators
// i.e. the stuff that actually makes it to the compiled Javascript
// ------------------------------------

const registerUserWithEmailAddress = createAction(
    ActionType.REGISTER_WITH_EMAIL_ADDRESS,
    (user: IRegisterableUser) => user,
);

const reportRegistrationSuccess = createAction(
    ActionType.REPORT_REGISTRATION_SUCCESS,
);

const reportRegistrationError = createAction(
    ActionType.REPORT_REGISTRATION_ERROR,
    (error: string) => error,
);

const validateShortCode = createAction(
    ActionType.VALIDATE_SHORT_CODE_BEFORE_SIGNIN,
    (user: IRegisterableUser) => user,
);

const reportShortCodeSuccess = createAction(
    ActionType.REPORT_VALIDATE_SHORT_BEFORE_SIGNIN_CODE_SUCCESS,
);

const reportShortCodeError = createAction(
    ActionType.REPORT_VALIDATE_SHORT_BEFORE_SIGNIN_CODE_FAILURE,
    (error: string) => error,
);

const startRegistrationWithSocialInfo = createAction(
    ActionType.START_REGISTRATION_WITH_SOCIAL_INFO,
);

const finishRegistrationWithSocialInfo = createAction(
    ActionType.FINISH_REGISTRATION_WITH_SOCIAL_INFO,
    (info: ISocialRegistrationInfo) => info,
);

const setInviteCode = createAction(
    ActionType.SET_INVITE_CODE,
    (code: string) => code,
);

export {
    ActionType,
    IRegisterWithEmailAddressAction,
    IReportRegistrationSuccessAction,
    IReportRegistrationError,
    IValidateShortCodeBeforeSignInAction,
    IValidateShortCodeBeforeSignInSuccessAction,
    IValidateShortCodeBeforeSignInFailureAction,
    validateShortCode,
    reportShortCodeSuccess,
    reportShortCodeError,
    IRegistrationAction,
    IStartRegistrationWithSocialInfoAction,
    IFinishRegistrationWithSocialInfoAction,
    registerUserWithEmailAddress,
    reportRegistrationSuccess,
    reportRegistrationError,
    finishRegistrationWithSocialInfo,
    startRegistrationWithSocialInfo,
    setInviteCode,
};
