import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';
import { Dots } from 'components/common/dots';
import { BodyCopy } from 'components/common/typography';
import {
    BraintreeDropInContainer as BraintreeDropIn,
    ButtonContainer as Button,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { IDeviceTypeProps, IModalProps } from 'models';

const styles = require('./pay-now.less');

// tslint:disable:no-console

interface IState {
    readonly error: string;
}

interface IProps extends IAnalyticsProps, IDeviceTypeProps, IModalProps {
    readonly chargeAmount: number;
    readonly payNowError: string;
    readonly payNowSubmissionStatus: FetchStatus;
    submitPayNow(nonce: string, amount: number): void;
}

@CSSModules(styles, { allowMultiple: true })
class PayNow extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            error: '',
        };
    }

    componentWillReceiveProps({ payNowError: error }: IProps) {
        const { payNowError } = this.props;
        if (error && error !== payNowError) {
            this.setState({ error });
        }
    }

    render() {
        const { error } = this.state;
        if (error) {
            return (
                <>
                    <BodyCopy error>{error}</BodyCopy>
                    <Button
                        fullWidthMobile
                        onClick={this.clearError}
                        label="Try Again"
                    />
                </>
            );
        }
        return this.paymentComponent;
    }

    private readonly clearError = () => {
        this.setState({ error: '' });
    };

    private get paymentComponent(): JSX.Element {
        return this.braintreeComponent || <Dots />;
    }

    private readonly handlePaymentNonce = (nonce: string): void => {
        this.props.submitPayNow(nonce, this.props.chargeAmount);
    };

    private get braintreeComponent(): JSX.Element {
        return (
            <BraintreeDropIn
                renderSubmitButton={this.renderSubmitButton}
                onError={error => console.log(error)}
                onGetPaymentNonce={this.handlePaymentNonce}
            />
        );
    }

    private readonly renderSubmitButton = ({
        onClick,
        isDisabled,
    }): JSX.Element => {
        const { payNowSubmissionStatus } = this.props;
        return (
            <Button
                fullWidthMobile
                margin15Top
                noMarginBottom
                onClick={onClick}
                disabled={isDisabled}
                loading={payNowSubmissionStatus === FetchStatus.FETCHING}
                label={__('account.payNow.next')}
            />
        );
    };
}

export { PayNow };
