import * as React from 'react';

import { IAnalyticsProps } from 'components/common/analytics';
import { BodyCopy } from 'components/common/typography/bodycopy/bodycopy';
import { ErrorMsg } from 'components/common/typography/error-msg';
import { ButtonContainer as Button, InputContainer as Input } from 'containers';
import { IInputValidationProps } from 'models';
import { IAccountInfo } from 'models/port';
import { InputValidator, ValidationResponse } from 'utils';

interface Props extends IAnalyticsProps, IInputValidationProps {
    readonly accountNumberInstructions: string;
    readonly havingTrouble: string;
    readonly havingTroubleLabel: string;
    readonly instructionDescriptionLabel: string;
    readonly operatorName: string;
    readonly pinNumberInstructions: string;
    readonly support: string;
    readonly supportNumberLabel: string;

    readonly isLoading: boolean;
    readonly error: string;
    handleSubmit(info: IAccountInfo): void;
}

interface AccountHelpProps {
    readonly label: string;
    readonly addBreaks?: boolean;
    readonly children?: any;
}
const AccountHelpText = ({ label, addBreaks, children }: AccountHelpProps) =>
    children && children.length > 0 ? (
        <>
            <strong>{label}</strong> {children}
            {addBreaks && (
                <>
                    <br /> <br />
                </>
            )}
        </>
    ) : null;

class AccountInfo extends React.Component<Props, IAccountInfo> {
    render() {
        const {
            props: {
                accountNumberInstructions,
                data: { accountNumber, pin, zip },
                error,
                handleOnValidate,
                handleOnChange,
                isLoading,
                pinNumberInstructions,
                instructionDescriptionLabel,
                havingTrouble,
                havingTroubleLabel,
                support,
                supportNumberLabel,
            },
            hasErrors,
            handleSubmit,
            validateAccountNumber,
            validateBillingPin,
            validateBillingZip,
        } = this;
        const noMarginPaddingStyle = { margin: 0, padding: 0 };

        return (
            <form onSubmit={handleSubmit}>
                <div>
                    <div style={noMarginPaddingStyle}>
                        <Input
                            id="accountNumber"
                            label={__('account-info.account-number-label')}
                            description={__(
                                'account-info.account-number-description',
                            )}
                            showLabel
                            showDescription
                            placeholder={__(
                                'account-info.account-number-placeholder',
                            )}
                            onValidate={handleOnValidate}
                            validate={validateAccountNumber}
                            onChange={handleOnChange}
                            value={accountNumber}
                            noMargin
                        />
                        <BodyCopy>
                            <AccountHelpText
                                label={instructionDescriptionLabel}
                            >
                                {accountNumberInstructions}
                            </AccountHelpText>
                        </BodyCopy>
                    </div>
                </div>
                <div>
                    <div style={noMarginPaddingStyle}>
                        <Input
                            optional
                            id="pin"
                            label={__('account-info.billing-pin-label')}
                            description={__(
                                'account-info.billing-pin-description',
                            )}
                            showLabel
                            showDescription
                            placeholder={__('account-info.pin-placeholder')}
                            onValidate={handleOnValidate}
                            validate={validateBillingPin}
                            onChange={handleOnChange}
                            value={pin}
                            noMargin
                        />
                        <BodyCopy>
                            <AccountHelpText
                                addBreaks
                                label={instructionDescriptionLabel}
                            >
                                {pinNumberInstructions}
                            </AccountHelpText>
                            <AccountHelpText
                                addBreaks
                                label={havingTroubleLabel}
                            >
                                {havingTrouble}
                            </AccountHelpText>
                            <AccountHelpText label={supportNumberLabel}>
                                {support}
                            </AccountHelpText>
                        </BodyCopy>
                    </div>
                </div>
                <div>
                    <div style={noMarginPaddingStyle}>
                        <Input
                            id="zip"
                            label={__('account-info.zip-code-label')}
                            showLabel
                            placeholder={__('account-info.zip-placeholder')}
                            onValidate={handleOnValidate}
                            validate={validateBillingZip}
                            onChange={handleOnChange}
                            value={zip}
                        />
                    </div>
                </div>
                <div>
                    <div style={noMarginPaddingStyle}>
                        <Button
                            right
                            loading={isLoading}
                            disabled={isLoading || hasErrors}
                            type="submit"
                            label="Next"
                        />
                        <ErrorMsg>{error}</ErrorMsg>
                    </div>
                </div>
            </form>
        );
    }

    private get hasErrors(): boolean {
        return this.props.errors.accountNumber || this.props.errors.zip;
    }

    private readonly handleSubmit = (
        event: React.FormEvent<HTMLFormElement>,
    ): void => {
        event.preventDefault();
        if (!this.hasErrors) {
            this.props.handleSubmit(this.props.data);
        }
    };

    private readonly validateAccountNumber = (
        value: string,
    ): ValidationResponse => {
        const { NumbersLettersDashes } = InputValidator.Patterns;

        return new InputValidator(value, {
            errorMessage: __('account-info.account-number-error'),
        })
            .doesNotExceedLengthOf(20)
            .conformsToPattern(NumbersLettersDashes)
            .validate();
    };

    private readonly validateBillingPin = (
        value: string,
    ): ValidationResponse => {
        const { Numeric } = InputValidator.Patterns;

        return new InputValidator(value, {
            errorMessage: __('account-info.pin-error'),
            optional: true,
        })
            .conformsToPattern(Numeric)
            .validate();
    };

    private readonly validateBillingZip = (
        value: string,
    ): ValidationResponse => {
        const { Numeric } = InputValidator.Patterns;

        return new InputValidator(value, {
            errorMessage: __('account-info.zip-error'),
        })
            .conformsToPattern(Numeric)
            .hasExactLengthOf(5)
            .validate();
    };
}

export { AccountInfo };
