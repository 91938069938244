import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';
import { BodyCopy } from 'components/common/typography';
import { GridTemplates } from 'components/grid';
import { Grid } from 'components/grid/grid';
import { ButtonContainer as Button, InputContainer as Input } from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { IInputValidationProps } from 'models';
import { Link } from 'react-router-dom';
import { InputValidator } from 'utils';

const styles = require('./forgot-password.less');

interface IProps extends IAnalyticsProps, IInputValidationProps {
    readonly error: string;
    readonly status: string;
    readonly location: Location;
    sendEmail(email: string): void;
    clearPasswordReset(): void;
}

@CSSModules(styles)
class ForgotPassword extends React.Component<IProps> {
    componentWillUnmount() {
        this.props.clearPasswordReset();
    }
    render(): JSX.Element {
        const {
            hasErrors,
            isFetching,
            isSuccess,
            props: {
                data: { email },
                error,
                handleOnChange,
                handleOnValidate,
            },
        } = this;

        return (
            <Grid>
                {isSuccess ? (
                    <>
                        <GridTemplates.HeadlineTemplate
                            lg
                            center
                            headline={__('forgot-password.successHeadline')}
                        >
                            <BodyCopy center>
                                {__('forgot-password.successBody')}
                            </BodyCopy>
                            <Button
                                noPaddingVertically
                                fullWidth
                                unPadded
                                type="button"
                            >
                                <Link to={__('routes.sign-in')}>
                                    {__('forgot-password.success-button')}
                                </Link>
                            </Button>
                        </GridTemplates.HeadlineTemplate>
                    </>
                ) : (
                    <>
                        <GridTemplates.HeadlineTemplate
                            lg
                            center
                            headline={__('forgot-password.headline')}
                        >
                            <BodyCopy center>
                                {__('forgot-password.body')}
                            </BodyCopy>

                            <form onSubmit={this.handleSubmit}>
                                <label
                                    htmlFor="email"
                                    styleName="forgotPasswordLabels"
                                >
                                    {__('forgot-password.emailPlaceholder')}
                                </label>
                                <Input
                                    error={error}
                                    id="email"
                                    value={email}
                                    onChange={handleOnChange}
                                    onValidate={handleOnValidate}
                                    validate={InputValidator.validateEmail}
                                />
                                <Button
                                    fullWidth
                                    rounded
                                    type="submit"
                                    disabled={hasErrors}
                                    loading={isFetching}
                                    label={__('forgot-password.submit')}
                                />
                            </form>
                        </GridTemplates.HeadlineTemplate>
                    </>
                )}
            </Grid>
        );
    }

    private get isSuccess(): boolean {
        const {
            props: { status },
        } = this;
        return status === FetchStatus.SUCCESS;
    }

    private get isFetching(): boolean {
        const {
            props: { status },
        } = this;
        return status === FetchStatus.FETCHING;
    }

    private get hasErrors(): boolean {
        const {
            props: {
                errors: { email },
            },
        } = this;
        return email;
    }

    private readonly handleSubmit = (
        event: React.FormEvent<HTMLFormElement>,
    ): void => {
        const {
            props: {
                data: { email },
                sendEmail,
            },
        } = this;
        event.preventDefault();
        sendEmail(email);
    };
}

export { ForgotPassword };
