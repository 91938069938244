import { Block } from 'components/common/block';
import { TextArea } from 'components/common/forms';
import { BodyCopy } from 'components/common/typography';
import { Grid, GridTemplates } from 'components/grid';
import { ButtonContainer as Button } from 'containers';
import { FetchStatus } from 'lib/br-redux';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
const styles = require('./feedback.less');

interface Props {
    readonly error: string;
    readonly fetchStatus: FetchStatus;
    submit(message: string): void;
    redirectTo(url: string): void;
}

interface State {
    readonly emptyMessageError: string;
    readonly message: string;
}

const DEFAULT_STATE: State = {
    emptyMessageError: '',
    message: '',
};

@CSSModules(styles, { allowMultiple: true })
class Feedback extends React.Component<Props, State> {
    componentWillMount() {
        this.setState(DEFAULT_STATE);
    }

    componentWillReceiveProps(newProps: Props) {
        const {
            props: { redirectTo },
        } = this;
        if (
            newProps.fetchStatus !== this.props.fetchStatus &&
            newProps.fetchStatus === FetchStatus.SUCCESS
        ) {
            setTimeout(() => redirectTo(__('routes.home')), 1000);
        }
    }

    render() {
        const { error, fetchStatus } = this.props;
        const { emptyMessageError, message } = this.state;
        return (
            <Grid>
                <GridTemplates.HeadlineTemplate
                    lg
                    center
                    headlineCentered
                    headline={__('feedback.infoTemplate.header')}
                >
                    <BodyCopy center>
                        {__('feedback.infoTemplate.body')}
                    </BodyCopy>
                    <form
                        styleName="feedback-form"
                        onSubmit={this.handleSubmit}
                    >
                        {error && error.length && (
                            <p styleName="error">{error}</p>
                        )}
                        {emptyMessageError && emptyMessageError.length && (
                            <p styleName="error">{emptyMessageError}</p>
                        )}
                        <Block width={5}>
                            <TextArea
                                id="message"
                                placeholder={__('feedback.form.body')}
                                value={message}
                                onChange={this.handleChange}
                                styleName="feedback-form-text-area"
                            />
                        </Block>

                        <Block width={2}>
                            <Button
                                disabled={fetchStatus === FetchStatus.FETCHING}
                                fullWidth
                                type="submit"
                                label={
                                    fetchStatus === FetchStatus.FETCHING
                                        ? __('feedback.form.buttonSubmit')
                                        : __('feedback.form.button')
                                }
                            />
                        </Block>
                    </form>
                </GridTemplates.HeadlineTemplate>
            </Grid>
        );
    }

    private readonly handleChange = (
        event: React.FormEvent<HTMLTextAreaElement>,
    ) => {
        const target = event.target as HTMLTextAreaElement;
        const message = target.value;
        this.setState({ message });
    };

    private readonly handleSubmit = (
        event: React.FormEvent<HTMLFormElement>,
    ): void => {
        event.preventDefault();
        const { submit, fetchStatus } = this.props;
        const { message } = this.state;
        if (message.trim().length === 0) {
            this.setState({ emptyMessageError: __('feedback.form.error') });
        } else if (fetchStatus !== FetchStatus.FETCHING) {
            submit(message);
        }
    };
}

export { Feedback };
