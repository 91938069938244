import * as React from 'react';

import { SearchBar } from 'components/common/search';

interface IProps {
    readonly searchTerm: string;
    searchFAQ(searchTerm: string): string;
}

class FAQSearchBar extends React.Component<IProps, {}> {
    componentDidMount(): void {
        const query = new URLSearchParams(location.search);
        const value: string | null = query.get('q');
        if (value && value.length > 0) {
            this.props.searchFAQ(value);
        }
    }

    componentWillUnmount(): void {
        this.props.searchFAQ('');
    }

    render(): JSX.Element {
        return (
            <SearchBar
                searchTerm={this.props.searchTerm}
                executeSearch={(searchTerm: string) =>
                    this.props.searchFAQ(searchTerm)
                }
            />
        );
    }
}

export { FAQSearchBar };
