import { createAction, IAction } from 'lib/br-redux';
import { VerificationPayload } from 'models/email-action';

enum ActionType {
    VERIFY_CODE = 'EMAIL_ACTION/VERIFY_CODE',
    REPORT_VERIFICATION_SUCCESS = 'EMAIL_ACTION/REPORT_VERIFICATION_SUCCESS',
    REPORT_VERIFICATION_FAILURE = 'EMAIL_ACTION/REPORT_VERIFICATION_FAILURE',

    RESET_PASSWORD = 'EMAIL_ACTION/RESET_PASSWORD',
    REPORT_PASSWORD_RESET_SUCCESS = 'EMAIL_ACTION/REPORT_PASSWORD_RESET_SUCCESS',
    REPORT_PASSWORD_RESET_FAILURE = 'EMAIL_ACTION/REPORT_PASSWORD_RESET_FAILURE',
}

type VerifyEmailActionCodeAction = IAction<
    ActionType.VERIFY_CODE,
    VerificationPayload
>;
type ReportCodeVerificationSuccess = IAction<
    ActionType.REPORT_VERIFICATION_SUCCESS
>;
type ReportCodeVerificationFailure = IAction<
    ActionType.REPORT_VERIFICATION_FAILURE
>;

type ResetPasswordAction = IAction<ActionType.RESET_PASSWORD, string>;
type ReportPasswordResetSuccessAction = IAction<
    ActionType.REPORT_PASSWORD_RESET_SUCCESS
>;
type ReportPasswordResetFailureAction = IAction<
    ActionType.REPORT_PASSWORD_RESET_FAILURE,
    string
>;

type EmailActionAction =
    | VerifyEmailActionCodeAction
    | ReportCodeVerificationSuccess
    | ReportCodeVerificationFailure
    | ResetPasswordAction
    | ReportPasswordResetSuccessAction
    | ReportPasswordResetFailureAction;

const verifyEmailActionCode = createAction(
    ActionType.VERIFY_CODE,
    (p: VerificationPayload) => p,
);

const reportEmailActionCodeVerificationSuccess = createAction(
    ActionType.REPORT_VERIFICATION_SUCCESS,
);

const reportEmailActionCodeVerificationFailure = createAction(
    ActionType.REPORT_VERIFICATION_FAILURE,
    (error: string) => error,
);

const resetPassword = createAction(
    ActionType.RESET_PASSWORD,
    (newPassword: string) => newPassword,
);
const reportPasswordResetSuccess = createAction(
    ActionType.REPORT_PASSWORD_RESET_SUCCESS,
);
const reportPasswordResetFailure = createAction(
    ActionType.REPORT_PASSWORD_RESET_FAILURE,
    (error: string) => error,
);

export {
    ActionType,
    VerifyEmailActionCodeAction,
    ReportCodeVerificationFailure,
    ReportCodeVerificationSuccess,
    EmailActionAction,
    verifyEmailActionCode,
    reportEmailActionCodeVerificationFailure,
    reportEmailActionCodeVerificationSuccess,
    ResetPasswordAction,
    ReportPasswordResetSuccessAction,
    ReportPasswordResetFailureAction,
    resetPassword,
    reportPasswordResetSuccess,
    reportPasswordResetFailure,
};
