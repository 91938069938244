import { TealiumFlowName, TealiumPageType } from 'models/tealium';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { RouteComponentProps } from 'react-router-dom';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';

const styles = require('./referral-terms.less');

interface Props extends RouteComponentProps<any> {
    readonly refCode: string;
    setRefCode(code: string): any;
}
@CSSModules(styles)
class ReferralLanding extends React.Component<Props> {
    componentDidMount() {       
        const {
            match: { params },
        } = this.props;
        if (params.refCode) {
            sessionStorage.removeItem('refCode');
            params.refCode && sessionStorage.setItem('refCode', params.refCode);
        }
       TealiumDispatcher.dispatchView(
            TealiumFlowName.NONE,
            TealiumPageType.REFERRAL,
        );
    }

    render() {
        return (
            <div>
                <iframe
                    src={__('routes.referralLanding.aem')}
                    styleName="iframe"
                />
            </div>
        );
    }
}

export { ReferralLanding };
