import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { Configuration, IConfiguration } from 'models';
import { ActionType, IConfigurationAction } from './configuration.actions';

interface IConfigurationState extends IConfiguration {
    readonly fetchStatus: FetchStatus;
    readonly error: string;
}

const DEFAULT_STATE: IConfigurationState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
    ...Configuration.DEFAULT,
};

const { FETCH, LOAD, REPORT_ERROR, REPORT_SUCCESS } = ActionType;

const configurationReducer = (
    state: IConfigurationState = DEFAULT_STATE,
    action: IConfigurationAction | IDefaultAction,
): IConfigurationState => {
    switch (action.type) {
        case FETCH:
            return {
                ...state,
                ...{
                    fetchStatus: FetchStatus.FETCHING,
                },
            };
        case LOAD:
            return {
                ...state,
                ...{
                    fetchStatus: FetchStatus.SUCCESS,
                    ...action.payload,
                },
            };
        case REPORT_ERROR:
            return {
                ...state,
                ...{
                    error: action.payload,
                    fetchStatus: FetchStatus.ERROR,
                },
            };
        case REPORT_SUCCESS:
            return {
                ...state,
                ...{
                    fetchStatus: FetchStatus.SUCCESS,
                },
            };
        default:
            return state;
    }
};

export { DEFAULT_STATE, IConfigurationState, configurationReducer };
