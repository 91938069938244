import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import {
    ModalWrapperContainer as ModalWrapper,
    PayNowContainer as PayNow,
} from 'containers';
import { IModalProps } from 'models';

const styles = require('./pay-now.less');

interface Props extends IModalProps {}

@CSSModules(styles)
class PayNowModal extends React.Component<Props> {
    render() {
        return (
            <ModalWrapper closable title={__('account.payNow.title')}>
                <PayNow />
            </ModalWrapper>
        );
    }
}

export { PayNowModal };
