import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import Props from './social-props';

const styles = require('./social.less');

@CSSModules(styles, { allowMultiple: true })
class Youtube extends React.Component<Props> {
    render() {
        const {
            props: { black },
        } = this;
        return (
            <a
                target="_blank"
                href="/"
                rel="noopener"
                styleName={classNames('social', { black })}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 16 12"
                >
                    <path
                        styleName="social-icon"
                        fill="#666"
                        fillRule="evenodd"
                        // tslint:disable-next-line:max-line-length
                        d="M6.348 7.757V3.229l4.323 2.272-4.323 2.256zm9.492-5.312s-.156-1.11-.636-1.6C14.595.203 13.914.2 13.601.163 11.362 0 8.004 0 8.004 0h-.008S4.638 0 2.4.163C2.086.2 1.404.203.796.845c-.48.49-.636 1.6-.636 1.6S0 3.749 0 5.053v1.222C0 7.58.16 8.883.16 8.883s.156 1.11.636 1.6c.608.641 1.408.621 1.764.688 1.28.124 5.44.162 5.44.162s3.362-.005 5.601-.168c.312-.038.994-.04 1.603-.683.48-.489.636-1.599.636-1.599S16 7.58 16 6.275V5.053c0-1.304-.16-2.608-.16-2.608z"
                    />
                </svg>
            </a>
        );
    }
}

export { Youtube };
