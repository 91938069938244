import { connect } from 'react-redux';
import { compose } from 'redux';
import { AccountTerminated } from 'components/account/account-terminated';
import { Account, IApplicationState } from 'models';
import { showModal } from 'state/view/view.actions';

const mapStateToProps = ({ account, bill }: IApplicationState) => ({
    account: new Account(account),
});

const mapDispatchToProps = dispatch => ({
    showModal: p => dispatch(showModal(p)),
});

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);

const AccountTerminatedContainer = enhance(AccountTerminated);

export { AccountTerminatedContainer };
