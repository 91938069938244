import {
    Configuration,
    IConfiguration,
    IConfigurationResponse,
    INonNormalizedConfiguration,
    IChatTokenResponse,
    StartupScript,
} from 'models';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ConfigurationService, ChatService } from 'services';
import { StartupActions } from 'state/startup';
import { trackError } from 'state/tealium';
import {
    ActionType,
    fetchConfiguration as dispatchFetch,
    loadConfiguration,
    reportConfigurationError,
    reportConfigurationSuccess,
} from './configuration.actions';
import {
    renderLivechatByFlag,
    configurePrechatSettings,
    populateFields,
    getAccountFromStorage,
} from './liveChat';

function* initialize() {
    yield put(dispatchFetch());
}

function* fetchConfiguration() {
    try {
        const response: IConfigurationResponse = yield call(
            ConfigurationService.fetchConfiguration,
        );
        const chatConfig: IChatTokenResponse = yield call(
            ChatService.fetchChatToken,
        );
        const configNonNormalized: INonNormalizedConfiguration = {
            ...response.appconfig,
            toggles: response.toggles,
        };
        const config: IConfiguration = Configuration.Normalize(
            configNonNormalized,
        );
        sessionStorage.setItem(
            'enableLiveAgentChat',
            config.toggles.enableLiveAgentChat ? 'Y' : 'N',
        );
        sessionStorage.setItem('chatConfig', JSON.stringify(chatConfig));
        renderLivechatByFlag(config.toggles.enableLiveAgentChat);
        const account = getAccountFromStorage();
        const chatConfigInfo: any = sessionStorage.getItem('chatConfig');
        const parsedChatConfig = JSON.parse(chatConfigInfo);
        if (account) {
            console.log('(web)i am in if condition at user logged in');
            populateFields(JSON.parse(account), parsedChatConfig);
        } else {
            console.log('(web)i am in else block');
            configurePrechatSettings(JSON.parse(chatConfigInfo));
            console.log('(web)i am in else block, moving out');
        }
        yield put(loadConfiguration(config));
        yield put(
            StartupActions.reportInitializationSuccess(
                StartupScript.CONFIGURATION,
            ),
        );
        yield put(reportConfigurationSuccess());
    } catch (error) {
        yield put(reportConfigurationError(error.message));
        yield put(
            StartupActions.reportInitializationFailure(
                StartupScript.CONFIGURATION,
            ),
        );
    }
}

function* configurationSaga() {
    yield all([
        takeEvery(ActionType.FETCH, fetchConfiguration),
        takeEvery(ActionType.REPORT_ERROR, trackError),
        initialize() as any,
    ]);
}

export { configurationSaga, fetchConfiguration };
