import {
    AlertModalContainer as AlertModal,
    LeadCaptureModalContainer as LeadCaptureModal,
} from 'containers';
import { IApplicationState } from 'models';
import { push } from 'react-router-redux';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { LeadService } from 'services';
import { trackError } from 'state/tealium';
import { dismissModal } from 'state/view/view.actions';
import { confirmWithModal } from 'state/view/view.saga';
import { smellyStoreSingleton } from 'utils/store';
import {
    ActionType,
    clearCaptureLead,
    ICaptureLeadAction,
    reportLeadCaptureError,
    reportLeadCaptureSuccess,
} from './lead.actions';

function* leadCaptureModal(action: ICaptureLeadAction) {
    yield call(confirmWithModal, {
        additionalProps: {
            email: action.payload,
        },
        component: LeadCaptureModal,
    });
    yield put(clearCaptureLead());
    yield put(dismissModal());
}

function* captureLead(action: ICaptureLeadAction) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const { dispatch } = smellyStoreSingleton.store;

        yield call(LeadService.capture, basePaths, action.payload);
        yield put(reportLeadCaptureSuccess());
        yield call(confirmWithModal, {
            additionalProps: {
                body: __('home.lead-capture.success.body'),
                buttonText: __('home.lead-capture.success.buttonText'),
                emoji: true,
                invert: true,
                onConfirm: () => {
                    dispatch(push(__('routes.stay-in-loop')));
                },
                title: __('home.lead-capture.success.title'),
            },
            component: AlertModal,
        });
        yield put(clearCaptureLead());
    } catch (err) {
        yield put(reportLeadCaptureError((err as Error).message));
    }
}

function* leadSaga() {
    yield all([
        takeEvery(ActionType.SHOW_LEAD_CAPTURE_MODAL, leadCaptureModal),
        takeEvery(ActionType.CAPTURE_LEAD, captureLead),
        takeEvery(ActionType.REPORT_LEAD_CAPTURE_ERROR, trackError),
    ]);
}

export { leadSaga };
