import { createAction, IAction } from 'lib/br-redux';
import {
    IAccount,
    INonNormalizedAccount,
    ICartRequest,
    ICartResponse,
    GroupMembershipDetailResponse,
    IOrderResponseWithPromotions,
} from 'models/account';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    ACTIVATE_ACCOUNT = 'ACCOUNT/ACTIVATE_ACCOUNT',
    REPORT_ACTIVATE_ACCOUNT_SUCCESS = 'ACCOUNT/REPORT_ACTIVATE_ACCOUNT_SUCCESS',
    REPORT_ACTIVATE_ACCOUNT_ERROR = 'ACCOUNT/REPORT_ACTIVATE_ACCOUNT_ERROR',

    RESET_PASSWORD_FOR_EMAIL = 'ACCOUNT/RESET_PASSWORD_FOR_EMAIL',
    REPORT_RESET_PASSWORD_FOR_EMAIL_SUCCESS = 'ACCOUNT/REPORT_RESET_PASSWORD_FOR_EMAIL_SUCCESS',
    REPORT_RESET_PASSWORD_FOR_EMAIL_ERROR = 'ACCOUNT/REPORT_RESET_PASSWORD_FOR_EMAIL_ERROR',
    CLEAR_PASSWORD_RESET = 'ACCOUNT/CLEAR_PASSWORD_RESET',

    SHOW_UPDATE_EMAIL = 'ACCOUNT/SHOW_UPDATE_EMAIL',
    REPORT_SHOW_UPDATE_EMAIL_DONE = 'ACCOUNT/REPORT_SHOW_UPDATE_EMAIL_DONE',

    UPDATE_EMAIL_ADDRESS = 'ACCOUNT/UPDATE_EMAIL_ADDRESS',
    REPORT_EMAIL_UPDATE_SUCCESS = 'ACCOUNT/REPORT_EMAIL_UPDATE_SUCCESS',
    REPORT_EMAIL_UPDATE_ERROR = 'ACCOUNT/REPORT_EMAIL_UPDATE_ERROR',

    UPDATE_USER_ACCOUNT = 'ACCOUNT/UPDATE_USER_ACCOUNT',
    UPDATE_USER_ACCOUNT_OLD = 'ACCOUNT/UPDATE_USER_ACCOUNT_OLD',
    REPORT_UPDATE_USER_ACCOUNT_SUCCESS = 'ACCOUNT/REPORT_UPDATE_USER_ACCOUNT_SUCCESS',
    REPORT_UPDATE_USER_ACCOUNT_ERROR = 'ACCOUNT/REPORT_UPDATE_USER_ACCOUNT_ERROR',
    RESET_UPDATE_ACCOUNT_FETCH_STATUS = 'RESET_UPDATE_ACCOUNT_FETCH_STATUS',

    LOAD_USER_ACCOUNT = 'ACCOUNT/LOAD_USER_ACCOUNT',

    SET_PRIVACY_OPT_IN_OUT = 'ACCOUNT/SET_PRIVACY_OPT_IN_OUT',
    REPORT_PRIVACY_OPT_IN_OUT_SUCCESS = 'ACCOUNT/REPORT_PRIVACY_OPT_IN_OUT_SUCCESS',
    REPORT_PRIVACY_OPT_IN_OUT_ERROR = 'ACCOUNT/REPORT_PRIVACY_OPT_IN_OUT_ERROR',

    START_POLL_LAPSED_REACTIVATION = 'ACCOUNT/START_POLL_LAPSED_REACTIVATION',
    STOP_POLL_LAPSED_REACTIVATION = 'ACCOUNT/STOP_POLL_LAPSED_REACTIVATION',

    SHOW_ACCOUNT_SIGNUP_MODAL = 'ACCOUNT/SHOW_ACCOUNT_SIGNUP_MODAL',

    REPORT_FETCH_ORDERS_SUCCESS = 'ACCOUNT/REPORT_FETCH_ORDERS_SUCCESS',

    REPORT_FETCH_GROUP_MEMBERS_SUCCESS = 'ACCOUNT/REPORT_FETCH_GROUP_MEMBERS_SUCCESS',

    //cart actions
    CART_CHECK = 'ACCOUNT/CART_CHECK',
    CART_CHECK_SUCCESS = 'ACCOUNT/CART_CHECK_SUCCESS',
    CART_CHECK_FAILURE = 'ACCOUNT/CART_CHECK_FAILURE',

    //cart add items
    CART_ADD_ITEMS = 'ACCOUNT/ADD_ITEMS',
    CART_ADD_ITEMS_SUCCESS = 'ACCOUNT/ADD_ITEMS_SUCCESS',
    CART_ADD_ITEMS_FAILURE = 'ACCOUNT/ADD_ITEMS_FAILURE',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type IActivateAccountAction = IAction<ActionType.ACTIVATE_ACCOUNT>;

type IReportActivateAccountSuccessAction = IAction<
    ActionType.REPORT_ACTIVATE_ACCOUNT_SUCCESS,
    string
>;

type IReportActivateAccountErrorAction = IAction<
    ActionType.REPORT_ACTIVATE_ACCOUNT_ERROR,
    string
>;

type IResetPasswordForEmailAction = IAction<
    ActionType.RESET_PASSWORD_FOR_EMAIL,
    string
>;

type IReportResetPasswordForEmailSuccessAction = IAction<
    ActionType.REPORT_RESET_PASSWORD_FOR_EMAIL_SUCCESS,
    string
>;

type IReportResetPasswordForEmailErrorAction = IAction<
    ActionType.REPORT_RESET_PASSWORD_FOR_EMAIL_ERROR,
    string
>;

type IClearPasswordResetAction = IAction<ActionType.CLEAR_PASSWORD_RESET>;

type IShowUpdateEmailAction = IAction<ActionType.SHOW_UPDATE_EMAIL, string>;

type IReportShowUpdateEmailDoneAction = IAction<
    ActionType.REPORT_SHOW_UPDATE_EMAIL_DONE
>;

type IUpdateEmailAddressAction = IAction<
    ActionType.UPDATE_EMAIL_ADDRESS,
    string
>;

type IReportEmailUpdateSuccessAction = IAction<
    ActionType.REPORT_EMAIL_UPDATE_SUCCESS
>;

type IReportEmailUpdateErrorAction = IAction<
    ActionType.REPORT_EMAIL_UPDATE_ERROR,
    string
>;

type IUpdateUserAccountAction = IAction<
    ActionType.UPDATE_USER_ACCOUNT,
    Partial<INonNormalizedAccount>
>;

type IReportUpdateUserAccountSuccessAction = IAction<
    ActionType.REPORT_UPDATE_USER_ACCOUNT_SUCCESS,
    string
>;

type IReportUpdateUserAccountErrorAction = IAction<
    ActionType.REPORT_UPDATE_USER_ACCOUNT_ERROR,
    string
>;

type IResetUpdateAccountFetchStatusAction = IAction<
    ActionType.RESET_UPDATE_ACCOUNT_FETCH_STATUS,
    string
>;

type ILoadUserAccountAction = IAction<ActionType.LOAD_USER_ACCOUNT, IAccount>;

type SetPrivacyOptInOutAction = IAction<
    ActionType.SET_PRIVACY_OPT_IN_OUT,
    boolean
>;
type ReportPrivacyOptInOutSuccessAction = IAction<
    ActionType.REPORT_PRIVACY_OPT_IN_OUT_SUCCESS,
    boolean
>;
type ReportPrivacyOptInOutErrorAction = IAction<
    ActionType.REPORT_PRIVACY_OPT_IN_OUT_ERROR,
    string
>;

type IShowAccountSignupModalAction = IAction<
    ActionType.SHOW_ACCOUNT_SIGNUP_MODAL
>;

type ReportFetchOrderSuccessAction = IAction<
    ActionType.REPORT_FETCH_ORDERS_SUCCESS,
    IOrderResponseWithPromotions
>;

type ReportFetchGroupMembersSuccessAction = IAction<
    ActionType.REPORT_FETCH_GROUP_MEMBERS_SUCCESS,
    GroupMembershipDetailResponse
>;

type IReportCheckCartAction = IAction<ActionType.CART_CHECK>;

type IReportCheckCartSuccessAction = IAction<
    ActionType.CART_CHECK_SUCCESS,
    number
>;

type IReportCheckCartFailureAction = IAction<
    ActionType.CART_CHECK_FAILURE,
    string
>;

type IReportCartAddItems = IAction<
    ActionType.CART_ADD_ITEMS,
    Partial<ICartRequest>
>;

type IReportCartAddItemsSuccess = IAction<
    ActionType.CART_ADD_ITEMS_SUCCESS,
    ICartResponse
>;

type IReportCartAddItemsFailure = IAction<
    ActionType.CART_ADD_ITEMS_FAILURE,
    string
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IAccountAction =
    | IActivateAccountAction
    | IReportActivateAccountErrorAction
    | IReportActivateAccountSuccessAction
    | IResetPasswordForEmailAction
    | IReportResetPasswordForEmailErrorAction
    | IReportResetPasswordForEmailSuccessAction
    | IClearPasswordResetAction
    | IShowUpdateEmailAction
    | IReportShowUpdateEmailDoneAction
    | IUpdateEmailAddressAction
    | IReportEmailUpdateErrorAction
    | IReportEmailUpdateSuccessAction
    | IUpdateUserAccountAction
    | IReportUpdateUserAccountErrorAction
    | IResetUpdateAccountFetchStatusAction
    | IReportUpdateUserAccountSuccessAction
    | ILoadUserAccountAction
    | SetPrivacyOptInOutAction
    | ReportPrivacyOptInOutSuccessAction
    | ReportPrivacyOptInOutErrorAction
    | IShowAccountSignupModalAction
    | ReportFetchOrderSuccessAction
    | ReportFetchGroupMembersSuccessAction
    | IReportCheckCartAction
    | IReportCheckCartSuccessAction
    | IReportCheckCartFailureAction
    | IReportCartAddItems
    | IReportCartAddItemsSuccess
    | IReportCartAddItemsFailure;

// ------------------------------------
// Action Creators
// i.e. the stuff that actually makes it to the compiled Javascript
// ------------------------------------

const activateAccount = createAction(ActionType.ACTIVATE_ACCOUNT);

const reportActivateAccountSuccess = createAction(
    ActionType.REPORT_ACTIVATE_ACCOUNT_SUCCESS,
    (message: string) => message,
);

const reportActivateAccountError = createAction(
    ActionType.REPORT_ACTIVATE_ACCOUNT_ERROR,
    (err: string) => err,
);

const resetPasswordForEmail = createAction(
    ActionType.RESET_PASSWORD_FOR_EMAIL,
    (email: string) => email,
);

const reportResetPasswordForEmailSuccess = createAction(
    ActionType.REPORT_RESET_PASSWORD_FOR_EMAIL_SUCCESS,
    (message: string) => message,
);

const reportResetPasswordForEmailError = createAction(
    ActionType.REPORT_RESET_PASSWORD_FOR_EMAIL_ERROR,
    (err: string) => err,
);

const clearPasswordReset = createAction(ActionType.CLEAR_PASSWORD_RESET);

const showUpdateEmail = createAction(ActionType.SHOW_UPDATE_EMAIL);

const reportShowUpdateEmailDone = createAction(
    ActionType.REPORT_SHOW_UPDATE_EMAIL_DONE,
);

const updateEmailAddress = createAction(
    ActionType.UPDATE_EMAIL_ADDRESS,
    (newEmail: string) => newEmail,
);

const reportUpdateEmailSuccess = createAction(
    ActionType.REPORT_EMAIL_UPDATE_SUCCESS,
);

const reportUpdateEmailError = createAction(
    ActionType.REPORT_EMAIL_UPDATE_ERROR,
    (err: string) => err,
);

const updateUserAccount = createAction(
    ActionType.UPDATE_USER_ACCOUNT,
    (account: Partial<INonNormalizedAccount>) => account,
);

const updateUserAccountOld = createAction(
    ActionType.UPDATE_USER_ACCOUNT_OLD,
    (account: Partial<INonNormalizedAccount>) => account,
);

const reportUpdateUserAccountSuccess = createAction(
    ActionType.REPORT_UPDATE_USER_ACCOUNT_SUCCESS,
    (message: string) => message,
);

const reportUpdateUserAccountError = createAction(
    ActionType.REPORT_UPDATE_USER_ACCOUNT_ERROR,
    (err: string) => err,
);

const resetUpdateAccountFetchStatus = createAction(
    ActionType.RESET_UPDATE_ACCOUNT_FETCH_STATUS,
);

const loadUserAccount = createAction(
    ActionType.LOAD_USER_ACCOUNT,
    (account: IAccount) => account,
);

const setPrivacyOptInOut = createAction(
    ActionType.SET_PRIVACY_OPT_IN_OUT,
    (inOut: boolean) => inOut,
);
const reportPrivacyOptInOutSuccess = createAction(
    ActionType.REPORT_PRIVACY_OPT_IN_OUT_SUCCESS,
    (inOut: boolean) => inOut,
);
const reportPrivacyOptInOutError = createAction(
    ActionType.REPORT_PRIVACY_OPT_IN_OUT_ERROR,
    (err: string) => err,
);

const startPollLapsedReactivation = createAction(
    ActionType.START_POLL_LAPSED_REACTIVATION,
);

const stopPollLapsedReactivation = createAction(
    ActionType.STOP_POLL_LAPSED_REACTIVATION,
);

const showAccountSignupModal = createAction(
    ActionType.SHOW_ACCOUNT_SIGNUP_MODAL,
);

const fetchOrdersSuccess = createAction(
    ActionType.REPORT_FETCH_ORDERS_SUCCESS,
    (orders: any) => orders,
);

const checkCart = createAction(ActionType.CART_CHECK);

const checkCartSuccess = createAction(
    ActionType.CART_CHECK_SUCCESS,
    (bagCount: number) => bagCount,
);

const checkCartFailure = createAction(
    ActionType.CART_CHECK_FAILURE,
    (bagError: string) => bagError,
);

const addItemsCart = createAction(
    ActionType.CART_ADD_ITEMS,
    (payload: Partial<ICartRequest>) => payload,
);

const addItemsSuccess = createAction(
    ActionType.CART_ADD_ITEMS_SUCCESS,
    (response: ICartResponse) => response,
);

const addItemsFailure = createAction(
    ActionType.CART_ADD_ITEMS_FAILURE,
    (error: string) => error,
);

export {
    ActionType,
    activateAccount,
    reportActivateAccountSuccess,
    reportActivateAccountError,
    IResetPasswordForEmailAction,
    IAccountAction,
    ILoadUserAccountAction,
    IUpdateUserAccountAction,
    resetPasswordForEmail,
    reportResetPasswordForEmailSuccess,
    reportResetPasswordForEmailError,
    IShowUpdateEmailAction,
    IUpdateEmailAddressAction,
    IReportEmailUpdateErrorAction,
    IReportEmailUpdateSuccessAction,
    showUpdateEmail,
    reportShowUpdateEmailDone,
    updateEmailAddress,
    reportUpdateEmailError,
    reportUpdateEmailSuccess,
    updateUserAccount,
    updateUserAccountOld,
    reportUpdateUserAccountSuccess,
    reportUpdateUserAccountError,
    resetUpdateAccountFetchStatus,
    loadUserAccount,
    SetPrivacyOptInOutAction,
    setPrivacyOptInOut,
    reportPrivacyOptInOutError,
    reportPrivacyOptInOutSuccess,
    clearPasswordReset,
    startPollLapsedReactivation,
    stopPollLapsedReactivation,
    showAccountSignupModal,
    ReportFetchOrderSuccessAction,
    fetchOrdersSuccess,
    checkCart,
    checkCartSuccess,
    checkCartFailure,
    addItemsCart,
    addItemsSuccess,
    addItemsFailure,
    IReportCartAddItems,
};
