import { CustomerState } from 'models';
import * as React from 'react';
import { RouteProps } from 'react-router';
import { ConditionalRoute } from '../conditional-route';

interface Props extends RouteProps {
    readonly authenticated: boolean;
    readonly initializing: boolean;
    readonly location: any;

    readonly customerState?: CustomerState;
    readonly requiredCustomerStates?: Set<CustomerState>;
    readonly isBYOD: boolean;
}

const hasAppropriateAccess = (
    customerState: CustomerState | undefined,
    requiredCustomerStates: Set<CustomerState> | undefined,
): boolean =>
    !!(
        !requiredCustomerStates ||
        (customerState && requiredCustomerStates.has(customerState))
    );

const SecureRoute = ({
    authenticated,
    initializing,
    location,
    customerState,
    requiredCustomerStates,
    isBYOD,
    ...rest
}: Props) => (
    <ConditionalRoute
        destination={__('routes.sign-in')}
        condition={
            authenticated &&
            hasAppropriateAccess(customerState, requiredCustomerStates)
        }
        initializing={initializing}
        location={location}
        {...rest}
        isBYOD={isBYOD}
    />
);

export { SecureRoute };
