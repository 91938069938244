import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./android-beta.less');

@CSSModules(styles, { allowMultiple: true })
class Androidbeta extends React.Component<{}> {
    constructor(props: {}) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <div>
                <iframe
                    src={__('routes.android-beta.aem')}
                    styleName="iframe"
                />
            </div>
        );
    }
}

export { Androidbeta };
