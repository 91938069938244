import * as React from 'react';
import * as CSSModules from 'react-css-modules';

interface Props extends React.HTMLProps<HTMLSpanElement> {
    readonly element?: string;
}

const NavTextComponent = ({ element, ...props }: Props) =>
    React.createElement(element || 'span', { ...props, styleName: 'nav-text' });

const NavText = CSSModules(NavTextComponent, require('./nav-text.less'));
export { NavText };
