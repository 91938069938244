import { AlertModalContainer as AlertModal } from 'containers';
import { User } from 'firebase/app';
import 'firebase/auth';
import { Account, IApplicationState, IValidateShortCodeResponse } from 'models';
import { INonNormalizedAccount } from 'models/account';
import { FacebookLoginStatus, IFacebookMeResponse } from 'models/facebook';
import { push } from 'react-router-redux';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { AccountService } from 'services';
import { ThinAirService } from 'services/thin-air.service';
import { loadUserAccount } from 'state/account/account.actions';
import { AuthenticationActions } from 'state/authentication';
import { reportAuthenticationSuccess } from 'state/authentication/authentication.actions';
import {
    getToken,
    signUpWithEmailAndPassword,
    trySendVerificationEmail,
} from 'state/firebase/firebase.saga';
import {
    ActionType,
    IRegisterWithEmailAddressAction,
    reportRegistrationError,
    reportRegistrationSuccess,
    reportShortCodeError,
    IValidateShortCodeBeforeSignInAction,
} from 'state/registration/registration.actions';
import { trackError } from 'state/tealium';
import {
    dismissLoadingIndicator,
    requestLoadingIndicator,
    showMessage,
} from 'state/view/view.actions';
import { confirmWithModal } from 'state/view/view.saga';
import { getCurrentUser } from '../firebase/firebase.saga';

// FB is the Facebook SDK added to the global scope
declare const FB: any;

function* registerWithEmailAddress(action: IRegisterWithEmailAddressAction) {
    // tslint:disable-next-line:no-let
    let firebaseUser: User | undefined;
    const createUserKey = 'create-user-key';
    try {
        yield put(requestLoadingIndicator(createUserKey));
        const {
            email,
            password,
            firstName,
            inviteCode,
            lastName,
            groupName,
        } = action.payload;

        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );

        let canContinue = true;

        let createUserObj = {
            first_name: firstName,
            last_name: lastName,
            email_address: email,
        };

        if (inviteCode) {
            const shortCodeResponse: IValidateShortCodeResponse = yield call(
                AccountService.validateShortCodeBeforeSignUp,
                basePaths,
                null,
                inviteCode,
            );
            if (!shortCodeResponse.valid) {
                canContinue = false;
                yield put(
                    reportShortCodeError(
                        'Invalid Referral code. Please Retry.',
                    ),
                );
                throw new Error(__('default-error-message'));
            } else {
                createUserObj['shortCode'] = inviteCode;
                createUserObj['campaignId'] = shortCodeResponse.campaign_uid;
                yield put({
                    type: ActionType.SET_INVITE_CODE,
                    payload: {
                        inviteCode,
                    },
                });
                yield put({
                    type:
                        ActionType.REPORT_VALIDATE_SHORT_BEFORE_SIGNIN_CODE_SUCCESS,
                    payload: {
                        campaign_uid: shortCodeResponse.campaign_uid,
                        valid: shortCodeResponse.valid,
                    },
                });
            }
        }

        if (groupName) {
            createUserObj['groupName'] = groupName;
        }

        firebaseUser = yield* signUpWithEmailAndPassword({
            email,
            password,
        });

        if (!firebaseUser) {
            throw new Error(__('default-error-message'));
        }

        const fbUser = yield call(getCurrentUser);

        fbUser.updateProfile({ displayName: firstName } as any);
        const token = yield getToken();
        sessionStorage.setItem('firebaseToken', token);
        sessionStorage.setItem('displayName', firstName);

        if (canContinue) {
            const exchangeToken = yield call(
                AccountService.getExcToken,
                basePaths,
                token,
            );
            switch (exchangeToken.status) {
                case 200:
                    const excToken: any = yield call(
                        ThinAirService.parseJson,
                        exchangeToken,
                        __('default-error-message'),
                    );
                    const ExchangeTokenExpiration: any = new Date(
                        parseFloat(excToken.issued_at) +
                            parseFloat(excToken.expires_in) * 1000,
                    ).getTime();
                    sessionStorage.setItem(
                        'ExchangeTokenExpiration',
                        ExchangeTokenExpiration,
                    );
                    sessionStorage.setItem(
                        'ExchangeToken',
                        excToken.access_token,
                    );
                    break;
                default:
                    // unhandled response code - show an error
                    throw new Error(__('default-error-message'));
            }

            const response: Response = yield call(
                AccountService.createAccount,
                basePaths,
                token,
                createUserObj,
            );

            switch (response.status) {
                case 201: // follow normal account creation flow
                    const account: INonNormalizedAccount = yield call(
                        ThinAirService.parseJson,
                        response,
                        __('default-error-message'),
                    );
                    yield fork(
                        handlePostAccountCreationSteps,
                        account,
                        token,
                        fbUser,
                    );
                    break;
                case 403: // block the user from moving forward
                    yield call(trySendVerificationEmail); // still send verification email
                    yield fork(handleBetaAccountThrottling, response);
                    break;
                default:
                    // unhandled response code - show an error
                    throw new Error(__('default-error-message'));
            }
        }
    } catch (err) {
        if (firebaseUser) {
            if (firebaseUser.delete) {
                firebaseUser.delete();
            }
            firebaseUser = undefined;
        }
        yield put(reportRegistrationError((err as Error).message));
    } finally {
        yield put(dismissLoadingIndicator(createUserKey));
    }
}

function* getFacebookInfo() {
    const facebookLoginStatus = yield select(
        ({ authentication }: IApplicationState) =>
            authentication.facebookLoginStatus,
    );
    const login = () =>
        new Promise((resolve, reject) =>
            facebookLoginStatus === FacebookLoginStatus.CONNECTED
                ? resolve()
                : FB.login(
                      ({ status }) =>
                          status === 'connected' ? resolve() : reject(),
                      { scope: 'public_profile,email' },
                  ),
        );

    const queryFBGraph = () =>
        new Promise(resolve => {
            FB.api('me', { fields: 'first_name,last_name,email' }, response => {
                resolve(response);
            });
        });

    try {
        yield call(login);
        const result = yield call(queryFBGraph);
        yield call(finishGettingFacebookInfo, result);
        // tslint:disable-next-line:no-empty
    } catch (e) {
        yield call(finishGettingFacebookInfo);
    }
}

function* finishGettingFacebookInfo({
    email,
    first_name: firstName,
    last_name: lastName,
}: IFacebookMeResponse = {}) {
    yield put({
        payload: {
            email,
            firstName,
            lastName,
        },
        type: ActionType.FINISH_REGISTRATION_WITH_SOCIAL_INFO,
    });
}

function* handleBetaAccountThrottling(response: Response) {
    try {
        const messageBody = yield call(
            ThinAirService.parseErrorOrDefault,
            response,
            __('registration.beta-throttling.message-fallback'),
        );
        yield call(confirmWithModal, {
            additionalProps: {
                body: messageBody,
                buttonText: __('registration.beta-throttling.button-label'),
                title: __('registration.beta-throttling.title'),
            },
            component: AlertModal,
        });

        yield put(reportRegistrationError(''));
        yield put(AuthenticationActions.signOut());
        yield put(push(__('routes.home')));
    } catch (error) {
        // tslint:disable-next-line:no-console
        console.error(error);
    }
}

function* handlePostAccountCreationSteps(
    user: INonNormalizedAccount,
    token: string,
    firebaseUser: User,
) {
    try {
        yield call(trySendVerificationEmail);
        yield put(reportAuthenticationSuccess());

        yield put(
            showMessage(__('sign-up.account-created.email-confirm-message')),
        );

        yield put(loadUserAccount(Account.Normalize(user, firebaseUser)));
        yield put(reportRegistrationSuccess());
    } catch (error) {
        if (firebaseUser && firebaseUser.delete) {
            firebaseUser.delete();
        }
        yield put(reportRegistrationError((error as Error).message));
    }
}

function* validateShortCode(action: IValidateShortCodeBeforeSignInAction) {
    try {
        let firebaseUser: User | undefined;
        const { email, password, firstName, inviteCode } = action.payload;
        const userObj = action.payload;
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        firebaseUser = yield* signUpWithEmailAndPassword({
            email,
            password,
        });

        if (!firebaseUser) {
            throw new Error(__('default-error-message'));
        }

        const fbUser = yield call(getCurrentUser);

        fbUser.updateProfile({ displayName: firstName } as any);

        const token = yield getToken();
        sessionStorage.setItem('firebaseToken', token);
        sessionStorage.setItem('displayName', firstName);

        const shortCodeResponse: IValidateShortCodeResponse = yield call(
            AccountService.validateShortCodeBeforeSignUp,
            basePaths,
            token,
            inviteCode,
        );

        if (shortCodeResponse.valid) {
            yield put({
                type:
                    ActionType.REPORT_VALIDATE_SHORT_BEFORE_SIGNIN_CODE_SUCCESS,
                shortCodeResponse,
            });
            yield put({
                type: ActionType.REGISTER_WITH_EMAIL_ADDRESS,
                userObj,
            });
            // yield put(validateShortCodeSuccess(shortCodeResponse));
        } else {
            yield put(reportShortCodeError('Wrong Referral code'));
        }
    } catch (err) {
        yield put(reportShortCodeError((err as Error).message));
    }
}

function* registrationSaga() {
    yield all([
        takeEvery(
            ActionType.REGISTER_WITH_EMAIL_ADDRESS,
            registerWithEmailAddress,
        ),
        takeEvery(
            ActionType.START_REGISTRATION_WITH_SOCIAL_INFO,
            getFacebookInfo,
        ),
        takeEvery(ActionType.REPORT_REGISTRATION_ERROR, trackError),
        takeEvery(
            ActionType.VALIDATE_SHORT_CODE_BEFORE_SIGNIN,
            validateShortCode,
        ),
    ]);
}

export { registrationSaga, registerWithEmailAddress, validateShortCode };
