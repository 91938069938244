import { ButtonContainer as Button } from 'containers';

import {
    TealiumFlowName,
    TealiumLinkEvents,
    TealiumPageLinkLocations,
    TealiumPageType,
} from 'models/tealium';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
import { Link } from 'react-router-dom';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';
const styles = require('./promo-banner.less');

@CSSModules(styles, { allowMultiple: true })
class PromoBanner extends React.Component<{}> {
    render() {
        return (
            <div styleName="promo-container">
                <img
                    styleName="promo-face-left"
                    src={__('promo-banner-left')}
                />
                <div styleName="promo-center">
                    <div styleName="copy-container">
                        <label styleName="banner-copy">
                            {__('banner-copy')}
                        </label>
                        <label styleName="banner-subcopy">
                            {__('banner-subcopy')}
                        </label>
                    </div>
                </div>
                <Link
                    onClick={e => {
                        e.stopPropagation();
                        const page = window.location.pathname.replace('/', '');
                        TealiumDispatcher.dispatchEvent(
                            TealiumFlowName.PROMO_FLOW_NAME,
                            page,
                            TealiumPageType.PROMO_PAGE_TEALIUM_EVENT,
                            TealiumPageLinkLocations.TOP_NAV,
                            TealiumLinkEvents.BUTTON,
                        );
                    }}
                    to={__('routes.promo-url')}
                    target="_blank"
                >
                    <Button
                        invert
                        styleName="copy-container-btn"
                        secondary
                        label={__('button-copy')}
                    />
                </Link>
                <img
                    styleName="promo-face-right"
                    src={__('promo-banner-right')}
                />
            </div>
        );
    }
}

export { PromoBanner };
