import * as firebase from 'firebase/app';
import 'firebase/auth';
import { IEmailPassword, IFirebaseConfiguration, StartupScript } from 'models';
import { END, eventChannel } from 'redux-saga';
import { all, call, put, take } from 'redux-saga/effects';
import { StartupActions } from 'state/startup';
import { eventError } from 'state/tealium';

const config: IFirebaseConfiguration = {
    apiKey: process.env.FIREBASE_API_KEY || '',
    authDomain: process.env.FIREBASE_AUTH_DOMAIN || '',
    databaseURL: process.env.FIREBASE_DATABASE_URL || '',
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID || '',
    projectId: process.env.FIREBASE_PROJECT_ID || '',
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET || '',
};

const conditionalClearSessionKeys = () => {
    Object.keys(sessionStorage).forEach(key => {
        const envPath = `${window.location.host}MASTER_DEPLOYMENT_ID`;
        if (
            ![
                envPath,
                'chatConfig',
                'chatSession',
                'enableLiveAgentChat',
            ].includes(key)
        ) {
            sessionStorage.removeItem(key);
        }
    });
};

function waitForAuthInitialization() {
    return eventChannel(emitter => {
        firebase.auth().onAuthStateChanged(user => {
            emitter(
                StartupActions.reportInitializationSuccess(
                    StartupScript.FIREBASE,
                ),
            );
            emitter(END);
        });
        // tslint:disable-next-line:no-empty
        return () => {};
    });
}

function* initialize(c: IFirebaseConfiguration) {
    try {
        yield call(firebase.initializeApp, c);
        const channel = yield call(waitForAuthInitialization);
        while (true) {
            const action = yield take(channel);
            yield put(action);
        }
    } catch (err) {
        yield put(
            StartupActions.reportInitializationFailure(StartupScript.FIREBASE),
        );
    }
}

function* signInWithEmailAndPassword(credentials: IEmailPassword) {
    const auth = yield call(firebase.auth);
    const { email, password } = credentials;
    const response: firebase.User = yield call(
        auth.signInWithEmailAndPassword.bind(auth),
        email,
        password,
    );
    return response;
}

function* signUpWithEmailAndPassword(credentials: IEmailPassword) {
    const auth = yield call(firebase.auth);
    const { email, password } = credentials;
    const user: firebase.User = yield call(
        auth.createUserWithEmailAndPassword.bind(auth),
        email,
        password,
    );
    return user;
}

function* resetPasswordForEmail(email: string) {
    firebase.auth().sendPasswordResetEmail(email);
}

async function getCurrentUser() {
    return await firebase.auth().currentUser;
}

async function getToken() {
    const user = await getCurrentUser();
    if (user) {
        if (user.displayName) {
            sessionStorage.setItem('displayName', user.displayName);
        }
        return await user.getIdToken();
    } else {
        put(eventError({ error_msg: __('firebase.get-token-error') }));
        throw Error(__('firebase.get-token-error'));
    }
}

async function getId() {
    const user = await getCurrentUser();
    if (user) {
        return user.uid;
    } else {
        put(eventError({ error_msg: __('firebase.get-id-error') }));
        throw Error(__('firebase.get-id-error'));
    }
}

async function getIdOrDefault() {
    try {
        return await getId();
    } catch (err) {
        return '';
    }
}

async function trySendVerificationEmail() {
    const user = await getCurrentUser();
    if (user) {
        if (!user.emailVerified) {
            user.sendEmailVerification();
        }
    } else {
        put(
            eventError({
                error_msg: __('firebase.send-verification-email-error'),
            }),
        );
        throw new Error(__('firebase.send-verification-email-error'));
    }
}

async function signOut() {
    conditionalClearSessionKeys(); // window.sessionStorage.clear();
    return await firebase.auth().signOut();
}

function* updateEmailAddress(email: string) {
    const user = yield getCurrentUser();
    if (user && user.email !== email) {
        yield call(user.updateEmail.bind(user), email);
        yield call(trySendVerificationEmail);
    }
}

function* updateDisplayName(displayName: string) {
    const user = yield getCurrentUser();
    if (user && user.displayName !== displayName) {
        yield call(user.updateProfile.bind(user), { displayName });
    }
}

async function verifyPasswordResetCode(code: string): Promise<string> {
    try {
        return await firebase.auth().verifyPasswordResetCode(code);
    } catch (_) {
        put(eventError({ error_msg: __('reset-password-error-message') }));
        throw new Error(__('reset-password-error-message'));
    }
}

async function resetPasswordWithOob(
    code: string,
    password: string,
): Promise<void> {
    // using default error messaging for now
    return await firebase.auth().confirmPasswordReset(code, password);
}

async function applyVerificationCode(code: string): Promise<void> {
    return firebase.auth().applyActionCode(code);
}

// ----------------------------------------------------------------------
// We only trigger initialize because it needs to happen automatically.
// Everything else here is used by other sagas directly.
// ----------------------------------------------------------------------

function* firebaseSaga() {
    yield all([initialize(config) as any]);
}

export {
    firebaseSaga,
    initialize,
    resetPasswordForEmail,
    signInWithEmailAndPassword,
    signOut,
    signUpWithEmailAndPassword,
    getCurrentUser,
    updateEmailAddress,
    updateDisplayName,
    getToken,
    getId,
    getIdOrDefault,
    trySendVerificationEmail,
    verifyPasswordResetCode,
    resetPasswordWithOob,
    applyVerificationCode,
};
