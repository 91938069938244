import { PayBill } from 'components/account/pay-bill';
import { withAnalytics } from 'components/common/analytics';
import { withDeviceType } from 'components/common/device-type';
import { InitializationStatus } from 'lib/br-redux';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { resendVerificationEmail } from 'state/authentication/authentication.actions';
import { fetchCurrentBill } from 'state/bill';
import { PaymentActions } from 'state/payment';
import { getAutopayStatus } from 'state/payment/autopay/autopay.actions';
import {
    DefaultMethodActions,
    ManualPaymentActions,
} from 'state/payment/payment.actions';
import { getReferrerStats } from 'state/referral/referral.actions';
import { showModal } from 'state/view/view.actions';
import { CPNIModalContainer } from '.';

const { getClientToken } = PaymentActions.ClientTokenActions;

const {
    getDefaultPaymentMethod,
    updateDefaultPaymentMethod,
} = DefaultMethodActions;
const { submitManualPayment } = ManualPaymentActions;

const portingIssue = notice => {
    return (
        (notice.additionalProps && notice.additionalProps.generalPortError) ||
        (notice.additionalProps && notice.additionalProps.mdnCounterEnabled) ||
        (notice.additionalProps && notice.additionalProps.pinCounterEnabled)
    );
};

const mapStateToProps = ({
    account,
    bill,
    configuration,
    payment,
    referral,
    purchase,
    view,
    authentication,
}: IApplicationState) => ({
    account,
    accountBalance: bill.currentBill.accountBalance,
    approvedSum: referral.approvedSum,
    autopayFetchStatus: payment.autopay.fetchStatus,
    autopayStatus: account.autopay,
    authInitialized:
        authentication.initializationStatus ===
        InitializationStatus.INITIALIZED,
    authStatus: authentication.fetchStatus,
    clientToken: payment.clientToken.clientToken,
    clientTokenStatus: payment.clientToken.fetchStatus,
    billFetchStatus: bill.currentBill.fetchStatus,
    cart: purchase.cart.result,
    chargeAmount: bill.currentBill.chargeAmount,
    chargeEvents: bill.history.results || [],
    portIssue: view.noticeQueue.find(portingIssue),
    configuration,
    creditBalance: referral.creditBalance,
    dueDate: bill.currentBill.dueDate,
    enableManualPay: bill.currentBill.enableManualPay,
    error: payment.clientToken.error,
    kountDevice: payment.kountDevice.kountDevice,
    manualPaymentError: payment.manualPayment.error,
    manualPaymentSubmissionStatus: payment.manualPayment.fetchStatus,
    paymentMethod: payment.defaultMethod.paymentMethod,
    paymentMethodFetchStatus: payment.defaultMethod.fetchStatus,
    redeemedCredits: bill.currentBill.redeemedCredits,
    totalAmount: bill.currentBill.totalAmount,
    totalMembersInGroup: bill.currentBill.totalMembersInGroup,
    totalCreditsEarned: referral.totalCreditsEarned,
    groupCredits: bill.currentBill.groupCredits,
    referralCredits: bill.currentBill.referralCredits,
    goodwillCredits: bill.currentBill.goodwillCredits,
});

const mapDispatchToProps = dispatch => ({
    fetchCurrentBill: () => dispatch(fetchCurrentBill()),
    fetchReferralStats: email => dispatch(getReferrerStats(email)),
    fetchAutopayStatus: () => dispatch(getAutopayStatus()),
    getDefaultPaymentMethod: () => dispatch(getDefaultPaymentMethod()),
    showCPNI: () =>
        dispatch(
            showModal({
                additionalProps: {},
                component: CPNIModalContainer as any,
            }),
        ),
    showModal: p => dispatch(showModal(p)),
    submitManualPayment: (amount: number) =>
        dispatch(submitManualPayment(amount)),
    updateDefaultPaymentMethod: pm => dispatch(updateDefaultPaymentMethod(pm)),
    resendVerificationEmail: () => dispatch(resendVerificationEmail()),
    getClientToken: () => dispatch(getClientToken()),
});

const enhance = compose(
    withAnalytics,
    withDeviceType,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);

const PayBillContainer = enhance(PayBill);

export { PayBillContainer };
