import { createStateMerger, FetchStatus, IDefaultAction } from 'lib/br-redux';
import { ActionType, ICheckoutAction } from './checkout.actions';

interface ICheckoutState {
    readonly error: string;
    readonly fetchStatus: FetchStatus;
}

const DEFAULT_STATE: ICheckoutState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
};

const { CHECKOUT, REPORT_CHECKOUT_ERROR, REPORT_CHECKOUT_SUCCESS } = ActionType;

const checkoutReducer = (
    state: ICheckoutState = DEFAULT_STATE,
    action: ICheckoutAction | IDefaultAction,
) => {
    const merge = createStateMerger(state);
    switch (action.type) {
        case CHECKOUT:
            return merge({
                error: '',
                fetchStatus: FetchStatus.FETCHING,
            });
        case REPORT_CHECKOUT_SUCCESS:
            return merge({
                error: '',
                fetchStatus: FetchStatus.SUCCESS,
            });
        case REPORT_CHECKOUT_ERROR:
            return merge({
                error: action.payload,
                fetchStatus: FetchStatus.SUCCESS,
            });
        default:
            return state;
    }
};

export { ICheckoutState, DEFAULT_STATE, checkoutReducer };
