import {
    AlertModalContainer as AlertModal,
    AutopayConfirmContainer as AutopayConfirm,
    PayNowModalContainer as PayNowModal,
} from 'containers';
import { IAccount, IApplicationState } from 'models';
import { PaymentKind } from 'models/payment-method';
import {
    all,
    call,
    put,
    select,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import { PaymentService } from 'services';
import { refreshAccount } from 'state/account';
import { loadUserAccount } from 'state/account/account.actions';
import { runPostAuthSteps } from 'state/authentication';
import { fetchBillHistory, fetchCurrentBill } from 'state/bill/bill.actions';
import { getToken } from 'state/firebase/firebase.saga';
import { eventLink, trackError } from 'state/tealium';
import {
    dismissModal,
    dismissNotice,
    showModal,
} from 'state/view/view.actions';
import { confirmWithModal } from 'state/view/view.saga';

import {
    AutopayActions,
    ClientTokenActions,
    DefaultMethodActions,
    LapsedPaymentActions,
    ManualPaymentActions,
    PaymentNonceActions,
} from './payment.actions';

const { loadAutopayStatus, reportAutopayStatusError } = AutopayActions;
const { loadClientToken, reportClientTokenError } = ClientTokenActions;
const {
    loadDefaultPaymentMethod,
    reportDefaultPaymentMethodError,
} = DefaultMethodActions;
const {
    reportManualPaymentError,
    reportManualPaymentSuccess,
} = ManualPaymentActions;
const { reportPayNowError, reportPayNowSuccess } = LapsedPaymentActions;

function* getClientToken() {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);
        const account: IAccount = yield select(
            (state: IApplicationState) => state.account,
        );

        if (firebaseToken) {
            const clientToken = yield call(
                PaymentService.getClientToken,
                basePaths,
                firebaseToken,
                account.accountid,
            );
            yield put(loadClientToken(clientToken));
        }
    } catch (err) {
        yield put(reportClientTokenError((err as Error).message));
    }
}

function* getDefaultPayment(afterUpdate: boolean = false) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);
        const paymentMethod = yield call(
            PaymentService.getDefaultPaymentMethod,
            basePaths,
            firebaseToken,
        );
        // Cannot link metrics to Braintree drop-in options, so must fire event after update.
        if (afterUpdate) {
            if (paymentMethod === PaymentKind.CREDIT_CARD) {
                const payload: any = __(
                    'payment.update-payment-method-card-metrics',
                );
                yield put(eventLink(payload));
            }
            if (paymentMethod === PaymentKind.PAYPAL) {
                const payload: any = __(
                    'payment.update-payment-method-paypal-metrics',
                );
                yield put(eventLink(payload));
            }
        }
        yield put(loadDefaultPaymentMethod(paymentMethod));
    } catch (err) {
        yield put(reportDefaultPaymentMethodError((err as Error).message));
    }
}

function* updateDefaultPayment(
    action: DefaultMethodActions.IUpdateDefaultPaymentMethodAction,
) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);
        yield call(
            PaymentService.updateDefaultPaymentMethod,
            basePaths,
            firebaseToken,
            action.payload,
        );
        yield getDefaultPayment(true);
        return [true, null];
    } catch (err) {
        if ((err as Error).message === '401') {
            window.location.href = __('routes.sign-in');
        } else {
            yield put(reportDefaultPaymentMethodError((err as Error).message));
            return [false, (err as Error).message];
        }
    }
}

function* setAutopayStatus(action: AutopayActions.ISetAutopayStatusAction) {
    try {
        // show a modal if this is being set to "on"
        if (action.payload) {
            const didConfirm = yield call(confirmWithModal, {
                additionalProps: {},
                component: AutopayConfirm,
            });

            // Break if not confirmed
            if (!didConfirm) {
                yield put(loadAutopayStatus(false));
                return;
            }
        }

        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);
        const autopayStatus = yield call(
            PaymentService.setAutopayStatus,
            basePaths,
            firebaseToken,
            action.payload,
        );
        yield put(loadAutopayStatus(autopayStatus));
        const account = yield select(
            ({ account }: IApplicationState) => account,
        );
        yield put(loadUserAccount({ ...account, autopay: autopayStatus }));
    } catch (err) {
        yield put(reportAutopayStatusError((err as Error).message));
    }
}

function* showAutopayTerms() {
    yield call(confirmWithModal, {
        additionalProps: { showActions: false },
        component: AutopayConfirm,
    });
}

function* submitManualPayment(
    action: ManualPaymentActions.ISubmitManualPaymentAction,
) {
    try {
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);
        const account: IAccount = yield select(
            (state: IApplicationState) => state.account,
        );
        const kountDevice: object = yield select(
            (state: IApplicationState) => state.payment.kountDevice.kountDevice,
        );
        // If present, dismiss any notices (e.g. lapsed notice) while payment is attempted.
        yield put(dismissNotice());

        yield call(
            PaymentService.makeManualPayment,
            basePaths,
            firebaseToken,
            action.payload.amount,
            account,
            // @ts-ignore
            kountDevice.device,
        );

        yield put(
            showModal({
                additionalProps: {
                    buttonText: __('OK'),
                    title: __('account.currentStatement.modal.manualPay.title'),
                },
                component: AlertModal,
            }),
        );
        // Re-fetch bills.
        yield put(fetchCurrentBill());
        yield put(fetchBillHistory());
        yield put(reportManualPaymentSuccess());
    } catch (err) {
        yield put(reportManualPaymentError((err as Error).message));
        // Refresh account (show notice if applicable).
        yield call(refreshAccount);
        yield call(runPostAuthSteps);
    }
}

function* submitPayNowPayment(
    action: LapsedPaymentActions.ISubmitPayNowAction,
) {
    try {
        // tslint:disable:no-console
        const basePaths = yield select(
            (state: IApplicationState) => state.configuration.basePaths,
        );
        const firebaseToken = yield call(getToken);
        const account: IAccount = yield select(
            (state: IApplicationState) => state.account,
        );
        const kountDevice: object = yield select(
            (state: IApplicationState) => state.payment.kountDevice.kountDevice,
        );

        yield call(
            PaymentService.makeManualPayment,
            basePaths,
            firebaseToken,
            action.payload.amount,
            account,
            // @ts-ignore
            kountDevice.device,
        );
        yield put(reportPayNowSuccess());
        yield put(dismissModal());
        yield put(dismissNotice());
        yield call(refreshAccount);
        yield call(runPostAuthSteps);
    } catch (err) {
        yield put(reportPayNowError((err as Error).message));
    }
}

function* showPayNowModal(action: LapsedPaymentActions.IShowPayNowAction) {
    yield put(
        showModal({
            additionalProps: {
                paymentAmount: action.payload.amount,
            },
            component: PayNowModal,
        }),
    );
}

function* paymentSaga() {
    yield all([
        takeEvery(
            ClientTokenActions.ActionType.GET_CLIENT_TOKEN,
            getClientToken,
        ),
        takeEvery(
            AutopayActions.ActionType.SET_AUTOPAY_STATUS,
            setAutopayStatus,
        ),
        takeEvery(
            AutopayActions.ActionType.SHOW_AUTOPAY_TERMS,
            showAutopayTerms,
        ),
        takeEvery(
            DefaultMethodActions.ActionType.GET_DEFAULT_PAYMENT_METHOD as any, // TODO: fix this
            getDefaultPayment,
        ),
        takeEvery(
            PaymentNonceActions.ActionType.SELECT_PAYMENT_METHOD,
            updateDefaultPayment,
        ),
        takeEvery(
            DefaultMethodActions.ActionType.UPDATE_DEFAULT_PAYMENT_METHOD,
            updateDefaultPayment,
        ),
        takeLatest(
            ManualPaymentActions.ActionType.SUBMIT_MANUAL_PAYMENT,
            submitManualPayment,
        ),
        takeEvery(
            LapsedPaymentActions.ActionType.SHOW_PAY_NOW,
            showPayNowModal,
        ),
        takeEvery(
            LapsedPaymentActions.ActionType.SUBMIT_PAY_NOW,
            submitPayNowPayment,
        ),
        takeEvery(
            AutopayActions.ActionType.REPORT_AUTOPAY_STATUS_ERROR,
            trackError,
        ),
        takeEvery(
            ClientTokenActions.ActionType.REPORT_CLIENT_TOKEN_ERROR,
            trackError,
        ),
        takeEvery(
            DefaultMethodActions.ActionType.REPORT_DEFAULT_PAYMENT_METHOD_ERROR,
            trackError,
        ),
        takeEvery(
            ManualPaymentActions.ActionType.REPORT_MANUAL_PAYMENT_ERROR,
            trackError,
        ),
    ]);
}

export { paymentSaga };
