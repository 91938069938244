import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import {
    Account,
    AOAccountOrderResponse,
    IAccount,
    ICartResponse,
} from 'models';
import {
    ActionType as AuthActions,
    ISignOutAction,
} from 'state/authentication/authentication.actions';
import { ActionType, IAccountAction } from './account.actions';
import {
    cancelReducer,
    CancelState,
    DEFAULT_STATE as DEFAULT_CANCEL_STATE,
} from './cancel';

import {
    DEFAULT_STATE as DEFAULT_PAUSE_STATE,
    pauseReducer,
    PauseState,
} from './pause';

import { DEFAULT_ICART_RESPONSE_STATE } from '../../models/account/account.model';
import { IPromoContent, IAppliedPromotion } from 'models/account';

interface IAccountState extends IAccount {
    readonly changeEmailFetchStatus: FetchStatus;
    readonly changeEmailError: string;
    readonly resetPasswordFetchStatus: FetchStatus;
    readonly resetPasswordError: string;
    readonly resetPasswordSuccess: string;
    readonly resetupdateAccountFetchStatus: string;
    readonly updateAccountFetchStatus: FetchStatus;
    readonly updateAccountSuccess: string;
    readonly updateAccountError: string;
    readonly privacyOptInOutStatus: FetchStatus;
    readonly updatingEmail: boolean;

    readonly cancel: CancelState;
    readonly pause: PauseState;

    readonly orders: ReadonlyArray<AOAccountOrderResponse>;
    readonly PROMO_CONTENTS: ReadonlyArray<IPromoContent>;
    readonly appliedPromotions: ReadonlyArray<IAppliedPromotion>;
    readonly ordersFetchStatus: FetchStatus;

    readonly bagFetchStatus: FetchStatus;
    readonly bagAddItemsStatus: FetchStatus;
    readonly bagCount: number;
    readonly bagCheckError: string;
    readonly bagResponse: ICartResponse;
    readonly bagAddItemsError: string;
}

// tslint:disable:object-literal-sort-keys
const DEFAULT_STATE: IAccountState = {
    changeEmailFetchStatus: FetchStatus.NOT_FETCHED,
    changeEmailError: '',
    resetPasswordFetchStatus: FetchStatus.NOT_FETCHED,
    resetPasswordError: '',
    resetPasswordSuccess: '',
    updateAccountFetchStatus: FetchStatus.NOT_FETCHED,
    resetupdateAccountFetchStatus: FetchStatus.NOT_FETCHED,
    updateAccountSuccess: '',
    updateAccountError: '',
    privacyOptInOutStatus: FetchStatus.NOT_FETCHED,
    updatingEmail: false,
    cancel: DEFAULT_CANCEL_STATE,
    pause: DEFAULT_PAUSE_STATE,
    orders: [],
    PROMO_CONTENTS: [],
    appliedPromotions: [],
    ordersFetchStatus: FetchStatus.NOT_FETCHED,
    bagFetchStatus: FetchStatus.NOT_FETCHED,
    bagCount: 0,
    bagCheckError: '',
    bagAddItemsStatus: FetchStatus.NOT_FETCHED,
    bagResponse: DEFAULT_ICART_RESPONSE_STATE,
    bagAddItemsError: '',
    ...Account.DEFAULT,
};

const {
    REPORT_EMAIL_UPDATE_ERROR,
    REPORT_EMAIL_UPDATE_SUCCESS,
    CLEAR_PASSWORD_RESET,
    UPDATE_EMAIL_ADDRESS,
    LOAD_USER_ACCOUNT,
    RESET_PASSWORD_FOR_EMAIL,
    REPORT_RESET_PASSWORD_FOR_EMAIL_SUCCESS,
    REPORT_RESET_PASSWORD_FOR_EMAIL_ERROR,
    REPORT_UPDATE_USER_ACCOUNT_SUCCESS,
    REPORT_UPDATE_USER_ACCOUNT_ERROR,
    RESET_UPDATE_ACCOUNT_FETCH_STATUS,
    UPDATE_USER_ACCOUNT,
    SET_PRIVACY_OPT_IN_OUT,
    REPORT_PRIVACY_OPT_IN_OUT_ERROR,
    REPORT_PRIVACY_OPT_IN_OUT_SUCCESS,
    SHOW_UPDATE_EMAIL,
    REPORT_SHOW_UPDATE_EMAIL_DONE,
    REPORT_FETCH_ORDERS_SUCCESS,
    CART_CHECK,
    CART_CHECK_SUCCESS,
    CART_CHECK_FAILURE,
    CART_ADD_ITEMS,
    CART_ADD_ITEMS_SUCCESS,
    CART_ADD_ITEMS_FAILURE,
} = ActionType;

const accountReducer = (
    state: IAccountState = DEFAULT_STATE,
    action: IAccountAction | ISignOutAction | IDefaultAction,
): IAccountState => {
    switch (action.type) {
        case UPDATE_EMAIL_ADDRESS:
            return {
                ...state,
                ...{
                    changeEmailError: '',
                    changeEmailFetchStatus: FetchStatus.FETCHING,
                },
            };
        case REPORT_EMAIL_UPDATE_ERROR:
            return {
                ...state,
                ...{
                    changeEmailError: action.payload,
                    changeEmailFetchStatus: FetchStatus.ERROR,
                },
            };
        case REPORT_EMAIL_UPDATE_SUCCESS:
            return {
                ...state,
                ...{
                    changeEmailError: '',
                    changeEmailFetchStatus: FetchStatus.SUCCESS,
                },
            };

        case CLEAR_PASSWORD_RESET:
            return {
                ...state,
                ...{
                    resetPasswordError: '',
                    resetPasswordFetchStatus: FetchStatus.NOT_FETCHED,
                    resetPasswordSuccess: '',
                },
            };
        case REPORT_RESET_PASSWORD_FOR_EMAIL_ERROR:
            return {
                ...state,
                ...{
                    resetPasswordError: action.payload,
                    resetPasswordSuccess: '',
                    resetPasswordFetchStatus: FetchStatus.ERROR,
                },
            };
        case RESET_PASSWORD_FOR_EMAIL:
            return {
                ...state,
                ...{
                    resetPasswordError: '',
                    resetPasswordSuccess: '',
                    resetPasswordFetchStatus: FetchStatus.FETCHING,
                },
            };
        case REPORT_RESET_PASSWORD_FOR_EMAIL_SUCCESS:
            return {
                ...state,
                ...{
                    resetPasswordError: '',
                    resetPasswordSuccess: action.payload,
                    resetPasswordFetchStatus: FetchStatus.SUCCESS,
                },
            };
        case REPORT_UPDATE_USER_ACCOUNT_ERROR:
            return {
                ...state,
                ...{
                    updateAccountError: action.payload,
                    updateAccountSuccess: '',
                    updateAccountFetchStatus: FetchStatus.ERROR,
                },
            };
        case UPDATE_USER_ACCOUNT:
            return {
                ...state,
                ...{
                    updateAccountError: '',
                    updateAccountFetchStatus: FetchStatus.FETCHING,
                },
            };
        case REPORT_UPDATE_USER_ACCOUNT_SUCCESS:
            return {
                ...state,
                ...{
                    updateAccountError: '',
                    updateAccountSuccess: action.payload,
                    updateAccountFetchStatus: FetchStatus.SUCCESS,
                },
            };
        case RESET_UPDATE_ACCOUNT_FETCH_STATUS:
            return {
                ...state,
                ...{
                    updateAccountError: '',
                    updateAccountSuccess: '',
                    updateAccountFetchStatus: FetchStatus.NOT_FETCHED,
                },
            };
        case LOAD_USER_ACCOUNT:
            return {
                ...state,
                ...action.payload,
            };
        case AuthActions.SIGN_OUT:
            return DEFAULT_STATE;
        case SET_PRIVACY_OPT_IN_OUT:
            return {
                ...state,
                privacyOptInOutStatus: FetchStatus.FETCHING,
            };
        case REPORT_PRIVACY_OPT_IN_OUT_SUCCESS:
            return {
                ...state,
                privacyPolicyOptedIn: action.payload,
                privacyOptInOutStatus: FetchStatus.SUCCESS,
            };
        case REPORT_PRIVACY_OPT_IN_OUT_ERROR:
            return {
                ...state,
                privacyOptInOutStatus: FetchStatus.ERROR,
            };

        case SHOW_UPDATE_EMAIL:
            return {
                ...state,
                updatingEmail: true,
            };
        case REPORT_SHOW_UPDATE_EMAIL_DONE:
            return {
                ...state,
                updatingEmail: false,
            };
        case REPORT_FETCH_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.payload.orders,
                PROMO_CONTENTS: action.payload.PROMO_CONTENTS,
                appliedPromotions: action.payload.appliedPromotions,
                ordersFetchStatus: FetchStatus.SUCCESS,
            };
        case CART_CHECK:
            return {
                ...state,
                bagFetchStatus: FetchStatus.FETCHING,
            };
        case CART_CHECK_SUCCESS:
            return {
                ...state,
                bagFetchStatus: FetchStatus.SUCCESS,
                bagCount: action.payload,
            };
        case CART_CHECK_FAILURE:
            return {
                ...state,
                bagFetchStatus: FetchStatus.SUCCESS,
                bagCheckError: action.payload,
            };
        case CART_ADD_ITEMS:
            return {
                ...state,
                bagAddItemsStatus: FetchStatus.FETCHING,
            };
        case CART_ADD_ITEMS_SUCCESS:
            return {
                ...state,
                bagAddItemsStatus: FetchStatus.SUCCESS,
                bagResponse: action.payload,
            };
        case CART_ADD_ITEMS_FAILURE:
            return {
                ...state,
                bagAddItemsStatus: FetchStatus.ERROR,
                bagAddItemsError: action.payload,
            };
        default:
            return {
                ...state,
                cancel: cancelReducer(state.cancel, action as IDefaultAction),
                pause: pauseReducer(state.pause, action as IDefaultAction),
            };
    }
};

export { DEFAULT_STATE, IAccountState, accountReducer };
