import { Head } from 'components/head';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

const mapStateToProps = ({ registration }: IApplicationState, ownProps) => ({
    inviteCode: registration.prefilledInfo.inviteCode,
});

const enhance = compose(
    withRouter,
    connect(mapStateToProps),
);
const HeadContainer = enhance(Head);

export { HeadContainer };
