import { CreateAccountForm } from 'components/account/create-account/form/create-account-form';
import { withAnalytics } from 'components/common/analytics';
import { withDeviceType } from 'components/common/device-type';
import { withInputValidation } from 'components/common/forms/input';
import { IApplicationState, IRegisterableUser, StartupScript } from 'models';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { registerUserWithEmailAddress } from 'state/registration/registration.actions';
import { validateShortCode } from 'state/referral';

const dataKeys: ReadonlyArray<string> = [
    'email',
    'firstName',
    'password',
    'lastName',
    'inviteCode',
];

const mapStateToProps = (state: IApplicationState) => ({
    configuration: state.configuration,
    configInitialized:
        state.startup.initialized.indexOf(StartupScript.CONFIGURATION) > -1,
    authStatus: state.authentication.fetchStatus,
    error: state.registration.error,
    prefilledInfo: state.registration.prefilledInfo,
    referral: state.referral,
    validateShortCodeError: state.referral.validateShortCodeError,
    registrationStatus: state.registration.fetchStatus,
});
const mapDispatchToProps = dispatch => ({
    createEmailAccount: (user: IRegisterableUser) => {
        dispatch(registerUserWithEmailAddress(user));
    },
    validateInviteCode: (inviteCode: string) => {
        dispatch(validateShortCode(inviteCode));
    },
    redirectTo: (url: string) => dispatch(push(url)),
});
const enhance = compose(
    withDeviceType,
    withAnalytics,
    withInputValidation(dataKeys),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const CreateAccountFormContainer = enhance(CreateAccountForm);

export { CreateAccountFormContainer };
