import * as React from 'react';

const Circle = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 46 16"
    >
        <circle
            cx="13"
            cy="13"
            r="11.5"
            fill="none"
            fillRule="evenodd"
            stroke="#1800FF"
            strokeWidth="3"
        />
    </svg>
);

export { Circle };
