import { createAction, IAction } from 'lib/br-redux';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    SUBMIT_FEEDBACK = 'FEEDBACK/SUBMIT_FEEDBACK',
    REPORT_FEEDBACK_SUCCESS = 'FEEDBACK/REPORT_FEEDBACK_SUCCESS',
    REPORT_FEEDBACK_ERROR = 'FEEDBACK/REPORT_FEEDBACK_ERROR',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

type ISubmitFeedbackAction = IAction<ActionType.SUBMIT_FEEDBACK, string>;
type IReportFeedbackSuccessAction = IAction<ActionType.REPORT_FEEDBACK_SUCCESS>;
type IReportFeedbackErrorAction = IAction<
    ActionType.REPORT_FEEDBACK_ERROR,
    string
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IFeedbackAction =
    | ISubmitFeedbackAction
    | IReportFeedbackErrorAction
    | IReportFeedbackSuccessAction;

// ------------------------------------
// Action Creators
// i.e. the stuff that actually makes it to the compiled Javascript
// ------------------------------------

const submitFeedback = createAction(
    ActionType.SUBMIT_FEEDBACK,
    (msg: string) => msg,
);
const reportFeedbackError = createAction(
    ActionType.REPORT_FEEDBACK_ERROR,
    (err: string) => err,
);

const reportFeedbackSuccess = createAction(ActionType.REPORT_FEEDBACK_SUCCESS);

export {
    ActionType,
    ISubmitFeedbackAction,
    IReportFeedbackErrorAction,
    IReportFeedbackSuccessAction,
    IFeedbackAction,
    submitFeedback,
    reportFeedbackError,
    reportFeedbackSuccess,
};
