import {
    FetchStatus,
    IDefaultAction,
    InitializationStatus,
} from 'lib/br-redux';
import { FacebookLoginStatus } from 'models/facebook';
import { REHYDRATE } from 'redux-persist';
import { ActionType, IAuthenticationAction } from './authentication.actions';

interface IAuthenticationState {
    readonly fetchStatus: FetchStatus;
    readonly initializationStatus: InitializationStatus;
    readonly error: string;
    readonly rememberMe: boolean;
    readonly lastAction: string;
    readonly reauthenticationStatus: FetchStatus;
    readonly reauthenticationError: string;
    readonly facebookLoginStatus: FacebookLoginStatus;
}

// tslint:disable:object-literal-sort-keys
const DEFAULT_STATE: IAuthenticationState = {
    error: '',
    facebookLoginStatus: FacebookLoginStatus.NOT_FETCHED,
    fetchStatus: FetchStatus.NOT_FETCHED,
    initializationStatus: InitializationStatus.INITIALIZING,
    lastAction: '',
    reauthenticationError: '',
    reauthenticationStatus: FetchStatus.NOT_FETCHED,
    rememberMe: false,
};

const {
    AUTH_INIT,
    AUTHENTICATE_WITH_EMAIL_AND_PASSWORD,
    REPORT_AUTHENTICATION_ERROR,
    REPORT_AUTHENTICATION_SUCCESS,
    REPORT_INITIALIZATION_FAILURE,
    REPORT_INITIALIZATION_SUCCESS,
    SIGN_OUT,
    REAUTHENTICATE,
    REPORT_REAUTHENTICATION_SUCCESS,
    REPORT_REAUTHENTICATION_ERROR,
    FINISH_REAUTHENTICATION_WITH_PASSWORD,
    SET_FACEBOOK_LOGIN_STATUS,
    CLEAR_AUTHENTICATION_ERRORS,
} = ActionType;

const authenticationReducer = (
    state: IAuthenticationState = DEFAULT_STATE,
    action: IAuthenticationAction | IDefaultAction,
): IAuthenticationState => {
    switch (action.type) {
        case REHYDRATE as '':
            const reloadedState: IAuthenticationState =
                action &&
                (action as any).payload &&
                (action as any).payload.authentication
                    ? (action as any).payload.authentication
                    : DEFAULT_STATE;
            return {
                ...state,
                ...{
                    lastAction: reloadedState.lastAction,
                    rememberMe: reloadedState.rememberMe,
                },
            };
        case AUTHENTICATE_WITH_EMAIL_AND_PASSWORD:
            return {
                ...state,
                ...{
                    error: '',
                    fetchStatus: FetchStatus.FETCHING,
                    rememberMe: action.payload.rememberMe || false,
                },
            };
        case REPORT_AUTHENTICATION_SUCCESS:
            return {
                ...state,
                ...{
                    fetchStatus: FetchStatus.SUCCESS,
                    lastAction: new Date().toISOString(),
                },
            };
        case REPORT_AUTHENTICATION_ERROR:
            return {
                ...state,
                ...{ fetchStatus: FetchStatus.ERROR, error: action.payload },
            };
        case AUTH_INIT:
            return {
                ...state,
                ...{ fetchStatus: FetchStatus.FETCHING },
            };
        case REPORT_INITIALIZATION_SUCCESS:
            return {
                ...state,
                ...{
                    // Do not set fetchStatus to SUCCESS, after AUTH_INIT, as that means authenticated.
                    fetchStatus: FetchStatus.NOT_FETCHED,
                    initializationStatus: InitializationStatus.INITIALIZED,
                },
            };
        case REPORT_INITIALIZATION_FAILURE:
            return {
                ...state,
                ...{
                    fetchStatus: FetchStatus.ERROR,
                    initializationStatus: InitializationStatus.FAILED,
                },
            };
        case REAUTHENTICATE:
            return {
                ...state,
                reauthenticationError: '',
            };
        case FINISH_REAUTHENTICATION_WITH_PASSWORD:
            return {
                ...state,
                reauthenticationError: '',
                reauthenticationStatus: FetchStatus.FETCHING,
            };
        case REPORT_REAUTHENTICATION_SUCCESS:
            return {
                ...state,
                reauthenticationStatus: FetchStatus.SUCCESS,
            };
        case REPORT_REAUTHENTICATION_ERROR:
            return {
                ...state,
                reauthenticationError: action.payload,
                reauthenticationStatus: FetchStatus.ERROR,
            };
        case SIGN_OUT:
            const { initializationStatus } = state;
            return {
                ...DEFAULT_STATE,
                initializationStatus,
            };
        case SET_FACEBOOK_LOGIN_STATUS:
            return {
                ...state,
                facebookLoginStatus: action.payload,
            };
        case CLEAR_AUTHENTICATION_ERRORS:
            return {
                ...state,
                error: '',
                reauthenticationError: '',
            };
        default:
            return state;
    }
};

export { DEFAULT_STATE, IAuthenticationState, authenticationReducer };
