import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';
import { BodyCopy, InfoLink } from 'components/common/typography';
import { Grid, GridTemplates } from 'components/grid';
import {
    AddressContainer as Address,
    BillingContainer as Billing,
    IMEIContainer as Compatibility,
    PhoneNumberContainer as PhoneNumber,
    PurchaseAccordionContainer as PurchaseAccordion,
    SummaryContainer as Summary,
} from 'containers';
import { FetchStatus, InitializationStatus } from 'lib/br-redux';
import {
    FacebookLoginStatus,
    HandsetCartItem,
    IAddress,
    IDeviceTypeProps,
    IProductItemModel,
    ProductType,
} from 'models';
import { match } from 'react-router-dom';
import { IPurchaseState } from 'state/purchase/purchase.reducer';

const styles = require('./sign-up.less');

interface IProps extends IPurchaseState, IAnalyticsProps, IDeviceTypeProps {
    readonly history: any; // TODO(dan)
    readonly match: match<any>;
    readonly accountid: string;
    readonly authStatus: FetchStatus;
    readonly authInitStatus: InitializationStatus;
    readonly billingAddress: IAddress;
    readonly paymentNonce: string;
    readonly registrationStatus: FetchStatus;
    readonly servicePlan: IProductItemModel;
    readonly shippingAddress: IAddress;
    readonly facebookStatus: FacebookLoginStatus;
    readonly isShowingLoading: boolean;
    readonly device: HandsetCartItem | null;
    startRegistrationWithSocialInfo(): void;
    dismissLoadingIndicator(): void;
    redirectTo(url: string): void;
    requestLoadingIndicator(): void;
    showRemovedSim(): void;
    showAccountSignupModal(): void;
}
interface IState {
    readonly activeStep: number;
    readonly userChangedSection: boolean;
    readonly paymentNonce: string;
    readonly allStepsComplete: boolean;
    readonly isFacebookLoggedIn: boolean;
    readonly hasShownSignupModal: boolean;
    readonly hasShownLoadingIndicator: boolean;
}

interface StepObject {
    readonly component: React.ComponentClass<any>;
    readonly isComplete: () => boolean;
    readonly title: string;
    readonly tealiumTag: string;
    readonly editButtonLabel?: string;
    readonly pageName: string;
    readonly suppressRightText?: boolean;
}

const { HANDSET, SIM } = ProductType;

@CSSModules(styles, { allowMultiple: true })
class SignUp extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: -1,
            allStepsComplete: false,
            hasShownLoadingIndicator: false,
            hasShownSignupModal: false,
            isFacebookLoggedIn: false,
            paymentNonce: '',
            userChangedSection: false,
        };
    }

    componentDidUpdate(prevProps) {
        const {
            getFirstIncompleteStep,
            isFetching,
            props: {
                accountid,
                authStatus,
                cart: { simRemoved },
                device,
                dismissLoadingIndicator,
                isShowingLoading,
                requestLoadingIndicator,
                showAccountSignupModal,
                showRemovedSim,
            },
            setActiveStep,
            state: {
                allStepsComplete,
                hasShownLoadingIndicator,
                hasShownSignupModal,
                userChangedSection,
            },
        } = this;
        // Tell the user their SIM has been removed
        if (simRemoved) {
            showRemovedSim();
        }
        // Handle sign out on sign-up page.  Make the sign-up modal re-display.
        if (
            prevProps.authStatus === FetchStatus.SUCCESS &&
            authStatus === FetchStatus.NOT_FETCHED
        ) {
            this.setState({ hasShownSignupModal: false });
        }
        if (!isFetching) {
            const isByodFlow = !device || device.isByod();
            // Set active blade
            if (!allStepsComplete && !userChangedSection && isByodFlow) {
                const firstIncompleteStep = getFirstIncompleteStep();
                setActiveStep(firstIncompleteStep);
            }
            // Loading indicator
            if (isShowingLoading) {
                dismissLoadingIndicator();
            }
            if (!accountid && !hasShownSignupModal) {
                dismissLoadingIndicator();
                showAccountSignupModal();
                this.setState({ hasShownSignupModal: true });
                return;
            }
        } else {
            if (!hasShownLoadingIndicator) {
                this.setState({ hasShownLoadingIndicator: true });
                requestLoadingIndicator();
            }
        }
    }

    render() {
        const {
            props: { isMobile },
        } = this;
        const forms = this.getSteps().map(this.mapStepToForm);

        return (
            <div styleName="container">
                <Grid>
                    <GridTemplates.HeadlineTemplate
                        left
                        two
                        sm
                        noMarginBottom
                        noMarginTop={false}
                        headline={__('sign-up.title')}
                        imageSrc={__('sign-up.title-image')}
                        imageSrcMobile={__('sign-up.title-image.mobile')}
                    >
                        <BodyCopy marginTop thin>
                            {__('sign-up.list-title')}
                        </BodyCopy>
                        <BodyCopy marginTop thin>
                            {__('sign-up.list-item-one')}
                        </BodyCopy>
                        <BodyCopy marginTop thin>
                            {__('sign-up.list-item-two')}
                        </BodyCopy>
                        <BodyCopy marginTop thin>
                            {__('sign-up.list-item-three')}
                        </BodyCopy>
                        <BodyCopy marginTop thin>
                            {__('sign-up.list-item-four')}
                        </BodyCopy>
                        <BodyCopy thin medium>
                            {__('sign-up.list-disclaimer')}
                        </BodyCopy>
                    </GridTemplates.HeadlineTemplate>
                </Grid>
                <div id="drawerList">{forms}</div>
                <BodyCopy noMargin={isMobile} styleName="disclaimer">
                    {__('sign-up.disclaimer')}
                    <InfoLink
                        element="a"
                        href={`${__('routes.coverage')}`}
                        target="_blank"
                    >
                        {__('sign-up.disclaimer-see-details')}
                    </InfoLink>
                </BodyCopy>
            </div>
        );
    }

    private readonly advanceStep = () => {
        const steps = this.getSteps();
        const firstIncompleteStep = this.getFirstIncompleteStep();
        if (firstIncompleteStep === steps.length) {
            this.setState({ activeStep: -1, allStepsComplete: true });
        } else {
            this.setActiveStep(firstIncompleteStep);
        }
    };

    private readonly mapStepToForm = (
        step: StepObject,
        idx: number,
    ): JSX.Element => {
        const firstIncompleteStep = this.getFirstIncompleteStep();
        return (
            <div style={{ marginBottom: '10px' }} key={idx}>
                <PurchaseAccordion
                    title={step.title}
                    active={idx === this.state.activeStep}
                    onClick={() => this.handleClickForIndex(idx)}
                    completed={idx < firstIncompleteStep}
                    incomplete={idx === firstIncompleteStep}
                    completeButtonText={step.editButtonLabel}
                    pageName={step.pageName}
                    suppressRightText={step.suppressRightText}
                >
                    {React.createElement(step.component, {
                        onNext: this.advanceStep,
                    })}
                </PurchaseAccordion>
            </div>
        );
    };

    private readonly handleClickForIndex = (idx: number): void => {
        const incompleteIdx = this.getFirstIncompleteStep();
        if (idx <= incompleteIdx) {
            this.setState({ activeStep: idx, userChangedSection: true });
        }
    };

    private readonly didCompleteCompatibilityStep = () => {
        const {
            props: {
                cart: { result: cart },
            },
        } = this;
        return !!(cart && cart.hasProductType(HANDSET));
    };

    private readonly didCompletePhoneNumberStep = (): boolean => {
        const {
            props: {
                cart: { result: cart },
            },
        } = this;
        return !!(cart && cart.hasProductType(SIM));
    };

    private readonly addressIsComplete = (address: IAddress) => {
        if (!address) {
            return false;
        }

        const requiredFields: ReadonlyArray<string> = [
            'city',
            'state',
            'street_line1',
            'zip',
        ];

        return requiredFields.every(
            k => !!(address[k] && address[k].trim().length),
        );
    };

    private readonly setActiveStep = stepIndex => {
        if (this.state.activeStep !== stepIndex) {
            this.setState({ activeStep: stepIndex, userChangedSection: false });
        }
    };

    private readonly getFirstIncompleteStep = () => {
        // use for loop rather than .foreach() so we can short circuit and return value directly=
        // tslint:disable-next-line:no-let
        for (const [i, step] of this.getSteps().entries()) {
            if (!step.isComplete()) {
                return i;
            }
        }

        // all steps are complete
        return this.getSteps().length;
    };

    private get isFetching(): boolean {
        const {
            props: {
                authInitStatus,
                authStatus,
                cart: { retrieveFetchStatus },
            },
        } = this;

        return (
            [authStatus, retrieveFetchStatus].indexOf(FetchStatus.FETCHING) >
                -1 || authInitStatus !== InitializationStatus.INITIALIZED
        );
    }

    private readonly getSteps = (): ReadonlyArray<StepObject> => {
        const { billingAddress, shippingAddress, paymentNonce } = this.props;
        return [
            {
                component: Compatibility,
                isComplete: () => this.didCompleteCompatibilityStep(),
                pageName: 'SignUp/Compatibility',
                suppressRightText: true,
                tealiumTag: __('imei.page-name-metrics'),
                title: __('imei.title'),
            },
            {
                component: PhoneNumber,
                editButtonLabel: 'View',
                isComplete: () => this.didCompletePhoneNumberStep(),
                pageName: 'SignUp/PhoneNumber',
                tealiumTag: __('phone-number.page-name-metrics'),
                title: __('phone_number.title'),
            },
            {
                component: Address,
                isComplete: () =>
                    this.addressIsComplete(billingAddress) &&
                    this.addressIsComplete(shippingAddress),
                pageName: 'SignUp/Address',
                tealiumTag: __('address.page-name-metrics'),
                title: __('address.title'),
            },
            {
                component: Billing,
                isComplete: () => !!(paymentNonce && paymentNonce.trim()),
                pageName: 'SignUp/Payment',
                tealiumTag: __('sign-up.billing.page-name-metrics'),
                title: __('billing.title'),
            },
            {
                component: Summary,
                isComplete: () => false,
                pageName: 'SignUp/Summary',
                tealiumTag: __('sign-up.summary.page-name-metrics'),
                title: __('summary.title'),
            },
        ];
    };
}

export { SignUp };
