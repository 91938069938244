import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./ApplicationHome.less');
@CSSModules(styles, { allowMultiple: true })
class ApplicationHome extends React.Component<{}> {
    render() {
        return <>{(window.location.href = __('routes.home'))}</>;
    }
}

export { ApplicationHome };
