import { createAction, IAction } from 'lib/br-redux';

// ------------------------------------
// ActionType
// Used as the "type" value in an action object.
// ------------------------------------

enum ActionType {
    REPORT_KOUNT_DEVICE_SUCCESS = 'PAYMENT/KOUNT_DEVICE/REPORT_KOUNT_DEVICE_SUCCESS',
}

// ------------------------------------
// ActionTypes
// Used for type discrimination in reducers/middleware
// ------------------------------------

interface KountDevicePayload {
    readonly device: object;
}

type IReportKountDeviceSuccessAction = IAction<
    ActionType.REPORT_KOUNT_DEVICE_SUCCESS,
    KountDevicePayload
>;

// ------------------------------------
// Action Union Type
// ------------------------------------

type IKountDeviceAction = IReportKountDeviceSuccessAction;

const reportKountDeviceSuccess = createAction(
    ActionType.REPORT_KOUNT_DEVICE_SUCCESS,
    (device: object) => ({ device }),
);

export {
    ActionType,
    IKountDeviceAction,
    IReportKountDeviceSuccessAction,
    reportKountDeviceSuccess,
};
