// tslint:disable:no-console
import { CustomerState, IAccount } from 'models/account';

interface ITealiumError {
    readonly error_cd?: string;
    readonly error_msg?: string;
}

interface ITealiumLink {
    readonly event_category?: string;
    readonly link_name?: string;
    readonly event_name?: string;
    readonly form_field?: string;
    readonly destination_url?: string;
    readonly link_value?: string;
}

interface ITealiumEventLink {
    readonly flow_name: string;
    readonly page_name: string;
    readonly tealium_event: string;
    readonly link_location: string;
    readonly link_type?: string;
    readonly model_selected?: any;
    readonly login_state?: string;
    readonly account_id?: string;
}

interface ITealiumEventView {
    readonly flow_name: string;
    readonly page_name: string;
    readonly tealium_event: string;
    readonly login_state?: string;
    readonly account_id?: string;
}

interface ITealiumUDO
    extends ITealiumError,
        ITealiumEventLink,
        ITealiumLink,
        ITealiumEventView {}

enum TealiumSitePlatform {
    MOBILE = 'mobile',
    DESKTOP = 'desktop',
}

enum TealiumLoginState {
    LOGGED_IN = 'logged in',
    LOGGED_OUT = 'logged out ',
}

enum TealiumPageType {
    UNKNOWN = '',
    HOME = 'home',
    LEARN = 'learn',
    LOGIN = 'login',
    SUPPORT = 'support',
    CHECKOUT = 'checkout',
    ACCOUNT = 'account',
    SIGNIN = 'signin',
    SIGNUP = 'signup',
    SIGNUP_PARTY = 'signup_party',
    SIGNIN_SUCCESS = 'signin_success',
    PLAN = 'plan',
    Help = 'help',
    VISIBLE_CONNECT = 'visible_connect',
    COVERAGE = 'coverage',
    ANDROID_LANDING = 'android_media_landing_page',
    IOS_LANDING = 'ios_media_landing_page',
    STANDALONE_COMPATABILITY = 'standalone_compatibility',
    PRE_ACTIVE_WELCOME = 'pre_active_welcome',
    PROMO_PAGE_TYPE = 'button',
    PROMO_PAGE_NAME = 'mastercard_promo',
    PROMO_PAGE_TEALIUM_EVENT = 'mc_promo_learn_more',
    NOT_COMPATABILE = 'not_compatabile',
    REFERRAL = 'referral',
    REFERRALTERMS = 'referral_terms',
    SWAP_LANDING = 'swap_landing_page',
    LETS_SWAP = 'lets_swap',
    SPREAD_THE_WORD = 'spread_the_word',
    SPREAD_THE_WORD_COPIED_CODE = 'spread_the_word_copied_code',
    ACTIVE_ACCOUNT_OVERVIEW = 'active_account_overview',
}

enum TealiumSiteLanguage {
    english = 'english',
}

enum TealiumFlowType {
    NONE = '',
    SINGLE_LINE = 'single_line',
    NEW_NUMBER = 'new number',
    PORT = 'port',
}

enum TealiumFlowName {
    SHOP_BYOD = 'shop or byod',
    ACCOUNT_NAV_LINK = 'account_nav',
    NONE = '',
    NEW_SERVICE = 'new service',
    PROMO_FLOW_NAME = '200_shop_100_byod',
    METHOD = 'method',
    IMEI = 'imei',
    MODEL = 'model',
    UNLOCKED = 'unlocked',
    SWAP = 'swap',
    CARRIER = 'carrier',
    BYOD = 'byod',
    STANDALONE_COMPATABILITY_FLOW = 'standalone_compatibility',
    OS = 'os',
}

enum TealiumLinkEvents {
    BUTTON = 'button',
    FORM_FIELD = 'form_field',
    TEXT_LINK = 'text_link',
    ICON = 'icon',
    PROMO_LEARN_MORE = 'mc_promo_learn_more',
    DROPDOWN = 'dropdown',
    NONE = '',
}

enum TealiumPagevents {
    SIGNIN_EMAIL = 'email_enter',
    SIGNIN_PASSWORD = 'password_enter',
    SIGNIN_SUBMIT = 'signin_clicked',
    SIGNIN_FORGOT_PWD = 'forgot_password',
    REGISTERED_HOME_CONTINUE = 'continue',
    SIGNUP_FIRST_NAME = 'first_name_entered',
    SIGNUP_LAST_NAME = 'last_name_entered',
    SIGNUP_EMAIL = 'email_entered',
    SIGNUP_PASSWORD = 'password_entered',
    SIGNUP_PRIVACY = 'privacy_policy',
    SIGNUP_SIGNIN = 'sign_in',
    SIGNUP_SUBMIT = 'create_account',
    SIGNIN_SUCCESS_BROWSE = 'browse_new_phones',
    SIGNIN_SUCCESS_BYOD = 'bring_your_own',
    TOP_PLAN = 'plan',
    TOP_SHOP = 'shop',
    TOP_HELP = 'help',
    TOP_SIGNUP = 'sign_up',
    TOP_SIGNIN = 'sign_in',
    TOP_LOGO = 'visible_logo',
    FOOTER_LOGO = 'visible_logo',
    FOOTER_AFFIRM = 'affirm_finance',
    FOOTER_REFERRAL = 'referral_program',
    FOOTER_SWAP = 'swap_program',
    FOOTER_CAREERS = 'careers',
    FOOTER_RETURN = 'return_policy',
    FOOTER_PRIVACY = 'privacy_policy',
    FOOTER_TERMS = 'term_conditions',
    FOOTER_PROTECT = 'visible_protect',
    FOOTER_COVERAGE = 'coverage',
    FOOTER_CONNECT = 'visible_connect',
    FOOTER_PRESS = 'press',
    FOOTER_FEEDBACK = 'feedback',
    FOOTER_ANDROID_DOWNLOAD = 'android_app_download',
    FOOTER_IOS_DOWNLOAD = 'ios_app_download',
    FOOTER_EMAIL_SUBMIT = 'email_submit',
    SIGNUP_INVITE_CODE = 'invite_code_entered',
    PROMO_CODE_ENTERED = 'promo_code_entered',
    ACCOUNT = 'account',
    ACCOUNT_OVERVIEW = 'overview',
    ACCOUNT_PROFILE = 'profile',
    ACCOUNT_ORDER_HISTORY = 'order_history',
    ACCOUNT_SECURITY = 'security',
    NEXT = 'next',
    MODEL_SELECTED = 'model_selected',
    APPLE = 'apple',
    ANDROID = 'android',
    CARRIER_SELECTED = 'carrier_selected',
    BROWSE_NEW_PHONES = 'browse_new_phones',
    SWAP = 'swap',
    CHECK_AGAIN = 'check_again',
    CODE_COPIED = 'code_copied',
    REFERAL_TERMS = 'terms_and_conditions',
    REFERAL_CLOSE = 'close_clicked',
    REFER_FRIEND = 'refer_a_friend',
    FOOTER_APPLE = 'apple',
    FOOTER_GOOGLE = 'google',
    FOOTER_PARTY_PAY = 'party_pay',
}

enum TealiumPageLinkLocations {
    NONE = '',
    REGISTERED_CONTINUE_LOCATION = 'module_1',
    TOP_NAV = 'top_navigation',
    MODULE_ONE = 'module 1',
    FOOTER = 'footer',
    PROMO_LOCATION = 'top_navigation',
    MODULE_TWO = 'module 2',
}

interface AdditionalPageViewParams {
    readonly Account_Id?: string;
    readonly page_value?: string;
    readonly flow_name: TealiumFlowName;
    readonly flow_type: TealiumFlowType;
    readonly login_state: TealiumLoginState;
    readonly site_platform: TealiumSitePlatform;
    readonly page_type: TealiumPageType;
    readonly global_id: string;
    readonly site_language: TealiumSiteLanguage;
}

interface TealiumPageViewData {
    readonly globalId: string;
    readonly inviteCode: string;
    readonly isLoggedIn: boolean;
    readonly pathname: string;
    readonly account: IAccount;
}

class PageView {
    readonly pageName: string;
    readonly pageType: TealiumPageType;
    readonly data: TealiumPageViewData;

    constructor({
        pageName,
        path,
        data,
    }: {
        readonly pageName?: string;
        readonly path?: string;
        readonly data: TealiumPageViewData;
    }) {
        this.data = data;
        if (path && path.trim()) {
            this.pageName = this.pageNameForPath(path);
        } else {
            this.pageName = pageName || '';
        }

        this.pageType = this.pageTypeForName(this.pageName);
    }

    static forPath(path: string, data: TealiumPageViewData): PageView {
        return new PageView({ data, path });
    }

    static forPageName(pageName: string, data: TealiumPageViewData): PageView {
        return new PageView({ data, pageName });
    }

    event(data: TealiumPageViewData) {
        return {
            page_name: this.pageName,
            page_type: this.pageType,
            ...this.additionalParamsForData(data),
        };
    }

    additionalParamsForData(
        data: TealiumPageViewData,
    ): Partial<AdditionalPageViewParams> {
        return {
            ...(data.inviteCode &&
                data.pathname === __('routes.sign-up') && {
                    page_value: data.inviteCode,
                }),
            Account_Id: this.data.account ? this.data.account.globalkey : '',
            flow_name: this.flowName,
            flow_type: this.flowType,
            global_id: data.globalId,
            login_state: data.isLoggedIn
                ? TealiumLoginState.LOGGED_IN
                : TealiumLoginState.LOGGED_OUT,
            site_language: TealiumSiteLanguage.english,
            site_platform:
                window.innerWidth > 768
                    ? TealiumSitePlatform.DESKTOP
                    : TealiumSitePlatform.MOBILE,
        };
    }

    private pageNameForPath(path: string): string {
        switch (path) {
            case __('routes.home'):
                if (
                    [
                        CustomerState.PURCHASED,
                        CustomerState.PREACTIVATED,
                    ].indexOf(this.data.account.customerState) !== -1
                ) {
                    return __('analytics.sign-up.welcome.page-name');
                }
                return __('analytics.home.page-name');
            case __('routes.coverage'):
                return __('analytics.coverage.page-name');
            case __('routes.plan'):
                return __('analytics.plan.page-name');
            case __('routes.sign-in'):
                return __('analytics.sign-in.page-name');
            case __('routes.help'):
                return __('analytics.faqs.page-name');
            case __('routes.sign-up'):
                return __('analytics.sign-up.page-name');
            case __('routes.account'):
                return __('analytics.account.page-name');
            case __('routes.account.overview'):
                return __('analytics.account.current-statement.page-name');
            case __('routes.account.bill-history'):
                return __('analytics.account.previous-payments.page-name');
            case __('routes.account.profile'):
                return __('analytics.account.profile.page-name');
            case __('routes.account.privacy'):
                return __('analytics.account.privacy.page-name');
            default:
                return this.normalizePathname(path);
        }
    }

    private pageTypeForName(pageName: string): TealiumPageType {
        switch (pageName) {
            case __('analytics.home.page-name'):
                return TealiumPageType.HOME;
            case __('analytics.coverage.page-name'):
            case __('analytics.plan.page-name'):
                return TealiumPageType.LEARN;
            case __('analytics.sign-in.page-name'):
                return TealiumPageType.LOGIN;
            case __('analytics.faqs.page-name'):
                return TealiumPageType.SUPPORT;
            case __('analytics.sign-up.page-name'):
            case __('analytics.sign-up.account.page-name'):
            case __('analytics.sign-up.phone-number.page-name'):
            case __('analytics.sign-up.address.page-name'):
            case __('analytics.sign-up.payment.page-name'):
            case __('analytics.sign-up.summary.page-name'):
            case __('analytics.sign-up.welcome.page-name'):
                return TealiumPageType.CHECKOUT;
            case __('analytics.account.page-name'):
            case __('analytics.account.current-statement.page-name'):
            case __('analytics.account.previous-payments.page-name'):
            case __('analytics.account.profile.page-name'):
            case __('analytics.account.privacy.page-name'):
                return TealiumPageType.ACCOUNT;
            default:
                return TealiumPageType.UNKNOWN;
        }
    }

    private get flowName(): TealiumFlowName {
        return this.pageName.indexOf('SignUp') !== -1
            ? TealiumFlowName.NEW_SERVICE
            : TealiumFlowName.NONE;
    }

    private get flowType(): TealiumFlowType {
        /**
         * As-is, we have no way of knowing at this level if this is a new number flow or a port flow
         */
        return this.singleLinePages.has(this.pageName)
            ? TealiumFlowType.SINGLE_LINE
            : TealiumFlowType.NONE;
    }

    private readonly normalizePathname = (pathname: string) =>
        pathname
            .split(/[\/-]+/)
            .map(
                item =>
                    item.charAt(0).toUpperCase() + item.slice(1).toLowerCase(),
            )
            .join('');

    private get singleLinePages(): Set<string> {
        return new Set([
            __('analytics.sign-up.page-name'),
            __('analytics.sign-up.account.page-name'),
            __('analytics.sign-up.phone-number.page-name'),
            __('analytics.sign-up.address.page-name'),
        ]);
    }
}

export {
    ITealiumError,
    ITealiumLink,
    ITealiumEventLink,
    ITealiumEventView,
    ITealiumUDO,
    TealiumPageViewData,
    PageView,
    TealiumLoginState,
    TealiumLinkEvents,
    TealiumPageType,
    TealiumFlowName,
    TealiumPagevents,
    TealiumPageLinkLocations,
};
