import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { BodyCopy } from 'components/common/typography';
import {
    ButtonContainer as Button,
    ModalWrapperContainer as ModalWrapper,
} from 'containers';
import { IModalProps } from 'models';

interface Props extends IModalProps {
    cancelService(): void;
}

@CSSModules(require('./service-cancel.less'))
class ServiceCancel extends React.Component<Props> {
    render() {
        const { onClose } = this.props;
        return (
            <ModalWrapper title={__('service-cancel.title')}>
                <>
                    <div styleName="modal-inner-wrap">
                        <BodyCopy>{__('service-cancel.message')}</BodyCopy>
                        <div styleName="modal-options">
                            <Button
                                styleName="button"
                                onClick={this.cancelService}
                                label={__('service-cancel.submit')}
                            />
                            <Button
                                styleName="button"
                                onClick={onClose}
                                label={__('service-cancel.cancel')}
                            />
                        </div>
                    </div>
                </>
            </ModalWrapper>
        );
    }

    private readonly cancelService = () => {
        const { cancelService } = this.props;
        cancelService();
    };
}

export { ServiceCancel };
