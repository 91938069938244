import { BodyCopy, Headline } from 'components/common/typography';
import * as React from 'react';
import { GridCell, GridCellProps } from '../grid-cell';

interface Props extends GridCellProps {
    readonly headline?: string | JSX.Element;
    readonly bodyCopy?: string | JSX.Element;
}

const InfoTemplate = ({ children, headline, bodyCopy, ...rest }: Props) => {
    return (
        <GridCell {...rest}>
            {typeof headline === 'string' ? (
                <Headline two>{headline}</Headline>
            ) : (
                headline || null
            )}
            {typeof bodyCopy === 'string' ? (
                <BodyCopy noMargin>{bodyCopy}</BodyCopy>
            ) : bodyCopy ? (
                bodyCopy
            ) : null}
            {children}
        </GridCell>
    );
};

export { InfoTemplate };
