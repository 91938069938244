import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { FAQResponse } from 'models';
import { ActionType, IFAQAction } from './faq.actions';

interface FAQState extends FAQResponse {
    readonly fetchStatus: FetchStatus;
    readonly error: string;
    readonly searchTerm: string;
}

const DEFAULT_STATE: FAQState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
    searchTerm: '',
    topics: [],
    version: '',
};

const {
    FETCH_FAQ,
    REPORT_FAQ_FETCH_ERROR,
    REPORT_FAQ_FETCH_SUCCESS,
    REPORT_FAQ_UP_TO_DATE,
    SEARCH_FAQ,
} = ActionType;

const faqReducer = (
    state: FAQState = DEFAULT_STATE,
    action: IFAQAction | IDefaultAction,
): FAQState => {
    switch (action.type) {
        case FETCH_FAQ:
            return {
                ...state,
                error: '',
                fetchStatus: FetchStatus.FETCHING,
            };
        case REPORT_FAQ_FETCH_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        case REPORT_FAQ_FETCH_SUCCESS:
            return {
                ...state,
                ...action.payload,
                error: '',
                fetchStatus: FetchStatus.SUCCESS,
            };
        case REPORT_FAQ_UP_TO_DATE:
            return {
                ...state,
                error: '',
                fetchStatus: FetchStatus.SUCCESS,
            };
        case SEARCH_FAQ:
            return {
                ...state,
                searchTerm: action.payload,
            };
        default:
            return state;
    }
};

export { FAQState, faqReducer, DEFAULT_STATE };
