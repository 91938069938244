import * as React from 'react';

import { Headline } from 'components/common/typography';
import { GridCell, GridCellProps } from '../grid-cell';

interface Props extends GridCellProps {
    readonly headline?: React.ReactNode;
    readonly subHeadline?: React.ReactNode;
    readonly invert?: boolean;
    readonly two?: boolean;
    readonly three?: boolean;
    readonly left?: boolean;
    readonly tableCaption?: boolean;
    readonly noMargin?: boolean;
    readonly noMarginTop?: boolean;
    readonly noMarginBottom?: boolean;
    readonly headlineCentered?: boolean;
    readonly width?: number;
}

const HeadlineTemplate = ({
    two,
    three,
    invert,
    left,
    tableCaption,
    headline,
    subHeadline,
    children,
    noMargin,
    noMarginTop,
    noMarginBottom,
    headlineCentered,
    ...rest
}: Props) => (
    <GridCell {...rest}>
        {headline && (
            <Headline
                two={two}
                three={three}
                invert={invert}
                tableCaption={tableCaption}
                center={headlineCentered}
                noMargin={noMargin}
                noMarginTop={
                    typeof noMarginTop !== 'undefined' ? noMarginTop : true
                }
                noMarginBottom={noMarginBottom}
            >
                {headline}
            </Headline>
        )}
        {subHeadline}
        {children}
    </GridCell>
);

export { HeadlineTemplate };
