import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./chevron.less');

interface IProps {
    readonly blue?: boolean;
    readonly white?: boolean;
    readonly inverted?: boolean;
    readonly right?: boolean;
    readonly left?: boolean;
    readonly marginLeft?: boolean;
}

@CSSModules(styles, { allowMultiple: true })
class Chevron extends React.Component<IProps> {
    render() {
        const { blue, white, inverted, marginLeft, right, left } = this.props;
        return (
            <svg
                styleName={classNames('chevron', {
                    blue,
                    inverted,
                    marginLeft,
                    white,
                    right,
                    left,
                })}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
            >
                <path
                    fill="none"
                    fillRule="evenodd"
                    strokeWidth="3"
                    d="M3 4.698L8.302 10 13.5 4.802"
                />
            </svg>
        );
    }
}

export { Chevron };
