import { createAction, IAction } from 'lib/br-redux';
import { ICarrier } from 'models/port';

enum ActionType {
    FETCH_CARRIERS = 'PURCHASE/PORT/CARRIERS/FETCH_CARRIERS',
    CLEAR_CARRIERS = 'PURCHASE/PORT/CARRIERS/CLEAR_CARRIERS',
    LOAD_CARRIERS = 'PURCHASE/PORT/CARRIERS/LOAD_CARRIERS',
    REPORT_CARRIERS_ERROR = 'PURCHASE/PORT/CARRIERS/REPORT_CARRIERS_ERROR',
}

type IFetchCarriersAction = IAction<ActionType.FETCH_CARRIERS, string>;
type IClearCarriersAction = IAction<ActionType.CLEAR_CARRIERS>;
type ILoadCarriersAction = IAction<
    ActionType.LOAD_CARRIERS,
    ReadonlyArray<ICarrier>
>;
type IReportCarrierErrorAction = IAction<
    ActionType.REPORT_CARRIERS_ERROR,
    string
>;

type ICarriersAction =
    | IFetchCarriersAction
    | IClearCarriersAction
    | ILoadCarriersAction
    | IReportCarrierErrorAction;

const fetchCarriers = createAction(
    ActionType.FETCH_CARRIERS,
    (mdn: string) => mdn,
);
const clearCarriers = createAction(ActionType.CLEAR_CARRIERS);
const loadCarriers = createAction(
    ActionType.LOAD_CARRIERS,
    (carriers: ReadonlyArray<ICarrier>) => carriers,
);
const reportCarriersError = createAction(
    ActionType.REPORT_CARRIERS_ERROR,
    (err: string) => err,
);

export {
    ActionType,
    IFetchCarriersAction,
    IClearCarriersAction,
    ILoadCarriersAction,
    IReportCarrierErrorAction,
    ICarriersAction,
    fetchCarriers,
    clearCarriers,
    loadCarriers,
    reportCarriersError,
};
