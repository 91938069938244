import * as _ from 'lodash';

type SplitProps<Props extends {}> = [Partial<Props>, Partial<Props>];

const splitProps = <Props extends {}>(
    props: Props,
    legend: {},
): SplitProps<Props> => {
    const pick = _.pick(props, _.keys(legend));
    const other = _.omit(props, _.keys(pick));
    return [pick, other];
};

export default splitProps;
