import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import {
    DEFAULT_REFERRER_STATS,
    DEFAULT_REFERRING_AMBASSADOR,
    DEFAULT_VALIDATE_SHORT_CODE_RESPONSE,
    IReferrerStats,
    IReferringAmbassador,
    IValidateShortCodeResponse,
} from 'models/referral/referral.model';
import { IReferrerStatsRequest } from '../../models/referral';
import { ActionType, IReferralAction } from './referral.actions';

interface IReferralState {
    readonly approvedSum: number;
    readonly creditBalance: number;
    readonly getCreditBalanceError: string;
    readonly getCreditBalanceFetchStatus: FetchStatus;
    readonly getOrCreateReferrerError: string;
    readonly getOrCreateReferrerFetchStatus: FetchStatus;
    readonly getReferrerStatsError: string;
    readonly getReferrerStatsFetchStatus: FetchStatus;
    readonly newReferralInfoError: string;
    readonly newReferralInfoFetchStatus: FetchStatus;
    readonly postEventError: string;
    readonly postEventFetchStatus: FetchStatus;
    readonly referrerStats: IReferrerStats;
    readonly referrerStatsRequest: IReferrerStatsRequest;
    readonly referringAmbassador: IReferringAmbassador;
    readonly shortCode: string;
    readonly totalCreditsEarned: number;
    readonly getCreditEarnedError: string;
    readonly getCreditEarnedFetchStatus: FetchStatus;
    readonly updateCommissionError: string;
    readonly updateCommissionFetchStatus: FetchStatus;
    readonly updateReferrerError: string;
    readonly updateReferrerFetchStatus: FetchStatus;
    readonly validateShortCodeError: string;
    readonly validateShortCodeFetchStatus: FetchStatus;
    readonly validateShortCodeResponse: IValidateShortCodeResponse;
}

// tslint:disable:object-literal-sort-keys
const DEFAULT_STATE: IReferralState = {
    approvedSum: 0,
    creditBalance: 0,
    getCreditBalanceError: '',
    getCreditBalanceFetchStatus: FetchStatus.NOT_FETCHED,
    getOrCreateReferrerError: '',
    getOrCreateReferrerFetchStatus: FetchStatus.NOT_FETCHED,
    getReferrerStatsError: '',
    getReferrerStatsFetchStatus: FetchStatus.NOT_FETCHED,
    newReferralInfoError: '',
    newReferralInfoFetchStatus: FetchStatus.NOT_FETCHED,
    postEventError: '',
    postEventFetchStatus: FetchStatus.NOT_FETCHED,
    referrerStats: DEFAULT_REFERRER_STATS,
    referrerStatsRequest: { email: '' },
    referringAmbassador: DEFAULT_REFERRING_AMBASSADOR,
    shortCode: '',
    totalCreditsEarned: 0,
    getCreditEarnedError: '',
    getCreditEarnedFetchStatus: FetchStatus.NOT_FETCHED,
    updateCommissionError: '',
    updateCommissionFetchStatus: FetchStatus.NOT_FETCHED,
    updateReferrerError: '',
    updateReferrerFetchStatus: FetchStatus.NOT_FETCHED,
    validateShortCodeError: '',
    validateShortCodeFetchStatus: FetchStatus.NOT_FETCHED,
    validateShortCodeResponse: DEFAULT_VALIDATE_SHORT_CODE_RESPONSE,
};

const {
    VALIDATE_SHORT_CODE,
    REPORT_VALIDATE_SHORT_CODE_SUCCESS,
    REPORT_VALIDATE_SHORT_CODE_FAILURE,
    POST_EVENT,
    REPORT_POST_EVENT_SUCCESS,
    REPORT_POST_EVENT_FAILURE,
    GET_OR_CREATE_REFERRER,
    REPORT_GET_OR_CREATE_REFERRER_SUCCESS,
    REPORT_GET_OR_CREATE_REFERRER_FAILURE,
    UPDATE_REFERRER,
    REPORT_UPDATE_REFERRER_SUCCESS,
    REPORT_UPDATE_REFERRER_FAILURE,
    UPDATE_COMMISSION,
    REPORT_UPDATE_COMMISSION_SUCCESS,
    REPORT_UPDATE_COMMISSION_FAILURE,
    NEW_REFERRAL_INFO,
    REPORT_NEW_REFERRAL_INFO_SUCCESS,
    REPORT_NEW_REFERRAL_INFO_FAILURE,
    GET_CREDIT_BALANCE,
    REPORT_GET_CREDIT_BALANCE_SUCCESS,
    REPORT_GET_CREDIT_BALANCE_FAILURE,
    GET_CREDIT_EARNED,
    REPORT_GET_CREDIT_EARNED_SUCCESS,
    REPORT_GET_CREDIT_EARNED_FAILURE,
    GET_REFERRER_STATS,
    REPORT_GET_REFERRER_STATS_SUCCESS,
    REPORT_GET_REFERRER_STATS_FAILURE,
} = ActionType;

const referralReducer = (
    state: IReferralState = DEFAULT_STATE,
    action: IReferralAction | IDefaultAction,
): IReferralState => {
    switch (action.type) {
        case VALIDATE_SHORT_CODE:
            return {
                ...state,
                ...{
                    shortCode: action.payload,
                    validateShortCodeFetchStatus: FetchStatus.FETCHING,
                },
            };
        case REPORT_VALIDATE_SHORT_CODE_SUCCESS:
            return {
                ...state,
                ...{
                    validateShortCodeResponse: action.payload,
                    validateShortCodeFetchStatus: FetchStatus.SUCCESS,
                },
            };
        case REPORT_VALIDATE_SHORT_CODE_FAILURE:
            return {
                ...state,
                ...{
                    validateShortCodeError: action.payload,
                    validateShortCodeFetchStatus: FetchStatus.ERROR,
                },
            };
        case POST_EVENT:
            return {
                ...state,
                ...{
                    postEventFetchStatus: FetchStatus.FETCHING,
                },
            };
        case REPORT_POST_EVENT_SUCCESS:
            return {
                ...state,
                ...{
                    postEventFetchStatus: FetchStatus.SUCCESS,
                },
            };
        case REPORT_POST_EVENT_FAILURE:
            return {
                ...state,
                ...{
                    postEventError: action.payload,
                    postEventFetchStatus: FetchStatus.ERROR,
                },
            };
        case GET_OR_CREATE_REFERRER:
            return {
                ...state,
                ...{
                    getOrCreateReferrerFetchStatus: FetchStatus.FETCHING,
                },
            };
        case REPORT_GET_OR_CREATE_REFERRER_SUCCESS:
            return {
                ...state,
                ...{
                    referringAmbassador: action.payload,
                    getOrCreateReferrerFetchStatus: FetchStatus.SUCCESS,
                },
            };
        case REPORT_GET_OR_CREATE_REFERRER_FAILURE:
            return {
                ...state,
                ...{
                    getOrCreateReferrerError: action.payload,
                    getOrCreateReferrerFetchStatus: FetchStatus.ERROR,
                },
            };
        case UPDATE_REFERRER:
            return {
                ...state,
                ...{
                    updateReferrerFetchStatus: FetchStatus.FETCHING,
                },
            };
        case REPORT_UPDATE_REFERRER_SUCCESS:
            return {
                ...state,
                ...{
                    updateReferrerFetchStatus: FetchStatus.SUCCESS,
                },
            };
        case REPORT_UPDATE_REFERRER_FAILURE:
            return {
                ...state,
                ...{
                    updateReferrerError: action.payload,
                    updateReferrerFetchStatus: FetchStatus.ERROR,
                },
            };
        case UPDATE_COMMISSION:
            return {
                ...state,
                ...{
                    updateCommissionFetchStatus: FetchStatus.FETCHING,
                },
            };
        case REPORT_UPDATE_COMMISSION_SUCCESS:
            return {
                ...state,
                ...{
                    updateCommissionFetchStatus: FetchStatus.SUCCESS,
                },
            };
        case REPORT_UPDATE_COMMISSION_FAILURE:
            return {
                ...state,
                ...{
                    updateCommissionError: action.payload,
                    updateCommissionFetchStatus: FetchStatus.ERROR,
                },
            };
        case NEW_REFERRAL_INFO:
            return {
                ...state,
                ...{
                    newReferralInfoFetchStatus: FetchStatus.FETCHING,
                },
            };
        case REPORT_NEW_REFERRAL_INFO_SUCCESS:
            return {
                ...state,
                ...{
                    newReferralInfoFetchStatus: FetchStatus.SUCCESS,
                },
            };
        case REPORT_NEW_REFERRAL_INFO_FAILURE:
            return {
                ...state,
                ...{
                    newReferralInfoError: action.payload,
                    newReferralInfoFetchStatus: FetchStatus.ERROR,
                },
            };
        case GET_CREDIT_BALANCE:
            return {
                ...state,
                ...{
                    getCreditBalanceFetchStatus: FetchStatus.FETCHING,
                },
            };
        case REPORT_GET_CREDIT_BALANCE_SUCCESS:
            return {
                ...state,
                ...{
                    creditBalance: parseInt(action.payload),
                    getCreditBalanceFetchStatus: FetchStatus.SUCCESS,
                },
            };
        case REPORT_GET_CREDIT_BALANCE_FAILURE:
            return {
                ...state,
                ...{
                    getCreditBalanceError: action.payload,
                    getCreditBalanceFetchStatus: FetchStatus.ERROR,
                },
            };
        case GET_CREDIT_EARNED:
            return {
                ...state,
                ...{
                    getCreditEarnedFetchStatus: FetchStatus.FETCHING,
                },
            };
        case REPORT_GET_CREDIT_EARNED_SUCCESS:
            return {
                ...state,
                ...{
                    totalCreditsEarned: parseInt(action.payload),
                    getCreditEarnedFetchStatus: FetchStatus.SUCCESS,
                },
            };
        case REPORT_GET_CREDIT_EARNED_FAILURE:
            return {
                ...state,
                ...{
                    getCreditEarnedError: action.payload,
                    getCreditEarnedFetchStatus: FetchStatus.ERROR,
                },
            };
        case GET_REFERRER_STATS:
            return {
                ...state,
                ...{
                    referrerStatsRequest: action.payload,
                    getReferrerStatsFetchStatus: FetchStatus.FETCHING,
                },
            };
        case REPORT_GET_REFERRER_STATS_SUCCESS:
            return {
                ...state,
                ...{
                    approvedSum: parseInt(action.payload.approvedSum),
                    creditBalance: parseInt(action.payload.referralCreditBal),
                    totalCreditsEarned: parseInt(
                        action.payload.totalCreditsEarned,
                    ),
                    getReferrerStatsFetchStatus: FetchStatus.SUCCESS,
                },
            };
        case REPORT_GET_REFERRER_STATS_FAILURE:
            return {
                ...state,
                ...{
                    getReferrerStatsError: action.payload,
                    getReferrerStatsFetchStatus: FetchStatus.ERROR,
                },
            };
        default:
            return {
                ...state,
            };
    }
};

export { DEFAULT_STATE, IReferralState, referralReducer };
