const COMPATIBILITY_SKIP_ENABLED =
    process.env.COMPATIBILITY_SKIP_ENABLED === 'true';

const DEVICE_OS = {
    0: 'iOS',
    1: 'Android',
};

interface IDeviceCompatibilityPayload {
    readonly deviceId?: string;
    readonly carrier?: string; // TODO(dan) Not in Device v2 Swagger.
    readonly model?: string;
    readonly os?: string;
}

const DEFAULT_COMPATIBILITY = {
    compatibility: '',
    deviceNotFound: '',
    message: '',
    deviceLockCompatibility: '',
    swappable: '',
};

interface IDeviceCompatibilityResponse {
    readonly compatibility: any;
    readonly deviceId?: string;
    readonly deviceNotFound?: string;
    readonly message?: string;
    readonly deviceLockCompatibility: string;
    readonly swappable: string;
}

interface IDeviceModel {
    readonly [key: string]: ReadonlyArray<string>;
}

type DeviceModels = ReadonlyArray<IDeviceModel>;

interface IDeviceListCarriersModels {
    readonly carriers: ReadonlyArray<string>;
    readonly models: DeviceModels;
}

export {
    DeviceModels,
    DEFAULT_COMPATIBILITY,
    DEVICE_OS,
    IDeviceModel,
    COMPATIBILITY_SKIP_ENABLED,
    IDeviceCompatibilityPayload,
    IDeviceCompatibilityResponse,
    IDeviceListCarriersModels,
};
