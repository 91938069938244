import * as React from 'react';

import { ResubmissionType } from 'components/port-fallout/base-resubmission-form';
import { BaseResubmissionFormContainer as BaseResubmissionForm } from 'containers';

const PINResubmissionForm = () => (
    <BaseResubmissionForm
        title={__('account-pin-resubmission.title')}
        message={__('account-pin-resubmission.message')}
        label={__('account-pin-resubmission.label')}
        type={ResubmissionType.PIN}
    />
);

export { PINResubmissionForm };
