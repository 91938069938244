import { IDefaultAction } from 'lib/br-redux';
import { ActionType, IPaymentNonceAction } from './payment-nonce.actions';

type IPaymentNonceState = string;
const DEFAULT_STATE: IPaymentNonceState = '';
const { SELECT_PAYMENT_METHOD, CLEAR_PAYMENT_METHOD } = ActionType;

const paymentNonceReducer = (
    state: IPaymentNonceState = DEFAULT_STATE,
    action: IPaymentNonceAction | IDefaultAction,
) => {
    switch (action.type) {
        case SELECT_PAYMENT_METHOD:
            return action.payload;
        case CLEAR_PAYMENT_METHOD:
            return '';
        default:
            return state;
    }
};

export { IPaymentNonceState, DEFAULT_STATE, paymentNonceReducer };
