import { CountDown } from 'components/common/countdown';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';

const mapStateToProps = (
    { account }: IApplicationState,
    { mdnCounterEnabled, pinCounterEnabled },
) => ({
    incorrectAccountPinPortRetryCounter:
        account.incorrectAccountPinPortRetryCounter,
    incorrectAccountPinPortRetryThreshold:
        account.incorrectAccountPinPortRetryThreshold,
    incorrectMDNPortRetryCounter: account.incorrectMDNPortRetryCounter,
    incorrectMDNPortRetryThreshold: account.incorrectMDNPortRetryThreshold,
    mdnCounterEnabled,
    pinCounterEnabled,
});

const enhance = compose(connect(mapStateToProps));
const CountDownContainer = enhance(CountDown);

export { CountDownContainer };
