import {
    ITealiumError,
    ITealiumEventLink,
    ITealiumEventView,
    ITealiumLink,
    ITealiumUDO,
    TealiumFlowName,
    TealiumLinkEvents,
    TealiumLoginState,
    TealiumPageLinkLocations,
    TealiumPageType,
    TealiumPagevents,
} from './tealium-data.model';

declare global {
    interface Window {
        // tslint:disable:readonly-keyword
        utag_cfg_ovrd: {
            noview: boolean;
        };
        utag_data: ITealiumUDO;
        utag:
            | {
                  view: (
                      data: ITealiumUDO | undefined | null,
                  ) => void | undefined;
                  link: (
                      data: ITealiumUDO | undefined | null,
                  ) => void | undefined;
              }
            | undefined;
    }
}

export {
    ITealiumError,
    ITealiumLink,
    ITealiumEventLink,
    ITealiumEventView,
    ITealiumUDO,
    TealiumLoginState,
    TealiumLinkEvents,
    TealiumPageType,
    TealiumFlowName,
    TealiumPagevents,
    TealiumPageLinkLocations,
};
