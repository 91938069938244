import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { BodyCopy } from 'components/common/typography';
import {
    ButtonContainer as Button,
    ServiceCancelContainer as ServiceCancel,
    ServicePauseContainer as ServicePause,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { IModalProps, IQueueableModal } from 'models';
import { CancelState } from 'state/account/cancel/cancel.reducer';
import { PauseState } from 'state/account/pause/pause.reducer';

const styles = require('./service.less');

// todo(dan): get pause/cancel state

interface IMappedProps extends CancelState, PauseState {
    readonly fetchStatus: FetchStatus;
    readonly error: string;
    showModal(modal: IQueueableModal): void;
}

interface IState {
    readonly isServicePaused: boolean | null;
    readonly isServiceCanceled: boolean | null;
    readonly isServiceLapsed: boolean | null;
    readonly isServiceSuspended: boolean | null;
}

type IProps = IMappedProps & IModalProps;

@CSSModules(styles, { allowMultiple: true })
class Service extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isServiceCanceled: null,
            isServiceLapsed: null,
            isServicePaused: null,
            isServiceSuspended: null,
        };
    }

    render(): JSX.Element {
        return (
            <>
                <div styleName="service-pause-container">
                    <BodyCopy blue>
                        {__('account.service.pauseMembership')}
                    </BodyCopy>
                    <BodyCopy>
                        {__('account.service.pauseMembershipMessage')}
                    </BodyCopy>
                    <div styleName="service-pause-options">
                        <Button
                            invert
                            secondary
                            styleName="button"
                            onClick={this.showPauseConfirmation(3)}
                            label={__('account.service.pauseOption1')}
                        />
                        <Button
                            invert
                            secondary
                            styleName="button"
                            onClick={this.showPauseConfirmation(6)}
                            label={__('account.service.pauseOption2')}
                        />
                        <Button
                            invert
                            secondary
                            styleName="button"
                            onClick={this.showPauseConfirmation(9)}
                            label={__('account.service.pauseOption3')}
                        />
                    </div>
                </div>

                <div styleName="divider-dotted" />

                <div styleName="service-cancel-container">
                    <BodyCopy blue>
                        {__('account.service.cancelMembership')}
                    </BodyCopy>
                    <BodyCopy>
                        {__('account.service.cancelMembershipMessage')}
                    </BodyCopy>
                    <div styleName="service-cancel-options">
                        <Button
                            styleName="button"
                            onClick={this.showCancelConfirmation()}
                            label={__('account.service.cancel')}
                        />
                    </div>
                </div>
            </>
        );
    }

    private readonly showCancelConfirmation = (): (() => void) => {
        const { showModal } = this.props;
        return () => {
            showModal({
                additionalProps: {},
                component: ServiceCancel as any,
            });
        };
    };

    private readonly showPauseConfirmation = (
        // todo(dan) type?
        pauseServiceTerm: number,
    ): (() => void) => {
        const { showModal } = this.props;
        return () => {
            showModal({
                additionalProps: { pauseServiceTerm },
                component: ServicePause as any,
            });
        };
    };
}

export { Service };
