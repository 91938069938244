import { BillHistory } from 'components/account/bill-history/bill-history';
import { withDeviceType } from 'components/common/device-type';
import { IApplicationState } from 'models';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchBillDetails, fetchBillHistory } from 'state/bill';
import { showModal } from 'state/view/view.actions';
import { CPNIModalContainer } from '.';

const mapStateToProps = (state: IApplicationState) => ({
    chargeEvents: state.bill.history.results || [],
    fetchStatus: state.bill.history.fetchStatus,
});

const mapDispatchToProps = dispatch => ({
    fetchBillHistory: () => dispatch(fetchBillHistory()),
    fetchDetails: id => dispatch(fetchBillDetails(id)),
    showCPNI: () =>
        dispatch(
            showModal({
                additionalProps: {},
                component: CPNIModalContainer as any,
            }),
        ),
    showModal: p => dispatch(showModal(p)),
});

const enhance = compose(
    withDeviceType,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);
const BillHistoryContainer = enhance(BillHistory);

export { BillHistoryContainer };
