import { FetchStatus, IDefaultAction } from 'lib/br-redux';
import { ILead } from 'models';
import { ActionType, ILeadAction } from './lead.actions';

interface ILeadState {
    readonly lead: ILead;
    readonly error: string;
    readonly fetchStatus: FetchStatus;
}

const DEFAULT_STATE: ILeadState = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
    lead: {
        carrier: '',
        device: '',
        email: '',
        os: '',
    },
};

const {
    CAPTURE_LEAD,
    CLEAR,
    REPORT_LEAD_CAPTURE_ERROR,
    REPORT_LEAD_CAPTURE_SUCCESS,
} = ActionType;

const leadReducer = (
    state: ILeadState = DEFAULT_STATE,
    action: ILeadAction | IDefaultAction,
): ILeadState => {
    switch (action.type) {
        case CAPTURE_LEAD:
            return {
                ...state,
                error: '',
                fetchStatus: FetchStatus.FETCHING,
                lead: action.payload,
            };
        case CLEAR:
            return { ...DEFAULT_STATE };
        case REPORT_LEAD_CAPTURE_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            };
        case REPORT_LEAD_CAPTURE_SUCCESS:
            return {
                ...state,
                error: '',
                fetchStatus: FetchStatus.SUCCESS,
            };
        default:
            return state;
    }
};

export { ILeadState, DEFAULT_STATE, leadReducer, ILead };
