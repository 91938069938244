import * as React from 'react';

import {
    AccountInfoContainer as AccountInfo,
    EnterPhoneNumberContainer as EnterPhoneNumber,
    SelectCarrierContainer as SelectCarrier,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { Cart } from 'models/cart/cart.model';
import { IAccountInfo, ICarrier } from 'models/port';
import { ProductType } from 'models/products';
import {
    ICartItemPayload,
    IProductItemModel,
} from 'models/products/products.model';

interface Props {
    readonly carriers: ReadonlyArray<ICarrier>;
    readonly carriersFetchStatus: FetchStatus;
    readonly cartError: string;
    readonly cartFetchStatuses: Set<FetchStatus>;
    readonly carriersError: string;
    readonly cart: Cart | null;
    readonly sim: IProductItemModel | undefined;
    addItemToCart(item: ICartItemPayload): void;
    fetchCarriers(phoneNumber: string): void;
    clearCarriers(): void;

    onNext(): void;
    onEmptyCarrierList(): void;
}

interface IState {
    readonly phoneNumber: string;
    readonly carrier?: ICarrier;
    readonly accountInfo?: IAccountInfo;
}

class PortNumber extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            phoneNumber: '',
        };
    }

    componentWillReceiveProps(newProps: Props): void {
        const { cart, onNext, sim } = newProps;
        if (sim) {
            if (
                cart &&
                cart.hasProductType(ProductType.SIM) &&
                this.fetchStatus !== FetchStatus.SUCCESS
            ) {
                onNext();
            }
        }

        // if the carrier list comes back empty, we need to let the parent component know by calling the handler
        if (
            this.props.carriersFetchStatus !== FetchStatus.SUCCESS &&
            newProps.carriersFetchStatus === FetchStatus.SUCCESS &&
            (!newProps.carriers || !newProps.carriers.length)
        ) {
            this.props.onEmptyCarrierList();
        }
    }

    componentWillUnmount() {
        this.props.clearCarriers();
    }

    render() {
        const {
            accountInfoComponent,
            carrierComponent,
            handlePhoneNumberSubmit,
            isFetching,
            props: { carriersFetchStatus, carriersError },
            setPhoneNumber,
        } = this;
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <EnterPhoneNumber
                    complete={carriersFetchStatus === FetchStatus.SUCCESS}
                    carriersError={!isFetching ? carriersError : ''}
                    fetching={isFetching}
                    handleOnPhoneNumberChange={setPhoneNumber}
                    handleSubmit={handlePhoneNumberSubmit}
                />
                {carrierComponent}
                {accountInfoComponent}
            </div>
        );
    }

    private get carrierComponent(): JSX.Element | null {
        const {
            props: { carriers, carriersFetchStatus },
            state: { phoneNumber },
        } = this;
        switch (carriersFetchStatus) {
            case FetchStatus.SUCCESS:
                if (phoneNumber && carriers && carriers.length >= 1) {
                    return (
                        <SelectCarrier
                            complete={!!this.state.carrier}
                            carriers={carriers}
                            handleChange={this.setCarrier}
                            label={__('phone-carrier.label')}
                        />
                    );
                }
                return null;
        }

        return null;
    }

    private get accountInfoComponent(): JSX.Element | null {
        const {
            state: { carrier, phoneNumber },
        } = this;
        if (phoneNumber && carrier) {
            return (
                <AccountInfo
                    accountNumberInstructions={
                        carrier.accountNumberInstructions
                    }
                    pinNumberInstructions={carrier.pinNumberInstructions}
                    handleSubmit={this.setAccountInfo}
                    isLoading={this.isFetching}
                    error={this.props.cartError}
                    havingTrouble={carrier.havingTrouble}
                    havingTroubleLabel={carrier.havingTroubleLabel}
                    instructionDescriptionLabel={
                        carrier.instructionDescriptionLabel
                    }
                    operatorName={carrier.operatorName}
                    support={carrier.support}
                    supportNumberLabel={carrier.supportNumberLabel}
                />
            );
        }

        return null;
    }

    private readonly setPhoneNumber = (phoneNumber: string): void => {
        if (this.props.carriers.length > 0) {
            this.props.clearCarriers();
        }
        this.setState({
            accountInfo: undefined,
            carrier: undefined,
            phoneNumber,
        });
    };

    private readonly handlePhoneNumberSubmit = (phoneNumber: string): void => {
        this.props.fetchCarriers(phoneNumber);
    };

    private readonly setCarrier = (carrier: ICarrier): void => {
        this.setState({ accountInfo: undefined, carrier });
    };

    private readonly setAccountInfo = (accountInfo: IAccountInfo): void => {
        this.setState({ accountInfo }, () => {
            const { phoneNumber, carrier } = this.state;
            const { addItemToCart, sim } = this.props;
            if (sim && carrier) {
                const { itemid } = sim;
                addItemToCart({
                    itemid,
                    portin: {
                        numberLoc: carrier.numberLoc,
                        portEligibilityInd: carrier.portEligibilityInd,
                        portinAccountNumber: accountInfo.accountNumber,
                        portinMdn: phoneNumber,
                        portinOperator: carrier.operatorName,
                        portinPin: accountInfo.pin,
                        portinSpid: carrier.operatorSPID,
                        portinZip: accountInfo.zip,
                    },
                    productType: ProductType.SIM,
                    quantity: 1,
                });
            }
        });
    };

    private get fetchStatus(): FetchStatus {
        const { cart, cartFetchStatuses, carriersFetchStatus } = this.props;

        if (cartFetchStatuses.has(FetchStatus.FETCHING)) {
            return FetchStatus.FETCHING;
        }

        if (carriersFetchStatus === FetchStatus.FETCHING) {
            return carriersFetchStatus;
        }

        if (cart && cart.hasProductType(ProductType.SIM)) {
            return FetchStatus.SUCCESS;
        }

        return FetchStatus.NOT_FETCHED;
    }

    private get isFetching(): boolean {
        return this.fetchStatus === FetchStatus.FETCHING;
    }
}

export { PortNumber };
