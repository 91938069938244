import { isEmpty } from '../common';

const formatMoney = (money: string | number): string => {
    if (isEmpty(money)) {
        return 'N/A';
    }
    return new Intl.NumberFormat('en-US', {
        currency: 'USD',
        minimumFractionDigits: 2,
        style: 'currency',
    }).format(parseFloat(money.toString()));
};

export { formatMoney };
