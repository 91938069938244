import { withAnalytics } from 'components/common/analytics';
import { withModal } from 'components/common/modal';
import { InactiveNumberError } from 'components/sign-up/components/phone-number/errors/inactive-number-error';
import { compose } from 'redux';

const enhance = compose(
    withModal,
    withAnalytics,
);
const InactiveNumberErrorContainer = enhance(InactiveNumberError);

export { InactiveNumberErrorContainer };
