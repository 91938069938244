import * as React from 'react';

import { ResubmissionType } from 'components/port-fallout/base-resubmission-form';
import { BaseResubmissionFormContainer as BaseResubmissionForm } from 'containers';

const AccountNumberResubmissionForm = () => (
    <BaseResubmissionForm
        title={__('account-number-resubmission.title')}
        message={__('account-number-resubmission.message')}
        label={__('account-number-resubmission.label')}
        type={ResubmissionType.ACCOUNT_NUMBER}
    />
);

export { AccountNumberResubmissionForm };
