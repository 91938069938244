import { createStateMerger, FetchStatus, IDefaultAction } from 'lib/br-redux';
import { INpaNxxCollection } from 'models';
import { REHYDRATE } from 'redux-persist';
import {
    ActionType,
    INpaNxxAction,
} from 'state/purchase/npanxx/npanxx.actions';

interface INpaNxxState {
    readonly fetchStatus: FetchStatus;
    readonly error: string;
    readonly results: INpaNxxCollection;
}

const DEFAULT_STATE = {
    error: '',
    fetchStatus: FetchStatus.NOT_FETCHED,
    results: [],
};

const {
    FETCH_NPA_NXX,
    LOAD_NPA_NXX,
    REPORT_NPA_NXX_ERROR,
    CLEAR_NPA_NXX,
} = ActionType;

const npaNxxReducer = (
    state: INpaNxxState = DEFAULT_STATE,
    action: INpaNxxAction | IDefaultAction,
): INpaNxxState => {
    const merge = createStateMerger(state);
    switch (action.type) {
        case REHYDRATE as '':
            return merge({ error: '' });
        case FETCH_NPA_NXX:
            return merge({ fetchStatus: FetchStatus.FETCHING, error: '' });
        case LOAD_NPA_NXX:
            return merge({
                fetchStatus: FetchStatus.SUCCESS,
                results: action.payload,
            });
        case REPORT_NPA_NXX_ERROR:
            return merge({
                error: action.payload,
                fetchStatus: FetchStatus.ERROR,
            });
        case CLEAR_NPA_NXX:
            return merge(DEFAULT_STATE);
        default:
            return state;
    }
};

export { DEFAULT_STATE, INpaNxxState, npaNxxReducer };
