export const isEmpty = value => {
    try {
        if (typeof value === 'undefined' || value === null) {
            return true;
        }
        return false;
    } catch (err) {
        return undefined;
    }
};

export const isVowel = value => {
    const vowels = ['a', 'e', 'i', 'o', 'u'];

    return vowels.indexOf(value.charAt(0)) === -1 ? false : true;
};
