import { ThinAirService } from './thin-air.service';

const queryStringify = (obj: Record<string, string>) =>
    Object.keys(obj).reduce(
        (str: string, key: string) =>
            `${str === '?' ? str : `${str}&`}${key}=${obj[key]}`,
        '?',
    );

class UserValidationService extends ThinAirService {
    static async validate(verificationCode: string): Promise<any> {
        return UserValidationService.fetchJson(
            [
                UserValidationService.BASE_URL,
                'v1',
                'authentication',
                'validateuser',
            ].join('/') + queryStringify({ verificationCode }),
            {
                headers: UserValidationService.getDefaultHeaders(),
                method: 'POST',
            },
        );
    }
}

export { UserValidationService };
