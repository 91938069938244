import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { BodyCopy } from 'components/common/typography';
import {
    ButtonContainer as Button,
    InputContainer as Input,
    ModalWrapperContainer as ModalWrapper,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { IAnalyticsProps, IInputValidationProps, IModalProps } from 'models';
import { InputValidator } from 'utils';

const styles = require('./update-email-modal.less');

interface Props extends IInputValidationProps, IModalProps, IAnalyticsProps {
    readonly status: FetchStatus;
    readonly error: string;
    readonly reauthenticationStatus: FetchStatus;
    updateEmailAddress(email: string): void;
}

@CSSModules(styles)
class UpdateEmailModal extends React.Component<Props> {
    componentWillReceiveProps(newProps: Props): void {
        if (
            newProps.status !== this.props.status &&
            newProps.status === FetchStatus.SUCCESS
        ) {
            const {
                props: { bindHandleClickWith, onConfirm },
            } = this;
            bindHandleClickWith(onConfirm)();
        }
    }
    render() {
        const {
            hasErrors,
            handleSubmit,
            isFetching,
            props: {
                data: { email },
                error,
                handleOnChange,
                handleOnValidate,
            },
        } = this;
        return (
            <ModalWrapper closable title={__('account.updateEmail.title')}>
                <>
                    <BodyCopy margin30Bottom>
                        {__('account.updateEmail.description')}
                    </BodyCopy>
                    <form onSubmit={handleSubmit}>
                        <Input
                            noMargin
                            error={error}
                            label={__('account.updateEmail.label')}
                            id="email"
                            type="email"
                            value={email}
                            onChange={handleOnChange}
                            onValidate={handleOnValidate}
                            validate={InputValidator.validateEmail}
                        />
                        <Button
                            loading={isFetching}
                            disabled={hasErrors}
                            type="submit"
                            label={__('account.updateEmail.next')}
                        />
                    </form>
                </>
            </ModalWrapper>
        );
    }

    private get isFetching(): boolean {
        const {
            props: { status },
        } = this;
        return status === FetchStatus.FETCHING;
    }

    private get hasErrors(): boolean {
        const {
            props: {
                errors: { email },
            },
        } = this;
        return email;
    }

    private readonly handleSubmit = (
        event: React.FormEvent<HTMLFormElement>,
    ): void => {
        event.preventDefault();
        const {
            props: {
                data: { email },
                updateEmailAddress,
            },
        } = this;
        updateEmailAddress(email);
    };
}

export { UpdateEmailModal };
