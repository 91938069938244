export class DeferredPromise<T> {
    // tslint:disable:readonly-keyword
    resolve: (resolver?: T | PromiseLike<T>) => void;
    reject: (arg?: any) => void;
    promise: Promise<T>;
    // tslint:enable

    constructor(value?: T) {
        this.promise = new Promise<T>((resolveFn, rejectFn) => {
            this.resolve = resolveFn;
            this.reject = rejectFn;

            if (value) {
                this.resolve(value);
            }
        });
    }
}
