import { PurchaseType } from 'models';
import {
    AOAccountOrderResponse,
    HandsetItemResponse,
    MDNType,
    OrderItemResponse,
    SIMItemResponse,
} from 'models/account';
import { ProductType } from 'models/products';

const KOUNT_ENABLED = process.env.KOUNT_ENABLED === 'true';

// tslint:disable:max-classes-per-file

interface ICheckoutPayload {
    readonly email: string;
    readonly uid: string;
    readonly transactionId: string;
    readonly imei: string;
    readonly shortCode?: string;
    readonly campaignId?: string;
    readonly deviceData?: object;
}

class BaseCartItem {
    readonly name: string;
    readonly createDate: string;
    readonly salesforceId: string;
    readonly itemId: string;
    readonly mdnType?: string;
    readonly parentId: string;
    readonly quantity: number;
    readonly productType: ProductType;

    constructor(orderItem: OrderItemResponse) {
        this.name = orderItem.Name;
        this.createDate = orderItem.CreateDate;
        this.salesforceId = orderItem.SalesforceId;
        this.itemId = orderItem.SalesforceId;
        this.quantity = 1;
        this.productType = orderItem.Family;
    }
}

class SIMCartItem extends BaseCartItem {
    readonly mdnType: MDNType;
    readonly productType: ProductType.SIM;
    readonly mdn: string;

    constructor(orderItem: SIMItemResponse) {
        super(orderItem);
        this.mdnType = orderItem.MDNType || MDNType.UNKNOWN;
        this.mdn = orderItem.MDN;
    }

    get isNewMDN(): boolean {
        return this.mdnType === MDNType.NEW;
    }
}

class HandsetCartItem extends BaseCartItem {
    readonly serialNumber: string;
    readonly productType: ProductType.HANDSET;
    readonly oneTimeTotal: number;
    readonly imageURL: any;
    readonly color: any;
    readonly storage: any;

    constructor(orderItem: HandsetItemResponse) {
        super(orderItem);
        this.serialNumber = orderItem.SerialNumber;
        this.oneTimeTotal = orderItem['OneTimeTotal'] || 0;
        this.imageURL = orderItem.ImageURL || null;
        this.color = orderItem.Color ? orderItem.Color : '';
        this.storage = orderItem.Storage ? orderItem.Storage : '';
    }

    readonly isByod = (): boolean => {
        return this.oneTimeTotal === 0;
    };
}

type CartItem = BaseCartItem | HandsetCartItem;

class Cart {
    readonly id: string;
    readonly items: ReadonlyArray<CartItem>;
    readonly model: string;
    readonly make: string;
    readonly status: string;

    readonly purchaseType: string;
    readonly shippingUrl: string;

    constructor(order: AOAccountOrderResponse) {
        this.id = order.SalesforceId;
        this.items = order.Items
            ? order.Items.map(this.orderItemToCartItem)
            : [];
        this.purchaseType = order.PurchaseType ? order.PurchaseType : '';
        this.shippingUrl = order.ShipmentTrackingURL
            ? order.ShipmentTrackingURL
            : '';
        this.model = order.Model ? order.Model : '';
        this.make = order.Make ? order.Make : '';
        this.status = order.Status ? order.Status : '';
    }

    // Adding status and purchase_tyoe checks
    readonly isDraft = (): boolean => {
        return this.status === PurchaseType.STATUS_DRAFT;
    };

    // Adding status and purchase_tyoe checks
    readonly isProcessed = (): boolean => {
        return this.status === PurchaseType.STATUS_PROCESSED;
    };

    readonly isByod = (): boolean => {
        return this.purchaseType === PurchaseType.BYOD;
    };

    readonly isDevicePurchase = (): boolean => {
        return this.purchaseType === PurchaseType.DEVICE_PURCHASE;
    };

    readonly itemForItemId = (itemId: string): CartItem | undefined => {
        return this.items.find(i => i.itemId === itemId);
    };

    readonly hasItemId = (itemId: string): boolean => {
        return !!this.itemForItemId(itemId);
    };

    readonly itemForProductType = (
        productType: ProductType,
    ): CartItem | undefined => {
        return this.items.find(i => i.productType === productType);
    };

    readonly hasProductType = (productType: ProductType): boolean => {
        return !!this.itemForProductType(productType);
    };

    private readonly orderItemToCartItem = (
        orderItem: OrderItemResponse,
    ): CartItem => {
        switch (orderItem.Family) {
            case ProductType.HANDSET:
                return new HandsetCartItem(orderItem as HandsetItemResponse);
            case ProductType.SIM:
                return new SIMCartItem(orderItem as SIMItemResponse);
            default:
                return new BaseCartItem(orderItem);
        }
    };
}

export {
    BaseCartItem,
    Cart,
    CartItem,
    HandsetCartItem,
    ICheckoutPayload,
    KOUNT_ENABLED,
    SIMCartItem,
};
