import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Dots } from 'components/common/dots';
import { IDeviceTypeProps } from 'models';
import { splitProps } from 'utils';

const styles = require('./button.less');

enum Modifiers {
    label = 'label',
    embedded = 'embedded',
    secondary = 'secondary',
    selected = 'selected',
    loading = 'loading',
    invert = 'invert',
    fullWidth = 'fullWidth',
    rounded = 'rounded',
    right = 'right',
    noPaddingVertically = 'noPaddingVertically',
    noPaddingSides = 'noPaddingSides',
    noMargin = 'noMargin',
    noMarginMobile = 'noMarginMobile',
    noMarginTop = 'noMarginTop',
    noMarginTopMobile = 'noMarginTopMobile',
    noMarginBottom = 'noMarginBottom',
    noMarginBottomMobile = 'noMarginBottomMobile',
    margin20 = 'margin20',
    margin30 = 'margin30',
    margin15Top = 'margin15Top',
    margin15Bottom = 'margin15Bottom',
    margin30Top = 'margin30Top',
    margin30Bottom = 'margin30Bottom',
    minWidth = 'minWidth',
    link = 'link',
    blueLink = 'blueLink',
    smallMobile = 'smallMobile',
    error = 'error',
    fontSize16 = 'fontSize16',
    mobileFont = 'mobileFont',
}

interface ButtonModifiers {
    readonly label?: string;
    readonly embedded?: boolean;
    readonly secondary?: boolean;
    readonly selected?: boolean;
    readonly loading?: boolean;
    readonly invert?: boolean;
    readonly fullWidth?: boolean;
    readonly rounded?: boolean;
    readonly fullWidthMobile?: boolean;
    readonly right?: boolean;
    readonly noPaddingSides?: boolean;
    readonly noPaddingVertically?: boolean;
    readonly noMargin?: boolean;
    readonly noMarginMobile?: boolean;
    readonly noMarginTop?: boolean;
    readonly noMarginTopMobile?: boolean;
    readonly noMarginBottom?: boolean;
    readonly noMarginBottomMobile?: boolean;
    readonly margin20?: boolean;
    readonly margin30?: boolean;
    readonly margin15Top?: boolean;
    readonly margin15Bottom?: boolean;
    readonly margin30Top?: boolean;
    readonly margin30Bottom?: boolean;
    readonly minWidth?: number;
    readonly link?: boolean;
    readonly blueLink?: boolean;
    readonly smallMobile?: boolean;
    readonly error?: boolean;
    readonly fontSize16?: boolean;
    readonly mobileFont?: boolean;
}

interface Props
    extends React.DetailedHTMLProps<
            React.ButtonHTMLAttributes<HTMLButtonElement>,
            HTMLButtonElement
        >,
        ButtonModifiers,
        IDeviceTypeProps {}

@CSSModules(styles, { allowMultiple: true })
class Button extends React.Component<Props> {
    render() {
        const {
            props: {
                children,
                disabled,
                fullWidthMobile,
                isMobile,
                isDesktop,
                windowWidth,
                embedded,
                invert,
                loading,
                minWidth,
                styleName,
                type,
                fontSize16,
                mobileFont,
                ...props
            },
        } = this;
        const [modifiers, other] = splitProps(props, Modifiers);
        const cn = classNames(
            'button',
            {
                fullWidth: (fullWidthMobile && isMobile) || props.fullWidth,
                embedded,
                invert,
                loading,
                minWidth,
                fontSize16,
                mobileFont,
            },
            modifiers,
            styleName,
        );
        return (
            <button
                type={type || 'button'}
                styleName={cn}
                disabled={disabled || loading}
                style={{ minWidth }}
                {...other}
            >
                {this.content}
            </button>
        );
    }

    private get content(): JSX.Element {
        const {
            props: { children, invert, loading, label },
        } = this;
        if (loading) {
            return <Dots invert={invert} />;
        }
        return (
            <>
                {children}
                {label && (
                    <div styleName="transition">
                        <span>{label}</span>
                    </div>
                )}
            </>
        );
    }
}

export { Button };
