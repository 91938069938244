import * as classNames from 'classnames';
import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { Checklist } from 'components/checklist/checklist';
import { IAnalyticsProps } from 'components/common/analytics';
import { Block } from 'components/common/block';
import {
    BodyCopy,
    ErrorMsg,
    Headline,
    InfoLink,
    Col,
    Row,
} from 'components/common/typography';
import { ButtonContainer as Button, InputContainer as Input } from 'containers';
import { FetchStatus } from 'lib/br-redux';
import {
    IConfiguration,
    IDeviceTypeProps,
    IInputValidationProps,
    IRegisterableUser,
    IServiceStepProps,
} from 'models';
import {
    TealiumFlowName,
    TealiumLinkEvents,
    TealiumPageLinkLocations,
    TealiumPageType,
    TealiumPagevents,
} from 'models/tealium';
import { InputValidator, Toggles, ValidationResponse } from 'utils';
import { TealiumDispatcher } from 'utils/tealium-dispatcher';
interface IPrefilledInfo {
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly referralCode: string;
    readonly inviteCode: string;
}
interface IMappedProps
    extends IAnalyticsProps,
        IInputValidationProps,
        IDeviceTypeProps {
    readonly authStatus: FetchStatus;
    readonly compatibility?: boolean;
    readonly configuration: IConfiguration;
    readonly configInitialized: boolean;
    readonly error: string;
    readonly prefilledInfo: IPrefilledInfo;
    readonly registrationStatus: FetchStatus;
    readonly validateShortCodeError: string;
    readonly referral: any;
    createEmailAccount(user: IRegisterableUser): void;
    redirectTo(url: string): void;
    validateInviteCode(shortCode: any): void;
}

interface IOtherProps extends IServiceStepProps {}

type IProps = IMappedProps & IOtherProps;
interface State {
    readonly needSCValidation: boolean;
}
@CSSModules(require('./create-account-form.less'), { allowMultiple: true })
class CreateAccountForm extends React.Component<IProps, State> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            needSCValidation: true,
        };
    }
    componentDidMount() {
        const {
            handleOnChange,
            registrationStatus,
            error,
            redirectTo,
            prefilledInfo: { inviteCode },
        } = this.props;

        if (inviteCode && inviteCode.trim()) {
            handleOnChange({
                target: { name: 'inviteCode', value: inviteCode },
            });
        }
        if (error !== '' && registrationStatus === FetchStatus.SUCCESS) {
            redirectTo(__('routes.phones'));
        }

        const fromPartyFlow = sessionStorage.getItem('fromPartyFlow');
        TealiumDispatcher.dispatchView(
            TealiumFlowName.NONE,
            fromPartyFlow
                ? TealiumPageType.SIGNUP_PARTY
                : TealiumPageType.SIGNUP,
        );
    }

    componentWillReceiveProps({
        configuration,
        prefilledInfo: { inviteCode },
        validateInviteCode,
    }: IProps) {
        if (configuration.toggles) {
            let refCode = sessionStorage.getItem('refCode');

            let rCode = inviteCode || refCode;
            if (
                Toggles.isReferral(configuration.toggles) &&
                rCode !== null &&
                this.state.needSCValidation
            ) {
                this.setState({ needSCValidation: false });
                validateInviteCode(rCode);
            }
        }
    }

    render() {
        const {
            form,
            props: { authStatus },
        } = this;
        const authed = authStatus === FetchStatus.SUCCESS;

        return (
            <div
                styleName={classNames('container', {
                    collapsed: authed,
                })}
            >
                {form}
            </div>
        );
    }

    private isGroupSignUp() {
        if (sessionStorage.getItem('GROUP_NAME')) {
            return true;
        } else {
            return false;
        }
    }

    private get form() {
        const refCode = sessionStorage.getItem('refCode');
        const fromPartyFlow = sessionStorage.getItem('fromPartyFlow');
        const pageName = fromPartyFlow
            ? TealiumPageType.SIGNUP_PARTY
            : TealiumPageType.SIGNUP;
        const {
            handleSubmit,
            hasErrors,
            props: {
                compatibility,
                handleOnChange,
                handleOnValidate,
                data: { email, firstName, lastName, password, inviteCode },
                error,
                authStatus,
                registrationStatus,
                validateShortCodeError,
                configuration,
            },
        } = this;

        if (error) {
            window.scrollTo(0, 0);
        }

        return (
            <div styleName="parent-container-create-account">
                <Row>
                    <Col width={12} mobileWidth={12}>
                        <Headline center>
                            {this.isGroupSignUp() ? (
                                <Block width={4}>
                                    {__('create-account-groups-title').replace(
                                        '{GroupName}',
                                        sessionStorage.getItem('GROUP_NAME'),
                                    )}
                                </Block>
                            ) : (
                                <Block width={2}>
                                    {__('create-account.title')}
                                </Block>
                            )}
                        </Headline>
                    </Col>
                </Row>
                <div styleName="container-create-account">
                    <div>
                        <div styleName="create-account-title">
                            <BodyCopy thin center>
                                {compatibility
                                    ? __(
                                          'create-account.subtitle.compatibility',
                                      )
                                    : __(
                                          'create-account.already-have-an-account',
                                      )}
                                {compatibility ? (
                                    ''
                                ) : (
                                    <InfoLink
                                        onClick={e => {
                                            e.stopPropagation();
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                pageName,
                                                TealiumPagevents.SIGNUP_SIGNIN,
                                                TealiumPageLinkLocations.NONE,
                                                TealiumLinkEvents.TEXT_LINK,
                                            );
                                        }}
                                        to={__('routes.sign-in')}
                                    >
                                        {__('create-account.sign-in')}
                                    </InfoLink>
                                )}
                            </BodyCopy>
                        </div>
                    </div>
                    <div>
                        <form
                            id="SignUpForm"
                            onSubmit={handleSubmit}
                            styleName="form"
                        >
                            <ErrorMsg>{error}</ErrorMsg>
                            <Input
                                id="firstName"
                                label={__(
                                    'create-account.firstname-field-label',
                                )}
                                placeholder={__(
                                    'create-account.firstname-field-label',
                                )}
                                value={firstName}
                                onFocus={e => {
                                    e.stopPropagation();
                                    TealiumDispatcher.dispatchEvent(
                                        TealiumFlowName.NONE,
                                        pageName,
                                        TealiumPagevents.SIGNUP_FIRST_NAME,
                                        TealiumPageLinkLocations.NONE,
                                        TealiumLinkEvents.FORM_FIELD,
                                    );
                                }}
                                onChange={handleOnChange}
                                onValidate={handleOnValidate}
                                validate={InputValidator.validateFirstName}
                            />
                            <Input
                                id="lastName"
                                label={__(
                                    'create-account.lastname-field-label',
                                )}
                                placeholder={__(
                                    'create-account.lastname-field-label',
                                )}
                                value={lastName}
                                onFocus={e => {
                                    e.stopPropagation();
                                    TealiumDispatcher.dispatchEvent(
                                        TealiumFlowName.NONE,
                                        pageName,
                                        TealiumPagevents.SIGNUP_LAST_NAME,
                                        TealiumPageLinkLocations.NONE,
                                        TealiumLinkEvents.FORM_FIELD,
                                    );
                                }}
                                onChange={handleOnChange}
                                onValidate={handleOnValidate}
                                validate={InputValidator.validateLastName}
                            />

                            <Block width={4}>
                                <Input
                                    id="email"
                                    type="email"
                                    label={__(
                                        'create-account.email-field-label',
                                    )}
                                    placeholder={__(
                                        'create-account.email-field-label',
                                    )}
                                    value={email}
                                    onFocus={e => {
                                        e.stopPropagation();
                                        TealiumDispatcher.dispatchEvent(
                                            TealiumFlowName.NONE,
                                            pageName,
                                            TealiumPagevents.SIGNUP_EMAIL,
                                            TealiumPageLinkLocations.NONE,
                                            TealiumLinkEvents.FORM_FIELD,
                                        );
                                    }}
                                    onChange={handleOnChange}
                                    onValidate={handleOnValidate}
                                    validate={InputValidator.validateEmail}
                                />
                                <Input
                                    id="password"
                                    type="password"
                                    noMargin
                                    label={__(
                                        'create-account.password-field-label',
                                    )}
                                    placeholder={__(
                                        'create-account.password-field-label',
                                    )}
                                    value={password}
                                    onFocus={e => {
                                        e.stopPropagation();
                                        TealiumDispatcher.dispatchEvent(
                                            TealiumFlowName.NONE,
                                            pageName,
                                            TealiumPagevents.SIGNUP_PASSWORD,
                                            TealiumPageLinkLocations.NONE,
                                            TealiumLinkEvents.FORM_FIELD,
                                        );
                                    }}
                                    onChange={handleOnChange}
                                    onValidate={handleOnValidate}
                                    validate={InputValidator.validatePassword}
                                    showErrors={false}
                                    togglePassword
                                />
                            </Block>

                            <Block width={2}>
                                <Checklist
                                    items={this.passwordRequirements(password)}
                                />
                            </Block>

                            {Toggles.isReferral(configuration.toggles) ? (
                                <Block width={2}>
                                    <Input
                                        id="inviteCode"
                                        label={__(
                                            'create-account.invitecode-field-label',
                                        )}
                                        placeholder={__(
                                            'create-account.invitecode-field-label',
                                        )}
                                        value={inviteCode || refCode}
                                        onValidate={handleOnValidate}
                                        onFocus={e => {
                                            e.stopPropagation();
                                            TealiumDispatcher.dispatchEvent(
                                                TealiumFlowName.NONE,
                                                pageName,
                                                TealiumPagevents.PROMO_CODE_ENTERED,
                                                TealiumPageLinkLocations.NONE,
                                                TealiumLinkEvents.FORM_FIELD,
                                            );
                                        }}
                                        onChange={handleOnChange}
                                        optional
                                        validate={this.validateInviteCode}
                                    />
                                    {validateShortCodeError && (
                                        <ErrorMsg>
                                            {validateShortCodeError}
                                        </ErrorMsg>
                                    )}
                                </Block>
                            ) : (
                                ''
                            )}
                        </form>
                    </div>
                </div>
                <Row>
                    <Col width={12} mobileWidth={12} flexFlowCol alignCenter>
                        {this.isGroupSignUp() && (
                            <Block width={3}>
                                <BodyCopy
                                    thin
                                    center
                                    styleName="create-account-footer-copy"
                                >
                                    {__(
                                        'create-account-groups.footer.message',
                                    ).replace(
                                        '{GroupName}',
                                        sessionStorage.getItem('GROUP_NAME'),
                                    )}
                                </BodyCopy>
                            </Block>
                        )}
                        <div styleName="create-account-button-div">
                            <Button
                                type="submit"
                                fullWidth
                                form="SignUpForm"
                                disabled={hasErrors}
                                loading={new Set([
                                    authStatus,
                                    registrationStatus,
                                ]).has(FetchStatus.FETCHING)}
                                onClick={e => {
                                    e.stopPropagation();
                                    TealiumDispatcher.dispatchEvent(
                                        TealiumFlowName.NONE,
                                        pageName,
                                        TealiumPagevents.SIGNUP_SUBMIT,
                                        TealiumPageLinkLocations.NONE,
                                        TealiumLinkEvents.BUTTON,
                                    );
                                }}
                                label={
                                    compatibility
                                        ? __(
                                              'create-account.submit-button-label.compatibility',
                                          )
                                        : __(
                                              'create-account.submit-button-label',
                                          )
                                }
                            />
                        </div>
                        <BodyCopy medium thin center>
                            {compatibility
                                ? ''
                                : __('create-account.more-info')}
                            <InfoLink
                                element="a"
                                href={__('routes.privacy')}
                                onClick={e => {
                                    e.stopPropagation();
                                    TealiumDispatcher.dispatchEvent(
                                        TealiumFlowName.NONE,
                                        pageName,
                                        TealiumPagevents.SIGNUP_PRIVACY,
                                        TealiumPageLinkLocations.NONE,
                                        TealiumLinkEvents.TEXT_LINK,
                                    );
                                }}
                                target="_blank"
                                plain
                            >
                                {__('create-account.privacy-policy')}
                            </InfoLink>
                        </BodyCopy>
                    </Col>
                </Row>
            </div>
        );
    }

    private readonly handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        const {
            createEmailAccount,
            data,
            referral: { shortCode },
        } = this.props;
        e.preventDefault();
        let createUserObj = { ...data };
        if (data.inviteCode === '' && shortCode) {
            createUserObj = { ...createUserObj, inviteCode: shortCode };
        }
        if (this.isGroupSignUp()) {
            createUserObj = {
                ...createUserObj,
                groupName: this.getSessionStorageKeyForGroup(),
            };
        }
        if (!this.hasErrors) {
            createEmailAccount(createUserObj);
        }
    };

    private getSessionStorageKeyForGroup() {
        return sessionStorage.getItem('GROUP_NAME');
    }

    private readonly validateInviteCode = (
        value: string,
    ): ValidationResponse => {
        return new InputValidator(value, {
            optional: true,
        }).validate();
    };

    private readonly passwordRequirements = (
        password: string,
    ): ReadonlyArray<{
        readonly label: string;
        readonly complete: boolean;
    }> => [
        {
            complete: password.length >= 8,
            label: '8 or more characters',
        },
        {
            complete: /[A-Z]/.test(password),
            label: 'include 1 uppercase letter',
        },
        {
            complete: /[a-z]/.test(password),
            label: 'include 1 lowercase letter',
        },
        {
            complete: /[~|!|@|#|$|%|^|&|*|?]/.test(password),
            label: 'include 1 symbol (!@#$%^&*)',
        },
    ];

    private get hasErrors(): boolean {
        const {
            props: { errors },
        } = this;
        return Object.keys(errors).findIndex(k => !!errors[k]) > -1;
    }
}

export { CreateAccountForm };
