import { BodyCopy } from 'components/common/typography';
import * as React from 'react';

const CPNI = () => (
    <>
        {(__('account.privacy.cpni.body') as ReadonlyArray<string>).map(
            (text, idx) => (
                <BodyCopy medium key={`cpni_${idx}`}>
                    {text}
                </BodyCopy>
            ),
        )}
    </>
);

export { CPNI };
