
import * as React from 'react';
import * as CSSModules from 'react-css-modules';
const styles = require('./faq.less');

@CSSModules(styles, { allowMultiple: true })
class FAQ extends React.Component<{}> {
    render() {
        return <>{(window.location.href = __('routes.help'))}</>;
    }
}
    export { FAQ };
