import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./search-bar.less');

interface IProps extends React.HTMLProps<HTMLDivElement> {
    readonly searchTerm: string;
    executeSearch(searchTerm: string): string;
}

@CSSModules(styles, { allowMultiple: true })
class SearchBar extends React.Component<IProps> {
    render() {
        const {
            props: { searchTerm, executeSearch },
        } = this;
        return (
            <div
                styleName={`search-bar ${
                    searchTerm.length > 0 ? 'close' : 'search'
                }`}
            >
                <button
                    styleName="search-button close-btn"
                    onClick={() => executeSearch('')}
                >
                    <svg width="34" height="34" viewBox="0 0 34 34">
                        <g
                            fill="none"
                            fillRule="evenodd"
                            stroke="#1800FF"
                            strokeLinecap="square"
                            strokeWidth="3.5"
                        >
                            <path d="M22.749 11L11 22.749M11 11l11.749 11.749" />
                        </g>
                    </svg>
                </button>
                <button styleName="search-button search-btn">
                    <svg width="30" height="30" viewBox="0 0 30 30">
                        <g
                            fill="none"
                            fillRule="evenodd"
                            stroke="#1800FF"
                            strokeWidth="3"
                            transform="translate(7 8)"
                        >
                            <circle cx="6.5" cy="6.5" r="6.5" />
                            <path
                                strokeLinecap="square"
                                d="M12.621 11.121l3.416 3.416"
                            />
                        </g>
                    </svg>
                </button>
                <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={event => executeSearch(event.target.value)}
                />
            </div>
        );
    }
}

export { SearchBar };
