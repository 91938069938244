import * as React from 'react';
import * as CSSModules from 'react-css-modules';

const styles = require('./returns-policy.less');

@CSSModules(styles)
class ReturnsPolicy extends React.Component<{}> {
    render() {
        return <>{(window.location.href = __('routes.returns-policy'))}</>;
    }
}

export { ReturnsPolicy };
