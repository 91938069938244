import { withAnalytics } from 'components/common/analytics';
import { IncompatibilityIMEI } from 'components/sign-up/components/phone-number/imei/ime-incompatiblity';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';

const mapDispatchToProps = dispatch => ({
    redirectTo: (url: string) => dispatch(push(url)),
});

const enhance = compose(
    withAnalytics,
    connect(
        null,
        mapDispatchToProps,
    ),
);

const IncompatibilityIMEIContainer = enhance(IncompatibilityIMEI);

export { IncompatibilityIMEIContainer };
