import * as React from 'react';
import * as CSSModules from 'react-css-modules';

import { IAnalyticsProps } from 'components/common/analytics';
import { BaseForm } from 'components/common/forms';
import { Hidden } from 'components/common/hidden';
import { BodyCopy, Label } from 'components/common/typography';
import {
    ButtonContainer as Button,
    SelectContainer as Select,
} from 'containers';
import { FetchStatus } from 'lib/br-redux';
import { ChoiceOption } from 'models';
import { IPurchaseState } from 'state/purchase/purchase.reducer';

const styles = require('./new-number.less');

interface Props extends IAnalyticsProps {
    readonly complete: boolean;
    handleSubmit(npaNxx: string): void;
}

interface State {
    readonly selectedNpaNxx: string;
}

@CSSModules(styles, { allowMultiple: true })
class SelectNpaNxxForm extends BaseForm<Props & IPurchaseState, State> {
    componentWillMount(): void {
        this.setState({ selectedNpaNxx: '' });
    }

    render() {
        const { npanxx, complete, mdn } = this.props;
        const { selectedNpaNxx } = this.state;

        return (
            <form onSubmit={this.handleSubmit(selectedNpaNxx)}>
                <Label id="select-npa-nxx-label" htmlFor="select-npa-nxx">
                    {__('select-npa-nxx-form.label')}
                </Label>
                <Select
                    id="select-npa-nxx"
                    styleName="no-margin no-pad"
                    handleChange={value => {
                        this.setState({ selectedNpaNxx: value });
                    }}
                    options={this.getNpaNxxOptions(npanxx.results)}
                    value={selectedNpaNxx}
                />
                <BodyCopy thin>{__('new-number.new-number-choices')}</BodyCopy>
                <Hidden when={complete}>
                    <Button
                        noMargin
                        right
                        loading={mdn.fetchStatus === FetchStatus.FETCHING}
                        disabled={this.state.selectedNpaNxx === ''}
                        type="submit"
                        label="Next"
                    />
                </Hidden>
            </form>
        );
    }

    private readonly getNpaNxxOptions = (
        results,
    ): ReadonlyArray<ChoiceOption> => {
        return results.map(r => ({
            label: `(${r.npa}) ${r.nxx}`,
            value: r.npa + r.nxx,
        }));
    };
}

export { SelectNpaNxxForm };
